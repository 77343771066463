import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import cookies from 'utils/Cookies/Cookies';
import NaviItem from './NaviItem';
import MobileNaviItem from './MobileNaviItem';
import Svg from 'components/Svg/Svg';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonSquare from 'components/Skeletons/SkeletonSquare';

import { getPortfolioLinks } from 'utils/Data/partners';
import { setSideNavigationStatus } from 'redux/modules';
import HorizontalScroll from '../HorizontalScroll/HorizontalScroll';
import useScrollPosition from 'decorators/Scroll/useScrollPosition';
import useQuery from 'decorators/Query/useQuery';

const StyledMenu = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: ${props => props.theme.zIndex('sideNavi')};
  flex-flow: column nowrap;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.opiCard};
  transition: width ${props => props.theme.navigation.transition};
  width: ${props =>
    props.isExtended ? props.theme.navigation.sideTabletWidth : props.theme.navigation.sideSmallWidth};
  padding-top: calc(var(--size-md) + ${props => props.theme.navigation.height});

  ${props => props.theme.media.landscape`
        display: flex;
    `}

  ${props => props.theme.media.desktop`
        width: ${props =>
          props.isExtended ? props.theme.navigation.sideWidth : props.theme.navigation.sideSmallWidth};
    `}

  @media print {
    display: none;
  }
`;

const Scrollable = styled.div`
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Edge */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const ToggleContainer = styled.div`
  border-top: 1px solid ${props => props.theme.colors.alabaster};
  margin-top: auto;
`;

const Toggle = styled.div`
  padding: var(--size-xs) var(--size-md);
  display: flex;
  justify-content: ${props => (props.isExtended ? 'flex-end' : 'center')};
  align-items: center;
  cursor: pointer;
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.fontSize.xxs};
  letter-spacing: 0.3px;
  overflow: hidden;
`;

const ToggleIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.mystic};
`;

const ToggleIcon = styled(Svg)`
  font-size: ${props => props.theme.fontSize.xxxs};
  fill: ${props => props.theme.colors.midnight};
`;

const MobileNavi = styled.div`
  height: 80px;
  margin: 0 -${props => props.theme.layoutSpacing.md}; /* negative margin */

  ${props => props.theme.media.tablet`
    margin: 0 -${props => props.theme.layoutSpacing.lg}; /* negative margin */
  `}

  ${props => props.theme.media.portrait`
    margin-bottom: ${props => props.theme.layoutSpacing.md};
  `}

  ${props => props.theme.media.landscape`
    display: none;
  `}

  @media print {
    display: none;
  }
`;

const FixedContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${props => props.theme.zIndex('sideNavi')};

  ${props => props.theme.media.portrait`
    ${props =>
      props.fixed &&
      `
      position: fixed;
    `}
  `}
`;

const MobileNaviContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 80px;

  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  background-color: ${props => props.theme.colors.white};
`;

export const SideNavigation = props => {
  const { links, linksMobile, t, loading, customViews } = props;
  const dispatch = useDispatch();
  const { query } = useQuery();
  const [scrollPosition] = useScrollPosition();
  const { partnerNumber } = useParams();
  const location = useLocation();
  const features = useSelector(state => state.profile.profile.features);
  const featureTeasers = useSelector(state => state.profile.profile.featureTeasers);
  const isExtended = useSelector(state => state.navigation.sideNaviExtended);
  const loadingCustomViews = useSelector(state => state.customView.loading);
  const disabledCustomViews = useSelector(state => state.profile.profile.disabledCustomViews || EMPTY_ARRAY);
  const serviceRequestCount = useSelector(state => state.serviceRequests.counts.partnerNumber[partnerNumber]);
  const toggleExtend = open => dispatch(setSideNavigationStatus(open));

  const toggle = e => {
    e.preventDefault();

    // Force resize to update floorplan/chart widths
    setTimeout(() => {
      requestAnimationFrame(() => {
        window.dispatchEvent(new Event('resize'));
      });
    }, 500);
    cookies.setItem('naviExtended', !isExtended, Infinity, '/');
    toggleExtend(!isExtended);
  };

  const renderLinks = (linksToShow, NaviElement) => {
    return linksToShow.map(link => {
      const {
        title,
        icon,
        disabled,
        count,
        loading,
        queryParams,
        to,
        smallIcon,
        contextLink,
        pathname,
        teaser,
        external,
        items,
      } = link;

      const linkTo = to || {
        pathname: pathname || (location && location.pathname),
        query: { page: undefined, year: undefined, ...queryParams },
      };
      return (
        <NaviElement
          key={pathname || to?.pathname || title}
          title={title}
          disabled={disabled || teaser}
          icon={icon}
          count={count}
          to={linkTo}
          location={location}
          t={t}
          loading={loading}
          extended={isExtended}
          smallIcon={smallIcon}
          contextLink={contextLink}
          teaser={teaser}
          external={external}
          items={items}
        />
      );
    });
  };

  const getLoadingSkeleton = toggleIcon => {
    return (
      <Fragment>
        <StyledMenu isExtended={isExtended}>
          <SkeletonText large style={{ width: '90%', marginBottom: '1em', marginTop: '2em', height: '1em' }} />
          <SkeletonText large style={{ width: '90%', marginBottom: '1em', height: '1em' }} />
          <SkeletonText large style={{ width: '90%', marginBottom: '1em', height: '1em' }} />
          <SkeletonText large style={{ width: '90%', marginBottom: '1em', height: '1em' }} />
          <ToggleContainer>
            <Toggle onClick={toggle} isExtended={isExtended}>
              <ToggleIconContainer>
                <ToggleIcon name={toggleIcon} />
              </ToggleIconContainer>
            </Toggle>
          </ToggleContainer>
        </StyledMenu>
        <MobileNavi>
          <MobileNaviContent>
            <SkeletonSquare size="3em" style={{ margin: 'auto 0 auto 2em' }} />
            <SkeletonSquare size="3em" style={{ margin: 'auto 0 auto 2em' }} />
            <SkeletonSquare size="3em" style={{ margin: 'auto 0 auto 2em' }} />
            <SkeletonSquare size="3em" style={{ margin: 'auto 0 auto 2em' }} />
          </MobileNaviContent>
        </MobileNavi>
      </Fragment>
    );
  };
  const toggleIcon = isExtended ? 'caret-left' : 'caret-right';
  const linksToShow =
    links ||
    getPortfolioLinks(
      partnerNumber,
      features,
      query,
      customViews,
      disabledCustomViews,
      featureTeasers,
      serviceRequestCount
    );
  const linksToShowInMobile = linksMobile || linksToShow;

  const scrollOffsetTablet = 64; // Navi
  const fixedTablet = scrollOffsetTablet < scrollPosition;

  if (loading) {
    return getLoadingSkeleton(toggleIcon);
  }

  return (
    <Fragment>
      <StyledMenu isExtended={isExtended} data-test-id="side-nav">
        <Scrollable>
          {renderLinks(linksToShow, NaviItem)}
          {loadingCustomViews && partnerNumber && (
            <SkeletonText large style={{ width: '90%', marginBottom: '1em', height: '1em' }} />
          )}
        </Scrollable>
        <ToggleContainer>
          <Toggle onClick={toggle} isExtended={isExtended}>
            <ToggleIconContainer>
              <ToggleIcon name={toggleIcon} />
            </ToggleIconContainer>
          </Toggle>
        </ToggleContainer>
      </StyledMenu>
      <MobileNavi data-test-id="mobile-nav">
        <FixedContainer fixed={fixedTablet}>
          <HorizontalScroll>
            <MobileNaviContent>
              {renderLinks(linksToShowInMobile, MobileNaviItem)}
              {loadingCustomViews && partnerNumber && (
                <SkeletonSquare size="3em" style={{ margin: 'auto 0 auto 2em' }} />
              )}
            </MobileNaviContent>
          </HorizontalScroll>
        </FixedContainer>
      </MobileNavi>
    </Fragment>
  );
};

SideNavigation.propTypes = {
  t: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      count: PropTypes.number,
      loading: PropTypes.bool,
      queryParams: PropTypes.object,
      to: PropTypes.object,
      smallIcon: PropTypes.bool,
      contextLink: PropTypes.bool,
      teaser: PropTypes.bool,
      external: PropTypes.bool,
    })
  ),
  linksMobile: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      count: PropTypes.number,
      loading: PropTypes.bool,
      queryParams: PropTypes.object,
      to: PropTypes.object,
      smallIcon: PropTypes.bool,
      contextLink: PropTypes.bool,
      teaser: PropTypes.bool,
      external: PropTypes.bool,
    })
  ),
  loading: PropTypes.bool,
  customViews: PropTypes.array,
};

const EMPTY_ARRAY = [];

export default SideNavigation;
