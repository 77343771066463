import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Title = styled.h3`
  color: var(--headline-fg);
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.default};
  text-transform: uppercase;
  margin: 2em 1em;
  ${props => props.theme.media.desktop`
        margin: 2em 0;
    `}
`;
Title.displayName = 'Title';

const Info = styled.span`
  font-weight: ${props => props.theme.font.weight.normal};
`;

const List = styled.ul``;
List.displayName = 'List';

const ListItem = styled.li`
  border-top: var(--table-row-border);
  &:hover {
    cursor: ${props => (props.clickEnabled ? 'pointer' : 'default')};
    opacity: ${props => (props.clickEnabled ? '.6' : '1')};
  }
  &:last-child {
    border-bottom: var(--table-row-border);
  }
  &:nth-of-type(even) {
    background-color: var(--white);
  }
`;
ListItem.displayName = 'ListItem';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-sm);
`;
Wrapper.displayName = 'Wrapper';

const ItemTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
ItemTitles.displayName = 'ItemTitles';

const ItemTitle = styled.span`
  color: var(--subheader-fg);
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.bold};
`;
ItemTitle.displayName = 'ItemTitle';

const ItemSubtitle = styled.span`
  margin-top: var(--size-xxs);
  color: var(--subheader-fg);
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.normal};
`;
ItemSubtitle.displayName = 'ItemSubtitle';

const ItemValue = styled.span`
  color: var(--body-text-fg);
  background-color: ${props => props.backgroundColor};
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.normal};
  border-radius: 4px;
  padding: var(--size-xs) 0;
  min-width: ${props => `${props.width}px`};
  text-align: center;
`;
ItemValue.displayName = 'ItemValue';

export const PerformanceList = ({
  title,
  info,
  items,
  t,
  clickEnabled,
  valueWidth,
  resolveValueBackgroundColor,
  valueUnit,
  theme,
}) => {
  const handleClick = e => {
    if (!clickEnabled) {
      e.preventDefault();
    }
  };

  const getItemValueBackgroundColor = item => {
    if (resolveValueBackgroundColor) {
      return resolveValueBackgroundColor(item.value);
    }
    return item.value === 0 ? 'var(--grey-10)' : 'var(--grey-20)';
  };

  return (
    <div>
      <Title>
        {t(title)}
        <Info>{info ? ` (${t(info)})` : ''}</Info>
      </Title>
      <List>
        {items.map(item => (
          <ListItem clickEnabled={clickEnabled} key={item.to}>
            <Link to={item.to} onClick={handleClick}>
              <Wrapper>
                <ItemTitles>
                  <ItemTitle data-test-id="PerformanceListItemTitle">{item.title}</ItemTitle>
                  <ItemSubtitle>{item.subtitle}</ItemSubtitle>
                </ItemTitles>
                <ItemValue width={valueWidth} backgroundColor={getItemValueBackgroundColor(item)}>
                  {valueUnit ? `${item.value} ${valueUnit}` : item.value}
                </ItemValue>
              </Wrapper>
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default withTheme(PerformanceList);

PerformanceList.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      to: PropTypes.string,
    })
  ).isRequired,
  clickEnabled: PropTypes.bool.isRequired,
  info: PropTypes.string,
  valueWidth: PropTypes.number,
  resolveValueBackgroundColor: PropTypes.func,
  valueUnit: PropTypes.string,
};

PerformanceList.defaultProps = {
  title: '',
  info: '',
  valueWidth: 115,
};
