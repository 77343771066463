import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { findOutdoorsTemperatureSensorId } from 'components/Modules/BuildingAutomationModule/utils';
import useBuildingSensors from '../useBuildingSensors';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const WEATHER_SENSOR_TYPE_NAMES = ['weather/azure/temperature'];

const useOutdoorTemperatureSensor = functionalLocationId => {
  const buildingConditions = useSelector(
    state => state.values.conditions.conditions[functionalLocationId] ?? EMPTY_OBJECT
  );
  const buildingMeta = useSelector(state => state.buildingMeta.meta[functionalLocationId] ?? EMPTY_ARRAY);
  const buildingSensors = useBuildingSensors(functionalLocationId);

  const sensor = useMemo(() => {
    // 1) Find a building sensor of weather sensor type
    const weatherSensor = buildingSensors.find(sensor => WEATHER_SENSOR_TYPE_NAMES.includes(sensor.sensorType?.name));

    if (weatherSensor) {
      return weatherSensor;
    }

    // 2) Find a building sensor configured in meta, or from building conditions
    const sensorIdFromMetaOrConditionData = findOutdoorsTemperatureSensorId(buildingConditions, buildingMeta);

    if (sensorIdFromMetaOrConditionData) {
      const outdoorTemperatureSensor = buildingSensors.find(
        sensor => sensor.id === Number(sensorIdFromMetaOrConditionData)
      );

      if (outdoorTemperatureSensor) {
        return outdoorTemperatureSensor;
      }
    }

    // 3) Null
    return null;
  }, [buildingConditions, buildingMeta, buildingSensors]);

  return sensor;
};

export default useOutdoorTemperatureSensor;
