import PropTypes from 'prop-types';
import get from 'lodash/get';
import styled from 'styled-components';

import Svg from 'components/Svg/Svg';

export const Textarea = styled.textarea`
  font-size: ${props => props.theme.input.font.mobileSize};
  font-family: ${props => props.theme.input.font.family};
  line-height: ${props => props.theme.font.lineHeight.md};
  color: ${props => props.theme.input.font.color.default};
  height: ${props => props.cssHeight || '120px'};
  width: 100%;
  padding: ${props => props.theme.input.padding};
  border: ${props => props.theme.input.border.static};
  border-bottom-color: ${props => props.highlightError && 'var(--error-color)'};
  border-radius: 0;
  box-shadow: ${props => !props.simple && props.theme.input.shadow.static};
  transition: ${props => props.theme.input.transition};

  &:hover,
  &:focus {
    box-shadow: ${props => !props.simple && props.theme.input.shadow.active};
    border: ${props => props.theme.input.border.active};
  }
  resize: none; /* Disabled because it breaks layout for some reason */

  &:disabled {
    opacity: 0.6;
    box-shadow: none;
    color: ${props => props.theme.input.font.color.disabled};
    background-color: ${props => props.theme.input.background.disabled};
  }

  &::placeholder {
    color: ${props => props.theme.input.font.color.placeholder};
    opacity: 1;
  }

  ${props => props.theme.media.landscape`
    font-size: ${props => props.theme.input.font.size};
  `}
`;
Textarea.displayName = 'Textarea';

export const Lock = styled(Svg)`
  position: absolute;
  right: 0.875rem;
  top: 0.8rem;
  fill: var(--input-subcontrol-fg-disabled);
  font-size: ${props => props.theme.font.size.lg};
`;
Lock.displayName = 'Lock';

const Container = styled.div`
  position: relative;
  display: flex;
`;
Container.displayName = 'Container';

export const InputTextArea = ({
  model,
  property,
  value,
  id,
  onChange,
  placeholder,
  readOnly,
  required,
  maxLength,
  height,
  disabled,
  className,
  highlightError,
  simple,
  ...otherProps
}) => {
  const inputValue = get(model, property) || value || '';
  return (
    <Container className={className}>
      <Textarea
        id={id}
        data-test-id={otherProps['data-test-id']}
        data-group={otherProps['data-group']}
        onChange={e => onChange(property, e.target.value)}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        maxLength={maxLength}
        value={inputValue}
        cssHeight={height}
        disabled={disabled}
        highlightError={highlightError}
        simple={simple}
      />
      {readOnly && <Lock name="fa-lock" />}
    </Container>
  );
};
InputTextArea.displayName = 'InputTextArea';

InputTextArea.propTypes = {
  model: PropTypes.object,
  className: PropTypes.string,
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  'data-test-id': PropTypes.string,
  'data-group': PropTypes.string,
  highlightError: PropTypes.bool,
  simple: PropTypes.bool,
};

export default InputTextArea;
