import PropTypes from 'prop-types';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

const StyledButton = styled.span`
  position: fixed;
  bottom: 10px;
  right: ${props => (props.offsetRight ? '80px' : '10px')};
  width: 60px;
  height: 60px;
  transition: all ${props => props.theme.motion.quick} ${props => props.theme.motion.easing};

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  background-color: var(--btn-blue-bg);
  border-radius: 50%;
  border: var(--border-semibold) solid var(--btn-blue-bc);
  cursor: pointer;
  z-index: ${props => props.theme.zIndex('header')};

  ${props => props.theme.media.landscape`
        bottom: 30px;
        right: ${props => (props.offsetRight ? '110px' : '30px')};
        width: 70px;
        height: 70px;
    `}

  &:hover {
    background-color: var(--btn-blue-bg-hl);
    transform: scale(1.15);
  }
`;

const Icon = styled(Svg)`
  fill: var(--btn-blue-fg);
  font-size: 25px;
`;
Icon.displayName = 'Icon';

export const CircleButton = ({ onClick, icon, children }) => (
  <StyledButton onClick={onClick} data-test-id="CircleButton">
    <Icon name={icon} />
    {children}
  </StyledButton>
);

CircleButton.displayName = 'CircleButton';

CircleButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  offsetRight: PropTypes.bool,
};
