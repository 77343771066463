import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_V2_PATH } from './index';

export const getAnalyticsFunctionsByFlFn = (request, functionalLocation) =>
  request.get({
    apiPath: `${MASTER_DATA_V2_PATH}/analyticsFunctions/findByFunctionalLocation`,
    query: { functionalLocation },
    noCache: true,
  });

export const toggleAnalyticsFunctionsFn = (request, toggles) =>
  request.put({
    apiPath: `${MASTER_DATA_V2_PATH}/analyticsFunctions/toggle`,
    data: toggles,
    noCache: true,
  });

export const updateAnalyticsFunctionFn = (request, analyticsFunctionUpdate) =>
  request.patch({
    apiPath: `${MASTER_DATA_V2_PATH}/analyticsFunctions`,
    data: analyticsFunctionUpdate,
    noCache: true,
  });

export const updateDisabledDevicesFn = (request, disabledDevices) => {
  request.put({
    apiPath: `${MASTER_DATA_V2_PATH}/analyticsFunctions/toggleDisabledDevices`,
    data: disabledDevices,
    noCache: true,
  });
};

export default wrapEachWithThunkRequest({
  getAnalyticsFunctionsByFl: getAnalyticsFunctionsByFlFn,
  toggleAnalyticsFunctions: toggleAnalyticsFunctionsFn,
  updateAnalyticsFunction: updateAnalyticsFunctionFn,
  updateDisabledDevices: updateDisabledDevicesFn,
});
