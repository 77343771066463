// copied from @caverion/loopback-shared package

export interface OpeningHourConfig {
  timezone: string;
  hours: {
    common: OpeningHours;
    byUseCase: Partial<{
      [useCase in UseCaseType]: OpeningHours;
    }>;
  };
}
export declare type Limit = [number | null, number | null];
export declare type LimitFactory = (timestamp: Date) => Limit;
export interface PerformanceLimitGroup {
  [component: string]: Limit | LimitFactory;
}
export interface PerformanceLimits {
  common: PerformanceLimitGroup;
  byUseCase: Partial<{
    [useCase in UseCaseType]: Partial<PerformanceLimitGroup>;
  }>;
}
export declare type OpeningHours = Array<[string | null, string | null]>;
/**
 * Guess building timezone based on the functional location prefix.
 */
export declare function guessBuildingTimezone(functionalLocation: string): string;
/**
 * Get timezone for a building from building meta if available, otherwise use the FL prefix to determine the timezone.
 */
export declare function getBuildingTimezone(functionalLocation: string, buildingMeta?: readonly BuildingMeta[]): string;
/**
 * Get use case for a device.
 *
 * Removes possible prefix so that 'sigfox_cooler', 'cream_cooler' and 'cooler' all resolve to the same use case.
 */
export declare function getUseCase(sensorTypeName?: string): UseCaseType | undefined;
/**
 * Parse opening hours from meta.
 *
 * Takes in opening hours for each day starting from Monday, returns formatted opening hours starting from Monday.
 */
export declare function parseOpeningHours(meta?: BuildingMeta, sundayFirst?: boolean): OpeningHours | undefined;
/**
 * Default limits and opening hours.
 */
export declare const defaults: {
  openingHours: OpeningHourConfig;
  limits: PerformanceLimits;
};
/**
 * Get performance limits for a sensors.
 *
 * 1. Sensor min/max meta (h:min, h:max)
 * 2. Use case specific building meta
 * 3. Global defaults for use case
 * 4. Building meta
 * 5. Global defaults
 */
export declare function getSensorPerformanceLimits(sensor: PerformanceSensor, timestamp?: Date): Limit | null;
/**
 * Get default limit for given sensor type and use case.
 */
export declare function getDefaultLimit(
  sensorType: string,
  useCase: string | undefined,
  timestamp: Date
): Limit | undefined;

export const UseCase = {
  Outdoor: 'outdoor',
  Cooler: 'cooler',
  Freezer: 'freezer',
  Storage: 'storage',
} as const;

export type UseCaseType = (typeof UseCase)[keyof typeof UseCase];

export const ServiceOrderLogEventType = {
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  DocumentUpload: 'document-upload',
  DocumentDelete: 'document-delete',
  CommentCreate: 'comment-create',
  StatusUpdate: 'status-update',
  AssignmentCreate: 'assignment-create',
  AssignmentSend: 'assignment-send',
  RequestCreate: 'request-create',
  ChecklistReportCreate: 'checklist-create',
  ChecklistReportUpdate: 'checklist-update',
  ReminderSms: 'reminder-sms',
  ReminderEmail: 'reminder-email',
} as const;

export const PlannedMaintenanceLogEventType = {
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  DocumentUpload: 'document-upload',
  DocumentDelete: 'document-delete',
  OrderCreate: 'service-order-create',
  OrderDelete: 'service-order-delete',
} as const;

/**
 * The model class is generated from OpenAPI schema - buildingMeta
 * buildingMeta
 */
export declare class BuildingMeta {
  constructor(data?: Partial<BuildingMeta>);
  /**
   *
   */
  id?: number;
  /**
   *
   */
  partnerNumber?: string;
  /**
   *
   */
  functionalLocation?: string;
  /**
   *
   */
  key: string;
  /**
   *
   */
  value?: string;
  /**
   *
   */
  filterable?: boolean;
  /**
   *
   */
  created?: string;
  /**
   *
   */
  modified?: string;
}

export declare class PerformanceSensor {
  constructor(data?: Partial<PerformanceSensor>);
  id: number;
  name: string | null;
  equipmentNumber: string | null;
  type: string | null;
  created: Date;
  modified: Date;
  default: number | null;
  granularity: string | null;
  status: string | null;
  sensorHierarchyBuildingId: number | null;
  disabled: boolean | null;
  parentId: number | null;
  sensorTypeId: number;
  sensorTypeName: string;
  sensorHierarchyId: number;
  sensorHierarchyType: string;
  functionalLocation: string;
  sensorParentTypeName: string | null;
  /** Minimum value set for the sensor (sensor meta: h:min) */
  sensorMin: number | null;
  /** Maximum value set for the sensor (sensor meta: h:max) */
  sensorMax: number | null;
  /** Minimum value set for the use case of the sensor (building meta: performance/{useCase}/{sensorType}/min) */
  useCaseMin: number | null;
  /** Maximum value set for the use case of the sensor (building meta: performance/{useCase}/{sensorType}/max) */
  useCaseMax: number | null;
  /** Minimum value set for the sensor on building level (building meta: performance/{sensorType}/min) */
  buildingMin: number | null;
  /** Maximum value set for the sensor on building level (building meta: performance/{sensorType}/max) */
  buildingMax: number | null;
  /**
   * @deprecated Use 'id' property.
   */
  sensorId: number;
  /**
   * @deprecated Use 'name' property.
   */
  sensorName: string | null;
  /**
   * @deprecated Use 'parentId' property.
   */
  sensorParentId: number | null;
}
