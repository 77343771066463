import { Component } from 'react';
import PropTypes from 'prop-types';
import withQuery from 'decorators/Query/withQuery';
import ControlledPager from './ControlledPager';

class Pager extends Component {
  handlePageChange = page => {
    const { onChange, setQuery } = this.props;
    onChange && onChange(page);
    setQuery({ page });
  };

  render() {
    const { limit, totalResults, center, query } = this.props;

    const currentPage = parseInt(query.page, 10) || 1;

    return (
      <ControlledPager
        page={currentPage}
        limit={limit}
        totalResults={totalResults}
        center={center}
        onChange={this.handlePageChange}
      />
    );
  }
}

Pager.propTypes = {
  limit: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  center: PropTypes.bool.isRequired,
  query: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default withQuery(Pager);
