import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseButton from 'components/Button/BaseButton/BaseButton';

const StyledButton = styled(BaseButton)`
  background-color: ${props => props.theme.colors.transparent};
  border-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.white};

  margin: var(--size-xxs);
  height: 4em;
  flex: 1;
  min-width: auto;

  ${props => props.theme.media.landscape`
    height: ${props => props.theme.button.large.height};
    flex: initial;
    min-width: ${props => props.theme.button.large.minWidth};
  `}
`;

const SuccessPageButton = ({ onClick, children }) => (
  <StyledButton large onClick={onClick}>
    {children}
  </StyledButton>
);

SuccessPageButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default SuccessPageButton;
