import { Component } from 'react';
import PropTypes from 'prop-types';
import LocalizationContext from './LocalizationContext';
import setGlobalLocale from 'utils/Date/setGlobalLocale';
import cookies from 'utils/Cookies/Cookies';
import { getTranslateFn } from 'decorators/Translations/translations';

class LocalizationProvider extends Component {
  static propTypes = {
    children: PropTypes.any,
  };
  constructor(props) {
    super(props);
    this.state = {
      translations: {},
      translationRowNumbers: {},
      showTranslationKeys: false,
      updateTranslations: this.updateTranslations,
    };
  }
  updateTranslations = ({ language, translations, showTranslationKeys, translationRowNumbers }) => {
    if (language !== this.state.language) {
      setGlobalLocale(language, getTranslateFn(translations || this.state.translations));
      cookies.setItem('language', language, Infinity, '/');
      const googleLanguage = language === 'nb' ? 'no' : language;
      cookies.setItem('googtrans', `/en/${googleLanguage}`, Infinity, '/');
      document.documentElement.lang = language;
    }
    this.setState({
      language,
      translations,
      showTranslationKeys,
      translationRowNumbers,
    });
  };

  render() {
    return <LocalizationContext.Provider value={this.state}>{this.props.children}</LocalizationContext.Provider>;
  }
}

export default LocalizationProvider;
