import { useState, ReactNode } from 'react';
import {
  useReactTable,
  TableOptions,
  Table,
  getCoreRowModel,
  PartialKeys,
  CellContext,
  RowData,
} from '@tanstack/react-table';

import { TableLabelWrapper } from './GenericTable';

/**
 * Low-level hook that uses `useTable` from `react-table` with the following defaults:
 * - Enable column visibility toggling
 * - Wrap cells to {@link TableLabelWrapper} by default (no cell provided)
 *
 * @param tableOptions Table options. For automatic width for all columns and the table, set `meta.width = "auto"`.
 *   For full-width table, set `meta.width = "full"`.
 *   See {@link https://tanstack.com/table/v8/docs/adapters/react-table}.
 */
export function useTable<TData extends RowData>(
  tableOptions: PartialKeys<TableOptions<TData>, 'getCoreRowModel'>
): Table<TData> {
  const [columnVisibility, setColumnVisibility] = useState({});

  return useReactTable({
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,

    ...tableOptions,

    defaultColumn: {
      // Wrap value for default formatting
      cell: ({ getValue }: CellContext<TData, unknown>) => (
        <TableLabelWrapper>{getValue() as ReactNode}</TableLabelWrapper>
      ),
      ...tableOptions.defaultColumn,
    },

    state: {
      columnVisibility,
      ...tableOptions.state,
    },
  });
}
