import styled from 'styled-components';
import PropTypes from 'prop-types';

import DialogFooter from 'components/Dialog/DialogFooter';
import PrimaryButton from 'components/Button/PrimaryButton';

const SpaciousFooter = styled(DialogFooter)`
  ${props => props.theme.media.landscape`
  padding: ${({
    theme: {
      spacing: { lg, xxl },
    },
  }) => `${lg} ${xxl} ${xxl} ${xxl}`};
`}
`;
SpaciousFooter.displayName = 'SpaciousFooter';

const ConfirmationModalFooter = ({ onClickCancel, onClickConfirm, t, loading, confirmButtonType = 'warning' }) => (
  <SpaciousFooter naked>
    <PrimaryButton naked large onClick={onClickCancel}>
      {t('Cancel')}
    </PrimaryButton>
    <PrimaryButton
      warning={confirmButtonType === 'warning'}
      confirm={confirmButtonType === 'confirm'}
      add={confirmButtonType === 'add'}
      large
      onClick={onClickConfirm}
      loading={loading}
    >
      {t('Confirm')}
    </PrimaryButton>
  </SpaciousFooter>
);

ConfirmationModalFooter.propTypes = {
  onClickCancel: PropTypes.func,
  onClickConfirm: PropTypes.func,
  t: PropTypes.func,
  loading: PropTypes.bool,
  confirmButtonType: PropTypes.string,
};

export default ConfirmationModalFooter;
