import PropTypes from 'prop-types';
import range from 'lodash/range';

import SkeletonText from 'components/Skeletons/SkeletonText';
import HorizontalScroll from 'components/HorizontalScroll/HorizontalScroll';
import { Container, MonthSelector, Year, Month } from './CalendarMonthSelector';
import themes from 'styles/themes';

const yearSkeletonStyles = {
  flexDirection: 'column',
  padding: `0  ${themes.customerPlatform.spacing.xs}`,
};

export const MonthSelectorSkeleton = ({ lastMonth }) => {
  return (
    <Container>
      <HorizontalScroll>
        <MonthSelector>
          <Year style={yearSkeletonStyles}>
            <SkeletonText style={{ margin: 0 }} />
          </Year>
          {range(0, lastMonth).map(currentMonth => (
            <Month key={currentMonth}>
              <SkeletonText style={{ margin: 0 }} />
            </Month>
          ))}
        </MonthSelector>
      </HorizontalScroll>
    </Container>
  );
};

MonthSelectorSkeleton.propTypes = {
  lastMonth: PropTypes.number,
};

export default MonthSelectorSkeleton;
