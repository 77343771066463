import { useRef } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';

import { ChartContainer } from '../common';
import ScatterPlot from 'components/Charts/ScatterPlot/ScatterPlot';
import SeriesSensorsNames from './SeriesSensorsNames';

import useCustomChart from '../useCustomChart';
import useScatterPlotCustomChart from './useScatterPlotCustomChart';
import { CustomChart as CustomChartPropType } from '../propTypes';
import useScatterPlotAnnotations from './ScatterPlotAnnotations/useScatterPlotAnnotations';
import useAnnotationForm from './ScatterPlotAnnotations/useAnnotationForm';
import ScatterPlotCreateAnnotation from './ScatterPlotAnnotations/ScatterPlotCreateAnnotation';
import useBuildingSensors from '../../useBuildingSensors';

import useScatterPlotAnnotationLabels from './ScatterPlotAnnotations/useScatterPlotAnnotationLabels';
import AnnotationPopover from 'components/Sensor/SensorAnnotations/AnnotationPopover';
import useNotification from 'decorators/Notification/useNotification';
import { AnnotationInfo } from '../DoubleClickAnnotationInfo';
import useChartDoubleClick from 'components/Charts/useChartDoubleClick';
import { useTranslations } from 'decorators/Translations/translations';

const uniqueSensorIds = chart => uniq(chart.series.reduce((sensorIds, serie) => sensorIds.concat(serie.sensorIds), []));

const ScatterPlotCustomChart = props => {
  const { chart, functionalLocationId, preview } = props;
  const containerRef = useRef();
  const [t] = useTranslations();

  const buildingSensors = useBuildingSensors(functionalLocationId);

  const { valuesBySensorId, loadingSensorValues } = useCustomChart({
    chart,
    functionalLocationId,
  });

  const { seriesData, ...chartUnits } = useScatterPlotCustomChart({
    chart,
    buildingSensors,
    valuesBySensorId,
  });

  const { showNotification, renderedNotification } = useNotification();

  const { annotations, addAnnotation, removeAnnotation } = useScatterPlotAnnotations(
    chart.id,
    functionalLocationId,
    uniqueSensorIds(chart)
  );

  const { onOpenAnnotationForm, onCloseAnnotations, annotationFormContext, annotationPopover, setAnnotationPopover } =
    useAnnotationForm();

  const { onClick } = useChartDoubleClick(onOpenAnnotationForm, null);

  const annotationLabels = useScatterPlotAnnotationLabels(annotations, setAnnotationPopover);

  const handleAddAnnotation = async (...args) => {
    const err = await addAnnotation(...args);
    if (err) {
      return showNotification({ type: 'error', message: t('Saving note failed'), autoHide: true });
    }
    return showNotification({ type: 'success', message: t('Note was saved successfully'), autoHide: true });
  };

  return (
    <ChartContainer ref={containerRef}>
      <ScatterPlot
        loading={loadingSensorValues}
        seriesData={seriesData}
        {...chartUnits}
        setpoint={chart.setpoint}
        showTrend={chart.keyConfiguration?.showTrend}
        annotations={annotationLabels}
        onOpenAnnotationForm={onClick}
      />
      {annotationPopover && (
        <AnnotationPopover
          {...props}
          {...annotationPopover}
          containerRef={containerRef}
          onClose={onCloseAnnotations}
          showNotification={showNotification}
          onDeleted={removeAnnotation}
        />
      )}
      {annotationFormContext && (
        <ScatterPlotCreateAnnotation
          containerRef={containerRef}
          createAnnotationAt={annotationFormContext}
          chart={chart}
          functionalLocationKey={functionalLocationId}
          onClose={onCloseAnnotations}
          onSubmit={handleAddAnnotation}
          showNotification={showNotification}
          chartUnits={chartUnits}
        />
      )}
      {renderedNotification}
      {!preview && <AnnotationInfo />}
      <SeriesSensorsNames seriesData={seriesData} />
    </ChartContainer>
  );
};

ScatterPlotCustomChart.defaultProps = {
  chart: { series: [] },
};

ScatterPlotCustomChart.propTypes = {
  chart: CustomChartPropType.isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

export default ScatterPlotCustomChart;
