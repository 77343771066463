import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import translations from 'decorators/Translations/translations';
import RescuePlanService from 'services/rescuePlan';
import MasterDataService from 'services/masterData';
import RescuePlanBreakdown from './RescuePlanBreakdown/RescuePlanBreakdown';
import RescuePlanSelectTab from './RescuePlanSelectTab/RescuePlanSelectTab';
import styled from 'styled-components';

const Container = styled.div`
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  width: 100%;
  margin: var(--size-md) auto 0;
  margin-top: 0
    ${props => props.theme.media.landscape`
    margin: ${props => props.theme.section.spacing} auto 0;
  `};
`;

const SAFexistsForFunctionalLocation = async functionalLocation => {
  // Find technical systems of the building
  const result = await MasterDataService.functionalLocationsByParent([functionalLocation]);
  const functionalUnit = result
    .find(location => {
      return location.type === 'TO';
    })
    .path.pop();
  // Check that technical systems containg a rescue plan and return it
  const safetyUnit = await MasterDataService.functionalLocations([functionalUnit + '-SAF']);
  const saf = safetyUnit[0];

  return saf;
};

const RescuePlanModule = ({ t, functionalLocation, publicInfo, publicUserLanguage }) => {
  const [info, setInfo] = useState(publicInfo || []);
  const [rescueFunctionalLocation, setRescueFunctionalLocation] = useState({});
  const [error, setError] = useState(null);

  const setData = async functionalLocation => {
    try {
      if (functionalLocation) {
        const location = await SAFexistsForFunctionalLocation(functionalLocation.functionalLocation);
        setRescueFunctionalLocation(location);
        const rescueInfo = await RescuePlanService.rescuePlansInfoByFunctionalLocation(location.functionalLocation);
        setInfo(rescueInfo);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    setData(functionalLocation);
  }, [functionalLocation]);

  return (
    <Container>
      <RescuePlanSelectTab info={info} />
      <RescuePlanBreakdown info={info} publicUserLanguage={publicUserLanguage} />
    </Container>
  );
};

RescuePlanModule.propTypes = {
  functionalLocation: PropTypes.object,
  publicInfo: PropTypes.array,
  publicUserLanguage: PropTypes.string,
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
});

const connector = connect(mapStateToProps);

export default withRouter(connector(translations(RescuePlanModule)));
