import PropTypes from 'prop-types';

import translations from 'decorators/Translations/translations';
import UserManualContainer from '../UserManualV1/UserManualContainer';
import { CUSTOM_TYPE } from '../UserManualV1/UserManualUtils';

export const Roadmap = ({ t }) => <UserManualContainer type={CUSTOM_TYPE.ROADMAP} title={t('Roadmap')} />;

Roadmap.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translations(Roadmap);
