import isEmpty from 'lodash/isEmpty';
import { getChartSensors } from '../../utils';
import { formatEnergyValueToUnit, ENERGY_UNIT_DIVIDERS } from 'utils/Data/values';
import groupBy from 'lodash/groupBy';
import endsWith from 'lodash/endsWith';
import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';

const now = new Date();
const currentYear = now.getFullYear();

export const sensorMapper = (sensor = {}, unit, name) => ({
  ...sensor,
  name: name ?? sensor.name,
  sensorType: {
    ...sensor?.sensorType,
    ...(ENERGY_UNIT_DIVIDERS[unit] && { unit }),
  },
});

export const dataMapper = (data = [], unit) =>
  data.map(value => ({
    ...value,
    ...(ENERGY_UNIT_DIVIDERS[unit] && { value: formatEnergyValueToUnit(value?.value, unit) }),
  }));

export const combineSeriesData = (valuesByComparisonSeries = [], category, seriesObj = {}) => {
  if (!seriesObj.sensorIds || seriesObj.sensorIds.length === 0) {
    return [];
  }
  if (seriesObj.sensorIds?.length === 1) {
    return (
      valuesByComparisonSeries.find(
        values => +values.category === +category && +values.sensorId === seriesObj.sensorIds[0]
      )?.data ?? []
    );
  }

  // if series has multiple sensor ids, calculate averages or sums:

  const values = valuesByComparisonSeries
    .filter(values => +values.category === +category && seriesObj.sensorIds.includes(+values.sensorId))
    .flatMap(values => values.data);
  const valueGroups = Object.values(groupBy(values, value => value.timestamp));
  const aggregationFunc = endsWith(values[0]?.aggregation, 'Sum') ? sumBy : meanBy;

  return valueGroups.map(valueArray => ({
    ...valueArray[0],
    sensorId: null,
    value: aggregationFunc(valueArray, 'value'),
  }));
};

export const mapComparisonData = (chart, valuesByComparisonSeries = [], sensors, temperatureSensor) => {
  if (isEmpty(valuesByComparisonSeries)) {
    return [];
  }

  const chartSensors = getChartSensors(chart.series, sensors);

  // hackish way to distinct which series are connected
  const groups = Object.values(groupBy(chart.series, ({ name, sensorIds }) => [name, ...sensorIds].join('-')));

  const data = groups.map(seriesArray => {
    // use first series object to get unit and name
    const unit = seriesArray[0]?.unit;
    const name = seriesArray[0]?.name;
    const seriesSensors = seriesArray[0]?.sensorIds ?? [];

    const isOutdoorTemperature = chartSensors.some(
      sensor => seriesSensors.includes(sensor.id) && sensor?.id === temperatureSensor?.id
    );

    const sensors = chartSensors
      .filter(sensor => seriesArray[0]?.sensorIds.includes(sensor.id))
      .map(sensor => sensorMapper(sensor, !isOutdoorTemperature ? unit : '', name));

    return {
      sensors,
      comparisonData:
        seriesArray
          .map(series => {
            const category = series.comparisonYear ?? series.comparisonYearDiff + currentYear;
            const data = combineSeriesData(valuesByComparisonSeries, category, series);
            return {
              data: dataMapper(data, isOutdoorTemperature ? '' : unit),
              name: name ? `${name} (${category})` : category,
              category,
              color: series.color,
              graphType: series.graphType,
              // dashed line for outdoor temperature series
              dashStyle: (series.dashStyle ?? isOutdoorTemperature) ? 'Dash' : undefined,
              isOutdoorTemperature,
            };
          })
          .filter(item => !!item.data?.length) ?? [],
    };
  });
  return data;
};
