import styled from 'styled-components';

type SimpleDropdownItemProps = {
  useWarningColor?: boolean;
  disabled?: boolean;
  bold?: boolean;
  align?: string;
};

const SimpleDropdownItem = styled.button<SimpleDropdownItemProps>`
  display: block;
  white-space: nowrap;
  background-color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.font.size.xs};
  color: ${props =>
    props.useWarningColor
      ? props.theme.colors.radicalRed
      : props.disabled
        ? props.theme.colors.darkgray
        : props.theme.colors.black};
  appearance: none;
  border: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  padding: var(--size-sm) var(--size-lg);
  font-weight: ${props => props.bold && props.theme.font.weight.bold};
  text-align: ${props => props.align};

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${props => props.theme.colors.alabaster};
  }
`;

export default SimpleDropdownItem;
