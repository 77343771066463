import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyledFilterCss } from './Filter';

const StyledFilters = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${props => props.theme.layoutSpacing.sm};

  /* TODO: fix child styles */
  > * {
    ${StyledFilterCss}
    display: flex;
    margin: 0 !important;
  }
`;

const Filters = ({ children, ...others }) => {
  return <StyledFilters {...others}>{children}</StyledFilters>;
};

Filters.propTypes = {
  children: PropTypes.node,
};

export default Filters;
