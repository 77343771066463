const lineOptions = {
  lineWidth: 2,
  marker: {
    enabled: false,
    lineWidth: 2,
    radius: 5,
    symbol: 'circle',
    states: {
      hover: {
        lineWidthPlus: 0,
        radiusPlus: 0,
        shadow: false,
        animation: { duration: 0 },
      },
    },
  },
  states: {
    hover: {
      lineWidthPlus: 0,
      halo: { size: 0 },
    },
  },
  zIndex: 4,
};

export const BASE_CONFIG = {
  chart: {
    zoomType: 'x',
    backgroundColor: 'transparent',
    spacing: [8, 20, 20, 20],
    className: 'default-custom-chart',
    inverted: false,
    animation: false,
  },
  title: false,
  credits: false,
  tooltip: {
    split: true,
    shared: false,
    crosshairs: true,
    borderWidth: 0,
    padding: 0,
    backgroundColor: null,
    useHTML: true,
  },
  plotOptions: {
    series: {
      turboThreshold: 400, // Make rendering large series (> 400) more efficient.
      boostTreshold: 2000,
      animation: false,
    },
    spline: lineOptions,
    line: lineOptions,
    column: {
      zIndex: 3,
    },
    area: {
      fillOpacity: 0.5,
      step: 'left',
      lineWidth: 0,
      trackByArea: true,
      marker: {
        enabled: false,
      },
      zIndex: 2,
    },
  },
  legend: {
    align: 'left',
    margin: 26,
    verticalAlign: 'top',
    borderWidth: 0,
    enabled: true,
  },
  xAxis: {
    type: 'datetime',
    lineWidth: 0,
    minorGridLineWidth: 0,
    gridLineWidth: 0,
    tickLength: 5,
    ordinal: false,
  },
  scrollbar: {
    enabled: false,
    minWidth: 10,
  },
};

export const GRAPH_TYPES_WITH_STEP = ['presence', 'event', 'boolean'];
