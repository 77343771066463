import { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'decorators/Translations/translations';
import MasterDataService from 'services/masterData';

import ButtonDropdown from 'components/Button/ButtonDropdown';
import Infotip from 'components/Infotip/Infotip';

import { saveProfile } from 'redux/modules';
import { deriveEditedPermissions } from 'utils/profile';
import { useClearCache } from 'utils/Fetch/useClearCache';

const AdminAuthorizeButton = () => {
  const profile = useSelector(state => state.profile.profile);
  const [t] = useTranslations();
  const { partnerNumber, functionalLocationId } = useParams();
  const dispatch = useDispatch();
  const [invalidValue, setInvalidValue] = useState();
  const location = useLocation();
  const history = useHistory();
  const { clearAll } = useClearCache();

  const validatePartnerNumber = async () => {
    const customers = await dispatch(MasterDataService.customersByPartnerNumbers([partnerNumber]));
    if (customers.length === 0) {
      throw new Error('Invalid partnerNumber');
    }
  };

  const validateFunctionalLocation = async () => {
    const functionalLocations = await MasterDataService.adminFunctionalLocations([functionalLocationId]);
    if (functionalLocations.length === 0) {
      throw new Error('Invalid functionalLocation');
    }
  };

  const validateParams = async () => {
    try {
      await validatePartnerNumber();
    } catch (e) {
      setInvalidValue(partnerNumber);
      return;
    }
    if (functionalLocationId) {
      try {
        await validateFunctionalLocation();
      } catch (e) {
        setInvalidValue(functionalLocationId);
      }
    }
  };

  useEffect(() => {
    validateParams();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const afterSave = async () => {
    await clearAll();
    history.push('/');
    history.replace(`${location.pathname}${location.search}`);
  };

  const savePortfolioPermissions = () => {
    return dispatch(
      saveProfile(profile.id, {
        ...profile,
        ...deriveEditedPermissions({ ...profile, addedPartnerPermissions: [partnerNumber] }),
      })
    ).then(afterSave);
  };

  const saveFunctionalLocationPermissions = () => {
    return dispatch(
      saveProfile(profile.id, {
        ...profile,
        ...deriveEditedPermissions({
          ...profile,
          addedFunctionalLocations: { [partnerNumber]: [functionalLocationId] },
        }),
      })
    ).then(afterSave);
  };

  const items = [
    {
      label: t('Customer Portfolio'),
      onClick: savePortfolioPermissions,
    },
  ];
  if (functionalLocationId) {
    items.push({
      label: t('Functional location'),
      onClick: saveFunctionalLocationPermissions,
    });
  }

  const button = (
    <ButtonDropdown
      buttonLabel={t('Add Permissions')}
      items={items}
      buttonProps={{ large: true, disabled: !!invalidValue }}
    />
  );

  if (invalidValue) {
    return (
      <Infotip text={`${t('Invalid')}: ${invalidValue}`} position="top">
        {button}
      </Infotip>
    );
  }

  return button;
};

export default AdminAuthorizeButton;
