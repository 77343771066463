import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import createRootReducer from './modules/reducer';

import { ApiSettingsProvider } from 'utils/Fetch/providers';

const create = () => {
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
  });

  const store = configureStore({
    reducer: {
      router: routerReducer,
      ...createRootReducer(),
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            settings: ApiSettingsProvider,
          },
        },
        // Disable middleware checks for now
        serializableCheck: false,
        immutableCheck: false,
      }).concat(routerMiddleware),
  });

  const history = createReduxHistory(store);
  return { store, history };
};

export default create;
