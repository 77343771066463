import PropTypes from 'prop-types';
import DateColumn from './../ColumnTypes/DateColumn';
import DescriptionColumn from './../ColumnTypes/DescriptionColumn';
import StatusColumn from './../ColumnTypes/StatusColumn';
import TypeColumn from './../ColumnTypes/TypeColumn';
import CreatedColumn from './../ColumnTypes/CreatedColumn';
import ImprovementColumn from './../ColumnTypes//ImprovementColumn';
import LocationColumn from './../ColumnTypes/LocationColumn';
import DefaultColumn from './../ColumnTypes/DefaultColumn';
import SavingsColumn from './../ColumnTypes/SavingsColumn';

const cellPropTypes = {
  rowData: PropTypes.object,
  t: PropTypes.func,
  field: PropTypes.string,
};

export const OriginCell = ({ rowData }) => <CreatedColumn data={rowData.origin} />;
OriginCell.propTypes = cellPropTypes;

export const DescriptionCell = ({ t, rowData }) => <DescriptionColumn t={t} data={rowData.location} />;
DescriptionCell.propTypes = cellPropTypes;

export const LocationCell = ({ t, rowData }) => (
  <LocationColumn
    t={t}
    data={{
      name: rowData.functionalLocationName,
      functionalLocation: rowData.functionalLocation,
      partnerNumber: rowData.currentPartnerNumber,
    }}
  />
);
LocationCell.propTypes = cellPropTypes;

export const StatusCell = ({ t, rowData }) => (
  <StatusColumn data={{ status: rowData.status, subStatus: rowData.subStatus }} t={t} />
);
StatusCell.propTypes = cellPropTypes;

export const TypeCell = ({ rowData }) => <TypeColumn data={rowData.deviceGroup} />;
TypeCell.propTypes = cellPropTypes;

export const ImprovementCell = ({ t, rowData, field }) => <ImprovementColumn data={rowData[field]} t={t} />;
ImprovementCell.propTypes = cellPropTypes;

export const DateCell = ({ rowData, field }) => <DateColumn data={rowData[field]} />;
DateCell.propTypes = cellPropTypes;

export const DefaultCell = ({ t, rowData, field }) => <DefaultColumn data={rowData[field]} t={t} />;
DefaultCell.propTypes = cellPropTypes;

export const YearCell = ({ t, rowData, field }) => (
  <DefaultColumn data={rowData[field]} unit={rowData[field] === 1 ? t('year') : t('years')} />
);
YearCell.propTypes = cellPropTypes;

export const TranslatedCell = ({ t, rowData, field }) => <DefaultColumn data={t(rowData[field])} />;
TranslatedCell.propTypes = cellPropTypes;

export const SavingsCell = ({ t, rowData, field }) => (
  <SavingsColumn
    t={t}
    data={Number.isInteger(rowData[field]) ? rowData[field] / 100 : 0}
    unit={Number.isInteger(rowData[field]) ? '€' : ''}
  />
);
SavingsCell.propTypes = cellPropTypes;

export const CostCell = ({ t, rowData, field }) => (
  <DefaultColumn
    data={Number.isInteger(rowData[field]) ? rowData[field] / 100 : 0}
    unit={Number.isInteger(rowData[field]) ? '€' : ''}
  />
);
CostCell.propTypes = cellPropTypes;
