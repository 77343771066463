import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import range from 'lodash/range';
import take from 'lodash/take';
import orderBy from 'lodash/orderBy';
import BenchmarkTabSkeleton from 'containers/Application/Recycling/RecyclingBenchmark/BenchmarkTab/BenchmarkTabSkeleton';
import ErrorBoundaryFallback from 'components/ErrorPage/ErrorBoundaryFallback';
import PerformanceList from 'components/PerformanceList/PerformanceList';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import connect from 'containers/Application/Recycling/RecyclingBenchmark/BenchmarkTab/connectBenchmarkTab';
import translations from 'decorators/Translations/translations';
import tabs from 'containers/Application/Recycling/tabs';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { ListWrapper, Performances, Filter, FilterInfo } from 'containers/Application/RemoteCenter/Benchmarking';
import { formatNumber } from 'utils/Number/decimalFormat';

export const BenchmarkTab = ({
  t,
  profile,
  theme,
  containerLoading,
  tab,
  visible,
  partnerNumber,
  syntheticSensors,
  loadBenchmarkData,
  data,
}) => {
  const [limit, setLimit] = useState(10);
  useEffect(() => {
    if (!containerLoading && data.needsReload) {
      loadBenchmarkData({
        partnerNumber,
        sensorIds: syntheticSensors.map(syntheticSensors => syntheticSensors.id),
      });
    }
  }, [containerLoading, syntheticSensors, data.needsReload]);

  const generateFilterSelection = () => {
    if (benchmarkingData) {
      const options = map(range(5, benchmarkingData.length, 5), index => ({
        label: `${index} ${index > 1 ? t('buildings') : t('building')}`,
        value: index,
      }));
      options.push({
        label: `${benchmarkingData.length} ${benchmarkingData.length > 1 ? t('buildings') : t('building')}`,
        value: benchmarkingData.length,
      });
      return options;
    }
    return [];
  };
  if (!visible) {
    return null;
  }
  if (data.error.status) {
    return <ErrorBoundaryFallback />;
  }
  if (containerLoading || data.isLoading) {
    return <BenchmarkTabSkeleton />;
  }
  const filteredData = tab.filterData(data.result);
  const benchmarkingData = filteredData.map(entry => ({
    title: entry.name,
    subtitle: entry.address,
    rawValue: entry.value,
    value: formatNumber(profile.language, entry.value),
    to: `/${partnerNumber}/FunctionalLocation/${entry.functionalLocationId}/Recycling${tab.toQuery}`,
  }));
  const info = t('Last 12 Months');
  const resolveValueBackgroundColor = tab.resolveValueBackgroundColor
    ? value => tab.resolveValueBackgroundColor(theme, value)
    : undefined;
  const items = take(benchmarkingData, limit);
  const descendingItems = orderBy(items, 'rawValue', 'desc');
  const ascendingItems = orderBy(items, 'rawValue', 'asc');
  const topPerformers = tab.id === tabs.CARBON_FOOTPRINT.id ? ascendingItems : descendingItems;
  let worstPerformers;
  if (items?.length > 10) {
    worstPerformers = tab.id === tabs.CARBON_FOOTPRINT.id ? descendingItems : ascendingItems;
  }
  return (
    <Fragment>
      <Performances>
        <ListWrapper>
          <PerformanceList
            t={t}
            valueWidth={tab.valueWidth}
            valueUnit={t(tab.valueUnit)}
            resolveValueBackgroundColor={resolveValueBackgroundColor}
            title="Top performers"
            info={info}
            items={topPerformers}
            clickEnabled
          />
        </ListWrapper>
        {worstPerformers && (
          <ListWrapper>
            <PerformanceList
              t={t}
              valueWidth={tab.valueWidth}
              valueUnit={t(tab.valueUnit)}
              resolveValueBackgroundColor={resolveValueBackgroundColor}
              title="Worst performers"
              info={info}
              items={worstPerformers}
              clickEnabled
            />
          </ListWrapper>
        )}
      </Performances>
      {benchmarkingData.length > 5 && (
        <Filter>
          <FilterInfo>{t('Show')}</FilterInfo>
          <InputSelectDropdown
            t={t}
            options={generateFilterSelection()}
            onChange={(property, value) => setLimit(value)}
            model={{ limit }}
            property="limit"
            clearable={false}
          />
        </Filter>
      )}
    </Fragment>
  );
};

BenchmarkTab.propTypes = {
  t: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  containerLoading: PropTypes.bool,
  tab: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  partnerNumber: PropTypes.string,
  syntheticSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  loadBenchmarkData: PropTypes.func.isRequired,
  data: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      status: PropTypes.bool.isRequired,
      message: PropTypes.string,
    }).isRequired,
    needsReload: PropTypes.bool.isRequired,
    result: PropTypes.array,
  }).isRequired,
};

export default withRouter(translations(withTheme(connect(BenchmarkTab))));
