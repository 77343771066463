import PropTypes from 'prop-types';
import styled from 'styled-components';

const InsightMessageListItem = styled.li`
  margin: var(--size-sm);
  &::before {
    content: '\\2012\\2002';
  }
`;
InsightMessageListItem.displayName = 'InsightMessageListItem';

const InsightMessageBody = ({ rawInsightMessage }) => {
  const lines = rawInsightMessage ? rawInsightMessage.split('\n') : [];
  return (
    <>
      {lines.length > 0 && <p>{lines[0]}</p>}
      <ul>
        {lines.splice(1).map((line, idx) => (
          <InsightMessageListItem key={idx}>{line}</InsightMessageListItem>
        ))}
      </ul>
    </>
  );
};
InsightMessageBody.propTypes = {
  rawInsightMessage: PropTypes.string,
};

export default InsightMessageBody;
