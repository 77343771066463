import PropTypes from 'prop-types';
import InfoBox from 'components/InfoBox/InfoBox';
import InputBooleanCheckbox from 'components/Form/InputBooleanCheckbox';
import { ContentType } from 'constants/maintenance';
import {
  ConfigurationModalHeader,
  ConfigurationList,
  ConfigurationListItem,
} from 'components/Dialog/DialogConfiguration';

const BasicColumnList = ({ t, columns, onCheckbox, contentType }) => {
  const isPlannedMaintenance = contentType === ContentType.PLANNED;
  return (
    <>
      <ConfigurationModalHeader>{t('Select columns')}</ConfigurationModalHeader>
      <ConfigurationList>
        {columns.map(([field, config]) => (
          <ConfigurationListItem key={field}>
            <InputBooleanCheckbox
              id={field}
              property={field}
              onChange={onCheckbox}
              label={config.label}
              value={config.selected}
              disabled={config.disabled}
            />
          </ConfigurationListItem>
        ))}
      </ConfigurationList>
      {isPlannedMaintenance && <InfoBox>{t('listConfiguration.infoText.plannedMaintenance')}</InfoBox>}
    </>
  );
};

BasicColumnList.propTypes = {
  t: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  onCheckbox: PropTypes.func.isRequired,
  contentType: PropTypes.oneOf(Object.values(ContentType)),
};

export default BasicColumnList;
