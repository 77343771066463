import PropTypes from 'prop-types';
import get from 'lodash/get';
import InputText from './InputText';

export const InputPassword = props => {
  const value = get(props.model, props.property) || '';

  return (
    <InputText
      type="password"
      id={props.id}
      value={value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      readonly={props.readonly}
      required={props.required}
      property={props.property}
    />
  );
};

InputPassword.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  property: PropTypes.string,
  model: PropTypes.object,
};

export default InputPassword;
