import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { lighten, readableColor } from 'polished';

const getWidth = ({ showFullText, small, roundInMobile, tagWidth }, isMobile) => {
  if (tagWidth) {
    return tagWidth;
  }
  if (isMobile) {
    if (showFullText) {
      return 'auto';
    }
    if (roundInMobile) {
      return '20px';
    }
  }

  if (showFullText) {
    return 'auto';
  }
  if (small) {
    return '20px';
  }
  return '40px';
};

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => getWidth(props, true)};
  ${props => (props.showFullText ? 'padding: var(--size-xxxxs) var(--size-xs);' : '')}
  height: ${props => props.roundInMobile && '20px'};
  border-radius: ${props => (props.showFullText || !props.roundInMobile ? '2px' : '50%')};
  line-height: 16px;
  text-align: center;
  font-size: ${props => (props.showFullText ? props.theme.fontSize.xxs : props.theme.fontSize.xxxs)};
  color: ${props =>
    props.textColor ??
    (props.backgroundColor
      ? readableColor(props.backgroundColor, props.theme.colors.black, props.theme.colors.white)
      : props.theme.colors.white)};
  font-weight: ${props => props.theme.font.weight.normal};
  background-color: ${props => props.backgroundColor};
  border: 1px solid ${props => props.borderColor ?? props.backgroundColor};
  cursor: ${props => (props.onClick || props.hasCursor) && 'pointer'};

  &:hover {
    ${props =>
      (props.onClick || props.hasCursor) &&
      css`
        background-color: ${props.backgroundHoverColor
          ? lighten(0.3, props.backgroundHoverColor)
          : lighten(0.3, props.backgroundColor)};
        border-color: ${props.borderHoverColor};
        color: ${props.textHoverColor};
      `}
  }

  ${props => props.theme.media.landscape`
    width: ${props => getWidth(props, false)};
    border-radius: 2px;
    line-height: 1.5;
  `}
`;
Tag.displayName = 'Tag';

Tag.defaultProps = {
  roundInMobile: true,
};

Tag.propTypes = {
  textColor: PropTypes.string,
  textHoverColor: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  backgroundHoverColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderHoverColor: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  hasCursor: PropTypes.bool,
  showFullText: PropTypes.bool,
  roundInMobile: PropTypes.bool,
  tagWidth: PropTypes.string,
};

export default Tag;
