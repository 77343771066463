import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { SERVICE_API_PATH } from './index';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';
import ApiRequest from 'utils/Fetch/ApiRequest';

export const invalidateMostServiceOrderCaches = () =>
  Promise.all([invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/service-orders`)]);

export const serviceOrdersFn = (
  request,
  { partnerNumber, functionalLocation, serviceOrderNumber, equipmentNumber, startDate, endDate, status, refreshCache }
) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/service-orders`,
    query: { partnerNumber, functionalLocation, serviceOrderNumber, equipmentNumber, startDate, endDate, status },
    refreshCache,
  });

export const serviceOrdersExportFn = (
  request,
  { partnerNumber, functionalLocation, serviceOrderNumber, equipmentNumber, startDate, endDate }
) =>
  request.download({
    method: 'POST',
    apiPath: `${SERVICE_API_PATH}/service-orders/export`,
    data: {
      partnerNumber,
      functionalLocation,
      serviceOrderNumber,
      equipmentNumber,
      startDate,
      endDate,
    },
  });

export const serviceOrdersExportForUserFn = (
  request,
  startDate,
  endDate,
  partnerNumber,
  functionalLocation,
  serviceOrderNumber
) =>
  request.download({
    method: 'POST',
    apiPath: `${SERVICE_API_PATH}/service-orders/export/for-user`,
    data: { startDate, endDate, partnerNumber, functionalLocation, serviceOrderNumber },
  });

export const serviceOrdersForUserFn = (
  request,
  { startDate, endDate, partnerNumber, functionalLocation, onlyHavingChecklist, refreshCache }
) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/service-orders/for-user`,
    query: { startDate, endDate, partnerNumber, functionalLocation, onlyHavingChecklist },
    refreshCache,
  });

export const serviceOrdersSearch = ({ keyword, partnerNumber, functionalLocation, limit, refreshCache }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/service-orders/search`,
    query: { keyword, partnerNumber, functionalLocation, limit },
    refreshCache,
  });

export const serviceOrdersCounts = ({ startDate, endDate, partnerNumber, functionalLocation, status, excludeStatus }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/service-orders/counts`,
    query: { startDate, endDate, partnerNumber, functionalLocation, status, excludeStatus },
  });

export const serviceOrderOperationsFn = (request, serviceOrderNumber, functionalLocation) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/service-orders/${serviceOrderNumber}/operations`,
    query: { functionalLocation },
  });

export const serviceOrderServiceManager = ({ serviceOrderNumber }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/service-orders/${serviceOrderNumber}/service-manager`,
  });

export const serviceOrderWithTokenFn = (request, token, refreshCache) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/service-orders/tokens/${token}`,
    refreshCache,
    useAuthorization: false,
  });

export const createServiceOrderRequestAsAnonymousUserFn = (request, { serviceRequest, publicView, language }) =>
  request.post({
    apiPath: `${SERVICE_API_PATH}/service-orders/requests`,
    data: {
      ...serviceRequest,
      language,
      publicViewId: publicView.id,
    },
    noCache: true,
    useAuthorization: false,
  });

export const upsertServiceOrder = serviceOrder =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/service-orders`,
    data: serviceOrder,
  });

export const assignServiceOrder = serviceOrderNumber =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/service-orders/${serviceOrderNumber}/assign`,
    noCache: true,
  });

export const updateServiceOrderStatus = ({ serviceOrderNumber, status, comment, resend }) =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/service-orders/${serviceOrderNumber}/status`,
    data: { serviceOrderNumber, status, comment, resend },
  });

export const updateServiceOrderStatusWithToken = ({ token, status, comment, createdBy, language }) =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/service-orders/tokens/${token}/status`,
    data: { token, status, comment, createdBy, language },
    useAuthorization: false,
  });

export default wrapEachWithThunkRequest({
  serviceOrders: serviceOrdersFn,
  serviceOrdersForUser: serviceOrdersForUserFn,
  serviceOrderOperations: serviceOrderOperationsFn,
  serviceOrdersExport: serviceOrdersExportFn,
  serviceOrdersExportForUser: serviceOrdersExportForUserFn,
  serviceOrderWithToken: serviceOrderWithTokenFn,
  createServiceOrderRequestAsAnonymousUser: createServiceOrderRequestAsAnonymousUserFn,
});
