import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'utils/Date/dateFormatter';
import { Link } from 'react-router-dom';

import { useTranslations } from 'decorators/Translations/translations';

const BoxBase = styled.div`
  padding: ${props => props.theme.section.verticalSpacing} var(--size-md);
  border: var(--border-thin) solid ${props => (props.active ? 'var(--box-bc-active)' : 'var(--box-bc)')};
  background-color: ${props => (props.active ? 'var(--box-bg-active)' : 'var(--box-bg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  &:hover {
    border-color: var(--box-bc-active);
    background-color: var(--box-bg-active);
  }
`;

const DaySelector = styled(BoxBase)`
  flex-direction: row;
  justify-content: space-between;
  cursor: default;
  margin: 0;
`;

const DayLink = styled(Link)`
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  color: var(--link-fg);
  text-transform: uppercase;

  &:hover {
    color: var(--link-fg-hover);
  }

  ${props => props.theme.media.portrait`
    font-size: ${props => props.theme.font.size.xxs};
  `}
`;

const DayHeading = styled.h4`
  color: var(--subheader-fg);
  margin-bottom: -0.2em;
  font-size: ${props => props.theme.font.size.sm};

  ${props => props.theme.media.portrait`
    font-size: ${props => props.theme.font.size.md};
  `}
`;

const YearLink = styled(Link)`
  color: var(--link-fg);
  &:hover {
    color: var(--link-fg-hover);
  }
`;

const CalendarDaySelector = ({ date, year, yearLink, previousDayLink, nextDayLink }) => {
  const [t] = useTranslations();
  return (
    <DaySelector>
      <DayLink to={previousDayLink}>← {t('Previous')}</DayLink>
      <DayHeading>
        {format(date, 'LLLL do')}, <YearLink to={yearLink}>{year}</YearLink>
      </DayHeading>
      <DayLink to={nextDayLink}>{t('Next')} →</DayLink>
    </DaySelector>
  );
};

CalendarDaySelector.propTypes = {
  date: PropTypes.object,
  year: PropTypes.number,
  yearLink: PropTypes.string,
  previousDayLink: PropTypes.string,
  nextDayLink: PropTypes.string,
};

export default CalendarDaySelector;
