import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useTranslations } from 'decorators/Translations/translations';

import Svg from 'components/Svg/Svg';

const hideOnMobile = css`
  display: none;
  ${props => props.theme.media.portrait`
    display: flex;
  `}
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  margin: 0;
  cursor: pointer;
  color: var(--btn-default-fg);
  font-size: ${props => props.theme.iconSize.sm};
  background-color: ${props => (props.isActive ? 'var(--blue-02)' : 'transparent')};
  vertical-align: middle;
  border-radius: 50%;
  padding: var(--size-xs);

  ${props => props.hideOnMobile && hideOnMobile}

  &:hover {
    background-color: var(--btn-icon-bg-hover);
  }

  ${props =>
    props.disabled &&
    css`
      cursor: default;

      svg {
        fill: var(--grey-40);
      }

      &:hover {
        background-color: var(--black-010);
      }
    `}
`;

const Icon = styled(Svg)`
  pointer-events: none;
`;

const IconButton = ({ iconName, title, ...props }) => {
  const [t] = useTranslations();

  return (
    <Button {...props} title={title ? t(title) : ''}>
      <Icon name={iconName} />
    </Button>
  );
};

IconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default IconButton;
