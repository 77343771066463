import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInfoRow = styled.p`
  font-weight: ${props => (props.bold ? props.theme.fontWeight.bold : props.theme.fontWeight.normal)};
  margin-bottom: ${props => (props.bold ? 'var(--size-xxs)' : 0)};
`;
StyledInfoRow.displayName = 'StyledInfoRow';

const StyledInfoContainer = styled.div`
  color: var(--grey-100);
  line-height: ${props => props.theme.lineHeight.text};
  flex: 1 1 0;
`;
StyledInfoContainer.displayName = 'StyledInfoContainer';

const InsightMessageInfoContainer = ({ rows, linkUrl = null }) => {
  return (
    <StyledInfoContainer>
      <StyledInfoRow bold={true}>{rows.length > 0 ? rows[0] : ''}</StyledInfoRow>
      {rows.splice(1).map((row, idx) =>
        linkUrl === null ? (
          <StyledInfoRow key={idx}>{row}</StyledInfoRow>
        ) : (
          <Link to={linkUrl} key={idx}>
            {idx >= rows.length ? (
              <StyledInfoRow>{`${row} \u27F6`}</StyledInfoRow>
            ) : (
              <StyledInfoRow>{row}</StyledInfoRow>
            )}
          </Link>
        )
      )}
    </StyledInfoContainer>
  );
};
InsightMessageInfoContainer.propTypes = {
  rows: PropTypes.array,
  linkUrl: PropTypes.string,
};

export default InsightMessageInfoContainer;
