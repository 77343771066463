import PropTypes from 'prop-types';

import Section from 'components/Section/Section';
import { InsightTable } from 'components/Insights/Tables/InsightTable';

import { getSuccessInsightMessage } from 'components/Insights/utils';
import { useTranslations } from 'decorators/Translations/translations';
import { getTitle } from './FloorPlanSection';

const SORT_BY = [
  { id: 'status', desc: true },
  { id: 'createdAt', desc: true },
];
const PAGE_SIZE = 3;
const EMPTY_ARRAY = [];

const InsightSection = ({ floor, openInsightDialog, insightMessages = EMPTY_ARRAY, loading, error, hasInsights }) => {
  const [t] = useTranslations();

  if (!hasInsights) {
    return null;
  }

  const floorInsightMessages = insightMessages.filter(msg => msg.sensorHierarchyId === floor.id);

  // Add success message if there are no active insight messages
  const shouldAddSuccessMessage = !floorInsightMessages.some(message => message.active);
  const tableInsightMessages = floorInsightMessages.concat(
    shouldAddSuccessMessage ? getSuccessInsightMessage('floor', getTitle(floor)) : []
  );

  return (
    <Section>
      <InsightTable
        insightMessages={tableInsightMessages}
        loading={loading}
        error={error}
        onClick={openInsightDialog}
        pageSize={PAGE_SIZE}
        initialSortBy={SORT_BY}
        removeHead
        t={t}
      />
    </Section>
  );
};

InsightSection.propTypes = {
  floor: PropTypes.object.isRequired,
  openInsightDialog: PropTypes.func,
  insightMessages: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  hasInsights: PropTypes.bool,
};

export default InsightSection;
