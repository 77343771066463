import useResponsive from 'components/Responsive/useResponsive';

const useChartDimensions = (containerRef, propsWidth, propsHeight) => {
  const dimensions = useResponsive(containerRef);
  const chartWidth = propsWidth ? propsWidth : null;
  const chartHeight = propsHeight ? propsHeight : (dimensions?.height ?? null);

  return [chartWidth, chartHeight];
};

export default useChartDimensions;
