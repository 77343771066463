import { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import Blueprint from 'components/Blueprint/Blueprint';
import Svg from 'components/Svg/Svg';

import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { controlsCss } from 'components/Blueprint/utils';
import { useTranslations } from 'decorators/Translations/translations';

const ControlsWrapper = styled.div`
  display: flex;
  ${controlsCss}
`;
ControlsWrapper.displayName = 'ControlsWrapper';

const ExpandButton = styled.button`
  padding: 0;
  margin: 0 0 0 var(--size-md);
  border: 0;
  background: ${props => props.theme.colors.white};
  cursor: pointer;

  &:hover svg {
    fill: ${props => props.theme.colors.blue};
  }
`;
ExpandButton.displayName = 'ExpandButton';

const Icon = styled(Svg)`
  fill: ${props => props.theme.colors.darkGray};
  font-size: 2em;
`;
Icon.displayName = 'Icon';

export const getTitle = floor => {
  if (floor.title) {
    return floor.title;
  }
  if (floor.number || floor.number === 0) {
    return floor.number;
  }
  return '';
};

const FloorPlanSection = ({
  floor,
  loading,
  onSensorClick,
  renderFloorSelector,
  isExpanded = false,
  toggleExpand,
  content,
  selectedTab,
}) => {
  const [t] = useTranslations();
  const controlsRef = useRef();

  // image size
  const imageSize = useMemo(
    () => ({ width: floor.imageWidth, height: floor.imageHeight }),
    [floor.imageWidth, floor.imageHeight]
  );
  // Get togglable feature group names from area titles
  const featureGroupNames = useMemo(
    () => floor.areas.features.map(feature => feature.properties.title),
    [floor.areas.features]
  );
  // Map areas to their own feature groups so they can have their own colors and filtering
  const areaFeatureGroups = useMemo(
    () =>
      floor.areas.features.map(feature => {
        return [
          {
            type: 'FeatureCollection',
            features: [feature],
          },
        ];
      }),
    [floor.areas.features]
  );

  return (
    <Section>
      <SectionHeader title={`${t('Floor')} ${getTitle(floor)}`} ctxHelp={`${CTXHELP_PREFIX} Floor`} size="small">
        <ControlsWrapper>
          <div ref={controlsRef} className="ol-controls" />
          <ExpandButton onClick={toggleExpand}>
            <Icon name="fullscreen" />
          </ExpandButton>
        </ControlsWrapper>
      </SectionHeader>
      {floor.image && (
        <Blueprint
          t={t}
          onSensorClick={onSensorClick}
          image={floor.image}
          imageSize={imageSize}
          number={floor.number}
          title={floor.title}
          featureGroupNames={featureGroupNames}
          featureGroups={[...areaFeatureGroups, [floor.sensors]]}
          loading={loading}
          controlsRef={controlsRef}
          isExpanded={isExpanded}
          toggleExpand={toggleExpand}
          renderFloorSelector={renderFloorSelector}
          content={content}
          selectedTab={selectedTab}
          isZoomable
        />
      )}
    </Section>
  );
};

FloorPlanSection.propTypes = {
  floor: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    number: PropTypes.number,
    imageHeight: PropTypes.number,
    imageWidth: PropTypes.number,
    sensors: PropTypes.shape({
      type: PropTypes.string,
      features: PropTypes.arrayOf(PropTypes.object),
    }),
    areas: PropTypes.shape({
      type: PropTypes.string,
      features: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  renderFloorSelector: PropTypes.func.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      performance: PropTypes.string,
      title: PropTypes.string,
      toolTipContent: PropTypes.node,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedTab: PropTypes.object,
  isExpanded: PropTypes.bool,
  onSensorClick: PropTypes.func,
};
export default FloorPlanSection;
