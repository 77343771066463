import { createReducerFromMapping } from 'redux/utils/index';
import IoTService from 'services/iot';
import { getBrowserTimeZone } from 'utils/Date/date';

const initialState = {
  loading: false,
  utilizationRateChartValues: {},
};

export const LOAD_UTILIZATION_RATE_CHART_VALUES = 'CUSTOMER_PLATFORM/IoT/LOAD_UTILIZATION_RATE_CHART_VALUES';
export const LOAD_UTILIZATION_RATE_CHART_VALUES_SUCCESS =
  'CUSTOMER_PLATFORM/IoT/LOAD_UTILIZATION_RATE_CHART_VALUES_SUCCESS';
export const LOAD_UTILIZATION_RATE_CHART_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT/LOAD_UTILIZATION_RATE_CHART_VALUES_FAIL';

export const loadUtilizationRateChartValues = ({
  sensorGroups,
  startTime,
  endTime,
  utilizationHours,
  isArea,
  publicViewId,
  floorId,
}) => {
  const aggregation = 'hourlyUtilizationRate';
  const timeZone = getBrowserTimeZone();

  return async dispatch => {
    dispatch({ type: LOAD_UTILIZATION_RATE_CHART_VALUES });
    try {
      const result = {};
      for (const sensorGroup of sensorGroups) {
        const sensorIds = sensorGroup.sensors.map(sensor => sensor.id);
        if (publicViewId) {
          result[sensorGroup.name] = IoTService.utilizationRateChartValuesWithPublicView({
            sensorIds,
            startDate: startTime.toISOString(),
            endDate: endTime.toISOString(),
            utilizationHours,
            timeZone,
            aggregation,
            createSummaryPerSensor: isArea,
            publicViewId,
            floorId,
          });
        } else {
          result[sensorGroup.name] = IoTService.utilizationRateChartValues({
            sensorIds,
            startDate: startTime.toISOString(),
            endDate: endTime.toISOString(),
            utilizationHours,
            timeZone,
            aggregation,
            createSummaryPerSensor: isArea,
          });
        }
      }
      for (const [group, response] of Object.entries(result)) {
        result[group] = await response;
      }
      return dispatch({
        type: LOAD_UTILIZATION_RATE_CHART_VALUES_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_UTILIZATION_RATE_CHART_VALUES_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_UTILIZATION_RATE_CHART_VALUES]: state => ({
      ...state,
      loading: true,
      utilizationRateChartValues: {},
    }),
    [LOAD_UTILIZATION_RATE_CHART_VALUES_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      utilizationRateChartValues: action.result,
    }),
    [LOAD_UTILIZATION_RATE_CHART_VALUES_FAIL]: state => ({
      ...state,
      loading: false,
    }),
  },
  initialState
);
