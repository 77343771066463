import { useCallback } from 'react';
import { StyledTableBody } from './FlexTableBody';
import { SkeletonRow } from './FlexTableSkeleton';

const useRenderers = options => {
  const {
    rows,
    isRowSelectable,
    tableInstance,
    onClick,
    customRowHeight,
    showAll,
    RowComponent,
    CellComponent,
    handleRowSelection,
  } = options;

  const hasSelectionChangeHandler = typeof handleRowSelection === 'function';

  const RenderRow = useCallback(
    (index, key) => {
      const row = rows[index];
      tableInstance.prepareRow(row);
      const rowSelectable =
        hasSelectionChangeHandler && (typeof isRowSelectable !== 'function' || isRowSelectable(row));
      if (row.original.renderSkeleton) {
        return <SkeletonRow {...row.getRowProps({ key })} columns={row.cells}></SkeletonRow>;
      }
      return (
        <RowComponent
          {...row.getRowProps({ key })}
          isSelectable={rowSelectable}
          isExpanded={!!row.isExpanded}
          original={row.original}
          depth={row.depth}
          onClick={rowSelectable ? () => handleRowSelection(row) : undefined}
        >
          {row.cells.map(cell => (
            <CellComponent
              columnId={cell.column.id}
              {...cell.getCellProps()}
              style={cell.column.style}
              hideOnMobile={cell.column.hideOnMobile}
              fullWidthOnMobile={cell.column.fullWidthOnMobile}
              onClick={!rowSelectable && onClick ? event => onClick(event, cell) : undefined}
            >
              {cell.render('Cell')}
            </CellComponent>
          ))}
        </RowComponent>
      );
    },
    [
      isRowSelectable,
      onClick,
      rows,
      hasSelectionChangeHandler,
      tableInstance.prepareRow,
      tableInstance.selectedFlatRows,
      handleRowSelection,
    ]
  );

  const RenderBody = useCallback(
    (items, ref) => (
      <div ref={ref}>
        <StyledTableBody
          rowHeight={customRowHeight}
          {...tableInstance.getTableBodyProps({ rowCount: rows.length, showAll })}
        >
          {items}
        </StyledTableBody>
      </div>
    ),
    [customRowHeight, rows.length, showAll, tableInstance]
  );

  return { RenderRow, RenderBody };
};

export default useRenderers;
