import { memo } from 'react';
import PropTypes from 'prop-types';

import StatusInfo from 'components/StatusInfo/StatusInfo';

const ConsumptionKPI = ({ t, kpi, heading, history, partnerNumber, formatValue, sideNaviExtended }) => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const formatTooltip = raw => {
    const { value, unit } = formatValue(raw);
    return `${value} ${unit}`;
  };
  return (
    <StatusInfo
      t={t}
      sideNaviExtended={sideNaviExtended}
      loading={kpi.loading}
      valuePosition="outside"
      precision={1}
      heading={t(heading)}
      value={formatValue(kpi.value).value}
      unit={formatValue(kpi.value).unit}
      valueNote={t('Last 12 Months')}
      items={[
        {
          label: previousYear,
          ...formatValue(kpi.previous),
          showUnit: kpi.previous !== null,
        },
        {
          label: currentYear,
          ...formatValue(kpi.current),
          showUnit: kpi.current !== null,
        },
      ]}
      chart={kpi.chart}
      onClick={() => history.push(`/${partnerNumber}/Energy`)}
      formatTooltip={formatTooltip}
    />
  );
};

ConsumptionKPI.propTypes = {
  t: PropTypes.func.isRequired,
  kpi: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  formatValue: PropTypes.func.isRequired,
  sideNaviExtended: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  partnerNumber: PropTypes.string.isRequired,
};

export default memo(ConsumptionKPI);
