import { lazy, Suspense } from 'react';
import { FullScreenLoader } from 'components/Loader/Loader';

const Maintenance = lazy(
  () =>
    import(
      /* webpackChunkName: "service-calendar" */
      'containers/Application/Maintenance/Maintenance'
    )
);

const LazyLoadedMaintenance = props => (
  <Suspense fallback={<FullScreenLoader size="LARGE" />}>
    <Maintenance {...props} />
  </Suspense>
);

export default LazyLoadedMaintenance;
