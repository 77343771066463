import { Children, cloneElement } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export const Row = styled.div`
  width: 100%;
  margin: ${props => (!props.gray ? 'var(--size-xs)' : '')} 0;
  align-items: center;

  ${props =>
    props.gray
      ? css`
          display: flex;
          background-color: var(--grey-08);
          border: var(--border-thin) solid var(--table-row-bc);
          padding: var(--size-xs);

          &:not(:first-child) {
            border-top: none;
          }

          &:nth-child(2) {
            ${props => (!props.fullRow ? 'border-top: var(--border-thin) solid var(--table-row-bc)' : '')};
          }

          & > * {
            flex: 1;
          }

          & > *:last-child {
            margin-left: var(--size-xs);
          }
        `
      : css`
          & > * {
            width: 100%;
          }
        `}

  & > label {
    line-height: 2.5em;
    display: inline-block;
  }

  & > button {
    width: auto; /* width: initial does not work in IE */
  }

  ${props => props.theme.media.portrait`
    ${props => (!props.dense && !props.gray ? 'margin: var(--size-md) 0;' : '')}

    ${props =>
      !props.fullRow &&
      `
        width: ${props.gray ? '50%' : '48%'};

        &:nth-of-type(odd) {
          margin-right: ${!props.gray && '2%'};
          border-right: ${props.gray && 'none'};
        }

        &:nth-of-type(even) {
          margin-left: ${!props.gray && '2%'};
        }
    `}
  `}
`;
Row.displayName = 'Row';

function propagateDownwards(required, children) {
  if (children === undefined) {
    return [];
  }

  return Children.map(children, child => {
    if (typeof child === 'string' || !child) {
      return child;
    }

    const newProps = {};

    if (child.props !== undefined && child.props.id !== undefined) {
      newProps.required = required;
    }

    const grandchildren = propagateDownwards(required, child.props?.children);

    return cloneElement(child, newProps, grandchildren);
  });
}

export const InputRow = ({ required = false, children, className = '', dense, ...other }) => {
  const newChildren = propagateDownwards(!!required, children);

  return (
    <Row className={`inline-row ${className}`} dense={dense} {...other}>
      {newChildren}
    </Row>
  );
};
InputRow.displayName = 'InputRow';

InputRow.propTypes = {
  required: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  fullRow: PropTypes.bool,
  dense: PropTypes.bool,
  gray: PropTypes.bool,
};

export default InputRow;
