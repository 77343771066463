import ProfileService from 'services/profile';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import Page from './Page';

export const TermsOfServicePage = () => {
  const { t } = useUnauthenticatedTranslations();
  return <Page title={t('Terms and Conditions')} fetchTerms={ProfileService.termsOfService} />;
};

export default TermsOfServicePage;
