import PropTypes from 'prop-types';
import useQuery from './useQuery';

const withQuery = WrappedComponent => {
  const QueryComponent = props => {
    const { query, setQuery } = useQuery();
    return <WrappedComponent query={query} setQuery={setQuery} {...props} />;
  };

  QueryComponent.propTypes = {
    query: PropTypes.object,
    setQuery: PropTypes.func,
  };

  return QueryComponent;
};

export default withQuery;
