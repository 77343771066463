import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { getBuildingTimezone, getOpeningHours } from 'utils/Data/performance';

const EMPTY_ARRAY = [];

export const useBuildingOpenHours = functionalLocationId => {
  const buildingMeta = useSelector(state => state.buildingMeta.meta[functionalLocationId] || EMPTY_ARRAY);
  const buildingHierarchy = useSelector(
    state => state.sensorHierarchy.buildingHierarchy[functionalLocationId]?.[0] || EMPTY_ARRAY
  );
  const functionalLocationOpenHours = useSelector(state => state.functionalLocationOpenHours.data);

  return useMemo(() => {
    let openHours;
    let timezone;
    if (!isEmpty(functionalLocationOpenHours)) {
      openHours = sortBy([...functionalLocationOpenHours], 'isoDayOfWeek').map(({ opens, closes }) => [opens, closes]);
      timezone = functionalLocationOpenHours[0].timezone;
    } else {
      openHours = getOpeningHours(buildingHierarchy, buildingMeta);
      timezone = getBuildingTimezone(functionalLocationId, buildingMeta);
    }
    return [openHours, timezone];
  }, [functionalLocationOpenHours, buildingHierarchy, buildingMeta, functionalLocationId]);
};

export default useBuildingOpenHours;
