import { lazy, Suspense } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { StandardPage } from 'components/index';
import Header from 'containers/Application/Header/Header';
import FadeTransition from 'components/FadeTransition/FadeTransition';
import { useTranslations } from 'decorators/Translations/translations';
import useEditUser from 'containers/Application/Admin/EditUser/useEditUser';
import withNotification from 'decorators/Notification/withNotification';
import { icons } from 'components/Icon/IconNames';
import { FullScreenLoader } from 'components/Loader/Loader';
import { GO_BACK_LINK } from 'containers/Application/Admin/utils';

// Import UserForm dynamically as part of the admin bundle to prevent it from leaking to the main bundle
const UserForm = lazy(
  () =>
    import(
      /* webpackChunkName: "admin" */
      'containers/Application/Admin/UserForm/UserForm'
    )
);

const Profile = ({ showNotification }) => {
  const [t] = useTranslations();
  const profile = useSelector(state => state.profile.profile);

  const [saving, saved, loadingPermissions, setProperty, model, save] = useEditUser(profile.id, showNotification);

  const links = [GO_BACK_LINK, { title: 'Your Profile', icon: icons.ADMIN_MANAGE_USERS, pathname: '/Profile' }];

  if (!model) {
    return null;
  }

  return (
    <FadeTransition>
      <StandardPage withTabs>
        <Helmet title={t('My Profile')} />
        <Header t={t} links={links} selected="your profile" />
        <Suspense fallback={<FullScreenLoader size="LARGE" />}>
          <UserForm
            id="editProfileForm"
            profileId={profile.id}
            model={model}
            setProperty={setProperty}
            submit={save}
            t={t}
            saving={saving}
            saved={saved}
            loadingPermissions={loadingPermissions}
            showNotification={showNotification}
          />
        </Suspense>
      </StandardPage>
    </FadeTransition>
  );
};

Profile.propTypes = {
  showNotification: PropTypes.func,
};

export default withNotification(Profile);
