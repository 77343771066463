import PropTypes from 'prop-types';

import OPICards from './OPICards';
import OPICard from './OPICard';

const OPICardsSkeleton = ({ numberOfOPICards }) => {
  const cards = [];
  for (let i = 1; i <= numberOfOPICards; i++) {
    cards.push(<OPICard key={`skeleton-opi-card-${i}`} loading />);
  }

  return <OPICards>{cards}</OPICards>;
};

OPICardsSkeleton.propTypes = {
  // how many loading OPI cards skeleton should show
  numberOfOPICards: PropTypes.number.isRequired,
};

export default OPICardsSkeleton;
