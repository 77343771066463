import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledValuesBoxComparisonValue = styled.div`
  padding: var(--size-md);

  ${props => props.theme.media.desktop`
    flex: 1;
    padding: var(--size-xxs);
    text-align: center;
  `}
`;
StyledValuesBoxComparisonValue.displayName = 'StyledValuesBoxComparisonValue';

const Value = styled.div`
  font-size: ${props => props.theme.fontSize.md};
  color: ${props => props.valueColor};
  line-height: 1;

  ${props => props.theme.media.desktop`
  font-size: ${props => props.theme.fontSize.sm};
  `}
`;
Value.displayName = 'Value';

const Label = styled.div`
  font-size: ${props => props.theme.fontSize.xxs};
  color: var(--supplemental-text-fg);
  line-height: var(--line-height-tight);
  margin-top: var(--size-xxs);

  ${props => props.theme.media.desktop`
    margin-top: 0;
  `}
`;
Label.displayName = 'Label';

const ValuesBoxComparisonValue = ({ value, label, valueColor, ...others }) => (
  <StyledValuesBoxComparisonValue {...others}>
    {value && <Value valueColor={valueColor}>{value}</Value>}
    {label && <Label>{label}</Label>}
  </StyledValuesBoxComparisonValue>
);

ValuesBoxComparisonValue.defaultProps = {
  value: '',
  label: '',
};

ValuesBoxComparisonValue.propTypes = {
  /** Value with unit */
  value: PropTypes.string,
  valueColor: PropTypes.string,
  /** Value label */
  label: PropTypes.string,
};

export default ValuesBoxComparisonValue;
