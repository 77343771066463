import { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import map from 'lodash/map';
import take from 'lodash/take';
import takeRight from 'lodash/takeRight';
import sortBy from 'lodash/sortBy';

import PerformanceList from 'components/PerformanceList/PerformanceList';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';

const StyledBenchmarking = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-lg);

  ${props => props.theme.media.landscape`
    flex-direction: column;
  `}
`;
StyledBenchmarking.displayName = 'StyledBenchmarking';

const ListWrapper = styled.div`
  flex: 1;
`;

const Performances = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${props => props.theme.media.landscape`
    flex-flow: row nowrap;
    justify-content: space-between;
  `};
`;
Performances.displayName = 'Performances';

const Filter = styled.div`
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Select-control {
    width: 300px;
  }
  ${props => props.theme.media.landscape`
    flex-direction: row;
  `}
`;
Filter.displayName = 'Filter';

const FilterInfo = styled.span`
  color: var(--supplemental-text-fg);
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.default};
  text-transform: uppercase;
  margin-bottom: 1em;
  ${props => props.theme.media.landscape`
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 1em;
  `};
`;
FilterInfo.displayName = 'FilterInfo';

export class Benchmarking extends Component {
  static propTypes = {
    performers: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    energyTabEnabled: PropTypes.bool.isRequired,
    partnerNumber: PropTypes.string.isRequired,
    leftTitle: PropTypes.string,
    rightTitle: PropTypes.string,
    reverse: PropTypes.bool,
    valueBackgroundColor: PropTypes.string,
    decimalPrecision: PropTypes.number,
    valueFormatter: PropTypes.func,
  };

  static defaultProps = {
    decimalPrecision: 1,
    theme: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: 10,
      top: [],
      worst: [],
      filterOptions: null,
      showWorst: props.performers && props.performers.length > 10,
    };
  }

  componentDidMount() {
    this.assignPerformers();
  }

  componentDidUpdate(prevProps, prevState) {
    const { performers } = this.props;
    if (prevState.filter !== this.state.filter || prevProps.performers !== performers) {
      this.assignPerformers();
      this.setState({
        showWorst: performers && performers.length > 10,
      });
    }
  }

  assignPerformers = () => {
    const sorted = sortBy(this.props.performers, 'value');
    const top = take(sorted, this.state.filter);
    const worst = takeRight(sorted, this.state.filter);
    const filterOptions = this.generateFilterSelection();

    this.setState({ top, worst, filterOptions });
  };

  buildListItem = (entry, partnerNumber) => {
    const { valueFormatter } = this.props;
    return {
      title: entry.title,
      subtitle: entry.address,
      value: typeof valueFormatter === 'function' ? valueFormatter(entry) : this.defaultValueFormatter(entry),
      to: `/${partnerNumber}/FunctionalLocation/${entry.functionalLocation}/Energy`,
    };
  };

  defaultValueFormatter(entry) {
    const value = Number.isFinite(entry.value) ? entry.value.toFixed(this.props.decimalPrecision) : entry.value;
    return `${value ?? ''} ${entry.unit ?? ''}`;
  }

  generateFilterSelection = () => {
    const { performers, t } = this.props;
    if (performers.length < 10) {
      return [];
    }
    const options = map(range(5, performers.length, 5), index => ({
      label: `${index} ${t('Buildings')}`,
      value: index,
    }));
    options.push({
      label: `${performers.length} ${t('Buildings')}`,
      value: performers.length,
    });
    return options;
  };

  handleSelectDropdown = (property, value) => this.setState({ [property]: value });

  get performances() {
    const {
      t,
      partnerNumber,
      energyTabEnabled,
      leftTitle = 'Top performers',
      rightTitle = 'Worst performers',
      reverse,
      valueBackgroundColor,
    } = this.props;
    const { top, worst, showWorst } = this.state;
    const children = [
      <ListWrapper key="low">
        <PerformanceList
          t={t}
          title={leftTitle}
          items={top.map(performer => this.buildListItem(performer, partnerNumber))}
          clickEnabled={energyTabEnabled}
          resolveValueBackgroundColor={() => valueBackgroundColor}
        />
      </ListWrapper>,
      <ListWrapper key="high">
        <PerformanceList
          t={t}
          title={rightTitle}
          items={worst.map(performer => this.buildListItem(performer, partnerNumber)).reverse()}
          clickEnabled={energyTabEnabled}
          resolveValueBackgroundColor={() => valueBackgroundColor}
        />
      </ListWrapper>,
    ];

    if (reverse) {
      children.reverse();
    }

    if (!showWorst) {
      children.pop();
    }

    return <Performances>{children}</Performances>;
  }

  render() {
    const { t } = this.props;
    const { filter, filterOptions } = this.state;

    return (
      <StyledBenchmarking>
        {this.performances}
        {filterOptions?.length > 0 && (
          <Filter>
            <FilterInfo>{t('Show')}</FilterInfo>
            <InputSelectDropdown
              t={t}
              options={filterOptions}
              onChange={this.handleSelectDropdown}
              model={{ filter }}
              property="filter"
              clearable={false}
            />
          </Filter>
        )}
      </StyledBenchmarking>
    );
  }
}

export default withTheme(Benchmarking);
