import without from 'lodash/without';

import MasterDataService from 'services/masterData';
import { createReducerFromMapping } from 'redux/utils/index';
import { loadEquipments, loadSubEquipments } from 'redux/modules/customer/equipments';
import { loadFunctionalLocationImages, loadFunctionalLocationsImages } from 'redux/modules/customer/partnerImage';
import { loadBuildingContainer } from 'redux/modules/containers/building';
import { isBusinessUnit, isBusinessEntity, isTenant, isSystem } from 'utils/Data/functionalLocations';
import { loadSensorDataTypes } from '../customer/sensorHierarchy';
import { myFLCustomViews } from 'redux/modules/customView/customView';
import {
  loadDocumentsByFunctionalLocation,
  loadFunctionalLocations,
  loadFunctionalLocationsByPath,
  loadFolders,
  loadFiles,
  fetchConfig,
  loadFunctionalLocationsForParent,
  loadFunctionalLocationOpenHours,
  checkSriAvailability,
} from 'redux/modules';
import { isBuildingManagementEnabled } from 'utils/Data/profileData';
import subDays from 'date-fns/subDays';
import startOfHour from 'date-fns/startOfHour';
import { startOfUTCDay } from 'utils/Date/date';

const now = new Date();

const initialState = {
  // Make the default state loading so that the first load respects loading order via promise resolve
  loading: true,
  loadingChildren: true,
  loadingEquipment: true,
  loadingParents: true,
  loadingServiceOrders: true,
  error: false,
};

export const FUNCTIONAL_LOCATION_CONTAINER_LOAD = 'CUSTOMER_PLATFORM/FunctionalLocationContainer/LOAD';
export const FUNCTIONAL_LOCATION_CONTAINER_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/FunctionalLocationContainer/LOAD_SUCCESS';
export const FUNCTIONAL_LOCATION_CONTAINER_LOAD_FAIL = 'CUSTOMER_PLATFORM/FunctionalLocationContainer/LOAD_FAIL';

export const loadFunctionalLocationContainer = (functionalLocationId, features, partnerNumber) => {
  return async dispatch => {
    dispatch({ type: FUNCTIONAL_LOCATION_CONTAINER_LOAD });
    try {
      // Load functional location
      const functionalLocationResult = await dispatch(loadFunctionalLocations([functionalLocationId]));
      const functionalLocation = functionalLocationResult.result[0];

      // Check if functional location is missing due missing authorization,
      // building endpoint will return 403. Rethrow 403 for correct UI error page
      if (!functionalLocation) {
        try {
          await MasterDataService.getBuilding(functionalLocationId, 'sap');
        } catch (error) {
          if (error.statusCode === 403) {
            throw error;
          }
        }
      }

      const parents = without(functionalLocation.path, functionalLocation.functionalLocation);

      // Load custom views for side navigation
      await dispatch(myFLCustomViews(functionalLocationId));

      // Dispatch success immediately after Functional Location has loaded, this invokes render
      dispatch({ type: FUNCTIONAL_LOCATION_CONTAINER_LOAD_SUCCESS });

      // Load config for contact module
      dispatch(fetchConfig());

      const toLoad = [];

      if (parents?.length) {
        toLoad.push(
          dispatch(loadFunctionalLocationsByPath(partnerNumber, parents)).then(async ({ result = [] }) => {
            // Load images for BU and UN parents
            const FLsForImageLoad = result
              .filter(fl => isBusinessUnit(fl) || isTenant(fl))
              .map(fl => fl.functionalLocation);

            if (FLsForImageLoad.length > 0) {
              await dispatch(loadFunctionalLocationsImages(FLsForImageLoad));
            }

            if (isBusinessUnit(functionalLocation) || isTenant(functionalLocation)) {
              await dispatch(loadFunctionalLocationImages(functionalLocationId));
            }

            dispatch(setFunctionalLocationParentsLoaded());
          })
        );
      } else {
        dispatch(setFunctionalLocationParentsLoaded());
      }

      // Load equipment, if enabled.
      if (features.equipment) {
        toLoad.concat([
          dispatch(loadEquipments(functionalLocation.functionalLocation)).then(({ result = [] }) => {
            dispatch(setFunctionalLocationEquipmentLoaded());
            return [
              dispatch(
                loadSubEquipments(
                  functionalLocation.functionalLocation,
                  result.map(equipment => equipment.equipmentNumber).filter(Boolean)
                )
              ),
            ];
          }),
        ]);
      }

      // Load child-FLs, if enabled.
      if (features.technicalTab) {
        toLoad.concat([
          // Load child functional locations
          dispatch(loadFunctionalLocationsForParent(functionalLocation)).then(({ result = [] }) => {
            // Set children loaded to invoke rendering of the cards
            dispatch(setFunctionalLocationChildrenLoaded());

            // Load images for BU and UN children
            const FLsForImageLoad = result
              .filter(fl => isBusinessUnit(fl) || isTenant(fl))
              .map(fl => fl.functionalLocation);
            if (FLsForImageLoad.length > 0) {
              dispatch(loadFunctionalLocationsImages(FLsForImageLoad));
            }

            // Load equipment for disciplines
            return Promise.all(
              result.filter(fl => isSystem(fl)).map(fl => dispatch(loadEquipments(fl.functionalLocation)))
            );
          }),
        ]);
      }

      // Load documents for building or tenant, if enabled.
      if (features.files && (isBusinessUnit(functionalLocation) || isTenant(functionalLocation))) {
        toLoad.concat([
          dispatch(loadFolders(functionalLocation)),
          dispatch(loadFiles(functionalLocation.functionalLocation)),
        ]);
      }

      // Load external documents, if enabled.
      if (features.documents) {
        toLoad.concat([dispatch(loadDocumentsByFunctionalLocation(functionalLocation))]);
      }

      // load SensorDataTypes, if energy tab is enabled
      if (features.energyTab) {
        toLoad.concat([dispatch(loadSensorDataTypes())]);
      }

      // Load FL open hours
      if (isBusinessUnit(functionalLocation)) {
        toLoad.concat([dispatch(loadFunctionalLocationOpenHours(functionalLocation.functionalLocation))]);
      }

      // Check SRI report availability, if feature enabled.
      if (features.sri) {
        toLoad.concat([dispatch(checkSriAvailability(functionalLocation.functionalLocation))]);
      }

      Promise.all(toLoad);

      // Load building container (for buildings) if buildingManagement is enabled
      if (
        isBuildingManagementEnabled(features) &&
        (isBusinessUnit(functionalLocation) || isTenant(functionalLocation))
      ) {
        dispatch(
          loadBuildingContainer(
            partnerNumber,
            functionalLocation,
            startOfUTCDay(subDays(now, 6)),
            startOfHour(now),
            features
          )
        );
      }
    } catch (error) {
      return dispatch({
        type: FUNCTIONAL_LOCATION_CONTAINER_LOAD_FAIL,
        error,
      });
    }
  };
};

export const SET_FUNCTIONAL_LOCATION_CHILDREN_LOADED =
  'CUSTOMER_PLATFORM/FunctionalLocationContainer/SET_FUNCTIONAL_LOCATION_CHILDREN_LOADED';

export const setFunctionalLocationChildrenLoaded = () => ({
  type: SET_FUNCTIONAL_LOCATION_CHILDREN_LOADED,
  loadingChildren: false,
});

export const SET_FUNCTIONAL_LOCATION_PARENTS_LOADED =
  'CUSTOMER_PLATFORM/FunctionalLocationContainer/SET_FUNCTIONAL_LOCATION_PARENTS_LOADED';

export const setFunctionalLocationParentsLoaded = () => ({
  type: SET_FUNCTIONAL_LOCATION_PARENTS_LOADED,
  loadingParents: false,
});

export const SET_FUNCTIONAL_LOCATION_EQUIPMENT_LOADED =
  'CUSTOMER_PLATFORM/FunctionalLocationContainer/SET_FUNCTIONAL_LOCATION_EQUIPMENT_LOADED';

export const setFunctionalLocationEquipmentLoaded = () => ({
  type: SET_FUNCTIONAL_LOCATION_EQUIPMENT_LOADED,
  loadingEquipment: false,
});

export default createReducerFromMapping(
  {
    [FUNCTIONAL_LOCATION_CONTAINER_LOAD]: state => ({
      ...state,
      loading: true,
      loadingChildren: true,
      loadingParents: true,
      loadingEquipments: true,
      loadingServiceOrders: true,
      error: false,
    }),
    [FUNCTIONAL_LOCATION_CONTAINER_LOAD_SUCCESS]: state => ({
      ...state,
      loading: false,
    }),
    [FUNCTIONAL_LOCATION_CONTAINER_LOAD_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      loadingChildren: false,
      loadingParents: false,
      loadingEquipments: false,
      loadingServiceOrders: false,
      error: action.error,
    }),
    [SET_FUNCTIONAL_LOCATION_CHILDREN_LOADED]: state => ({
      ...state,
      loadingChildren: false,
    }),
    [SET_FUNCTIONAL_LOCATION_PARENTS_LOADED]: state => ({
      ...state,
      loadingParents: false,
    }),
    [SET_FUNCTIONAL_LOCATION_EQUIPMENT_LOADED]: state => ({
      ...state,
      loadingEquipment: false,
    }),
  },
  initialState
);
