import isEmpty from 'lodash/isEmpty';
import memoizeOne from 'memoize-one';

// Check custom views that have custom chart ids defined but no custom charts available for those
export const isCustomChartDeleted = memoizeOne(
  customView => isEmpty(customView.customChartIds) || !isEmpty(customView.customCharts)
);
export const filterCustomViewsWithDeletedCustomCharts = memoizeOne(customViews =>
  (customViews ?? []).filter(isCustomChartDeleted)
);
