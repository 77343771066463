import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import parseISO from 'date-fns/parseISO';
import { percentage } from 'utils/math';
import sumBy from 'lodash/sumBy';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import mean from 'lodash/mean';

const getEnergyRatingKPI = createSelector(
  state => state.values.energyRating.energyRating,
  energyRating => {
    const { latest, previous } = energyRating.data.averages;
    return {
      loading: energyRating.loading,
      latest,
      previous,
      positiveValue: (latest && previous && latest < previous) || false,
    };
  }
);

const getServiceOrderKPI = createSelector(
  state => state.serviceOrders.kpiCounts,
  counts => ({
    loading: counts.loading,
    value: percentage(counts.serviceOrders.completed, counts.serviceOrders.total),
  })
);

const getPlannedMaintenanceKPI = createSelector(
  state => state.serviceOrders.kpiCounts,
  counts => ({
    loading: counts.loading,
    value: percentage(counts.plannedMaintenances.completed, counts.plannedMaintenances.total),
  })
);

const getAlarmKPI = createSelector(
  (state, props) => state.alarm.byPartner[props.match.params.partnerNumber],
  state => state.alarm.byPartner.loading,
  (alarms, loading) => {
    const total = alarms ? alarms.total : null;
    const handled = alarms ? alarms.handled : null;
    return {
      loading,
      value: alarms ? percentage(handled, total) : undefined,
    };
  }
);

const getConsumptionKPI = kpi => {
  const breakdown = get(kpi, 'data.breakdown', []);
  return {
    loading: get(kpi, 'loading', false),
    value: breakdown.length ? sumBy(breakdown, 'value') : null,
    current: breakdown.length ? get(kpi, 'data.current') : null,
    previous: breakdown.length ? get(kpi, 'data.previous') : null,
    chart: {
      type: 'column',
      data: breakdown && {
        categories: breakdown.map(value => parseISO(value.timestamp)),
        values: breakdown.map(value => value.value),
      },
    },
    breakdown,
  };
};

const getElectricityKPI = createSelector(
  state => state.values.consumption.consumptionKPIs.electricity_main,
  getConsumptionKPI
);

const getCoolingKPI = createSelector(state => state.values.consumption.consumptionKPIs.cooling_main, getConsumptionKPI);

const getWaterKPI = createSelector(
  state => state.values.consumption.consumptionKPIs.water_consumption,
  getConsumptionKPI
);

const getHeatingKPI = createSelector(state => state.values.consumption.consumptionKPIs.heating_main, getConsumptionKPI);

const getEnergyConsumptionKPI = createSelector(
  getElectricityKPI,
  getHeatingKPI,
  getCoolingKPI,
  (electricity, heating, cooling) => {
    if (electricity.loading || heating.loading || cooling.loading) {
      return {
        loading: true,
        chart: {
          type: 'column',
          data: {},
        },
      };
    }

    const electricityValues = keyBy(electricity.breakdown, 'timestamp');
    const heatingValues = keyBy(heating.breakdown, 'timestamp');
    const coolingValues = keyBy(cooling.breakdown, 'timestamp');
    const values = uniq([...keys(electricityValues), ...keys(heatingValues), ...keys(coolingValues)]).map(
      timestamp =>
        get(electricityValues[timestamp], 'value', 0) +
        get(heatingValues[timestamp], 'value', 0) +
        get(coolingValues[timestamp], 'value', 0)
    );

    const noValue = electricity.value === null && heating.value === null;
    const breakdown = electricity.breakdown.length ? electricity.breakdown : heating.breakdown;
    return {
      loading: false,
      value: noValue ? null : Number(electricity.value) + Number(heating.value) + Number(cooling.value),
      chart: {
        type: 'column',
        data: breakdown && {
          categories: breakdown.map(value => parseISO(value.timestamp)),
          values,
        },
      },
    };
  }
);

const alarmCombiner = (kpi, loading, limit) => {
  const mean = kpi ? kpi.mean : null;
  return {
    loading,
    mean,
    positiveValue: mean < limit,
  };
};

const getAlarmHandlingTimeKPI = createSelector(
  (state, props) => state.alarm.handlingTimeKPI[props.match.params.partnerNumber],
  state => state.alarm.handlingTimeKPI.loading,
  () => 60,
  alarmCombiner
);

const getAlarmTimeToActionKPI = createSelector(
  (state, props) => state.alarm.timeToActionKPI[props.match.params.partnerNumber],
  state => state.alarm.timeToActionKPI.loading,
  () => 180,
  alarmCombiner
);

const getObservationKPI = createSelector(
  (state, props) => state.notice.observationKPI[props.match.params.partnerNumber],
  state => state.notice.observationKPI.loading,
  (observationKPI, loading) => {
    const total = observationKPI ? observationKPI.total : null;
    const completed = observationKPI ? observationKPI.completed : null;
    return {
      loading,
      value: observationKPI ? percentage(completed, total) : undefined,
      total,
      completed,
    };
  }
);
const getInspectionKPI = createSelector(
  (state, props) => state.notice.inspectionKPI[props.match.params.partnerNumber],
  state => state.notice.inspectionKPI.loading,
  (inspectionKPI = {}, loading) => {
    const { completed, total, sla = {} } = inspectionKPI;
    const slas = Object.keys(sla).map(key => ({
      count: sla[key].completed,
      reference: sla[key].sla,
      title: key,
    }));
    return {
      loading,
      value: inspectionKPI ? `${completed} / ${total}` : '',
      slas,
    };
  }
);

const getAirQualityKPI = createSelector(
  state => state.values.airQuality.airQualityKPI,
  kpi => {
    const flValues = kpi.data.moving7dAverage && values(kpi.data.moving7dAverage);
    const moving7dAverage = flValues && flValues.length ? mean(flValues) : null;
    return {
      loading: kpi.loading,
      value: moving7dAverage,
    };
  }
);

const getKPIs = createStructuredSelector({
  energyRating: getEnergyRatingKPI,
  serviceOrders: getServiceOrderKPI,
  plannedMaintenance: getPlannedMaintenanceKPI,
  alarms: getAlarmKPI,
  electricity: getElectricityKPI,
  water: getWaterKPI,
  cooling: getCoolingKPI,
  heating: getHeatingKPI,
  energyConsumption: getEnergyConsumptionKPI,
  observation: getObservationKPI,
  alarmHandlingTime: getAlarmHandlingTimeKPI,
  alarmTimeToAction: getAlarmTimeToActionKPI,
  inspection: getInspectionKPI,
  airQuality: getAirQualityKPI,
});

const EMPTY_ARRAY = [];

const mapStateToProps = (state, props) => ({
  profile: state.profile.profile,
  profileKpis: state.profile.profile.kpis || EMPTY_ARRAY,
  kpis: getKPIs(state, props),
  sideNaviExtended: state.navigation.sideNaviExtended,
});

export const connector = connect(mapStateToProps);
