import { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';
import { markdownImageComponent } from '../RescuePlanMarkdown/RescuePlanMarkdown';
import RescuePlanService from 'services/rescuePlan';
import DocumentStyle from '../DocumentStyle';
import { withRouter } from 'react-router-dom';

const TOCCounterSettings = styled.div`
  ol {
    counter-reset: item;
  }

  li {
    display: block;
  }

  li::before {
    content: counters(item, '.') '. ';
    counter-increment: item;
  }
`;

const ContentsStyleH1 = styled.div`
  counter-reset: heading;

  h1::before {
    content: counter(heading) '. ';
    counter-increment: heading;
  }
  h1 {
    counter-reset: subheading;
  }
  h2::before {
    content: counter(heading) '.' counter(subheading) '. ';
    counter-increment: subheading;
  }
`;

const ContentsStyleH2 = styled.div`
  counter-reset: subheading;

  h2::before {
    content: counter(subheading) '. ';
    counter-increment: subheading;
  }
`;

const Container = styled.div`
  margin: var(--size-xl);
`;

const TitleStyle = styled.h1`
  margin-bottom: var(--size-md);
  font-size: ${props => props.theme.fontSize.xl};
`;

const DocumentContents = styled(DocumentStyle)`
  display: flex;
  flex-wrap: wrap;
`;

const TOCStyle = styled(TOCCounterSettings)`
  flex: 25%;
  max-width: 25%;
  padding-right: var(--size-xl);
  column-gap: var(--size-xl);

  @media (max-width: 1200px) {
    flex: 100%;
    max-width: 100%;
  }
`;

const ContentsStyle = styled.div`
  flex: 75%;
  max-width: 75%;

  @media (max-width: 1200px) {
    flex: 100%;
    max-width: 100%;
  }
`;

const MarkdownComponents = {
  p: paragraph => {
    return markdownImageComponent(paragraph);
  },
  h1: heading => {
    const { node } = heading;

    const children = node.children[0];

    if (!children) {
      return <h1></h1>;
    }

    const text = children.value.split(' CQskuE0BkV1yjeLFLm1Z1Q==_CUSTOM_SYNTAX')[0] || children;
    const id = children.value.match('CQskuE0BkV1yjeLFLm1Z1Q==_CUSTOM_SYNTAX:heading_id{(.*)}')[1];

    if (id) {
      return <h1 id={id}>{text}</h1>;
    }
    return <h1>{text}</h1>;
  },
  h2: heading => {
    const { node } = heading;
    const children = node.children[0];

    if (!children) {
      return <h2></h2>;
    }

    const text = children.value.split(' CQskuE0BkV1yjeLFLm1Z1Q==_CUSTOM_SYNTAX')[0] || children;
    const id = children.value.match('CQskuE0BkV1yjeLFLm1Z1Q==_CUSTOM_SYNTAX:heading_id{(.*)}')[1];

    if (id) {
      return <h2 id={id}>{text}</h2>;
    }
    return <h2>{text}</h2>;
  },
};

export const RescuePlan = ({ t, planId, planLanguage, title, location }) => {
  const [rescueDocument, setRescueDocument] = useState('');
  const [rescueToc, setRescueToc] = useState('');
  const [error, setError] = useState(null);
  const [scrollOnce, setScrollOnce] = useState(true);

  const fetchDocument = async id => {
    try {
      const { document } = await RescuePlanService.documentById(id, planLanguage);
      setRescueDocument(document.contents);
      setRescueToc(document.toc);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchDocument(planId);
  }, [planId, planLanguage]);

  useEffect(() => {
    // this called only once when first opening the document and it has hash href
    if (rescueDocument !== '' && rescueToc !== '') {
      const hash = location.hash;
      const elem = document.getElementById(hash.substring(1));
      if (elem) {
        elem.scrollIntoView();
      }
    }
  }, [rescueDocument, rescueToc]);

  // Rescue documents can start heading at h2 in rare cases
  const firstHeading = rescueDocument.indexOf('# ');
  const secondHeading = rescueDocument.indexOf('## ');
  const comesFirst = firstHeading <= secondHeading;

  return (
    <Container>
      <TitleStyle>{title}</TitleStyle>
      <DocumentContents>
        <TOCStyle>
          <ReactMarkdown children={rescueToc} remarkPlugins={[remarkGfm]} />
        </TOCStyle>
        <ContentsStyle>
          {comesFirst ? (
            <ContentsStyleH1>
              <ReactMarkdown children={rescueDocument} remarkPlugins={[remarkGfm]} components={MarkdownComponents} />
            </ContentsStyleH1>
          ) : (
            <ContentsStyleH2>
              <ReactMarkdown children={rescueDocument} remarkPlugins={[remarkGfm]} components={MarkdownComponents} />
            </ContentsStyleH2>
          )}
        </ContentsStyle>
      </DocumentContents>
    </Container>
  );
};

RescuePlan.propTypes = {
  t: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  planLanguage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(translations(RescuePlan));
