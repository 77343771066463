import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

const StyledItem = styled.div`
  background: var(--draggable-list-bg);
  border: 1px solid var(--draggable-list-bc);
  padding: var(--size-xs);
  margin-bottom: ${props => props.theme.layoutSpacing.xs};
`;

const ListItem = ({ item, index }) => (
  <Draggable draggableId={item.id} index={index}>
    {provided => (
      <StyledItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        {item.content}
      </StyledItem>
    )}
  </Draggable>
);

ListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    content: PropTypes.any,
  }),
  index: PropTypes.number,
};

export default ListItem;
