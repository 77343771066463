import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

import DragHandle from 'components/Button/IconButton/DragHandle';
import { CheckboxContainer, CheckboxInput, CheckboxLabel } from 'components/Form/Checkbox';
import IconButton from 'components/Button/IconButton/IconButton';

const LockIcon = styled(props => <IconButton as="div" {...props} iconName="fa-lock" disabled />)`
  cursor: default;
  &:hover {
    background-color: var(--draggable-list-bg-checked);
  }

  svg {
    fill: var(--grey-100);
  }
`;

const ListItemStyles = styled.div`
  display: flex;
  gap: 0;
  align-items: stretch;
  width: 100%;
  margin-bottom: ${props => props.theme.layoutSpacing.sm};

  & > * {
    border-style: solid;
    border-color: ${props => (!props.checked ? 'var(--draggable-list-bc)' : 'var(--draggable-list-bc-checked)')};
    border-width: 1px 0 1px 1px;

    background-color: ${props => (!props.checked ? 'transparent' : 'var(--draggable-list-bg-checked)')};

    &:first-child {
      flex: 1;
      display: flex;
      align-items: center;
      margin: 0;
      padding: ${props => props.theme.layoutSpacing.sm};
    }

    &:last-child {
      border-radius: 0;
      border-width: 1px;
    }

    input ~ label {
      padding-left: 1.75rem;
    }
  }
`;

const FramedListItemWithHandleAndCheckbox = ({ item, index, ...restProps }) => {
  const flip = event => {
    event?.preventDefault();
    event?.stopPropagation();
    return item.onChange?.(!item.checked);
  };

  return (
    <Draggable draggableId={item.id} index={index}>
      {provided => (
        <ListItemStyles checked={item.checked} ref={provided.innerRef} {...provided.draggableProps}>
          <CheckboxContainer onClick={flip}>
            <CheckboxInput
              type="checkbox"
              id={item.id}
              checked={item.checked}
              onChange={flip}
              data-test-id={restProps['data-test-id']}
            />
            <CheckboxLabel htmlFor={item.id} className={restProps.className} labelNoWrap={false}>
              {item.content}
            </CheckboxLabel>
          </CheckboxContainer>
          <DragHandle {...provided.dragHandleProps} />
        </ListItemStyles>
      )}
    </Draggable>
  );
};

FramedListItemWithHandleAndCheckbox.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    content: PropTypes.any,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
  }),
  index: PropTypes.number,
};

export const FramedListItemLocked = ({ item }) => (
  <ListItemStyles locked checked={true}>
    <CheckboxContainer>
      <CheckboxInput readOnly type="checkbox" id={`static-${item.id}`} checked={true} />
      <CheckboxLabel htmlFor={`static-${item.id}`} disabled labelNoWrap={false}>
        {item.content}
      </CheckboxLabel>
    </CheckboxContainer>
    <LockIcon />
  </ListItemStyles>
);

FramedListItemLocked.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    content: PropTypes.any,
  }),
};

export default FramedListItemWithHandleAndCheckbox;
