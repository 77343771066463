import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const getBuilding = (externalId, externalType, { refreshCache } = {}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/buildings`,
    query: {
      externalType,
      externalId,
    },
    refreshCache,
    useAuthorization: true,
  });
