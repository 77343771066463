import { useState } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import InputText from './InputText';
import PrimaryButton from 'components/Button/PrimaryButton';

const Container = styled.form`
  display: flex;
  align-items: center;
  position: relative;
`;

const InputContainer = styled.div`
  flex-grow: 1;
`;

const ButtonContainer = styled.div`
  margin-left: var(--size-md);
`;

const INPUT_PROPS = { name: 'inputWithButton' };

const InputWithButton = ({ placeholder = '', buttonText, onSubmit }) => {
  const [text, setText] = useState('');
  const [invalid, setInvalid] = useState(false);

  const onInputChange = (_property, value) => {
    setText(value);
    setInvalid(false);
  };

  const onButtonClick = event => {
    event.preventDefault();
    const valid = event.target.form.inputWithButton.checkValidity();

    if (valid) {
      setText('');
      onSubmit(text);
    } else {
      setInvalid(true);
    }
  };

  return (
    <Container>
      <InputContainer>
        <InputText
          data-test-id="InputText"
          inputProps={INPUT_PROPS}
          type="email"
          value={text}
          onChange={onInputChange}
          placeholder={placeholder}
          invalid={invalid}
        />
      </InputContainer>
      <ButtonContainer>
        <PrimaryButton add large type="submit" data-test-id="SubmitButton" onClick={onButtonClick} disabled={!text}>
          {buttonText}
        </PrimaryButton>
      </ButtonContainer>
    </Container>
  );
};

InputWithButton.displayname = 'InputWithButton';

InputWithButton.propTypes = {
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default InputWithButton;
