import { useState, createRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import throttle from 'lodash/throttle';

import translations from 'decorators/Translations/translations';
import SkeletonCircle from 'components/Skeletons/SkeletonCircle';
import Infotip, { InfotipContent, InfotipContentWrapper } from 'components/Infotip/Infotip';
import FloorTabLabel from './FloorTabLabel';
import InputSelectDropdown from '../Form/InputSelectDropdown';

export const ScrollOverflowContainer = styled.div`
  max-width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.small ? 'left' : 'center')};
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
`;
ScrollOverflowContainer.displayName = 'ScrollOverflowContainer';

const TabWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
`;

const SkeletonItem = styled.div`
  display: inline-block;
`;

export const FloorTabsListContainer = styled.div`
  height: ${props => props.theme.navigation.height};
`;
FloorTabsListContainer.displayName = 'FloorTabsListContainer';

export const FloorTabsList = styled.div`
  position: relative;
  background-color: var(--white);
  box-shadow: var(--box-shadow-03);
  white-space: nowrap;
  text-align: center;

  ${props =>
    props.fixedMobile &&
    css`
      top: 0;
      left: 0;
      right: 0;
      position: fixed;
      z-index: ${props => props.theme.zIndex('circleTabs')};
    `};

  ${props => props.theme.media.portrait`
      position: initial;
      ${props =>
        props.fixedTablet &&
        css`
          position: fixed;
          top: 80px;
        `}
  `}

  ${props => props.theme.media.landscape`
      position: initial;
      ${props =>
        props.fixed &&
        css`
          position: fixed;
          top: ${props => props.theme.navigation.height};
          left: ${props =>
            props.naviExtended ? props.theme.navigation.sideTabletWidth : props.theme.navigation.sideSmallWidth};
          z-index: ${props => props.theme.zIndex('circleTabs')};
        `}
  `}
  ${props => props.theme.media.desktop`
      position: initial;
      ${props =>
        props.fixed &&
        css`
          position: fixed;
          left: ${props =>
            props.naviExtended ? props.theme.navigation.sideWidth : props.theme.navigation.sideSmallWidth};
        `}
  `}
`;
FloorTabsList.displayName = 'FloorTabsList';

const TabLabel = styled.div`
  display: inline-block;
`;
TabLabel.displayName = 'TabLabel';

const StyledInfotip = styled(Infotip)`
  && {
    display: block;
    position: static;
  }

  && ${InfotipContentWrapper} {
    position: static;
    transform: none;
  }

  && ${InfotipContent} {
    position: fixed;
    z-index: ${props => props.theme.zIndex('infoTip')};
    top: ${props => props.offsetTop + 'px'};
    left: ${props => props.offsetLeft + 'px'};
    transform: translateX(-50%);
  }
`;

const FloorSelectContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: ${props => (props.small ? 'left' : 'center')};
  text-align: left;
  & > * {
    margin: var(--size-xs);
  }
  & > div {
    width: ${props => (props.small ? '200px' : '300px')};
  }
`;

const FloorTabLabels = ({ loading, content, selectedTab, scrollTop, isExpanded, t, location, onSelectTab, small }) => {
  const [toolTipStyles, setToolTipStyles] = useState([]);
  const [useDropdownLayout, setUseDropdownLayout] = useState(false);
  const overflowContainerRef = createRef();
  const labelRefs = [];

  const scrollOffset = 150 + 40; // Hero + margin
  const fixed = scrollOffset < scrollTop;

  const updateFloorTabSize = () => {
    const node = overflowContainerRef?.current;
    if (!node) {
      return;
    }
    if (!useDropdownLayout && node.children?.[0].offsetWidth > node.offsetWidth) {
      setUseDropdownLayout(true);
    }
  };

  const throttledUpdateSize = throttle(updateFloorTabSize, 200);

  useEffect(() => {
    updateFloorTabSize();
    window.addEventListener('resize', throttledUpdateSize);
    return () => {
      window.removeEventListener('resize', throttledUpdateSize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (content.some(item => (item.title || item.shortName || '').length > 2)) {
      setUseDropdownLayout(true);
    }
  }, [content]);

  const handleInfoTipMouseOver = index => {
    const bounds = getLabelPosition(index);
    if (bounds) {
      setInfoTipPosition(bounds, index);
    }
  };

  const getLabelPosition = index => {
    if (labelRefs && labelRefs.length > 0) {
      const element = labelRefs[index].current;
      if (element) {
        return element.getBoundingClientRect();
      }
    }
    return null;
  };

  const setInfoTipPosition = (bounds, index) => {
    setToolTipStyles(prevState => {
      const styles = Array.isArray(prevState) ? prevState.slice(0) : [];
      styles[index] = {
        left: bounds.left + bounds.width / 2,
        top: bounds.top - 66,
      };
      return styles;
    });
  };

  if (useDropdownLayout) {
    return (
      <FloorSelectContainer small={small}>
        {!small && <span>{t('Floor')}</span>}
        <div data-test-id="FloorDropdownContainer">
          <InputSelectDropdown
            t={t}
            value={selectedTab}
            options={content.map(item => ({ label: item.title || item.shortName, value: item }))}
            onChange={(property, value) => onSelectTab(value)}
            loading={loading}
            clearable={false}
            required
          />
        </div>
      </FloorSelectContainer>
    );
  }
  return (
    <ScrollOverflowContainer small={small} ref={overflowContainerRef}>
      <TabWrapper>
        {loading && (
          <>
            <SkeletonItem>
              <SkeletonCircle width="40px" borderWidth="2px" />
            </SkeletonItem>
            <SkeletonItem>
              <SkeletonCircle width="40px" borderWidth="2px" />
            </SkeletonItem>
            <SkeletonItem>
              <SkeletonCircle width="40px" borderWidth="2px" />
            </SkeletonItem>
            <SkeletonItem>
              <SkeletonCircle width="40px" borderWidth="2px" />
            </SkeletonItem>
            <SkeletonItem>
              <SkeletonCircle width="40px" borderWidth="2px" />
            </SkeletonItem>
          </>
        )}
        {!loading &&
          content.map((child, index) => {
            if (!child) {
              return null;
            }
            const selected = selectedTab === child;
            const tooltipContent = child.tooltipContent;
            labelRefs[index] = createRef();
            if (tooltipContent && !selected && !small && fixed === false) {
              return (
                <TabLabel ref={labelRefs[index]} onMouseOver={() => handleInfoTipMouseOver(index)} key={index}>
                  <StyledInfotip
                    offsetLeft={toolTipStyles[index] && toolTipStyles[index].left}
                    offsetTop={toolTipStyles[index] && toolTipStyles[index].top}
                    index={index}
                    text={tooltipContent}
                  >
                    <FloorTabLabel
                      label={child}
                      index={index}
                      small={small}
                      selected={selected}
                      expanded={isExpanded}
                      location={location}
                      onSelectTab={onSelectTab}
                    />
                  </StyledInfotip>
                </TabLabel>
              );
            }

            return (
              <TabLabel key={index}>
                <FloorTabLabel
                  label={child}
                  index={index}
                  small={small}
                  selected={selected}
                  expanded={isExpanded}
                  location={location}
                  onSelectTab={onSelectTab}
                />
              </TabLabel>
            );
          })}
      </TabWrapper>
    </ScrollOverflowContainer>
  );
};

FloorTabLabels.propTypes = {
  scrollTop: PropTypes.number,
  selectedTab: PropTypes.object,
  t: PropTypes.func.isRequired,
  content: PropTypes.array,
  loading: PropTypes.bool,
  isExpanded: PropTypes.bool,
  location: PropTypes.object,
  onSelectTab: PropTypes.func,
  small: PropTypes.bool,
};

export default translations(withRouter(FloorTabLabels));
