import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as yup from 'yup';

import InputForm from 'components/Form/InputForm';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputTextArea from 'components/Form/InputTextArea';
import InputText from 'components/Form/InputText';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import ServiceModuleDocumentUpload from 'containers/Application/ServiceModule/ServiceModuleForm/ServiceModuleDocuments/ServiceModuleDocumentUpload';

const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const createSchema = (t, requireFunctionalLocation) =>
  yup.object().shape({
    functionalLocation: requireFunctionalLocation
      ? yup.string().label(t('Location')).required()
      : yup.string().label(t('Location')),
    title: yup.string().label(t('Title')).required(),
  });

const getLocationOptions = functionalLocations =>
  functionalLocations.map(({ functionalLocation, address, description }) => ({
    label: `${description} (${address})`,
    value: functionalLocation,
  }));

const InfoForm = ({
  t,
  model,
  onFormChange,
  functionalLocations = [],
  loadingFunctionalLocations,
  files = [],
  erroredFiles = [],
  onFileUpload,
  onFileRemoval,
  validationErrors,
}) => (
  <InputForm onPropertyChange={onFormChange} model={model} FormComponent={Form} validationErrors={validationErrors}>
    <InputRow dense fullRow>
      <InputLabel text={t('Title')} required />
      <InputText property="title" placeholder={t('Title')} required />
    </InputRow>
    {(functionalLocations.length > 0 || loadingFunctionalLocations) && (
      <InputRow dense fullRow>
        <InputLabel text={t('Location')} required />
        <InputSelectDropdown
          loading={loadingFunctionalLocations}
          property="functionalLocation"
          options={getLocationOptions(functionalLocations)}
          t={t}
          required
          clearable={false}
        />
      </InputRow>
    )}
    <InputRow dense fullRow>
      <InputLabel text={t('Description')} />
      <InputTextArea property="description" placeholder={`${t('Give a short outline of the issue')}...`} />
    </InputRow>
    <InputRow dense fullRow>
      <InputLabel text={t('Detailed location')} />
      <InputText property="locationDetails" placeholder={`${t('For example floor or space')}...`} />
    </InputRow>
    <InputRow dense fullRow>
      <InputLabel text={t('Attachments')} />
      <ServiceModuleDocumentUpload
        files={files}
        erroredFiles={erroredFiles}
        onFileUpload={onFileUpload}
        onFileRemoval={onFileRemoval}
      />
    </InputRow>
  </InputForm>
);

InfoForm.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  functionalLocations: PropTypes.arrayOf(
    PropTypes.shape({
      functionalLocation: PropTypes.string,
      address: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  onFormChange: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onFileRemoval: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  erroredFiles: PropTypes.array.isRequired,
  loadingFunctionalLocations: PropTypes.bool.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default InfoForm;
