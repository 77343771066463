import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon/Icon';

export const MultiOption = styled.div`
  display: inline-flex;
  align-items: center;

  height: auto;
  margin: ${props => (props.alignMiddle ? '2px var(--size-xs) 2px 0' : '0 var(--size-xs) var(--size-xs) 0')};

  color: var(--multiselect-pill-fg);
  background-color: var(--multiselect-pill-bg);
  border-radius: var(--radius-sm);
`;

const MultiOptionLabel = styled.div`
  font: var(--font-xxs);
  padding: var(--size-xxxs) 0 var(--size-xxxs) var(--size-xs);
  line-height: 1;
  cursor: default;
`;

const MultiOptionRemoveControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--size-lg);
  height: var(--size-lg);
  border-radius: var(--radius-sm);
  cursor: pointer;

  &:hover {
    background-color: var(--multiselect-pill-control-bg-hl);
  }

  svg {
    font-size: var(--size-xxs-plus);
    fill: var(--multiselect-pill-control-fg);
  }
`;

const SelectedMultiOption = ({ label, onClick, alignMiddle }) => (
  <MultiOption alignMiddle={alignMiddle}>
    <MultiOptionLabel>{label}</MultiOptionLabel>
    <MultiOptionRemoveControl onClick={onClick}>
      <Icon name="remove" fill="inherit" />
    </MultiOptionRemoveControl>
  </MultiOption>
);

SelectedMultiOption.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  alignMiddle: PropTypes.bool,
};

export default SelectedMultiOption;
