import styled from 'styled-components';
import SkeletonAnimation from './SkeletonAnimation';

const SkeletonBall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.width ? props.width : '136px')};
  height: ${props => (props.width ? `calc(${props.width})` : '136px')};
  margin: ${props => (props.margin ? props.margin : '12px')};
  background-color: ${props => props.theme.skeleton.backgroundColor};
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: ${props => props.borderWidth || '5px'};
    right: ${props => props.borderWidth || '5px'};
    bottom: ${props => props.borderWidth || '5px'};
    left: ${props => props.borderWidth || '5px'};
    z-index: 2;
    background-color: ${props => props.backgroundColor || props.theme.colors.white};
    border-radius: 50%;
  }
`;

const SkeletonCircle = props => (
  <SkeletonBall {...props}>
    <SkeletonAnimation />
  </SkeletonBall>
);

export default SkeletonCircle;
