import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import withQuery from 'decorators/Query/withQuery';

import RescuePlan from './RescuePlanDocument/RescuePlan/RescuePlan';
import RescuePlanActionGuide from './RescuePlanDocument/RescuePlanActionGuide/RescuePlanActionGuide';
import RescuePlanPrintFiles from './RescuePlanPrintFiles/RescuePlanPrintFiles';
import RescuePlanSignAsRead from './RescuePlanSignAsRead/RescuePlanSignAsRead';
import RescuePlanSafetyNotice from './RescuePlanSafetyNotice/RescuePlanSafetyNotice';
import BinderTabs from 'components/BinderBox/BinderTabs';

const rescueFeatures = (id, t) => {
  switch (id) {
    case 'rescue_plan.details_plan':
      return {
        id: 'rescue-plan',
        label: t('Rescue Plan'),
      };
    case 'rescue_plan.details_acknowledgements':
      return {
        id: 'sign-as-read',
        label: t('Sign as read'),
      };

    case 'rescue_plan.details_actions':
      return {
        id: 'action-guide',
        label: t('Action guide'),
      };
    case 'rescue_plan.details_print':
      return {
        id: 'print-files',
        label: t('Print'),
      };
    case 'rescue_plan.details_safety_remarks':
      return {
        id: 'safety-remarks',
        label: t('Provide safety notice'),
      };
    default:
      return null;
  }
};

const tabQueryToRescueFeature = (id, query) => {
  switch (id) {
    case 'rescue_plan.details_plan':
      return ['rescue-plan'].includes(query);
    case 'rescue_plan.details_acknowledgements':
      return ['sign-as-read'].includes(query);
    case 'rescue_plan.details_actions':
      return ['action-guide'].includes(query);
    case 'rescue_plan.details_print':
      return ['print-files'].includes(query);
    case 'rescue_plan.details_safety_remarks':
      return ['safety-remarks'].includes(query);
    default:
      return false;
  }
};

const calculateTabs = (info, query, language, t, publicUserLanguage) => {
  if (info.length === 0) {
    return { planId: null, tabs: null, planLanguage: null, selectedInfo: null };
  }

  const planId = query?.planId || info[0].rescuePlanIdUuid;
  const selectedInfo = info.find(plan => plan.rescuePlanIdUuid === planId);

  const planFeatures = selectedInfo.menuItems;
  // Rescue plans only come with fi, sv, en, ru localizations. Default to en if locale not found
  let planLanguage = 'en';
  if (language) {
    planLanguage = selectedInfo.locales.find(lang => lang === language) || 'en';
  } else {
    planLanguage = selectedInfo.locales.find(lang => lang === publicUserLanguage) || 'en';
  }

  const tabs = planFeatures.reduce((previous, current) => {
    const tab = rescueFeatures(current.id, t);
    if (tab) {
      return [...previous, tab];
    }
    return previous;
  }, []);

  return { planId, tabs, planLanguage, selectedInfo };
};

const tabSelector = (info, query, setQuery) => {
  if (info.length === 0 || !query.tab) {
    return 'rescue-plan';
  }
  const planId = query?.planId || info[0].rescuePlanIdUuid;
  const planSelected = info.find(plan => plan.rescuePlanIdUuid === planId);
  const tab = planSelected.menuItems.find(item => tabQueryToRescueFeature(item.id, query?.tab));
  if (tab) {
    return query?.tab;
  }
  setQuery({ tab: 'rescue-plan' });
  return 'rescue-plan';
};

export const RescuePlanBreakdown = ({ t, query, info, language, setQuery, publicUserLanguage }) => {
  const [selectedTab, selectTab] = useState('rescue-plan');

  useEffect(() => {
    selectTab(tabSelector(info, query, setQuery));
  }, [info, query, setQuery]);

  const setTab = id => {
    selectTab(id);
    setQuery({ tab: id });
  };

  const { planId, tabs, planLanguage, selectedInfo } = useMemo(() => {
    return calculateTabs(info, query, language, t, publicUserLanguage);
  }, [info, query, language, t, publicUserLanguage]);

  if (planId === null) {
    return null;
  }

  const bindTabsToContent = tab => {
    const selected = {
      'rescue-plan': <RescuePlan planId={planId} planLanguage={planLanguage} title={selectedInfo.buildingName} />,
      'action-guide': <RescuePlanActionGuide planId={planId} planLanguage={planLanguage} />,
      'sign-as-read': <RescuePlanSignAsRead planId={planId} planLanguage={planLanguage} />,
      'print-files': <RescuePlanPrintFiles planId={planId} planLanguage={planLanguage} />,
      'safety-remarks': <RescuePlanSafetyNotice planId={planId} planLanguage={planLanguage} />,
    }[tab.id];

    return { id: tab.id, title: tab.label, content: selected };
  };

  const binderTabs = tabs.map(tab => bindTabsToContent(tab));

  return (
    <BinderTabs
      tabWidth={'10%'}
      mobileTabWidth={'10%'}
      selectedTabId={selectedTab}
      setSelectedTab={setTab}
      tabs={binderTabs}
    />
  );
};

RescuePlanBreakdown.propTypes = {
  t: PropTypes.func.isRequired,
  query: PropTypes.object,
  info: PropTypes.array.isRequired,
  setQuery: PropTypes.func.isRequired,
  publicUserLanguage: PropTypes.string,
};

const mapStateToProps = state => ({
  language: state.profile.profile.language,
});

const connector = connect(mapStateToProps);

export default withQuery(connector(translations(RescuePlanBreakdown)));
