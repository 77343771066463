import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.centerContent ? 'center' : 'space-between')};
  align-items: center;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : props.naked ? 'transparent' : 'var(--dialog-footer-bg)'};
  ${props => (!props.naked ? 'border-top: var(--light-separator-border);' : '')}
  padding: var(--size-md) var(--size-lg);
  & button:not(:first-child) {
    margin-left: var(--size-sm);
  }
`;
Footer.displayName = 'Footer';

const DialogFooter = ({ children, backgroundColor, ...other }) => (
  <Footer backgroundColor={backgroundColor} {...other}>
    {children}
  </Footer>
);
DialogFooter.displayName = 'DialogFooter';

DialogFooter.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  naked: PropTypes.bool,
  centerContent: PropTypes.bool,
};

DialogFooter.defaultProps = {
  children: null,
  backgroundColor: null,
  naked: false,
  centerContent: false,
};

export default DialogFooter;
