import { useRef, useMemo } from 'react';
import Highstock from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import useChartSkeleton from '../useChartSkeleton';
import { useTranslations } from 'decorators/Translations/translations';
import { getChartConfig, hasData } from './scatterPlotUtils';
import * as Styled from './ScatterPlot.styled';

const ScatterPlot = props => {
  const { loading, error, seriesData, xUnit, yUnit, setpoint, showTrend, annotations, onOpenAnnotationForm } = props;
  const [t] = useTranslations();
  const theme = useTheme();
  const containerRef = useRef();

  const config = useMemo(
    () =>
      getChartConfig({
        theme,
        chartTitle: '',
        seriesData,
        xUnit,
        yUnit,
        setpoint,
        showTrend,
        t,
        annotations,
        onOpenAnnotationForm,
      }),
    [theme, seriesData, xUnit, yUnit, setpoint, showTrend, t, annotations, onOpenAnnotationForm]
  );

  const chartSkeleton = useChartSkeleton(loading, error, !hasData(seriesData));

  return (
    <Styled.SensorChart ref={containerRef}>
      {chartSkeleton ?? (
        <HighchartsReact highcharts={Highstock} options={config} containerProps={{ style: { width: '100%' } }} />
      )}
    </Styled.SensorChart>
  );
};

ScatterPlot.defaultProps = {
  loading: false,
  error: null,
  xUnit: null,
  yUnit: null,
  setpoint: null,
  showTrend: false,
};

ScatterPlot.propTypes = {
  seriesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      data: PropTypes.any,
    })
  ).isRequired,
  setpoint: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      })
    ),
  }),
  showTrend: PropTypes.bool,
  xUnit: PropTypes.string,
  yUnit: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  annotations: PropTypes.arrayOf(PropTypes.any),
  onOpenAnnotationForm: PropTypes.func,
};

export default ScatterPlot;
