import { useState, useEffect, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { filterFolders } from 'utils/Data/documents';

const filterFiles = (files, filterString) => files.filter(file => file.name?.toLowerCase().includes(filterString));

export const useFilters = (files, folders, filterString) => {
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (filterString) {
      // filter files by name field
      const tempFiles = filterFiles(files, filterString);

      // filter folders by having files in them
      const folderIds = Array.from(new Set(tempFiles.map(file => file.folderId)));
      const tempFolders = filterFolders(cloneDeep(folders), folderIds);

      setFilteredFiles(tempFiles);
      setFilteredFolders(tempFolders);
      setIsFiltered(true);
    } else if (isFiltered) {
      setFilteredFiles(files);
      setFilteredFolders(folders);
      setIsFiltered(false);
    }
  }, [filterString]);

  useEffect(() => {
    setFilteredFiles(files);
    setFilteredFolders(folders);
    setIsFiltered(false);
  }, [files, folders]);

  return useMemo(
    () => ({ filteredFiles, filteredFolders, isFiltered }),
    [filteredFiles, filteredFolders, isFiltered]
  );
};
