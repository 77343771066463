import { useMemo } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import { useTranslations } from 'decorators/Translations/translations';

const Preview = styled.div`
  font-size: ${props => props.theme.font.size.xs};
`;

const PreviewLabel = styled.div`
  margin-top: var(--size-md);
  margin-bottom: var(--size-sm);
`;

const PreviewScrollContainer = styled.div`
  padding: var(--size-sm) var(--size-md);
  background-color: ${props => props.theme.colors.alabaster};
  max-height: 175px;
  overflow: auto;
  border: 1px solid ${props => props.theme.input.border.static};
`;
const PreviewList = styled.ol``;
const PreviewItem = styled.li`
  list-style-position: inside;
  padding: var(--size-xxs) 0;
`;

const ChecklistTemplatePreview = ({ template }) => {
  const [t] = useTranslations();
  const sortedItems = useMemo(() => sortBy(template?.items, 'sortOrder'), [template?.items]);
  if (!template?.items?.length) {
    return null;
  }
  return (
    <Preview>
      <PreviewLabel>{t('Checklist preview')}</PreviewLabel>
      <PreviewScrollContainer>
        <PreviewList>
          {sortedItems.map(item => (
            <PreviewItem key={item.id}>{item.title}</PreviewItem>
          ))}
        </PreviewList>
      </PreviewScrollContainer>
    </Preview>
  );
};

ChecklistTemplatePreview.propTypes = {
  template: PropTypes.shape({
    items: PropTypes.array,
  }),
};

export default ChecklistTemplatePreview;
