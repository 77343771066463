import { Fragment, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import last from 'lodash/last';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';
import takeRight from 'lodash/takeRight';
import head from 'lodash/head';
import { rgba, transparentize } from 'polished';
import queryString from 'query-string';
import Infotip from 'components/Infotip/Infotip';
import Svg from 'components/Svg/Svg';
import { flIcons, functionalLocationName } from 'utils/Data/functionalLocations';
import QueryLink from 'components/QueryLink/QueryLink';
import SimpleDropdown from 'components/SimpleDropdown/SimpleDropdown';
import Loader from 'components/Loader/Loader';
import { InputSearch } from 'components';
import { getPartnerNumbers } from 'utils/profile';
import { getEnabledTabs, getEnabledCustomViews } from 'utils/Data/features';

import {
  loadCustomers,
  setActivePartner,
  loadFunctionalLocationsForParent,
  loadFunctionalLocationsForOverview,
} from 'redux/modules';
import { loadEquipments, loadSubEquipments } from 'redux/modules/customer/equipments';
import { isBusinessUnit } from 'utils/Data/functionalLocations';
import { isValidPartner } from 'utils/Data/partners';
import { pushToDataLayer } from 'utils/Analytics/analytics';
import { CaretToggle } from 'components/CaretToggle/CaretToggle';

const Loading = styled.h4`
  color: ${props => props.theme.colors.lightGray};
`;

const LinkContainer = styled.div`
  font-size: ${props => props.theme.fontSize.xs};
  padding: var(--size-md) 0 var(--size-md) var(--size-md);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: ${props => (props.withName ? 'auto' : '50px')};
  cursor: pointer;

  &:last-child {
    cursor: ${props => (props.withLink ? 'pointer' : 'default')};
  }

  ${props => props.theme.media.portrait`
    border-left: 1px solid ${props => rgba(props.theme.colors.white, 0.2)};
    padding: var(--size-md);
    width: ${props => (props.withName ? 'auto' : '68px')};
  `}
`;

const ContextIcon = styled(Svg)`
  fill: ${props => rgba(props.theme.colors.white, 0.7)};
  font-size: 28px;
  min-width: 28px;
  margin: 0 2px;

  ${props => props.theme.media.portrait`
    fill: ${props => props.theme.colors.white};
  `}
`;

const Links = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 10;
  pointer-events: auto;

  > *:last-child {
    height: 100%;
    overflow: hidden;
  }

  > *:last-child ${LinkContainer} {
    position: relative;
    height: 100%;

    ${props => props.theme.media.portrait`
      border-right: 1px solid ${props => rgba(props.theme.colors.white, 0.2)};
      background-color: ${props => rgba(props.theme.colors.white, 0.15)};
      `}
  }

  > *:last-child ${ContextIcon} {
    fill: ${props => props.theme.colors.white};
  }
`;

const SelectorText = styled.div`
  padding-left: var(--size-sm);
  font-size: ${props => props.theme.fontSize.xxxs};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.default};
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
`;

SelectorText.displayName = 'SelectorText';

const Selector = styled.div`
  position: absolute;
  top: 64px;
`;
Selector.displayName = 'Selector';

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.geyser};
`;

const SearchInput = styled(InputSearch)`
  font-size: ${props => props.theme.fontSize.xs};
  width: 100%;
  height: 100%;

  input,
  input:hover,
  input:focus {
    box-shadow: none;
    border: none;
  }
`;

const SelectorLink = styled(QueryLink)`
  font-size: ${props => props.theme.fontSize.xs};
  color: ${props => props.theme.colors.midnight};
  padding: var(--size-md);
  cursor: pointer;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${props => transparentize(0.9, props.theme.colors.cerulean)};
  }
`;

const DropdownContainer = styled.div`
  height: 18em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.rockBlue};
`;

const EmptyIcon = styled(Svg)`
  font-size: 3rem;
  margin-bottom: var(--size-md);
  fill: ${props => props.theme.colors.rockBlue};
`;

const ArrowIcon = styled(CaretToggle)`
  font-size: ${props => props.theme.fontSize.xs};
  margin-left: var(--size-xs);
  fill: ${props => props.theme.colors.white};
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const renderIcon = type => {
  const icon = flIcons[type];

  if (!icon) {
    return null;
  }

  return <ContextIcon name={icon} />;
};

const renderLink = (loadingContext, context, contextLink, type, index) => {
  if (contextLink) {
    return (
      <Infotip text={context || ''} position="bottom" type="white" key={`${type}-${index}`}>
        <LinkContainer>
          <StyledLink to={contextLink}>{type && renderIcon(type)}</StyledLink>
        </LinkContainer>
      </Infotip>
    );
  }
  return (
    <LinkContainer key={`${type}-${index}`}>
      <Loading>{loadingContext ? '...' : context || ''}</Loading>
    </LinkContainer>
  );
};

export const Context = props => {
  const {
    profile,
    customers,
    t,
    location,
    location: { pathname },
    match: {
      params: { partnerNumber, functionalLocationId },
    },
    contextLinks,
    showPartnerSelect,
    loadingContext,
    type,
    text,
    setActivePartner,
    functionalLocation,
    equipment,
    features,
    functionalLocations,
    topLevelFunctionalLocations,
    equipments,
    subEquipments,
    loadEquipment,
    loadSubEquipment,
    loadCustomers,
    loadChildFunctionalLocations,
    loadTopLevelFunctionalLocations,
  } = props;

  const [selectorOpen, setSelectorOpen] = useState(false);
  const [loadingSiblings, setLoadingSiblings] = useState(true);
  const [searchResults, setSearchResults] = useState(null);

  const isMounted = useRef(true);
  const node = useRef(null);
  const selector = useRef(null);
  const parent = functionalLocation && last(without(functionalLocation.path, functionalLocation.functionalLocation));

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    loadPartners();

    // Set selected partner based on url parameter to keep it in sync when using direct urls
    isValidPartner(partnerNumber) && setActivePartner(partnerNumber);
    pushToDataLayer({ partnerNumber: partnerNumber });
    // set active functional location - also clear it if not in url parameters
    pushToDataLayer({ functionalLocation: functionalLocationId });

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
      isMounted.current = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!showPartnerSelect) {
      load();
    }
  }, [functionalLocations[parent]]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectorOpen && selector.current) {
      selector.current.focus();
    }
  }, [selectorOpen, loadingContext, loadingSiblings]);

  const loadPartners = async () => {
    const partners = getPartnerNumbers(profile);
    if (partners.length > 0) {
      setLoadingSiblings(true);
      await loadCustomers(partners);

      // don't change state if unmount has happened
      if (isMounted.current) {
        setLoadingSiblings(false);
      }
    }
  };

  const load = async () => {
    // Load siblings for FL/Equipment
    if (functionalLocation) {
      setLoadingSiblings(true);
      const parentFl = functionalLocations[parent];

      if (!equipment) {
        if (parentFl) {
          await loadChildFunctionalLocations(parentFl);
        } else {
          await loadTopLevelFunctionalLocations(partnerNumber);
        }
      } else if (equipment.equipmentSuperordinate) {
        await loadSubEquipment(functionalLocation, equipment.equipmentSuperordinate);
      } else {
        await loadEquipment(functionalLocation);
      }

      // don't change state if unmount has happened
      if (isMounted.current) {
        setLoadingSiblings(false);
      }
    }
  };

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setSelectorOpen(false);
  };

  const renderSelectorText = (text, showSelector) => {
    return (
      <Fragment>
        <SelectorText>{text}</SelectorText>
        {showSelector && <ArrowIcon type="dropdown" isOpen={selectorOpen} />}
      </Fragment>
    );
  };

  const renderSelector = (text, options, onClick) => {
    if (!selectorOpen) {
      return null;
    }
    const optionsToShow = searchResults !== null ? searchResults : options;

    return (
      <Selector data-test-id="Selector">
        <SimpleDropdown left width="20em">
          {!loadingSiblings && !isEmpty(options) && (
            <div>
              {options.length > 7 && (
                <SearchContainer>
                  <SearchInput
                    onChange={value => {
                      const searchResults = filter(
                        options,
                        option => option.search && option.search.indexOf(value.toLowerCase()) !== -1
                      );
                      setSearchResults(searchResults);
                    }}
                    placeholder={t('Filter by Name')}
                    onClear={() => setSearchResults(null)}
                    inputRef={selector}
                  />
                </SearchContainer>
              )}
              {optionsToShow.map((option, index) => (
                <SelectorLink
                  to={option.to}
                  key={index}
                  data-test-id="SelectorLink"
                  onClick={onClick ? () => onClick(option.value) : undefined}
                >
                  {option.label}
                </SelectorLink>
              ))}
            </div>
          )}
          {loadingSiblings && (
            <DropdownContainer>
              <Loader color="GRAY" size="MEDIUM" />
            </DropdownContainer>
          )}
          {!loadingSiblings && isEmpty(options) && (
            <DropdownContainer>
              <EmptyIcon name="empty-box" />
              <p>{t('No siblings available')}</p>
            </DropdownContainer>
          )}
        </SimpleDropdown>
      </Selector>
    );
  };

  const renderContext = contextLinks => {
    if (showPartnerSelect && partnerNumber) {
      const options = getPartnerOptions();
      const selected = find(options, { value: partnerNumber }) || { label: t('Select') };
      const onClick = value => {
        setActivePartner(value);
        pushToDataLayer({ partnerNumber: value });
        pushToDataLayer({ functionalLocation: null });
        setSelectorOpen(false);
        setSearchResults(null);
      };
      const selectorClick = () => setSelectorOpen(!selectorOpen);

      return (
        <Links>
          <div>
            <LinkContainer withLink withName onClick={selectorClick} data-test-id="PartnerSelector">
              {renderIcon('BE')}
              {renderSelectorText(selected.label, true)}
            </LinkContainer>
            {renderSelector(selected.label, options, onClick)}
          </div>
        </Links>
      );
    }

    if (contextLinks && contextLinks.length > 0) {
      const firstLevel = contextLinks.length === 1;
      const showSelector = isValidPartner(partnerNumber) && !!functionalLocation;
      const siblings = showSelector && selectorOpen && (equipment ? getEquipmentOptions() : getFLOptions(firstLevel));

      const currentType = type || (functionalLocation && functionalLocation.type);
      const selectorText = text || functionalLocationName(t, functionalLocation);
      const selectorClick = showSelector ? () => setSelectorOpen(!selectorOpen) : undefined;
      const tail = currentType && (
        <div>
          <LinkContainer key={currentType} withName withLink={showSelector} onClick={selectorClick}>
            {renderIcon(currentType)}
            {selectorText && renderSelectorText(selectorText, showSelector)}
          </LinkContainer>
          {selectorText && renderSelector(selectorText, siblings)}
        </div>
      );

      if (loadingContext) {
        return (
          <Links>
            {renderLink(loadingContext, contextLinks[0].context, contextLinks[0].contextLink, contextLinks[0].type, 0)}
            <LinkContainer key="loading">
              <Loading>...</Loading>
            </LinkContainer>
            {tail}
          </Links>
        );
      }
      return (
        <Links>
          {contextLinks.map((link, index) =>
            renderLink(loadingContext, link.context, link.contextLink, link.type, index)
          )}
          {tail}
        </Links>
      );
    }

    return null;
  };

  const getPartnerOptions = () => {
    const partners = getPartnerNumbers(profile);
    const pathArray = pathname.split('/').splice(2);
    let path = '';
    // Custom view pages are partner specific and we don't want to include them when changin context
    if (!includes(pathArray, 'Report')) {
      path = pathArray.join('/');
    }

    // Map all partner numbers to customers and remove non-mapped partners.
    const filteredCustomers = partners.map(partner => customers[partner]).filter(partner => !!partner);
    const options = sortBy(
      filteredCustomers.map(customer => ({
        to: { pathname: `/${customer.partnerNumber}/${path}` },
        label: customer.name,
        value: customer.partnerNumber,
        search: `${customer.name.toLowerCase()} ${customer.address.toLowerCase()}`,
      })),
      'label'
    );
    options.push({ to: { pathname: `/all/${path}` }, label: t('Show all'), value: 'all' });
    return options;
  };

  const getFLOptions = firstLevel => {
    let options;
    let siblings;

    // If this is the first level, there is no parent. Use BUs for partner permissions and profile FLs otherwise.
    if (firstLevel) {
      siblings = topLevelFunctionalLocations;
    } else {
      const pathWithoutFL = without(functionalLocation.path, functionalLocation.functionalLocation);
      const parent = last(pathWithoutFL);
      siblings = without(Object.keys(functionalLocations), functionalLocation.functionalLocation)
        .map(fl => functionalLocations[fl])
        .filter(
          fl =>
            fl.functionalLocation !== parent &&
            includes(fl.partnerNumberWithParents, partnerNumber) &&
            ((isBusinessUnit(functionalLocation) && isBusinessUnit(fl)) || includes(takeRight(fl.path, 2), parent))
        );
    }

    if (siblings && siblings.length > 0) {
      options = siblings.map(sibling => {
        let tab = queryString.parse(location.search).tab;
        const enabledTabs = getEnabledTabs(
          features,
          sibling.type,
          getEnabledCustomViews(profile.customViews, {
            functionalLocation: sibling.functionalLocation,
          })
        );
        // Check if wanted tab is enabled for user and if not, just select first enabled tab
        tab = includes(enabledTabs, tab) ? tab : head(enabledTabs);

        const id = sibling.functionalLocation;
        const pathname = !isNil(partnerNumber)
          ? `/${partnerNumber}/FunctionalLocation/${id}/${tab}`
          : `/FunctionalLocation/${id}/${tab}`;
        const search = `${sibling.description ? sibling.description.toLowerCase() : ''} ${
          sibling.address ? sibling.address.toLowerCase() : ''
        }`;
        return {
          label: functionalLocationName(t, sibling),
          to: { pathname },
          search,
        };
      });
    }

    return sortBy(options, 'label');
  };

  const getEquipmentOptions = () => {
    let options;
    const flEquipmentList = equipments[functionalLocation.functionalLocation];
    const subEquipmentList = subEquipments[equipment.equipmentSuperordinate];
    const equipmentList = equipment.equipmentSuperordinate ? subEquipmentList : flEquipmentList;
    const siblings =
      equipmentList &&
      equipmentList.filter(
        flEquipment =>
          flEquipment.equipmentNumber !== equipment.equipmentNumber &&
          flEquipment.functionalLocation === functionalLocation.functionalLocation
      );

    if (siblings && siblings.length > 0) {
      options = siblings.map(sibling => {
        const tab = queryString.parse(location.search).tab;
        const partnerPart = !partnerNumber ? '' : `/${partnerNumber}`;
        const parentPart = `/${equipment.equipmentSuperordinate ?? ''}`;
        const fl = functionalLocation.functionalLocation;
        const id = sibling.equipmentNumber;
        const pathname = `${partnerPart}/Equipment/${fl}${parentPart}/${id}/${tab}`;
        return {
          label: sibling.text,
          to: { pathname },
          search: sibling.text ? sibling.text.toLowerCase() : '',
        };
      });
    }
    return sortBy(options, 'label');
  };

  const links = showPartnerSelect
    ? contextLinks
    : [
        {
          context: t('Overview'),
          contextLink: '/',
          type: 'OVERVIEW',
        },
      ].concat(contextLinks);

  return <span ref={node}>{renderContext(links)}</span>;
};

Context.displayName = 'Context';

Context.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      partnerNumber: PropTypes.string,
      functionalLocationId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.string,
  type: PropTypes.string,
  contextLinks: PropTypes.array,
  loadingContext: PropTypes.bool,
  functionalLocation: PropTypes.object,
  equipment: PropTypes.object,
  showPartnerSelect: PropTypes.bool,
  features: PropTypes.object,
  functionalLocations: PropTypes.object,
  equipments: PropTypes.object,
  subEquipments: PropTypes.object,
  loadEquipment: PropTypes.func,
  loadSubEquipment: PropTypes.func,
  loadCustomers: PropTypes.func,
  loadChildFunctionalLocations: PropTypes.func,
  loadTopLevelFunctionalLocations: PropTypes.func,
  setActivePartner: PropTypes.func,
  profile: PropTypes.object,
  topLevelFunctionalLocations: PropTypes.array,
  customers: PropTypes.object,
};

Context.defaultProps = {
  contextLinks: [],
  loadingContext: false,
  showPartnerSelect: false,
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  topLevelPermissions: state.profile.topLevelPermissions,
  customers: state.customer.customers,
  functionalLocations: state.functionalLocations.functionalLocations,
  topLevelFunctionalLocations: state.functionalLocations.topLevelFunctionalLocations,
  equipments: state.equipments.equipments,
  subEquipments: state.equipments.subEquipments,
  features: state.profile.profile.features,
});
const mapDispatchToProps = {
  loadCustomers,
  setActivePartner,
  loadChildFunctionalLocations: loadFunctionalLocationsForParent,
  loadTopLevelFunctionalLocations: loadFunctionalLocationsForOverview,
  loadEquipment: fl => loadEquipments(fl.functionalLocation, 0, null),
  loadSubEquipment: (fl, parent) => loadSubEquipments(fl.functionalLocation, parent),
};

export default connect(mapStateToProps, mapDispatchToProps)(Context);
