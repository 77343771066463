import subYears from 'date-fns/subYears';
import { startOfUTCYear, endOfUTCYear } from 'utils/Date/date';

export const KG_UNIT = 'kg';
export const KG_PRECISION = 0;

export const TONNES_UNIT = 'tonnes';
export const TONNES_PRECISION = 1;

export const PERCENTAGE_UNIT = '%';
export const PERCENTAGE_PRECISION = 0;

export const CO2E_TONNES_UNIT = `CO${String.fromCharCode('8322')} tonnes`;
export const CO2E_TONNES_PRECISION = 1;

export const CO2E_KG_UNIT = `CO${String.fromCharCode('8322')} kg`;
export const CO2E_KG_PRECISION = 1;

export const LAST_YEAR = new Date().getUTCFullYear() - 1;
export const THIS_YEAR = new Date().getUTCFullYear();

export const RECYCLABLE_WASTE = 'recyclableWaste';
export const NON_RECYCLABLE_WASTE = 'nonRecyclableWaste';
export const CARBON_FOOTPRINT = 'carbonFootprint';

export const YEAR_COLORS = ['previousYear', 'currentYear'];

export const RECYCLING_RATE_THRESHOLD = 55;
export const RECOVERY_RATE_THRESHOLD = 90;

export const WASTE_BREAKDOWN_ELEMENT_ID = 'waste-breakdown';
export const RECYCLING_RATES_BREAKDOWN_ELEMENT_ID = 'rates-breakdown';

export const RATES_TIMEFRAME = {
  startTime: startOfUTCYear(subYears(new Date(), 1)),
  endTime: endOfUTCYear(new Date()),
};
