import { useState, useRef, useLayoutEffect, Children } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MobileSelector from 'containers/Technician/TechniciansViewSelector/MobileSelector';
import { Prev, Next } from './buttons/index';

import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import { useTranslations } from 'decorators/Translations/translations';
import useValidationEffects from './hooks/useValidationEffects';

const MobileContainer = styled.div`
  border-radius: 25px 25px 0 0;
  background-color: ${props => props.theme.colors.white};
  padding: var(--size-lg) var(--size-md) var(--size-md);
`;
MobileContainer.displayName = 'MobileContainer';

const TabWrapper = styled.div`
  margin-bottom: calc(4rem + var(--size-lg));
`;

const FixedButtonContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.singleButton ? 'flex-end' : 'space-between')};
  position: fixed;
  right: var(--size-xs);
  bottom: var(--size-lg);
  left: var(--size-xs);

  button {
    display: inline-block;
    margin: var(--size-xxs);
    height: 4em;
    flex: 1;
    min-width: auto;
  }
`;
FixedButtonContainer.displayName = 'FixedButtonContainer';

const MobileForm = ({ tabs, children, SendButton, handleMobileSend, validationErrors, header }) => {
  const [t] = useTranslations();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const top = useRef(null);
  const breakpoints = useBreakpoints();
  const errorMessage = useValidationEffects(validationErrors, top);

  useLayoutEffect(() => {
    if (top.current && selectedTabIndex !== 0) {
      top.current.scrollIntoView({ block: 'center' });
    }
  }, [selectedTabIndex]);

  const validateCurrent = tabs[selectedTabIndex].validate;

  const handleNextPhase = async event => {
    event.preventDefault();
    const isValid = await validateCurrent();
    if (isValid) {
      setSelectedTabIndex(selectedTabIndex + 1);
    }
  };

  const handlePrevPhase = event => {
    event.preventDefault();
    setSelectedTabIndex(selectedTabIndex - 1);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const isValid = await validateCurrent();
    if (isValid) {
      handleMobileSend();
    }
  };

  if (breakpoints.landscape) {
    return null;
  }

  const tabTitles = tabs.map(tab => tab.title);

  return (
    <div>
      <div ref={top} />
      <MobileSelector tabs={tabTitles} selected={selectedTabIndex} />
      <MobileContainer>
        {header}
        {Children.map(
          children,
          (child, index) =>
            index === selectedTabIndex && (
              <TabWrapper key={index}>
                {errorMessage}
                {child}
              </TabWrapper>
            )
        )}
        <FixedButtonContainer singleButton={selectedTabIndex === 0}>
          {selectedTabIndex !== 0 && <Prev t={t} onClick={handlePrevPhase} />}
          {selectedTabIndex !== tabs.length - 1 && <Next t={t} onClick={handleNextPhase} />}
          {selectedTabIndex === tabs.length - 1 && <SendButton t={t} onClick={handleSubmit} />}
        </FixedButtonContainer>
      </MobileContainer>
    </div>
  );
};

MobileForm.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      validate: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  SendButton: PropTypes.func.isRequired,
  handleMobileSend: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  header: PropTypes.node,
};

export default MobileForm;
