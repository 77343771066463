import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TableConfigurationModal from 'components/TableConfiguration/TableConfigurationModal';

import { DEFAULT_FL_COLUMNS } from 'constants/observations';
import { setColumnConfig } from 'redux/modules/iot/notice';

const ObservationsTableConfigurationModal = props => {
  const { setColumnConfig, columnConfig, defaultColumns, columns, visible, onClose } = props;

  // Use session or props config if it is set
  const configuration = columnConfig || defaultColumns || DEFAULT_FL_COLUMNS;

  const onSubmit = model => {
    const newConfiguration = columns
      .filter(option => model[option.accessor] && model[option.accessor].selected)
      .map(option => option.accessor);
    setColumnConfig(newConfiguration);
  };

  if (!visible) {
    return null;
  }
  return (
    <TableConfigurationModal
      configurationOptions={columns}
      configuration={configuration}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

ObservationsTableConfigurationModal.propTypes = {
  defaultColumns: PropTypes.array,
  columns: PropTypes.array.isRequired,
  setColumnConfig: PropTypes.func.isRequired,
  columnConfig: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  columnConfig: state.notice.columnConfig,
});

const mapDispatchToProps = {
  setColumnConfig,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ObservationsTableConfigurationModal);
