import PropTypes from 'prop-types';
import styled from 'styled-components';
import { addDays, startOfDay } from 'date-fns';
import * as yup from 'yup';

import InputForm from 'components/Form/InputForm';
import InputRow from 'components/Form/InputRow';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import RadioButton from 'components/Form/RadioButton';
import InputLabel from 'components/Form/InputLabel';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import InputDate from 'components/Form/InputDate/InputDate';
import InputText from 'components/Form/InputText';
import Checkbox from 'components/Form/Checkbox';
import SubformComponent, {
  SubformWithMargin,
} from 'containers/Application/ServiceModule/ServiceModuleForm/forms/SubformComponent';
import {
  Recurrence,
  RecurrenceParts,
  getFrequencyOptions,
  getByDayOptions,
  getByMonthOptions,
  HumanFrequency,
  HumanDay,
} from 'utils/Schedule/utils';

export const SMALL_INPUT_WIDTH = 70;

const NoEndDate = styled.div`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.xs};
  line-height: ${props => props.theme.font.lineHeight.lg};
  padding-left: var(--size-xxs);
`;

const Text = styled.div`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.xs};
  padding: var(--size-md) var(--size-xs);
`;

const AddEndDate = styled.div`
  color: ${props => props.theme.colors.cerulean};
  font-size: ${props => props.theme.font.size.xs};
  font-family: ${props => props.theme.font.family.arial};
  line-height: ${props => props.theme.font.lineHeight.lg};
  cursor: pointer;

  & > span {
    margin-left: calc(-1 * var(--size-lg));
  }
`;

const RepeatingInputs = styled.div`
  display: flex;
  flex-grow: 2;
  margin-left: -2px !important;

  & > *:first-child {
    width: ${SMALL_INPUT_WIDTH}px;
  }

  & > *:last-child {
    margin-left: var(--size-xs);
    flex-basis: 100%;

    ${props => props.theme.media.landscape`
      flex-basis: ${props => (props.twoSmallInputs ? 'initial' : '50%')};
      width: ${props => props.twoSmallInputs && `${SMALL_INPUT_WIDTH}px`};
    `}
  }
`;

const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 2;
`;

const CheckboxWrapper = styled.div`
  flex: 1 1 25%;
  padding: var(--size-xs) 0;
  padding-right: var(--size-md);
  text-transform: capitalize;
`;

const tomorrow = startOfDay(addDays(new Date(), 1));
const dayAfterTomorrow = startOfDay(addDays(new Date(), 2));

const newByDayValue = (oldValues = [], checked, value) => {
  if (checked) {
    return oldValues.concat(value);
  }
  return oldValues.filter(oldValue => oldValue !== value);
};

export const createSchema = t =>
  yup.object().shape({
    schedule: yup.object().shape({
      recurrence: yup.boolean().nullable(),
      [Recurrence.START]: yup.date().nullable().label(t('Start date')).required(),
      [Recurrence.END]: yup.date().nullable().label(t('End date')),
      [Recurrence.INTERVAL]: yup
        .number()
        .nullable()
        .label(`${t('Repeat every')} (1)`)
        .when('recurrence', (recurrence, schema) =>
          recurrence ? schema.required().min(0) : schema.transform(value => (isNaN(value) ? null : value))
        ),
      [Recurrence.FREQUENCY]: yup
        .string()
        .nullable()
        .label(`${t('Repeat every')} (2)`)
        .when('recurrence', (recurrence, schema) =>
          recurrence ? schema.required().oneOf(Object.keys(HumanFrequency)) : schema
        ),
      [Recurrence.PARTS]: yup
        .object()
        .nullable()
        .label(t('Recurring by'))
        .when('recurrence', (recurrence, schema) =>
          recurrence
            ? schema.required().shape({
                [RecurrenceParts.BY_DAY]: yup
                  .mixed()
                  .nullable()
                  .when(['recurrence', Recurrence.FREQUENCY], (recurrence, frequency, schema) =>
                    recurrence && frequency === 'WEEKLY' ? schema.required().oneOf(Object.keys(HumanDay)) : schema
                  ),
                [RecurrenceParts.BY_MONTH]: yup
                  .number()
                  .nullable()
                  .when(['recurrence', Recurrence.FREQUENCY], (recurrence, frequency, schema) =>
                    recurrence && frequency === 'YEARLY'
                      ? schema.required().min(1).max(12)
                      : schema.transform(value => (isNaN(value) ? null : value))
                  ),
                [RecurrenceParts.BY_MONTH_DAY]: yup
                  .number()
                  .nullable()
                  .when(['recurrence', Recurrence.FREQUENCY], (recurrence, frequency, schema) =>
                    recurrence && frequency === 'MONTHLY'
                      ? schema.required().min(1).max(31)
                      : schema.transform(value => (isNaN(value) ? null : value))
                  ),
              })
            : schema
        ),
    }),
  });

const RecurrenceForm = ({ t, model, onFormChange, id, desktop, isCreationMode, validationErrors }) => {
  return (
    <>
      <InputForm
        onPropertyChange={onFormChange}
        model={model}
        FormComponent={SubformComponent}
        validationErrors={validationErrors}
      >
        <InputRow dense fullRow>
          <RadioButtonGroup row id={id} name="recurrence" value={model.recurrence} onChange={onFormChange}>
            <RadioButton label={t('Recurrent maintenance')} value={true} disabled={!isCreationMode} />
            <RadioButton label={t('Single maintenance')} value={false} disabled={!isCreationMode} />
          </RadioButtonGroup>
        </InputRow>
      </InputForm>
      {model.recurrence && (
        <InputForm
          onPropertyChange={onFormChange}
          model={model}
          FormComponent={SubformWithMargin}
          validationErrors={validationErrors}
        >
          <InputRow gray fullRow>
            <InputLabel text={t('Start date')} required />
            <InputDate
              property={Recurrence.START}
              iconName="calendar-date"
              required
              minDate={tomorrow}
              showMonthDropdown
              showYearDropdown
            />
            {desktop && <div />}
          </InputRow>
          <InputRow gray fullRow>
            <InputLabel text={t('Repeat every')} required />
            <RepeatingInputs>
              <InputText property={Recurrence.INTERVAL} type="number" required inputProps={{ min: 0 }} />
              <InputSelectDropdown
                property={Recurrence.FREQUENCY}
                options={getFrequencyOptions(t)}
                t={t}
                required
                clearable={false}
              />
            </RepeatingInputs>
          </InputRow>
          {model[Recurrence.FREQUENCY] === 'WEEKLY' && (
            <InputRow gray fullRow>
              <InputLabel text={t('Recurring by')} required />
              <Checkboxes>
                {getByDayOptions(t).map(option => (
                  <CheckboxWrapper key={`${id}_${option.label}`}>
                    <Checkbox
                      id={`${id}_${option.label}`}
                      label={option.label}
                      checked={model[Recurrence.PARTS][RecurrenceParts.BY_DAY]?.includes(option.value)}
                      onChange={event =>
                        onFormChange(
                          [Recurrence.PARTS, RecurrenceParts.BY_DAY],
                          newByDayValue(
                            model[Recurrence.PARTS][RecurrenceParts.BY_DAY],
                            event.target.checked,
                            option.value
                          )
                        )
                      }
                    />
                  </CheckboxWrapper>
                ))}
              </Checkboxes>
            </InputRow>
          )}
          {model[Recurrence.FREQUENCY] === 'MONTHLY' && (
            <InputRow gray fullRow>
              <InputLabel text={t('Recurring by')} required />
              <RepeatingInputs twoSmallInputs>
                <Text>{t('Day')}</Text>
                <InputText
                  property={[Recurrence.PARTS, RecurrenceParts.BY_MONTH_DAY]}
                  type="number"
                  required
                  inputProps={{ min: 1, max: 31 }}
                />
              </RepeatingInputs>
            </InputRow>
          )}
          {model[Recurrence.FREQUENCY] === 'YEARLY' && (
            <>
              <InputRow gray fullRow>
                <InputLabel text={t('Recurring by')} required />
                <Checkboxes>
                  {getByMonthOptions().map(option => (
                    <CheckboxWrapper key={`${id}_${option.label}`}>
                      <Checkbox
                        id={`${id}_${option.label}`}
                        label={option.label}
                        checked={model[Recurrence.PARTS][RecurrenceParts.BY_MONTH]?.includes(option.value)}
                        onChange={event =>
                          onFormChange(
                            [Recurrence.PARTS, RecurrenceParts.BY_MONTH],
                            newByDayValue(
                              model[Recurrence.PARTS][RecurrenceParts.BY_MONTH],
                              event.target.checked,
                              option.value
                            )
                          )
                        }
                      />
                    </CheckboxWrapper>
                  ))}
                </Checkboxes>
              </InputRow>
              <InputRow gray fullRow>
                <InputLabel text={t('Day')} required />
                <RepeatingInputs>
                  <InputText
                    property={[Recurrence.PARTS, RecurrenceParts.BY_MONTH_DAY]}
                    type="number"
                    required
                    inputProps={{ min: 1, max: 31 }}
                  />
                  <div />
                </RepeatingInputs>
              </InputRow>
            </>
          )}
          <InputRow gray fullRow>
            <InputLabel required text={t('End date')} />
            {model[Recurrence.END] && (
              <>
                <InputDate
                  required
                  property={Recurrence.END}
                  iconName="calendar-date"
                  minDate={dayAfterTomorrow}
                  showMonthDropdown
                  showYearDropdown
                />
                {desktop && <div />}
              </>
            )}
            {!model[Recurrence.END] && (
              <>
                <NoEndDate>{t('No end date')}</NoEndDate>
                <AddEndDate>
                  <span onClick={() => onFormChange('end', dayAfterTomorrow)}>{t('Add end date')} +</span>
                </AddEndDate>
              </>
            )}
          </InputRow>
        </InputForm>
      )}
      {!model.recurrence && (
        <InputForm
          onPropertyChange={onFormChange}
          model={model}
          FormComponent={SubformComponent}
          validationErrors={validationErrors}
        >
          <InputRow gray fullRow>
            <InputLabel text={t('Start date')} required />
            <InputDate
              property={Recurrence.START}
              iconName="calendar-date"
              required
              minDate={tomorrow}
              showMonthDropdown
              showYearDropdown
            />
            {desktop && <div />}
          </InputRow>
        </InputForm>
      )}
    </>
  );
};

RecurrenceForm.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  desktop: PropTypes.bool,
  isCreationMode: PropTypes.bool.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default RecurrenceForm;
