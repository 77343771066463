import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';
import setWith from 'lodash/setWith';

import InputTextArea from 'components/Form/InputTextArea';
import InputLabel from 'components/Form/InputLabel';
import ItemForm from './ItemForm';
import DialogModal from 'components/Dialog/DialogModal';
import Checkbox from 'components/Form/Checkbox';
import InfoBox from 'components/InfoBox/InfoBox';

import useConfirmationModal from 'components/ConfirmationModal/useConfirmationModal';
import { deleteConfirmationMessage } from 'components/ConfirmationModal/ConfirmationModal';
import { createReportItemsModel, isPreviouslyAnswered, sortTemplateOptions, sortTemplateItems } from './utils';
import ChecklistPrintHeader from './ChecklistPrintHeader';

const Content = styled.div`
  padding: var(--size-lg) 0;
`;

const AdditionalInfo = styled.div`
  padding: 0 var(--size-lg);
`;

const TitleRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: var(--size-md);
  padding: 0 var(--size-lg);
`;

const Title = styled.h4`
  padding-right: var(--size-md);
`;

const StyledInfoBox = styled(InfoBox)`
  margin: 0 var(--size-lg);
`;

export const ChecklistReportForm = ({
  t,
  checklistTemplate,
  checklistReport,
  checklistReportFiles,
  onSubmit,
  onClose,
  onFileDownload,
  onFileDelete,
  disabled,
  serviceOrder,
}) => {
  const [items, setItems] = useState({});
  const [freeText, setFreeText] = useState(checklistReport?.freeText || '');
  const [hidePreviouslyAnswered, setHidePreviouslyAnswered] = useState(false);
  const [saving, setSaving] = useState(false);

  const sortedTemplateItems = useMemo(() => sortTemplateItems(checklistTemplate.items), [checklistTemplate]);
  const sortedTemplateOptions = useMemo(
    () => sortTemplateOptions(checklistTemplate.options),
    [checklistTemplate]
  );

  useEffect(() => {
    setItems(createReportItemsModel(sortedTemplateItems, checklistReport?.reportItems || []));
  }, [sortedTemplateItems, checklistReport]);

  const handleFilterChange = event => {
    setHidePreviouslyAnswered(event.target.checked);
  };

  const handleFreeTextChange = (_property, value) => {
    setFreeText(value);
  };

  const handleItemChange = (property, value) => {
    setItems(setWith({ ...items }, property, value, Object));
  };

  const handleSubmit = async () => {
    setSaving(true);
    try {
      await onSubmit({
        id: checklistReport?.id,
        freeText,
        reportItems: Object.values(items).map(reportItem => ({
          ...reportItem,
          checklistReportId: checklistReport?.id,
        })),
      });

      onClose();
    } catch (e) {
      setSaving(false);
    }
  };

  const [DeleteConfirmationModal, openDeleteModal] = useConfirmationModal({
    message: t(deleteConfirmationMessage),
    additionalInfo: file => file.name || file.filename,
    onConfirm: onFileDelete,
    waitForSuccessfulCompletion: true,
  });

  return (
    <DialogModal
      small
      animate
      isActive
      noPadding
      onOverlayClick={onClose}
      t={t}
      actions={{
        cancel: {
          label: disabled ? 'Close' : 'Cancel',
          onClick: onClose,
        },
        confirm: disabled
          ? undefined
          : {
              onClick: handleSubmit,
              disabled: false,
              loading: saving,
              label: 'Save',
            },
      }}
    >
      <Content>
        {serviceOrder && <ChecklistPrintHeader serviceOrder={serviceOrder} />}
        {DeleteConfirmationModal && <DeleteConfirmationModal />}
        <TitleRow>
          <Title>{checklistTemplate.title}</Title>
          {!disabled && (
            <Checkbox
              id="ChecklisReportForm-filter"
              onChange={handleFilterChange}
              label={t('Hide previously answered')}
            />
          )}
        </TitleRow>
        {disabled && (
          <StyledInfoBox>
            {t('This checklist is in preview mode only until the assignment has been sent')}
          </StyledInfoBox>
        )}
        {sortedTemplateItems
          .filter(templateItem => !hidePreviouslyAnswered || !isPreviouslyAnswered(items[templateItem.id]))
          .map(templateItem => (
            <ItemForm
              key={templateItem.id}
              onChange={handleItemChange}
              onFileDownload={onFileDownload}
              onFileDelete={onFileDelete ? openDeleteModal : undefined}
              itemModel={items[templateItem.id]}
              itemFiles={checklistReportFiles[items[templateItem.id]?.id]}
              templateItem={templateItem}
              templateOptions={sortedTemplateOptions}
              disabled={disabled}
            />
          ))}
        <AdditionalInfo>
          <InputLabel text={t('Additional info')} />
          <InputTextArea
            property="freeText"
            value={freeText}
            onChange={handleFreeTextChange}
            placeholder={`${t('Add a comment')}...`}
            disabled={disabled}
          />
        </AdditionalInfo>
      </Content>
    </DialogModal>
  );
};

ChecklistReportForm.propTypes = {
  t: PropTypes.func.isRequired,
  checklistTemplate: PropTypes.object.isRequired,
  checklistReport: PropTypes.object,
  checklistReportFiles: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFileDownload: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func,
  disabled: PropTypes.bool,
  serviceOrder: PropTypes.object,
};

export default translations(ChecklistReportForm);
