import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import UserManualContainer from './UserManualContainer';
import cookies from 'utils/Cookies/Cookies';

export const UserManual = ({ t }) => {
  if (window.location.pathname.endsWith('/UserManual')) {
    cookies.removeItem('io.prismic.preview');
  }
  return <UserManualContainer title={t('User Manual')} />;
};

UserManual.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translations(UserManual);
