import { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';

import QueryLink from 'components/QueryLink/QueryLink';
import Svg from 'components/Svg/Svg';

const Label = styled.span`
  position: relative;
  height: 80px;
  font-size: ${props => props.theme.fontSize.xxs};
  font-weight: ${props => props.theme.font.weight.semibold};
  min-width: 94px;
  flex: 1 0 0;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  background-color: ${props => props.theme.colors.white};
`;

const linkStyles = css`
  color: ${props => (props.disabled ? props.theme.colors.lightGray : props.theme.colors.black)};
  width: 100%;
  height: 100%;
  cursor: ${props => (props.disabled || props.selected ? 'default' : 'pointer')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${props => props.theme.media.landscape`
        padding: var(--size-xs) var(--size-md);
    `}
`;

const Link = styled(QueryLink)`
  ${linkStyles}
`;
Link.displayName = 'Link';

const ExternalLink = styled.a`
  ${linkStyles}
`;
ExternalLink.displayName = 'ExternalLink';

const Icon = styled(({ small, ...props }) => <Svg {...props} />)`
  font-size: var(--size-lg);
  margin: var(--size-sm) var(--size-sm) var(--size-xs);
  height: var(--size-lg-plus);
  fill: ${props =>
    props.disabled
      ? props.theme.colors.rockBlue
      : props.selected
        ? props.theme.colors.orange
        : props.theme.colors.midnight};
`;

Icon.displayName = 'Icon';

const Text = styled.span`
  margin: 0 var(--size-sm);
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
  line-height: ${props => props.theme.lineHeight.text};
`;

Text.displayName = 'Text';

const MobileNaviItem = props => {
  const { t, id, title, icon, disabled, loading, to, selected, smallIcon, external } = props;
  const LinkComponent = external ? ExternalLink : Link;
  const key = id ? id : title.toLowerCase();
  const history = useHistory();

  const handleClick = event => {
    if (disabled) {
      event.preventDefault();
    }
    if (to.back) {
      history.goBack();
    }
  };

  return (
    <Label
      data-test-id={`${title}${loading ? '-loading' : ''}${disabled ? '-disabled' : ''}`}
      key={`${key}-label`}
      selected={selected}
      disabled={disabled}
    >
      <LinkComponent
        selected={selected}
        disabled={disabled}
        to={to}
        href={external ? to.pathname : undefined}
        target={external ? '_blank' : undefined}
        rel={external ? 'noopener noreferrer' : undefined}
        onClick={handleClick}
      >
        <Icon name={icon} selected={selected} disabled={disabled} small={smallIcon} />
        <Text>{t(title)}</Text>
      </LinkComponent>
    </Label>
  );
};

MobileNaviItem.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  to: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  smallIcon: PropTypes.bool,
  external: PropTypes.bool,
};

MobileNaviItem.defaultProps = {
  disabled: false,
  selected: false,
  loading: false,
  smallIcon: false,
  external: false,
};

export default memo(MobileNaviItem);
