import map from 'lodash/map';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import { Icon } from 'components/Icon/Icon';

const NavigationLink = styled(({ mobileOnly, ...props }) => <NavLink {...props} />)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-size: ${props => props.theme.fontSize.sm};
  color: ${props => props.theme.colors.blue};
  padding: 0 var(--size-xl);

  left: 0;
  transition-property: transform, opacity, left;
  transition-duration: 0.4s;
  transition-delay: 0.15s, 0.15s, 0s;
  transition-timing-function: ease;
  cursor: pointer;

  &:hover {
    left: 6px;
  }

  svg {
    margin-right: var(--size-xs);
  }

  ${props => props.theme.media.portrait`
        display: ${props => (props.mobileOnly ? 'none' : 'flex')};
    `}

  ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.fontSize.md};
    `}
`;

NavigationLink.displayName = 'NavigationLink';

export const NavigationLinks = ({ items, onClick }) => (
  <div>
    {map(
      items,
      (navigationItem, indx) =>
        navigationItem.show && (
          <NavigationLink
            key={indx}
            data-test-id={navigationItem.dataTestId}
            exact
            to={navigationItem.linkTo}
            onClick={onClick}
            mobileOnly={navigationItem.mobileOnly}
          >
            <Icon name={navigationItem.iconName} fill="currentColor" />
            {navigationItem.text}
          </NavigationLink>
        )
    )}
  </div>
);

NavigationLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      dataTestId: PropTypes.string.isRequired,
      linkTo: PropTypes.string.isRequired,
      iconName: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      show: PropTypes.bool.isRequired,
      mobileOnly: PropTypes.bool,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};
