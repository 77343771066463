import { useState, useEffect, useCallback } from 'react';

export const useReplyForm = serviceOrder => {
  const [replyFormModel, setReplyFormModel] = useState({});

  useEffect(() => {
    setReplyFormModel(oldModel => ({ ...oldModel, status: serviceOrder?.status }));
  }, [serviceOrder]);

  const handleReplyFormChange = useCallback(
    (property, value) => {
      setReplyFormModel(oldModel => ({ ...oldModel, [property]: value }));
    },
    [setReplyFormModel]
  );

  return {
    replyFormModel,
    handleReplyFormChange,
  };
};
