import { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';
import meanBy from 'lodash/meanBy';
import mean from 'lodash/mean';
import minBy from 'lodash/minBy';
import min from 'lodash/min';
import maxBy from 'lodash/maxBy';
import max from 'lodash/max';
import isEmpty from 'lodash/isEmpty';

import { useTranslations } from 'decorators/Translations/translations';

import { Ruler } from 'components/Ruler';
import Content from 'components/Content/Content';
import SortableTable from 'components/SortableTable/SortableTable';
import TableCell from 'components/BuildingEvents/EventsRow/EventsCell';
import SkeletonText from 'components/Skeletons/SkeletonText';
import KeyValueRow from './KeyValueRow';

const SkeletonBodyComponent = () => (
  <tbody>
    <tr>
      <TableCell>
        <SkeletonText />
      </TableCell>
      <TableCell width="15%">
        <SkeletonText />
      </TableCell>
      <TableCell width="15%">
        <SkeletonText />
      </TableCell>
      <TableCell width="15%">
        <SkeletonText />
      </TableCell>
      <TableCell width="15%">
        <SkeletonText />
      </TableCell>
    </tr>
  </tbody>
);

export const KeyValueContainer = styled(Content)`
  margin: 0 var(--size-md);

  ${props => props.theme.media.portrait`
    margin: 0 ${props => props.theme.grid.gutter};
  `}
`;

const Header = styled.div`
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: ${props => props.theme.font.lineHeight.lg};
  color: ${props => props.theme.colors.black};
`;

const CustomChartKeyValues = ({ chart, sensorData, reference, loading }) => {
  const [t] = useTranslations();
  const theme = useTheme();

  const showTotals = Boolean(chart.keyConfiguration?.showTotals);
  const showAverages = Boolean(chart.keyConfiguration?.showAverages);
  const showMinMax = showTotals || showAverages;

  const header = showTotals ? t('Totals') : showAverages ? t('Averages') : '';

  const columns = useMemo(
    () => [
      {
        title: t('Name'),
        field: 'label',
        sortable: false,
      },
      {
        title: t('Unit'),
        field: 'unit',
        sortable: false,
      },
      {
        title: t(header),
        field: 'value',
        sortable: false,
        align: 'right',
      },
      {
        title: t('Min'),
        field: 'min',
        sortable: false,
        align: 'right',
      },
      {
        title: t('Max'),
        field: 'max',
        align: 'right',
        sortable: false,
      },
    ],
    [t, header]
  );

  if ((!showTotals && !showAverages) || isEmpty(sensorData)) {
    return null;
  }

  let items = [];

  if (showTotals || showAverages) {
    const defaultColors = [...theme.chartColors];
    const calculate = data => {
      if (showTotals && data.sensor?.sensorType?.unit !== '%') {
        return sumBy(data.data, 'value');
      }
      return meanBy(data.data, 'value');
    };
    items = items.concat(
      sensorData
        .map(data => ({
          ...data,
          data: data.data.filter(valueObj => valueObj.value !== null),
        }))
        .filter(data => data.data.length)
        .map(data => ({
          id: data.sensor.id || data.sensor.name,
          label: data.sensor.name,
          value: calculate(data),
          unit: data.sensor.sensorType?.unit || '',
          min: showMinMax && minBy(data.data, 'value').value,
          max: showMinMax && maxBy(data.data, 'value').value,
          color: data.color ?? defaultColors.shift() ?? theme.colors.black,
        }))
    );
  }
  if (reference) {
    items = items.concat([
      {
        id: 'reference',
        label: reference.name,
        value: showTotals ? sum(reference.data) : mean(reference.data),
        min: showMinMax && min(reference.data),
        max: showMinMax && max(reference.data),
        unit: sensorData?.[0]?.sensor.sensorType?.unit,
        color: reference.color,
      },
    ]);
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <Ruler />
      <KeyValueContainer>
        {header && <Header>{header}</Header>}
        <div>
          <SortableTable
            columns={columns}
            data={items}
            RowComponent={rowProps => <KeyValueRow {...rowProps} />}
            SkeletonBodyComponent={SkeletonBodyComponent}
            t={t}
            loading={loading}
            allowScrollX={true}
          />
        </div>
      </KeyValueContainer>
    </>
  );
};

CustomChartKeyValues.propTypes = {
  chart: PropTypes.object.isRequired,
  sensorData: PropTypes.arrayOf(
    PropTypes.shape({
      sensor: PropTypes.shape({
        sensorType: PropTypes.shape({
          unit: PropTypes.string,
          graphType: PropTypes.string,
        }),
        name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
      data: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number,
          timestamp: PropTypes.any,
        })
      ),
      openHours: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    })
  ).isRequired,
  reference: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number),
  }),
  loading: PropTypes.bool,
};

export default CustomChartKeyValues;
