import { Fragment } from 'react';
import styled from 'styled-components';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonSquare from 'components/Skeletons/SkeletonSquare';

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BenchmarkTabSkeleton = () => (
  <Fragment>
    <Columns>
      <Column columnWidth={{ landscape: 6, default: 12 }}>
        <SkeletonText style={{ height: '1em', margin: '1.1em 0' }} />
        <SkeletonText style={{ height: '3.2em' }} />
      </Column>
      <Column columnWidth={{ landscape: 6, default: 12 }}>
        <SkeletonText style={{ height: '1em', margin: '1.1em 0' }} />
        <SkeletonText style={{ height: '3.2em' }} />
      </Column>
    </Columns>
    <FilterContainer>
      <SkeletonSquare style={{ width: '25%', height: '3.25em', marginTop: '2.5em' }} />
    </FilterContainer>
  </Fragment>
);

export default BenchmarkTabSkeleton;
