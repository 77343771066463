import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { legacyColorApproximation } from 'styles/UIv3/legacyColorApproximation';

import { Row, Cell } from './FlexTableBody';

const StyledTableFooter = styled.div`
  display: ${props => (props.hideOnMobile ? 'none' : 'flex')};
`;
StyledTableFooter.displayName = 'StyledTableFooter';

const FooterRow = styled(Row)`
  ${props =>
    !props.backgroundColor &&
    css`
      background-color: var(--table-footer-bg);
    `}
  ${props =>
    props.backgroundColor &&
    css`
      background-color: ${legacyColorApproximation[props.backgroundColor]};
    `}

  border-top: var(--table-row-border);
  border-bottom: var(--table-row-border);
`;
FooterRow.displayName = 'FooterRow';

export const FooterCell = styled(Cell)`
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  color: var(--black);
  white-space: nowrap;
  vertical-align: bottom;
  display: ${props => props.hideOnMobile && 'none'};
  width: ${props => (props.cellWidthMobile ? props.cellWidthMobile : 'auto')};
  overflow: hidden;
  text-overflow: ellipsis;

  ${props => props.theme.media.portrait`
        width: ${props => (props.cellWidth ? props.cellWidth : props.cellWidthMobile || 'auto')};
    `}

  ${props => props.theme.media.landscape`
        display: ${props => (props.hideOnDesktop ? 'none' : 'block')};
    `}

    @media print {
    display: ${props => (props.hideOnPrint ? 'none' : 'block')};
  }
`;
FooterCell.displayName = 'FooterCell';

const FlexTableFooter = ({ footerGroups = [] }) => (
  <StyledTableFooter>
    {footerGroups.map(footerGroup => (
      <FooterRow {...footerGroup.getFooterGroupProps()}>
        {footerGroup.headers.map((column, i) => (
          <FooterCell key={i} style={column.style} hideOnMobile={column.hideOnMobile}>
            {column.render('Footer')}
          </FooterCell>
        ))}
      </FooterRow>
    ))}
  </StyledTableFooter>
);

FlexTableFooter.propTypes = {
  footerGroups: PropTypes.array,
};

export default FlexTableFooter;
