import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledSimpleDropdown, Content } from 'components/SimpleDropdown/SimpleDropdown';
import SimpleDropdownItem from 'components/SimpleDropdown/SimpleDropdownItem';
import Svg from 'components/Svg/Svg';
import OnOffSwitch from 'components/Button/OnOffSwitch';

const SubDropdown = styled.div`
  position: absolute;
  top: 0;

  right: ${props => (props.mobileAlignment === 'left' ? 0 : 'auto')};
  transform: ${props => (props.mobileAlignment === 'right' ? 'translateX(-100%)' : 'translateX(100%)')};

  ${props => props.theme.media.portrait`
    right: ${props => (props.alignment === 'left' ? 0 : 'auto')};
    transform: ${props => (props.alignment === 'right' ? 'translateX(-100%)' : 'translateX(100%)')};
  `}
`;
SubDropdown.displayName = 'SubDropdown';

const ArrowIcon = styled(Svg)`
  font-size: 50%;
  fill: var(--dropdown-control-fg);
  margin-left: var(--size-md);
`;
ArrowIcon.displayName = 'ArrowIcon';

export const Ruler = styled.hr`
  border: solid var(--light-separator-color);
  border-width: 0 0 var(--border-thin) 0;
  margin: 0;
`;
Ruler.displayName = 'Ruler';

const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
ItemContent.displayName = 'ItemContent';

const RelativeItem = styled.div`
  position: relative;
`;
RelativeItem.displayName = 'RelativeItem';

const ToggleWrapper = styled.div`
  margin-left: var(--size-md);
`;
ToggleWrapper.displayName = 'ToggleWrapper';

const Label = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${props => (props.textAlign === 'center' ? 'center' : 'flex-start')};
`;

const ButtonDropdownItem = ({ item, onCloseAll, subMenuAlignment, mobileSubMenuAlignment, textAlign }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const timeoutRef = useRef(null);

  const openMenu = event => {
    event.preventDefault();
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setMenuOpen(true);
  };
  const closeMenu = event => {
    event.preventDefault();
    timeoutRef.current = setTimeout(() => setMenuOpen(false), 300);
  };

  if (!item.label) {
    return <Ruler key={item.id} />;
  }

  const handleItemClick =
    item =>
    (...params) => {
      if (item.activeToggle === undefined) {
        onCloseAll();
      }
      item.onClick(...params);
    };

  const hasItems = Boolean(item.items);

  return (
    <RelativeItem onMouseEnter={hasItems ? openMenu : undefined} onMouseLeave={hasItems ? closeMenu : undefined}>
      <SimpleDropdownItem
        id={item.id}
        onClick={item.onClick ? handleItemClick(item) : undefined}
        bold={item.bold}
        useWarningColor={item.useWarningColor}
        disabled={item.disabled}
      >
        <ItemContent>
          <Label textAlign={textAlign}>{item.label}</Label>
          {item.items && <ArrowIcon name="caret-right" />}
          {item.activeToggle !== undefined && (
            <ToggleWrapper>
              <OnOffSwitch active={item.activeToggle} disabled={item.disabled} />
            </ToggleWrapper>
          )}
        </ItemContent>
      </SimpleDropdownItem>
      {hasItems && menuOpen && (
        <SubDropdown alignment={subMenuAlignment} mobileAlignment={mobileSubMenuAlignment}>
          <StyledSimpleDropdown>
            <Content>
              {item.items.map(subItem => (
                <SimpleDropdownItem
                  id={subItem.id}
                  key={subItem.id || subItem.label}
                  onClick={handleItemClick(subItem)}
                  useWarningColor={subItem.useWarningColor}
                  bold={subItem.bold}
                  disabled={subItem.disabled}
                >
                  <Label textAlign={textAlign}>{subItem.label}</Label>
                </SimpleDropdownItem>
              ))}
            </Content>
          </StyledSimpleDropdown>
        </SubDropdown>
      )}
    </RelativeItem>
  );
};

ButtonDropdownItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    bold: PropTypes.bool,
    onClick: PropTypes.func,
    useWarningColor: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        bold: PropTypes.bool,
        onClick: PropTypes.func,
        useWarningColor: PropTypes.bool,
      })
    ),
    activeToggle: PropTypes.bool,
    disabled: PropTypes.bool,
  }).isRequired,
  subMenuAlignment: PropTypes.oneOf(['right', 'left']).isRequired,
  mobileSubMenuAlignment: PropTypes.oneOf(['right', 'left']).isRequired,
  onCloseAll: PropTypes.func.isRequired,
  textAlign: PropTypes.oneOf(['left', 'center']),
};

export default ButtonDropdownItem;
