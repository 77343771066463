import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withErrorBoundary } from 'components/ErrorPage/ErrorBoundary';
import { useTranslations } from 'decorators/Translations/translations';

import Svg from 'components/Svg/Svg';
import { TitleWrapper, Title, Subtitle } from './OPICard';
import { Card } from './Card';

const Icon = styled(Svg)`
  fill: var(--opi-card-error-icon-fg);
  font-size: 60px;
`;
Icon.displayName = 'Icon';

const ErrorIconContainer = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
ErrorIconContainer.displayName = 'ErrorIconContainer';

export const withOpiCardError = Component =>
  withErrorBoundary(Component, {
    FallbackComponent: OPICardError,
    wrapperName: 'WithOpiCardError',
    passOriginalPropsToFallback: true,
  });

const OPICardError = ({ originalProps }) => {
  const { title } = originalProps;
  const [t] = useTranslations();
  return (
    <Card>
      <ErrorIconContainer>
        <Icon name="dead-page" />
      </ErrorIconContainer>
      <TitleWrapper>
        <Title>{t(title)}</Title>
        <Subtitle>{t('Unexpected error')}</Subtitle>
      </TitleWrapper>
    </Card>
  );
};

OPICardError.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string }),
  originalProps: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default OPICardError;
