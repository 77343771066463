import PropTypes from 'prop-types';
import StatusTag from 'components/Tag/StatusTag';
import Tag from 'components/Tag/Tag';
import { styles } from './tagUtils';

const CalendarStatusTag = props => {
  const { status, text, onClick, small, hasCursor, statusColors, showFullText, tagWidth } = props;
  const sharedProps = {
    ...styles(status, statusColors),
    onClick,
    small,
    hasCursor,
    tagWidth,
  };
  return showFullText ? (
    <Tag {...sharedProps} showFullText>
      {text}
    </Tag>
  ) : (
    <StatusTag {...sharedProps} text={text} />
  );
};

CalendarStatusTag.propTypes = {
  status: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  hasCursor: PropTypes.bool,
  statusColors: PropTypes.object,
  showFullText: PropTypes.bool,
  tagWidth: PropTypes.string,
};

export default CalendarStatusTag;
