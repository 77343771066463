import PropTypes from 'prop-types';
import styled from 'styled-components';

const Nav = styled.nav`
  background-color: ${props => props.theme.colors.midnight};
  border-top: var(--border-thin) solid ${props => props.theme.colors.kashmirBlue};
  padding: var(--size-md);
  text-align: center;

  ${props => props.theme.media.landscape`
    display: none;
  `}
`;

const Tab = styled.a`
  display: inline-block;
  font: var(--font-xs-bold);
  color: var(${props => (props.selected ? '--white' : '--grey-50')});
  padding: var(--size-xxs) var(--size-xxxs);
  margin: 0 var(--size-xs);
  border-bottom: var(--border-semibold) solid ${props => (props.selected ? 'var(--blue-02)' : 'transparent')};
  cursor: ${props => props.hasPointer && 'pointer'};
`;

const MobileSelector = ({ tabs, selected, onSelect }) => (
  <Nav>
    {tabs.map((tab, index) => (
      <Tab
        key={tab}
        selected={selected === index}
        onClick={onSelect ? () => onSelect(index) : undefined}
        hasPointer={!!onSelect}
      >
        {tab}
      </Tab>
    ))}
  </Nav>
);

MobileSelector.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
};

export default MobileSelector;
