import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import translations from 'decorators/Translations/translations';
import DialogModal from 'components/Dialog/DialogModal';
import { InputRow, InputLabel, InputText, InputForm, InputSelectDropdown } from 'components/index';
import { upsertSensorHierarchy, loadSensorHierarchies } from 'redux/modules/customer/sensorHierarchy';

const FormContainer = styled.section`
  margin-bottom: var(--size-xl);
`;

export const SENSOR_GROUP = {
  GENERIC: 'group',
  ENERGY: 'group_energy',
  WASTE: 'group_waste',
  WATER: 'group_water',
  HEATING: 'group_heating',
  COOLING: 'group_cooling',
  VENTILATION: 'group_ventilation',
  ELECTRICITY: 'group_electricity',
  SUSTAINABILITY: 'group_sustainability',
  RECYCLING_SYNTHETICS: 'group_recycling_synthetics',
};

export const GROUP_TYPES = [
  {
    value: SENSOR_GROUP.GENERIC,
    label: 'Generic',
  },
  {
    value: SENSOR_GROUP.ENERGY,
    label: 'Energy',
  },
  {
    value: SENSOR_GROUP.WASTE,
    label: 'Waste',
  },
  {
    value: SENSOR_GROUP.WATER,
    label: 'Water',
  },
  {
    value: SENSOR_GROUP.HEATING,
    label: 'Heating',
  },
  {
    value: SENSOR_GROUP.COOLING,
    label: 'Cooling',
  },
  {
    value: SENSOR_GROUP.VENTILATION,
    label: 'Ventilation',
  },
  {
    value: SENSOR_GROUP.ELECTRICITY,
    label: 'Electricity',
  },
  {
    value: SENSOR_GROUP.RECYCLING_SYNTHETICS,
    label: 'Recycling synthetics',
  },
  {
    value: SENSOR_GROUP.SUSTAINABILITY,
    label: 'Sustainability',
  },
];

const SUM_GROUPS = [
  SENSOR_GROUP.ELECTRICITY,
  SENSOR_GROUP.HEATING,
  SENSOR_GROUP.COOLING,
  SENSOR_GROUP.WATER,
  SENSOR_GROUP.VENTILATION,
];

const SUM_SUB_TYPE = 'sum';

const resolveSubTypeForGroup = type => {
  if (SUM_GROUPS.includes(type)) {
    return SUM_SUB_TYPE;
  }
};

class EditOrCreateGroup extends Component {
  state = {
    error: '',
    loading: false,
    model: {
      ...this.props.group,
      type: this.props.group.type ?? 'group',
    },
  };

  handleSubmit = () => {
    const { model } = this.state;
    const data = {
      id: model.id,
      type: model.type,
      name: model.name,
      parentId: this.props.building.id,
      functionalLocation: this.props.functionalLocation,
      subtype: resolveSubTypeForGroup(model.type),
    };

    this.setState({ loading: true });
    this.props.upsertSensorHierarchy(data).then(({ result }) => {
      if (result) {
        this.afterSubmit();
      } else {
        this.setState({ loading: false });
      }
    });
  };

  afterSubmit = () => {
    this.props.loadSensorHierarchies(this.props.functionalLocation).then(() => {
      this.setState({ loading: false });
      const notification = {
        type: 'success',
        message: this.props.t('Group successfully saved'),
      };
      this.props.onSubmit(null, notification);
    });
  };

  handleFormChange = (property, value) => {
    this.setState(prevState => ({
      model: {
        ...prevState.model,
        [property]: value,
      },
    }));
  };

  getGroupOptions(types, t) {
    return types.map(type => ({ value: type.value, label: t(type.label) }));
  }

  render() {
    const { t, onCancel } = this.props;
    const { loading, model } = this.state;
    const groupOptions = this.getGroupOptions(GROUP_TYPES, t);

    return (
      <DialogModal
        isActive
        t={t}
        onOverlayClick={onCancel}
        actions={{
          cancel: {
            onClick: onCancel,
          },
          confirm: {
            onClick: this.handleSubmit,
            loading,
          },
        }}
      >
        <FormContainer>
          <h3>{t(model.id ? 'Edit group' : 'Add group')}</h3>
          <InputForm onPropertyChange={this.handleFormChange} model={model} id="adminGroupForm">
            <InputRow required id="groupName">
              <InputLabel text={t('Group name')} />
              <InputText property="name" />
            </InputRow>
            <InputRow required>
              <InputLabel text={t('Group type')} />
              <InputSelectDropdown
                model={model}
                disabled={Boolean(model.id)}
                property="type"
                options={groupOptions}
                t={t}
                clearable={false}
                id="groupType"
                onChange={this.handleFormChange}
                scrollToMenu
              />
            </InputRow>
          </InputForm>
        </FormContainer>
      </DialogModal>
    );
  }
}

EditOrCreateGroup.propTypes = {
  t: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  functionalLocation: PropTypes.string.isRequired,
  building: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loadSensorHierarchies: PropTypes.func.isRequired,
  upsertSensorHierarchy: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  upsertSensorHierarchy: data => dispatch(upsertSensorHierarchy(data)),
  loadSensorHierarchies: functionalLocation => dispatch(loadSensorHierarchies(functionalLocation, true)),
});

const connector = connect(null, mapDispatchToProps);

export default connector(translations(EditOrCreateGroup));
