import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import Svg from 'components/Svg/Svg';

const Value = styled.div`
  margin: var(--size-xs) 0;
  font-size: ${props => props.theme.fontSize.md};
  color: ${props => (props.isLoading ? props.theme.colors.rockBlue : props.theme.colors.black)};
  font-weight: ${props => props.theme.font.weight.normal};
  order: 1;
  display: ${props => (props.hideOnMobile || props.isLoading) && 'none'};

  ${props => props.theme.media.landscape`
    font-size: ${props => props.theme.font.size.md};
    order: 0;
    display: block;
    margin-top: auto;
  `}

  ${props => props.theme.media.desktop`
    font-size: ${props => props.theme.font.size.lg};
  `}
`;
Value.displayName = 'Value';

const Unit = styled.span`
  font-size: ${props => props.small && props.theme.font.size.xs};

  ${props => props.theme.media.landscape`
    font-size: ${props => props.small && props.theme.font.size.xxs};
  `}

  ${props => props.theme.media.desktop`
    font-size: ${props => props.small && props.theme.font.size.md};
  `}
`;
Unit.displayName = 'Unit';

const TrendArrow = styled(({ value, ...props }) => <Svg {...props} />)`
  fill: ${props => props.color || props.theme.status.neutralOkColor};
  transform: ${props =>
    (props.direction === 'up' && 'rotate(-40deg)') || (props.direction === 'down' && 'rotate(40deg)')};
`;
TrendArrow.displayName = 'TrendArrow';

const StatusInfoOutsideValue = ({
  value,
  spaceBeforeUnit,
  unit,
  loading,
  positiveValueDirection,
  theme,
  hideOnMobile,
  showArrow,
}) => {
  if (loading) {
    return <Value isLoading>...</Value>;
  }

  if (isNil(value)) {
    return <Value>N/A</Value>;
  }

  const trendDirection = value >= 0 ? 'up' : 'down';
  const hasPositiveTrend = positiveValueDirection === trendDirection;
  const trendColor = hasPositiveTrend ? theme.status.okColor : theme.status.alarmColor;

  return (
    <Value hideOnMobile={hideOnMobile} small={unit && unit.length > 1}>
      {unit !== '%' && showArrow && value >= 0 && '+'}
      {value}
      {spaceBeforeUnit && ' '}
      <Unit small={unit && unit.length > 1}>{unit}</Unit>
      {showArrow && (
        <TrendArrow color={trendColor} direction={trendDirection} name="kpi-trend-arrow" width="0.8em" height="0.8em" />
      )}
    </Value>
  );
};

export default withTheme(StatusInfoOutsideValue);

StatusInfoOutsideValue.defaultProps = {
  value: null,
  spaceBeforeUnit: true,
  unit: '',
  loading: false,
  hideOnMobile: false,
  positiveValueDirection: null,
  showArrow: false,
};

StatusInfoOutsideValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  spaceBeforeUnit: PropTypes.bool,
  unit: PropTypes.string,
  loading: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
  positiveValueDirection: PropTypes.string,
  showArrow: PropTypes.bool,
  theme: PropTypes.object,
};
