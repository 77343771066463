import { rem, rgba, transparentize } from 'polished';

import { CSSAttributeMap, FontTheme, NumericValueMap } from './ThemeTypes';

/*
    Sizing naming:
      extra extra extra small:  xxxs
      extra extra small:        xxs
      extra small:              xs
      small:                    sm
      medium:                   md
      large:                    lg
      extra large:              xl
      extra extra large         xxl
*/

// Default rem size
export const rootSize = 16;
export const textRootSize = 17;
// Amount of pixels to be added to root font size
export const sizeAdd = textRootSize - rootSize;

/**
 * Colors
 * Please do not add new colors without asking the UI designer.
 * Use transparentize from polished or alpha channel for additional lighter colors, if needed.
 */
export const colors: CSSAttributeMap = {
  black: '#222222',
  white: '#FFFFFF',
  mystic: '#E7ECF1',
  geyser: '#D3DEEA',
  rockBlue: '#A2B3C9',
  kashmirBlue: '#446794',
  blue: '#002F6C',
  midnight: '#001F45',
  alabaster: '#F7F7F7', // Chart backgrounds
  concrete: '#F2F2F2',
  silver: '#CCCCCC',
  lightGray: '#E6E6E6',
  darkGray: '#888E97', // Chart text labels & chart lines
  radicalRed: '#FF4656',
  orange: '#FF8F1C',
  yellow: '#FFCC00',
  emerald: '#3CC87A',
  cerulean: '#00B5E3',
  amethyst: '#8951cd',
  serenade: '#fff3e9',
  peachOrange: '#fec999',
  violet: '#8b61cc',
  transparent: 'transparent',
};

/**
 * Colors named and defined in Caverion style guide
 */
export const brandColors: CSSAttributeMap = {
  // Primary colors
  caverionBlue: '#002d6e',
  caverionPrimaryGreen: '#008f3c',
  caverionWarmConcrete: '#fff5f2',
  white: '#ffffff',
  // Secondary colors
  // - Cool palette
  green01: '#b4d200',
  turquoise01: '#46bcaf',
  lightblue: '#0073be',
  // - Warm palette
  magenta01: '#f54373',
  orange01: '#ff9d00',
  // Tertiary colors - extreme cases
  // - Cool palette
  green02: '#73a00f',
  turquoise02: '#009ba5',
  // - Warm palette
  magenta02: '#be2d78',
  orange02: '#ff6400',
};

/**
 * Color palette for charts
 *
 * Please do not add new colors without asking the UX designer
 */

export const chartColors: CSSAttributeMap = {
  chartTurqoise: '#46BCAF',
  chartDarkBlue: '#002D6E',
  chartViolet: '#9669D2',
  chartOrange: '#F5AF32',
  chartTeal: '#007171',
  chartRock: '#BCC8D4',
  chartMagenta: '#c83c78',
  chartGreen: '#3CC87A',
};

export const display: CSSAttributeMap = {
  inline: 'inline',
  block: 'block',
  inlineBlock: 'inline-block',
  none: 'none',
};

export const font: FontTheme = {
  // Font families
  family: {
    cairo: 'Cairo, sans-serif',
  },
  // Font weights
  weight: {
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  // Font sizes
  size: {
    root: textRootSize + 'px',
    xxxs: rem(0.625 * rootSize + sizeAdd),
    xxs: rem(0.75 * rootSize + sizeAdd),
    xs: rem(0.875 * rootSize + sizeAdd),
    sm: rem(rootSize + sizeAdd),
    md: rem(1.25 * rootSize + sizeAdd),
    lg: rem(1.5 * rootSize + sizeAdd),
    xl: rem(1.75 * rootSize + sizeAdd),
    xxl: rem(2 * rootSize + sizeAdd),
    xxxl: rem(2.5 * rootSize + sizeAdd),
    button: '14px',
  },
  // Line height
  lineHeight: {
    sm: 1,
    md: 1.25,
    lg: 1.375,
  },
  letterSpacing: {
    default: '1.5px',
    button: '0.2px',
    cardSmallText: '0.3px',
  },
};

export const iconSize: CSSAttributeMap = {
  xxxs: rem(0.625 * rootSize),
  xxs: rem(0.75 * rootSize),
  xs: rem(0.875 * rootSize),
  sm: rem(rootSize),
  md: rem(1.25 * rootSize),
  lg: rem(1.5 * rootSize),
  xl: rem(1.75 * rootSize),
  xxl: rem(2 * rootSize),
  xxxl: rem(2.5 * rootSize),
};

export const size: Record<'spacing' | 'border', CSSAttributeMap> = {
  // Available spacings
  spacing: {
    xxxs: rem(0.125 * rootSize),
    xxs: rem(0.25 * rootSize),
    xs: rem(0.5 * rootSize),
    sm: rem(0.75 * rootSize),
    md: rem(rootSize),
    mdPlus: rem(1.25 * rootSize),
    lg: rem(1.5 * rootSize),
    xl: rem(2 * rootSize),
    xxl: rem(3 * rootSize),
  },
  border: {
    sm: '1px',
    md: '2px',
    lg: '3px',
  },
};

export const grid: CSSAttributeMap = {
  gutter: size.spacing.lg,
};

export const shadows: CSSAttributeMap = {
  default: `0 2px 5px ${rgba(colors.black, 0.1)}`,
  card: `0 2px 4px 0 ${transparentize(0.95, colors.black)}`,
  kpiCard: `0 5px 5px 2px ${rgba(colors.black, 0.08)}`,
  opiCard: `0 5px 5px 2px ${rgba(colors.black, 0.06)}`,
  meterBox: `0 5px 5px 2px ${rgba(colors.black, 0.08)}`,
};

export const time: CSSAttributeMap = {
  quick: '150ms',
  default: '250ms',
  long: '1000ms',
};

export const text: CSSAttributeMap = {
  p: font.size.sm,
  h1: font.size.xxxl,
  h2: font.size.xxl,
  h3: font.size.lg,
};

export const breakpoints: NumericValueMap = {
  mobile: 480,
  portrait: 600,
  tablet: 768,
  landscape: 900,
  desktop: 1280,
  bigDesktop: 1600,
};

export type BreakpointName = keyof typeof breakpoints;
export type BreakpointValue = (typeof breakpoints)[BreakpointName];

/**
 * Stacking order for component z-indexes from lowest to highest.
 * Use with zIndex() function that is included on the theme (usage example: zIndex('menu')).
 * If needed, z-index can be bumped up manually in local context: zIndex('menu') + 1
 * but try to avoid this.
 * Z-indexes below base z-index (100) are also free to use for local stacking adjustments.
 * Please try to keep all local adjustments as minimal as possible and below 10.
 */
export const zIndexKeys = [
  'filter',
  'hero',
  'sideNavi',
  'circleTabs',
  'header',
  'menu',
  'sideFilter',
  'dropdown',
  'infoTip',
  'announcements',
  'feedback',
  'blueprint',
  'tos',
  'modal',
  'contextualHelp',
  'snackbar',
  'snackbarError',
];

const baseZIndex = 100;

export type ZIndexName = (typeof zIndexKeys)[number];

export const zIndex = (key: ZIndexName) => {
  const keyIndex = zIndexKeys.indexOf(key);
  if (keyIndex > -1) {
    return baseZIndex + (keyIndex + 1) * 10;
  }
  return baseZIndex;
};

export const defaultColor = '#1a9edd';
