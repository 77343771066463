import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import map from 'lodash/map';
import range from 'lodash/range';
import take from 'lodash/take';

import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import PerformanceList from 'components/PerformanceList/PerformanceList';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonChart from 'components/Skeletons/SkeletonChart';

export const ListWrapper = styled.div`
  flex: 1;
  ${props => props.theme.media.landscape`
    &:first-child:not(:only-child) {
      margin-right: var(--size-lg);
    }
  `};
`;

export const Performances = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${props => props.theme.media.landscape`
        flex-flow: row nowrap;
        justify-content: space-between;
    `};
`;
Performances.displayName = 'Performances';

export const Filter = styled.div`
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Select-control {
    width: 300px;
  }
  ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;
Filter.displayName = 'Filter';

export const FilterInfo = styled.span`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.default};
  text-transform: uppercase;
  margin-bottom: 1em;
  ${props => props.theme.media.landscape`
        margin-bottom: auto;
        margin-top: auto;
        margin-right: 1em;
    `};
`;
FilterInfo.displayName = 'FilterInfo';

const getSkeleton = () => {
  return (
    <Section>
      <SectionHeader>
        <SkeletonText header />
      </SectionHeader>
      <SkeletonText height="200px" />
      <SkeletonChart height="200px" />
    </Section>
  );
};

const Benchmarking = props => {
  const { t, ctxHelp, leftTitle, rightTitle, leftData, rightData, infoText, loading } = props;

  const [limit, setLimit] = useState(10);

  if (loading) {
    return getSkeleton();
  }

  const generateFilterSelection = () => {
    const options = map(range(5, leftData.length, 5), index => ({ label: `${index} ${t('buildings')}`, value: index }));
    options.push({ label: `${leftData.length} ${t('buildings')}`, value: leftData.length });
    return options;
  };

  return (
    <Section>
      <SectionHeader
        title={t('Benchmarking')}
        ctxHelp={ctxHelp}
        descriptionText={t('Buildings')}
        descriptionValue={leftData.length}
        noBorder
        size="small"
      />
      <Performances>
        <ListWrapper>
          <PerformanceList
            t={t}
            valueWidth={80}
            title={leftTitle}
            info={infoText}
            items={take(leftData, limit)}
            clickEnabled
          />
        </ListWrapper>
        <ListWrapper>
          <PerformanceList
            t={t}
            valueWidth={80}
            title={rightTitle}
            info={infoText}
            items={take(rightData, limit)}
            clickEnabled
          />
        </ListWrapper>
      </Performances>
      <Filter>
        <FilterInfo>{t('Show')}</FilterInfo>
        <InputSelectDropdown
          t={t}
          options={generateFilterSelection()}
          onChange={(property, value) => setLimit(value)}
          model={{ limit }}
          property="limit"
          clearable={false}
        />
      </Filter>
    </Section>
  );
};

Benchmarking.propTypes = {
  t: PropTypes.func.isRequired,
  ctxHelp: PropTypes.string.isRequired,
  leftTitle: PropTypes.string.isRequired,
  rightTitle: PropTypes.string.isRequired,
  leftData: PropTypes.array.isRequired,
  rightData: PropTypes.array.isRequired,
  infoText: PropTypes.string,
  loading: PropTypes.bool,
};

export default Benchmarking;
