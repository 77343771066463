import PropTypes from 'prop-types';
import FramedListItemWithHandleAndCheckbox, {
  FramedListItemLocked,
} from 'components/DraggableList/FramedListItemWithHandleAndCheckbox';
import DraggableList from 'components/DraggableList/DraggableList';
import {
  ConfigurationModalHeader,
  HelpText,
  ConfigurationModalSection,
  ConfigurationModalSectionHeader,
} from 'components/Dialog/DialogConfiguration';
import { ContentType } from 'constants/maintenance';
import InfoBox from 'components/InfoBox/InfoBox';

const ReorderableColumnList = ({ t, columns, onCheckbox, onReorder, contentType }) => {
  const isPlannedMaintenance = contentType === ContentType.PLANNED;

  const items = columns.map(([field, config]) => ({
    id: field,
    content: config.label,
    checked: config.selected,
    locked: config.disabled,
    order: config.order,
    onChange: value => {
      if (!config.disabled) {
        onCheckbox(field, value);
      }
    },
  }));

  return (
    <>
      <ConfigurationModalHeader>{t('List configuration')}</ConfigurationModalHeader>
      <HelpText>{t('listConfigurationOrder.helpText')}</HelpText>
      <ConfigurationModalSection>
        <ConfigurationModalSectionHeader>{t('Columns')}</ConfigurationModalSectionHeader>
        <DraggableList
          id="table-configuration-column-list"
          items={items}
          setItems={newOrder => onReorder(newOrder.map(item => item.id))}
          ListItemComponent={FramedListItemWithHandleAndCheckbox}
          LockedItemComponent={FramedListItemLocked}
        />
      </ConfigurationModalSection>
      {isPlannedMaintenance && <InfoBox>{t('listConfiguration.infoText.plannedMaintenance')}</InfoBox>}
    </>
  );
};

ReorderableColumnList.propTypes = {
  t: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  onCheckbox: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  contentType: PropTypes.oneOf(Object.values(ContentType)),
};

export default ReorderableColumnList;
