import { memo } from 'react';
import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';
import { format } from 'utils/Date/dateFormatter';
import translations from 'decorators/Translations/translations';

import InfoTable from 'components/Maintenance/InfoTable';

const getItems = (t, order) =>
  order
    ? [
        { label: t('ID'), value: order.serviceOrderNumber },
        { label: t('Functional location'), value: order.functionalLocation?.functionalLocation },
        { label: t('Category'), value: t(order.discipline) },
        { label: t('Detailed location'), value: order.locationDetails },
        { label: t('Created'), value: format(parseISO(order.createdDate), 'PPP, HH:mm') },
        { label: t('Last Update'), value: format(parseISO(order.modifiedDate), 'PPP, HH:mm') },
      ].filter(item => !!item.value)
    : [];

export const TechniciansViewInfo = ({ t, order, loading }) => {
  return <InfoTable header={t('Service Order Info')} loading={loading} items={getItems(t, order)} />;
};

TechniciansViewInfo.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
};

export default memo(translations(TechniciansViewInfo));
