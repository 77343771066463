import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslations } from 'decorators/Translations/translations';
import ControlledPager from 'components/Pager/ControlledPager';
import PrimaryButton from 'components/Button/PrimaryButton';

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: var(--size-md) 0;

  ${props => props.theme.media.landscape`
    flex-direction: row;
    margin-top: var(--size-lg);
    margin-bottom: ${props => (props.small ? 'var(--size-xs)' : 'var(--size-lg)')};
  `}
`;

const PagerContainer = styled.div`
  order: -1;

  ${props => props.theme.media.landscape`
    order: 0;
  `}
`;

const Count = styled.div`
  font-size: ${props => props.theme.fontSize.xs};
  color: var(--grey-50);
  ${props => (props.mobileMargin ? 'margin: var(--size-md) 0 var(--size-lg);' : '')}

  ${props => props.theme.media.landscape`
        margin: 0;
    `}
`;

const Number = styled.span`
  color: var(--btn-default-fg);
  font-weight: ${props => props.theme.fontWeight.bold};
`;

const Link = styled.a`
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.bold};
  &:hover {
    cursor: pointer;
  }
`;

const getMinMaxRows = (page, pageSize, totalRowsCount) => {
  let maxRow = page * pageSize;
  const minRow = maxRow - pageSize + 1;
  maxRow = maxRow > totalRowsCount ? totalRowsCount : maxRow;
  return {
    minRow,
    maxRow,
  };
};

const scrollTo = ref => {
  if (ref?.current) {
    window.requestAnimationFrame(() => {
      window.scrollBy({
        behavior: 'smooth',
        top: ref.current.getBoundingClientRect().top - 160,
        left: 0,
      });
    });
  }
};

const Pagination = props => {
  const {
    showAll,
    onToggleShowAll,
    rowCount,
    gotoPage,
    onChangePageIndex,
    pageIndex,
    pageSize,
    tableRef,
    useSimpleShowAll,
    manualPagination,
  } = props;
  const [t] = useTranslations();
  const { minRow, maxRow } = getMinMaxRows(pageIndex + 1, pageSize, rowCount);
  const currentRows = showAll ? rowCount : `${minRow}-${maxRow}`;

  const onChangePage = page => {
    const newPageIndex = page - 1;
    gotoPage(newPageIndex);
    if (typeof onChangePageIndex === 'function') {
      onChangePageIndex(newPageIndex);
    }
    scrollTo(tableRef);
  };

  if (rowCount <= pageSize && !manualPagination) {
    return null;
  }

  return (
    <PaginationContainer small={useSimpleShowAll}>
      <Count mobileMargin>
        {`${t('Showing')}: `}
        <Number>{currentRows}</Number> / <Number>{rowCount}</Number>
      </Count>
      {!showAll && !useSimpleShowAll && (
        <PagerContainer>
          <ControlledPager page={pageIndex + 1} limit={pageSize} totalResults={rowCount} onChange={onChangePage} />
        </PagerContainer>
      )}
      {useSimpleShowAll ? (
        <Link onClick={onToggleShowAll}>{showAll ? `${t('Show less')} -` : `${t('Show all')} (${rowCount}) +`}</Link>
      ) : (
        <PrimaryButton reveal invertColor large onClick={onToggleShowAll}>
          {showAll ? t('Show less') : t('Show all')}
        </PrimaryButton>
      )}
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  showAll: PropTypes.bool,
  onToggleShowAll: PropTypes.func,
  rowCount: PropTypes.number,
  gotoPage: PropTypes.func,
  onChangePageIndex: PropTypes.func,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  tableRef: PropTypes.object,
  useSimpleShowAll: PropTypes.bool,
  manualPagination: PropTypes.bool,
};

export default Pagination;
