import { createReducerFromMapping } from 'redux/utils/index';

import { loadFunctionalLocationsImages, loadPartnerImages } from 'redux/modules/customer/partnerImage';
import { loadPartnerMeta } from 'redux/modules/customer/partnerMeta';
import { fetchConfig, loadFunctionalLocationsForOverview } from 'redux/modules';

const initialState = {
  loading: true,
  error: false,
};

export const PARTNER_OVERVIEW_LOAD = 'CUSTOMER_PLATFORM/PartnerOverview/LOAD';
export const PARTNER_OVERVIEW_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/PartnerOverview/LOAD_SUCCESS';
export const PARTNER_OVERVIEW_LOAD_FAIL = 'CUSTOMER_PLATFORM/PartnerOverview/LOAD_FAIL';

export const loadPartnerOverview = (partnerNumber, skipPartnerImage = false) => {
  return async dispatch => {
    dispatch({ type: PARTNER_OVERVIEW_LOAD });
    try {
      if (partnerNumber && partnerNumber !== 'all') {
        if (!skipPartnerImage) {
          dispatch(loadPartnerImages(partnerNumber));
        }
        dispatch(loadPartnerMeta(partnerNumber));
      }

      const flResult = await dispatch(loadFunctionalLocationsForOverview(partnerNumber));
      const permissions = flResult.result;

      if (permissions) {
        dispatch(loadFunctionalLocationsImages(permissions.map(x => x.functionalLocation)));
      }

      dispatch(fetchConfig());

      // Don't wait for the images to load
      return dispatch({ type: PARTNER_OVERVIEW_LOAD_SUCCESS });
    } catch (error) {
      return dispatch({
        type: PARTNER_OVERVIEW_LOAD_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [PARTNER_OVERVIEW_LOAD]: state => ({
      ...state,
      loading: true,
    }),
    [PARTNER_OVERVIEW_LOAD_SUCCESS]: state => ({
      ...state,
      loading: false,
    }),
    [PARTNER_OVERVIEW_LOAD_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  },
  initialState
);
