import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const loadDeviations = data =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/deviations/find`,
    data,
    useAuthorization: true,
  });

export const loadDeviationStatistics = data =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/deviations/find-statistics`,
    data,
    useAuthorization: true,
  });

export const loadDeviationFilterOptions = data =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/deviations/find-filter-options`,
    data,
    useAuthorization: true,
  });

export const updateDeviationStatus = statusUpdates =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/deviations/status`,
    data: statusUpdates,
    noCache: true,
  });
