import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import memoizeOne from 'memoize-one';
import { withRouter } from 'react-router';
import { NameColumn } from '../SortableFunctionalLocationTable/SortableFLTableNameColumn';
import { AddressColumn } from '../SortableFunctionalLocationTable/SortableFLTableAddressColumn';
import EquipmentColumn from '../SortableFunctionalLocationTable/SortableFLTableEquipmentColumn';
import { IconColumn } from '../SortableFunctionalLocationTable/SortableFLTableIconColumn';
import ImageColumn from '../SortableFunctionalLocationTable/SortableFLTableImageColumn';
import FlexTable, { FlexTableWithQueryPaging, cellPropTypes } from 'components/FlexTable/FlexTable';
import { getFunctionalLocationUrl, getEquipmentUrl } from 'utils/Data/functionalLocations';
import ValueBarIndicatorColumn from '../Conditions/ConditionTable/ValueBarIndicatorColumn';
import SelectMissingPartner from './SelectMissingPartner';

const NoResult = styled.p`
  font-family: ${props => props.theme.fontFamily.text};
  font-size: ${props => props.theme.fontSize.xs};
  color: var(--supplemental-text-fg);
  margin: var(--size-md);
`;

const StyledCountColumn = styled.div`
  color: var(--supplemental-text-fg);
  text-align: right;
`;

const performanceTranslationKeys = {
  indoorAirQuality: 'Indoor Air Quality',
  energy: 'Energy Rating',
  energyConsumption: 'Energy Consumption',
  alarms: 'Alarms',
  observations: 'Observations',
};

const CountCell = ({ row }) => <StyledCountColumn>{row.original.count}</StyledCountColumn>;
CountCell.propTypes = cellPropTypes;

const ImageCell = ({ value, row, columns }) => (
  <ImageColumn type={value} functionalLocation={row.original} hasHover={columns.some(column => column.id === 'icon')} />
);
ImageCell.propTypes = cellPropTypes;

const NameCell = ({ value, row }) => <NameColumn description={value} />;
NameCell.propTypes = cellPropTypes;

const AddressCell = ({ value }) => <AddressColumn address={value} />;
AddressCell.propTypes = cellPropTypes;

const EquipmentCell = ({ value, row }) => <EquipmentColumn equipmentCount={value} type={row.original.type} />;
EquipmentCell.propTypes = cellPropTypes;

const PerformanceCell = ({ value, row }) => {
  if (!value) {
    return null;
  }
  return <ValueBarIndicatorColumn data={value} align="left" />;
};
PerformanceCell.propTypes = cellPropTypes;

const getCompareFn = accessorFn => (rowA, rowB) => {
  const a = accessorFn(rowA);
  const b = accessorFn(rowB);
  return a === b ? 0 : a > b ? 1 : -1;
};

const COLUMN_STYLES = {
  image: { minWidth: '68px', width: '68px', flexGrow: 0, height: '100%' },
  icon: { minWidth: '36px', width: '36px', flexGrow: 0, height: '100%' },
  large: { minWidth: '260px', width: '260px', flexGrow: 2 },
  medium: { minWidth: '120px', width: '120px', flexGrow: 0 },
  small: { minWidth: '75px', width: '75px', flexGrow: 0 },
};

const getColumns = memoizeOne(
  (t, { hideAddress, hasEquipment, isEquipment, performanceColumns, showCount, hideArrow }) => {
    const columns = [
      {
        Header: '',
        accessor: 'type',
        style: COLUMN_STYLES.image,
        Cell: ImageCell,
        disableSortBy: true,
      },
      {
        Header: t('Name'),
        accessor: 'description',
        style: COLUMN_STYLES.large,
        Cell: NameCell,
      },
    ];
    if (showCount) {
      columns.push({
        Header: t('Count'),
        accessor: 'count',
        style: COLUMN_STYLES.small,
        width: undefined,
        Cell: CountCell,
      });
    }
    if (!hideAddress) {
      columns.push({
        Header: t('Address'),
        accessor: 'address',
        style: COLUMN_STYLES.large,
        Cell: AddressCell,
      });
    }
    if (hasEquipment) {
      columns.push({
        Header: isEquipment ? t('Subequipment') : t('Equipment'),
        accessor: 'equipmentCount',
        style: COLUMN_STYLES.medium,
        Cell: EquipmentCell,
      });
    }
    if (performanceColumns?.length) {
      performanceColumns.forEach(performance => {
        columns.push({
          Header: t(performanceTranslationKeys[performance]),
          id: performance,
          accessor: item => item?.performances?.[performance],
          sortType: getCompareFn(row => row.original.performances?.[performance]?.value ?? Number.NEGATIVE_INFINITY),
          sortDescFirst: true,
          style: COLUMN_STYLES.medium,
          Cell: PerformanceCell,
        });
      });
    }
    if (!hideArrow) {
      columns.push({
        Header: '',
        accessor: 'icon',
        width: 20,
        style: COLUMN_STYLES.icon,
        disableSortBy: true,
        Cell: IconColumn,
      });
    }
    return columns;
  }
);

export const FunctionalLocationTable = ({
  loading,
  t,
  data,
  hasEquipment,
  isEquipment,
  hideAddress,
  removeHead,
  showCount,
  initialSortBy,
  match: { params },
  history,
  features,
  customViews,
  paginate,
  pageSize,
  useQueryPaging,
  performanceColumns,
  customers,
  horizontalScroll,
}) => {
  const [brokenLinkData, setBrokenLinkData] = useState();

  if (!loading && data.length === 0) {
    return <NoResult>{t('No results found')}</NoResult>;
  }

  const TableComponent = useQueryPaging ? FlexTableWithQueryPaging : FlexTable;

  const onClick = (event, cell) => {
    const rowData = cell.row.original;
    const { functionalLocation, equipmentNumber, tab, partnerNumbers } = rowData;
    const superordinate = params.equipmentNumber;

    // fix "all" if there is only one possible partnerNumber for FL
    const partnerNumber =
      params.partnerNumber === 'all' && partnerNumbers.length === 1 ? partnerNumbers[0] : params.partnerNumber;

    const link = equipmentNumber
      ? getEquipmentUrl(partnerNumber, functionalLocation, equipmentNumber, superordinate)
      : getFunctionalLocationUrl(rowData, features, partnerNumber, customViews, tab);

    if (partnerNumber === 'all') {
      // if partnerNumber is still "all", show select modal for possible customers or all customers
      if (partnerNumbers.length) {
        const selectableCustomers = Object.values(pick(customers, partnerNumbers));
        setBrokenLinkData({ customers: selectableCustomers, link });
      } else {
        setBrokenLinkData({ customers: Object.values(customers), link });
      }
    } else {
      history.push(link);
    }
  };

  return (
    <div>
      <TableComponent
        loading={loading}
        columns={getColumns(t, {
          hideAddress,
          hasEquipment,
          isEquipment,
          performanceColumns,
          showCount,
        })}
        data={data}
        initialSortBy={initialSortBy}
        paginate={paginate}
        pageSize={pageSize}
        onClick={onClick}
        removeHead={removeHead}
        horizontalScroll={horizontalScroll}
      />
      {brokenLinkData && (
        <SelectMissingPartner
          link={brokenLinkData.link}
          customers={brokenLinkData.customers}
          onClose={() => setBrokenLinkData(null)}
        />
      )}
    </div>
  );
};

FunctionalLocationTable.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
  loading: PropTypes.bool,
  t: PropTypes.func,
  data: PropTypes.array,
  hasEquipment: PropTypes.bool,
  isEquipment: PropTypes.bool,
  hideAddress: PropTypes.bool,
  removeHead: PropTypes.bool,
  showCount: PropTypes.bool,
  initialSortBy: PropTypes.array,
  match: PropTypes.shape({ params: PropTypes.object }),
  features: PropTypes.object,
  customViews: PropTypes.array,
  paginate: PropTypes.bool,
  pageSize: PropTypes.number,
  useQueryPaging: PropTypes.bool,
  performanceColumns: PropTypes.array,
  customers: PropTypes.object,
  horizontalScroll: PropTypes.shape({
    enabled: PropTypes.bool,
    horizontalPadding: PropTypes.bool,
    verticalPadding: PropTypes.bool,
    borderRadius: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  features: state.profile.profile.features,
  customViews: state.profile.profile.customViews,
  customers: state.customer.customers,
});

const connector = connect(mapStateToProps, null);

export default withRouter(
  connector(
    memo(
      FunctionalLocationTable,
      (prevProps, nextProps) =>
        isEqual(prevProps.data, nextProps.data) &&
        isEqual(prevProps.loading, nextProps.loading) &&
        isEqual(prevProps.page, nextProps.page)
    )
  )
);
