import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import translations from 'decorators/Translations/translations';
import { loadAnnouncements } from 'redux/modules/announcement/announcement';
import { saveProfile } from 'redux/modules/index';
import { announcementShape, ANNOUNCEMENT_TYPE, getAnnouncements } from './utils';

import AnnouncementBox from './AnnouncementBox';
import { BellButton } from './BellButton';
import { toggleAnnouncementMenu } from 'redux/modules/announcement/announcement';
import DialogModal from '../Dialog/DialogModal';
import AnnouncementModalContent from './AnnouncementModalContent';

const AnnouncementContainer = styled.div`
  position: relative;
  height: 100%;
  width: 0;

  ${props => props.theme.media.portrait`
    width: 55px;
  `}
`;

export class GeneralAnnouncements extends PureComponent {
  state = { showAnnouncementInModal: null };

  componentDidMount() {
    // Wait until the profile is loaded
    if (!isEmpty(this.props.profile)) {
      setTimeout(() => this.props.loadAnnouncements(), 500);
    }
  }

  componentDidUpdate(prevProps) {
    if (isEmpty(prevProps.profile) && !isEmpty(this.props.profile)) {
      setTimeout(() => this.props.loadAnnouncements(), 500);
    }
  }

  toggleBox = () => {
    this.props.toggleAnnouncementMenu();
  };

  saveAckAnnouncements = ids => {
    const newAcks = {};
    ids.forEach(id => {
      newAcks[id] = new Date().toISOString();
    });
    this.props.saveProfile(this.props.profile.id, {
      ...this.props.profile,
      ackAnnouncements: { ...this.props.profile.ackAnnouncements, ...newAcks },
    });
  };

  render() {
    const { activePartner, generalAnnouncements, personalAnnouncements, t, profile, customers, announcementMenuOpen } =
      this.props;
    const { showAnnouncementInModal } = this.state;
    const { read, unread } = getAnnouncements(profile, generalAnnouncements, personalAnnouncements, activePartner);

    return (
      <AnnouncementContainer data-test-id="GeneralAnnouncements">
        {announcementMenuOpen && (
          <AnnouncementBox
            unreadAnnouncements={unread}
            announcements={read}
            customers={customers}
            t={t}
            onClose={this.toggleBox}
            onReadMore={announcement => this.setState({ showAnnouncementInModal: announcement })}
            saveAcknowledgements={this.saveAckAnnouncements}
          />
        )}
        <BellButton onClick={this.toggleBox} unread={unread.length} label={t('Announcements')} />
        {showAnnouncementInModal && (
          <DialogModal
            small
            animate
            isActive
            onOverlayClick={() => this.setState({ showAnnouncementInModal: null })}
            t={t}
          >
            <AnnouncementModalContent announcement={showAnnouncementInModal} />
          </DialogModal>
        )}
      </AnnouncementContainer>
    );
  }
}

GeneralAnnouncements.propTypes = {
  generalAnnouncements: PropTypes.arrayOf(
    PropTypes.oneOfType([announcementShape(ANNOUNCEMENT_TYPE.PARTNER), announcementShape(ANNOUNCEMENT_TYPE.SYSTEM)])
  ).isRequired,
  personalAnnouncements: PropTypes.arrayOf(announcementShape(ANNOUNCEMENT_TYPE.PERSONAL)).isRequired,
  profile: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  activePartner: PropTypes.string,
  announcementMenuOpen: PropTypes.bool.isRequired,
  loadAnnouncements: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  toggleAnnouncementMenu: PropTypes.func.isRequired,
  saveProfile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  activePartner: state.profile.activePartner,
  profile: state.profile.profile,
  customers: state.customer.customers,
  generalAnnouncements: state.announcement.general,
  personalAnnouncements: state.announcement.personal,
  announcementMenuOpen: state.announcement.menuOpen,
});

const mapDispatchToProps = dispatch => ({
  loadAnnouncements: () => dispatch(loadAnnouncements()),
  saveProfile: (id, data) => dispatch(saveProfile(id, data)),
  toggleAnnouncementMenu: () => dispatch(toggleAnnouncementMenu()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(GeneralAnnouncements));
