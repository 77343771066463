import { useRef } from 'react';
import PropTypes from 'prop-types';

import useResponsive from './useResponsive';

const Responsive = WrappedComponent => {
  const ResponsiveComponent = props => {
    const componentRef = useRef();
    const dimensions = useResponsive(componentRef);
    return <WrappedComponent {...props} ref={componentRef} dimensions={dimensions} />;
  };

  ResponsiveComponent.propTypes = {
    dimensions: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  };

  return ResponsiveComponent;
};

export default Responsive;
