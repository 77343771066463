import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.label`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.normal};

  h2 {
    font-weight: ${props => props.theme.font.weight.bold};
    font-size: ${props => props.theme.font.size.xs};
  }

  && {
    line-height: 1.75em;
  }
`;

export const LabelHeading = styled(Label)`
  font-weight: ${props => props.theme.font.weight.semibold};
  && {
    line-height: 2.5em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  button {
    width: 100%;
  }
`;

export const PointInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: ${props => props.theme.fontSize.xxs};
  gap: 8px;
`;

interface DotProps {
  markerColor: string;
}

export const Dot = styled.div<DotProps>`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  shape-outside: border-box;
  background-color: ${props => props.markerColor};
  border: 1px solid ${props => `${props.markerColor}99`}; /* alpha 0.6 in hex */
`;
