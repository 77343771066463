import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

const StyledSimpleTable = styled.table`
  width: calc(100% + 4rem);
  overflow: hidden;
  margin: 1rem -2rem;
  border-top: 1px solid ${props => props.theme.colors.lightGray};

  ${props => props.theme.media.portrait`
    width: 100%;
    overflow: visible;
    margin: 0;
    border: none;
  `};
`;
StyledSimpleTable.displayName = 'StyledSimpleTable';

const DataLabel = styled.p`
  margin-bottom: 0.5rem;
  text-align: left;
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};

  &::first-letter {
    text-transform: uppercase;
  }

  display: block;
  ${props => props.theme.media.portrait`
    display: none;
  `}
`;
DataLabel.displayName = 'DataLabel';

const SimpleTableHeader = styled.th`
  text-align: left;
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  padding-right: 1em;

  &::first-letter {
    text-transform: uppercase;
  }
`;
SimpleTableHeader.displayName = 'SimpleTableHeader';

const SimpleTableCell = styled.td`
  padding: ${props => props.padding};
  display: block;
  width: 100%;

  ${props => props.theme.media.portrait`
    display: table-cell;
    width: auto;
  `}
`;
SimpleTableCell.displayName = 'SimpleTableCell';

const SimpleTableRow = styled.tr`
  display: block;
  width: 100%;
  padding: 0.5rem 2rem 1.5rem;

  ${props => props.theme.media.portrait`
    display: table-row;
    width: auto;
    padding: 0;
    border-bottom: none;
  `}
`;
SimpleTableRow.displayName = 'SimpleTableRow';

const SimpleTableHead = styled.thead`
  display: none;
  ${props => props.theme.media.portrait`
    display: table-header-group;
  `}
`;
SimpleTableHead.displayName = 'SimpleTableHead';

const SimpleTableBody = styled.tbody`
  ${SimpleTableRow}:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  }

  ${props => props.theme.media.portrait`
    ${SimpleTableRow}:not(:last-child) {
      border-bottom: none;
    }
  `}
`;
SimpleTableBody.displayName = 'SimpleTableBody';

const SimpleTable = ({ columns, rows, padding = '1rem 0.5rem 0 0', t }) => {
  return (
    <StyledSimpleTable>
      {!isEmpty(columns) && (
        <SimpleTableHead>
          <tr>
            {columns.map((column, index) => (
              <SimpleTableHeader key={index}>{t(column)}</SimpleTableHeader>
            ))}
          </tr>
        </SimpleTableHead>
      )}
      <SimpleTableBody>
        {rows.map((row, index) => (
          <SimpleTableRow key={index}>
            {row.cells.map((cell, index) => (
              <SimpleTableCell key={index} padding={padding}>
                {!isEmpty(columns) && columns[index] && <DataLabel>{t(columns[index])}</DataLabel>}
                {cell}
              </SimpleTableCell>
            ))}
          </SimpleTableRow>
        ))}
      </SimpleTableBody>
    </StyledSimpleTable>
  );
};

SimpleTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.array.isRequired,
  padding: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default SimpleTable;
