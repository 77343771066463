import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

export const StyledDescriptionColumn = styled.div`
  max-width: 800px;
  min-width: 200px;
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const Description = styled.div`
  display: inline;
  margin-right: var(--size-xxs);
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.fontSize.xs};
  line-height: ${props => props.theme.lineHeight.text};
  white-space: pre-line;
`;

const Icon = styled(Svg)`
  font-size: ${props => props.theme.fontSize.huge};
  fill: ${props => props.theme.colors.blue};
  min-width: ${props => props.theme.fontSize.huge};
  margin-right: var(--size-md);
`;

const DESC_MAX_LENGTH = 300;

const DescriptionColumn = props => {
  const { value, description } = props.data;
  const icon = props.icon && props.icon.icon;
  const { showAll } = props;
  const renderDescription = description =>
    showAll
      ? description
      : `${description?.substring(0, DESC_MAX_LENGTH)}${description?.length > DESC_MAX_LENGTH ? '...' : ''}`;

  return (
    <StyledDescriptionColumn>
      {icon && <Icon name={icon} />}
      <div>
        {value && <Title>{value}</Title>}
        {description && <Description>{renderDescription(description)}</Description>}
      </div>
    </StyledDescriptionColumn>
  );
};

export default DescriptionColumn;
