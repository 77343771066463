import type { ReactNode } from 'react';
import * as Styled from './StatusBar.styled';
import { optionLabelOverrides } from 'containers/Application/Maintenance/utils';
import { TranslationFunction } from 'decorators/Translations/TranslationFunction';
import { OrderStatus, OrderStatusColors } from 'constants/maintenance';
import CalendarStatusTag from 'components/Calendar/CalendarStatusTag/CalendarStatusTag';
import { translatePriority } from 'utils/Data/serviceOrders';
import SkeletonText from 'components/Skeletons/SkeletonText';

type Priority = string | number | undefined;
type Props = {
  t: TranslationFunction;
  order?: {
    externalType: string;
    status: string;
    priority: Priority;
  };
  isOverdue?: boolean;
  loadingOrder: boolean;
};

type StatusFieldProps = {
  title: string;
  value: ReactNode;
};

type SourceMap = {
  [key: string]: string;
};

const StatusField = ({ title, value }: StatusFieldProps) => {
  if (!value) {
    return <></>;
  }
  return (
    <Styled.Status>
      <Styled.StatusName>{title}</Styled.StatusName>
      {value}
    </Styled.Status>
  );
};

export const getStatusTag = (status: string | undefined, text: string) => {
  if (!status || !text) {
    return null;
  }
  return <CalendarStatusTag status={status} statusColors={OrderStatusColors} text={text} showFullText />;
};

const statusField = (status: string | undefined, t: TranslationFunction) => getStatusTag(status, t(status || ''));

const sourceField = (externalType: string | undefined) =>
  externalType && ((optionLabelOverrides.source as SourceMap)[externalType] || externalType);

const priorityField = (priority: Priority, t: TranslationFunction) => priority && translatePriority(priority, t);

const StatusBar = (props: Props) => {
  const { t, order, isOverdue, loadingOrder } = props;

  if (loadingOrder) {
    return <SkeletonText />;
  }

  return (
    <Styled.StatusBar>
      <StatusField title={t('Status')} value={statusField(order?.status, t)} />
      <StatusField title={t('Source')} value={sourceField(order?.externalType)} />
      <StatusField title={t('Priority')} value={priorityField(order?.priority, t)} />
      {isOverdue ? getStatusTag(OrderStatus.OVERDUE, t(OrderStatus.OVERDUE)) : <></>}
    </Styled.StatusBar>
  );
};

export default StatusBar;
