import { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useHorizontalScrollDimensions } from 'components/HorizontalScroll/useHorizontalScrollDimensions';

const RelativeWrapper = styled.div`
  position: relative;
`;

export const HorizontallyScrollable = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`;

const BaseShadow = styled.div`
  width: 1.25rem;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: ${props => (props.visible ? 0.125 : 0)};
  transition: opacity 0.15s ease-in-out;
  pointer-events: none;
`;

export const RightShadow = styled(BaseShadow)`
  background: linear-gradient(90deg, rgb(0 0 0 / 0) 0%, rgb(0 0 0 / 1) 100%);
  right: 0;
  border-radius: ${props => props.borderRadius};
`;
RightShadow.displayName = 'RightShadow';

export const LeftShadow = styled(BaseShadow)`
  background: linear-gradient(270deg, rgb(0 0 0 / 0) 0%, rgb(0 0 0 / 1) 100%);
  left: 0;
  border-radius: ${props => props.borderRadius};
`;
LeftShadow.displayName = 'LeftShadow';

/* used when scroll needs to bee from side to side on mobile (less than 768px wide screens) */
export const horizontallyScrollableMobile = css`
  @media (max-width: 767px) {
    margin: 0 -${props => props.theme.layoutSpacing.md}; /* negative margin */
    ${HorizontallyScrollable} {
      > div {
        margin: 0 ${props => props.theme.layoutSpacing.md};
        &::after {
          content: '';
          flex: 0 0 ${props => props.theme.layoutSpacing.md};
        }
      }
    }
  }
`;

export const HorizontalScroll = ({ borderRadius = '0px', onScrollingChange, children }) => {
  const containerRef = useRef();

  const { scrollable, scrolledLeft, scrolledRight } =
    useHorizontalScrollDimensions(containerRef, onScrollingChange) ?? {};

  return (
    <RelativeWrapper>
      <HorizontallyScrollable ref={containerRef}>{children}</HorizontallyScrollable>
      <RightShadow visible={scrollable && !scrolledRight} borderRadius={borderRadius} />
      <LeftShadow visible={scrollable && !scrolledLeft} borderRadius={borderRadius} />
    </RelativeWrapper>
  );
};

HorizontalScroll.propTypes = {
  borderRadius: PropTypes.string,
  onScrollingChange: PropTypes.func,
  children: PropTypes.node,
};

export default HorizontalScroll;
