import { forwardRef, Ref } from 'react';
import { Row, SortingState } from '@tanstack/react-table';
import styled, { css } from 'styled-components';

import { RemotePaginatedDataTable } from 'components/VirtualizedTable/RemotePaginatedDataTable';
import { TableRemoteDataState } from 'components/VirtualizedTable/RemotePaginatedTable';
import { PaginationRowCountValue } from 'components/Pagination/PaginationPageSizeSelector';
import { QueryParameterPaginationProps } from 'components/Pagination/QueryConnectedPagination';
import { useTranslations } from 'decorators/Translations/translations';
import { InsightMessage } from 'types/Insights';
import { rootSize } from 'styles/definitions';

import { GenericTR, GenericTRProps } from 'components/VirtualizedTable/GenericTable';

import { createShortMessageString, createLocationString } from '../utils';
import { INSIGHT_STATUS } from '../constants';

import { useInsightTableColumns } from './useInsightTableColumns';

export const insightTablePageSizeOptions = [
  { value: 5, label: '5' },
  { value: null, label: 'All' },
];

export type InsightTableProps = {
  insightMessages: InsightMessage[];
  loading: boolean;
  error: boolean;
  onClick: (insightId: number) => void;
  removeHead: boolean;
  showHistory: boolean;
};

export type InsightTableMessage = {
  insightId: number;
  location: string;
  insightMessage: string;
  createdAt: string;
  status: (typeof INSIGHT_STATUS)[keyof typeof INSIGHT_STATUS];
};

export const THeadHider = styled.div<{ removeHead: boolean }>`
  thead {
    ${props =>
      props.removeHead
        ? css`
            display: none;
          `
        : ''};
  }
`;

export const InsightTable = ({
  insightMessages,
  loading,
  error,
  onClick,
  removeHead = false,
  showHistory = false,
}: InsightTableProps) => {
  const [t] = useTranslations();
  const columns = useInsightTableColumns();

  const data: InsightTableMessage[] = insightMessages
    .map(
      message =>
        ({
          insightId: message.insightId,
          location: createLocationString(message, t),
          insightMessage: createShortMessageString(message, t),
          createdAt: message.timestamp,
          status: message.active
            ? INSIGHT_STATUS.active
            : message.success
              ? INSIGHT_STATUS.success
              : INSIGHT_STATUS.inactive,
        } as InsightTableMessage)
    )
    .filter(message =>
      showHistory
        ? message.status === INSIGHT_STATUS.inactive
        : message.status === INSIGHT_STATUS.active || message.status === INSIGHT_STATUS.success
    );

  const tableOptions = {
    columns,
    manualSorting: false,
    state: {
      columnVisibility: { status: false },
    },
    initialState: {
      sorting: [{ id: 'createdAt', desc: true }],
    },
    meta: {
      width: 'full' as const,
    },
  };

  const paginationProps: QueryParameterPaginationProps = {
    totalItems: data.length,
    paginationRowCountOptions: insightTablePageSizeOptions,
    firstPageOrAll: true,
  };

  const navigationHeight = 4 * rootSize;

  const virtualizerOptions = {
    // Take the navigation into account and add some space
    scrollPaddingStart: navigationHeight + 2 * rootSize,
    // Increase overscan accordingly
    overscan: 10,
  };

  const dataState: TableRemoteDataState<InsightTableMessage> = {
    data: data.length > 0 ? data : null,
    isFetching: loading,
    isLoading: loading,
    error,
  };

  const genericTableProps = {
    TR: forwardRef((props: GenericTRProps, ref: Ref<HTMLTableRowElement>) => {
      const row = props.row as Row<InsightTableMessage>;
      const insightId = row?.original?.insightId;
      return (
        <GenericTR
          ref={ref}
          onClick={() => {
            if (insightId) {
              onClick(insightId);
            }
          }}
          style={{
            cursor: insightId ? 'pointer' : undefined,
            ...props.style,
          }}
          {...props}
        />
      );
    }),
  };

  return (
    <THeadHider removeHead={removeHead}>
      <RemotePaginatedDataTable
        tableOptions={tableOptions}
        genericTableProps={genericTableProps}
        remoteDataState={dataState}
        paginationProps={paginationProps}
        virtualizerOptions={virtualizerOptions}
        dataNotFoundText={t('No data available')}
      />
    </THeadHider>
  );
};
