import SkeletonText from 'components/Skeletons/SkeletonText';
import * as Styled from './ModalHeader.styled';

const ServiceOrderHeaderSkeleton = () => (
  <Styled.TitleContainer>
    <SkeletonText />
    <SkeletonText />
    <SkeletonText />
  </Styled.TitleContainer>
);

export default ServiceOrderHeaderSkeleton;
