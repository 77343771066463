import PropTypes from 'prop-types';
import styled from 'styled-components';

export const CheckboxContainer = styled.span`
  margin: var(--size-xxs) 0;
`;
CheckboxContainer.displayName = 'CheckboxContainer';

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;

  &:checked + label::after {
    border-color: ${props => (props.disabled ? 'var(--grey-50)' : 'var(--white)')};
  }

  &:checked + label::before {
    background: ${props => (props.disabled ? 'var(--grey-20)' : 'var(--blue-02)')};
    border: ${props => (props.disabled ? '1px solid var(--grey-50)' : 'none')};
    box-shadow: none;
  }
`;
CheckboxInput.displayName = 'CheckboxInput';

export const CheckboxLabel = styled.label`
  cursor: ${props => !props.disabled && 'pointer'};
  position: relative;
  font-size: ${props => (props.labelSize === 'large' ? props.theme.fontSize.sm : props.theme.fontSize.xs)};
  font-weight: ${props =>
    props.labelSize === 'large' || props.useBold ? props.theme.fontWeight.bold : props.theme.fontWeight.normal};
  padding-left: 2.25em;
  line-height: ${props => props.theme.lineHeight.text};
  opacity: ${props => props.disabled && 0.6};
  white-space: ${props => (props.labelNoWrap ? 'nowrap' : 'normal')};
  color: ${props => props.theme.input.font.color.default};
  display: flex;
  width: 100%;
  min-height: calc(1em * ${props => props.theme.lineHeight.text});
  box-sizing: border-box;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    font-size: ${props => (props.labelSize === 'large' ? props.theme.iconSize.sm : props.theme.iconSize.xs)};
  }

  &::before {
    left: 0;
    top: 50%;
    width: 1.5em;
    height: 1.5em;
    background: white;
    border: 1px solid var(--grey-50);
    box-shadow: var(--inset-shadow-02);
    border-radius: 2px;
    transform: translateY(-50%);
    transition: border-color ${props => props.theme.motion.quick} ${props => props.theme.motion.easing};
  }

  &::after {
    left: 0.25em;
    top: calc(50% - 0.2em);
    width: 0.5em;
    height: 0.75em;
    border: solid transparent;
    border-width: 0 0.225em 0.225em 0;
    transform: rotate(45deg) translateY(-50%);
  }
`;
CheckboxLabel.displayName = 'CheckboxLabel';

const Checkbox = props => {
  return (
    <CheckboxContainer>
      <CheckboxInput
        type="checkbox"
        id={props.id}
        data-test-id={props.id}
        checked={props.checked}
        onChange={props.onChange}
        onClick={props.stopClickPropagation ? event => event.stopPropagation() : undefined}
        disabled={props.disabled}
      />
      <CheckboxLabel
        labelSize={props.labelSize}
        htmlFor={props.id}
        disabled={props.disabled}
        labelNoWrap={props.labelNoWrap}
        onClick={props.stopClickPropagation ? event => event.stopPropagation() : undefined}
      >
        {props.label}
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  labelSize: PropTypes.string,
  label: PropTypes.string,
  labelNoWrap: PropTypes.bool,
  stopClickPropagation: PropTypes.bool,
};

export default Checkbox;
