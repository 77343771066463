import PropTypes from 'prop-types';
import PrimaryButton from 'components/Button/PrimaryButton';

const SendButton = ({ t, ...buttonProps }) => (
  <PrimaryButton large confirm {...buttonProps}>
    {t('Send')}
  </PrimaryButton>
);

SendButton.propTypes = {
  t: PropTypes.func.isRequired,
};

export default SendButton;
