import PropTypes from 'prop-types';
import styled from 'styled-components';
import PrimaryButton from 'components/Button/PrimaryButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

const Message = styled.div`
  text-align: center;
`;

const RetryLoading = ({ message, buttonText, onRetry }) => (
  <Container>
    <Message>{message}</Message>
    {onRetry && (
      <PrimaryButton naked large onClick={onRetry}>
        {buttonText}
      </PrimaryButton>
    )}
  </Container>
);

RetryLoading.propTypes = {
  message: PropTypes.string,
  buttonText: PropTypes.string,
  onRetry: PropTypes.func,
};

export default RetryLoading;
