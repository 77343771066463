import PropTypes from 'prop-types';

import useProgress from './useProgress';

const withProgress = WrappedComponent => {
  const ProgressComponent = ({ loading, full, initStep, ...other }) => {
    const progress = useProgress(loading, full, initStep);

    return <WrappedComponent progress={full ? 100 : progress} {...other} />;
  };

  ProgressComponent.defaultProps = {
    full: false,
    initStep: 0.15,
    loading: false,
  };

  ProgressComponent.propTypes = {
    full: PropTypes.bool,
    /**
     * initStep affects the speed of progress
     * Hints for appropriate values:
     * fast: 0.3
     * default: 0.15
     * slow: 0.01
     * super slow: 0.001
     */
    initStep: PropTypes.number,
    loading: PropTypes.bool,
  };

  return ProgressComponent;
};

export default withProgress;
