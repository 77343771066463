import { FC, ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useTranslations } from 'decorators/Translations/translations';

export type PaginationPageCounterTextProps = PropsWithChildren<{
  ref?: ForwardedRef<HTMLDivElement | null>;
}>;

const PaginationPageCounterText: FC<PaginationPageCounterTextProps> = styled.div`
  font-size: ${props => props.theme.font.size.xs};
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PaginationPageCounterLabel: FC<PropsWithChildren<unknown>> = styled.span`
  display: inline-block;
`;

const PaginationPageCounterPages: FC<PropsWithChildren<unknown>> = styled(PaginationPageCounterLabel)`
  text-align: left;

  .pages {
    font-weight: ${props => props.theme.font.weight.bold};
  }

  .total {
    font-weight: ${props => props.theme.font.weight.normal};
  }
`;

const PaginationPageCounterPagesWidth = styled(PaginationPageCounterPages)`
  height: 1px;
  margin-top: -1px;
  visibility: hidden;
  white-space: nowrap;
  display: block;
`;

export type PaginationPageCounterProps = {
  first: number;
  last: number;
  total: number;
  labelHidden?: boolean;
  reserveTextWidth?: boolean;
};

/**
 * @implements UIv3
 */
export const PaginationPageCounter = forwardRef<HTMLDivElement, PaginationPageCounterProps>(
  (
    { first, last, total, labelHidden = false, reserveTextWidth = false }: PaginationPageCounterProps,
    ref
  ): JSX.Element => {
    const [t] = useTranslations();

    const formatDisplayText = (first: unknown, last = first, total = first) => (
      <>
        <span className="pages">{`${first}\u2013${last}`}</span>
        <span className="total">{`\u00a0/\u00a0${total}`}</span>
      </>
    );

    const displayText = formatDisplayText(first, last, total);

    const totalMagnitude = total > 0 ? Math.ceil(Math.log10(total)) : 1;
    const widthDeterminingPart = '0'.repeat(totalMagnitude);
    const widthDeterminingText = formatDisplayText(widthDeterminingPart);

    return (
      <PaginationPageCounterText ref={ref}>
        {labelHidden || <PaginationPageCounterLabel>{t('Showing')}:&nbsp;</PaginationPageCounterLabel>}
        <PaginationPageCounterPages>
          {displayText}
          {
            // Prevent page selector from jumping by reserving a wide space
            reserveTextWidth && (
              <PaginationPageCounterPagesWidth aria-hidden="true">
                {widthDeterminingText}
              </PaginationPageCounterPagesWidth>
            )
          }
        </PaginationPageCounterPages>
      </PaginationPageCounterText>
    );
  }
);
