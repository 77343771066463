import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import HorizontalScroll from 'components/HorizontalScroll/HorizontalScroll';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';

const Container = styled.div``;

const Content = styled.div`
  border: var(--border-thin) solid var(--tab-content-bc);
  background-color: ${props => (props.highlight ? 'var(--grey-08)' : 'var(--card-bg)')};
  padding: var(--size-sm);
  font-size: ${props => props.theme.font.size.sm};
  line-height: ${props => props.theme.font.lineHeight.md};
  color: var(--body-text-fg);

  ${props => props.theme.media.landscape`
      padding: var(--size-lg);
  `}
`;
Content.displayName = 'Content';

const Bookmark = styled.button.attrs(props => ({ 'data-selected': props.selected }))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  color: ${props =>
    props.selected ? 'var(--tab-fg-selected)' : props.disabled ? 'var(--tab-fg-disabled)' : 'var(--tab-fg)'};
  background-color: ${props =>
    !props.selected ? 'var(--tab-bg)' : props.highlight ? 'var(--grey-08)' : 'var(--tab-bg-selected)'};
  border: ${props => (props.selected ? 'var(--border-thin) solid var(--tab-bc)' : 'none')};
  border-bottom: none;
  margin-bottom: ${props => (props.selected ? 'calc(-1 * var(--border-thin))' : 0)};
  padding: var(--size-xs);
  outline: 0;

  &:hover {
    z-index: 1;
    ${props =>
      !props.disabled
        ? css`
            cursor: pointer;
          `
        : 'cursor: default;'}

    ${props =>
      !props.disabled && !props.selected
        ? css`
            background-color: var(--tab-bg-hover);
          `
        : ''}
  }

  ${props => props.theme.media.landscape`
    padding: var(--size-xs) 0;
    flex-basis: ${props => props.tabWidth};
  `}

  span {
    width: 100%;
    line-height: var(--size-sm);
    padding: 0 var(--size-sm);
    border-left: var(--border-semibold) solid transparent;
  }

  /* Separators should be only shown between two, adjacent, and non-selected tabs */
  &[data-selected='false']:not(:hover) + &[data-selected='false'] {
    span {
      border-left: var(--border-semibold) solid var(--tab-separator-color);
    }
  }
`;
Bookmark.displayName = 'Bookmark';

const Bookmarks = styled.div`
  display: flex;

  ${props => props.theme.media.landscape`
    flex-wrap: wrap;
  `}
`;
Bookmarks.displayName = 'Bookmarks';

// Binderbox, which selects tabs based on id, simplifies making queries to tab contents
const BinderTabs = ({ tabs, setSelectedTab, selectedTabId, tabWidth = '25%' }) => {
  const selectedTab = tabs.find(x => x.id === selectedTabId) || {};
  const breakpoints = useBreakpoints();

  const bookmarks = (
    <Bookmarks>
      {tabs.map(tab => (
        <Bookmark
          key={tab.id}
          id={tab.id}
          onClick={tab.disabled ? undefined : () => setSelectedTab(tab.id)}
          selected={tab.id === selectedTabId}
          highlight={selectedTab?.highlight}
          disabled={tab.disabled}
          tabWidth={tabWidth}
        >
          <span>{tab.title}</span>
        </Bookmark>
      ))}
    </Bookmarks>
  );

  return (
    <Container>
      {breakpoints.landscape ? bookmarks : <HorizontalScroll>{bookmarks}</HorizontalScroll>}
      <Content highlight={selectedTab?.highlight}>{selectedTab?.content}</Content>
    </Container>
  );
};

BinderTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      highlight: PropTypes.bool,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  selectedTabIndex: PropTypes.number.isRequired,
  setSelectedTabIndex: PropTypes.func.isRequired,
  tabWidth: PropTypes.string,
};

export default BinderTabs;
