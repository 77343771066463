import { CellContext } from '@tanstack/react-table';

import { TableLabelWrapper } from 'components/VirtualizedTable/GenericTable';
import { useTranslations } from 'decorators/Translations/translations';

import { INSIGHT_STATUS } from '../constants';
import ActivityIndicator from '../ActivityIndicator';

import { InsightTableMessage } from './InsightTable';

export const InsightMessageCell = ({ getValue, row }: CellContext<InsightTableMessage, string>) => {
  const [t] = useTranslations();

  return (
    <TableLabelWrapper>
      {getValue()}
      {row.original.status === INSIGHT_STATUS.inactive && (
        <>
          &ensp;
          <ActivityIndicator active={false} t={t} />
        </>
      )}
    </TableLabelWrapper>
  );
};
