import { createReducerFromMapping } from 'redux/utils/index';
import assign from 'lodash/assign';
import clone from 'lodash/clone';
import merge from 'lodash/merge';
import capitalize from 'lodash/capitalize';
import IoTService from 'services/iot';
import MasterDataService from 'services/masterData';

const initialState = {
  realSensors: {},
  categories: {},
  logs: {},
};

const Group = {
  MainMeter_kW_5min_rate: 'Energy',
  MainMeter_kW_15min_rate: 'Energy',
  MainMeter_kW_30min_rate: 'Energy',
  w: 'Energy',
  MainMeter_kWh_hourly: 'Energy',
  MainMeter_kWh_total: 'Energy',
  Total_Building_kWh_hourly: 'Energy',

  Temp_Performance: 'Temperature',
  'Temperature after HeatRecovery': 'Temperature',
  'Temperature Extract Air': 'Temperature',
  'Temperature Inlet Air': 'Temperature',
  'Temperature Supply Air': 'Temperature',
  'Temperature Room': 'Temperature',
  Vip_Temp_Performance: 'Temperature',
  'VIP temperature': 'Temperature',

  Lights_Off_Performance: 'Lighting',
  Lights_DayLux_Performance: 'Lighting',
  'Lights in building': 'Lighting',
  'LUX Room': 'Lighting',

  AfterHeatExchangerBattery: 'Power',
  ExctractSensorBattery: 'Power',
  ExtractFilterSensorBattery: 'Power',
  InletSensorBattery: 'Power',
  InletFilterSensorBattery: 'Power',
  PulseSensorBattery: 'Power',
  RoomSensorBattery: 'Power',
  SupplySensorBattery: 'Power',
  VIPSensorBattery: 'Power',

  HeatExchanger_Performance: 'Thermal',
  HeatRecoveryEfficiency: 'Thermal',
  Heating_Performance: 'Thermal',
  Heating: 'Thermal',
  'DewPoint Inlet Air': 'Thermal',
  'DewPoint Extract Air': 'Thermal',
  Humidity_Performance: 'Thermal',
  'Humidity Inlet Air': 'Thermal',
  'Humidity Extract Air': 'Thermal',
};

export const LOAD = 'CUSTOMER_PLATFORM/IoT_Sensor/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Sensor/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/IoT_Sensor/LOAD_FAIL';

export const load = (functionalLocation, equipment) => {
  const filter = {
    where: {
      functionalLocation: functionalLocation.functionalLocation,
    },
  };

  if (equipment !== undefined) {
    filter.where.equipmentNumber = equipment.equipmentNumber;
  }

  return async dispatch => {
    dispatch({ type: LOAD });
    try {
      const result = await dispatch(IoTService.sensors(filter));

      return dispatch({
        type: LOAD_SUCCESS,
        functionalLocation: functionalLocation.functionalLocation,
        equipmentNumber: equipment && equipment.equipmentNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        error,
      });
    }
  };
};

export const LOAD_SENSOR_LOG = 'CUSTOMER_PLATFORM/IoT_Sensor/LOAD_SENSOR_LOG';

export const loadSensorLog = (functionalLocation, cursor) => async dispatch => {
  const meta = { functionalLocation, cursor };
  const filter = { where: { functionalLocation } };
  dispatch({ type: LOAD_SENSOR_LOG, state: 'LOAD', meta });
  try {
    const result = await dispatch(MasterDataService.getSensorLog(filter, cursor));
    dispatch({ type: LOAD_SENSOR_LOG, state: 'SUCCESS', meta, result });
  } catch (error) {
    dispatch({ type: LOAD_SENSOR_LOG, state: 'FAIL', meta, error });
  }
};

const titleize = str => str.split(' ').map(capitalize).join(' ');
const replaceAll = (str, find, replace) => str.split(find).join(replace);

const humanreadableSensorName = id => titleize(replaceAll(id, '_', ' '));

const getName = sensor => {
  const sensorName = sensor.sensorName.split('/', 2)[1];

  if (sensorName === undefined) {
    return sensor.sensorName;
  }

  return sensorName;
};

export default createReducerFromMapping(
  {
    [LOAD]: (state, action) => ({
      ...state,
    }),

    [LOAD_SUCCESS]: (state, action) => ({
      ...state,
      realSensors: assign(clone(state.realSensors), {
        [action.equipmentNumber || action.functionalLocation]: Object.assign(
          {},
          ...action.result.map(x => ({
            [x.sensorName]: humanreadableSensorName(getName(x)),
          }))
        ),
      }),
      categories: merge(
        {},
        state.categories,
        ...(action.result.length > 0
          ? action.result.map(x => ({
              [action.functionalLocation]: {
                [Group[getName(x)] || '']: {
                  [x.sensorName]: x,
                },
              },
            }))
          : [
              {
                [action.functionalLocation]: null,
              },
            ])
      ),
    }),
    [LOAD_FAIL]: (state, action) => ({
      ...state,
    }),
    [LOAD_SENSOR_LOG]: (state, action) => {
      const { functionalLocation } = action.meta;
      const entry = state.logs[functionalLocation] ?? {};
      const existingLog = state.logs[functionalLocation]?.log ?? [];
      return {
        ...state,
        logs: {
          ...state.logs,
          [functionalLocation]: {
            ...entry,
            loading: action.state === 'LOAD',
            error: action.error,
            log: action.result ? [...action.result.data, ...existingLog] : existingLog,
            cursor: action.result?.meta.previous || entry.cursor,
          },
        },
      };
    },
  },
  initialState
);
