import { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MenuToggle from 'components/MenuToggle/MenuToggle';
import { toggleScroll } from 'utils/Modal/toggleScroll';
import FeedbackForm from './FeedbackForm';
import InfoBox from 'components/InfoBox/InfoBox';
import { useTranslations } from 'decorators/Translations/translations';

const FeedbackPanelContainer = styled.div`
  position: fixed;
  pointer-events: auto;
  background: var(--window-bg);
  width: ${props => (props.active ? '100%' : 0)};
  ${props => props.theme.media.portrait`
        width: ${props => (props.active ? '400px' : 0)};
    `};
  box-shadow: ${props => props.theme.shadows.opiCard};
  transition: width ${props => props.theme.navigation.transition};
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.theme.zIndex('feedback')};
`;
FeedbackPanelContainer.displayName = 'FeedbackPanelContainer';

const MenuToggleContainer = styled.div`
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  font-size: ${props => props.theme.font.size.lg};
  height: ${props => props.theme.navigation.height};
  background-color: var(--brand-color);
  align-items: center;
`;
MenuToggleContainer.displayName = 'MenuToggleContainer';

const FeedbackContent = styled.div`
  padding: var(--size-md) var(--size-xl) var(--size-xl);
  height: 100%;
  overflow-y: auto;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: ${props => (props.active ? 'opacity 0.3s' : 'none')};
  transition-delay: ${props => props.active && '0.3s'};
`;
FeedbackContent.displayName = 'FeedbackContent';

const FeedbackHeading = styled.h3`
  font-size: ${props => props.theme.font.size.lg};
  line-height: ${props => props.theme.font.lineHeight.lg};
`;
FeedbackHeading.displayName = 'FeedbackHeading';

const FeedbackHeadingExtra = styled.div`
  font-size: ${props => props.theme.font.size.xs};
  color: var(--supplemental-text-fg);
`;
FeedbackHeadingExtra.displayName = 'FeedbackHeadingExtra';

const FeedbackPanel = ({ active, onClose, onSubmit, errorMessages, sending, sent, model, setModel }) => {
  const [t] = useTranslations();
  const errorMessageValue = Object.values(errorMessages).filter(Boolean).join('\n');
  const { error: _, ...validationErrors } = errorMessages;

  const handleFormChange = useCallback(
    (property, value) => {
      setModel({ ...model, [property]: value });
    },
    [model]
  );

  const handleFormSubmit = useCallback(() => onSubmit(model), [model]);

  if (window && window.innerWidth < 600) {
    toggleScroll(active);
  }

  return (
    <FeedbackPanelContainer active={active}>
      <MenuToggleContainer>
        <MenuToggle toggleMenu={onClose} menuOpen={active} />
      </MenuToggleContainer>
      <FeedbackContent active={active}>
        <FeedbackHeading>{t('We appreciate your feedback')}</FeedbackHeading>
        <hr />
        <FeedbackForm
          model={model}
          onChange={handleFormChange}
          onSubmit={handleFormSubmit}
          sending={sending}
          sent={sent}
          validationErrors={validationErrors}
        />
        {!!errorMessageValue && (
          <InfoBox error preWrap>
            {errorMessageValue}
          </InfoBox>
        )}
      </FeedbackContent>
    </FeedbackPanelContainer>
  );
};

FeedbackPanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  active: PropTypes.bool,
  errorMessages: PropTypes.object,
  sending: PropTypes.bool,
  sent: PropTypes.bool,
  model: PropTypes.object.isRequired,
  setModel: PropTypes.func,
};

export default FeedbackPanel;
