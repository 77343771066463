import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const StyledBackLinkArrow = styled.span`
  display: inline-block;
  margin-right: var(--size-xs);
  &::after {
    content: '←';
  }
`;

const StyledBackLink = styled.div`
  display: flex;
  justify-content: center;
  padding: var(--size-xl);
  color: ${props => props.theme.colors.cerulean};
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  ${props => props.theme.media.landscape`
        color: ${props => props.theme.colors.white};
        background-color: inherit;
        justify-content: flex-start;
    `}
`;

const StyledBackLinkContent = styled.div`
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.default};
  text-transform: uppercase;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
`;

StyledBackLinkContent.displayName = 'StyledBackLinkContent';

export const BackLink = ({ text }) => {
  const history = useHistory();
  return (
    <StyledBackLink data-test-id="UserManualBackLink">
      <StyledBackLinkContent onClick={() => history.goBack()}>
        <StyledBackLinkArrow />
        {text}
      </StyledBackLinkContent>
    </StyledBackLink>
  );
};

BackLink.propTypes = {
  text: PropTypes.string.isRequired,
};

export default BackLink;
