import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as yup from 'yup';

import InputForm from 'components/Form/InputForm';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputText from 'components/Form/InputText';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import RadioButton from 'components/Form/RadioButton';
import SubformComponent, { SubformWithMargin } from './SubformComponent';
import { SMALL_INPUT_WIDTH } from './RecurrenceForm';

const NumberInput = styled(InputText)`
  width: ${SMALL_INPUT_WIDTH}px;
  ${props => (props.hasMargin ? 'margin-left: var(--size-xs);' : '')}
`;

const InputWrapper = styled.div`
  display: flex;
  flex-grow: 2;
  margin: 0 !important;
`;

export const createSchema = t =>
  yup.object().shape({
    assignmentSettings: yup.boolean().nullable(),
    createServiceOrderDays: yup
      .number()
      .nullable()
      .label(t('Create before days'))
      .when('assignmentSettings', (assignmentSettings, schema) =>
        assignmentSettings
          ? schema.required().min(0).max(100)
          : schema.transform(value => (isNaN(value) ? null : value))
      ),
    hours: yup
      .number()
      .nullable()
      .label(`${t('At time')} (${t('hours')})`)
      .when('assignmentSettings', (assignmentSettings, schema) =>
        assignmentSettings ? schema.required().min(0).max(23) : schema.transform(value => (isNaN(value) ? null : value))
      ),
    minutes: yup
      .number()
      .nullable()
      .label(`${t('At time')} (${t('minutes')})`)
      .when('assignmentSettings', (assignmentSettings, schema) =>
        assignmentSettings ? schema.required().min(0).max(59) : schema.transform(value => (isNaN(value) ? null : value))
      ),
  });

const AssignmentForm = ({ t, model, onFormChange, id, validationErrors }) => {
  return (
    <>
      {!model.schedule.recurrence && (
        <InputForm
          onPropertyChange={onFormChange}
          model={model}
          FormComponent={SubformComponent}
          validationErrors={validationErrors}
        >
          <InputRow dense fullRow>
            <RadioButtonGroup
              row
              id={id}
              name="assignmentSettings"
              value={model.assignmentSettings}
              onChange={onFormChange}
            >
              <RadioButton label={t('Schedule assignment')} value={true} />
              <RadioButton label={t('Create assignment now')} value={false} />
            </RadioButtonGroup>
          </InputRow>
        </InputForm>
      )}
      {model.assignmentSettings && (
        <InputForm
          onPropertyChange={onFormChange}
          model={model}
          FormComponent={SubformWithMargin}
          validationErrors={validationErrors}
        >
          <InputRow gray fullRow>
            <InputLabel text={t('Create before days')} required />
            <InputWrapper>
              <NumberInput property="createServiceOrderDays" type="number" required inputProps={{ min: 0, max: 100 }} />
            </InputWrapper>
          </InputRow>
          <InputRow gray fullRow>
            <InputLabel text={t('At time')} required />
            <InputWrapper>
              <NumberInput property="hours" type="number" required inputProps={{ min: 0, max: 23 }} />
              <NumberInput
                hasMargin
                property="minutes"
                type="number"
                required
                inputProps={{ min: 0, max: 59, step: 30, value: model.minutes || 0 }}
              />
            </InputWrapper>
          </InputRow>
        </InputForm>
      )}
    </>
  );
};

AssignmentForm.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default AssignmentForm;
