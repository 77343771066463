import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { useTranslations } from 'decorators/Translations/translations';
import InputForm from 'components/Form/InputForm';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import InputTextArea from 'components/Form/InputTextArea';
import InputRow from 'components/Form/InputRow';
import PrimaryButton from 'components/Button/PrimaryButton';
import FeedbackRadioButton from './FeedbackRadioButton';
import { InputLabel } from 'components/index';
import { SecondaryText, Label } from 'components/Form/InputLabel';

const StyledInputLabel = styled(InputLabel)`
  ${Label} {
    display: flex;
    flex-direction: column;
    font-size: 100%;
    font-weight: ${props => props.theme.font.weight.semibold};
    line-height: ${props => props.theme.font.lineHeight.md};
    color: var(--input-label-fg);
    margin-bottom: ${props => props.theme.layoutSpacing.sm};
  }
  ${SecondaryText} {
    font-size: ${props => props.theme.font.size.xxs};
    color: var(--error-color);
    padding: 0;
  }
`;

const booleanToString = value => (typeof value === 'boolean' ? value.toString() : undefined);

const stringToBoolean = value => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return undefined;
};

const FeedbackForm = ({ model, onSubmit, onChange, sending, sent, validationErrors }) => {
  const [t] = useTranslations();
  const theme = useTheme();

  return (
    <InputForm onPropertyChange={onChange} onSubmit={onSubmit} model={model} validationErrors={validationErrors}>
      <InputRow fullRow>
        <StyledInputLabel text={t('Are you happy with Smartview on today?')} />
        <RadioButtonGroup
          name="happyWithSmartView"
          value={booleanToString(model.happyWithSmartView)}
          type="icon"
          onChange={(property, value) => onChange(property, stringToBoolean(value))}
          row
        >
          <FeedbackRadioButton label={t('Yes')} value="true" icon="smiley-good" color="var(--positive-color)" />
          <FeedbackRadioButton label={t('No')} value="false" icon="smiley-bad" color="var(--negative-color)" />
        </RadioButtonGroup>
      </InputRow>
      <InputRow fullRow>
        <StyledInputLabel text={t('Feedback')} secondaryText={t('Required')} />
        <InputTextArea property="freeFormFeedback" />
      </InputRow>
      <PrimaryButton confirm large loading={sending} disabled={sent}>
        {sent ? t('Sent') : t('Send')}
      </PrimaryButton>
    </InputForm>
  );
};

FeedbackForm.propTypes = {
  model: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  sent: PropTypes.bool.isRequired,
  validationErrors: PropTypes.object,
};

export default FeedbackForm;
