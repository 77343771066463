import { createGlobalStyle } from 'styled-components';

const ResetCss = createGlobalStyle`
    /* font: Cairo */
    @font-face {
        font-family: Cairo;
        src:  url('/fonts/Cairo/Cairo-Regular.woff2') format('woff2'),
        url('/fonts/Cairo/Cairo-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: Cairo;
        src:  url('/fonts/Cairo/Cairo-Bold.woff2') format('woff2'),
        url('/fonts/Cairo/Cairo-Bold.woff') format('woff');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: Cairo;
        src:  url('/fonts/Cairo/Cairo-SemiBold.woff2') format('woff2'),
        url('/fonts/Cairo/Cairo-SemiBold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
    }

    /*
    html5doctor.com Reset Stylesheet v1.6.1
    Last Updated: 2010-09-17
    Author: Richard Clark - http://richclarkdesign.com
    */
    html, body, div, span, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    abbr, address, cite, code,
    del, dfn, em, img, ins, kbd, q, samp,
    small, strong, sub, sup, var,
    b, i,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section, summary,
    time, mark, audio, video {
        margin:         0;
        padding:        0;
        border:         0;
        outline:        0;
        font-size:      100%;
        vertical-align: baseline;
        background:     transparent;
    }
    h1, h2, h3, h4, h5, h6 {
        font-weight: normal;
    }
    h1 {
        font-size: 1.5em;
    }
    body {
        padding: 0;
        margin: 0;
        font-family: Cairo, sans-serif;
        font-size: 17px;
        line-height: 1;
        color: #222;
        background: #F7F7F7;
    }
    article,aside,details,figcaption,figure,
    footer,header,hgroup,menu,nav,section {
        display: block;
    }
    ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote::before, blockquote::after,
    q::before, q::after {
        content: '';
        content: none;
    }
    a {
        margin:           0;
        padding:          0;
        font-size:        100%;
        vertical-align:   baseline;
        background:       transparent;
        color: var(--link-fg);
        text-decoration: none;
    }

    small {
        font-size: 0.6667em;
    }

    .bshadow0, input {
        box-shadow: inset 0 -2px #e7e7e7;
    }
    input:hover {
        box-shadow: inset 0 -2px #ccc;
    }
    input, fieldset {
        font-size: 1em;
        margin: 0;
        padding: 0;
        border: 0;
    }
    input, select, textarea, button {
        font-family: inherit;
    }
    button {
        line-height: inherit;
    }
    input {
        color: inherit;
        height: 1.5em;
        padding: .25em 0;

        /* Firefox has line-height with !important set on the UA stylesheet */
        line-height: normal;
    }
    input:focus {
        outline: none;
        box-shadow: inset 0 -2px #449fdb;
    }

    /* change colours to suit your needs */
    ins {
        background-color: #FFD068;
        color: #222;
        text-decoration: none;
    }

    /* change colours to suit your needs */
    mark {
        background-color: #FFD068;
        color:            #222;
        font-style:       italic;
        font-weight:      bold;
    }
    del {
        text-decoration: line-through;
    }
    abbr[title], dfn[title] {
        border-bottom:    1px dotted;
    cursor:           help;
    }
    table {
        border-collapse:  collapse;
        border-spacing:   0;
    }

    /* change border color to suit your needs */
    hr {
        display:      block;
        height:       1px;
        border:       0;
        border-top:   1px solid #D3DEEA;
        margin:       1em 0;
        padding:      0;
    }
    sub, sup {
        /* Specified in % so that the sup/sup is the
        right size relative to the surrounding text */
        font-size: 75%;

        /* Zero out the line-height so that it doesn't
        interfere with the positioning that follows */
        line-height: 0;

        /* Where the magic happens: makes all browsers position
        the sup/sup properly, relative to the surrounding text */
        position: relative;

        /* Note that if you're using Eric Meyer's reset.css, this
        is already set and you can remove this rule */
        vertical-align: baseline;
    }

    sup {
        /* Move the superscripted text up */
        top: -0.5em;
    }

    sub {
        /* Move the subscripted text down, but only
        half as far down as the superscript moved up */
        bottom: -0.25em;
    }
    input, select {
        vertical-align: middle;
    }

    /* apply a natural box layout model to all elements, but allowing components to change */
    html {
        box-sizing: border-box;
    }
    *, *::before, *::after {
        box-sizing: inherit;
    }

    button::-moz-focus-inner {
        border: 0;
    }
`;

export default ResetCss;
