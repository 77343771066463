import styled, { css } from 'styled-components';
import parseISO from 'date-fns/parseISO';
import { DATE_FORMATS, format } from 'utils/Date/dateFormatter';

import Svg from 'components/Svg/Svg';
import ActionColumn from './ActionColumn';
import { getFileIconByExtension, getHumanreadableSize, indentationCSS } from 'utils/Data/documents';
import { cellPropTypes } from 'components/FlexTable/FlexTable';
import { CaretToggle } from 'components/CaretToggle/CaretToggle';

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.layoutSpacing.xs};
  min-height: ${props => !props.minimalUI && '40px'};
  cursor: ${props => props.isFolder && 'pointer'};
  ${indentationCSS}

  ${props =>
    !props.isFolder &&
    css`
      cursor: pointer;
      &:hover ${Name} {
        color: var(--document-name-fg-hover);
      }
      &:hover ${FileIcon} {
        fill: var(--document-name-fg-hover);
      }
    `}
`;

const FileIcon = styled(Svg)`
  float: left;
  font-size: ${props => props.theme.font.size.xxl};
  min-width: ${props => props.theme.font.size.xxl};
  margin: 0 var(--size-xs) 0 0;
  fill: var(--document-file-icon-fg);

  ${props => props.theme.media.landscape`
    min-width: ${props => props.theme.font.size.xxl};
  `}
`;

const Name = styled.span`
  color: var(--body-text-fg);
  font-weight: ${props => (props.isFolder ? props.theme.fontWeight.bold : props.theme.fontWeight.normal)};
  line-height: ${props => props.theme.font.lineHeight.md};
  font-size: ${props => props.theme.font.size.xs};
  overflow-wrap: break-word;
`;

const GrayText = styled.span`
  color: var(--supplemental-text-fg);
  font-weight: ${props => props.theme.fontWeight.text};
  font-size: ${props => (props.minimalUI ? props.theme.font.size.xxs : props.theme.font.size.xs)};
  line-height: ${props => props.theme.font.lineHeight.md};
`;

export const CeruleanText = styled.span`
  color: var(--blue-02);
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.font.size.xs};
  white-space: nowrap;
`;

export const DownloadLink = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &:active {
    & + ${CeruleanText} {
      color: var(--download-link-fg-active);
    }
  }
`;

const ChildCount = styled.span`
  color: var(--supplemental-text-fg);
  font-weight: ${props => props.theme.fontWeight.normal};
  font-size: ${props => props.theme.font.size.xs};
`;

const FileNameCell = ({ value, row }) => (
  <NameContainer depth={row.depth} onClick={() => row.original.onDownload(row.original, true)}>
    <FileIcon name={getFileIconByExtension(row.original)} />
    <Name>{value}</Name>
  </NameContainer>
);
FileNameCell.propTypes = cellPropTypes;

const FolderNameCell = ({ value, row }) => (
  <NameContainer
    depth={row.depth}
    onClick={() => {
      if (!row.isExpanded && row.original.onFolderOpen) {
        row.original.onFolderOpen(row.original);
      }
      row.toggleRowExpanded(!row.isExpanded);
    }}
    isFolder
  >
    <CaretToggle type="accordion" isOpen={row.isExpanded} />
    <Name isFolder>
      {value}
      {row.original.isVirtualFolder && ' *'}
    </Name>
    {!row.original.buildingId ? <ChildCount>({row.subRows?.length || 0})</ChildCount> : <></>}
  </NameContainer>
);
FolderNameCell.propTypes = cellPropTypes;

export const NameCell = props =>
  props.row.original.isFolder ? <FolderNameCell {...props} /> : <FileNameCell {...props} />;
NameCell.propTypes = cellPropTypes;

export const DateCell = ({ value, row }) =>
  row.original.isFolder ? null : <GrayText>{format(parseISO(value), DATE_FORMATS.dateTimeLong)}</GrayText>;
DateCell.propTypes = cellPropTypes;

export const SizeCell = ({ value, row }) =>
  row.original.isFolder ? null : <GrayText>{getHumanreadableSize(value)}</GrayText>;
SizeCell.propTypes = cellPropTypes;

export const DownloadCell = ({ value, row }) =>
  typeof row.original.onDownload === 'function' && (
    <>
      <DownloadLink
        href=""
        onClick={e => {
          e.preventDefault();
          row.original.onDownload(row.original);
        }}
      />
      <CeruleanText>{value}</CeruleanText>
    </>
  );
DownloadCell.propTypes = cellPropTypes;

export const ActionCell = ({ value, row }) => <ActionColumn actions={value} data={row.original} />;
ActionCell.propTypes = cellPropTypes;
