import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import EventsRow from 'components/BuildingEvents/EventsRow/EventsRow';
import EventsCell from 'components/BuildingEvents/EventsRow/EventsCell';
import styled from 'styled-components';
import { rgba } from 'polished';
import { indentationCSS } from 'utils/Data/documents';
import { CaretToggle } from 'components/CaretToggle/CaretToggle';

const StyledRow = styled(EventsRow)`
  td {
    &:first-child {
      font-weight: ${props => !props.depth && props.theme.fontWeight.bold};
      ${props => (!props.onClick ? 'padding-left: var(--size-md);' : '')};
    }
    &:not(:first-child) {
      border-left: 1px solid ${props => props.theme.colors.mystic};
    }
    &:nth-child(2) {
      background-color: ${props => rgba(props.theme.colors.cerulean, 0.1)};
      font-weight: ${props => props.theme.fontWeight.bold};
    }
    ${indentationCSS};
  }
`;
StyledRow.displayName = 'StyledRow';

const ParentRowName = styled.span`
  color: ${props => props.theme.colors.black};
  padding-left: var(--size-xxs);
`;
ParentRowName.displayName = 'ParentRowName';

const Cell = styled(EventsCell)`
  padding: var(--size-sm);
  text-align: ${props => props.textAlign};
  display: ${props => props.hideOnMobile && 'none'};

  ${props => props.theme.media.landscape`
    display: ${props => (props.hideOnDesktop ? 'none' : 'table-cell')};
  `}
`;
Cell.displayName = 'Cell';

const SummaryRow = props => {
  const [isClosed, setIsClosed] = useState(true);
  const renderSingleRow = (rowData, depth) => (
    <StyledRow key={rowData.type} depth={depth}>
      {props.rowData.columns.map((column, index) => (
        <Cell key={column.field} textAlign={!index ? 'left' : 'right'} hideOnMobile={index > 2}>
          {rowData[column.field]}
        </Cell>
      ))}
    </StyledRow>
  );

  const renderCollapsibleRow = () => (
    <Fragment>
      <StyledRow onClick={() => setIsClosed(!isClosed)}>
        {props.rowData.columns.map((column, index) => {
          if (!index) {
            return (
              <Cell key={column.field}>
                <CaretToggle type="accordion" isOpen={!isClosed} />
                <ParentRowName>{props.rowData.type}</ParentRowName>
              </Cell>
            );
          }
          return (
            <Cell key={column.field} textAlign="right" hideOnMobile={index > 2}>
              {props.rowData[column.field]}
            </Cell>
          );
        })}
      </StyledRow>
      {!isClosed && props.rowData.children.map(childRowData => renderSingleRow(childRowData, 1))}
    </Fragment>
  );
  return props.rowData.children ? renderCollapsibleRow() : renderSingleRow(props.rowData);
};

SummaryRow.propTypes = {
  t: PropTypes.func,
  rowData: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  depth: PropTypes.number,
};

export default SummaryRow;
