import PropTypes from 'prop-types';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';

import { StyledSnackBar } from 'components/SnackBar/SnackBar';
import SecondaryButton from 'components/Button/SecondaryButton';
import Svg from 'components/Svg/Svg';

const Content = styled.div`
  max-width: 54em;
  margin: 0 auto;
  padding: 1em;
  min-height: 4em;
  font-weight: ${props => props.theme.font.weight.semibold};
  color: ${props => props.theme.colors.black};

  ${props => props.theme.media.desktop`
    max-width: 80em;
  `};
`;

const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Message = styled.div`
  color: ${props => props.theme.colors.black};
  margin-bottom: var(--size-sm);
`;

const SuccessSvg = styled(Svg)`
  height: 1.25em;
  width: 1.25em;
  margin-right: var(--size-sm);
  vertical-align: middle;
  fill: ${props => props.theme.colors.emerald};
`;

const ErrorSvg = styled(Svg)`
  height: 1em;
  width: 1em;
  margin-right: var(--size-sm);
  vertical-align: middle;
  fill: ${props => props.theme.colors.radicalRed};
`;

export const ErrorContent = styled.ul``;

export const Error = styled.li`
  padding: var(--size-xxs) 0;
`;

const Bold = styled.span`
  font-weight: ${props => props.theme.font.weight.bold};
`;

export const ErrorSnackBar = ({ t, error = {}, onClose }) => {
  return (
    <StyledSnackBar visible={error.visible} variant="confirmation" hideDelay={0}>
      <Content>
        <MainContent>
          <div>
            {error.message && (
              <Message>
                <SuccessSvg name="check" /> {error.message}
              </Message>
            )}
            <ErrorContent>
              {error.errored.map(errorText => (
                <Error key={errorText}>
                  <ErrorSvg name="remove" /> <Bold>{t('Failed')}:&nbsp;</Bold>
                  {errorText}
                </Error>
              ))}
            </ErrorContent>
          </div>
          <SecondaryButton large onClick={onClose}>
            {t('Close')}
          </SecondaryButton>
        </MainContent>
      </Content>
    </StyledSnackBar>
  );
};

ErrorSnackBar.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.shape({ visible: PropTypes.bool, message: PropTypes.string, errored: PropTypes.array }).isRequired,
};

export default translations(ErrorSnackBar);
