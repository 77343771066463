import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';
import Helmet from 'react-helmet';
import { useTranslations } from 'decorators/Translations/translations';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Hero from 'components/Hero/Hero';
import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import { getPartnerNumbers } from 'utils/profile';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import Alarms from './Alarms';
import Observations from './Observations';
import Inspections from './Inspections';
import Export from './Export';
import {
  isControlRoomTabEnabled,
  isRemoteCenterAlarmsEnabled,
  isRemoteCenterObservationsEnabled,
  isRemoteCenterInspectionsEnabled,
} from 'utils/Data/profileData';
import { REMOTE_CENTER_TABS, getTabOptions } from './utils';
import Content from 'components/Content/Content';

const Container = styled.div`
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  width: 100%;
  margin: var(--size-md) auto 0;

  ${props => props.theme.media.landscape`
    margin: ${props => props.theme.section.spacing} auto 0;
  `}
`;
Container.displayName = 'Container';

const ExportContainer = styled.div`
  margin: var(--size-sm) 0;

  ${props => props.theme.media.portrait`
    margin: var(--size-xs) 0 var(--size-xs) var(--size-xs);
  `}

  ${props => props.theme.media.landscape`
    margin: calc(-1 * var(--size-xs)) 0 auto;
  `}
`;

const RemoteCenter = ({
  match: {
    params: { partnerNumber },
    path,
    url,
  },
  location,
  history,
}) => {
  const [t] = useTranslations();
  const profile = useSelector(state => state.profile.profile);
  const customers = useSelector(state => state.customer.customers);
  const features = useSelector(state => state.profile.profile.features);

  if (!isControlRoomTabEnabled(features)) {
    return null;
  }

  if (!partnerNumber || partnerNumber === 'all') {
    return <ErrorPage type="selectPartner" />;
  }

  if (!getPartnerNumbers(profile).includes(partnerNumber)) {
    return <ErrorPage type="partner" />;
  }

  const tabOptions = getTabOptions(features, t);

  if (tabOptions.length === 0) {
    return null;
  }

  const customerName = customers[partnerNumber]?.name;

  const getDefaultPath = features => {
    if (isRemoteCenterAlarmsEnabled(features)) {
      return REMOTE_CENTER_TABS.ALARMS;
    }
    if (isRemoteCenterObservationsEnabled(features)) {
      return REMOTE_CENTER_TABS.OBSERVATIONS;
    }
    if (isRemoteCenterInspectionsEnabled(features)) {
      return REMOTE_CENTER_TABS.INSPECTIONS;
    }
  };

  const selectedTab =
    tabOptions.map(option => option.value).find(tabValue => location.pathname.endsWith(tabValue)) ||
    getDefaultPath(profile.features);

  return (
    <StandardPage withTabs>
      <Helmet title={t('Control Room')} />
      <Header t={t} selected="remote center" showPartnerSelect />
      <Hero title={t('Control Room')} subTitle={customerName} t={t} type="CONTROL_ROOM" />
      <Container>
        <SectionHeader data-test-id="tab-container">
          <SectionTabSelector
            options={tabOptions}
            model={{ selectedTab }}
            property="selectedTab"
            onTabChange={(_, value) => history.push(`${url}/${value}`)}
          />
          <ExportContainer>
            <Export selectedTab={selectedTab} partnerNumber={partnerNumber} />
          </ExportContainer>
        </SectionHeader>
        <Content>
          <Switch>
            {isRemoteCenterAlarmsEnabled(profile.features) && (
              <Route
                path={`${path}/${REMOTE_CENTER_TABS.ALARMS}`}
                render={() => <Alarms partnerNumber={partnerNumber} />}
              />
            )}
            {isRemoteCenterInspectionsEnabled(profile.features) && (
              <Route
                path={`${path}/${REMOTE_CENTER_TABS.INSPECTIONS}`}
                render={() => <Inspections partnerNumber={partnerNumber} />}
              />
            )}
            {isRemoteCenterObservationsEnabled(profile.features) && (
              <Route
                path={`${path}/${REMOTE_CENTER_TABS.OBSERVATIONS}`}
                render={() => <Observations partnerNumber={partnerNumber} />}
              />
            )}
            <Redirect from={`${path}/`} to={`${path}/${getDefaultPath(profile.features)}`} />
          </Switch>
        </Content>
      </Container>
    </StandardPage>
  );
};

RemoteCenter.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default RemoteCenter;
