import { GroupBase, mergeStyles, StylesConfig } from 'react-select';
import { DefaultTheme } from 'styled-components';

function defaultSelectInputStyles<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  theme: DefaultTheme
): StylesConfig<Option, IsMulti, Group> {
  return {
    control: (provided, { isDisabled }) => ({
      ...provided,
      display: 'flex',
      boxShadow: 'unset',
      fontSize: theme.font.size.xs,
      fontWeight: theme.font.weight.normal,
      cursor: isDisabled ? 'auto' : 'pointer',
      backgroundColor: 'var(--input-bg)',
      border: '1px solid var(--input-bc)',
      borderRadius: '1px',
      padding: 'var(--size-xs) var(--size-sm)',
    }),
    valueContainer: provided => ({
      ...provided,
      padding: `0 ${theme.spacing.xs} 0 0`,
    }),
    input: provided => ({
      ...provided,
      fontSize: theme.font.size.xs,
      fontWeight: theme.font.weight.normal,
      input: {
        boxShadow: 'unset',
        height: `${theme.font.lineHeight.md}em`,
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: 'var(--input-option-fg)',
      fontWeight: theme.font.weight.normal,
      backgroundColor: 'transparent',
      margin: 0,
      overflow: 'visible',
    }),
    placeholder: provided => ({
      ...provided,
      color: 'var(--input-placeholder-fg)',
      fontWeight: theme.font.weight.normal,
      backgroundColor: 'transparent',
      margin: 0,
    }),
    indicatorsContainer: () => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      fontSize: '0.5rem',
    }),
    dropdownIndicator: (provided, { isDisabled, isFocused, selectProps }) => ({
      ...provided,
      fill: isDisabled
        ? 'var(--input-subcontrol-fg-disabled)'
        : isFocused
          ? 'var(--input-subcontrol-fg-focused)'
          : 'var(--input-subcontrol-fg)',
      padding: 0,
      transition: 'all .2s ease',
      transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
    }),
    clearIndicator: (provided, { isFocused }) => ({
      ...provided,
      fill: isFocused ? 'var(--input-subcontrol-fg-focused)' : 'var(--input-subcontrol-fg)',
      padding: theme.spacing.xs,
    }),
    loadingIndicator: provided => ({
      ...provided,
      padding: 0,
      marginRight: theme.spacing.xxs,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: provided => ({
      ...provided,
      boxShadow: 'none',
      border: `1px solid var(--input-bc)`,
      backgroundColor: 'var(--input-bg)',
      borderRadius: '1px',
      zIndex: 10,
      marginTop: '-1px',
      marginBottom: '-1px',
    }),
    option: (provided, { isFocused, isDisabled, isSelected }) => ({
      ...provided,
      color: isDisabled ? 'var(--input-option-fg-disabled)' : 'var(--input-option-fg)',
      fontSize: theme.font.size.xs,
      fontWeight: isSelected ? theme.fontWeight.bold : theme.fontWeight.normal,
      fontStyle: 'normal',
      backgroundColor: 'var(--input-option-bg)',
      ...(isSelected && { backgroundColor: 'var(--input-option-bg-selected)' }),
      ...(isFocused && { backgroundColor: 'var(--input-option-bg-hover)' }),
      ...(isDisabled && { backgroundColor: 'var(--input-bg-disabled)' }),
      ':hover': {
        ...(!isDisabled && { backgroundColor: 'var(--input-option-bg-hover)' }),
      },
      textAlign: 'left',
      cursor: isDisabled ? 'default' : 'pointer',
      wordBreak: 'normal',
      overflowWrap: 'anywhere',
    }),
  };
}

function V5Styles<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  theme: DefaultTheme,
  styles: StylesConfig<Option, IsMulti, Group> | undefined
): StylesConfig<Option, IsMulti, Group> {
  return mergeStyles(defaultSelectInputStyles(theme), styles);
}

export default V5Styles;
