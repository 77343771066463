import { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import { connect } from 'react-redux';
import remoteCenter from 'services/remoteCenter';

import { StyledDateColumn } from '../BuildingEvents/ColumnTypes/DateColumn';
import AnnotationRow from './AnnotationRow';
import { INCIDENT_ORIGIN } from 'utils/Data/notice';

const ObservationViewDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.media.landscape`
    flex-direction: row;
  `}
`;

const ObservationViewDetails = styled.div`
  background: var(--grey-box-bg);
  border: var(--box-border);
  margin-bottom: var(--size-xl);
  padding: var(--size-xs) var(--size-lg) var(--size-md);

  ${StyledDateColumn} {
    padding-left: 0;
  }

  ${props => props.theme.media.landscape`
    margin-bottom: 0;
    margin-right: var(--size-xl);
  `}
`;

const ObservationViewDescription = styled.div`
  margin-top: var(--size-md);
  margin-bottom: var(--size-xl);
`;

const ObservationViewDescriptionText = styled.p`
  margin: var(--size-md) 0;
  white-space: pre-line;
  line-height: ${props => props.theme.font.lineHeight.md};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.media.landscape`
    flex: ${props => props.flexSize};
  `}
`;

const EnergyImpactHeader = styled.div`
  display: inline-block;
  padding: var(--size-md);
  background: var(--grey-box-bg);
  border: var(--box-border);
  border-bottom: none;

  margin-bottom: calc(-1 * var(--border-thin));
  position: relative;
  z-index: 1;
`;

const ObservationViewValues = styled.div`
  display: flex;
`;

const ObservationViewLabel = styled.div`
  margin: var(--size-md) 0 var(--size-xs);
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.bold};
  color: var(--subheader-fg);
`;

export const ObservationViewValue = styled.div`
  white-space: nowrap;
  ${props =>
    !props.noMargin
      ? css`
          margin-bottom: var(--size-xs);
          margin-right: var(--size-xs);
        `
      : ''}

  padding: ${props => {
    if (props.value !== undefined) {
      return 'var(--size-xs) var(--size-sm)';
    }
  }};
  background-color: ${props => {
    if (props.value < 0) {
      return 'var(--green-01-020)';
    } else if (props.value > 0) {
      return 'var(--red-01-020';
    } else if (props.value === 0) {
      return 'var(--blue-01-020)';
    }
  }};
`;

const SmallerObservationViewValue = styled(ObservationViewValue)`
  font-size: ${props => props.theme.fontSize.xxs};
  display: inline-block;

  ${props => props.theme.media.landscape`
      font-size: ${props => props.theme.fontSize.xs};
  `}
`;

const ObservationViewAnnotations = styled.div`
  margin-top: var(--size-lg);
`;

const ObservationViewHeading = styled.h3`
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.bold};
  margin-bottom: var(--size-md);
  line-height: ${props => props.theme.font.lineHeight.sm};
`;

const ObservationViewContent = styled.div``;

const ObservationViewImage = styled.div`
  margin-top: var(--size-lg);
  img {
    max-width: 100%;
    max-height: 40rem;
    border: var(--box-border);
  }
`;

const ExternalSystemWrapper = styled.div`
  display: inline-block;
  margin-bottom: 2em;
`;

const ExternalSystemHeader = styled.div`
  display: inline-block;
  font-weight: ${props => props.theme.font.weight.bold};
  margin-right: 5px;
`;

export const ObservationView = ({ t, download, observation = {} }) => {
  const {
    date,
    description,
    savingsPotentialHeat,
    savingsPotentialElectricity,
    electricitySavingsYearlySum,
    heatSavingsYearlySum,
    savingsLifeSpan,
    savingsCost,
    paybackPeriod,
    status,
    annotations = [],
    origin,
    IOTImageUrl,
    actionType,
    totalSavingsYearlySum,
    otherSavingsYearlySum,
    savingsHeatPrice,
    savingsElectricityPrice,
    savingsWaterPrice,
    energyImpact,
    externalSystem,
    externalSystemId,
    endTime,
  } = observation;

  const viewElement = useRef(null);
  useEffect(() => {
    if (typeof viewElement?.current?.scrollIntoView === 'function') {
      viewElement.current.scrollIntoView();
    }
  }, []);

  const [downloading, setDownloading] = useState(null);

  const handleDownload = async annotation => {
    try {
      setDownloading(annotation.id);
      await download({ id: observation.id, annotationId: annotation.id });
    } finally {
      setDownloading(null);
    }
  };

  const isCompleted = status && status.status === 'completed';
  const sortedAnnotations = annotations
    .map(annotation => ({ ...annotation, timestamp: new Date(annotation.timestamp) }))
    .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  const hasResolution = sortedAnnotations.some(annotation => annotation.type === 'resolution');
  const createdByText = `Observation created by ${
    origin === INCIDENT_ORIGIN.analytics ? 'Analytics' : 'Caverion Expert'
  }`;
  const hasExternalSystemInformation = externalSystem && externalSystemId;

  const rowData = [
    {
      subject: t(createdByText),
      timestamp: date && date.date,
      type: 'created',
    },
    ...sortedAnnotations,
    ...(isCompleted && !hasResolution
      ? [
          {
            subject: t('Observation completed'),
            type: 'completed',
            timestamp: endTime ? new Date(endTime) : null,
          },
        ]
      : []),
  ];
  return (
    <ObservationViewDetailsWrapper>
      <Column flexSize={energyImpact ? '3' : '2'}>
        {energyImpact && (
          <>
            <div>
              <EnergyImpactHeader>{t('Energy Impact')}</EnergyImpactHeader>
            </div>
            <ObservationViewDetails>
              {actionType && (
                <>
                  <ObservationViewLabel>{t('Action type')}</ObservationViewLabel>
                  <ObservationViewValue>{t(actionType)}</ObservationViewValue>
                </>
              )}
              {isNumber(savingsPotentialHeat) && savingsPotentialHeat !== 0 && (
                <>
                  <ObservationViewLabel>{t('Heating')}</ObservationViewLabel>
                  <ObservationViewValues>
                    <ObservationViewValue value={savingsPotentialHeat}>
                      <strong>{`${savingsPotentialHeat / 1000} MWh`}</strong>
                      {` / ${t('year')}`}
                    </ObservationViewValue>
                    {isNumber(heatSavingsYearlySum) && (
                      <ObservationViewValue value={heatSavingsYearlySum}>
                        <strong>{`${heatSavingsYearlySum / 100} €`}</strong>
                        {` / ${t('year')}`}
                      </ObservationViewValue>
                    )}
                  </ObservationViewValues>
                </>
              )}
              {isNumber(savingsPotentialElectricity) && (
                <>
                  <ObservationViewLabel>{t('Electricity')}</ObservationViewLabel>
                  <ObservationViewValues>
                    <ObservationViewValue value={savingsPotentialElectricity}>
                      <strong>{`${savingsPotentialElectricity / 1000} MWh`}</strong>
                      {` / ${t('year')}`}
                    </ObservationViewValue>
                    {isNumber(electricitySavingsYearlySum) && (
                      <ObservationViewValue value={electricitySavingsYearlySum}>
                        <strong>{`${electricitySavingsYearlySum / 100} €`}</strong>
                        {` / ${t('year')}`}
                      </ObservationViewValue>
                    )}
                  </ObservationViewValues>
                </>
              )}
              {isNumber(savingsCost) && (
                <>
                  <ObservationViewLabel>{t('Investment')}</ObservationViewLabel>
                  <ObservationViewValue>{`${savingsCost / 100} €`}</ObservationViewValue>
                </>
              )}
              {isNumber(savingsLifeSpan) && (
                <>
                  <ObservationViewLabel>{t('Life span')}</ObservationViewLabel>
                  <ObservationViewValue>{`${savingsLifeSpan} ${t('years')}`}</ObservationViewValue>
                </>
              )}
              {isNumber(paybackPeriod) && (
                <>
                  <ObservationViewLabel>{t('Payback period')}</ObservationViewLabel>
                  <ObservationViewValue>{`${paybackPeriod} ${t('years')}`}</ObservationViewValue>
                </>
              )}
              {isNumber(totalSavingsYearlySum) && (
                <>
                  <ObservationViewLabel>{t('Yearly total savings')}</ObservationViewLabel>
                  <ObservationViewValue>{`${totalSavingsYearlySum / 100} €`}</ObservationViewValue>
                </>
              )}
              {isNumber(otherSavingsYearlySum) && (
                <>
                  <ObservationViewLabel>{t('Yearly other savings')}</ObservationViewLabel>
                  <ObservationViewValue>{`${otherSavingsYearlySum / 100} €`}</ObservationViewValue>
                </>
              )}
              {isNumber(savingsHeatPrice) && (
                <>
                  <ObservationViewLabel>{t('Heating savings price')}</ObservationViewLabel>
                  <ObservationViewValue>{`${savingsHeatPrice / 100} €`}</ObservationViewValue>
                </>
              )}
              {isNumber(savingsElectricityPrice) && (
                <>
                  <ObservationViewLabel>{t('Electricity savings price')}</ObservationViewLabel>
                  <ObservationViewValue>{`${savingsElectricityPrice / 100} €`}</ObservationViewValue>
                </>
              )}
              {isNumber(savingsWaterPrice) && (
                <>
                  <ObservationViewLabel>{t('Water savings price')}</ObservationViewLabel>
                  <ObservationViewValue>{`${savingsWaterPrice / 100} €`}</ObservationViewValue>
                </>
              )}
            </ObservationViewDetails>
          </>
        )}
      </Column>
      <Column flexSize={energyImpact ? '9' : '10'}>
        <ObservationViewContent>
          <ObservationViewHeading>{t('Description')}</ObservationViewHeading>
          <ObservationViewDescription>
            <ObservationViewDescriptionText>{description}</ObservationViewDescriptionText>
            {!!IOTImageUrl && (
              <ObservationViewImage>
                <img src={IOTImageUrl} alt="" />
              </ObservationViewImage>
            )}
          </ObservationViewDescription>
          {hasExternalSystemInformation && (
            <ExternalSystemWrapper>
              <ExternalSystemHeader>{`${t('External system')}:`}</ExternalSystemHeader>
              <SmallerObservationViewValue>
                {t(externalSystem)} ({externalSystemId})
              </SmallerObservationViewValue>
            </ExternalSystemWrapper>
          )}

          <ObservationViewHeading>{t('Timeline')}</ObservationViewHeading>
          <ObservationViewAnnotations>
            {rowData.map((annotation, index) => (
              <AnnotationRow
                annotation={annotation}
                index={index}
                key={index}
                download={() => handleDownload(annotation)}
                downloading={downloading === annotation.id}
              />
            ))}
          </ObservationViewAnnotations>
        </ObservationViewContent>
      </Column>
    </ObservationViewDetailsWrapper>
  );
};

ObservationView.propTypes = {
  t: PropTypes.func.isRequired,
  observation: PropTypes.object,
  download: PropTypes.func,
};

const mapDispatchToProps = {
  download: remoteCenter.downloadObservationAnnotationFile,
};

const connector = connect(null, mapDispatchToProps);

export default connector(translations(ObservationView));
