import styled from 'styled-components';
import PropTypes from 'prop-types';

export const TristateIconMark = styled.span`
  display: block;
  width: 16px;
  height: 3px;
  background-color: ${props => props.colorCss};
  border-radius: 15%;
  border: 0;
  position: relative;
  top: 7px;
`;

TristateIconMark.propTypes = {
  colorCss: PropTypes.string,
};

export const TristateIconContainer = styled.span`
  display: flex;
  width: 16px;
  height: 16px;
  transform: scale(${props => props.pixelSize / 16});
`;

TristateIconContainer.propTypes = {
  pixelSize: PropTypes.number,
};

export function TristateIcon({ colorCss, style, pixelSize = 15 }) {
  return (
    <TristateIconContainer pixelSize={pixelSize} style={style}>
      <TristateIconMark colorCss={colorCss} />
    </TristateIconContainer>
  );
}

TristateIcon.propTypes = {
  style: PropTypes.object,
  colorCss: PropTypes.string,
  pixelSize: PropTypes.number,
};
