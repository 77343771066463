import { createReducerFromMapping } from 'redux/utils/index';
import groupBy from 'lodash/groupBy';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import MasterDataService from 'services/masterData';

const initialState = {
  loading: false,
  saving: false,
  meta: {},
  error: undefined,
};

export const LOAD_BUILDING_META = 'CUSTOMER_PLATFORM/BuildingMeta/LOAD_BUILDING_META';
export const LOAD_BUILDING_META_SUCCESS = 'CUSTOMER_PLATFORM/BuildingMeta/LOAD_BUILDING_META_SUCCESS';
export const LOAD_BUILDING_META_FAIL = 'CUSTOMER_PLATFORM/BuildingMeta/LOAD_BUILDING_META_FAIL';

export const loadBuildingMeta =
  (functionalLocations, { refreshCache } = {}) =>
  async dispatch => {
    if (isEmpty(functionalLocations)) {
      throw new Error('Missing mandatory argument functionalLocations');
    }
    if (!isArray(functionalLocations)) {
      functionalLocations = [functionalLocations];
    }
    dispatch({
      type: LOAD_BUILDING_META,
      payload: { functionalLocations },
    });

    try {
      const results = await Promise.all(
        functionalLocations.map(fl => MasterDataService.getBuildingMeta(fl, { refreshCache }))
      );
      dispatch({
        type: LOAD_BUILDING_META_SUCCESS,
        payload: results.flat(),
        meta: { functionalLocations },
      });
    } catch (error) {
      dispatch({
        type: LOAD_BUILDING_META_FAIL,
        meta: { functionalLocations },
        payload: error,
        error: true,
      });
      throw error;
    }
  };

export const UPDATE_BUILDING_META = 'CUSTOMER_PLATFORM/BuildingMeta/UPDATE_BUILDING_META';
export const UPDATE_BUILDING_META_SUCCESS = 'CUSTOMER_PLATFORM/BuildingMeta/UPDATE_BUILDING_META_SUCCESS';
export const UPDATE_BUILDING_META_FAIL = 'CUSTOMER_PLATFORM/BuildingMeta/UPDATE_BUILDING_META_FAIL';

export const updateBuildingMeta = (buildingMetas, functionalLocation) => async dispatch => {
  dispatch({
    type: UPDATE_BUILDING_META,
    meta: { functionalLocation },
  });
  try {
    const result = await MasterDataService.upsertBuildingMeta(buildingMetas);
    dispatch({
      type: UPDATE_BUILDING_META_SUCCESS,
      payload: result,
      meta: { functionalLocation },
    });
    await dispatch(loadBuildingMeta(functionalLocation, { refreshCache: true }));
    return result;
  } catch (error) {
    dispatch({
      type: UPDATE_BUILDING_META_FAIL,
      payload: error,
      error: true,
      meta: { functionalLocation },
    });
    throw error;
  }
};

export const DELETE_BUILDING_META = 'CUSTMOER_PLATFORM/BuildingMeta/DELETE_BUILDING_META';
export const DELETE_BUILDING_META_SUCCESS = 'CUSTMOER_PLATFORM/BuildingMeta/DELETE_BUILDING_META_SUCCESS';
export const DELETE_BUILDING_META_FAIL = 'CUSTMOER_PLATFORM/BuildingMeta/DELETE_BUILDING_META_FAIL';

export const deleteBuildingMeta = (key, functionalLocation) => async dispatch => {
  dispatch({
    type: DELETE_BUILDING_META,
    payload: {
      key,
      functionalLocation,
    },
  });
  try {
    await MasterDataService.deleteBuildingMeta(functionalLocation, key);
    dispatch({
      type: DELETE_BUILDING_META_SUCCESS,
      meta: {
        functionalLocation,
        key,
      },
    });
    await dispatch(loadBuildingMeta(functionalLocation, { refreshCache: true }));
  } catch (error) {
    dispatch({
      type: DELETE_BUILDING_META_FAIL,
      payload: error,
      error: true,
      meta: {
        functionalLocation,
        key,
      },
    });
    throw error;
  }
};

export default createReducerFromMapping(
  {
    [LOAD_BUILDING_META]: state => ({
      ...state,
      loading: true,
    }),
    [LOAD_BUILDING_META_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [LOAD_BUILDING_META_SUCCESS]: (state, action) => ({
      ...state,
      meta: {
        ...state.meta,
        ...groupBy(action.payload, 'functionalLocation'),
      },
      loading: false,
    }),
    [UPDATE_BUILDING_META]: state => ({
      ...state,
      saving: true,
    }),
    [UPDATE_BUILDING_META_FAIL]: (state, action) => ({
      ...state,
      saving: false,
      error: action.payload,
    }),
    [UPDATE_BUILDING_META_SUCCESS]: state => ({
      ...state,
      saving: false,
    }),
    [DELETE_BUILDING_META]: state => ({
      ...state,
      saving: true,
    }),
    [DELETE_BUILDING_META_FAIL]: (state, action) => ({
      ...state,
      saving: false,
      error: action.payload,
    }),
    [DELETE_BUILDING_META_SUCCESS]: state => ({
      ...state,
      saving: false,
    }),
  },
  initialState
);
