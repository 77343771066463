import { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Icon, ICON_SIZES } from 'components/Icon/Icon';

export const StyledLabel = styled.label`
  cursor: ${props => !props.disabled && 'pointer'};
  opacity: ${props => (props.checked ? 1 : 0.8)};
  display: flex;
  align-items: center;
  color: var(--input-label-fg);
  border-width: var(--border-thin);
  border-style: solid;
  border-color: ${props => (props.checked ? 'var(--checkbox-rect-bc-active)' : 'var(--checkbox-rect-bg-inactive)')};
  border-radius: 1px;
  font-size: ${props => props.theme.font.size.xs};
  line-height: ${props => props.theme.font.lineHeight.md};
  white-space: nowrap;
  margin-top: 0;
  padding: var(--size-xxs) var(--size-xs);

  &:hover {
    opacity: 1;
  }

  svg {
    ${props => (props.disabled ? 'fill: var(--passive-color);' : '')}
    margin: 0;
  }
`;
StyledLabel.displayName = 'StyledLabel';

const StyledLabelText = styled.p`
  padding-left: var(--size-xs);
  padding-right: var(--size-lg);
`;

StyledLabelText.displayName = 'StyledLabelText';

const HiddenInput = styled.input`
  display: none;
`;
HiddenInput.displayName = 'HiddenInput';

const FeedbackRadioButton = props => (
  <Fragment>
    <HiddenInput
      id={`${props.name}_${props.value}`}
      type="radio"
      name={props.name}
      value={props.value}
      checked={props.checked}
      onChange={e => props.onChange(props.name, props.value)}
      disabled={props.disabled}
    />
    <StyledLabel htmlFor={`${props.name}_${props.value}`} disabled={props.disabled} checked={props.checked}>
      <Icon name={props.icon} size={ICON_SIZES.MEDIUM} fill={props.color} />
      <StyledLabelText>{props.label}</StyledLabelText>
    </StyledLabel>
  </Fragment>
);

FeedbackRadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  icon: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
};

FeedbackRadioButton.defaultProps = {
  disabled: false,
};

export default withTheme(FeedbackRadioButton);
