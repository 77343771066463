import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from '@reduxjs/toolkit';
import { subYears, parseISO } from 'date-fns';

import { getPartnerNumbers } from 'utils/profile';
import {
  getWasteSensorsForFunctionalLocation,
  getSyntheticSensorsForFunctionalLocation,
  getWasteSensorsForPortfolio,
  getSyntheticSensorsForPortfolio,
} from 'containers/Application/Modules/RecyclingModule/RecyclingDataUtils';

export const getProfile = state => state.profile?.profile;
export const getPartnerNumber = (state, props) => props.match.params.partnerNumber;
export const getFunctionalLocationId = (state, props) => props.match.params.functionalLocationId;
export const getBuildingHierarchy = state =>
  state.sensorHierarchy?.loading ? {} : state.sensorHierarchy?.buildingHierarchy;
export const getPartnerMeta = state => state.partnerMeta?.meta;
export const getBuildingMeta = state => (state.buildingMeta?.loading ? {} : state.buildingMeta?.meta);
export const getFunctionalLocations = state => state.functionalLocations.functionalLocations;
export const getSensorHierarchy = state => state.sensorHierarchy;
export const getNotice = state => state.notice;

export const getPartnerSelected = (state, props) => {
  const partnerNumber = getPartnerNumber(state, props);
  return !!partnerNumber || partnerNumber === 'all';
};

export const hasAccessRightsSelector = createSelector(getProfile, getPartnerNumber, (profile, partnerNumber) =>
  includes(getPartnerNumbers(profile), partnerNumber)
);

export const wasteSensorsSelector = createSelector(
  getBuildingHierarchy,
  getFunctionalLocationId,
  getBuildingMeta,
  getPartnerNumber,
  getPartnerMeta,
  getFunctionalLocations,
  (buildingHierarchy, functionalLocationId, buildingMeta, partnerNumber, partnerMeta, functionalLocations) => {
    if (!isEmpty(buildingHierarchy)) {
      // building level
      if (functionalLocationId) {
        return getWasteSensorsForFunctionalLocation(buildingHierarchy, buildingMeta, functionalLocationId);
      }
      // portfolio level
      if (partnerNumber && !isEmpty(partnerMeta) && !partnerMeta[partnerNumber]?.loading) {
        return getWasteSensorsForPortfolio(
          buildingHierarchy,
          !isEmpty(buildingMeta) ? buildingMeta : {},
          partnerMeta,
          partnerNumber,
          functionalLocations
        );
      }
    }
    return [];
  }
);

export const syntheticSensorsSelector = createSelector(
  getBuildingHierarchy,
  getFunctionalLocationId,
  getPartnerNumber,
  getFunctionalLocations,
  (buildingHierarchy, functionalLocationId, partnerNumber, functionalLocations) => {
    if (!isEmpty(buildingHierarchy)) {
      // building level
      if (functionalLocationId) {
        return getSyntheticSensorsForFunctionalLocation(buildingHierarchy, functionalLocationId);
      }
      // portfolio level
      if (partnerNumber) {
        return getSyntheticSensorsForPortfolio(buildingHierarchy, partnerNumber, functionalLocations);
      }
    }
    return [];
  }
);

export const partnerFunctionalLocationsSelector = createSelector(
  getPartnerNumber,
  getFunctionalLocations,
  (partnerNumber, functionalLocations) =>
    partnerNumber &&
    !isEmpty(functionalLocations) &&
    Object.keys(functionalLocations)
      .map(functionalLocationId => functionalLocations[functionalLocationId])
      .filter(functionalLocation => includes(functionalLocation.partnerNumber, partnerNumber))
);

export const createObservationsSelector = getObservations =>
  createSelector(getObservations, observations => {
    const wasteObservations = observations.filter(item => item.wasteObservation);
    const opiTimeLimit = subYears(new Date(), 1);
    const opiObservations = wasteObservations.filter(
      entry => parseISO(entry.modifiedOn?.date ?? entry.timestamp) >= opiTimeLimit
    );
    const opiTotalCount = opiObservations.length;
    const opiCompletedCount = opiObservations.filter(entry => entry.status?.status === 'completed').length;
    return {
      opi: {
        completedRatio: opiTotalCount ? (opiCompletedCount / opiTotalCount) * 100 : 100,
        completedCount: opiCompletedCount,
        totalCount: opiTotalCount,
      },
      apiData: wasteObservations,
    };
  });
