import { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import find from 'lodash/find';
import values from 'lodash/values';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';

import { loadInspectionsContainer, loadMoreInspections } from 'redux/modules/containers/remote-center';
import Benchmarking from './Benchmarking';
import ChartSection from './ChartSection';
import { filterFLsBySelectedPartner } from 'containers/Application/Energy/EnergyUtil';
import { SkeletonFilter } from 'components/BuildingEvents/BuildingEvents';
import FilterPill from 'components/FilterPills/FilterPill';
import FilterPills from 'components/FilterPills/FilterPills';
import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { mapFls } from './utils';
import { functionalLocationName } from 'utils/Data/functionalLocations';
import { useTranslations } from 'decorators/Translations/translations';

const EMPTY_ARRAY = [];

const getMostInspections = (t, inspections, functionalLocations, partnerNumber) => {
  if (!inspections || !functionalLocations) {
    return EMPTY_ARRAY;
  }
  return mapFls(t, functionalLocations, partnerNumber, inspections, 'Inspections');
};

const getChartData = inspections =>
  [...Array(12)].map((dummy, month) => (find(inspections, item => item.month === month) || { count: 0 }).count);

const getMostPlanned = (t, slas, functionalLocations, partnerNumber, inspectionFilter) => {
  if (!slas || !functionalLocations) {
    return EMPTY_ARRAY;
  }
  const flList = functionalLocations.map(fl => {
    const to = `/${partnerNumber}/FunctionalLocation/${fl.functionalLocation}/RemoteCenter/Inspections`;
    const address = (fl.address && fl.city && `${fl.address}, ${fl.city}`) || '';
    const sla = find(slas, item => includes(item.path, fl.functionalLocation));
    let count = 0;
    if (sla) {
      count = sla.sla
        .filter(item => inspectionFilter.all || inspectionFilter[item.type])
        .reduce((sum, item) => sum + (item.upcoming || 0) + (item.open || 0) ?? 0, 0);
    }
    return { title: functionalLocationName(t, fl), subtitle: address, value: count, to };
  });
  return orderBy(flList, 'value', 'desc');
};

const currentYear = new Date().getFullYear();

const Inspections = props => {
  const {
    partnerNumber,
    loadData,
    inspectionsByPartner,
    functionalLocations,
    monthlyInspections,
    loadMoreInspections,
  } = props;

  const [t] = useTranslations();

  const [inspectionFilter, setFilter] = useState({ all: true });
  const [year, setYear] = useState(currentYear);

  useEffect(() => {
    loadData(partnerNumber);
    setYear(currentYear);
  }, [partnerNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeYear = newYear => {
    setYear(newYear);
    loadMoreInspections(partnerNumber, newYear);
  };

  const inspections = (inspectionsByPartner[partnerNumber] || {}).data || [];
  const slas = (inspectionsByPartner[partnerNumber] || {}).slaData || [];
  const filteredInspections = inspectionFilter.all
    ? inspections
    : filter(inspections, inspection => inspectionFilter[inspection.origin]);
  const loading = !inspections || inspections.loading;
  const flList = values(filterFLsBySelectedPartner(functionalLocations, partnerNumber)) || EMPTY_ARRAY;
  const mostInspections = getMostInspections(
    t,
    filteredInspections.filter(inspection => inspection.status === 'completed'),
    flList,
    partnerNumber
  );
  const mostPlanned = getMostPlanned(t, slas, flList, partnerNumber, inspectionFilter);

  const partnerMonthlyInspections = monthlyInspections && monthlyInspections[partnerNumber];
  const chartData = partnerMonthlyInspections && partnerMonthlyInspections[year];
  const filters = [...new Set(slas.flatMap(sla => sla.sla).map(sla => sla.type))];

  const categories = [];
  filters.forEach(type => {
    // Inspections to date
    const FLslas = (slas && slas.flatMap(sla => sla.sla).filter(sla => sla.type === type)) || [];
    // Inspections completed
    const inspectionActual = FLslas.reduce((sum, sla) => sum + sla.completed, 0);
    // Inspections SLA target
    const SLATarget = FLslas.reduce((sum, sla) => sum + sla.completed + sla.open + sla.started + sla.upcoming, 0);
    // Completion percentage to date
    const value = (p => (isNaN(p) ? 0 : p > 1 ? 100 : Math.round(p * 100)))(inspectionActual / SLATarget);
    const valueInside = !SLATarget && !value && 'N/a';

    // Add inspections that have value or target
    if (value || SLATarget) {
      categories.push({
        title: t(type),
        value,
        inspectionActual,
        SLATarget,
        valueInside,
        type,
      });
    }
  });
  const activeCategories = inspectionFilter.all
    ? categories
    : filter(categories, category => inspectionFilter[category.type]);
  const inspectionFilters = [{ title: t('Show All'), type: 'all' }].concat(
    categories.map(category => ({ title: category.title, type: category.type }))
  );

  const toggleFilter = (name, value) => {
    if (name === 'all') {
      setFilter({ all: value });
      return;
    }

    const newValues = { ...inspectionFilter, [name]: value, all: false };
    setFilter(newValues);
  };

  return (
    <Fragment>
      {loading ? (
        <FilterPills>
          <SkeletonFilter />
          <SkeletonFilter />
          <SkeletonFilter />
        </FilterPills>
      ) : (
        <FilterPills>
          {inspectionFilters.map(filter => (
            <FilterPill
              key={filter.type}
              selected={inspectionFilter[filter.type]}
              onClick={value => toggleFilter(filter.type, value, false)}
            >
              {filter.title}
            </FilterPill>
          ))}
        </FilterPills>
      )}
      <OPICards>
        {activeCategories.map(category => (
          <OPICard
            key={category.title}
            title={category.title}
            subtitle={`${category.inspectionActual}\u00A0${t('Inspections')}\u00A0/\u00A0${category.SLATarget}\u00A0${t(
              'SLA'
            )}\n(${currentYear})`}
            contextualHelp={{
              text: `${CTXHELP_PREFIX} ${category.title} OPI`,
            }}
            contentWithCircle={{
              value: category.valueInside || category.value,
              neutral: true,
            }}
            loading={loading}
          />
        ))}
      </OPICards>
      <Benchmarking
        t={t}
        ctxHelp={`${CTXHELP_PREFIX} Inspections Benchmarking`}
        leftTitle="Most inspections done"
        rightTitle="Most inspections planned"
        leftData={mostInspections}
        rightData={mostPlanned}
        infoText={`${currentYear}`}
        loading={loading}
      />
      <ChartSection
        title={t('Inspections Done Per Month')}
        year={year}
        changeYear={changeYear}
        series={filters.map(group => ({
          data: chartData && getChartData(chartData[group]),
          name: t(group),
          _showTooltipForZeroValue: true,
          _unit: t('Inspections'),
          _hideTooltipUnit: true,
        }))}
        loading={!chartData || partnerMonthlyInspections.loading}
      />
    </Fragment>
  );
};

Inspections.propTypes = {
  partnerNumber: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  inspectionsByPartner: PropTypes.object.isRequired,
  functionalLocations: PropTypes.object.isRequired,
  monthlyInspections: PropTypes.object.isRequired,
  loadMoreInspections: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  inspectionsByPartner: state.notice.inspectionsByPartner,
  functionalLocations: state.functionalLocations.functionalLocations,
  monthlyInspections: state.notice.monthlyInspections,
});

const mapDispatchToProps = {
  loadData: loadInspectionsContainer,
  loadMoreInspections,
};

export default connect(mapStateToProps, mapDispatchToProps)(Inspections);
