import { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import parse from 'date-fns/parse';
import getMonth from 'date-fns/getMonth';
import getDayOfYear from 'date-fns/getDayOfYear';
import setDayOfYear from 'date-fns/setDayOfYear';
import round from 'lodash/round';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';

import BarChart from 'components/Charts/BarChart';
import { ZOOM_TYPE } from 'containers/Application/Modules/EnergyModule/EnergyModuleUtils';

export const ChartWrapper = styled.div`
  position: relative;
`;
ChartWrapper.displayName = 'ChartWrapper';

export const ResetButton = styled.button`
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 40px;

  background-color: ${props => props.theme.colors.midnight};
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: ${props => props.theme.lineHeight.md};
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.letterSpacing.default};
  border-radius: 20px;
  padding: 0.5em 1.75em;
  border: 1px solid ${props => props.theme.colors.midnight};
  appearance: none;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.midnight};
  }
  &:focus {
    outline: none;
  }
`;
ResetButton.displayName = 'ResetButton';

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
Footer.displayName = 'Footer';

export const ZoomLink = styled.span`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.default};
  text-transform: uppercase;
  text-align: ${props => (props.left ? 'left' : 'right')};
  cursor: pointer;
`;
ZoomLink.displayName = 'ZoomLink';

const ZoomTitle = styled.span`
  color: ${props => props.theme.colors.rockBlue};
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.bold};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.black};
  }
`;
ZoomTitle.displayName = 'ZoomTitle';

export class ZoomableChart extends PureComponent {
  handleZoom = (newSelected, horizontal, resetTo) => {
    const {
      zoom: { type },
      onZoom,
      maxZoomLevel,
    } = this.props;
    // If max zoom level is reached, don't do anything on zoom in
    if (isNil(resetTo) && type === maxZoomLevel && !horizontal) {
      return;
    }

    // If we are on a single day view, don't do anything on click
    if (isNil(resetTo) && !horizontal && type === ZOOM_TYPE.date) {
      return;
    }

    const newZoomType = !isNil(resetTo) ? resetTo : horizontal ? type : type + 1;

    if ((newZoomType <= ZOOM_TYPE.date && !isNil(newSelected)) || !isNil(resetTo)) {
      onZoom(newSelected, newZoomType);
    }
  };

  getSelectedFromZoomingEvent = event => {
    let selected, x, category;
    // click event is from chart background:
    if (event && event.xAxis && event.xAxis.length > 0) {
      x = round(event.xAxis[0].value);
      category = event.xAxis[0].axis.categories[x];
    } else if (event && event.point) {
      // click event is from chart bar:
      x = event.point.x;
      category = event.point.category;
    }
    // if selected is a string (= month), use x-coordinate instead
    selected = isNumber(category) ? category : x;
    // use dayOfYear for date zoom
    if (this.props.zoom.type === ZOOM_TYPE.month) {
      selected = getDayOfYear(parse(`${selected}/${this.props.zoom.selected + 1}`, 'd/M', new Date()));
    }
    return selected;
  };

  onZoomIn = event => this.handleZoom(this.getSelectedFromZoomingEvent(event));
  onNext = () => this.handleZoom(this.props.zoom.selected + 1, true);
  onPrev = () => this.handleZoom(this.props.zoom.selected - 1, true);
  onReset = () => this.handleZoom(null, false, ZOOM_TYPE.year);
  onBack = () =>
    this.props.zoom.type === ZOOM_TYPE.date
      ? this.handleZoom(getMonth(setDayOfYear(new Date(), this.props.zoom.selected)), false, this.props.zoom.type - 1)
      : // going back from month-view is the same as resetting to year-view
        this.onReset();

  showChartLink = (zoom, where) => {
    if (!zoom) {
      return false;
    }
    switch (zoom.type) {
      case ZOOM_TYPE.year:
        return false;
      case ZOOM_TYPE.month:
        return !(where < 0 || where > 11);
      case ZOOM_TYPE.date:
        return !(where < 1 || where > 365);
      default:
        return false;
    }
  };

  renderBarChart = chartProps => (
    <BarChart
      language={this.props.language}
      type="column"
      height={this.props.height}
      categories={this.props.categories}
      hideLegend={false}
      series={this.props.series}
      t={this.props.t}
      valueDecimals={this.props.valueDecimals}
      pointWidth={10}
      plotBorderWidth="0"
      legendAlign="left"
      labelStyle={{
        color: this.props.theme.colors.rockBlue,
        fontWeight: this.props.theme.font.weight.bold,
        fontFamily: this.props.theme.font.family.cairo,
        letterSpacing: '1px',
        textTransform: 'uppercase',
      }}
      loading={this.props.loading}
      noZoom={true}
      temperatureSeries={this.props.temperatureSeries}
      colors={this.props.colors}
      yTitle={this.props.yTitle}
      yMax={this.props.yMax}
      {...chartProps}
    />
  );

  getTooltipHeader = point => `${point} ${this.props.xTitle}`;

  render() {
    const { t, onZoom, zoom, xTitle, loading, maxZoomLevel, yAxisConfig, plotOptions, legendReversed } = this.props;

    if (!zoom || !onZoom) {
      return this.renderBarChart({ yAxisConfig, plotOptions, legendReversed });
    }

    return (
      <ChartWrapper>
        {this.renderBarChart({
          labelRotation: zoom.type === ZOOM_TYPE.date ? '-90' : undefined,
          onClick: this.onZoomIn,
          pointer: zoom.type === ZOOM_TYPE.date || zoom.type === maxZoomLevel ? 'default' : 'pointer',
          pointWidth: zoom.type === ZOOM_TYPE.year ? 10 : zoom.type === ZOOM_TYPE.month ? 5 : 8,
          getTooltipHeader: zoom.type === ZOOM_TYPE.month ? this.getTooltipHeader : undefined,
        })}
        {!loading && zoom.type !== ZOOM_TYPE.year && (
          <ResetButton onClick={this.onReset}>{t('Reset zoom')}</ResetButton>
        )}
        {!loading && (
          <Footer>
            <ZoomLink left onClick={this.onPrev}>
              {this.showChartLink(zoom, zoom.selected - 1) && `← ${t('Previous')}`}
            </ZoomLink>
            <ZoomTitle onClick={zoom.type === ZOOM_TYPE.year ? undefined : this.onBack}>{xTitle}</ZoomTitle>
            <ZoomLink onClick={this.onNext}>{this.showChartLink(zoom, zoom.selected + 1) && `${t('Next')} →`}</ZoomLink>
          </Footer>
        )}
      </ChartWrapper>
    );
  }
}

export default withTheme(ZoomableChart);

ZoomableChart.defaultProps = {
  language: 'en',
  zoom: null,
  onZoom: undefined,
  xTitle: '',
  loading: false,
  temperatureSeries: [],
  height: 400,
  colors: undefined,
  valueDecimals: 2,
  maxZoomLevel: undefined,
};

ZoomableChart.propTypes = {
  t: PropTypes.func.isRequired,
  language: PropTypes.string,
  series: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  temperatureSeries: PropTypes.array,
  zoom: PropTypes.object,
  onZoom: PropTypes.func,
  xTitle: PropTypes.string,
  yTitle: PropTypes.string,
  yAxisConfig: PropTypes.array,
  loading: PropTypes.bool,
  height: PropTypes.number,
  colors: PropTypes.array,
  valueDecimals: PropTypes.number,
  maxZoomLevel: PropTypes.number,
  theme: PropTypes.object,
  yMax: PropTypes.number,
  plotOptions: PropTypes.object,
  legendReversed: PropTypes.bool,
};
