import PropTypes from 'prop-types';
import ErrorPageContent from './ErrorPageContent';
import translations from 'decorators/Translations/translations';

export const ErrorBoundaryFallback = ({ t }) => (
  <ErrorPageContent
    instructions={t('Please try to refresh the page or contact local support for further assistance.')}
    buttonText={t('Refresh page')}
    onButtonClick={() => window.location.reload()}
  />
);

ErrorBoundaryFallback.propTypes = {
  t: PropTypes.func,
};

export default translations(ErrorBoundaryFallback);
