import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';

import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import Loader from 'components/Loader/Loader';
import ShadowCard from 'components/ShadowCard/ShadowCard';

const StyledShadowCard = styled(ShadowCard)`
  display: inline-block;
  * {
    width: 100%;
    height: auto;
  }
  cursor: pointer;
`;

const Contents = styled.div`
  height: 100%;
  text-align: center;
  p {
    margin: var(--size-xs) 0;
  }

  display: flex;
  flex-direction: column;
`;

const ContentsTop = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreviousPDFavailableCard = styled(ShadowCard)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: 325px;
  max-width: 231px;
  padding: var(--size-xs) 0;
  cursor: pointer;
`;

const StyledColumn = props => {
  return (
    <Column
      columnWidth={{
        desktop: 3,
        landscape: 4,
        portrait: 4,
        default: 12,
      }}
    >
      {props.children}
    </Column>
  );
};

export const PrintCards = ({ t, pdfs, planLanguage, showAll }) => {
  const openNewTab = url => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const DocumentContents = document => {
    if (document.pdf) {
      return (
        <StyledColumn key={document.pdf}>
          <Contents>
            <ContentsTop>
              <StyledShadowCard
                isLoading={false}
                onClick={() => openNewTab(document.pdf)}
                title={document.title + ' - ' + document.language}
              >
                <img src={document.previewImage} alt={document.title} />
              </StyledShadowCard>
            </ContentsTop>
            <p>{document.title + ' - ' + document.language}</p>
          </Contents>
        </StyledColumn>
      );
    } else if (document.previousPdf) {
      return (
        <StyledColumn key={document.previousPdf}>
          <Contents>
            <ContentsTop>
              <PreviousPDFavailableCard
                isLoading={true}
                onClick={() => openNewTab(document.previousPdf)}
                title={
                  t('Document is being created') +
                  '. ' +
                  t('You can download the previous version of the document if you wish')
                }
              >
                <Loader size="LARGE" color="GRAY" pixelSize={80} />
              </PreviousPDFavailableCard>
            </ContentsTop>
            <p>{document.title + ' - ' + document.language}</p>
          </Contents>
        </StyledColumn>
      );
    }

    return (
      <StyledColumn key={t(document.title) + ' - ' + document.language}>
        <Contents>
          <ContentsTop title={t('The document is being created')}>
            <Loader size="LARGE" color="GRAY" pixelSize={80} />
          </ContentsTop>
          <p>{t(document.title) + ' - ' + document.language}</p>
        </Contents>
      </StyledColumn>
    );
  };

  if (pdfs.length === 0) {
    return null;
  }

  const cards = pdfs.reduce(function (previous, current) {
    if (current.locale !== planLanguage && !showAll) {
      return previous;
    }
    previous.push(DocumentContents(current));
    return previous;
  }, []);

  return <Columns>{cards}</Columns>;
};

PrintCards.propTypes = {
  t: PropTypes.func.isRequired,
  pdfs: PropTypes.array.isRequired,
  planLanguage: PropTypes.string.isRequired,
  showAll: PropTypes.bool.isRequired,
};

export default translations(PrintCards);
