import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader';
import { useTranslations } from 'decorators/Translations/translations';

const useChartSkeleton = (loading, error, noData, chartWidth, chartHeight) => {
  const [t] = useTranslations();

  let skeletonContent;

  if (loading) {
    skeletonContent = <Loader color="BLUE" size="LARGE" />;
  } else if (noData && error) {
    skeletonContent = error;
  } else if (noData) {
    skeletonContent = t('No data available');
  }

  if (skeletonContent !== undefined) {
    return (
      <SkeletonChart width={chartWidth ? `${chartWidth}px` : '100%'} height={chartHeight ? `${chartHeight}px` : '100%'}>
        {skeletonContent}
      </SkeletonChart>
    );
  }
};

export default useChartSkeleton;
