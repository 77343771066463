import { useRef } from 'react';
import PropTypes from 'prop-types';
import ComparisonChart from 'components/Charts/ComparisonChart';
import useCustomChart from '../useCustomChart';
import useComparisonCustomChart from './useComparisonCustomChart';
import CustomChartKeyValues from '../../CustomChartKeyValues/CustomChartKeyValues';
import { CustomChart as CustomChartPropType } from '../propTypes';
import { ChartContainer } from '../common';
import useChartAnnotations from 'components/Sensor/SensorAnnotations/useChartAnnotations';
import SensorAnnotations from 'components/Sensor/SensorAnnotations/SensorAnnotations';
import { useTranslations } from 'decorators/Translations/translations';
import useSensorTargetOptionsForSeries from './useSensorTargetOptionsForSeries';
import useSeriesPartition from 'components/Charts/ComparisonChart/useSeriesPartition';
import { getPointStart } from 'components/Charts/ComparisonChart/utils';
import {
  ANNOTATION_TARGET,
  ANNOTATION_TARGET_LABEL,
  intervalTypeToGranularity,
} from 'components/Sensor/SensorAnnotations/utils';
import { activeBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import useAnnotations from 'components/Sensor/SensorAnnotations/useAnnotations';

const START_DATE = new Date(0);
const END_DATE = new Date(Date.now());

const ComparisonCustomChart = props => {
  const { chart, functionalLocationId, preview, publicViewId, chartType } = props;
  const [t] = useTranslations();
  const intervalType = 'monthly';
  const containerRef = useRef();
  const { valuesByComparisonSeries, loadingSensorValues, reference } = useCustomChart({
    chart,
    functionalLocationId,
  });

  const { sensorData, handleDrilldownDataLoading, getChartType, keyValuesData, handleSeriesChange } =
    useComparisonCustomChart({
      chart,
      functionalLocationId,
      valuesByComparisonSeries,
    });

  const { annotations } = useAnnotations({
    functionalLocation: functionalLocationId,
    chart,
    startDate: START_DATE,
    endDate: END_DATE,
    publicViewId,
  });

  const {
    chartAnnotations,
    getAnnotationConfigs,
    onClickChart: onOpenAnnotations,
    onCloseAnnotations,
    annotationPopover,
    createAnnotationAt,
  } = useChartAnnotations(chart, intervalTypeToGranularity(intervalType, !activeBreakpoints().landscape), annotations);

  const pointStart = getPointStart(START_DATE, intervalType);

  const [baseSeries, outdoorTemperatureSeries] = useSeriesPartition(
    sensorData,
    reference,
    intervalType,
    pointStart,
    chartType
  );

  const baseTargetOptions = useSensorTargetOptionsForSeries(baseSeries);
  const outdoorTempTargetOptions = useSensorTargetOptionsForSeries(outdoorTemperatureSeries);

  const targetOptions = [
    {
      label: t(ANNOTATION_TARGET_LABEL[ANNOTATION_TARGET.customChart]),
      customChartId: chart.id,
    },
  ].concat(baseTargetOptions, outdoorTempTargetOptions);

  return (
    <>
      <ChartContainer ref={containerRef}>
        <ComparisonChart
          loading={loadingSensorValues}
          sensorData={sensorData}
          intervalType={intervalType}
          loadDrilldownData={handleDrilldownDataLoading}
          chartType={getChartType(chart)}
          flipAxes={chart.flipAxes}
          isPreview={preview}
          isExpanded
          reference={reference}
          handleSeriesChange={handleSeriesChange}
          onOpenAnnotations={onOpenAnnotations}
          annotations={chartAnnotations}
          getAnnotationConfigs={getAnnotationConfigs}
          baseSeries={baseSeries}
          outdoorTemperatureSeries={outdoorTemperatureSeries}
          startDate={START_DATE}
        />

        <SensorAnnotations
          annotationPopover={annotationPopover}
          createAnnotationAt={createAnnotationAt}
          chart={chart}
          functionalLocationKey={functionalLocationId}
          onClose={onCloseAnnotations}
          containerRef={containerRef}
          targetOptions={targetOptions}
        />
      </ChartContainer>
      <CustomChartKeyValues chart={chart} sensorData={keyValuesData} loading={loadingSensorValues} />
    </>
  );
};

ComparisonCustomChart.propTypes = {
  chart: CustomChartPropType.isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  preview: PropTypes.bool,
  publicViewId: PropTypes.string,
  chartType: PropTypes.string,
};

export default ComparisonCustomChart;
