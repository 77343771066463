import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslations } from 'decorators/Translations/translations';
import DialogFooter from './DialogFooter';
import PrimaryButton from '../Button/PrimaryButton/PrimaryButton';
import SecondaryButton from 'components/Button/SecondaryButton';

export const ButtonsOnlyFooter = styled(DialogFooter)`
  justify-content: flex-end;
`;
ButtonsOnlyFooter.displayName = 'ButtonsOnlyFooter';

const DialogActions = ({ cancel, confirm, delete: deleteAction }) => {
  const [t] = useTranslations();
  return (
    <ButtonsOnlyFooter>
      {cancel && (
        <PrimaryButton naked large onClick={cancel.onClick}>
          {t(cancel.label || 'Cancel')}
        </PrimaryButton>
      )}
      {deleteAction && <SecondaryButton large onClick={deleteAction.onClick} iconName="trash-can" />}
      {confirm && (
        <PrimaryButton
          confirm
          large
          id={confirm.id}
          onClick={confirm.onClick}
          type={confirm.type}
          loading={confirm.loading}
          disabled={confirm.disabled}
          add={confirm.add}
        >
          {t(confirm.label || 'Save')}
        </PrimaryButton>
      )}
    </ButtonsOnlyFooter>
  );
};

DialogActions.propTypes = {
  cancel: PropTypes.shape({
    onClick: PropTypes.func,
    label: PropTypes.string,
  }),
  confirm: PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    add: PropTypes.bool,
  }),
  delete: PropTypes.shape({
    onClick: PropTypes.func,
  }),
};

export default DialogActions;
