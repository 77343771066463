import { useCallback } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import DocumentOverview from './DocumentOverview/DocumentOverview';
import Documents from './Documents/Documents';
import PelsuDocuments from './PelsuDocuments/PelsuDocuments';

import { useTranslations } from 'decorators/Translations/translations';
import { useFunctionalLocationParams } from 'utils/Data/functionalLocations';
import { ALLOW_BETA_FEATURES } from 'utils/profile/index';

export const DOCUMENT_TAB = {
  Overview: 'Overview',
  AllDocuments: 'AllDocuments',
  Pelsu: 'Pelsu',
};

const DocumentModule = () => {
  const [t] = useTranslations();
  const { functionalLocationId } = useFunctionalLocationParams();

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const tabOptions = [
    {
      value: DOCUMENT_TAB.Overview,
      label: t('Overview'),
    },
    {
      value: DOCUMENT_TAB.AllDocuments,
      label: t('All Documents'),
    },
  ];

  if (functionalLocationId && ALLOW_BETA_FEATURES) {
    tabOptions.push({
      value: DOCUMENT_TAB.Pelsu,
      label: 'Pelsu',
    });
  }

  const selectedTab = location.pathname.split('/').slice(-1)[0];

  const handleTabChange = useCallback(
    (_, value) => {
      history.push(`${match.url}/${value}`);
    },
    [history, match.url]
  );

  return (
    <>
      <SectionHeader>
        <SectionTabSelector
          options={tabOptions}
          model={{ selectedTab }}
          property="selectedTab"
          onTabChange={handleTabChange}
          noMobilePadding
        />
      </SectionHeader>
      <Switch>
        <Route path={`${match.path}/${DOCUMENT_TAB.Overview}`} component={DocumentOverview} />
        <Route path={`${match.path}/${DOCUMENT_TAB.AllDocuments}`} component={Documents} />
        <Route path={`${match.path}/${DOCUMENT_TAB.Pelsu}`} component={PelsuDocuments} />
        <Redirect to={`${match.url}/${DOCUMENT_TAB.Overview}`} />
      </Switch>
    </>
  );
};

export default DocumentModule;
