import { useState } from 'react';
import PropTypes from 'prop-types';
import { PassThroughOutsideClickHandler } from 'components/Dialog/PassThroughOutsideClickHandler';

import { useTranslations } from 'decorators/Translations/translations';
import { Textarea } from 'components/Form/InputTextArea';
import PrimaryButton from 'components/Button/PrimaryButton/PrimaryButton';

const EditCommentForm = ({ id, comment, onCloseEdit, editServiceModuleComment }) => {
  const [editedComment, setEditedComment] = useState(comment);
  const [loading, setLoading] = useState();
  const [t] = useTranslations();

  const submit = async () => {
    setLoading(true);
    try {
      await editServiceModuleComment(id, editedComment);
      onCloseEdit();
    } finally {
      setLoading(false);
    }
  };

  return (
    <PassThroughOutsideClickHandler onOutsideClick={onCloseEdit}>
      <Textarea value={editedComment} onChange={e => setEditedComment(e.target.value)} cssHeight="75px" />
      <PrimaryButton confirm loading={loading} onClick={submit}>
        {t('Save changes')}
      </PrimaryButton>
    </PassThroughOutsideClickHandler>
  );
};

EditCommentForm.propTypes = {
  id: PropTypes.number,
  comment: PropTypes.string,
  onCloseEdit: PropTypes.func,
  editServiceModuleComment: PropTypes.func,
};
export default EditCommentForm;
