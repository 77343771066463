import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { stripUnit } from 'polished';

const Columns = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => props.justifyContent};
  margin: ${props => props.noPadding && `0 ${stripUnit(props.theme.grid.gutter) / -2 + 'rem'}`};

  ${props =>
    props.scrollMobile &&
    css`
      flex-wrap: nowrap;
      ${props => props.theme.media.portrait`
            flex-wrap: wrap;
        `}

      @media print {
        flex-wrap: wrap;
        overflow-x: initial;
      }
    `}
`;

Columns.displayName = 'Columns';

Columns.propTypes = {
  scrollMobile: PropTypes.bool,
  justifyContent: PropTypes.string,
  noPadding: PropTypes.bool,
};

export default Columns;
