import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import SignAsReadForm from './SignAsReadForm';
import RescuePlanService from 'services/rescuePlan';

export const RescuePlanSignAsRead = ({ t, planId, planLanguage }) => {
  const [signAsReadInputOptions, setSignAsReadInputOptions] = useState({});
  const [error, setError] = useState(null);

  const fetchInputOptions = async id => {
    try {
      const options = await RescuePlanService.markAsReadInputOptionsById(id, planLanguage);
      setSignAsReadInputOptions(options);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchInputOptions(planId);
  }, [planId, planLanguage]);

  const submit = useCallback(
    async model => {
      try {
        await RescuePlanService.markAsReadById(planId, model);
      } catch (error) {
        setError(error);
      }
    },
    [planId]
  );

  return <SignAsReadForm inputOptions={signAsReadInputOptions} onSubmit={submit} />;
};

RescuePlanSignAsRead.propTypes = {
  t: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  planLanguage: PropTypes.string.isRequired,
};

export default translations(RescuePlanSignAsRead);
