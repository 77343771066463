import { OrderStatus, OrderStatusColors } from 'constants/maintenance';
import { serviceOrderColors } from '../serviceOrderColors';

export const tagTextColor = ({ status, hover = false }) => {
  if (status === OrderStatus.CANCELLED) {
    return 'var(--order-cancelled-tag-fg)';
  }

  if (status === OrderStatus.DRAFT && hover) {
    return 'var(--order-draft-tag-fg)';
  }

  if (status === OrderStatus.OVERDUE) {
    return 'var(--order-overdue-tag-fg)';
  }

  if (status === OrderStatus.REQUEST) {
    return 'var(--order-request-tag-fg)';
  }

  if (status) {
    return 'var(--order-any-status-tag-fg)';
  }

  return 'var(--order-no-status-tag-fg)';
};

export const tagBackgroundColor = ({ status, statusColors, hover = false }) => {
  if (status === OrderStatus.DRAFT && hover) {
    return 'var(--order-draft-tag-bg)';
  }

  if (status === OrderStatus.OVERDUE) {
    return '--order-overdue-tag-bg';
  }

  if (status) {
    return serviceOrderColors[statusColors?.[status] || OrderStatusColors[status]] || 'var(--order-any-status-tag-fg)';
  }

  return 'var(--order-no-status-tag-bg)';
};

export const tagBorderColor = ({ status, statusColors, hover = false }) => {
  if (status === OrderStatus.CANCELLED) {
    return 'var(--order-cancelled-tag-bc)';
  }

  if ((status === OrderStatus.DRAFT && hover) || status === OrderStatus.OVERDUE) {
    return serviceOrderColors[statusColors?.[status] || OrderStatusColors[status]];
  }

  if (status) {
    return 'transparent';
  }

  return 'var(--order-no-status-tag-bc)';
};

export const styles = (status, statusColors) => ({
  backgroundColor: tagBackgroundColor({ status, statusColors }),
  backgroundHoverColor: tagBackgroundColor({ status, statusColors, hover: true }),
  borderColor: tagBorderColor({ status, statusColors }),
  borderHoverColor: tagBorderColor({ status, statusColors, hover: true }),
  textColor: tagTextColor({ status }),
  textHoverColor: tagTextColor({ status, hover: true }),
});
