import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loadFullUrl } from 'redux/modules';

import translations from 'decorators/Translations/translations';
import ErrorPageContent from 'components/ErrorPage/ErrorPageContent';
import Anonymous from 'containers/Application/Anonymous/Anonymous';
import AnonymousHeader from 'containers/Application/Anonymous/AnonymousHeader';

export const LinkRedirect = ({
  match: {
    params: { id },
  },
  loadFullUrl,
  redirectUrl,
  error,
}) => {
  useEffect(() => {
    loadFullUrl(id);
  }, [loadFullUrl, id]);

  if (!id || error) {
    return (
      <Anonymous title={'Page not found'}>
        <AnonymousHeader />
        <ErrorPageContent title={'Page not found'} />
      </Anonymous>
    );
  }

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return null;
};

LinkRedirect.propTypes = {
  match: PropTypes.object.isRequired,
  loadFullUrl: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string,
  error: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  redirectUrl: state.shortUrls.urls[props.match.params.id],
  error: state.shortUrls.errors[props.match.params.id],
});

const mapDispatchToProps = {
  loadFullUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(translations(LinkRedirect)));
