import { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Svg from 'components/Svg/Svg';
import GeneralAnnouncement from './GeneralAnnouncement';
import { announcementShape, ANNOUNCEMENT_TYPE } from './utils';
import { toggleScroll } from 'utils/Modal/toggleScroll';

const Container = styled.div`
  position: fixed;
  z-index: ${props => props.theme.zIndex('announcements') + 1};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: auto;

  ${props => props.theme.media.landscape`
    top: auto;
    top: 64px;
    right: 65px;
    bottom: auto;
    left: auto;
  `}
`;
Container.displayName = 'Container';

const Box = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: var(--card-bg);

  ${props => props.theme.media.landscape`
    width: 400px;
    height: 500px;
    margin-bottom: 10px;
    box-shadow: var(--box-shadow-bigger);
  `}
`;
Box.displayName = 'Box';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  margin: 0;
  width: 100%;
  height: 40px;
  cursor: pointer;
`;
Header.displayName = 'Header';

const Title = styled.h2`
  margin-left: 1.5em;
  transform: translateY(0.1em);
  font-size: ${props => props.theme.font.size.sm};
`;
Title.displayName = 'Title';

const CloseIcon = styled(Svg)`
  margin-right: 1.5em;
  fill: var(--window-icon-fg);
  font-size: 0.8em;
  cursor: pointer;
`;
CloseIcon.displayName = 'CloseIcon';

const Scrollable = styled.div`
  height: calc(100% - 40px);
  overflow-y: auto;
`;
Scrollable.displayName = 'Scrollable';

const Content = styled.div`
  section:last-child {
    border-bottom: 0;
  }
`;
Content.displayName = 'Content';

const NoAnnouncements = styled.div`
  color: var(--supplemental-text-fg);
  font-size: ${props => props.theme.font.size.xs};
  line-height: var(--line-height-airy);
  text-align: center;
  margin: 30% auto auto;
  width: 70%;
`;
NoAnnouncements.displayName = 'NoAnnouncements';

const NoAnnouncementsTitle = styled.h3`
  color: var(--subheader-fg);
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.fontWeight.heading};
  padding: var(--size-md);
`;
NoAnnouncementsTitle.displayName = 'NoAnnouncementsTitle';

const Triangular = styled.div`
  ${props => props.theme.media.landscape`
    position: absolute;
    top: -10px
    right: 20px;
    width: 5px;
    height: 5px;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: var(--white);
  `}
`;
Triangular.displayName = 'Triangular';

const AnnouncementBox = ({
  announcements = [],
  t,
  onClose,
  unreadAnnouncements = [],
  onReadMore,
  customers,
  saveAcknowledgements,
}) => {
  useEffect(() => {
    if (window.innerWidth <= 900) {
      toggleScroll(true);
    }

    return () => {
      if (unreadAnnouncements.length > 0) {
        saveAcknowledgements(unreadAnnouncements.map(announcement => announcement.id));
      }
      toggleScroll(false);
    };
  }, []);

  const hasAnnouncements = announcements.length + unreadAnnouncements.length > 0;

  const renderAnnouncement = (announcement, unread) => (
    <GeneralAnnouncement
      announcement={announcement}
      unread={unread}
      partner={customers[announcement.partnerNumber]}
      key={announcement.id}
      onClick={() => onReadMore(announcement)}
      t={t}
    />
  );

  return (
    <Container data-test-id="AnnouncementBox">
      <Triangular />
      <Box>
        <Header onClick={onClose}>
          <Title>{t('Announcements')}</Title>
          <CloseIcon data-test-id="AnnouncementBoxCloseIcon" name="remove" />
        </Header>
        {hasAnnouncements && (
          <Scrollable>
            {unreadAnnouncements.map(announcement => renderAnnouncement(announcement, true))}
            {announcements.map(announcement => renderAnnouncement(announcement, false))}
          </Scrollable>
        )}
        {!hasAnnouncements && (
          <NoAnnouncements>
            <NoAnnouncementsTitle>{t('No announcements available')}</NoAnnouncementsTitle>
            {t('A red circle will appear on the bell icon when new announcements are published.')}
          </NoAnnouncements>
        )}
      </Box>
    </Container>
  );
};

AnnouncementBox.propTypes = {
  announcements: PropTypes.arrayOf(
    PropTypes.oneOfType([announcementShape(ANNOUNCEMENT_TYPE.PARTNER), announcementShape(ANNOUNCEMENT_TYPE.SYSTEM)])
  ).isRequired,
  unreadAnnouncements: PropTypes.arrayOf(
    PropTypes.oneOfType([announcementShape(ANNOUNCEMENT_TYPE.PARTNER), announcementShape(ANNOUNCEMENT_TYPE.SYSTEM)])
  ),
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onReadMore: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  saveAcknowledgements: PropTypes.func.isRequired,
};

export default AnnouncementBox;
