import PropTypes from 'prop-types';
import PrimaryButton from 'components/Button/PrimaryButton';

const SaveDraftButton = ({ t, ...buttonProps }) => (
  <PrimaryButton large reveal {...buttonProps}>
    {t('Save draft')}
  </PrimaryButton>
);

SaveDraftButton.propTypes = {
  t: PropTypes.func.isRequired,
};

export default SaveDraftButton;
