import { useState, useRef, useEffect } from 'react';
import MasterDataService from 'services/masterData';
import groupBy from 'lodash/groupBy';

import useQuery from 'decorators/Query/useQuery';
import { getBuildingSensorsFromHierarchy } from 'utils/Data/sensorHierarchy';
import useStoreHelpers from './useStoreHelpers';

const DEFAULT_INTERVAL = 310 * 1000; // ~5 minutes

const usePublicSelectedFloor = (publicView, floors) => {
  const [loading, setLoading] = useState(false);
  const [selectedFloorData, setSelectedFloorData] = useState({
    floor: {},
    latestValuesBySensorId: {},
    valuesBySensorId: {},
    insightMessages: [],
    sensors: [],
  });
  const { query, setQuery } = useQuery();
  const refreshIntervalRef = useRef();
  const rotationTimeoutRef = useRef();
  const { setSensorTypes, setLatestSensorValues } = useStoreHelpers();

  const findSelectedFloor = () => {
    const selectedFloor = floors.find(
      floor => floor.shortName?.toLowerCase().trim() === query.layer?.toLowerCase().trim()
    );

    if (selectedFloor) {
      return selectedFloor;
    }

    // default to first floor
    return [...floors].sort((a, b) => a.order - b.order)[0];
  };

  const load = async () => {
    setLoading(true);
    try {
      const selectedFloor = findSelectedFloor();
      const result = await MasterDataService.floorByIdWithPublicView(publicView.id, selectedFloor.id);
      const sensors = getBuildingSensorsFromHierarchy(result.floor);
      const valuesBySensorId = groupBy(result.sensorValues, 'sensorId');

      setSelectedFloorData({ ...result, sensors, valuesBySensorId });
      setSensorTypes(sensors);
      setLatestSensorValues(result.latestValuesBySensorId);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const setNextFloor = () => {
    const selectedFloor = findSelectedFloor();
    const index = floors.findIndex(floor => floor === selectedFloor);
    const nextIndex = index === floors.length - 1 ? 0 : index + 1;
    setQuery({ layer: floors[nextIndex].shortName });
  };

  useEffect(() => {
    load();

    if (publicView.configuration?.floorRotationSeconds > 0) {
      rotationTimeoutRef.current = setTimeout(setNextFloor, publicView.configuration?.floorRotationSeconds * 1000);
    } else {
      refreshIntervalRef.current = setInterval(load, DEFAULT_INTERVAL);
    }

    return () => {
      if (rotationTimeoutRef.current) {
        clearTimeout(rotationTimeoutRef.current);
      }
      if (refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
      }
    };
  }, [query.layer]); // eslint-disable-line react-hooks/exhaustive-deps

  return [selectedFloorData, loading, false];
};

export default usePublicSelectedFloor;
