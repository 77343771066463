import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import range from 'lodash/range';

import SimpleLineChart from 'components/Charts/SimpleLineChart';
import SimpleBarChart from 'components/Charts/SimpleBarChart';
import { ChartLoader } from './StatusInfoChart';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { ProgressBarWrapper, CHART_HEIGHT, CHART_HEIGHT_MOBILE, BAR_HEIGHT } from './StatusInfo';
import { format } from 'utils/Date/dateFormatter';

const StatusInfoTrendChartContainer = styled.div`
  ${props => props.theme.media.landscape`
    margin: var(--size-md);
    margin-bottom: 0;
  `}
`;
StatusInfoTrendChartContainer.displayName = 'StatusInfoTrendChartContainer';

const BarChartContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: var(--size-xs);
`;

const StyledSimpleBarChart = styled(SimpleBarChart)`
  width: 100%;
  max-width: 150px;
`;

const formatCategory = category => format(category, 'MMM');
const placeholderCategories = range(0, 12);

export const StatusInfoTrendChart = ({ chart, theme, loading, formatTooltip }) => {
  if (loading) {
    return <ChartLoader size={CHART_HEIGHT} mobileSize={CHART_HEIGHT_MOBILE} />;
  }

  if (isEmpty(chart.data)) {
    return (
      <ProgressBarWrapper>
        <ProgressBar color={theme.colors.mystic} value={0} height={BAR_HEIGHT} />
      </ProgressBarWrapper>
    );
  }

  const { name, data } = chart;
  return (
    <StatusInfoTrendChartContainer>
      {chart.type === 'column' ? (
        <BarChartContainer>
          <StyledSimpleBarChart
            categories={chart.data.categories.length ? chart.data.categories : placeholderCategories}
            values={chart.data.values}
            formatCategory={chart.data.categories.length ? formatCategory : undefined}
            formatValue={formatTooltip}
            height={CHART_HEIGHT + 16}
            placeholder={!chart.data.categories.length}
          />
        </BarChartContainer>
      ) : (
        <SimpleLineChart
          t={key => key}
          color={theme.colors.midnight}
          height={CHART_HEIGHT + 16} // margin-bottom: 0
          lineShadow={{
            color: theme.colors.black,
            offsetX: 0,
            offsetY: 3,
            opacity: 0.08,
            width: 2,
          }}
          series={[{ name, data }]}
        />
      )}
    </StatusInfoTrendChartContainer>
  );
};

StatusInfoTrendChart.defaultProps = {
  chart: { name: '', data: [] },
};

StatusInfoTrendChart.propTypes = {
  chart: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  formatTooltip: PropTypes.func,
};

export default withTheme(StatusInfoTrendChart);
