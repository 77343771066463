import PropTypes from 'prop-types';
import PrimaryButton from 'components/Button/PrimaryButton';

const PrevButton = ({ t, ...buttonProps }) => (
  <PrimaryButton large reveal {...buttonProps}>
    {t('Return')}
  </PrimaryButton>
);

PrevButton.propTypes = {
  t: PropTypes.func.isRequired,
};

export default PrevButton;
