import PropTypes from 'prop-types';
import styled from 'styled-components';

import Svg from 'components/Svg/Svg';
import { TYPE_COLORS, TYPE_ICONS } from '../common';

const LONG_OPTION_LABEL_LENGTH = 20; // Approximately two rows of option text

const getColumnMaxWidth = (options = [], { mobile, wrapLongTexts } = {}) => {
  const longTextsNeedWrapping =
    wrapLongTexts && options.some(option => option.label?.length > LONG_OPTION_LABEL_LENGTH);
  if (options.length > 3 && !mobile && !longTextsNeedWrapping) {
    return '24%';
  }
  if (options.length === 3) {
    return '32%';
  }
  return '49%';
};
const Grid = styled.div`
  display: grid;
  grid: auto-flow 1fr / repeat(
      auto-fill,
      minmax(max(100px, ${props => getColumnMaxWidth(props.options, { mobile: true })}), 1fr)
    );
  ${props => props.theme.media.portrait`
    grid: auto-flow 1fr / repeat(auto-fill, minmax(max(100px, ${props => getColumnMaxWidth(props.options, { wrapLongTexts: true })}), 1fr));
  `}
  gap: var(--size-xxs);

  @media print {
    grid: auto-flow 1fr / repeat(auto-fill, minmax(max(100px, ${props => getColumnMaxWidth(props.options)}), 1fr));
  }
`;

const OptionButton = styled.div`
  background: ${props => (props.isChecked ? props.color : props.theme.colors.white)};
  border: 1px solid ${props => (props.isChecked ? props.color : props.theme.colors.mystic)};
  border-radius: 2px;
  padding: var(--size-sm);
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  transition: background ${props => props.theme.motion.easeIn} ${props => props.theme.motion.quick};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const IconBackground = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  background: ${props => props.color};
  transition: background ${props => props.theme.motion.easeIn} ${props => props.theme.motion.quick};
`;

const Icon = styled(Svg)`
  fill: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.font.size.xxs};
`;

const LabelContainer = styled.div`
  text-align: center;
  margin-top: var(--size-xs);
`;

const Label = styled.span`
  color: ${props => (props.isChecked ? props.theme.colors.white : props.theme.colors.black)};
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.semibold};
  line-height: ${props => props.theme.font.lineHeight.lg};
`;

const Options = ({ options, selectedOption, onSelect }) => {
  const disabled = typeof onSelect !== 'function';

  return (
    <Grid options={options}>
      {options.map(({ id, label, type }) => (
        <OptionButton
          key={id}
          color={TYPE_COLORS[type] || TYPE_COLORS.neutral}
          isChecked={id === selectedOption}
          onClick={disabled ? undefined : () => onSelect(id)}
          disabled={disabled}
        >
          <IconContainer>
            <IconBackground color={TYPE_COLORS[type] || TYPE_COLORS.neutral}>
              <Icon color={TYPE_COLORS[type] || TYPE_COLORS.neutral} name={TYPE_ICONS[type]} />
            </IconBackground>
          </IconContainer>
          <LabelContainer>
            <Label isChecked={id === selectedOption} disabled={disabled}>
              {label}
            </Label>
          </LabelContainer>
        </OptionButton>
      ))}
    </Grid>
  );
};

Options.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['positive', 'alarming', 'negative', 'neutral']).isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func,
  selectedOption: PropTypes.number,
};

export default Options;
