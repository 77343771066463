import PropTypes from 'prop-types';

import Dialog from 'components/Dialog/Dialog';
import DialogFrame from 'components/Dialog/DialogFrame';
import DialogBody from 'components/Dialog/DialogBody';
import InsightMessage from './InsightMessage';

const InsightDialog = ({
  isActive = true,
  onClose,
  functionalLocation,
  insightMessage,
  t,
  enableLocationLink = true,
}) => {
  return (
    <Dialog isActive={isActive} onOverlayClick={onClose}>
      <DialogFrame onClose={onClose} smallVerticalPadding>
        <DialogBody large>
          {insightMessage ? (
            <InsightMessage
              message={insightMessage}
              functionalLocation={functionalLocation}
              t={t}
              enableLocationLink={enableLocationLink}
            />
          ) : (
            <p>{t('No data available')}</p>
          )}
        </DialogBody>
      </DialogFrame>
    </Dialog>
  );
};

InsightDialog.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  functionalLocation: PropTypes.object,
  insightMessage: PropTypes.object,
  t: PropTypes.func,
  enableLocationLink: PropTypes.bool,
};

export default InsightDialog;
