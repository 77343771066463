import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import debounce from 'lodash/throttle';

import Svg from 'components/Svg/Svg';
import ErrorBoundary from 'components/ErrorPage/ErrorBoundary';
import translations from 'decorators/Translations/translations';

export const Content = styled.div`
  background-color: var(--window-bg);

  @media not print {
    box-shadow: 0 0.125rem 1rem var(--black-015);
  }
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh; /* fallback */
  height: ${props => !props.fullScreen && props.windowHeight && `${props.windowHeight}px`};
  pointer-events: auto;

  ${props =>
    !props.fullScreen &&
    props.theme.media.landscape`
    height: auto;
    max-height: calc(100vh - 96px); /* Allow margin of 48px on both sides */
    width: auto;
    `}

  @media print {
    width: 100%;
    height: 100%;
  }
`;
Content.displayName = 'Content';

const TitleIcon = styled(Svg)`
  fill: var(--dialog-title-control-fg);
  font-size: ${props => props.theme.font.size.xs};
  pointer-events: none;
  vertical-align: bottom;
`;
TitleIcon.displayName = 'TitleIcon';

export const Scrollable = styled.div`
  height: 100%;
  overflow-x: ${props => (props.allowOverflow ? 'visible' : 'hidden')}; /* prevent scroll for Firefox */
  overflow-y: ${props => (props.allowOverflow ? 'visible' : 'scroll')};

  /* Non-standard feature to enhance iOS scrolling */
  -webkit-overflow-scrolling: touch;

  ${props => props.theme.media.landscape`
      overflow-y: ${props => (props.allowOverflow ? 'visible' : 'auto')};
      max-height: calc(100vh - 34px);
  `}

  @media print {
    height: auto;
    max-height: none;
    overflow: visible;
  }
`;
Scrollable.displayName = 'Scrollable';

const TitlePanel = styled.div`
  color: var(--dialog-title-fg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${props => props.theme.font.size.xs};
  background: var(--dialog-title-bg);
  border-bottom: var(--light-separator-border);
  padding: var(--size-xs) var(--size-lg);

  @media print {
    display: none;
  }
`;
TitlePanel.displayName = 'TitlePanel';

const Title = styled.div`
  font-weight: ${props => props.theme.font.weight.semibold};
`;
Title.displayName = 'Title';

const TitlePanelButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
TitlePanelButtons.displayName = 'TitlePanelButtons';

const TitlePanelButton = styled.div`
  display: ${props => (props.hideOnMobile ? 'none' : 'inline-block')};
  cursor: pointer;
  &:hover {
    color: var(--dialog-title-control-fg-hover);
  }
  &:hover ${TitleIcon} {
    fill: var(--dialog-title-control-fg-hover);
  }
  ${props => props.theme.media.landscape`
    display: inline-block;
  `}
  margin-left: var(--size-sm);
`;
TitlePanelButton.displayName = 'TitlePanelButton';

const TitlePanelMobileLabel = styled.div`
  display: inline-block;
  font-size: ${props => props.theme.font.size.xs};
  margin-right: var(--size-sm);
  vertical-align: top;

  ${props => props.theme.media.landscape`
    display: none;
  `}
`;
TitlePanelMobileLabel.displayName = 'TitlePanelMobileLabel';

const Footer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--dialog-footer-bg);
  border-top: var(--light-separator-border);
  padding: var(--size-xl);

  ${props => props.theme.media.landscape`
    padding: var(--size-xxl);
  `}
`;
Footer.displayName = 'Footer';

export const DialogFrame = props => {
  const { t, children, title, onClose, footer, disableFullScreen, disableTitlePanel, allowOverflow } = props;
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [fullScreen, setFullScreen] = useState(false);

  // We need ref to be able to access the latest fullScreen value in event listener
  const fullScreenRef = useRef(fullScreen);
  const toggleFullScreen = (value = !fullScreenRef.current) => {
    setFullScreen(value);
    fullScreenRef.current = value;
  };

  const handleEscape = event => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      if (fullScreenRef.current) {
        toggleFullScreen(false);
      } else {
        onClose();
      }
    }
  };

  const handleResize = debounce(() => window.innerWidth < 900 && setWindowHeight(window.innerHeight), 500);

  const fullScreenIcon = fullScreen ? 'fullscreen-exit' : 'fullscreen';

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false);
    window.addEventListener('resize', handleResize);
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const childIsAFunction = typeof children === 'function';

  return (
    <Content windowHeight={windowHeight} fullScreen={fullScreen}>
      {!disableTitlePanel && (
        <TitlePanel>
          <Title>{title}</Title>
          <TitlePanelButtons>
            {childIsAFunction && !disableFullScreen && (
              <TitlePanelButton hideOnMobile onClick={() => toggleFullScreen()}>
                <TitleIcon data-test-id="FullScreen" name={fullScreenIcon} />
              </TitlePanelButton>
            )}
            <TitlePanelButton onClick={onClose}>
              <TitlePanelMobileLabel>{t('Close')}</TitlePanelMobileLabel>
              <TitleIcon data-test-id="TitleIcon" name="remove" />
            </TitlePanelButton>
          </TitlePanelButtons>
        </TitlePanel>
      )}

      <Scrollable fullScreen={fullScreen} allowOverflow={allowOverflow}>
        <ErrorBoundary>{childIsAFunction ? children(fullScreen, toggleFullScreen) : children}</ErrorBoundary>
      </Scrollable>
      {footer}
    </Content>
  );
};
DialogFrame.displayName = 'DialogFrame';

DialogFrame.propTypes = {
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  footer: PropTypes.node,
  disableFullScreen: PropTypes.bool,
  disableTitlePanel: PropTypes.bool,
  allowOverflow: PropTypes.bool,
};

DialogFrame.defaultProps = {
  children: null,
  footer: null,
};

export default translations(DialogFrame);
