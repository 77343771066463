import { createGlobalStyle } from 'styled-components';

const BaseCss = createGlobalStyle`
    @media (min-width: 600px) {
      html {
        scroll-padding-top: 90px;
      }
    }
    h1, h2, h3, h4, h5, h6 {
    font-family: Cairo, sans-serif;
    font-weight: 600;
    color: black; }

    h1 {
    font-size: 2.75em;
    line-height: 1.2em; }
    h1.huge {
        font-size: 5em;
        line-height: 1.125em; }

    @media (max-width: 48em) {
        h1 {
        font-size: 2.5em; } }

    @media (max-width: 425px) {
        h1 {
        font-size: 1.75em; } }

    h2 {
    font-size: 2.5em;
    line-height: 1.125em; }

    @media (max-width: 48em) {
        h2 {
        font-size: 1.75em; } }

    @media (max-width: 425px) {
        h2 {
        font-size: 1.25em; } }

    h3 {
    font-size: 1.75em;
    line-height: 1.2em; }

    h4 {
    font-size: 1.25em;
    line-height: 1.2em; }
    h4.flat {
        line-height: 1em; }

    h5 {
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.2px; }
    h6 {
    font-size: 0.875em;
    line-height: 1.143em; }

    small {
    font-size: 0.75em; }

    .bold {
    font-weight: bold; }

    .white {
    color: #fff; }

    .center {
    text-align: center; }

    .fade-enter, .fade-appear {
    opacity: 0.01; }

    .fade-enter.fade-enter-active, .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 280ms ease-in; }

    .fade-exit {
    opacity: 1; }

    .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 280ms ease-out; }

    .noscroll {
    overflow: hidden; }

    @media print {
      * {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
      }
      body {
        background: white;
      }
      html.noscroll {
        font-size: 75%;
      }
      .noscroll {
        overflow: visible;
        page-break-inside: avoid;
      }
      .modal-open #root {
        display: none;
      }
      .highcharts-container, .highcharts-root {
        max-width: 100%;
        page-break-inside: avoid;
      }
    }

    /* stylelint-disable selector-id-pattern */
    #giosg_chat_now_button {
        display: none !important;
    }

    .highcharts-contextbutton, .highcharts-button {
      cursor: pointer;
    }
    .highcharts-exporting-group .highcharts-contextbutton {
      &.highcharts-button-hover .highcharts-button-box {
        fill: rgb(0 181 227 / 0.1);
      }
      .highcharts-button-box {
        width: 32px;
        height: 32px;
        rx: 50%;
        ry: 50%;
      }
      .highcharts-button-symbol {
        height: 16px;
        width: 16px;
        transform: translate(3px,4px);
      }
    }
    .highcharts-contextmenu-exporting {
      padding: 36px 15px !important;
    }

    .highcharts-button-box {
      height: 32px;
    }
    .highcharts-tooltip {
      /* Prevent (invisible) highcharts tooltip elements from interfering with highcharts-contextmenu */
      pointer-events: none;
    }

    .highcharts-annotation-labels:has(.xaxis-annotation) {
      transform: translateY(calc(100% - 30px)) !important;
    }

    .highcharts-annotation-labels {
      .highcharts-annotation-label {
        text {
          dominant-baseline: ideographic;
          pointer-events: none;
        }
      }
      .highcharts-annotation-label-box:hover {
        fill: var(--blue-02-light);
      }
    }
    .highcharts-annotation {
      clip-path: none !important;
      cursor: pointer !important;
    }

    @media (min-width: 900px) {
      #io-prismic-toolbar {
        bottom: unset !important;
        top: 0;
        left: 300px !important;
      }
    }
`;

export default BaseCss;
