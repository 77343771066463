import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';
import { setTosOpen, acknowledgeTos } from 'redux/modules';
import { isTosAcknowledged, getTos, isNewUser } from 'redux/modules/profile/profile.selectors';
import PrimaryButton from 'components/Button/PrimaryButton';
import TermsOfService from './TermsOfService';
import DialogModal from 'components/Dialog/DialogModal';
import { Footer } from 'components/Dialog/DialogFooter';

const StyledFooter = styled(Footer)`
  padding: var(--size-md) var(--size-xxl);
`;

const Error = styled.div`
  color: ${props => props.theme.colors.radicalRed};
  margin: var(--size-xs) var(--size-lg) var(--size-lg);
  text-align: center;
`;

const TermsOfServiceModal = ({ t, history, tos, isTosAcknowledged, acknowledgeTos, setTosOpen, isNewUser }) => {
  const handleDecline = useCallback(() => history.push('/Logout'), [history]);
  const handleAccept = useCallback(() => acknowledgeTos(tos.text), [acknowledgeTos, tos.text]);
  const handleClose = useCallback(() => isTosAcknowledged && setTosOpen(false), [isTosAcknowledged, setTosOpen]);
  const footerContent = (
    <StyledFooter backgroundColor="transparent" centerContent>
      {tos.error && <Error>{t('Save Failed')}</Error>}
      {!isTosAcknowledged && (
        <PrimaryButton warning invertColor large onClick={handleDecline}>
          {t('Decline the terms')}
        </PrimaryButton>
      )}
      {!isTosAcknowledged && (
        <PrimaryButton confirm large data-test-id="AcceptToSButton" onClick={handleAccept} loading={tos.saving}>
          {t('Accept the terms')}
        </PrimaryButton>
      )}
      {isTosAcknowledged && (
        <PrimaryButton naked large onClick={handleClose}>
          {t('Close')}
        </PrimaryButton>
      )}
    </StyledFooter>
  );
  // Modal will not be displayed if the translation for user terms is missing entirely
  // (this is only needed for preventing the empty modal from showing during release, while the translations are not yet updated)
  const isTranslationMissing = tos.text === 'user_terms_text';
  const showTos = (tos.open || !isTosAcknowledged) && !isTranslationMissing;
  if (!showTos) {
    return null;
  }
  let descriptionHeader = null;
  let descriptionBody = null;
  if (!isTosAcknowledged) {
    descriptionHeader = isNewUser
      ? t('user_terms_description_header_for_new_user')
      : t('user_terms_description_header_for_updated_terms');
    descriptionBody = isNewUser
      ? t('user_terms_description_body_for_new_user')
      : t('user_terms_description_body_for_updated_terms');
  }
  return (
    <DialogModal
      animate
      disableFullScreen
      disableTitlePanel
      isActive
      onOverlayClick={handleClose}
      t={t}
      footer={footerContent}
    >
      <TermsOfService
        showLogo
        text={tos.text}
        title={t('User Terms')}
        descriptionHeader={descriptionHeader}
        descriptionBody={descriptionBody}
      />
    </DialogModal>
  );
};

TermsOfServiceModal.propTypes = {
  t: PropTypes.func.isRequired,
  tos: PropTypes.shape({
    text: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
  }).isRequired,
  isTosAcknowledged: PropTypes.bool.isRequired,
  isNewUser: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  acknowledgeTos: PropTypes.func.isRequired,
  setTosOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tos: getTos(state),
  isTosAcknowledged: isTosAcknowledged(state),
  isNewUser: isNewUser(state),
});

const mapDispatchToProps = {
  acknowledgeTos,
  setTosOpen,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(withRouter(TermsOfServiceModal)));
