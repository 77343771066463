import { Children } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

export const ButtonGroupButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.thin ? 3 : 4)}rem;
  height: var(--input-height);
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  color: ${props => (props.selected ? 'var(--btn-group-fg-selected)' : 'var(--btn-group-fg)')};
  white-space: nowrap;
  letter-spacing: ${props => props.theme.letterSpacing.default};
  border: ${props => (props.selected ? '1px solid transparent' : 'var(--border-thin) solid var(--btn-group-bc)')};
  &:not(:first-child) {
    border-left: none;
  }
  background-color: ${props =>
    props.selected
      ? 'var(--btn-group-bg-selected)'
      : props.disabled
        ? 'var(--btn-group-bg-disabled)'
        : 'var(--btn-group-bg)'};
  cursor: ${props => !props.selected && !props.disabled && 'pointer'};
`;
ButtonGroupButton.displayName = 'ButtonGroupButton';
ButtonGroupButton.propTypes = {
  selected: PropTypes.bool,
  thin: PropTypes.bool,
};

const ButtonGroupContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const ButtonGroup = ({ children, value, onChange }) => {
  const handleClick = value => event => {
    event.preventDefault();
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <ButtonGroupContainer>
      {Children.map(
        children,
        child =>
          child && (
            <child.type
              {...child.props}
              selected={isNil(child.props.selected) ? child.props.value === value : child.props.selected}
              onClick={isNil(child.props.onClick) ? handleClick(child.props.value) : child.props.onClick}
            />
          )
      )}
    </ButtonGroupContainer>
  );
};

ButtonGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default ButtonGroup;
