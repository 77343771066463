import styled from 'styled-components';

export const StatusBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: ${props => props.theme.fontWeight.heading};
  font-size: ${props => props.theme.fontSize.md};
  gap: 0.5em;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  text-transform: capitalize;
  font-family: ${props => props.theme.fontFamily.subHeading};
  font-size: ${props => props.theme.fontSize.xs};

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: ${props => props.theme.fontSize.xxs};
  }
`;

export const StatusName = styled.span`
  color: var(--grey-100);
`;
