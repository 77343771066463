import { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';

import IoT from 'services/iot';
import { useTranslations } from 'decorators/Translations/translations';
import { useAnnotationDataValidity } from './annotationDataValidity.context';
import AnnotationForm from './AnnotationForm';
import useVirtualReference from 'containers/Application/Modules/CustomChartsModule/useVirtualReference';
import * as Styled from './CreateAnnotation.styled';
import getDefaultTargetOptions from './getDefaultTargetOptions';

const CreateAnnotation = props => {
  const {
    timestamp,
    sensorId,
    position,
    sensor,
    functionalLocationKey,
    chart,
    onClose,
    showNotification,
    containerRef,
    targetOptions,
    functionalLocation,
  } = props;

  const [t] = useTranslations();
  const [popperElement, setPopperElement] = useState(null);
  const virtualReference = useVirtualReference(position);
  const { styles, attributes } = usePopper(virtualReference, popperElement, {
    placement: 'top',
    modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
  });
  const { setDataInvalid } = useAnnotationDataValidity();

  const onSubmit = async annotation => {
    try {
      await IoT.upsertAnnotation({
        functionalLocation: annotation.functionalLocation,
        sensorId: annotation.sensorId ? String(annotation.sensorId) : undefined,
        customChartId: annotation.customChartId ? String(annotation.customChartId) : undefined,
        comment: annotation.comment,
        timestamp: annotation.timestamp.toISOString(),
      });
      setDataInvalid();
      showNotification({ type: 'success', message: t('Note was saved successfully'), autoHide: true });

      onClose();
    } catch (error) {
      showNotification({ type: 'error', message: t('Saving note failed'), autoHide: true });
      throw error;
    }
  };

  return ReactDOM.createPortal(
    <Styled.PortalContainer>
      <Styled.Backdrop onClick={onClose} />
      <Styled.Popover ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <Styled.Annotation>
          <AnnotationForm
            annotation={{ timestamp, sensorId: sensor?.id || sensorId, customChartId: chart?.id }}
            sensor={sensor}
            chart={chart}
            functionalLocation={functionalLocationKey}
            onSubmit={onSubmit}
            onClose={onClose}
            targetOptions={
              (targetOptions?.length && targetOptions) || getDefaultTargetOptions(sensor, chart, functionalLocation, t)
            }
          />
        </Styled.Annotation>
      </Styled.Popover>
    </Styled.PortalContainer>,
    containerRef.current
  );
};

CreateAnnotation.propTypes = {
  sensor: PropTypes.object,
  chart: PropTypes.object,
  functionalLocation: PropTypes.string,
  timestamp: PropTypes.string,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  onClose: PropTypes.func,
  showNotification: PropTypes.func,
  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

export default CreateAnnotation;
