import useNotification from './useNotification';

const withNotification = WrappedComponent => {
  const NotificationWrapperComponent = props => {
    const { showNotification, clearNotification, renderedNotification } = useNotification();
    return (
      <>
        <WrappedComponent showNotification={showNotification} clearNotification={clearNotification} {...props} />
        {renderedNotification}
      </>
    );
  };
  return NotificationWrapperComponent;
};

export default withNotification;
