import memoizeOne from 'memoize-one';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import findLast from 'lodash/findLast';
import intersection from 'lodash/intersection';
import head from 'lodash/head';
import { Division } from 'types/Division';

import { icons } from 'components/Icon/IconNames';
import { flIcons } from 'utils/Data/functionalLocations';
import {
  isMaintenanceEnabled,
  isMaintenanceModuleEnabled,
  isPartnerEnergyEnabled,
  isPartnerRecyclingEnabled,
  isPortfolioDocumentsEnabled,
  isControlRoomTabEnabled,
  isRescuePlanEnabled,
} from 'utils/Data/profileData';
import { getHeaderImageUrl, variants } from './images';
import { VIEW_TYPES, getIcon } from 'containers/Application/Admin/CustomViews/utils';
import { getPartnerNumbers } from 'utils/profile';

const paths = [
  'FunctionalLocation',
  'Maintenance',
  'ServiceRequest',
  'Equipment',
  'ServiceOrder',
  'KPI',
  'Admin',
  'Profile',
  'Logout',
  'UserManual',
  'UserManualPreview',
  'UserManualV1',
  'AdminUserManual',
  'DataMapping',
  'ContactCaverion',
  'index.html',
  'Roadmap',
];

export const isValidPartner = partner => !isNil(partner) && partner !== 'all' && !includes(paths, partner);

export const getValidPartnerFromRouter = router => {
  const partnerNumber = router.location.pathname.split('/')[1];

  if (isNil(partnerNumber) || partnerNumber === 'all') {
    return false;
  }

  if (includes(paths, partnerNumber)) {
    return false;
  }
  return partnerNumber;
};

export const getPortfolioLinks = memoizeOne(
  (partnerNumber, features, query, customViews, disabledCustomViews, featureTeasers, serviceRequestCount) => {
    const partnerPart = !partnerNumber ? '' : `/${partnerNumber}`;
    const selectedYear = query.year;
    const emptyQuery = { year: undefined, type: undefined };
    const maintenanceTo = {
      pathname: `${partnerPart}/Maintenance`,
      query: { year: selectedYear || new Date().getFullYear(), type: undefined },
    };
    const overviewTo = { pathname: `${partnerPart}/Overview`, query: emptyQuery };
    const energyTo = { pathname: `${partnerPart}/Energy`, query: emptyQuery };
    const remoteCenterTo = { pathname: `${partnerPart}/RemoteCenter`, query: emptyQuery };
    const documentsTo = { pathname: `${partnerPart}/Documents`, query: emptyQuery };
    const recyclingTo = { pathname: `${partnerPart}/Recycling`, query: emptyQuery };
    const rescuePlanTo = { pathname: `${partnerPart}/RescuePlans`, query: emptyQuery };

    const maintenanceLink = {
      title: 'Service Calendar',
      icon: icons.SERVICE_ORDERS,
      count: serviceRequestCount > 0 && isMaintenanceModuleEnabled(features) ? serviceRequestCount : undefined,
      to: maintenanceTo,
    };

    const energyTeaser = includes(featureTeasers, 'energyTab');
    const energyLink = {
      title: 'Energy',
      icon: icons.ENERGY,
      to: energyTo,
      teaser: energyTeaser,
    };

    const recyclingTeaser = includes(featureTeasers, 'recycling');
    const recyclingLink = {
      title: 'Recycling',
      icon: icons.RECYCLING,
      to: recyclingTo,
      teaser: recyclingTeaser,
    };

    const remoteCenterLink = { title: 'Remote Center', icon: icons.CONTROL_ROOM, to: remoteCenterTo };
    const documentsLink = { title: 'Documents', icon: icons.DOCUMENTS, to: documentsTo };
    const rescuePlanLink = { title: 'Pelsu', icon: icons.DOCUMENTS, to: rescuePlanTo };

    const linksToShow = [{ title: 'Overview', icon: flIcons.OVERVIEW, to: overviewTo }];

    if (isMaintenanceEnabled(features)) {
      linksToShow.push(maintenanceLink);
    }
    if ((partnerNumber !== 'all' && isPartnerEnergyEnabled(features)) || energyTeaser) {
      linksToShow.push(energyLink);
    }
    if ((partnerNumber !== 'all' && isPartnerRecyclingEnabled(features)) || recyclingTeaser) {
      linksToShow.push(recyclingLink);
    }
    if (partnerNumber !== 'all' && isControlRoomTabEnabled(features)) {
      linksToShow.push(remoteCenterLink);
    }
    if (partnerNumber !== 'all' && isPortfolioDocumentsEnabled(features)) {
      linksToShow.push(documentsLink);
    }

    if (partnerNumber !== 'all' && isRescuePlanEnabled(features)) {
      linksToShow.push(rescuePlanLink);
    }

    const enabledCustomViews = filter(customViews || [], customView => !includes(disabledCustomViews, customView.id));

    return linksToShow.concat(
      enabledCustomViews.map(customView => {
        const isExternalLink = customView.viewType === VIEW_TYPES.externalLink;
        const icon = getIcon(customView.viewType);
        const pathname = isExternalLink ? customView.content : `${partnerPart}/Report/${customView.id}`;

        return {
          id: customView.id,
          title: customView.viewName,
          icon,
          to: { pathname, query: emptyQuery },
          external: isExternalLink,
        };
      })
    );
  }
);

export const getPortfolioImage = (portfolioImages, partnerNumber) => {
  if (portfolioImages && partnerNumber) {
    const images = portfolioImages[partnerNumber];
    const portfolioImage = findLast(images, image => image.type === 'partner');
    if (portfolioImage && portfolioImage.path) {
      if (portfolioImage.variants && includes(portfolioImage.variants, variants.HEADER)) {
        return getHeaderImageUrl(portfolioImage.path);
      }
      return portfolioImage.path;
    }
  }
  return '';
};

export const findPartnerNumberForFL = (functionalLocation, profile, returnAll) => {
  if (!functionalLocation || !profile) {
    return null;
  }

  const profilePartnerNumbers = getPartnerNumbers(profile);
  const partnerNumbers = intersection(profilePartnerNumbers, functionalLocation.partnerNumberWithParents);

  if (returnAll) {
    return partnerNumbers;
  }
  return head(partnerNumbers);
};

export const getPartnerDivision = customer => {
  if (!customer?.country) {
    return undefined;
  }
  const divisionKey = Object.keys(Division).find(
    divisionCountry => divisionCountry.toLowerCase() === customer.country.toLowerCase()
  );
  return Division[divisionKey];
};

export const divisionName = (division, t) => {
  const key = [`division_${division}`];
  const translated = t(key);
  return translated !== key ? translated : division;
};
