import { Children, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import InputLabel from './InputLabel';

const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  ${props => (props.highlightError ? 'border-bottom: var(--border-thin) solid var(--error-color)};' : '')}

  ${props =>
    props.row &&
    css`
      flex-direction: row;
      flex-wrap: wrap;

      ${props.type !== 'color'
        ? css`
            & > div {
              margin-right: var(--size-lg);
            }
          `
        : ''}
    `};
`;

StyledGroup.displayName = 'StyledGroup';

const RadioButtonGroup = props => {
  const children = Children.map(props.children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        checked: props.value === child.props.value,
        name: props.name,
        onChange: props.onChange,
        type: props.type,
        required: props.required,
        id: props.id,
        errorText: props.errorText,
        disabled: child.props.disabled !== undefined ? child.props.disabled : props.disabled,
        inactive: !!(props.value && props.value !== child.props.value),
      });
    }
  });

  return (
    <>
      {props.label && <InputLabel text={props.label} required={props.required} />}
      <StyledGroup
        id={`group-${props.id}`}
        type={props.type}
        row={props.row}
        highlightError={props.highlightError}
        data-group={props['data-group']}
      >
        {children}
      </StyledGroup>
    </>
  );
};

RadioButtonGroup.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['default', 'icon', 'color']),
  row: PropTypes.bool,
  children: PropTypes.node,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  errorText: PropTypes.string,
  label: PropTypes.string,
  highlightError: PropTypes.bool,
  'data-group': PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  type: 'default',
  row: false,
};

export default RadioButtonGroup;
