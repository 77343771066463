import styled from 'styled-components';

export const PanelContainer = styled.div`
  width: 100%;
  display: flex;
  gap: var(--font-size-sm);

  @media (max-width: ${props => props.theme.breakpoints.landscape}px) {
    flex-wrap: wrap;
  }

  @media print {
    flex-direction: column;
  }
`;

export const InfoContainer = styled.div`
  flex: 1 1.5 40%;
  max-width: 330px;

  @media (max-width: ${props => props.theme.breakpoints.landscape}px) {
    max-width: 100%;
  }
`;

export const DescriptionContainer = styled.div`
  flex: 2 0.75 60%;
  display: flex;
  flex-direction: column;
  gap: var(--font-size-sm);
`;

export const SidePanelContainer = styled.div`
  grid-area: sidepanel;
  margin-top: 46px;
  justify-self: end;

  width: 350px;
  min-width: 350px;
  height: fit-content;
  background-color: var(--grey-08);
  border: 1px solid var(--grey-20);

  @media (max-width: ${props => props.theme.breakpoints.desktop}px) {
    margin-top: 0;
    width: 320px;
    min-width: 320px;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}px), print {
    margin-top: 0;
    width: 100%;
  }
`;
