import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tag from './Tag';

const HideOnMobile = styled.span`
  display: none;
  ${props => props.theme.media.landscape`
    display: inline;
  `}
`;

const HideOnDesktop = styled.span`
  display: inline;
  ${props => props.theme.media.landscape`
    display: none;
  `}
`;

const StatusTag = ({ text, ...props }) => {
  return (
    <Tag {...props} title={text}>
      {typeof text === 'string' && (
        <>
          <HideOnMobile>{text?.slice(0, 4)}</HideOnMobile>
          <HideOnDesktop>{text?.slice(0, 1)}</HideOnDesktop>
        </>
      )}
    </Tag>
  );
};

StatusTag.propTypes = {
  text: PropTypes.string,
};

export default StatusTag;
