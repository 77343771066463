import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import styled from 'styled-components';

const Relative = styled.div`
  position: relative;
  height: var(--input-height);
`;
Relative.displayName = 'Relative';

const Arrow = styled.div`
  position: absolute;
  top: calc(50% - 2px);
  right: 0.875rem;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: ${props => props.theme.input.font.color.default};
  pointer-events: none;
  display: block;
`;
Arrow.displayName = 'Arrow';

const StyledSelect = styled.select`
  position: relative;
  width: 100%;
  height: var(--input-height);
  min-width: 5em;
  appearance: none;
  outline: none;
  &::-ms-expand {
    display: none;
  }
  background-color: ${props => props.theme.input.background.default};
  padding: ${props => props.theme.input.padding};
  border: ${props => props.theme.input.border.static};
  border-radius: 0;
  box-shadow: ${props => props.theme.input.shadow.static};
  transition: ${props => props.theme.input.transition};

  font-size: ${props => props.theme.input.font.mobileSize};
  font-family: ${props => props.theme.input.font.family};
  line-height: 1.25;
  color: ${props => props.theme.input.font.color.default};

  &:hover,
  &:focus {
    box-shadow: ${props => props.theme.input.shadow.active};
    border: ${props => props.theme.input.border.active};
  }

  &:focus {
    ${Arrow} {
      border-top-color: transparent;
      border-bottom-color: ${props => props.theme.input.font.color.default};
    }
  }

  ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.input.font.size};
    `}
`;
StyledSelect.displayName = 'StyledSelect';

const StyledOption = styled.option`
  font-size: ${props => props.theme.input.font.mobileSize};
  font-family: ${props => props.theme.input.font.family};
  line-height: ${props => props.theme.input.lineHeight.default};
  color: ${props => props.theme.input.font.color.default};
  padding: ${props => props.theme.input.padding};

  ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.input.font.size};
    `}
`;
StyledOption.displayName = 'StyledOption';

export const InputDropdown = ({ id, model, property, options, required, disabled, onChange }) => {
  const value = get(model, property, {});
  const selected = !isNil(value)
    ? find(options, ['value', isObject(value) ? Object.keys(value)[0] : value]) || value
    : '';

  return (
    <Relative>
      <StyledSelect
        name={id}
        value={selected.value}
        required={required}
        disabled={disabled}
        onChange={event => onChange(property, event.target.value)}
      >
        {options.map(item => (
          <StyledOption key={item.value} value={item.value}>
            {item.label}
          </StyledOption>
        ))}
      </StyledSelect>
      <Arrow />
    </Relative>
  );
};
InputDropdown.displayName = 'InputDropdown';

InputDropdown.defaultProps = {
  required: false,
  disabled: false,
};

InputDropdown.propTypes = {
  model: PropTypes.object,
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.any,
    })
  ).isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default InputDropdown;
