import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import RescuePlanService from 'services/rescuePlan';
import styled from 'styled-components';

import PrimaryButton from 'components/Button/PrimaryButton/PrimaryButton';
import PrintCards from './PrintCards';
import InfoBox from 'components/InfoBox/InfoBox';

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: var(--size-md);
`;

export const RescuePlanPrintFiles = ({ t, planId, planLanguage }) => {
  const [pdfs, setPDFs] = useState([]);
  const [error, setError] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const fetchPDFs = async (id, language) => {
    try {
      const files = await RescuePlanService.printFilesStatus(id, language);
      setPDFs(files.documents);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchPDFs(planId, planLanguage);
  }, [planId, planLanguage]);

  return (
    <>
      <InfoBox>{t('On this page, you can find the printable documents for this rescue plan in PDF format.')}</InfoBox>
      <PrintCards pdfs={pdfs} planLanguage={planLanguage} showAll={showAll} />
      {!showAll ? (
        <StyledPrimaryButton reveal large onClick={() => setShowAll(!showAll)}>
          {t('Show all languages')}
        </StyledPrimaryButton>
      ) : null}
    </>
  );
};

RescuePlanPrintFiles.propTypes = {
  t: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  planLanguage: PropTypes.string.isRequired,
};

export default translations(RescuePlanPrintFiles);
