import { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import sum from 'lodash/sum';

import translations from 'decorators/Translations/translations';
import connector from './connectEnergyChart';
import BarChart from 'components/Charts/BarChart';
import withQuery from 'decorators/Query/withQuery';
import Section from 'components/Section/Section';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import BreakdownTotals from 'components/BreakdownTotals/BreakdownTotals';
import { formatValue } from 'utils/Data/values';
import { getMonthsShort } from 'utils/Date/dateFormatter';

const StylesSection = styled(Section)`
  box-shadow: none;
`;
const unitDividerMap = {
  MWh: 1000,
  '㎥': 1,
};

const sensorTypeUnitMap = {
  electricity_main: 'MWh',
  heating_main: 'MWh',
  water_consumption: '㎥',
  total: 'MWh',
};

const sensorTypeIconMap = {
  electricity_main: 'electricity.consumption',
  heating_main: 'heating.consumption',
  water_consumption: 'water.consumption',
  total: 'energy.consumption',
};

export class EnergyChart extends Component {
  componentDidMount() {
    const { functionalLocations, loadEnergyChartValues } = this.props;
    loadEnergyChartValues({ functionalLocations });
  }

  render() {
    const { t, theme, loading, energyChartValues } = this.props;
    const colors = [...theme.charts.colorsEnergy].reverse();
    const series = [];
    const categories = [];

    const sensorTypeHeadingMap = {
      electricity_main: t('Electricity'),
      heating_main: t('Heating'),
      water_consumption: t('Water'),
      total: t('Total'),
    };
    const tabs = loading
      ? [
          {
            heading: t('Loading data'),
          },
        ]
      : Object.keys(energyChartValues)
          .filter(key => Boolean(sensorTypeHeadingMap[key]))
          .map(key => ({
            heading: sensorTypeHeadingMap[key],
            type: key,
          }))
          .sort(tab => (tab.type === 'water_consumption' ? 0 : -1));
    const options = tabs.map((tab, index) => ({ value: index, label: tab.heading }));
    const handleTabChange = (property, value) => this.props.setQuery({ breakdown: tabs[value].type });
    const { breakdown: type = 'total' } = this.props.query;
    const selectedTabIndex = tabs.findIndex(tab => tab.type === type);
    const selectedTab = tabs[selectedTabIndex];
    const unit = sensorTypeUnitMap[type];
    let total = null;
    let totalUnit = unit;
    if (energyChartValues[type]) {
      Object.keys(energyChartValues[type]).forEach((year, index) => {
        series.push({
          name: year,
          type: 'column',
          color: colors[index],
          data: energyChartValues[type][year].map(item => item.value / unitDividerMap[unit]),
          _unit: unit,
          yAxis: 0,
        });

        const { value, unit: formattedUnit } = formatValue(
          sum(energyChartValues[type][year].map(item => item.value / unitDividerMap[unit])),
          unit
        );
        categories.push({
          categoryId: year,
          total: Number(value),
          categoryName: year,
          unit: formattedUnit,
        });
      });
      const { value, unit: formattedTotalUnit } = formatValue(
        Object.keys(energyChartValues[type])
          .flatMap(year => energyChartValues[type][year])
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
          .slice(0, 12)
          .map(item => item.value / unitDividerMap[unit])
          .reduce((value, total) => value + total, 0),
        unit
      );
      total = value;
      totalUnit = formattedTotalUnit;
    }
    return (
      <StylesSection>
        <SectionHeader>
          <SectionTabSelector
            options={options}
            model={{ tabSelected: selectedTabIndex }}
            property="tabSelected"
            onTabChange={handleTabChange}
          />
        </SectionHeader>
        <Columns>
          <Column
            columnWidth={{
              desktop: 9,
              default: 12,
            }}
          >
            <BarChart
              type="column"
              categories={getMonthsShort()}
              series={series}
              t={t}
              valueDecimals={0}
              pointWidth={10}
              legendAlign="left"
              labelStyle={{
                color: this.props.theme.colors.rockBlue,
                fontWeight: this.props.theme.font.weight.bold,
                fontFamily: this.props.theme.font.family.cairo,
                letterSpacing: '1px',
                textTransform: 'uppercase',
              }}
              loading={loading}
              noZoom={true}
              yTitle={sensorTypeUnitMap[type]}
            />
          </Column>
          <Column
            columnWidth={{
              desktop: 3,
              default: 12,
            }}
          >
            {selectedTab && (
              <BreakdownTotals
                header={selectedTab?.heading}
                label={t('Last 12 months')}
                icon={sensorTypeIconMap[type]}
                value={total}
                unit={totalUnit}
                categories={categories}
              ></BreakdownTotals>
            )}
          </Column>
        </Columns>
      </StylesSection>
    );
  }
}

EnergyChart.defaultProps = {
  functionalLocations: [],
};

EnergyChart.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  functionalLocations: PropTypes.array,
  theme: PropTypes.object,
  setQuery: PropTypes.func,
  loadEnergyChartValues: PropTypes.func,
  query: PropTypes.object,
  energyChartValues: PropTypes.object,
};
export default withQuery(withTheme(connector(translations(EnergyChart))));
