import PropTypes from 'prop-types';
import * as yup from 'yup';

import InputForm from 'components/Form/InputForm';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputText from 'components/Form/InputText';
import InputBooleanCheckbox from 'components/Form/InputBooleanCheckbox';
import SubformComponent from './SubformComponent';
import { getPhoneNumberPlaceholder } from 'containers/Application/ServiceModule/ServiceModuleForm/utils/options';

export const createSchema = t =>
  yup.object().shape({
    requestedByPerson: yup.string().nullable().label(t('Name')).required(),
    requestedByPhoneNumber: yup
      .string()
      .nullable()
      .label(t('Phone number'))
      .matches(/^[+][0-9 ]*$/, `${t('Phone number')}: ${t('use country code')}`)
      .required(),
    requestedByEmail: yup.string().nullable().required().label(t('Email')).trim().email(),
    sendNotificationsToRequester: yup.boolean().nullable().label(t('Send email on status changes')),
  });

const ContactForm = ({ t, model, onFormChange, id, division, validationErrors }) => {
  return (
    <InputForm
      onPropertyChange={onFormChange}
      model={model}
      FormComponent={SubformComponent}
      validationErrors={validationErrors}
    >
      <InputRow dense fullRow>
        <InputLabel text={t('Name')} required />
        <InputText
          property="requestedByPerson"
          placeholder={t('Name')}
          autoComplete="section-contact name"
          name="name"
          required
        />
      </InputRow>
      <div />
      <InputRow dense>
        <InputLabel text={t('Phone number')} extraText={t('use country code')} required />
        <InputText
          property="requestedByPhoneNumber"
          placeholder={getPhoneNumberPlaceholder(division)}
          autoComplete="section-contact tel"
          name="tel"
          type="tel"
          required
          inputProps={{ pattern: '^[+][0-9 ]*$' }}
        />
      </InputRow>
      <InputRow dense>
        <InputLabel text={t('Email')} required />
        <InputText
          property="requestedByEmail"
          placeholder={t('Email address')}
          autoComplete="section-contact email"
          name="email"
          required
        />
      </InputRow>
      <InputRow fullRow>
        <InputBooleanCheckbox
          id={`${id}_sendNotificationsToRequesterCheckbox`}
          property="sendNotificationsToRequester"
          label={t('Send email on status changes')}
        />
      </InputRow>
    </InputForm>
  );
};

ContactForm.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  division: PropTypes.string.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default ContactForm;
