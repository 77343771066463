import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ListItem from './ListItem';

const DraggableList = ({
  id,
  items,
  setItems,
  ListItemComponent = ListItem,
  renderClone,
  hasDraggableInItemContent,
  LockedItemComponent,
  lockPredicate = item => item.locked,
}) => {
  const lockedItems = items.filter(lockPredicate);
  const draggableItems = items.filter(item => !lockPredicate(item));

  const onDragEnd = useCallback(
    result => {
      if (result.destination && result.destination.index !== result.source.index) {
        const newDraggableItems = [...draggableItems];
        const [dragged] = newDraggableItems.splice(result.source.index, 1);
        newDraggableItems.splice(result.destination.index, 0, dragged);
        const newItems = [...lockedItems, ...newDraggableItems];
        setItems(newItems);
      }
    },
    [lockedItems, draggableItems, setItems]
  );

  return (
    <>
      {LockedItemComponent && lockedItems.map(item => <LockedItemComponent key={item.id} item={item} />)}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={id} renderClone={renderClone}>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {draggableItems.map((item, index) =>
                hasDraggableInItemContent ? item.content : <ListItemComponent item={item} index={index} key={item.id} />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

DraggableList.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  ListItemComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  // Use renderClone when DraggableList is used inside a modal
  renderClone: PropTypes.func,
  // item.content includes Draggable
  hasDraggableInItemContent: PropTypes.bool,
  LockedItemComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  lockPredicate: PropTypes.func,
};

export default DraggableList;
