import { memo, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';

const COMPONENT_HEIGHT = 50;
const RAIL_HEIGHT = 10;
const RAIL_TOP = 20;
const RAIL_BORDER_RADIUS = 3;
const HANDLE_SIZE = 18;
const TICK_HEIGHT = 4;

const rootStyle = {
  position: 'relative',
  width: '100%',
  height: COMPONENT_HEIGHT,
};

const RailImpl = styled.div`
  position: absolute;
  top: ${RAIL_TOP}px;
  width: 100%;
  height: ${RAIL_HEIGHT}px;
  border-radius: ${RAIL_BORDER_RADIUS}px;
  background-color: ${props => props.theme.colors.lightGray};
  cursor: pointer;
`;

const TrackImpl = styled.div`
  position: absolute;
  top: ${RAIL_TOP}px;
  left: ${props => props.source.percent}%;
  width: ${props => props.target.percent - props.source.percent}%;
  height: ${RAIL_HEIGHT}px;
  background-color: ${props => props.theme.colors.cerulean};
  border-radius: ${RAIL_BORDER_RADIUS}px;
  cursor: pointer;
`;

const HandleImpl = styled.div`
  position: absolute;
  left: ${props => props.percent}%;
  top: ${RAIL_TOP}px;
  transform: translate(-50%, calc(${RAIL_HEIGHT / 2}px - 50%));
  width: ${HANDLE_SIZE}px;
  height: ${HANDLE_SIZE}px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.alabaster};
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.default};
`;

const HandleLabel = styled.div`
  margin-top: calc(-1 * var(--size-md));
  text-align: center;
  font-size: ${props => props.theme.font.size.xxs};
  pointer-events: none;
`;

const TickImpl = styled.div`
  position: absolute;
  top: ${RAIL_TOP + RAIL_HEIGHT}px;
  left: ${props => props.percent}%;
  width: ${props => (props.isFirst || props.isLast ? '0' : '1px')};
  height: ${TICK_HEIGHT}px;
  background-color: ${props => props.theme.colors.darkGray};
`;

const TickLabel = styled.div`
  position: absolute;
  top: ${TICK_HEIGHT + 2}px;
  left: ${props => props.percent}%;
  transform: ${props => (props.isFirst && 'none') || (props.isLast && 'translateX(-100%)') || 'translateX(-50%)'};
  font-size: ${props => props.theme.font.size.xxxs};
`;

const SliderComponent = props => {
  const { onChange, property, values, domain, step, mode, showValueLabels, showTicks, showTickLabels, tickCount } =
    props;
  const hasOnChange = typeof onChange === 'function';

  const throttledOnChange = useCallback(
    throttle(
      newValues => {
        if (isEqual(newValues, values)) {
          return onChange(property, null);
        }
        onChange(property, newValues);
      },
      200,
      { trailing: false }
    ),
    [onChange]
  );

  return (
    <Slider
      rootStyle={rootStyle}
      domain={domain}
      values={values}
      step={step}
      mode={mode}
      onChange={hasOnChange ? throttledOnChange : undefined}
    >
      <Rail>{({ getRailProps }) => <RailImpl {...getRailProps()} />}</Rail>

      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <Fragment>
            {tracks.map(({ id, source, target }) => (
              <TrackImpl key={id} source={source} target={target} {...getTrackProps()} />
            ))}
          </Fragment>
        )}
      </Tracks>

      <Handles>
        {({ handles, getHandleProps }) => (
          <Fragment>
            {handles.map(handle => (
              <HandleImpl key={handle.id} {...handle} {...getHandleProps(handle.id)}>
                {showValueLabels && <HandleLabel>{handle.value}</HandleLabel>}
              </HandleImpl>
            ))}
          </Fragment>
        )}
      </Handles>

      {showTicks && (
        <Ticks count={tickCount}>
          {({ ticks }) => (
            <Fragment>
              {ticks.map((tick, index) => (
                <TickImpl key={tick.id} {...tick} count={ticks.length} isFirst={index === 0}>
                  {showTickLabels && (
                    <TickLabel count={ticks.length} isFirst={index === 0}>
                      {tick.value}
                    </TickLabel>
                  )}
                </TickImpl>
              ))}
            </Fragment>
          )}
        </Ticks>
      )}
    </Slider>
  );
};

SliderComponent.defaultProps = {
  mode: 2,
  showValueLabels: true,
  showTickLabels: true,
  showTicks: true,
};

SliderComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  mode: PropTypes.oneOf([1, 2, 3]),
  domain: PropTypes.arrayOf(PropTypes.number),
  values: PropTypes.arrayOf(PropTypes.number),
  tickCount: PropTypes.number,
  showValueLabels: PropTypes.bool,
  showTickLabels: PropTypes.bool,
  showTicks: PropTypes.bool,
  property: PropTypes.string.isRequired,
};

export default memo(SliderComponent);
