import { useCallback, useContext, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import ProfileService from 'services/profile';
import LocalizationContext from 'contexts/LocalizationContext';
import useQuery from '../Query/useQuery';
import { useTranslations } from './translations';
import cookies from 'utils/Cookies/Cookies';

const useUnauthenticatedTranslations = language => {
  const [loadingTranslations, setLoadingTranslations] = useState(true);
  const { translations, updateTranslations } = useContext(LocalizationContext);
  const { query, setQuery } = useQuery();
  const [t] = useTranslations();
  const selectedLanguage = language || query.lang || cookies.getItem('language') || 'en';
  const setSelectedLanguage = useCallback(lang => setQuery({ lang }), [setQuery]);

  useEffect(() => {
    setLoadingTranslations(true);
    ProfileService.unauthenticatedTranslations(selectedLanguage)
      .then(translations => updateTranslations({ language: selectedLanguage, translations }))
      .finally(() => setLoadingTranslations(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  return {
    t,
    loadingTranslations,
    selectedLanguage,
    setSelectedLanguage,
    translationsReady: !isEmpty(translations) && loadingTranslations === false,
  };
};

export default useUnauthenticatedTranslations;
