import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Row, Cell } from './FlexTableBody';
import Svg from 'components/Svg/Svg';
import { legacyColorApproximation } from 'styles/UIv3/legacyColorApproximation';

const Icon = styled(Svg)`
  font-size: 10px;
  vertical-align: middle;
  margin-left: 5px;
`;

const StyledTableHead = styled.div`
  display: ${props => (props.hideOnMobile ? 'none' : 'flex')};
`;
StyledTableHead.displayName = 'StyledTableHead';

const HeaderRow = styled(Row)`
  border: 0;
  ${props =>
    props.stripes &&
    !props.backgroundColor &&
    css`
      background-color: var(--table-alternating-row-bg);
    `}
  ${props =>
    props.backgroundColor &&
    css`
      background-color: ${legacyColorApproximation[props.backgroundColor]};
    `}
`;
HeaderRow.displayName = 'HeaderRow';

export const tableHeaderCellStyle = css`
  background-color: var(--table-header-bg);
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => (props.selected ? props.theme.font.weight.bold : props.theme.font.weight.semibold)};
  line-height: ${props => props.theme.font.lineHeight.lg};
  color: var(--table-header-fg);
  fill: var(--table-header-fg);

  &:first-child {
    border-radius: 2px 0 0 2px;
  }
  &:last-child {
    border-radius: 0 2px 2px 0;
  }
`;

export const HeaderCell = styled(Cell)`
  padding-top: var(--size-xs);
  white-space: nowrap;
  vertical-align: bottom;
  display: ${props => props.hideOnMobile && 'none'};
  width: ${props => (props.cellWidthMobile ? props.cellWidthMobile : 'auto')};
  overflow: hidden;
  text-overflow: ellipsis;

  ${tableHeaderCellStyle}

  ${props =>
    props.sortable &&
    css`
      cursor: pointer;
    `}

  ${props => props.theme.media.portrait`
        width: ${props => (props.cellWidth ? props.cellWidth : props.cellWidthMobile || 'auto')};
    `}

    ${props => props.theme.media.landscape`
        display: ${props => (props.hideOnDesktop ? 'none' : 'block')};
    `}

    @media print {
    display: ${props => (props.hideOnPrint ? 'none' : 'block')};
  }
`;
HeaderCell.displayName = 'HeaderCell';

const FlexTableHead = ({ headerGroups = [] }) => (
  <StyledTableHead>
    {headerGroups.map(headerGroup => (
      <HeaderRow {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <HeaderCell
            title="" // Override this in the column definition's `getHeaderProps` if necessary
            {...column.getHeaderProps(column.getSortByToggleProps())}
            selected={column.isSorted}
            style={column.style}
            hideOnMobile={column.hideOnMobile}
          >
            {column.render('Header')}
            {column.canSort && <Icon name={`caret-${column.isSortedDesc ? 'down' : 'up'}`} />}
          </HeaderCell>
        ))}
      </HeaderRow>
    ))}
  </StyledTableHead>
);

FlexTableHead.propTypes = {
  headerGroups: PropTypes.array,
};

export default FlexTableHead;
