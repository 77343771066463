import styled from 'styled-components';
import PropTypes from 'prop-types';

const Image = styled.div`
  width: calc(25% - var(--size-md));
  flex-shrink: 0;
  height: 100%;
  background-image: ${props => 'url(' + props.image + ')'};
  background-position: center center;
  background-size: cover;
  display: none;

  ${props => props.theme.media.portrait`
        display: block;
    `}

  @media print {
    display: block;
  }
`;

const HeroImage = ({ image }) => {
  if (!image) {
    return null;
  }

  return <Image image={image} />;
};

HeroImage.propTypes = {
  image: PropTypes.string,
};

export default HeroImage;
