import { useEffect } from 'react';

const useNestedRows = options => {
  const { tableInstance, expandRows, expandAllRows } = options;

  useEffect(() => {
    if (expandAllRows) {
      tableInstance.toggleAllRowsExpanded(true);
    }
  }, [expandAllRows]);

  useEffect(() => {
    if (expandRows?.length > 0) {
      // only visible row can be expanded, so this array needs to be ordered by depth
      expandRows.forEach(rowId => {
        tableInstance.toggleRowExpanded(rowId, true);
      });
    }
  }, [expandRows]);
};

export default useNestedRows;
