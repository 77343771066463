import styled from 'styled-components';
import PropTypes from 'prop-types';

import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import Pager from 'components/Pager/Pager';
import PrimaryButton from 'components/Button/PrimaryButton';

const Container = styled.div`
  position: relative;
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: var(--size-md) 0;

  ${props => props.theme.media.landscape`
        flex-direction: row;
        margin: var(--size-lg) 0;
    `}
`;

const CountContainer = styled.div`
  position: absolute;
  top: var(--size-lg);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;

  ${props => props.theme.media.landscape`
        top: 50%;
        left: var(--size-md);
        width: auto;
    `}
`;

const PagerContainer = styled.div`
  order: -1;

  ${props => props.theme.media.landscape`
        order: 0;
    `}
`;

const Count = styled.div`
  font-size: ${props => props.theme.fontSize.xs};
  color: ${props => props.theme.colors.rockBlue};
  ${props => (props.mobileMargin ? 'margin: var(--size-md) 0 var(--size-lg);' : '')}

  ${props => props.theme.media.landscape`
    margin: 0;
  `}
`;

const Number = styled.span`
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

const StyledFilterDropdown = styled(FilterDropdown)`
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: var(--size-md);
  padding-top: var(--size-xxl);

  ${props => props.theme.media.landscape`
        justify-content: center;
        margin-bottom: 0;
        padding-top: 0;
        margin-top: var(--size-md);
    `}
`;

const getMinMaxRows = (page, visibleRows, total) => {
  let maxRow = page * visibleRows;
  const minRow = maxRow - visibleRows + 1;
  maxRow = maxRow > total ? total : maxRow;
  return {
    minRow,
    maxRow,
  };
};

const SortableTableControls = ({
  totalRows,
  rowsPerFold,
  initialRows,
  visibleRows,
  setVisibleRows,
  loading,
  t,
  paginate,
  onPageChange,
  showAll,
  toggleShowAll,
  page,
  maxRowsToRender,
}) => {
  if (paginate) {
    const { minRow, maxRow } = getMinMaxRows(page, visibleRows || totalRows, totalRows);
    const currentRows = showAll ? Math.min(totalRows, maxRowsToRender) : `${minRow}-${maxRow}`;

    return (
      <PaginationContainer>
        <Count mobileMargin>
          {`${t('Showing')}: `}
          <Number>{currentRows}</Number> / <Number>{totalRows}</Number>
        </Count>
        {!showAll && (
          <PagerContainer>
            <Pager totalResults={totalRows} limit={visibleRows || totalRows} center onChange={onPageChange} />
          </PagerContainer>
        )}
        {showAll && totalRows > maxRowsToRender && (
          <PagerContainer>
            {t('Restricting visible rows to {0} because of a very large data set.', maxRowsToRender)}
          </PagerContainer>
        )}
        <PrimaryButton reveal invertColor large onClick={toggleShowAll}>
          {showAll ? t('Show less') : t('Show all')}
        </PrimaryButton>
      </PaginationContainer>
    );
  }

  const options = [{ text: initialRows.toString(), value: initialRows }];
  for (let i = rowsPerFold; i < totalRows; i = i + rowsPerFold) {
    if (i > initialRows) {
      options.push({ text: i.toString(), value: i });
    }
  }
  return (
    <Container>
      <CountContainer>
        <Count>
          {`${t('Showing')}: `}
          <Number>{`1-${Math.min(visibleRows, maxRowsToRender) || totalRows}`}</Number> / <Number>{totalRows}</Number>
          {}
        </Count>
      </CountContainer>
      <StyledFilterDropdown
        options={options}
        selectedFilter={visibleRows}
        handleChange={setVisibleRows}
        loading={loading}
        showAllText={t('All')}
        noFilterLast
        label={t('Show')}
        labelPosition="left"
      />
    </Container>
  );
};

SortableTableControls.propTypes = {
  t: PropTypes.func.isRequired,
  totalRows: PropTypes.number.isRequired,
  rowsPerFold: PropTypes.number.isRequired,
  initialRows: PropTypes.number.isRequired,
  visibleRows: PropTypes.number,
  setVisibleRows: PropTypes.func,
  loading: PropTypes.bool,
  paginate: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired,
  showAll: PropTypes.bool.isRequired,
  toggleShowAll: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  maxRowsToRender: PropTypes.number,
};

export default SortableTableControls;
