import PropTypes from 'prop-types';

import Dialog from './Dialog';
import DialogFrame from './DialogFrame';
import DialogBody from './DialogBody';
import DialogActions from './DialogActions';

const DialogModal = ({
  children,
  footer,
  actions,
  title,
  small,
  large,
  extraLarge,
  disableFullScreen,
  disableTitlePanel,
  statusColor,
  bodyComponent: BodyComponent,
  allowOverflow,
  noPadding,
  ...otherProps
}) => {
  if (!footer && actions) {
    footer = <DialogActions {...actions} />;
  }
  return (
    <Dialog {...otherProps}>
      <DialogFrame
        title={title}
        footer={footer}
        onClose={otherProps.onOverlayClick}
        disableFullScreen={disableFullScreen}
        disableTitlePanel={disableTitlePanel}
        allowOverflow={allowOverflow}
      >
        {fullScreen => (
          <BodyComponent
            small={small}
            large={large}
            extraLarge={extraLarge}
            fullScreen={fullScreen}
            statusColor={statusColor}
            noPadding={noPadding}
          >
            {typeof children === 'function' ? children(fullScreen) : children}
          </BodyComponent>
        )}
      </DialogFrame>
    </Dialog>
  );
};

DialogModal.propTypes = {
  bodyComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType]),
  noPadding: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  footer: PropTypes.node,
  actions: PropTypes.object,
  title: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  extraLarge: PropTypes.bool,
  disableFullScreen: PropTypes.bool,
  disableTitlePanel: PropTypes.bool,
  statusColor: PropTypes.string,
  allowOverflow: PropTypes.bool,
};

DialogModal.defaultProps = {
  bodyComponent: DialogBody,
};

export default DialogModal;
