import { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import parseISO from 'date-fns/parseISO';
import { format } from 'utils/Date/dateFormatter';

import EventsRow from 'components/BuildingEvents/EventsRow/EventsRow';
import EventsCell from 'components/BuildingEvents/EventsRow/EventsCell';
import Svg from 'components/Svg/Svg';
import { getFileIconByExtension, getHumanreadableSize, shouldShowInlineLink } from 'utils/Data/documents';

const Cell = styled(EventsCell)`
  position: ${props => props.relative && 'relative'};
  padding: ${props => (props.minimalUI ? 'var(--size-xs)' : 'var(--size-sm)')} var(--size-xs);
  text-align: ${props => props.relative && 'center'};

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
      &:hover ${Name} {
        color: ${props.theme.colors.cerulean};
      }
      &:hover ${FileIcon} {
        fill: ${props.theme.colors.cerulean};
      }
    `}

  ${props => props.theme.media.landscape`
    padding: var(--size-sm);

    &:first-child {
      ${props => (props.minimalUI ? 'padding-left: var(--size-sm);' : '')}
    }
  `}
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: ${props => !props.minimalUI && '40px'};
`;

const FileIcon = styled(Svg)`
  float: left;
  font-size: ${props => props.theme.font.size.xxl};
  min-width: ${props => props.theme.font.size.xxl};
  margin: 0 var(--size-xs) 0 0;
  fill: ${props => props.theme.colors.midnight};

  ${props => props.theme.media.landscape`
    min-width: ${props => props.theme.font.size.xxl};
  `}
`;

const Name = styled.span`
  color: ${props => props.theme.colors.black};
  font-weight: ${props => props.theme.fontWeight.normal};
  line-height: ${props => props.theme.font.lineHeight.md};
  font-size: ${props => props.theme.font.size.xs};
  overflow-wrap: break-word;
`;

const GrayText = styled.span`
  color: ${props => props.theme.colors.darkGray};
  font-weight: ${props => props.theme.fontWeight.text};
  font-size: ${props => (props.minimalUI ? props.theme.font.size.xxs : props.theme.font.size.xs)};
  line-height: ${props => props.theme.font.lineHeight.md};
`;

export const CeruleanText = styled.span`
  color: ${props => props.theme.colors.cerulean};
  font-weight: ${props => props.theme.fontWeight.bold};
  white-space: nowrap;
`;

export const DownloadLink = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &:active {
    & + ${CeruleanText} {
      color: ${props => props.theme.colors.midnight};
    }
  }
`;

export const MinimalDocumentRow = props => <DocumentRow {...props} minimalUI />;

const DocumentRow = props => {
  const { rowData, t, onClick, minimalUI } = props;

  const download = e => {
    e.preventDefault();
    onClick(rowData);
  };
  const showInlineLink = shouldShowInlineLink(rowData) && typeof onClick === 'function';

  return (
    <EventsRow gray={minimalUI}>
      <Cell minimalUI={minimalUI} hideOnMobile onClick={showInlineLink ? () => onClick(rowData, true) : undefined}>
        <NameContainer minimalUI={minimalUI}>
          {!minimalUI && <FileIcon name={getFileIconByExtension(rowData)} />}
          <Name>{rowData.name}</Name>
        </NameContainer>
      </Cell>
      <Cell minimalUI={minimalUI} hideOnMobile>
        <GrayText minimalUI={minimalUI}>
          {format(parseISO(rowData.date.date), minimalUI ? 'dd.MM.yyyy, HH:mm' : 'do MMMM yyyy, HH:mm')}
        </GrayText>
      </Cell>
      {!minimalUI && (
        <Cell minimalUI={minimalUI} hideOnMobile>
          <GrayText>{getHumanreadableSize(rowData.size)}</GrayText>
        </Cell>
      )}
      <Cell minimalUI={minimalUI} hideOnMobile relative>
        {typeof onClick === 'function' && (
          <>
            <DownloadLink href="" onClick={download} />
            <CeruleanText>{t('Download')}</CeruleanText>
          </>
        )}
      </Cell>
      <Cell minimalUI={minimalUI} hideOnDesktop onClick={typeof onClick === 'function' ? download : undefined}>
        <NameContainer minimalUI={minimalUI}>
          {!minimalUI && <FileIcon name={getFileIconByExtension(rowData)} />}
          <Name>{rowData.name}</Name>
        </NameContainer>
        <GrayText minimalUI={minimalUI}>
          {format(parseISO(rowData.date.date), 'do MMMM yyyy, HH:mm')} ({getHumanreadableSize(rowData.size)})
        </GrayText>
      </Cell>
    </EventsRow>
  );
};

DocumentRow.propTypes = {
  t: PropTypes.func,
  onClick: PropTypes.func,
  rowData: PropTypes.object,
  minimalUI: PropTypes.bool,
};

export default memo(DocumentRow);
