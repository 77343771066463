import styled from 'styled-components';

const Arrow = styled.div`
  width: 0;
  height: 0;
  top: ${props => props.position === 'bottom' && '-10px'};
  bottom: ${props => props.position === 'top' && '-10px'};
  right: ${props => props.position === 'left' && '-10px'};
  left: ${props => props.position === 'right' && '-10px'};

  border-top: ${props =>
    props.position === 'top'
      ? `10px solid var(--white)`
      : props.position === 'bottom'
        ? 'none'
        : '10px solid transparent'};
  border-bottom: ${props =>
    props.position === 'bottom'
      ? `10px solid var(--white)`
      : props.position === 'top'
        ? 'none'
        : '10px solid transparent'};
  border-right: ${props =>
    props.position === 'right'
      ? `10px solid var(--white)`
      : props.position === 'left'
        ? 'none'
        : '10px solid transparent'};
  border-left: ${props =>
    props.position === 'left'
      ? `10px solid var(--white)`
      : props.position === 'right'
        ? 'none'
        : '10px solid transparent'};
`;
Arrow.displayName = 'Arrow';

export default Arrow;
