import styled from 'styled-components';
import { withErrorBoundary } from 'components/ErrorPage/ErrorBoundary';

export const Section = styled.div`
  display: block;
  position: relative;
  overflow: visible;
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  padding: ${props => (props.noVerticalPadding ? 0 : props.theme.section.verticalSpacing)}
    ${props => (!props.noPadding || props.mobilePadding ? 'var(--size-md)' : 0)};
  background-color: ${props => (props.transparent ? props.theme.colors.transparent : props.theme.colors.white)};
  width: 100%;

  ${props => props.theme.media.portrait`
    padding: ${props => (props.noVerticalPadding ? 0 : props.theme.section.verticalSpacing)} ${props =>
      props.noPadding ? 0 : props.theme.grid.gutter};
    margin: 0 auto 0;
    box-shadow: ${props => !props.transparent && props.theme.section.shadow};
    border-radius: ${props => props.theme.section.borderRadius};
  `}
`;
Section.displayName = 'Section';

export default withErrorBoundary(Section);
