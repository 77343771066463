import {
  getCarbonFootprintSensors,
  createBreakdownWasteAmountSeries,
  createBreakdownWasteAmountTotals,
  createBreakdownCarbonFootprintSeries,
  createBreakdownCarbonFootprintTotals,
  createBreakdownYearlySeries,
  createBreakdownYearlyTotals,
  createBreakdownRatesSeries,
  createBreakdownRateTotals,
} from 'containers/Application/Modules/RecyclingModule/RecyclingDataUtils';
import { TONNES_UNIT, PERCENTAGE_UNIT, CO2E_TONNES_UNIT } from 'constants/recycling';

export const getWasteAmountsData = ioTData =>
  ioTData.filter(entry => /monthlySum|quarterlySum|yearlySum/.test(entry.aggregation));
export const getCarbonFootprintData = ioTData => ioTData.filter(entry => entry.aggregation.includes('CO2e'));
export const getRecyclingRateData = ioTData => ioTData.filter(entry => entry.aggregation.includes('RecyclingRate'));
export const getRecyclingRateOpiData = ioTData =>
  ioTData.filter(entry => entry.aggregation === 'recyclingOpiRecyclingRate');
export const getRecoveryRateData = ioTData => ioTData.filter(entry => entry.aggregation.includes('RecoveryRate'));
export const getRecoveryRateOpiData = ioTData =>
  ioTData.filter(entry => entry.aggregation === 'recyclingOpiRecoveryRate');

export const getWasteAmountsTotal = ioTData => ioTData.find(entry => entry.aggregation.includes('WasteAmount'))?.value;
export const getRecyclableTotal = ioTData =>
  ioTData.find(entry => entry.aggregation.includes('RecyclableSum'))?.value || 0;
export const getCarbonFootprintTotal = ioTData => ioTData.find(entry => entry.aggregation.includes('CO2eSum'))?.value;

const wasteBreakdownTabs = {
  WASTE_AMOUNTS: {
    id: 'waste-amounts',
    label: 'Waste amounts',
    totalsIconName: 'waste-bin',
    filterSensors: wasteSensors => wasteSensors,
    createSeries: (ioTData, wasteSensors) =>
      createBreakdownWasteAmountSeries(getWasteAmountsData(ioTData), TONNES_UNIT, wasteSensors),
    createTotals: iotData =>
      createBreakdownWasteAmountTotals(getWasteAmountsTotal(iotData), getRecyclableTotal(iotData), TONNES_UNIT),
    createYearlySeries: (ioTData, years) =>
      createBreakdownYearlySeries(getWasteAmountsData(ioTData), years, TONNES_UNIT),
    createYearlyTotals: (ioTData, years) =>
      createBreakdownYearlyTotals(getWasteAmountsData(ioTData), years, TONNES_UNIT),
  },
  CARBON_FOOTPRINT: {
    id: 'carbon-footprint',
    label: 'Carbon footprint',
    totalsIconName: 'carbon-footprint',
    filterSensors: getCarbonFootprintSensors,
    createSeries: (ioTData, wasteSensors) =>
      createBreakdownCarbonFootprintSeries(getCarbonFootprintData(ioTData), CO2E_TONNES_UNIT, wasteSensors),
    createTotals: ioTData => createBreakdownCarbonFootprintTotals(getCarbonFootprintTotal(ioTData), CO2E_TONNES_UNIT),
    createYearlySeries: (ioTData, years) =>
      createBreakdownYearlySeries(getCarbonFootprintData(ioTData), years, CO2E_TONNES_UNIT),
    createYearlyTotals: (ioTData, years) =>
      createBreakdownYearlyTotals(getCarbonFootprintData(ioTData), years, CO2E_TONNES_UNIT),
  },
};

const ratesTabs = {
  RECYCLING_RATE: {
    id: 'recycling-rate',
    label: 'Recycling rate',
    totalsIconName: 'recycling-recovery',
    createRateSeries: (ioTData, years) =>
      createBreakdownRatesSeries(getRecyclingRateData(ioTData), years, PERCENTAGE_UNIT),
    createAmountSeries: (ioTData, years) =>
      createBreakdownYearlySeries(getWasteAmountsData(ioTData), years, TONNES_UNIT),
    createRateTotals: (ioTData, ioTOpiData) =>
      createBreakdownRateTotals(getRecyclingRateData(ioTData), getRecyclingRateOpiData(ioTOpiData), PERCENTAGE_UNIT),
  },
  RECOVERY_RATE: {
    id: 'recovery-rate',
    label: 'Recovery rate',
    totalsIconName: 'recycling-recovery',
    createRateSeries: (ioTData, years) =>
      createBreakdownRatesSeries(getRecoveryRateData(ioTData), years, PERCENTAGE_UNIT),
    createAmountSeries: (ioTData, years) =>
      createBreakdownYearlySeries(getWasteAmountsData(ioTData), years, TONNES_UNIT),
    createRateTotals: (ioTData, ioTOpiData) =>
      createBreakdownRateTotals(getRecoveryRateData(ioTData), getRecoveryRateOpiData(ioTOpiData), PERCENTAGE_UNIT),
  },
};

export default {
  wasteBreakdownTabs,
  ratesTabs,
};
