import mergeWith from 'lodash/mergeWith';
import fromPairs from 'lodash/fromPairs';
import entries from 'lodash/entries';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import memoizeOne from 'memoize-one';

import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { performanceColors, getPerformanceStatus } from 'utils/Data/performance';
import { performanceSourceLabels } from 'constants/performance';
import { loadPartnerOverview } from 'redux/modules/containers/partner-overview';
import themes from 'styles/themes';
import { getDivisionConfig } from 'redux/modules/config/config.selectors';

const getAirQualityPerformance = memoizeOne(kpi => {
  const values = get(kpi, 'data.moving7dAverage', {});
  return mapValues(
    values,
    value =>
      typeof value === 'number' && {
        value,
        formattedValue: value.toFixed(1),
        unit: '%',
        color: performanceColors({ theme: themes.customerPlatform })[getPerformanceStatus(value, true)],
        infotip: performanceSourceLabels.indoorAirQuality,
      }
  );
});

const getEnergyRatingPerformance = memoizeOne(energyRating =>
  mapValues(
    energyRating.data || {},
    value =>
      value.latest && {
        value: value.latest,
        formattedValue: value.latest.toFixed(1),
        unit: 'KWh',
        color: themes.customerPlatform.colors.rockBlue,
        infotip: performanceSourceLabels.energy,
      }
  )
);

const getEnergyConsumptionPerformance = memoizeOne((electricity, heating, cooling) => {
  if (
    !electricity ||
    electricity.loading ||
    electricity.error ||
    !heating ||
    heating.loading ||
    heating.error ||
    !cooling ||
    cooling.loading ||
    cooling.error
  ) {
    return {};
  }
  const sums = mergeWith(
    {},
    electricity.data.byFL,
    heating.data.byFL,
    cooling.data.byFL,
    (lhs, rhs) => (lhs || 0) + rhs
  );
  return mapValues(sums, value => ({
    value,
    formattedValue: (value / 1000).toFixed(0),
    unit: 'MWh',
    color: themes.customerPlatform.colors.rockBlue,
    infotip: performanceSourceLabels.energyConsumption,
  }));
});

const getAlarmPerformance = memoizeOne(performance => {
  if (!performance || !performance.data) {
    return {};
  }
  return fromPairs(
    entries(performance.data)
      .filter(([, value]) => !isNil(value) && !isNil(value.performance))
      .map(([fl, value]) => [
        fl,
        {
          value: value.performance,
          formattedValue: value.performance.toFixed(0),
          unit: '%',
          color: performanceColors({ theme: themes.customerPlatform })[getPerformanceStatus(value.performance)],
          infotip: performanceSourceLabels.alarms,
        },
      ])
  );
});

const getObservationPerformance = memoizeOne(performance => {
  if (!performance || !performance.data) {
    return {};
  }

  return fromPairs(
    entries(performance.data)
      .filter(([, value]) => !isNil(value))
      .map(([fl, value]) => [
        fl,
        {
          value,
          formattedValue: value.toFixed(0),
          unit: '%',
          color: performanceColors({ theme: themes.customerPlatform }).neutral,
          infotip: performanceSourceLabels.observations,
        },
      ])
  );
});

const getPerformanceValues = createSelector(
  state => state.profile.profile?.buildingPerformanceSources,
  state => state.values.airQuality.airQualityKPI,
  state => state.values.energyRating.energyRating,
  state => state.values.consumption.consumptionKPIs,
  (state, props) => state.alarm.performance[props.match.params.partnerNumber],
  (state, props) => state.notice.observationPerformance[props.match.params.partnerNumber],
  (
    buildingPerformanceSources,
    airQualityKPI,
    energyRating,
    consumptionKPIs,
    alarmPerformance,
    observationPerformance
  ) =>
    buildingPerformanceSources?.map(source => ({
      source,
      data: getPerformanceForSource({
        source,
        airQualityKPI,
        energyRating,
        consumptionKPIs,
        alarmPerformance,
        observationPerformance,
      }),
    }))
);

const getPerformanceForSource = ({
  source,
  airQualityKPI,
  energyRating,
  consumptionKPIs,
  alarmPerformance,
  observationPerformance,
}) => {
  switch (source) {
    case 'indoorAirQuality':
      return getAirQualityPerformance(airQualityKPI);
    case 'energy':
      return getEnergyRatingPerformance(energyRating);
    case 'energyConsumption':
      return getEnergyConsumptionPerformance(
        consumptionKPIs.electricity_main,
        consumptionKPIs.heating_main,
        consumptionKPIs.cooling_main
      );
    case 'alarms':
      return getAlarmPerformance(alarmPerformance);
    case 'observations':
      return getObservationPerformance(observationPerformance);
    default:
      return undefined;
  }
};

const getServiceRequestCount = (state, props) =>
  state.serviceRequests.counts.partnerNumber[props.match.params.partnerNumber];

const EMPTY_ARRAY = [];

const mapStateToProps = (state, props) => ({
  customViewsByCustomer: state.customView.customViewsByCustomer,
  loading: state.partnerOverview.loading,
  loadingImage: state.partnerImage.loading,
  error: state.partnerOverview.error,
  profile: state.profile.profile,
  features: state.profile.profile.features,
  featureTeasers: state.profile.profile.featureTeasers,
  defaultFeature: state.profile.profile.defaultFeature,
  customers: state.customer.customers,
  partnerImages: state.partnerImage.byPartner,
  topLevelFunctionalLocations: state.functionalLocations.topLevelFunctionalLocations,
  disabledCustomViews: state.profile.profile.disabledCustomViews || EMPTY_ARRAY,
  partnerMeta: state.partnerMeta.meta,
  performances: getPerformanceValues(state, props),
  divisionConfig: getDivisionConfig(state),
  serviceRequestCount: getServiceRequestCount(state, props),
});

const mapDispatchToProps = {
  loadPartnerOverview,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
