import SkeletonText from 'components/Skeletons/SkeletonText';
import ContactPhone from '../../icons/ContactPhone';
import ContactEmail from '../../icons/ContactEmail';
import * as Styled from './ContactDetails.styled';
import { TranslationFunction } from 'decorators/Translations/TranslationFunction';

export type ContactDetail = {
  title: string;
  name: string;
  phone: string;
  email: string;
};

interface Props {
  contacts: ContactDetail[];
  t: TranslationFunction;
  loading: boolean;
}

const Contact = (props: ContactDetail) => {
  const { title, name, phone, email } = props;

  return (
    <Styled.Card>
      <h4>{title}</h4>
      <address>
        <p>{name}</p>
        <ul>
          {phone && (
            <li>
              <ContactPhone />
              <span>{phone}</span>
            </li>
          )}
          {email && (
            <li>
              <ContactEmail />
              <a href={`mailto:${email}`}>{email}</a>
            </li>
          )}
        </ul>
      </address>
    </Styled.Card>
  );
};

const ContactDetails = (props: Props) => {
  const { contacts, loading } = props;

  if (loading === true) {
    return (
      <Styled.Container>
        <SkeletonText />
      </Styled.Container>
    );
  }

  return !contacts?.length ? null : (
    <Styled.Container>
      {contacts.map((contact, idx) => (
        <Contact key={idx} {...contact} />
      ))}
    </Styled.Container>
  );
};

export default ContactDetails;
