import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import ObservationsModal from './ObservationsModal';
import MonthSelector from './ObservationsMonthSelector';
import withQuery from 'decorators/Query/withQuery';
import RadioButtonGroup from '../Form/RadioButtonGroup';
import RadioButton from '../Form/RadioButton';
import { defaultFilterValues } from './utils';

import { DATE_FILTER_FIELD } from './utils';

const StyledSection = styled(Section)`
  h2 {
    font-size: ${props => props.theme.font.size.md};
  }
`;

const StyledSectionHeader = styled(SectionHeader)`
  margin-bottom: ${props => props.theme.layoutSpacing.md};
  padding-bottom: ${props => props.theme.layoutSpacing.md};
`;

export const Observations = ({ observations = [], t, query, setQuery, loading, partnerNumber, defaultColumns }) => {
  const filterValues = { ...defaultFilterValues, ...query };
  // Set initial year and dateFilterField if it is defined
  useEffect(
    () => setQuery({ year: filterValues.year, dateFilterField: filterValues.dateFilterField }, { useReplace: true }),
    [partnerNumber, query] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const handleMonthClick = ({ year, month }) => {
    setQuery({ observationsModalVisible: true, year, month });
  };
  const handleModalClose = () => {
    setQuery({ ...defaultFilterValues, ...query, selectedModalTab: undefined, observationsModalVisible: undefined });
  };
  const dateFilterField = filterValues.dateFilterField || DATE_FILTER_FIELD.CREATED;
  return (
    <StyledSection>
      <StyledSectionHeader title={t('Observations')}>
        {filterValues.dateFilterField && (
          <RadioButtonGroup
            row={true}
            name="date"
            value={filterValues.dateFilterField}
            onChange={(_a, dateFilterField) => setQuery({ dateFilterField })}
          >
            <RadioButton
              id="dateFilterField-CREATED"
              label={t('Created')}
              value={DATE_FILTER_FIELD.CREATED}
            ></RadioButton>
            <RadioButton
              id="dateFilterField-MODIFIED"
              label={t('Modified')}
              value={DATE_FILTER_FIELD.MODIFIED}
            ></RadioButton>
          </RadioButtonGroup>
        )}
      </StyledSectionHeader>
      <MonthSelector
        onMonthClick={handleMonthClick}
        items={observations}
        toggleActiveMonth={false}
        callMonthClickOnYearChange={false}
        dateField={dateFilterField}
        loading={loading}
      />
      {(query.observationsModalVisible || query.observationId) && (
        <ObservationsModal
          observations={observations}
          onClose={() => handleModalClose()}
          loading={loading}
          defaultColumns={defaultColumns}
        />
      )}
    </StyledSection>
  );
};

Observations.propTypes = {
  t: PropTypes.func.isRequired,
  observations: PropTypes.array.isRequired,
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.shape({
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    observationsModalVisible: PropTypes.oneOf(['true', 'false']),
    dateFilterField: PropTypes.oneOf(['date', 'modifiedOn']),
    selectedModalTab: PropTypes.string,
    observationId: PropTypes.string,
  }),
  loading: PropTypes.bool,
  partnerNumber: PropTypes.string,
  defaultColumns: PropTypes.array,
};

export default withQuery(translations(Observations));
