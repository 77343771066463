import { format, toDate } from 'date-fns';
import { lighten } from 'polished';
import clone from 'lodash/clone';
import sumBy from 'lodash/sumBy';
import reject from 'lodash/reject';
import groupBy from 'lodash/groupBy';
import merge from 'lodash/merge';
import { getAxisTextStyles, getCommonExportingOptions, getCommonNavigationOptions } from './utils';
import { formatNumber } from 'utils/Number/decimalFormat';
import { getSymbolChar } from 'utils/String/symbols';
import { CELSIUS } from 'utils/Data/values';
import simpleMultiValueTooltip from './simpleMultiValueTooltip';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const getConfig = props => {
  // Use 1.) explicit width and height, or 2.) Dimensions from Responsive, or 3.) Highcharts default.
  const { temperatureSeries } = props;

  const series = props.series ? [...props.series] : [];

  // enhance temperature data and push them to series
  const outdoorsTemperatureTitle = props.t('Outdoors temperature');
  const outdoorsTemperatureColors = props.theme.charts.colors.map(color => lighten(0.1, color));
  if (temperatureSeries?.length) {
    temperatureSeries.forEach((temperatureSeries, index) => {
      series.push({
        ...temperatureSeries,
        name: temperatureSeries.name,
        type: temperatureSeries.type || 'line',
        color: temperatureSeries.color || outdoorsTemperatureColors[index % outdoorsTemperatureColors.length],
        lineWidth: temperatureSeries.lineWidth || 1,
        dashStyle: temperatureSeries.dashStyle || 'Dash',
        data: temperatureSeries.data,
        _unit: temperatureSeries._unit,
        zIndex: 0,
        visible: temperatureSeries.visible ?? true,
        showInLegend: temperatureSeries.showInLegend !== undefined ? temperatureSeries.showInLegend : true,
        _showTooltipForZeroValue: true,
        states: {
          inactive: {
            opacity: 1,
          },
        },
      });
    });
  }

  const config = {
    annotations: props.annotations,
    chart: {
      type: props.type || 'column',
      zoomType: props.noZoom ? undefined : 'yx',
      panning: !props.noZoom,
      panKey: 'shift',
      className: 'bar-chart',
      backgroundColor: props.backgroundColor || 'transparent',
      plotBorderWidth: props.plotBorderWidth,
      plotBorderColor: props.plotBorderColor,
      plotBackgroundColor: props.plotBackgroundColor,
      style: {
        cursor: props.pointer || props.onClick ? 'pointer' : null,
        fontFamily: props.theme.fontFamily.text,
      },
      events: {
        click: props.onPointClick,
        selection: props.onSelection,
        drilldown: props.onDrilldown,
        drillup: props.onDrillup,
        render: props.onRender,
      },
      inverted: props.inverted,
      spacing: [8, 20, 8, 20],
    },
    drilldown: {
      allowPointDrilldown: false,
      series: [],
      colors: props.theme.charts.colors,
      activeAxisLabelStyle: props.labelStyle,
      breadcrumbs: {
        buttonTheme: {
          fill: props.theme.colors.alabaster,
          stroke: props.theme.colors.silver,
          'stroke-width': 1,
          padding: 8,
          style: {
            color: props.theme.colors.black,
          },
        },
        floating: true,
        position: {
          align: 'right',
        },
        showFullPath: false,
        formatter: function () {
          return props.t('Zoom out');
        },
      },
    },
    colors: props.theme.charts.colors,
    title: { text: props.title || '' },
    subtitle: { text: props.subtitle || '' },
    xAxis: merge({}, getAxisTextStyles(props.theme), {
      visible: true,
      categories: props.categories,
      crosshair: true,
      labels: {
        enabled: true,
        style: props.labelStyle,
        rotation: props.labelRotation,
        formatter: props.labelFormatter,
      },
      title: {
        text: props.xTitle,
      },
      type: props.xAxisType || 'datetime',
      minRange: props.minXRange,
      max: props.maxX,
    }),
    legend: {
      align: props.legendAlign || 'right',
      margin: props.legendAlign === 'left' ? 26 : 16,
      verticalAlign: 'top',
      borderWidth: 0,
      reversed: props.legendReversed || false,
    },
    tooltip: {
      enabled: !props.disableTooltip,
      outside: false,
      backgroundColor: 'transparent',
      borderWidth: 0,
      shadow: false,
      padding: 0,
      valueDecimals: props.valueDecimals,
      shared: true,
      useHTML: true,
      positioner(labelWidth, _, point) {
        const chartWidth = this.chart.chartWidth;
        const outOfBounds = point.plotX + labelWidth > chartWidth - 10;
        const rightMaxPosition = chartWidth - labelWidth - 10;
        const xPosition = outOfBounds ? rightMaxPosition : point.plotX;
        return {
          x: xPosition,
          y: 25,
        };
      },
      formatter() {
        let headerText = '';
        if (typeof props.getTooltipHeader === 'function') {
          headerText = props.getTooltipHeader(this.x, this.y, this.points);
        } else if (props.categories || props.xAxisType === 'category') {
          headerText = this.points[0].key;
        } else if (props.hideTime) {
          headerText = format(
            toDate(this.x),
            'do MMM',
            this.points?.[0]?.point?.options?.useUTC ? { timeZone: 'UTC' } : {}
          );
        } else {
          headerText = format(
            toDate(this.x),
            'do MMM HH:mm',
            this.points?.[0]?.point?.options?.useUTC ? { timeZone: 'UTC' } : {}
          );
        }

        const points = [...this.points];

        if (props.showTooltipTotal) {
          const total = sumBy(this.points, 'y');
          points.push({
            series: {
              ...points[0].series,
              name: props.t('Total'),
              color: props.theme.colors.black,
            },
            point: {},
            y: formatNumber(props.language, total),
          });
        }

        const tooltipPoints = points.reduce((accu, point) => {
          if (point && (point.y !== 0 || point.series.userOptions._showTooltipForZeroValue)) {
            const color = !point.series.userOptions._noTooltipColors && point.series.color;
            const symbol = getSymbolChar(point.series.symbol);

            let maximumFractionDigits = 2;

            if (point.series.userOptions._unit === CELSIUS) {
              maximumFractionDigits = 1;
            } else if (props.noRounding) {
              maximumFractionDigits = undefined; // uses default value from locale
            } else if (Number.isFinite(props.valueDecimals)) {
              maximumFractionDigits = props.valueDecimals;
            }

            const valuePart = formatNumber(props.language, point.y, { maximumFractionDigits });
            const unitPart = (!point.series.userOptions._hideTooltipUnit && point.series.userOptions._unit) || '';

            const value = `${valuePart} ${unitPart}`;

            return accu.concat([
              {
                color,
                symbol,
                label: props.hideLegend ? '' : point.series.name,
                value,
                warning: point.point.warning,
              },
            ]);
          }
          return accu;
        }, []);

        return simpleMultiValueTooltip({ theme: props.theme, headerText, points: tooltipPoints });
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        events: {
          click: props.onClick,
        },
      },
      series: {
        cursor: 'pointer',
        pointWidth: props.pointWidth,
        enableMouseTracking: !props.disableTooltip,
        animation: !props.disableAnimation && !props.disableTooltip,
        colorByPoint: props.colorByPoint || false,
        stacking: props.stacked ? 'normal' : undefined,
        point: {
          events: {
            click: props.onPointClick,
          },
        },
        pointStart: props.pointStart,
        pointInterval: props.pointInterval,
      },
      line: {
        marker: {
          states: {
            hover: {
              lineWidthPlus: 0,
              radiusPlus: 0,
              shadow: false,
              animation: { duration: 0 },
            },
          },
        },
        states: {
          hover: {
            lineWidthPlus: 0,
            halo: { size: 0 },
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      spline: {
        marker: {
          fillColor: props.theme.colors.white,
          lineWidth: 2,
          lineColor: null, // inherit from series
          states: {
            hover: {
              lineWidthPlus: 0,
              radiusPlus: 0,
              shadow: false,
              animation: { duration: 0 },
            },
          },
          enabled: props.plotOptions?.spline?.marker?.enabled || true,
        },
        states: {
          hover: {
            lineWidthPlus: 0,
            halo: { size: 0 },
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    series,
    exporting: getCommonExportingOptions({ title: props.title, categories: props.categories }),
    navigation: getCommonNavigationOptions(),
  };

  if (props.colors) {
    config.colors = props.colors;
  }
  if (props.hideLegend) {
    config.legend.enabled = false;
  }

  // recycling rates specific y-axis config
  if (props.yAxisConfig) {
    config.yAxis = props.yAxisConfig;
  } else {
    // y-axis configurations for different units
    const seriesByUnits = groupBy(series, '_unit');
    const units = reject(Object.keys(seriesByUnits), key => key === 'undefined');
    const isOnlyUnitTemperature =
      units.length === 0 && (!props.temperatureSeries || props.temperatureSeries.length === 0);
    if (units.length >= 1 || isOnlyUnitTemperature) {
      config.yAxis = [];
      units.forEach((unit, unitIndex) => {
        const yAxis = merge({}, getAxisTextStyles(props.theme), {
          title: { text: !props.hideYTitle && (props.yTitle || unit) },
          allowDecimals: !props.hideDecimals,
          opposite: props.yOpposite,
          tickInterval:
            props.yTickInterval === 'auto' ? undefined : props.yTickInterval || (unit === CELSIUS ? 10 : undefined),
          max: props.yMax,
          min: props.yMin,
          labels: {
            format: `{value}${props.hideYTitle ? unit : ''}`,
            style: props.labelStyle,
          },
          plotLines: clone(props.plotLines),
        });
        if (Boolean(unitIndex)) {
          yAxis.opposite = true;
          yAxis.title.text = !props.hideYTitle
            ? unit === CELSIUS
              ? `${outdoorsTemperatureTitle}(${CELSIUS})`
              : unit
            : undefined;
          yAxis.gridLineWidth = 0;
          yAxis.tickInterval = props.yTickInterval || unit === CELSIUS ? 1 : undefined;
          yAxis.minRange = unit === CELSIUS ? 5 : undefined;
        }
        // push to config and add config-index to all the series of this unit
        config.yAxis.push(yAxis);
        seriesByUnits[unit].forEach(series => {
          series.yAxis = unitIndex;
        });
      });
    } else {
      // Disable y-axis title if no units are given.
      config.yAxis = { title: { text: null } };
    }
  }

  if (props.dataLabels) {
    const seriesValues = series[0].totals;
    config.plotOptions.series.dataLabels = {
      style: {
        color: props.theme.colors.black,
        fontSize: '1.5em',
        textOutline: 'none',
      },
      formatter: function () {
        return seriesValues && seriesValues.length > 0 ? `${seriesValues[this.point.x]}` : this.y > 0 ? this.y : '';
      },
      enabled: true,
      inside: true,
    };
  }

  return config;
};

const useBarChartConfigs = props => {
  const language = useSelector(state => state.profile.profile.language);

  const configs = useMemo(() => getConfig({ ...props, language }), [props, language]);
  return configs;
};

export default useBarChartConfigs;
