import styled from 'styled-components';

const ShadowCard = styled.section`
  overflow: visible;
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-left: none;

  &::after,
  &::before {
    content: '';
  }

  background-color: ${props =>
    (props.isLoading && props.theme.colors.mystic) ||
    (props.className === 'gray' && props.theme.colors.alabaster) ||
    props.theme.colors.white};
`;

export default ShadowCard;
