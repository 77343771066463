import PropTypes from 'prop-types';
import PrimaryButton from 'components/Button/PrimaryButton';

const CancelButton = ({ t, ...buttonProps }) => (
  <PrimaryButton large naked {...buttonProps}>
    {t('Cancel')}
  </PrimaryButton>
);

CancelButton.propTypes = {
  t: PropTypes.func.isRequired,
};

export default CancelButton;
