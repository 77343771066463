import { lazy, Suspense } from 'react';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Section from 'components/Section/Section';
import SkeletonText from 'components/Skeletons/SkeletonText';
import FreeLayoutView from 'components/FreeLayoutView/FreeLayoutView';
import EmbeddedPageView from 'components/EmbeddedPageView/EmbeddedPageView';
import EmbeddedDocumentView from 'components/EmbeddedDocumentView/EmbeddedDocumentView';
import CustomChartCustomView from 'components/CustomCharts/CustomChartCustomView';
import Loader from 'components/Loader/Loader';
import PlainSection from 'components/Section/PlainSection';

import { customViewShape } from 'components/CustomView/utils';
import { VIEW_TYPES } from 'containers/Application/Admin/CustomViews/utils';

export const PowerBIReport = lazy(() => import('containers/Application/PowerBI/PowerBIReport'));

const isPowerBiView = view => view?.viewType === VIEW_TYPES.powerBi;
const isEmbeddedView = view => view?.viewType === VIEW_TYPES.embedded;
const isEmbeddedDocumentView = view => view?.viewType === VIEW_TYPES.embeddedDocument;
const isCustomChartView = view => view?.viewType === VIEW_TYPES.customCharts;

export const NoData = styled.p`
  width: 100%;
  text-align: center;
  margin-top: var(--size-xl);
`;
NoData.displayName = 'NoData';

const Description = styled.p`
  padding-bottom: var(--size-sm);
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.colors.black};
`;
Description.displayName = 'Description';

const CustomViewModule = ({ t, isPortfolioLevel, customView, functionalLocationId }) => {
  const LoadingRender = (
    <Section>
      <SkeletonText header />
    </Section>
  );

  const NoDataRender = <NoData>{t('No data available')}</NoData>;

  if (isPowerBiView(customView)) {
    return (
      <Suspense fallback={<Loader />}>
        <PowerBIReport
          customView={customView}
          isPortfolioLevel={isPortfolioLevel}
          loadingRender={LoadingRender}
          noDataRender={NoDataRender}
          functionalLocationId={functionalLocationId}
        />
      </Suspense>
    );
  }
  if (isEmbeddedView(customView)) {
    return (
      <PlainSection>
        <EmbeddedPageView customView={customView} loadingRender={LoadingRender} noDataRender={NoDataRender} />
      </PlainSection>
    );
  }
  if (isEmbeddedDocumentView(customView)) {
    return (
      <PlainSection>
        <EmbeddedDocumentView
          customView={customView}
          loadingRender={LoadingRender}
          noDataRender={NoDataRender}
          t={t}
          hasHero
        />
      </PlainSection>
    );
  }
  if (isCustomChartView(customView)) {
    return <CustomChartCustomView customView={customView} />;
  }
  return <FreeLayoutView customView={customView} loadingRender={LoadingRender} noDataRender={NoDataRender} />;
};

CustomViewModule.defaultProps = {
  isPortfolioLevel: false,
};

CustomViewModule.propTypes = {
  customView: PropTypes.oneOfType([customViewShape()]).isRequired,
  t: PropTypes.func.isRequired,
  isPortfolioLevel: PropTypes.bool,
  functionalLocationId: PropTypes.string,
};

export default translations(CustomViewModule);
