import styled from 'styled-components';

import SkeletonText from 'components/Skeletons/SkeletonText';

export const Header = styled.header`
  display: ${props => (props.hideOnMobile ? 'none' : 'flex')};
  ${props => props.theme.media.landscape`
    display: flex;
  `}
  ${props => props.theme.media.desktop`
      align-items: ${props => props.bottomStripe && 'center'};
  `}

  overflow-wrap: break-word;
  ${props => props.theme.media.portrait`
    word-break: normal;
  `}
`;

export const TitleContainer = styled.div`
  flex-grow: 2;
`;

export const ButtonContainer = styled.div`
  button {
    margin: var(--size-xxs) var(--size-xs);
  }
  button:last-child {
    margin-right: 0;
  }
`;

ButtonContainer.displayName = 'ButtonContainer';

export const Title = styled.h2`
  font-size: ${props => props.theme.fontSize.heading};
  font-weight: ${props => props.theme.fontWeight.heading};
  letter-spacing: 0.3px;
  line-height: ${props => props.theme.lineHeight.heading};
`;

Title.displayName = 'Title';

export const TitleSkeleton = styled(SkeletonText)`
  margin: 2px 0;
  height: calc(${props => props.theme.font.lineHeight.lg} * ${props => props.theme.fontSize.heading} - 2px);
  max-width: 400px;
`;

TitleSkeleton.displayName = 'TitleSkeleton';

export const SubTitle = styled.h3`
  color: var(--subheader-fg);
  font-size: ${props => props.theme.fontSize.note};
  font-weight: ${props => props.theme.fontWeight.normal};
  line-height: ${props => props.theme.lineHeight.text};
  margin-top: var(--size-xxs);
`;

SubTitle.displayName = 'SubTitle';

export const SubTitleSkeleton = styled(SkeletonText)`
  margin: 0;
  height: 1em;
  max-width: 600px;
`;

SubTitleSkeleton.displayName = 'SubTitleSkeleton';

export const StatusWrapper = styled.div`
  display: inline-block;
  margin-right: var(--size-sm);
`;

StatusWrapper.displayName = 'StatusWrapper';

export const SubtitleSecondaryContent = styled.span`
  color: var(--subheader-fg);
  padding-left: 0;
  ${props => props.theme.media.portrait`
    padding-left: 0.5em;
  `}
`;

export const SubtitlePrimaryContent = styled.span`
  color: var(--subheader-fg);
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const MobileBreak = styled.br`
  ${props => props.theme.media.portrait`
    display: none;
  `}
`;
