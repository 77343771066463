import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import orderBy from 'lodash/orderBy';
import some from 'lodash/some';

import Svg from 'components/Svg/Svg';
import { icons } from 'components/Icon/IconNames';
import { format } from 'utils/Date/dateFormatter';

const Content = styled.section`
  text-align: left;
  line-height: ${props => props.theme.font.lineHeight.lg};
`;
Content.displayName = 'Content';

const Title = styled.h2`
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.fontWeight.heading};
  padding-bottom: var(--size-md);
  margin-bottom: var(--size-md);
  border-bottom: ${props => `1px solid ${props.theme.colors.lightGray}`};
`;
Title.displayName = 'Title';

const Row = styled.div`
  display: flex;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.xs};
  padding-bottom: var(--size-md);
  position: relative;

  &::after {
    position: absolute;
    left: 1.5em;
    top: 0;
    content: ' ';
    display: block;
    width: 2px;
    height: 100%;
    background-color: ${props => props.theme.colors.lightGray};
  }

  &:last-child::after {
    background-color: transparent;
  }
`;
Row.displayName = 'Row';

const IconContainer = styled.div`
  border: ${props => !props.completed && !props.created && `1px solid ${props.theme.colors.lightGray}`};
  background-color: ${props =>
    props.completed
      ? props.theme.colors.emerald
      : props.created
        ? props.theme.colors.midnight
        : props.theme.colors.white};
  color: ${props => (props.completed || props.created ? props.theme.colors.white : props.theme.colors.midnight)};
  border-radius: 50%;
  width: 3em;
  min-width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--size-md);
  z-index: 1;
`;

const Icon = styled(Svg)`
  font-size: ${props => props.theme.font.size.md};
`;
Icon.displayName = 'Icon';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-line;
`;
TextContainer.displayName = 'TextContainer';

const SubjectContainer = styled.div`
  font-size: ${props => props.theme.font.size.xxs};
`;

const DateSpan = styled.span`
  color: ${props => props.theme.colors.darkGray};
`;

const Subject = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
`;
Subject.displayName = 'Subject';

const iconNames = {
  note: icons.PEN,
  email: icons.EMAIL,
  phonecall: icons.PHONE,
  completed: icons.OK,
  resolution: icons.OK,
  appointment: icons.APPOINTMENT,
};

const renderRow = (annotation, index) => {
  const { timestamp, subject, notetext, type, iconName } = annotation;
  const date = timestamp && new Date(timestamp);
  const isCompleted = type === 'completed' || type === 'resolution';
  const isCreated = !!iconName;
  const icon = iconNames[type] || iconName;

  return (
    <Row key={index}>
      <IconContainer completed={isCompleted} created={isCreated}>
        <Icon name={icon} fill="currentColor" />
      </IconContainer>
      <TextContainer>
        <SubjectContainer>
          {date && <DateSpan>{`${format(date, 'P')} - ${format(date, 'p')} // `}</DateSpan>}
          <Subject>{subject}</Subject>
        </SubjectContainer>
        {notetext}
      </TextContainer>
    </Row>
  );
};

const ObservationsModalContent = ({ data, t }) => {
  if (!data || !data.location) {
    return null;
  }

  const { location, status, date, system, annotations = [] } = data;
  const { description, value } = location;

  const isCompleted = status && status.status === 'completed';
  const sortedAnnotations = orderBy(annotations, 'timestamp', 'desc');
  const hasResolution = some(sortedAnnotations, { type: 'resolution' });

  let rowData =
    !isCompleted || hasResolution
      ? sortedAnnotations
      : [
          {
            subject: t('Observation completed'),
            type: 'completed',
          },
        ].concat(sortedAnnotations);

  rowData = rowData.concat([
    {
      subject: t('Observation created'),
      notetext: description,
      timestamp: date && date.date,
      iconName: system && system.icon,
    },
  ]);

  return (
    <Content>
      <Title>{value}</Title>
      {rowData.map((annotation, index) => renderRow(annotation, index))}
    </Content>
  );
};

ObservationsModalContent.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({
    location: PropTypes.object,
    status: PropTypes.object,
    date: PropTypes.object,
    system: PropTypes.object,
    annotations: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default withTheme(ObservationsModalContent);
