import { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import withQuery from 'decorators/Query/withQuery';

import StandardPage from 'components/StandardPage/StandardPage';
import { scrollTo, CUSTOM_TYPE } from './UserManualUtils';
import OnImagesLoaded from 'components/OnImagesLoaded/OnImagesLoaded';
import UserManualContent from './UserManualContent';
import UserManualSectionList from './UserManualSectionList';
import { UserManualHeader } from './UserManualHeader';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import { loadData, loadAdminUserManualData, loadRoadmapData } from 'redux/modules/userManual/userManual';
import FadeTransition from 'components/FadeTransition/FadeTransition';

const PageContainer = styled.div`
  position: initial;
`;

const StyledUserManualContainer = styled.div`
  display: flex;
`;

const SectionListFill = styled.div`
  display: none;

  ${props => props.theme.media.landscape`
    display: block;
    width: 25%;
    max-width: 300px;
    flex-shrink: 0;
  `};
`;

const UserManualContentContainer = styled.div`
  padding: 9rem 3rem 3rem;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.white};
  margin: 0 auto;

  ${props => props.theme.media.landscape`
    padding: var(--size-xxl);
    width: 75%;
    max-width: 1100px;
  `};
`;

UserManualContentContainer.displayName = 'UserManualContentContainer';

export class UserManualContainer extends PureComponent {
  state = {
    initialLoading: true,
  };

  componentDidMount() {
    const { type, loadData, loadAdminUserManualData, loadRoadmapData } = this.props;
    type === CUSTOM_TYPE.GENERAL_USER_MANUAL && loadData();
    type === CUSTOM_TYPE.ADMIN_USER_MANUAL && loadAdminUserManualData();
    type === CUSTOM_TYPE.ROADMAP && loadRoadmapData();
  }

  componentDidUpdate(prevProps) {
    const prevUserManual = prevProps.userManual;
    const userManual = this.props.userManual;

    if (this.state.initialLoading && prevUserManual.loading && !userManual.loading) {
      this.setState({ initialLoading: false });
    }
  }

  scrollToTitle = () => {
    const title = this.props.query.title;
    if (title) {
      scrollTo(title);
    }
  };

  getUserManualData = (type, userManual) => {
    let data;
    if (type === CUSTOM_TYPE.GENERAL_USER_MANUAL) {
      data = userManual?.general;
    } else if (type === CUSTOM_TYPE.ADMIN_USER_MANUAL) {
      data = userManual?.admin;
    } else if (type === CUSTOM_TYPE.ROADMAP) {
      data = userManual?.roadmap;
    }
    return data || {};
  };

  getSkeleton = () => {
    return (
      <Fragment>
        <SkeletonText header />
        <SkeletonText style={{ margin: '2em 0', width: '20%' }} />
        <SkeletonChart height="200px" />
      </Fragment>
    );
  };

  render() {
    const { type, userManual, title } = this.props;
    const { initialLoading } = this.state;

    const data = this.getUserManualData(type, userManual);
    const dataSuccessfullyLoaded = !isEmpty(data);
    const error = !!userManual.error;
    const loading = userManual.loading || initialLoading;

    if (!loading && (error || !dataSuccessfullyLoaded)) {
      return <ErrorPage />;
    }

    return (
      <FadeTransition>
        <StandardPage noMargin>
          <Helmet title={title} />
          <PageContainer>
            <UserManualSectionList data={data} loading={loading} />
            <StyledUserManualContainer>
              <SectionListFill />
              <UserManualContentContainer>
                {loading && this.getSkeleton()}
                {dataSuccessfullyLoaded && !loading && (
                  <Fragment>
                    <UserManualHeader data={data} />
                    <OnImagesLoaded onLoaded={this.scrollToTitle} timeout={10000}>
                      <UserManualContent data={data} />
                    </OnImagesLoaded>
                  </Fragment>
                )}
              </UserManualContentContainer>
            </StyledUserManualContainer>
          </PageContainer>
        </StandardPage>
      </FadeTransition>
    );
  }
}

UserManualContainer.defaultProps = {
  userManual: PropTypes.shape({
    general: null,
    admin: null,
    error: null,
  }),
};

UserManualContainer.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([CUSTOM_TYPE.ADMIN_USER_MANUAL, CUSTOM_TYPE.GENERAL_USER_MANUAL, CUSTOM_TYPE.ROADMAP]),
  userManual: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    general: PropTypes.object,
    admin: PropTypes.object,
    roadmap: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  query: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  loadAdminUserManualData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userManual: state.userManual,
});

const mapDispatchToProps = {
  loadData,
  loadAdminUserManualData,
  loadRoadmapData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withQuery(connector(UserManualContainer));
