import { Component } from 'react';
import PropTypes from 'prop-types';

export default class AccordionItem extends Component {
  render() {
    const { children } = this.props;

    return <div className="accordion-content">{children}</div>;
  }
}

AccordionItem.propTypes = {
  // Item's collapsible contents
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  // Title of accordion item
  title: PropTypes.string,
  // Custom styling for title
  titleStyle: PropTypes.object,
  // Title can also be defined as an component to provide full customization
  titleComponent: PropTypes.node,
};
