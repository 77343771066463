import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_PATH } from './index';

export const sendFeedbackFn = (request, data) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/feedbacks`,
    data,
    noCache: true,
  });

export default wrapEachWithThunkRequest({
  sendFeedback: sendFeedbackFn,
});
