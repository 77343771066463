import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import popperMaxSize from 'popper-max-size-modifier';

import { useTranslations } from 'decorators/Translations/translations';
import AnnotationForm from './AnnotationForm';
import { format } from 'utils/Date/dateFormatter';
import parseISO from 'date-fns/parseISO';
import { useAnnotationDataValidity } from './annotationDataValidity.context';
import IoT from 'services/iot';
import useVirtualReference from 'containers/Application/Modules/CustomChartsModule/useVirtualReference';
import { useSelector } from 'react-redux';
import { hasBuildingAndPortfolioAdminTools } from 'utils/Data/profileData';
import getDefaultTargetOptions from './getDefaultTargetOptions';
import * as Styled from './AnnotationPopover.styled';
import { Popover as FormPopover } from './CreateAnnotation.styled';

const applyMaxHeightLimit = {
  name: 'applyMaxHeightLimit',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['maxSize'],
  fn({ state }) {
    const { height } = state.modifiersData.maxSize;
    state.styles.popper.maxHeight = `${height}px`;
  },
};

const AnnotationPopover = props => {
  const {
    annotations,
    position,
    sensor,
    chart,
    functionalLocation,
    onClose,
    showNotification,
    containerRef,
    targetOptions,
    onDeleted,
  } = props;
  const [t] = useTranslations();
  const [editingAnnotation, setEditingAnnotation] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const virtualReference = useVirtualReference(position);
  const allowAnnotationsAdminTools = useSelector(state => hasBuildingAndPortfolioAdminTools(state.profile.profile));

  const { styles, attributes } = usePopper(virtualReference, popperElement, {
    placement: 'top',
    modifiers: [popperMaxSize, applyMaxHeightLimit],
  });
  const { setDataInvalid } = useAnnotationDataValidity();

  const onDelete = useCallback(
    async annotation => {
      try {
        await IoT.deleteAnnotation(annotation.id);
        setDataInvalid();
        showNotification({ type: 'success', message: t('Note was deleted successfully'), autoHide: true });
        onDeleted?.(annotation);
        onClose();
      } catch (error) {
        showNotification({ type: 'error', message: t('Deleting note failed'), autoHide: true });
      }
    },
    [onClose, setDataInvalid, t, showNotification, onDeleted]
  );

  const onSubmitEdit = useCallback(
    async annotation => {
      try {
        await IoT.upsertAnnotation({
          id: annotation.id,
          sensorId: annotation.sensorId,
          functionalLocation: annotation.functionalLocation,
          customChartId: annotation.customChartId,
          comment: annotation.comment,
          timestamp: annotation.timestamp,
        });
        setDataInvalid();
        showNotification({ type: 'success', message: t('Note was saved successfully'), autoHide: true });
        onClose();
      } catch (error) {
        showNotification({ type: 'error', message: t('Saving note failed'), autoHide: true });
        throw error;
      }
    },
    [setDataInvalid, showNotification, onClose, t]
  );

  const renderEditing = annotation => (
    <Styled.Annotation key={annotation.id}>
      <AnnotationForm
        annotation={annotation}
        sensor={sensor}
        chart={chart}
        functionalLocation={functionalLocation}
        onSubmit={onSubmitEdit}
        onClose={() => setEditingAnnotation(null)}
        targetOptions={
          (targetOptions?.length && targetOptions) || getDefaultTargetOptions(sensor, chart, functionalLocation, t)
        }
      />
    </Styled.Annotation>
  );

  const renderViewing = annotation => (
    <Styled.Annotation key={annotation.id}>
      <Styled.Comment
        allowAnnotationsAdminTools={allowAnnotationsAdminTools}
        onClick={() => allowAnnotationsAdminTools && setEditingAnnotation({ ...annotation })}
      >
        {annotation.comment}
      </Styled.Comment>
      <Styled.Details>
        <Styled.Time>{format(parseISO(annotation.timestamp), 'do MMM yy, p')}</Styled.Time>
        {allowAnnotationsAdminTools && (
          <Styled.Delete onClick={() => onDelete(annotation)}>{t('Delete')}</Styled.Delete>
        )}
      </Styled.Details>
    </Styled.Annotation>
  );

  const shouldRenderEditing = allowAnnotationsAdminTools && editingAnnotation;

  if (!annotations?.length) {
    return null;
  }

  const Popover = shouldRenderEditing ? FormPopover : Styled.Popover;
  return ReactDOM.createPortal(
    <Styled.PortalContainer>
      <Styled.Backdrop onClick={onClose} active />
      <Popover ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {shouldRenderEditing
          ? renderEditing(editingAnnotation)
          : annotations.map(annotation => renderViewing(annotation))}
      </Popover>
    </Styled.PortalContainer>,
    containerRef.current
  );
};

AnnotationPopover.propTypes = {
  sensor: PropTypes.object,
  chart: PropTypes.object,
  functionalLocation: PropTypes.string,
  annotations: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string,
      timestamp: PropTypes.string,
    })
  ),
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  onClose: PropTypes.func,
  t: PropTypes.func,
  allowAnnotationsAdminTools: PropTypes.bool,
  showNotification: PropTypes.func,
  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  targetOptions: PropTypes.array,
  onDeleted: PropTypes.func,
};

export default AnnotationPopover;
