import ErrorPageContent from 'components/ErrorPage/ErrorPageContent';
import PlainSection from 'components/Section/PlainSection';
import AdditionalSaleContent from './AdditionalSaleContent';
import AdditionalSaleConfirmation from './AdditionalSaleConfirmation/AdditionalSaleConfirmation';

import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import useQuery from 'decorators/Query/useQuery';
import useAdditionalSale, { ACTION_STAGE } from './useAdditionalSale';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';

const AdditionalSaleContainer = () => {
  const { t, loadingTranslations } = useUnauthenticatedTranslations();
  const { query } = useQuery();
  const { additionalSale, loading, error, stage, submitAction, submitManagerChange, submitData } = useAdditionalSale(
    query.token
  );
  const { desktop } = useBreakpoints();

  if (stage === ACTION_STAGE.Sent) {
    return (
      <PlainSection>
        <AdditionalSaleConfirmation
          action={submitData.action || additionalSale.action}
          type={additionalSale.token.type}
          submitData={submitData}
        />
      </PlainSection>
    );
  }

  if (stage === ACTION_STAGE.Error) {
    return <ErrorPageContent />;
  }

  if (!query.token || error) {
    return (
      <ErrorPageContent
        title={t('Work proposal not found')}
        instructions={t('You have already replied this proposal or you have a wrong address.')}
      />
    );
  }

  return (
    <PlainSection noPadding={!desktop}>
      <AdditionalSaleContent
        additionalSale={additionalSale}
        loading={loadingTranslations || loading}
        onSubmitAction={submitAction}
        onSubmitManagerChange={submitManagerChange}
      />
    </PlainSection>
  );
};

AdditionalSaleContainer.propTypes = {};

export default AdditionalSaleContainer;
