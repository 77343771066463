import styled, { css } from 'styled-components';
import { RowData } from '@tanstack/react-table';

import { ColumnDragHandleProps } from './GenericTable';

export type ResizerProps = {
  isResizing: boolean;
};

export const Resizer = styled.div<ResizerProps>`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;

  background-color: rgb(0 0 0 / 0.2);

  cursor: col-resize;
  user-select: none;
  touch-action: none;

  opacity: 0;
  transition: opacity 150ms ease-out;

  ${props =>
    props.isResizing &&
    css`
      opacity: 1;
    `}

  *:hover > & {
    opacity: 1;
  }
`;

/**
 * See {@link https://tanstack.com/table/v8/docs/examples/react/column-sizing}
 */
export function DefaultColumnDragHandle<TData extends RowData>({ header, table }: ColumnDragHandleProps<TData>) {
  return (
    <Resizer
      isResizing={header.column.getIsResizing()}
      {...{
        onMouseDown: header.getResizeHandler(),
        onTouchStart: header.getResizeHandler(),
        className: `${header.column.getIsResizing() ? 'isResizing' : ''}`,
        style: {
          transform:
            table.options.columnResizeMode === 'onEnd' && header.column.getIsResizing()
              ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
              : '',
        },
      }}
    />
  );
}
