import { PureComponent, useState } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import compact from 'lodash/compact';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { getBuildingTimezone } from 'utils/Data/performance';

import translations from 'decorators/Translations/translations';

import { Sensor } from 'components/Sensor/Sensor';
import SensorHead from 'components/Sensor/SensorHead/SensorHead';
import SensorBody from 'components/Sensor/SensorBody/SensorBody';
import SensorTools from 'components/Sensor/SensorTools/SensorTools';
import { SensorSelector } from 'components/Sensor/SensorSelector/SensorSelector';
import PresenceKeys from 'components/Sensor/PresenceKeys/PresenceKeys';
import { AnnotationDataValidityProvider } from 'components/Sensor/SensorAnnotations/annotationDataValidity.context';
import { OnOffAggregations } from 'components/Sensor/OnOffAggregations/OnOffAggregations';
import { DATE_RANGES } from 'components/Form/DateTools';

import { getLatestSensorValueProperties, isEnergySensor, isStatisticsSensor } from 'utils/Data/values';
import { getPerformanceLimit } from 'utils/Data/performance';

import {
  loadSensorsValues,
  loadBucketedSensorValues,
  addLoading,
  removeLoading,
  loadUtilizationRateChartValues,
  loadLatestSensorValuesDetectAggregation,
  loadPublicSensorValues,
} from 'redux/modules';

import {
  getSensorData,
  getSensor,
  getSensorType,
  getSensorTitle,
  getAggregation,
  getInitialParameters,
  getPerformanceSelectorOptions,
  getRawUtilization,
  STATISTICS_TYPES,
  getStatisticsOptions,
  getUtilizationDataset,
  getUtilizationHours,
  getUtilizationRateSelectorOptions,
  getSensorSelectorOptions,
  DEFAULT_GROUP_NAME,
} from './SensorValuesUtils';
import { useOnOffSensorChartData } from 'services/iot/onOffSensors';

const NoData = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 5em;
`;
NoData.displayName = 'NoData';

class SensorValues extends PureComponent {
  state = {
    sensors: [],
    sensor: undefined,
    parameters: {
      startDatetime: null,
      endDatetime: null,
      isZoomed: false,
      resetZoomDatetimes: {},
      presence: [0, 0],
      statistics: STATISTICS_TYPES.perDay,
    },
    sensorDataLoaded: false,
  };

  getLatestValue = sensor => this.props.values.sensors.modalLatestValuesBySensorId[sensor.id];

  handleSensorSelect = (property, sensor) => {
    const sensorType = getSensorType(sensor, this.props.sensorTypes);
    this.setState(oldState => ({
      sensor,
      parameters: getInitialParameters(oldState.parameters, sensorType?.graphType, true),
    }));
  };

  handleParameterSelect = (property, value) => {
    this.setState(oldState => ({ parameters: { ...oldState.parameters, [property]: value } }));
  };

  updateMultipleParameters = changes => {
    this.setState(oldState => ({ parameters: { ...oldState.parameters, ...changes } }));
  };

  componentDidMount() {
    const { sensorsIds, defaultSensorId, sensorTypes, buildingSensors, t, sensorGroups, showCombinationGroup } =
      this.props;

    if (!isEmpty(sensorsIds)) {
      const sensors = sensorGroups || compact(sensorsIds.map(sensorId => getSensor(sensorId, buildingSensors)));

      const combinedSensor = showCombinationGroup && {
        name: t('All'),
        isGroup: true,
        sensorType: sensors[0].sensorType,
        sensorTypeId: sensors[0].sensorTypeId,
        sensorIds: sensorsIds,
      };

      const sensor =
        combinedSensor || (defaultSensorId && find(sensors, { id: defaultSensorId })) || sortBy(sensors, 'name')[0];

      const sensorType = getSensorType(sensor, sensorTypes);

      this.setState(oldState => ({
        sensors,
        sensor,
        combinedSensor,
        parameters: getInitialParameters(oldState.parameters, sensorType?.graphType, false),
      }));
    }
  }

  // noinspection JSCheckFunctionSignatures
  componentDidUpdate(prevProps, prevState) {
    const {
      parameters: { startDatetime, endDatetime },
      sensor,
      sensorDataLoaded,
    } = this.state;

    const { isAirQuality, isUtilizationRate, sensorTypes, sensorsIds, setSensorArguments } = this.props;
    const sensorType = getSensorType(sensor, sensorTypes);

    if (!startDatetime || !endDatetime || !sensorTypes?.length) {
      return;
    }

    // Do not load data if sensor or datetimes haven't changed
    if (
      !sensorDataLoaded ||
      (!isAirQuality && !isUtilizationRate && prevState.sensor && prevState.sensor.id !== sensor.id) ||
      !prevState.parameters.startDatetime ||
      !prevState.parameters.endDatetime ||
      prevState.parameters.startDatetime.valueOf() !== startDatetime.valueOf() ||
      prevState.parameters.endDatetime.valueOf() !== endDatetime.valueOf()
    ) {
      const isOnOff = sensorType?.name === 'on_off_sensor';

      if (isOnOff) {
        // Pass the props upwards to withOnOffSensorData.
        // In the future this component should be refactored to a function component
        // and the data handling logic should be simplified. Once the data parameters
        // have been lifted, this becomes unnecessary.
        setSensorArguments(previousArguments => {
          const nextArguments = {
            sensorIds: sensorsIds,
            startDate: startDatetime,
            endDate: endDatetime,
            include: ['labels', 'summary'],
          };
          // Make sure that the reference is not updated if the content remains the same
          return JSON.stringify(previousArguments) === JSON.stringify(nextArguments)
            ? previousArguments
            : nextArguments;
        });
      }

      this.loadSensorData();
      if (!sensorDataLoaded) {
        this.setState({ sensorDataLoaded: true });
      }
    }
  }

  loadSensorData = () => {
    const {
      addLoading,
      removeLoading,
      loadSensorsValues,
      loadBucketedSensorValues,
      loadUtilizationRateChartValues,
      loadPublicSensorValues,
      functionalLocation: { functionalLocation },
      sensorTypes,
      isAirQuality,
      isUtilizationRate,
      sensorsIds,
      buildingMeta,
      loadLatestSensorValuesDetectAggregation,
      publicViewId,
      floorId,
      buildingSensors,
    } = this.props;

    const {
      sensor,
      sensors,
      parameters: { startDatetime, endDatetime },
      parameters,
      combinedSensor,
    } = this.state;

    const sensorType = getSensorType(sensor, sensorTypes);
    const start = new Date(startDatetime);
    const end = new Date(endDatetime);
    const aggregation = getAggregation(sensorType, parameters, sensor, isUtilizationRate);
    const utilizationHours = getUtilizationHours(buildingMeta);
    const isArea = sensor.sensorType && sensor.sensorType.name === 'presence_area';
    const sensorsGrouped = sensors[0]?.isGroup;

    const loadValues = () => {
      if (isUtilizationRate) {
        const combinedSensorGroup = {
          ...combinedSensor,
          sensors: compact(combinedSensor.sensorIds.map(sensorId => getSensor(sensorId, buildingSensors))),
        };

        const sensorGroups = sensorsGrouped
          ? [combinedSensorGroup, ...sensors]
          : [{ name: DEFAULT_GROUP_NAME, sensors }];

        return loadUtilizationRateChartValues({
          sensorGroups,
          startTime: start,
          endTime: end,
          utilizationHours,
          isArea,
          publicViewId,
          floorId,
        });
      }

      if (publicViewId) {
        return loadPublicSensorValues({
          sensorIds: isAirQuality ? sensorsIds : [sensor.id],
          startDate: start,
          endDate: end,
          aggregation,
          publicViewId,
          floorId,
        });
      }

      if (aggregation === 'raw') {
        return loadSensorsValues(sensorsIds, [start, end], aggregation);
      }

      if (isAirQuality) {
        return loadBucketedSensorValues(sensorsIds, [start, end], aggregation);
      }

      return loadBucketedSensorValues([sensor.id], [start, end], aggregation);
    };

    const loadLatestValues = () => {
      if (!isUtilizationRate && !isAirQuality && !publicViewId) {
        return loadLatestSensorValuesDetectAggregation(sensors, true);
      }
    };

    addLoading(functionalLocation);

    Promise.all([loadValues(), loadLatestValues()]).finally(() => removeLoading(functionalLocation));
  };

  getPerformanceSelectorOptions() {
    const {
      values: {
        sensors: { modalValuesBySensorId, modalLatestValuesBySensorId },
      },
      isAirQuality,
      isUtilizationRate,
      utilizationRateChartValues,
      areas,
      buildingMeta,
      theme,
      t,
      sensorAlarmsById,
      openingHours,
      functionalLocation,
    } = this.props;
    const { sensors, combinedSensor } = this.state;

    const timezone = getBuildingTimezone(functionalLocation.functionalLocation, buildingMeta);

    if (isAirQuality) {
      return getPerformanceSelectorOptions(sensors, combinedSensor, modalValuesBySensorId, openingHours, timezone);
    }
    if (isUtilizationRate) {
      return getUtilizationRateSelectorOptions(sensors, combinedSensor, utilizationRateChartValues, areas);
    }
    return getSensorSelectorOptions(sensors, modalLatestValuesBySensorId, buildingMeta, theme, t, sensorAlarmsById);
  }

  render() {
    const {
      t,
      isLoading,
      defaultSensorId,
      theme,
      values: {
        sensors: { modalValuesBySensorId },
      },
      sensorTypes,
      isAirQuality,
      isUtilizationRate,
      buildingMeta,
      category,
      sensorAlarmsById,
      title,
      areas,
      openingHours,
      functionalLocation,
      sensorGroups,
      utilizationRateChartValues,
      useDeviceNames,
      buildingSensors,
      fullScreen,
      publicViewId,
      onOffSensorRemoteDataState,
    } = this.props;

    const { sensor, sensors, parameters } = this.state;

    if (!sensor && !onOffSensorRemoteDataState.data) {
      return <NoData>{t('No data available')}</NoData>;
    }

    const sensorType = getSensorType(sensor, sensorTypes);
    const isOnOff = sensorType?.name === 'on_off_sensor';
    const onOffSeries = onOffSensorRemoteDataState.data?.series[0];

    const utilizationHours = getUtilizationHours(buildingMeta);
    const aggregation = getAggregation(sensorType, parameters, sensor, isUtilizationRate);
    const latestValue = this.getLatestValue(sensor);

    const sensorData = isUtilizationRate
      ? getUtilizationDataset(sensor, sensors, utilizationRateChartValues, parameters.statistics, areas)
      : getSensorData(sensor, sensorType, modalValuesBySensorId, latestValue, parameters);

    const { value, time, color } = getLatestSensorValueProperties(
      latestValue,
      sensorType,
      sensorData,
      sensor.sensorMeta,
      theme,
      t
    );

    const isPresence = sensorType?.graphType === 'presence';
    const isPresenceView = isPresence && !isUtilizationRate && sensors.length === 1;
    const isRadon = sensorType?.name === 'radon';

    const defaultRange = isUtilizationRate
      ? DATE_RANGES.DAYS_30
      : isPresence
        ? DATE_RANGES.DAYS_7
        : isRadon
          ? DATE_RANGES.DAYS_30
          : sensorType && (isEnergySensor(sensorType.name) || isStatisticsSensor(sensorType.name))
            ? DATE_RANGES.DAYS_365
            : isOnOff
              ? DATE_RANGES.HOURS_24
              : sensor?.granularity === 'month'
                ? DATE_RANGES.DAYS_365
                : DATE_RANGES.DAYS_7;

    const performanceSelectorOptions = !isPresenceView && this.getPerformanceSelectorOptions();

    const presenceUtilization =
      isPresenceView && getRawUtilization(modalValuesBySensorId[sensor.id], parameters, utilizationHours);

    const statisticsOptions = isUtilizationRate && getStatisticsOptions(t, sensorType, !!sensorGroups);

    const getThreshold =
      (sensorType &&
        (sensorType.graphType === 'iot' || sensorType.graphType === 'performance') &&
        getPerformanceLimit(sensor, sensor.parent, buildingMeta, sensorAlarmsById[sensor.id])) ||
      undefined;

    const timezone = getBuildingTimezone(functionalLocation.functionalLocation, buildingMeta);

    const sensorTitle = getSensorTitle(title, sensor);

    return (
      <Sensor fullScreen={fullScreen}>
        <SensorHead
          t={t}
          loading={isLoading}
          sensor={sensor}
          sensorTitle={sensorTitle}
          sensorValue={performanceSelectorOptions ? undefined : value}
          sensorValueTime={performanceSelectorOptions ? undefined : time}
          sensorValueColor={color}
          statisticsOptions={statisticsOptions || undefined}
          onStatisticsChange={this.handleParameterSelect}
          category={category}
          utilizationHours={isUtilizationRate || isPresenceView ? utilizationHours : undefined}
          model={parameters}
          contextualHelp={isUtilizationRate ? 'Utilization Rate' : undefined}
          openingHours={openingHours}
          timezone={timezone}
        />

        {performanceSelectorOptions && parameters.statistics !== STATISTICS_TYPES.perSensor && (
          <SensorSelector
            model={this.state}
            property="sensor"
            onClick={this.handleSensorSelect}
            options={performanceSelectorOptions}
            loading={isLoading}
            theme={theme}
            isAirQuality={isAirQuality}
            isUtilizationRate={isUtilizationRate}
            useDeviceNames={useDeviceNames}
            buildingSensors={buildingSensors}
            areas={areas}
          />
        )}

        {isPresenceView && !isUtilizationRate && (
          <PresenceKeys
            t={t}
            loading={isLoading}
            utilizationRate={presenceUtilization.utilizationRate}
            unusedHours={presenceUtilization.unusedHours}
            color={theme.colors.midnight}
          />
        )}

        {isOnOff && <OnOffAggregations series={onOffSeries} />}

        <AnnotationDataValidityProvider>
          <SensorBody
            loading={isLoading}
            sensorData={sensorData}
            sensorType={sensorType}
            parameterModel={this.state.parameters}
            updateParameters={this.updateMultipleParameters}
            hasSubsensors={!!defaultSensorId}
            sensor={sensor}
            aggregation={aggregation}
            sensorMeta={sensor.sensorMeta}
            smallHeight
            utilizationHours={utilizationHours}
            statistics={parameters.statistics}
            getThreshold={getThreshold}
            openingHours={openingHours}
            buildingMeta={buildingMeta}
            timezone={timezone}
            functionalLocation={functionalLocation}
            sensorTitle={sensorTitle}
            publicViewId={publicViewId}
            booleanLabels={isOnOff ? onOffSeries?.labels?.map(t) : undefined}
          />
        </AnnotationDataValidityProvider>

        <SensorTools
          parameterModel={this.state.parameters}
          updateParameters={this.updateMultipleParameters}
          defaultRange={defaultRange}
          sensorGranularity={sensor.granularity}
          isUtilizationRate={isUtilizationRate}
        />
      </Sensor>
    );
  }
}

const COMPONENT = 'SensorValues';

const isLoadingState = (state, props) => {
  if (props.isUtilizationRate && state.utilizationRate.loading) {
    return true;
  }
  return includes(state.common.loading[COMPONENT], props.functionalLocation.functionalLocation);
};

const mapStateToProps = (state, ownProps) => ({
  values: state.values,
  isLoading: isLoadingState(state, ownProps),
  sensorTypes: state.sensorHierarchy.sensorDataTypes,
  utilizationRateChartValues: state.utilizationRate.utilizationRateChartValues,
});

const mapDispatchToProps = {
  loadSensorsValues: (sensorsIds, between, aggregation) => loadSensorsValues(sensorsIds, between, aggregation, true),
  loadBucketedSensorValues: (sensorsIds, between, aggregation) =>
    loadBucketedSensorValues(sensorsIds, between, aggregation, true),
  loadPublicSensorValues,
  loadUtilizationRateChartValues,
  addLoading: key => addLoading(key, COMPONENT),
  removeLoading: key => removeLoading(key, COMPONENT),
  loadLatestSensorValuesDetectAggregation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

SensorValues.propTypes = {
  // required from redux/decorators:
  addLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadSensorsValues: PropTypes.func.isRequired,
  loadBucketedSensorValues: PropTypes.func.isRequired,
  loadPublicSensorValues: PropTypes.func.isRequired,
  loadLatestSensorValuesDetectAggregation: PropTypes.func.isRequired,
  loadUtilizationRateChartValues: PropTypes.func.isRequired,
  removeLoading: PropTypes.func.isRequired,
  sensorTypes: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  utilizationRateChartValues: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  // required from parent:
  sensorsIds: PropTypes.array.isRequired,
  buildingSensors: PropTypes.array.isRequired,
  functionalLocation: PropTypes.object.isRequired,
  // optional:
  defaultSensorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isAirQuality: PropTypes.bool,
  isUtilizationRate: PropTypes.bool,
  buildingMeta: PropTypes.arrayOf(PropTypes.object),
  category: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    shortName: PropTypes.string,
  }),
  sensorAlarmsById: PropTypes.object,
  title: PropTypes.string,
  areas: PropTypes.arrayOf(PropTypes.object),
  openingHours: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sensorGroups: PropTypes.array,
  showCombinationGroup: PropTypes.bool,
  useDeviceNames: PropTypes.bool,
  fullScreen: PropTypes.bool,
  publicViewId: PropTypes.string, // public view usage
  floorId: PropTypes.number, // public view usage
  setSensorArguments: PropTypes.func.isRequired, // see withOnOffSensorData
  onOffSensorRemoteDataState: PropTypes.object.isRequired, // see withOnOffSensorData
};

SensorValues.defaultProps = {
  defaultSensorId: undefined,
  sensorType: undefined,
  isAirQuality: false,
  isUtilizationRate: false,
};

export function withOnOffSensorData(Component) {
  return props => {
    const [sensorArguments, setSensorArguments] = useState({
      sensorIds: [],
      startDate: null,
      endDate: null,
      include: [],
    });

    const onOffSensorRemoteDataState = useOnOffSensorChartData(
      sensorArguments.sensorIds,
      sensorArguments.startDate,
      sensorArguments.endDate,
      sensorArguments.include
    );

    return (
      <Component
        {...props}
        setSensorArguments={setSensorArguments}
        onOffSensorRemoteDataState={onOffSensorRemoteDataState}
      />
    );
  };
}

export default withOnOffSensorData(connector(translations(withTheme(SensorValues))));
