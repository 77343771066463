import { useEffect } from 'react';

const RELOAD_TIME = 60 * 60 * 1000; // one hour

const usePageReload = () => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.location.reload();
    }, RELOAD_TIME);
    return () => clearTimeout(timeoutId);
  }, []);
};

export default usePageReload;
