import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { format } from 'utils/Date/dateFormatter';
import toDate from 'date-fns/toDate';
import parseISO from 'date-fns/parseISO';
import KeyCards from 'components/OPICard/KeyCards';
import { withRouter } from 'react-router-dom';

const CardContainer = styled.div`
  width: 100%;
`;
CardContainer.displayName = 'CardContainer';

const handleDates = data => {
  const date = typeof data.date === 'string' ? parseISO(data.date) : toDate(data.date.valueOf());
  return `${format(date, 'P')} - ${format(date, 'p')}`;
};

const handleFunctionalLocations = locations => {
  for (let i = locations.length - 1; i >= 0; i--) {
    if (locations[i].match(/(UN)|(BU)|(IN)/)) {
      return locations[i];
    }
  }
  return null;
};

export const ObservationModalTop = ({ t, observation, profile, location }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const newItems = [];
    if (observation.status) {
      newItems.push({
        label: t('Status'),
        value: t(observation?.status?.title),
        subvalue: t(observation?.subStatus),
      });
    }
    if (observation.disciplineValue) {
      newItems.push({
        label: t('Discipline'),
        value: t(observation?.disciplineValue),
      });
    }
    if (observation.sensorid) {
      let isLink = true;

      const baseUrl = location.pathname.match(/.*\/FunctionalLocation\/.*?\//);
      let sensorBaseUrl = `${baseUrl}BMSAnalytics/AnalyticsChart?openBmsSensorId=${observation.sensorid}`;

      if (observation.path && observation.partnerNumber && !baseUrl) {
        const flBuilding = handleFunctionalLocations(observation.path);
        if (!flBuilding) {
          isLink = false;
        } else {
          const partnerNumber = Array.isArray(observation.partnerNumber)
            ? observation.partnerNumber[0]
            : observation.partnerNumber;
          sensorBaseUrl = `/${partnerNumber}/FunctionalLocation/${flBuilding}/BMSAnalytics/AnalyticsChart?openBmsSensorId=${observation.sensorid}`;
        }
      }

      newItems.push({
        label: t('Sensor ID'),
        value: t(observation.sensorid),
        isLink: isLink,
        linkAddress: sensorBaseUrl,
      });
    }
    if (observation.date && observation.modifiedOn) {
      newItems.push(
        {
          label: t('Created'),
          value: handleDates(observation?.date),
        },
        {
          label: t('Modified'),
          value: handleDates(observation?.modifiedOn),
        }
      );
    }
    setItems(newItems);
  }, [t, observation, profile]);

  return (
    <CardContainer>
      <KeyCards items={items} margin={false} capitalize={true} />
    </CardContainer>
  );
};

ObservationModalTop.propTypes = {
  t: PropTypes.func.isRequired,
  observation: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      modifiedOn: PropTypes.object,
      date: PropTypes.object,
      disciplineValue: PropTypes.string,
      sensorid: PropTypes.string,
      status: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  ]),
  profile: PropTypes.object,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
});

export const ObservationModalTopWithRouter = withRouter(ObservationModalTop);

const connector = connect(mapStateToProps);

export default withRouter(connector(ObservationModalTop));
