import { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import { PassThroughOutsideClickHandler } from 'components/Dialog/PassThroughOutsideClickHandler';

import SimpleDropdown, { Caret } from 'components/SimpleDropdown/SimpleDropdown';
import Svg from 'components/Svg/Svg';

export const OptionsButton = styled.div`
  padding: 0;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--size-xl);
  height: var(--size-xl);
  border-radius: var(--circle);
  color: var(--table-row-options-fg);
  font-size: ${props => props.theme.fontSize.md};

  ${props => (props.menuOpen ? 'background-color: var(--table-row-options-bg-hl);' : '')}

  &:hover {
    background-color: var(--table-row-options-bg-hl);
  }
`;

const Ref = styled.div`
  display: inline-block;
`;
Ref.displayName = 'Ref';

const DefaultButton = props => (
  <OptionsButton {...props}>
    <Svg name="fa-ellipsis-h" />
  </OptionsButton>
);
DefaultButton.displayName = 'DefaultButton';

const CellPopper = ({ children, ButtonComponent = DefaultButton }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [caretElement, setCaretElement] = useState(null);

  const onClickMenuToggle = useCallback(
    event => {
      setMenuOpen(!menuOpen);
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [menuOpen]
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [{ name: 'arrow', options: { element: caretElement } }],
  });

  return (
    <>
      <Ref ref={setReferenceElement}>
        <ButtonComponent onClick={onClickMenuToggle} menuOpen={menuOpen} />
      </Ref>
      {menuOpen &&
        ReactDOM.createPortal(
          <PassThroughOutsideClickHandler onOutsideClick={() => setMenuOpen(false)}>
            <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
              <SimpleDropdown disableCaret>{children}</SimpleDropdown>
              <Caret
                ref={setCaretElement}
                top={attributes.popper?.['data-popper-placement'] === 'top'}
                style={styles.arrow}
              />
            </div>
          </PassThroughOutsideClickHandler>,
          document.body
        )}
    </>
  );
};

CellPopper.propTypes = {
  children: PropTypes.node,
  ButtonComponent: PropTypes.func,
};

export default CellPopper;
