import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const StyledSimpleDropdown = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-radius: var(--radius-sm);
  box-shadow: 0 2px 10px ${props => transparentize(0.9, props.theme.colors.black)};
`;

type ContentProps = {
  width?: string;
};

export const Content = styled.div<ContentProps>`
  align-items: flex-end;
  flex-direction: column;
  max-height: 25em;
  width: ${props => props.width};
  overflow-y: auto;
`;

type CaretProps = {
  top?: boolean;
  left?: boolean;
  mobileLeft?: boolean;
};

export const Caret = styled.div<CaretProps>`
  position: absolute;
  width: 0;
  height: 0;
  top: ${props => !props.top && '-10px'};
  bottom: ${props => props.top && '-10px'};

  right: ${props => (!props.mobileLeft ? 0 : 'auto')};
  left: ${props => (props.mobileLeft ? 0 : 'auto')};

  ${props => props.theme.media.portrait`
    right: ${() => (!props.left ? 'var(--size-xs)' : 'auto')};
    left: ${() => (props.left ? 'var(--size-xs)' : 'auto')};
  `}

  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: ${props => !props.top && `10px solid ${props.theme.colors.lightGray}`};
  border-top: ${props => props.top && `10px solid ${props.theme.colors.lightGray}`};

  &::after {
    content: '';
    position: absolute;
    top: ${props => !props.top && '1px'};
    bottom: ${props => props.top && '1px'};
    left: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: ${props => !props.top && `10px solid ${props.theme.colors.white}`};
    border-top: ${props => props.top && `10px solid ${props.theme.colors.white}`};
  }
`;

type SimpleDropdownProps = PropsWithChildren<{ disableCaret?: boolean } & CaretProps & ContentProps>;

const SimpleDropdown = ({ children, disableCaret, ...props }: SimpleDropdownProps) => (
  <StyledSimpleDropdown {...props}>
    <Content {...props}>{children}</Content>
    {!disableCaret && <Caret {...props} />}
  </StyledSimpleDropdown>
);

export default SimpleDropdown;
