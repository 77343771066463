import { MouseEvent } from 'react';

import { TristateIcon } from 'components/Form/TristateIcon';

import { CheckIcon } from './CheckIcon';

import * as checkboxStyles from './SmallDummyCheckbox.module.scss';

export type CheckBoxSquareProps = {
  indeterminate?: boolean;
  checked?: boolean;
  disabled?: boolean;
  title?: string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};

/**
 * @implements {UIv3}
 */
export function SmallDummyCheckbox({ checked, indeterminate, disabled, title, onClick }: CheckBoxSquareProps) {
  const checkboxSquareClasses = [
    checkboxStyles.checkboxSquare,
    checked && checkboxStyles.checked,
    indeterminate && checkboxStyles.indeterminate,
    disabled && checkboxStyles.disabled,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={checkboxSquareClasses} title={title} onClick={onClick}>
      {indeterminate ? (
        <TristateIcon colorCss={disabled ? 'var(--checkbox-fg-disabled)' : 'var(--checkbox-fg-active)'} pixelSize={9} />
      ) : checked ? (
        <CheckIcon colorCss={disabled ? 'var(--checkbox-fg-disabled)' : 'var(--checkbox-fg-active)'} pixelSize={8} />
      ) : null}
    </div>
  );
}
