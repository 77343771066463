import styled, { css } from 'styled-components';

import { BaseButton } from 'components/Button/BaseButton/BaseButton';
import { IconContainer } from 'components/Button/ButtonIcon/ButtonIcon';
import SecondaryButton from 'components/Button/SecondaryButton';

import { foldPages } from './foldPages';

export const PaginationPageSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--size-xxxs);

  @media print {
    display: none;
  }
`;

export type PaginationPageButtonProps = {
  selected: boolean;
};

export const PaginationPageButton = styled(BaseButton)<PaginationPageButtonProps>`
  padding: var(--size-xxs-plus);
  height: 2rem;
  min-width: 2rem;
  ${props =>
    props.selected &&
    css`
      background-color: var(--pagination-btn-bg-selected);
      color: var(--pagination-btn-fg-selected);
    `}
`;

export type PaginationStepButtonProps = {
  enabled: boolean;
  tiny?: boolean;
};

export const PaginationStepButton = styled(SecondaryButton)<PaginationStepButtonProps>`
  svg {
    font-size: ${props => (props.tiny ? '0.35rem' : '0.5625rem')};
  }

  ${props =>
    !props.enabled &&
    css`
      border-color: var(--btn-naked-fg-disabled);
      cursor: default;
      opacity: 0.5;
    `}

  ${props =>
    props.tiny &&
    css`
      width: 1.5rem;
      height: 1.5rem;
    `}

  ${IconContainer} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PaginationEllipsis = styled.span.attrs(() => ({ children: '\u22ef' }))`
  width: 2rem;
  pointer-events: none;
  text-align: center;
`;

export type PaginationPageSelectorProps = {
  selectedPage: number;
  totalPages: number;
  maxVisiblePages: number;
  onChange?: (newPageNumber: number) => void;
};

/**
 * @param selectedPage Currently selected page.
 * @param totalPages This works best if at least 7, then the middle part, if split three-way, e.g.
 *   "1 ... 4 5 6 ... 9", has the previous and the following page numbers.
 * @param maxVisiblePages The width, in page buttons. The ellipsis markers are same width and consume
 *   space exactly like the page buttons.
 * @param onChange Called when page is changed by the user.
 * @constructor
 */
export function PaginationPageSelector({
  selectedPage,
  totalPages,
  maxVisiblePages = 6,
  onChange,
}: PaginationPageSelectorProps): JSX.Element {
  const { left, leftEllipsis, mid, rightEllipsis, right } = foldPages(selectedPage, totalPages, maxVisiblePages);

  const previousButton = (
    <PaginationStepButton
      enabled={selectedPage > 1}
      onClick={() => onChange?.(Math.max(selectedPage - 1, 1))}
      iconName="caret-left"
      loading={false}
      children={null}
    />
  );

  const nextButton = (
    <PaginationStepButton
      enabled={selectedPage < totalPages}
      onClick={() => onChange?.(Math.min(selectedPage + 1, totalPages))}
      iconName="caret-right"
      loading={false}
      children={null}
    />
  );

  const leftButtons = left?.map(pageNumber => (
    <PaginationPageButton
      key={String(pageNumber)}
      selected={pageNumber === selectedPage}
      onClick={() => onChange?.(pageNumber)}
    >
      {pageNumber}
    </PaginationPageButton>
  ));

  const midButtons = mid?.map(pageNumber => (
    <PaginationPageButton
      key={String(pageNumber)}
      selected={pageNumber === selectedPage}
      onClick={() => onChange?.(pageNumber)}
    >
      {pageNumber}
    </PaginationPageButton>
  ));

  const rightButtons = right?.map(pageNumber => (
    <PaginationPageButton
      key={String(pageNumber)}
      selected={pageNumber === selectedPage}
      onClick={() => onChange?.(pageNumber)}
    >
      {pageNumber}
    </PaginationPageButton>
  ));

  return (
    <PaginationPageSelectorContainer>
      <PaginationPageSelectorContainer>
        {previousButton}
        {leftButtons || midButtons}
        {leftEllipsis && <PaginationEllipsis />}
      </PaginationPageSelectorContainer>

      {midButtons && leftButtons && <PaginationPageSelectorContainer>{midButtons}</PaginationPageSelectorContainer>}

      <PaginationPageSelectorContainer>
        {rightEllipsis && <PaginationEllipsis />}
        {rightButtons}
        {nextButton}
      </PaginationPageSelectorContainer>
    </PaginationPageSelectorContainer>
  );
}
