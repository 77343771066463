import { Component } from 'react';
import PropTypes from 'prop-types';

import InputSelectDropdown from './InputSelectDropdown';

const TICKER_TIMEOUT = 500;
const MIN_LENGTH_TO_SEARCH = 3;

class InputSearchDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ticker: null,
      value: props.initialValue || '',
    };
  }

  handleChange(value) {
    this.setState(prevState => {
      if (prevState.ticker !== null) {
        clearTimeout(prevState.ticker);
      }

      let ticker;

      if (typeof value === 'string' && value.length >= MIN_LENGTH_TO_SEARCH) {
        ticker = setTimeout(() => this.props.onSearch(value), TICKER_TIMEOUT);
      } else {
        ticker = null;
      }

      return {
        ticker: ticker,
        value: value,
      };
    });
  }

  render() {
    return (
      <InputSelectDropdown
        {...this.props}
        onInput={value => this.handleChange(value)}
        noResultsText={
          this.props.noResultsText || this.props.t('No results found. Change search keyword or input more characters.')
        }
        placeholder={this.props.placeholder || `${this.props.t('Select or search')}...`}
      />
    );
  }
}

InputSearchDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  noResultsText: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  onSearch: PropTypes.func,
};

export default InputSearchDropdown;
