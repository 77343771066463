import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const getInfotipPositioning = (position, giosg, isMobile) => {
  if (giosg) {
    return css`
      top: -25%;
      left: ${isMobile ? '-80%' : '-50%'};
      transform: translate(-50%, -100%);
    `;
  }
  switch (position) {
    case 'top':
      return css`
        top: -25%;
        left: 50%;
        transform: translate(-50%, -100%);
      `;
    case 'right':
      return css`
        top: 50%;
        left: 125%;
        transform: translate(0, -50%);
      `;
    case 'bottom':
      return css`
        top: 125%;
        left: 50%;
        transform: translate(-50%, 0%);
      `;
    case 'left':
      return css`
        top: 50%;
        left: -25%;
        transform: translate(-100%, -50%);
      `;
    default:
      break;
  }
};

const getTrianglePositioning = (position, backgroundColor, giosg, isMobile) => {
  if (giosg) {
    const left = isMobile ? '85%' : '80%';
    return css`
      border-left: 0.5em solid transparent;
      border-bottom: 0.5em solid transparent;
      border-top: 0.5em solid ${backgroundColor};
      border-right: 0.5em solid transparent;
      bottom: -0.95em;
      left: ${left};
    `;
  }
  switch (position) {
    case 'top':
      return css`
        border-left: 0.5em solid transparent;
        border-bottom: 0.5em solid transparent;
        border-top: 0.5em solid ${backgroundColor};
        border-right: 0.5em solid transparent;
        bottom: -0.95em;
        left: 50%;
      `;
    case 'right':
      return css`
        border-left: 0.5em solid transparent;
        border-bottom: 0.5em solid transparent;
        border-top: 0.5em solid transparent;
        border-right: 0.5em solid ${backgroundColor};
        left: -0.45em;
        top: calc(50% - 0.5em);
      `;
    case 'bottom':
      return css`
        border-left: 0.5em solid transparent;
        border-bottom: 0.5em solid ${backgroundColor};
        border-top: 0.5em solid transparent;
        border-right: 0.5em solid transparent;
        top: -0.95em;
        left: 50%;
      `;
    case 'left':
      return css`
        border-left: 0.5em solid ${backgroundColor};
        border-bottom: 0.5em solid transparent;
        border-top: 0.5em solid transparent;
        border-right: 0.5em solid transparent;
        right: -0.95em;
        top: calc(50% - 0.5em);
      `;
    default:
      break;
  }
};

export const StyledInfotip = styled.div`
  position: ${props => (props.absolute ? 'absolute' : 'relative')};
  display: inline;
  cursor: text;
  ${props => props.absolute && 'width: 100%;'};
  ${props => props.absolute && 'height: 100%;'};
  ${props => props.absolute && 'cursor: pointer;'};
  ${props => props.tagWidth && `width: ${props.tagWidth}`};
`;
StyledInfotip.displayName = 'StyledInfotip';

export const InfotipContentWrapper = styled.div`
  position: absolute;
  ${props => getInfotipPositioning(props.position, props.giosg, props.isMobile)};
  z-index: ${props => props.theme.zIndex('infoTip')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  transition: visibility 0s 0.15s;
  ${props => props.show && 'transition-delay: 0.3s;'};
`;

/**
 * This component can be used independently and should not affect positioning
 */
export const InfotipContent = styled.div`
  position: relative;
  border-radius: 5px;
  white-space: pre;
  background-color: var(--bubble-bg);
  box-shadow: var(--box-shadow-bigger-strong);
  padding: ${props => (props.isComponent ? '0.5em' : '1em')};
  color: var(--bubble-fg);
  text-align: ${props => props.textAlign};
  opacity: ${props => (props.show ? '1' : '0')};
  transition: opacity 0.15s ease-in;
  ${props => props.show && 'transition-delay: 0.3s;'};

  &::before {
    content: '';
    position: absolute;
    margin-left: -0.5em;
    z-index: ${props => props.theme.zIndex('infoTip') + 1};
    line-height: 0;
    ${props => getTrianglePositioning(props.position, 'var(--bubble-bg)', props.giosg, props.isMobile)}
  }
`;
InfotipContent.displayName = 'InfotipContent';

const StyledInfotext = styled.div`
  overflow: hidden;
`;
StyledInfotext.displayName = 'StyledInfotext';

export default class Infotip extends PureComponent {
  state = { isVisible: false };

  showTip = event => {
    event.preventDefault();
    this.setState({ isVisible: true });
  };

  hideTip = event => {
    event.preventDefault();
    this.setState({ isVisible: false });
  };

  render() {
    const textIsComponent = !Array.isArray(this.props.text) && typeof this.props.text !== 'string';
    const isMobile = window.innerWidth < 900;
    const show = (this.state.isVisible || this.props.alwaysVisible) && !this.props.hide;
    return (
      <StyledInfotip
        position={this.props.position}
        type={this.props.type}
        onMouseEnter={event => this.showTip(event)}
        onMouseLeave={event => this.hideTip(event)}
        className={this.props.className}
        absolute={this.props.absolute}
        tagWidth={this.props.tagWidth}
      >
        {this.props.children}
        <InfotipContentWrapper isMobile={isMobile} giosg={this.props.giosg} show={show} position={this.props.position}>
          <InfotipContent
            isMobile={isMobile}
            giosg={this.props.giosg}
            textAlign={this.props.textAlign}
            isComponent={textIsComponent}
            position={this.props.position}
            show={show}
          >
            <StyledInfotext>
              {Array.isArray(this.props.text)
                ? this.props.text.map((line, index) => line + (index < this.props.text.length - 1 ? '\n' : ''))
                : this.props.text}
            </StyledInfotext>
          </InfotipContent>
        </InfotipContentWrapper>
      </StyledInfotip>
    );
  }
}

Infotip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  type: PropTypes.any,
  className: PropTypes.string,
  alwaysVisible: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify', 'initial', 'inherit']),
  absolute: PropTypes.bool,
  giosg: PropTypes.bool,
  hide: PropTypes.bool,
  children: PropTypes.node,
  tagWidth: PropTypes.string,
};

Infotip.defaultProps = {
  position: 'top',
  textAlign: 'center',
  alwaysVisible: false,
  className: '',
  type: '',
  giosg: false,
};
