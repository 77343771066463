import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SkeletonText from 'components/Skeletons/SkeletonText';
import InfoTable from 'components/InfoTable/InfoTable';
import { ContentSection, Subheader, Description } from './common';

import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import { additionalSalePropType } from './utils';
import AdditionalSaleChat from './AdditionalSaleChat';

const ThumbnailContainer = styled.div`
  display: inline-block;
  margin: 0 var(--size-sm) var(--size-sm) 0;
  border: 1px solid ${props => props.theme.colors.lightGray};
  background-color: ${props => props.theme.colors.alabaster};
`;

const Thumbnail = styled.img`
  width: 64px;
  height: 64px;
  box-sizing: content-box;
  cursor: pointer;
  border: 10px solid ${props => props.theme.colors.alabaster};
`;

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const openAttachment = url => window.open(url, '_blank', 'noopener,noreferrer');

const AdditionalSaleInfo = ({ additionalSale = EMPTY_OBJECT, loading }) => {
  const { t } = useUnauthenticatedTranslations();

  const infoTableItems = useMemo(
    () => [
      { label: t('Customer'), value: additionalSale.customerName },
      { label: t('Address'), value: `${additionalSale.customerAddress}, ${additionalSale.customerCity}` },
      { label: t('Technical Category'), value: additionalSale.technicalCategory },
      { label: t('Observation Category'), value: additionalSale.observationCategory },
      { label: t('Priority'), value: additionalSale.priority },
    ],
    [t, additionalSale]
  );

  return (
    <>
      {loading ? (
        <SkeletonText />
      ) : (
        <>
          <ContentSection>
            <Subheader>{t(`Technician's observations`)}</Subheader>
            <Description>{additionalSale.description}</Description>
            <Description>
              {additionalSale.value === 'big'
                ? t(
                    'Our tehnician estimates that fixing this can cost more than the jobs that are usually carried out as an additional work based on agreed hourly rates.'
                  )
                : t(
                    'Our tehnician estimates that fixing this is a relatively small job and could be carried out as an additional work based on agreed hourly rates.'
                  )}
            </Description>
          </ContentSection>
          {additionalSale.comments?.length ? (
            <ContentSection>
              <Subheader>{t('Timeline & Discussion')}</Subheader>
              <AdditionalSaleChat additionalSale={additionalSale} />
            </ContentSection>
          ) : null}
          <ContentSection>
            <Subheader>{t('Details')}</Subheader>
            <InfoTable loading={loading} items={loading ? EMPTY_ARRAY : infoTableItems} />
          </ContentSection>
          {additionalSale.images?.length ? (
            <ContentSection>
              <Subheader>{t('Attachments')}</Subheader>
              {additionalSale.images?.map(({ url }, i) => (
                <ThumbnailContainer id={i} key={i}>
                  <Thumbnail src={url} onClick={() => openAttachment(url)} />
                </ThumbnailContainer>
              ))}
            </ContentSection>
          ) : null}
        </>
      )}
    </>
  );
};

AdditionalSaleInfo.propTypes = {
  additionalSale: additionalSalePropType,
  loading: PropTypes.bool,
};

export default AdditionalSaleInfo;
