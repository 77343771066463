import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslations } from 'decorators/Translations/translations';

const Wrapper = styled.div`
  display: flex;
  gap: var(--size-xxs);
  margin-bottom: 8px;
  flex-wrap: wrap;

  ${props => props.theme.media.tablet`
    gap: 0;
    margin: 0;
    flex-wrap: nowrap;
    overflow-x: scroll;
  `}
`;

export const Tab = styled.button.attrs(props => ({ 'data-selected': props.selected }))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  font-weight: ${props => props.theme.font.weight.semibold};
  color: ${props =>
    props.selected ? 'var(--tab-fg-selected)' : props.disabled ? 'var(--tab-fg-disabled)' : 'var(--tab-fg)'};
  background-color: ${props => (props.selected ? 'var(--tab-bg-selected)' : 'var(--tab-bg)')};
  border: 1px solid transparent;

  /* mobile styles */
  border-radius: 2px;
  border-color: ${props => !props.selected && props.theme.colors.silver};
  font-size: ${props => props.theme.font.size.xxs};
  padding: var(--size-xs) var(--size-sm);

  /* tablet and desktop styles */
  ${props => props.theme.media.tablet`
    border-radius: 0;
    border: 0;
    font-size: ${props => props.theme.font.size.xs};
    padding: var(--size-sm) 0;
  `}

  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    text-decoration: ${props => !props.disabled && !props.selected && 'underline'};
  }

  &:focus-visible {
    outline: ${props => props.selected && 'thin dotted'};
    outline-offset: ${props => (props.selected ? '-5px' : '-2px')};
  }

  span {
    line-height: ${props => props.theme.font.lineHeight.lg};
    ${props => props.theme.media.tablet`
      padding: 0 var(--size-lg);
      border-left: var(--border-semibold) solid transparent;
    `}
  }

  /* Separators should be only shown between two, adjacent, and non-selected tabs */
  &[data-selected='false'] + &[data-selected='false'] {
    span {
      border-left: var(--border-semibold) solid var(--tab-separator-color);
    }
  }
`;

const TabList = ({ tabs, setSelectedTab, selectedTab }) => {
  const [t] = useTranslations();

  if (!tabs || tabs.length === 0) return null;
  const isIndex = Number.isFinite(selectedTab);

  return (
    <Wrapper>
      {tabs.map((tab, index) => {
        const selectedTabId = isIndex ? index : tab.id;
        return (
          <Tab
            key={tab.id}
            onClick={tab.disabled ? undefined : () => setSelectedTab(selectedTabId)}
            selected={selectedTabId === selectedTab}
            disabled={tab.disabled}
          >
            <span>{t(tab.title)}</span>
          </Tab>
        );
      })}
    </Wrapper>
  );
};

TabList.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

export default TabList;
