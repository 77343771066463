import Anonymous from 'containers/Application/Anonymous/Anonymous';
import AnonymousHeader from 'containers/Application/Anonymous/AnonymousHeader';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import TechniciansViewContainer from './TechniciansView/TechniciansViewContainer';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import useQuery from 'decorators/Query/useQuery';

export const Technician = () => {
  const { query } = useQuery();
  const { t, loadingTranslations, selectedLanguage, setSelectedLanguage } = useUnauthenticatedTranslations();

  return (
    <Anonymous title={t('Technician')} isAnonymousArticle={false}>
      <AnonymousHeader>
        <LanguageSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
      </AnonymousHeader>
      <TechniciansViewContainer
        loadingTranslations={loadingTranslations}
        token={query.token}
        language={selectedLanguage}
      />
    </Anonymous>
  );
};

export default Technician;
