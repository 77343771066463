import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';

import InputText from 'components/Form/InputText';
import PhotoButton from './PhotoButton';
import FileList from 'components/Documents/FileList/FileList';
import Options from './Options';

const ItemContainer = styled.div`
  background: ${props => props.theme.colors.white};
  padding: var(--size-lg);
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  border: ${props => props.isErrored && `1px solid ${props.theme.colors.radicalRed}`};
  transition:
    background,
    height ${props => props.theme.motion.easeIn} ${props => props.theme.motion.quick};
`;

const ItemTitle = styled.p`
  margin-bottom: var(--size-md);
`;

const TextRow = styled.div`
  display: flex;
  margin: var(--size-xs) 0;
  flex-direction: row;
  align-items: center;
`;

const TextInputWrapper = styled.div`
  flex-grow: 1;
`;

const PhotoButtonWrapper = styled.div`
  margin-bottom: 0;
  margin-right: var(--size-xs);

  @media print {
    display: none;
  }
`;

const ErrorText = styled.p`
  white-space: pre-wrap;
  padding: var(--size-xs) var(--size-xxs);
  color: ${props => props.theme.colors.radicalRed};
`;

const ItemForm = ({
  t,
  onChange,
  onFileDownload,
  onFileDelete,
  itemModel = {},
  itemFiles = [],
  templateItem,
  templateOptions,
  disabled,
}) => {
  const [fileErrors, setFileErrors] = useState([]);

  const handleButtonClick = optionId => {
    if (optionId === itemModel.selectedChecklistTemplateOptionId) {
      onChange(`[${templateItem.id}].selectedChecklistTemplateOptionId`, null);
    } else {
      onChange(`[${templateItem.id}].selectedChecklistTemplateOptionId`, optionId);
    }
  };

  const handleAddFile = useCallback(
    file => onChange(`[${templateItem.id}].files`, (itemModel.files || []).concat([file])),
    [onChange, templateItem, itemModel]
  );

  const handleRemoveFile = useCallback(
    file =>
      onChange(
        `[${templateItem.id}].files`,
        (itemModel.files || []).filter(oldFile => oldFile !== file)
      ),
    [onChange, templateItem, itemModel]
  );

  const errorMessage = [
    ...fileErrors,
    ...(itemModel.erroredFiles?.map(fileError => `${t('Upload failed')}: ${fileError.message}`) || []),
  ].join('\n');

  return (
    <ItemContainer
      isAnswered={Boolean(itemModel.selectedChecklistTemplateOptionId)}
      isErrored={itemModel.erroredFiles?.length > 0}
    >
      <ItemTitle>
        {templateItem.ordinal}. {templateItem.title}
      </ItemTitle>
      <Options
        options={templateOptions}
        selectedOption={itemModel.selectedChecklistTemplateOptionId}
        onSelect={disabled ? undefined : handleButtonClick}
      />
      {(itemModel.selectedChecklistTemplateOptionId || itemModel.id) && (
        <TextRow>
          <PhotoButtonWrapper>
            <PhotoButton onFileUpload={handleAddFile} setFileErrors={setFileErrors} t={t} />
          </PhotoButtonWrapper>
          <TextInputWrapper>
            <InputText
              property={`[${templateItem.id}].freeText`}
              value={itemModel.freeText}
              placeholder={`${t('Type a comment')}...`}
              onChange={onChange}
            />
          </TextInputWrapper>
        </TextRow>
      )}
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      <FileList files={itemFiles} onDownload={onFileDownload} onDelete={onFileDelete} showThumbnails />
      <FileList files={itemModel.files} onDelete={handleRemoveFile} showThumbnails />
    </ItemContainer>
  );
};

ItemForm.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFileDownload: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func,
  itemModel: PropTypes.object,
  itemFiles: PropTypes.array,
  templateItem: PropTypes.object.isRequired,
  templateOptions: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default translations(ItemForm);
