import styled from 'styled-components';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';

import Dialog from 'components/Dialog/Dialog';
import DialogFrame from 'components/Dialog/DialogFrame';
import EnergyChart from './EnergyChart';

const MIN_HEIGHT = 475;

const DialogContent = styled.div`
  position: relative;
  ${props =>
    !props.fullScreen &&
    props.theme.media.landscape`
    width: 95vw;
    max-height: 85vh;
    max-width: ${props => props.theme.grid.maxWidth};
    min-height: ${MIN_HEIGHT};
  `};

  @media print {
    width: auto;
    max-height: none;
    max-width: none;
  }
`;

export const EnergyModal = ({ t, onClose, functionalLocations }) => {
  return (
    <Dialog animate isActive onOverlayClick={onClose} t={t}>
      <DialogFrame hasTopStripe={false} onClose={onClose} smallVerticalPadding>
        {fullScreen => (
          <DialogContent fullScreen={fullScreen}>
            <EnergyChart functionalLocations={functionalLocations} />
          </DialogContent>
        )}
      </DialogFrame>
    </Dialog>
  );
};

EnergyModal.defaultProps = {
  functionalLocations: [],
};

EnergyModal.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  functionalLocations: PropTypes.array,
};
export default translations(EnergyModal);
