import { useTranslations } from 'decorators/Translations/translations';

import { components, GroupBase, MultiValueProps } from 'react-select';
import { SelectFilterOptionType } from './SelectFilter';

export function MultiValue<
  Option extends SelectFilterOptionType,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(props: MultiValueProps<Option, IsMulti, Group>) {
  const [t] = useTranslations();

  const currentValues = props.getValue();
  const hasOneSelectedValue = currentValues.length === 1;
  const title = hasOneSelectedValue ? currentValues[0].label : t(`${currentValues.length} selected`);

  if (props.index === 0) {
    return <components.MultiValue {...props}>{currentValues.length > 0 && title}</components.MultiValue>;
  }
  return null;
}
