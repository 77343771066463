import { useState } from 'react';
import PropTypes from 'prop-types';
import DialogModal from 'components/Dialog/DialogModal';
import translations from 'decorators/Translations/translations';
import BasicColumnList from './BasicColumnList';
import ReorderableColumnList from './ReorderableColumnList';
import { useTranslations } from 'decorators/Translations/translations';
import { ContentType } from 'constants/maintenance';

const TableConfigurationModal = ({
  configurationOptions,
  configuration,
  onSubmit,
  onClose,
  hiddenColumns = [],
  canReorderColumns = false,
  contentType = null,
}) => {
  const [t] = useTranslations();

  const initializeModel = () => {
    const entries = {};
    let orderIndex = 0;

    configurationOptions.forEach((option, index) => {
      const isSelected = configuration.includes(option.accessor);
      entries[option.accessor] = {
        label: option.label || option.Header || option.title,
        selected: isSelected,
        hide: !option.label && !option.Header && !option.title,
        disabled: option.accessor === 'title' || option.locked,
        order: !canReorderColumns ? index : isSelected ? orderIndex++ : null,
      };
    });

    if (canReorderColumns) {
      configurationOptions.forEach(option => {
        if (!configuration.includes(option.accessor)) {
          entries[option.accessor].order = orderIndex++;
        }
      });
    }

    return entries;
  };

  const [model, setModel] = useState(initializeModel);
  const [loading, setLoading] = useState(false);

  const handleReorder = newOrder => {
    setModel(prev => {
      const updated = { ...prev };
      newOrder.forEach((field, index) => {
        updated[field] = { ...updated[field], order: index };
      });
      return updated;
    });
  };

  const handleCheckbox = (property, value) => {
    setModel(prev => ({
      ...prev,
      [property]: { ...prev[property], selected: value },
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(model);
    setLoading(false);
    onClose();
  };

  const columns = Object.entries(model)
    .sort(([_, a], [__, b]) => a.order - b.order)
    .filter(([field, config]) => !config.hide && !hiddenColumns.includes(field));

  return (
    <DialogModal
      small
      isActive
      onOverlayClick={onClose}
      t={t}
      actions={{
        cancel: { onClick: onClose },
        confirm: { onClick: handleSubmit, loading },
      }}
    >
      <section>
        {canReorderColumns ? (
          <ReorderableColumnList
            t={t}
            columns={columns}
            onCheckbox={handleCheckbox}
            onReorder={handleReorder}
            contentType={contentType}
          />
        ) : (
          <BasicColumnList t={t} columns={columns} onCheckbox={handleCheckbox} contentType={contentType} />
        )}
      </section>
    </DialogModal>
  );
};

TableConfigurationModal.propTypes = {
  configurationOptions: PropTypes.array.isRequired,
  configuration: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  // these columns will be hidden from configuration list
  // hiding won't remove column from the saved list configuration array
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  canReorderColumns: PropTypes.bool,
  contentType: PropTypes.oneOf(Object.values(ContentType)),
};

export default translations(TableConfigurationModal);
