import ApiRequest from './ApiRequest';
import memoizeOne from 'memoize-one';

/**
 * Deprecated.
 *
 * No need to use thunk for requests anymore, just inport ApiRequest directly in your service
 */
export const withThunkRequest =
  service =>
  (...serviceCallArgs) =>
  (dispatch, getState, thunkDependencies) =>
    service(thunkRequest(dispatch, getState, thunkDependencies), ...serviceCallArgs);

/**
 * Deprecated.
 *
 * No need to use thunk for requests anymore, just inport ApiRequest directly in your service
 */
export const wrapEachWithThunkRequest = services =>
  Object.keys(services).reduce(
    (accumulator, serviceName) => ({
      ...accumulator,
      [serviceName]: withThunkRequest(services[serviceName]),
    }),
    {}
  );

/**
 * Deprecated.
 *
 * No need to use thunk for requests anymore, just inport ApiRequest directly in your service
 */
export const thunkRequest = memoizeOne(() => {
  return ApiRequest;
});
