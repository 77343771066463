import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { validateModel } from '../utils/validation';

const useFormValidation = (model, formSchemas) => {
  const [validationErrors, setValidationErrors] = useState({});

  const validateForms = async formKeys => {
    const schemas = formKeys ? formKeys.map(key => formSchemas[key]).filter(Boolean) : Object.values(formSchemas);
    const errors = await validateModel(model, schemas);

    if (!isEmpty(errors)) {
      setValidationErrors(errors);
      return false;
    }

    // clear old error messages
    if (!isEmpty(validationErrors)) {
      setValidationErrors({});
    }
    return true;
  };

  return [validationErrors, validateForms];
};

export default useFormValidation;
