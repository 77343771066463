import { MutableRefObject } from 'react';
import { RowData } from '@tanstack/react-table';

import { smallestAvailableDefaultPageSize } from 'components/Pagination/PaginationPageSizeSelector';
import { QueryConnectedPagination } from 'components/Pagination/QueryConnectedPagination';
import { FlexGroup } from 'components/Layout/FlexGroup';

import { GenericWindowVirtualizedTable, GenericWindowVirtualizedTableProps } from './GenericWindowVirtualizedTable';
import { DefaultTableStyleProvider } from './DefaultTableStyleProvider';
import { TableWindowVirtualizer } from './useVirtualizedTable';
import { RichTableProps } from './RichTable';
import { scrollWindowVirtualizerToTop } from './scrollWindowVirtualizerToTop';

export type RichWindowVirtualizedTableProps<TData extends RowData> = Omit<
  RichTableProps<TData>,
  'genericTableProps'
> & {
  containerRef: MutableRefObject<HTMLDivElement | null>;
  genericTableProps?: Omit<GenericWindowVirtualizedTableProps<TData>, 'table' | 'virtualizer'>;
  virtualizer: TableWindowVirtualizer;
};

/**
 * Virtualized table with the following features:
 *
 * <ul>
 *   <li>Pagination
 *     <p>Pagination is visible, if there are more items than fits on a single
 *     page of the smallest available size (50).
 *   <li>Horizontal scrolling
 *     <p>For horizontal scrolling to work, enclose this component to e.g. {@link RestrictWidth}.
 *   <li>Optional column resizing
 *   <li>Print awareness
 *     <p>In printing mode the pagination is disabled and all rows are rendered.
 * </ul>
 *
 * This is not intended to be used directly, but in specific table components.
 *
 * Rendering hierarchy:
 *
 * ```
 *   <div {...containerProps}>
 *     <GenericWindowVirtualizedTable {...tableContainerProps} />
 *     <QueryConnectedPagination {...paginationProps} />
 *   </div>
 * ```
 *
 * @implements {UIv3}
 *
 * @param table As output by e.g. {@link useVirtualizedTable}.
 * @param virtualizer As output by e.g. {@link useVirtualizedTable}.
 * @param containerRef Use this to configure the virtualizer, see {@link GenericWindowVirtualizedTable}.
 * @param scrollTargetRef Use this as the target element to scroll to after page change.
 *   The responsibility is on the calling side, since scrolling only works well if the layout
 *   has settled, and it can depend on the availability of data.
 * @param containerProps Any props to pass to the enclosing `div`, e.g. styling.
 * @param paginationProps Any props to pass to the {@link QueryConnectedPagination}, e.g. query parameter names.
 *   For controlled/fetching pagination add at least field `paginationProps.totalItems`.
 * @param genericTableProps Any props to pass to `<GenericWindowVirtualizedTable>`, e.g. cell or row renderers.
 * @param StyleProvider CSS style provider for styling HTML table, thead, tbody, tr, th and td elements for this table.
 */
export function RichWindowVirtualizedTable<TData extends RowData>({
  table,
  virtualizer,
  containerRef,
  scrollTargetRef,
  containerProps,
  paginationProps,
  genericTableProps,
  StyleProvider = DefaultTableStyleProvider,
}: RichWindowVirtualizedTableProps<TData>) {
  const pageChangeListener = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView();
    } else {
      scrollWindowVirtualizerToTop(virtualizer);
    }
  };

  let smallestAvailablePageSize = paginationProps?.paginationRowCountOptions?.[0]?.value;
  smallestAvailablePageSize ??= smallestAvailableDefaultPageSize;

  return (
    <div ref={scrollTargetRef}>
      <FlexGroup {...containerProps}>
        <StyleProvider style={{ maxWidth: '100%' }}>
          <GenericWindowVirtualizedTable
            containerRef={containerRef}
            table={table}
            virtualizer={virtualizer}
            {...genericTableProps}
          />
        </StyleProvider>

        {(paginationProps?.totalItems ?? table.getCoreRowModel().rows.length) > smallestAvailablePageSize && (
          <QueryConnectedPagination
            totalItems={table.options.data.length}
            onPageChange={pageChangeListener}
            {...paginationProps}
          />
        )}
      </FlexGroup>
    </div>
  );
}
