import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import orderBy from 'lodash/orderBy';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { ConfigCategory, ConfigKey } from 'types/Division';

import MasterDataService from 'services/masterData';
import {
  InputRow,
  InputLabel,
  InputText,
  InputTextArea,
  InputForm,
  ErrorText,
  InputSelectDropdown,
  Infotip,
} from 'components/index';
import { loadFunctionalLocationsForOverview, loadFunctionalLocations } from 'redux/modules';
import { getParentFunctionLocations, functionalLocationName } from 'utils/Data/functionalLocations';
import Svg from 'components/Svg/Svg';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import FileUploadForm from 'components/FileUploadForm/FileUploadForm';
import PrimaryButton from 'components/Button/PrimaryButton';
import { getClassificationCodeLabel } from '../LongTermPlan/LongTermPlanForm/forms/CategoryInput';
import { getPartnerDivision } from 'utils/Data/partners';
import { SERVICE_CLASSIFICATION_CODE_TYPE } from 'constants/maintenance';

const defaultCategoryOptions = t => {
  const orderedOptions = orderBy(
    [
      { value: 'customer_feedback', label: t('Customer feedback') },
      { value: 'outside_areas', label: t('Outside areas') },
      { value: 'building_parts', label: t('Building parts') },
      { value: 'equipment', label: t('Equipment') },
      { value: 'hvac_systems', label: t('HVAC system') },
      { value: 'cleaning_services', label: t('Cleaning services') },
      { value: 'office_employee_services', label: t('Office employee services') },
      { value: 'energy_services', label: t('Energy services') },
      { value: 'individual_order', label: t('Individual order') },
      { value: 'real_estate_management', label: t('DISCIPLINE_REE') },
      { value: 'electricity', label: t('DISCIPLINE_ELE') },
      { value: 'plumbing', label: t('DISCIPLINE_PLU') },
      { value: 'ventilation', label: t('DISCIPLINE_VEN') },
      { value: 'cooling', label: t('DISCIPLINE_COO') },
      { value: 'security', label: t('DISCIPLINE_SEC') },
      { value: 'automation', label: t('DISCIPLINE_AUT') },
      { value: 'av', label: t('DISCIPLINE_AVI') },
      { value: 'ict_systems', label: t('DISCIPLINE_IT') },
      { value: 'sprinkler_systems', label: t('DISCIPLINE_SPR') },
      { value: 'transportation_systems', label: t('DISCIPLINE_TRA') },
      { value: 'evc', label: t('DISCIPLINE_EVC') },
    ],
    'label'
  );

  orderedOptions.push({ value: 'other', label: t('Other') });
  return orderedOptions;
};

const StyledInputRow = styled(InputRow)`
  width: 100%;
  margin: var(--size-lg) 0 !important;
`;

const InputColumns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > * {
    flex-grow: 1;
  }
`;

const Column = styled.div`
  width: 100%;

  ${props => props.theme.media.landscape`
        width: 48%;
    `}

  &:first-child {
    margin-right: auto;
    order: 1;

    ${props => props.theme.media.landscape`
            order: 0;
        `}
  }
`;

const SingleFunctionalLocation = styled.div`
  opacity: 0.6;
  min-height: 36px;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  padding-right: 30px;
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledSVGSeparatorIcon = styled(Svg)`
  fill: ${props => props.theme.colors.black};
  opacity: 0.6;
  font-size: 8px;
  margin: 0 4px;
`;

const DisabledIconContainer = styled.div`
  opacity: 0.6;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledSVGDisabledIcon = styled(Svg)`
  font-size: 0.875em;
`;

const EMPTY_ARRAY = [];

class ServiceRequestForm extends Component {
  state = { saveButtonClicked: false };

  componentDidMount() {
    const { publicView } = this.props;
    if (!publicView) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.saving === true && this.props.saving === false) {
      this.setState({ saveButtonClicked: false });
    }
    if (!this.props.publicView) {
      this.loadData(prevProps, prevState);
    }
  }

  async loadData(prevProps = {}, prevState = {}) {
    const { functionalLocation, model, partnerNumber, profile, equipment, superordinate, customer } = this.props;
    const alreadySelected = model.functionalLocation && functionalLocation;
    const oldPartner = prevProps.partnerNumber;
    const oldFunctionalLocation = prevProps.functionalLocation;

    // Load parent functional locations for pre-selected FL
    if (functionalLocation && !oldFunctionalLocation) {
      this.props.loadFunctionalLocations(functionalLocation.path);
    }
    // Load top-level functional locations if none is pre-selected (and partner number is changed)
    else if (partnerNumber !== oldPartner || isEmpty(prevState)) {
      this.props.loadFunctionalLocationsForOverview(partnerNumber);
    }

    // Set pre-selected functional location as selected
    if (functionalLocation && !alreadySelected) {
      this.props.setProperty('functionalLocation', functionalLocation.functionalLocation);
    }

    if (partnerNumber !== model.partnerNumber) {
      this.props.setProperty('partnerNumber', partnerNumber);
    }

    if (profile && profile.division && model && !model.division) {
      this.props.setProperty('division', profile.division);
    }

    if (equipment && !prevProps.equipment) {
      this.props.setProperty('equipment', equipment.equipmentNumber);
    }

    if (superordinate && !prevProps.superordinate) {
      this.props.setProperty('superordinate', superordinate.equipmentNumber);
    }

    const customerDivision = getPartnerDivision(customer) || profile.division;
    const divisionChanged = customerDivision !== this.state.division;

    if (divisionChanged) {
      MasterDataService.getDivisionConfig({
        query: {
          category: ConfigCategory.Classifications,
          name: ConfigKey.EnableClassificationsInNewServiceRequest,
          division: customerDivision,
        },
      }).then(async config => {
        const useClassificationCodesConfig = config?.find(
          configItem =>
            configItem.division === customerDivision &&
            configItem.name === ConfigKey.EnableClassificationsInNewServiceRequest
        );

        let classificationCodes = [];
        if (useClassificationCodesConfig?.value === 'true') {
          classificationCodes = await MasterDataService.classificationCodes({
            division: customerDivision,
            type: SERVICE_CLASSIFICATION_CODE_TYPE,
          });
        }

        this.setState({
          division: customerDivision,
          classificationCodes,
        });
      });
    }
  }

  getCategoryOptions() {
    const { partnerNumber, partnerMeta, t } = this.props;
    const metaValue = partnerMeta[partnerNumber]?.meta?.find(
      row => row.key === 'new_service_request_categories'
    )?.value;

    if (metaValue) {
      const options = metaValue.split(',');
      return options.map(option => ({ value: option, label: option }));
    }

    if (this.state.classificationCodes?.length > 0) {
      return this.getClassificationOptions();
    }

    return defaultCategoryOptions(t);
  }

  getClassificationOptions() {
    return this.state.classificationCodes?.map(classification => ({
      value: classification.code,
      label: getClassificationCodeLabel(classification),
    }));
  }

  getFunctionalLocationOptions() {
    const { publicView, topLevelFunctionalLocations, t } = this.props;
    if (!publicView) {
      return sortBy(
        topLevelFunctionalLocations.map(fl => ({
          value: fl.functionalLocation,
          label: `${functionalLocationName(t, fl)} (${fl.address})`,
        })),
        ['label']
      );
    }
  }

  getPath() {
    const { publicView, functionalLocation, functionalLocations, superordinate, equipment } = this.props;
    if (publicView) {
      if (publicView.functionalLocation) {
        return [publicView.functionalLocation];
      }
      if (publicView.customer) {
        return [{ description: publicView.customer.name }];
      }
    }
    let parents = [];
    if (functionalLocation && functionalLocations) {
      parents = getParentFunctionLocations(
        functionalLocations,
        functionalLocation.path,
        functionalLocation.functionalLocation
      ).reverse();
    }
    return compact([...parents, functionalLocation, superordinate, equipment]);
  }

  getMissingFields() {
    const { model, requiredFields } = this.props;
    return requiredFields
      .filter(field => field !== 'category' || this.state.division !== 'no')
      .some(field => !model[field]);
  }
  // Show Infotip after Save-button has been clicked once
  buttonClicked() {
    this.setState({ saveButtonClicked: true });
  }

  render() {
    const { t, id, model, setProperty, submit, functionalLocation, publicView } = this.props;
    const { division } = this.state;

    const functionalLocationOptions = this.getFunctionalLocationOptions();
    const path = this.getPath();
    const error = this.props.error !== null ? <ErrorText>{t(this.props.error)}</ErrorText> : null;
    const missingFields = this.getMissingFields();
    const showFunctionalLocationSelect =
      !publicView &&
      (!functionalLocation ||
        functionalLocationOptions.some(({ value }) => value === functionalLocation.functionalLocation));
    const showCategorySelect = !publicView && (division !== 'no' || this.state.classificationCodes?.length > 0);
    return (
      <Fragment>
        {error}
        <InputForm
          id={id}
          model={model}
          onPropertyChange={setProperty}
          onSubmit={this.props.saving ? () => {} : submit}
        >
          <Column>
            <StyledInputRow required>
              <InputLabel id="functionalLocation" text={t('Location')} />
              {showFunctionalLocationSelect && (
                <InputSelectDropdown
                  property="functionalLocation"
                  options={functionalLocationOptions}
                  t={t}
                  clearable={!functionalLocation}
                />
              )}
              {!showFunctionalLocationSelect && (
                <SingleFunctionalLocation>
                  <div>
                    {path.map((item, index) => (
                      <span key={item.equipmentNumber || item.functionalLocation}>
                        {index > 0 && <StyledSVGSeparatorIcon name="caret-right" />}
                        {functionalLocationName(t, item) || item.text}
                      </span>
                    ))}
                  </div>
                  <DisabledIconContainer>
                    <StyledSVGDisabledIcon name="fa-lock" />
                  </DisabledIconContainer>
                </SingleFunctionalLocation>
              )}
            </StyledInputRow>
            {showCategorySelect && (
              <StyledInputRow required>
                <InputLabel id="category" text={t('Category')} />
                <InputSelectDropdown
                  model={model}
                  property="category"
                  placeholder={t('Select category')}
                  options={this.getCategoryOptions()}
                  t={t}
                  clearable={false}
                />
              </StyledInputRow>
            )}
            <StyledInputRow required>
              <InputLabel id="title" text={t('Title')} />
              <InputText id="title" property="title" placeholder={t('Give a short outline of the issue')} />
            </StyledInputRow>
            <StyledInputRow required>
              <InputLabel id="description" text={t('Description')} />
              <InputTextArea id="description" property="description" placeholder={t('Describe the issue thoroughly')} />
            </StyledInputRow>
            <StyledInputRow>
              <InputLabel id="locationDetails" text={t('Detailed location')} extraText={`(${t('Optional')})`} />
              <InputText
                id="locationDetails"
                property="locationDetails"
                placeholder={t('Describe the specific location')}
              />
            </StyledInputRow>
            {!publicView && (
              <StyledInputRow>
                <InputLabel id="priority" text={t('Requested priority')} extraText={`(${t('Optional')})`} />
                <RadioButtonGroup name="priority" value={model.priority} onChange={setProperty} row>
                  <RadioButton value="low" label={t('Low')} />
                  <RadioButton value="medium" label={t('Medium')} />
                  <RadioButton value="high" label={t('High')} />
                  <RadioButton value="urgent" label={t('Urgent')} />
                </RadioButtonGroup>
              </StyledInputRow>
            )}
            {publicView && (
              <>
                <StyledInputRow required>
                  <InputLabel text={t('Name')} />
                  <InputColumns>
                    <InputText id="firstName" property="firstName" placeholder={t('First name')} />
                    <InputText id="lastName" property="lastName" placeholder={t('Last name')} />
                  </InputColumns>
                </StyledInputRow>
                <StyledInputRow required>
                  <InputLabel text={t('Email')} />
                  <InputText id="email" property="email" placeholder={t('Email')} />
                </StyledInputRow>
              </>
            )}
            <StyledInputRow>
              <InputLabel
                id="createdByPhoneNumber"
                text={t('Phone number for contact')}
                extraText={`(${t('Optional')})`}
              />
              <InputText id="createdByPhoneNumber" property="createdByPhoneNumber" />
            </StyledInputRow>
            <StyledInputRow>
              <Infotip
                text={t('Please fill in the required details')}
                hide={!missingFields || this.props.saving || !this.state.saveButtonClicked}
              >
                <PrimaryButton
                  confirm
                  large
                  disabled={missingFields && this.state.saveButtonClicked}
                  loading={this.props.saving}
                  onClick={this.props.saving ? undefined : () => this.buttonClicked()}
                >
                  {!this.props.saving && t('Send')}
                </PrimaryButton>
              </Infotip>
            </StyledInputRow>
          </Column>
          <Column>
            <StyledInputRow>
              <InputLabel id="attachment" text={t('Add image')} extraText={`(${t('Optional')})`} />
              <FileUploadForm
                files={model.attachment || EMPTY_ARRAY}
                onChange={files => setProperty('attachment', files)}
              />
            </StyledInputRow>
          </Column>
        </InputForm>
      </Fragment>
    );
  }
}

ServiceRequestForm.defaultProps = {
  requiredFields: ['category', 'title', 'functionalLocation', 'description'],
};

export const serviceRequestFormPropTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  setProperty: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  functionalLocation: PropTypes.object,
  partnerNumber: PropTypes.string,
  equipment: PropTypes.object,
  superordinate: PropTypes.object,
  saving: PropTypes.bool,
};

ServiceRequestForm.propTypes = {
  ...serviceRequestFormPropTypes,
  functionalLocations: PropTypes.objectOf(PropTypes.object),
  topLevelFunctionalLocations: PropTypes.arrayOf(PropTypes.object),
  profile: PropTypes.object,
  partnerMeta: PropTypes.object,
  error: PropTypes.string,
  loadFunctionalLocations: PropTypes.func.isRequired,
  loadFunctionalLocationsForOverview: PropTypes.func.isRequired,
  publicView: PropTypes.object,
  requiredFields: PropTypes.array,
  customer: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  functionalLocations: state.functionalLocations.functionalLocations,
  topLevelFunctionalLocations: state.functionalLocations.topLevelFunctionalLocations,
  error: state.profile.error || state.serviceRequests.error,
  profile: state.profile.profile,
  partnerMeta: state.partnerMeta.meta,
  customer: state.customer.customers[props.partnerNumber],
});

const mapDispatchToProps = {
  loadFunctionalLocations,
  loadFunctionalLocationsForOverview,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ServiceRequestForm);
