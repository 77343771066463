import { createContext, useState, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

const AnnotationDataValidityContext = createContext({
  invalidationTime: null,
  setDataInvalid: () => {}, // eslint-disable-line
});

export const AnnotationDataValidityProvider = ({ children }) => {
  const [invalidationTime, setInvalidationTime] = useState(null);
  const setDataInvalid = useCallback(() => setInvalidationTime(Date.now()), []);

  return (
    <AnnotationDataValidityContext.Provider
      value={useMemo(
        () => ({
          invalidationTime,
          setDataInvalid,
        }),
        [invalidationTime, setDataInvalid]
      )}
    >
      {children}
    </AnnotationDataValidityContext.Provider>
  );
};

AnnotationDataValidityProvider.propTypes = {
  children: PropTypes.node,
};

export const useAnnotationDataValidity = () => useContext(AnnotationDataValidityContext);
