import { Fragment } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header';
import ErrorPageContent from 'components/ErrorPage/ErrorPageContent';
import Navigation from 'containers/Application/Navigation/Navigation';
import AdminAuthorizeButton from './AdminAuthorizeButton';

import { isCaverionAdminRole } from 'utils/Data/profileData';
import PrimaryButton from 'components/Button/PrimaryButton';

const StyledLink = styled.a`
  margin-left: ${props => props.theme.spacing.md};
`;

export const ErrorPage = ({ t, type, embed, profile, history }) => {
  let title, link, onButtonClick, buttonText, buttons;
  const overviewLink = '/all/Overview';
  const overViewText = t('Go to frontpage');
  let instructions = t('Please try to refresh the page or contact local support for further assistance.');
  let pageTitle = t('Error');

  switch (type) {
    case 'functionalLocation':
      title = t(`Functional Location not found or you don't have the right permissions`);
      link = overviewLink;
      buttonText = overViewText;
      if (isCaverionAdminRole(profile?.role)) {
        buttons = AdminAuthorizeButton;
      }
      break;
    case 'serviceOrder':
      title = t(`Service order not found or you don't have the right permissions`);
      link = overviewLink;
      buttonText = overViewText;
      break;
    case 'maintenancePlan':
      title = t(`Maintenance plan not found or you don't have the right permissions`);
      link = overviewLink;
      buttonText = overViewText;
      break;
    case 'longTermPlan':
      title = t(`Long term plan not found or you don't have the right permissions`);
      link = overviewLink;
      buttonText = overViewText;
      break;
    case 'equipment':
      title = t(`Equipment not found or you don't have the right permissions`);
      link = overviewLink;
      buttonText = overViewText;
      break;
    case 'partner':
      title = t(`Customer portfolio not found or you don't have the right permissions`);
      link = overviewLink;
      buttonText = overViewText;
      if (isCaverionAdminRole(profile?.role)) {
        buttons = AdminAuthorizeButton;
      }
      break;
    case 'selectPartner':
      title = t('No Customer selected');
      link = overviewLink;
      buttonText = overViewText;
      instructions = t('Please select a Customer portfolio from the selector on top navigation');
      break;
    case 'customView':
      title = t(`Custom view not found or you don't have the right permissions`);
      link = overviewLink;
      buttonText = overViewText;
      break;
    case 'authorization':
      pageTitle = t('Access denied');
      title = t(`It looks like your permissions don't meet the access policy for this functional location`);
      onButtonClick = history.goBack;
      buttonText = `← ${t('Go Back')}`;
      link = null;
      buttons = () => (
        <StyledLink href={overviewLink}>
          <PrimaryButton large add>
            {overViewText}
          </PrimaryButton>
        </StyledLink>
      );
      break;
    case 'authentication':
    case 'session':
    default:
      link = '/';
      buttonText = t('Go to frontpage');
      break;
  }

  const Container = embed ? Fragment : StandardPage;
  const containerProps = embed ? undefined : { noMargin: true };
  return (
    <Container {...containerProps}>
      <Helmet title={pageTitle} />
      <Header useALinkTag showPartnerSelect t={t} />
      <Navigation />
      <ErrorPageContent
        pageTitle={pageTitle}
        title={title}
        instructions={instructions}
        buttons={buttons}
        buttonText={buttonText}
        buttonLink={link}
        onButtonClick={onButtonClick}
      />
    </Container>
  );
};

ErrorPage.defaultProps = {
  type: null,
};

ErrorPage.propTypes = {
  t: PropTypes.func.isRequired,
  profile: PropTypes.object,
  type: PropTypes.string,
  embed: PropTypes.bool,
  saveProfile: PropTypes.func,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      partnerNumber: PropTypes.string,
      functionalLocationId: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
});

const connector = connect(mapStateToProps);

export default withRouter(connector(translations(ErrorPage)));
