import { Children, cloneElement, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;
Form.displayName = 'Form';

function propagateDownwards({ scope, model, onChange, children, validationErrors = {} }) {
  if (children === undefined) {
    return [];
  }

  return Children.map(children, child => {
    if (typeof child === 'string' || !child) {
      return child;
    }

    const newProps = {};

    if (child.props?.id !== undefined) {
      newProps.id = `${scope}-${child.props.id}`;
    } else {
      newProps.id = scope;
    }

    if (child.props?.property !== undefined) {
      newProps.model = model;
      newProps.property = Array.isArray(child.props.property) ? child.props.property : [child.props.property];
      newProps.highlightError = !!validationErrors[newProps.property];

      // Join onChange callbacks if element already has onChange callback.
      if (child.props.onChange !== undefined) {
        newProps.onChange = (property, value, override) => {
          onChange(property, value, override);
          child.props.onChange(property, value, override);
        };
      } else {
        newProps.onChange = onChange;
      }
    }

    const grandchildren = propagateDownwards({
      scope,
      model,
      onChange,
      children: child.props?.children,
      validationErrors,
    });

    return cloneElement(child, newProps, grandchildren);
  });
}

export const InputForm = forwardRef((props, ref) => {
  const newChilds = propagateDownwards({
    scope: props.id,
    model: props.model,
    onChange: props.onPropertyChange,
    children: props.children,
    validationErrors: props.validationErrors,
  });

  const submit = e => {
    e.preventDefault();
    props.onSubmit(e);
  };

  const { FormComponent } = props;
  return (
    <FormComponent
      ref={ref}
      data-test-id={props.id}
      id={props.id}
      name={props.id}
      onSubmit={submit}
      className={`input-form ${props.className}`}
    >
      {newChilds}
    </FormComponent>
  );
});
InputForm.displayName = 'InputForm';

InputForm.defaultProps = {
  FormComponent: Form,
};

InputForm.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onPropertyChange: PropTypes.func,
  onSubmit: PropTypes.func,
  model: PropTypes.object,
  children: PropTypes.node,
  FormComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  validationErrors: PropTypes.object,
};

export default InputForm;
