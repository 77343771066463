import PropTypes from 'prop-types';
import styled from 'styled-components';

import FunctionalLocationHeader from 'components/FunctionalLocationHeader/FunctionalLocationHeader';
import Tag from 'components/Tag/Tag';

import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import { additionalSalePropType, AdditionalSaleStatus } from './utils';

const StatusTag = styled(Tag).attrs(props => ({
  showFullText: true,
  backgroundColor:
    props.status === AdditionalSaleStatus.CLOSED ? props.theme.colors.darkGray : props.theme.brandColors.turquoise01,
  textColor: props.theme.colors.white,
}))`
  text-transform: capitalize;
`;
StatusTag.displayName = 'StatusTag';

const EMPTY_OBJECT = {};

const AdditionalSaleHeader = ({ additionalSale = EMPTY_OBJECT, loading }) => {
  const { t } = useUnauthenticatedTranslations();

  const statusText = t(additionalSale.status === AdditionalSaleStatus.CLOSED ? 'closed' : 'open');

  return (
    <FunctionalLocationHeader
      title={t('New Work Proposal')}
      statusTag={<StatusTag status={additionalSale.status}>{statusText}</StatusTag>}
      functionalLocation={{
        description: additionalSale.customerName,
        address: additionalSale.customerAddress,
        city: additionalSale.customerCity,
      }}
      loading={loading}
      t={t}
    />
  );
};

AdditionalSaleHeader.propTypes = {
  additionalSale: additionalSalePropType,
  loading: PropTypes.bool,
};

export default AdditionalSaleHeader;
