import { useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import isObjectLike from 'lodash/isObjectLike';
import isArray from 'lodash/isArray';
import { SelectInputV5 } from 'components/Select/SelectInputV5';
import { useTheme } from 'styled-components';
import SelectedMultiOption from 'components/Form/SelectedMultiOption/SelectedMultiOption';
import { useTranslations } from 'decorators/Translations/translations';
import oldSelectInputStyles from './oldSelectInputStyles';

const SELECT_ALL_VALUE = '*.*';

const getLabel = (selectedOption, options) => {
  if (isObjectLike(selectedOption)) {
    return selectedOption.label;
  }
  return options.find(option => option.value === selectedOption)?.label;
};

const getSelectedValue = (value, options) => {
  if (isNil(value)) {
    return '';
  }
  if (isArray(value)) {
    return value;
  }
  const valueToFind = isObjectLike(value) && !isObjectLike(options?.[0]?.value) ? Object.keys(value)[0] : value;
  return find(options, ['value', valueToFind]) || value;
};

// mimic onChange or multi select: return all selected values as option array
const getMultiInputChangeValue = (selectedOption, previouslySelected, options, remove) => {
  if (selectedOption.value === SELECT_ALL_VALUE) {
    return options;
  }
  let valueArray = previouslySelected || []; // fix empty previous value
  if (!Array.isArray(valueArray)) {
    valueArray = [valueArray]; // fix single previous value
  }
  if (previouslySelected.length > 0 && !previouslySelected[0]?.label) {
    valueArray = previouslySelected.map(value => ({ label: getLabel(value, options), value })); // fix simple previous values to option array
  }

  if (remove) {
    return valueArray.filter(value => value.value !== selectedOption.value);
  }
  return valueArray.concat(selectedOption);
};

/**
 * An older implementation of a dropdown selector using value and options conversion for backwards compatibility.
 *
 * @deprecated Do not use in new components.
 * This is a multi-select dropdown, use {@link InputSelectDropdown} if you need single select with old styles.
 */
export const MultiSelectDropdown = props => {
  const [t] = useTranslations();
  const theme = useTheme();
  const oldStyles = useMemo(() => oldSelectInputStyles(theme), [theme]);
  const ref = useRef();
  const handleMenuOpen = useCallback(() => ref.current.scrollIntoView(), []);

  const value = props.value || get(props.model, props.property);
  const selected = getSelectedValue(value, props.options);
  const selectedValues = (Array.isArray(selected) ? selected : [selected]).map(item => item.value ?? item);
  const options = (props.showAll ? [{ label: t('Select all'), value: SELECT_ALL_VALUE }] : []).concat(
    props.options ? props.options.filter(option => !selectedValues.includes(option.value)) : []
  );
  const showMultiValues = props.multi && selected && Array.isArray(selected); // single value is shown inside select

  const handleChange = selectedOption => {
    let newValue = '';
    if (props.multi) {
      newValue = getMultiInputChangeValue(selectedOption, selected, props.options, false);
    } else if (selectedOption?.value !== undefined) {
      newValue = selectedOption?.value;
    } else if (props.resetValue !== undefined) {
      newValue = props.resetValue;
    }
    props.onChange(props.property, newValue);
  };

  return (
    <>
      <div ref={ref}>
        {showMultiValues &&
          selected.map(value => {
            const option = isObjectLike(value) ? value : { label: getLabel(value, props.options), value: value };
            return (
              <SelectedMultiOption
                key={option.value}
                label={option.label}
                onClick={() =>
                  props.onChange(props.property, getMultiInputChangeValue(option, selected, props.options, true))
                }
              />
            );
          })}
      </div>
      <SelectInputV5
        styles={oldStyles}
        className={props.className}
        defaultMenuIsOpen={false}
        name={props.id}
        value={showMultiValues ? '' : selected}
        required={props.required && (!props.multi || !selected)} // trigger required to multi select only if there is no value
        disabled={props.disabled}
        clearable={props.clearable}
        filterOption={props.filterOption}
        onInputChange={value => (props.onInput ? props.onInput(value) : undefined)}
        onChange={handleChange}
        options={options}
        placeholder={props.placeholder || `${t('Select')}...`}
        noResultsText={props.noResultsText || t('No results found')}
        clearValueText={t('Clear value')}
        isLoading={props.loading}
        onBlurResetsInput={false}
        onOpen={props.scrollToMenu ? handleMenuOpen : undefined}
        menuPlacement={props.menuPlacement}
        arrowRenderer={props.arrowRenderer}
        label={props.label}
        isFilter={props.isFilter}
        highlightError={props.highlightError}
        optionRenderer={props.optionRenderer}
        isMulti={props.multi}
      />
    </>
  );
};
MultiSelectDropdown.displayName = 'SelectInputDropdown';

MultiSelectDropdown.propTypes = {
  className: PropTypes.string,
  arrowRenderer: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.number]),
  model: PropTypes.object,
  options: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  filterOption: PropTypes.func,
  isFilter: PropTypes.bool,
  label: PropTypes.string,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  multi: PropTypes.bool,
  showAll: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noResultsText: PropTypes.string,
  loading: PropTypes.bool,
  scrollToMenu: PropTypes.bool,
  menuPlacement: PropTypes.string,
  highlightError: PropTypes.bool,
  resetValue: PropTypes.any,
  optionRenderer: PropTypes.func,
};

export default MultiSelectDropdown;
