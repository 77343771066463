import { rgba } from 'polished';
import recyclingBuildingLevelTabs from 'containers/Application/Modules/RecyclingModule/tabs';
import {
  RECOVERY_RATE_THRESHOLD,
  RECYCLING_RATE_THRESHOLD,
  CO2E_TONNES_UNIT,
  WASTE_BREAKDOWN_ELEMENT_ID,
  RECYCLING_RATES_BREAKDOWN_ELEMENT_ID,
} from 'constants/recycling';

export default {
  RECYCLING_RATE: {
    id: 'recycling-rate',
    label: 'Recycling rate',
    toQuery: `?scrollTo=${RECYCLING_RATES_BREAKDOWN_ELEMENT_ID}&rateTab=${recyclingBuildingLevelTabs.ratesTabs.RECYCLING_RATE.id}`,
    filterData: result => result.map(entry => ({ ...entry, value: entry.values.recyclingRate })),
    valueWidth: 80,
    valueUnit: '%',
    resolveValueBackgroundColor: (theme, value) =>
      value && value >= RECYCLING_RATE_THRESHOLD ? rgba(theme.colors.emerald, 0.2) : rgba(theme.colors.radicalRed, 0.2),
  },
  RECOVERY_RATE: {
    id: 'recovery-rate',
    label: 'Recovery rate',
    toQuery: `?scrollTo=${RECYCLING_RATES_BREAKDOWN_ELEMENT_ID}&rateTab=${recyclingBuildingLevelTabs.ratesTabs.RECOVERY_RATE.id}`,
    filterData: result => result.map(entry => ({ ...entry, value: entry.values.recoveryRate })),
    valueWidth: 80,
    valueUnit: '%',
    resolveValueBackgroundColor: (theme, value) =>
      value && value >= RECOVERY_RATE_THRESHOLD ? rgba(theme.colors.emerald, 0.2) : rgba(theme.colors.radicalRed, 0.2),
  },
  CARBON_FOOTPRINT: {
    id: 'carbon-footprint',
    label: 'Carbon footprint',
    toQuery: `?scrollTo=${WASTE_BREAKDOWN_ELEMENT_ID}&breakdownTab=${recyclingBuildingLevelTabs.wasteBreakdownTabs.CARBON_FOOTPRINT.id}`,
    valueWidth: 140,
    valueUnit: CO2E_TONNES_UNIT,
    filterData: result => result.map(entry => ({ ...entry, value: entry.values.carbonFootprint })),
  },
};
