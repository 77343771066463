// extracted by mini-css-extract-plugin
var _1 = "sv__OnOffAggregations-module__card-container";
var _2 = "sv__OnOffAggregations-module__card-container";
var _3 = "sv__OnOffAggregations-module__event-count";
var _4 = "sv__OnOffAggregations-module__event-count";
var _5 = "sv__OnOffAggregations-module__not-available-text";
var _6 = "sv__OnOffAggregations-module__not-available-text";
var _7 = "sv__OnOffAggregations-module__on-off-gauge-box";
var _8 = "sv__OnOffAggregations-module__on-off-gauge-box";
var _9 = "sv__OnOffAggregations-module__primary-label";
var _a = "sv__OnOffAggregations-module__primary-label";
var _b = "sv__OnOffAggregations-module__secondary-label";
var _c = "sv__OnOffAggregations-module__secondary-label";
var _d = "sv__OnOffAggregations-module__value-container";
var _e = "sv__OnOffAggregations-module__value-container";
export { _1 as "card-container", _2 as "cardContainer", _3 as "event-count", _4 as "eventCount", _5 as "not-available-text", _6 as "notAvailableText", _7 as "on-off-gauge-box", _8 as "onOffGaugeBox", _9 as "primary-label", _a as "primaryLabel", _b as "secondary-label", _c as "secondaryLabel", _d as "value-container", _e as "valueContainer" }
