import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import rehypeRaw from 'rehype-raw';
import isEmpty from 'lodash/isEmpty';

import Section from 'components/Section/Section';
import { customViewShape } from 'components/CustomView/utils';

/* Some basic styling for html content */
const StyledFreelayout = styled.div`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol {
    margin-bottom: 1rem;
  }
`;

const DOMPurifyOptions = { ADD_TAGS: ['iframe'], ADD_ATTR: ['src', 'height', 'width'] };

const FreeLayoutView = ({ customView, noDataRender }) => {
  if (!customView || isEmpty(customView.content)) {
    return noDataRender;
  }

  const cleanedContent =
    customView && customView.content ? DOMPurify.sanitize(customView.content, DOMPurifyOptions) : '';
  return (
    <Section>
      <StyledFreelayout>
        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={cleanedContent}></ReactMarkdown>
      </StyledFreelayout>
    </Section>
  );
};

FreeLayoutView.defaultProps = {
  customView: {},
  noDataRender: undefined,
};

FreeLayoutView.propTypes = {
  customView: PropTypes.oneOfType([customViewShape()]),
  noDataRender: PropTypes.node,
};

export default FreeLayoutView;
