import styled from 'styled-components';
import Button, { StyledButton } from 'components/Button/PrimaryButton/PrimaryButton';
import PageTemplate from './PageTemplate';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import MarkdownContent from 'components/Announcement/MarkdownContent';
import LanguageSelector from 'containers/Technician/LanguageSelector/LanguageSelector';
import { Description, Hr, Note, Title } from './StyledElements';

const Troubleshooting = styled.div`
  text-align: left;
`;

const TroubleshootingTitle = styled.h4`
  margin-bottom: var(--size-lg);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  ${StyledButton} {
    margin: var(--size-xs);
  }
`;

const NoProfileFound = () => {
  const { t, selectedLanguage, setSelectedLanguage, translationsReady } = useUnauthenticatedTranslations();
  const languageSelector = (
    <LanguageSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
  );
  return (
    <PageTemplate title={t('Something went wrong')} languageSelector={languageSelector}>
      <Title>{t('Something went wrong')}</Title>
      <Description>{t('Your SmartView profile cannot be found')}</Description>
      <Note>{t('See troubleshooting below and try again or contact your local support for further assistance.')}</Note>
      <ButtonContainer>
        <Button large add onClick={() => window.location.reload()}>
          {t('Try again')}
        </Button>
        <Button large add reactLink to="/Logout">
          {t('Log in')}
        </Button>
      </ButtonContainer>
      {translationsReady && (
        <>
          <Hr />
          <Troubleshooting>
            <TroubleshootingTitle>{t('Troubleshooting')}</TroubleshootingTitle>
            <MarkdownContent content={t('login_help_text')} />
          </Troubleshooting>
        </>
      )}
    </PageTemplate>
  );
};

export default NoProfileFound;
