import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import sum from 'lodash/sum';
import translations from 'decorators/Translations/translations';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import BreakdownTotals from 'components/BreakdownTotals/BreakdownTotals';
import BarChart from 'components/Charts/BarChart';
import ContextualHelp, { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import withQuery from 'decorators/Query/withQuery';
import PrimaryButton, { StyledButton } from 'components/Button/PrimaryButton/PrimaryButton';
import Dialog from 'components/Dialog/Dialog';
import DialogFrame from 'components/Dialog/DialogFrame';

export const StyledSection = styled(Section)`
  h2 {
    font-size: ${props => props.theme.font.size.md};
  }
`;

const HelpPositioner = styled.div`
  margin-right: auto;
  margin-left: var(--size-sm);
`;

const TotalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${StyledButton} {
    margin-bottom: var(--size-xl);
    align-self: flex-end;
  }
`;

const scrollTo = 'energy-savings';

const Wrapper = ({ modal, onModalClose, children }) => {
  if (modal) {
    return (
      <Dialog isActive onOverlayClick={onModalClose}>
        <DialogFrame onClose={onModalClose} smallVerticalPadding>
          {() => children}
        </DialogFrame>
      </Dialog>
    );
  }
  return <>{children}</>;
};
Wrapper.propTypes = {
  modal: PropTypes.bool,
  onModalClose: PropTypes.func,
  children: PropTypes.node,
};

const Chart = ({
  t,
  theme,
  modal,
  totalSavingsPotential,
  totalSavingsPotentialMonetary,
  electricitySavingsMonetary,
  heatSavingsMonetary,
  electricitySavings,
  heatSavings,
}) => {
  return (
    <BarChart
      t={t}
      type="column"
      height={400}
      yMin={0}
      width={modal ? window.innerWidth * 0.75 : null}
      categories={[t('Total'), t('Electricity'), t('Heating')]}
      legendAlign="left"
      hideDecimals={true}
      series={[
        {
          name: 'MWh',
          data: [totalSavingsPotential, electricitySavings, heatSavings],
          _unit: 'MWh',
          color: theme.colors.emerald,
          _showTooltipForZeroValue: true,
        },
        {
          name: 'EUR',
          data: [totalSavingsPotentialMonetary, electricitySavingsMonetary, heatSavingsMonetary],
          _unit: 'EUR',
          color: theme.colors.midnight,
          _showTooltipForZeroValue: true,
        },
      ]}
      loading={false}
    />
  );
};

Chart.propTypes = {
  t: PropTypes.func,
  theme: PropTypes.object,
  modal: PropTypes.bool,
  totalSavingsPotential: PropTypes.number,
  totalSavingsPotentialMonetary: PropTypes.number,
  electricitySavingsMonetary: PropTypes.number,
  heatSavingsMonetary: PropTypes.number,
  electricitySavings: PropTypes.number,
  heatSavings: PropTypes.number,
};

export const EnergySavingsPotential = ({ t, theme, notices, setQuery, modal, onModalClose }) => {
  // Energy savings potential values are negative, however we want to display positive values in the UI
  // for savings potential
  const totalSavingsPotential = (notices.totalSavingsPotential * -1) / 1000;
  const totalSavingsPotentialMonetary = (notices.totalSavingsPotentialMonetary * -1) / 100;
  const electricitySavings = (sum(notices.observations.map(item => item.savingsPotentialElectricity)) * -1) / 1000;
  const heatSavings = (sum(notices.observations.map(item => item.savingsPotentialHeat)) * -1) / 1000;
  const electricitySavingsMonetary =
    (sum(notices.observations.map(item => item.electricitySavingsYearlySum)) * -1) / 100;
  const heatSavingsMonetary = (sum(notices.observations.map(item => item.heatSavingsYearlySum)) * -1) / 100;

  const improvementCount = notices.observations.filter(
    item => item.status?.status !== 'completed' && item.savingsPotentialSum < 0
  ).length;
  const totalInvestment = sum(notices.observations.map(item => item.savingsCost)) / 100;

  return (
    <Wrapper modal={modal} onModalClose={onModalClose}>
      <StyledSection>
        <ScrollToComponent id={scrollTo} />
        <SectionHeader title={t('Savings Potential')}>
          <HelpPositioner>
            <ContextualHelp
              title={t('Savings Potential')}
              text={`${CTXHELP_PREFIX} Savings potential`}
              position="bottom"
            />
          </HelpPositioner>
        </SectionHeader>
        {modal && (
          <Chart
            t={t}
            theme={theme}
            modal={modal}
            totalSavingsPotential={totalSavingsPotential}
            totalSavingsPotentialMonetary={totalSavingsPotentialMonetary}
            electricitySavings={electricitySavings}
            heatSavings={heatSavings}
            electricitySavingsMonetary={electricitySavingsMonetary}
            heatSavingsMonetary={heatSavingsMonetary}
          />
        )}
        {!modal && (
          <Columns>
            <Column
              columnWidth={{
                desktop: 9,
                default: 12,
              }}
            >
              <Chart
                t={t}
                theme={theme}
                modal={modal}
                totalSavingsPotential={totalSavingsPotential}
                totalSavingsPotentialMonetary={totalSavingsPotentialMonetary}
                electricitySavings={electricitySavings}
                heatSavings={heatSavings}
                electricitySavingsMonetary={electricitySavingsMonetary}
                heatSavingsMonetary={heatSavingsMonetary}
              />
            </Column>
            <Column
              columnWidth={{
                desktop: 3,
                default: 12,
              }}
            >
              <TotalsWrapper>
                <PrimaryButton
                  reveal
                  large
                  autoWidth={true}
                  onClick={() =>
                    setQuery({ hideWithoutImprovement: true, observationsModalVisible: true, showCompleted: false })
                  }
                >
                  {`${t('Show Improvements')} (${improvementCount})`}
                </PrimaryButton>
                <BreakdownTotals
                  header={t('Savings Potential')}
                  label={t('Yearly consumption')}
                  icon={'savings-potential'}
                  value={totalSavingsPotential}
                  unit={'MWh'}
                  positiveValueColor={theme.colors.emerald}
                  footer={{
                    header: t('Projected Investment'),
                    value: `${totalInvestment} €`,
                  }}
                  categories={[
                    {
                      categoryId: 'In yearly costs',
                      categoryName: t('In yearly costs'),
                      color: theme.colors.midnight,
                      unit: '€',
                      total: totalSavingsPotentialMonetary,
                    },
                  ]}
                />
              </TotalsWrapper>
            </Column>
          </Columns>
        )}
      </StyledSection>
    </Wrapper>
  );
};

EnergySavingsPotential.propTypes = {
  t: PropTypes.func,
  theme: PropTypes.object,
  notices: PropTypes.object,
  modal: PropTypes.bool,
  onModalClose: PropTypes.func,
};

export default withQuery(withTheme(translations(EnergySavingsPotential)));
