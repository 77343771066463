import * as Styled from './InfoTable.styled';
import SkeletonText from 'components/Skeletons/SkeletonText';

const InfoTableSkeleton = ({ loading, borderTop, borders, labelColumnWidth, valueColumnWidth }) => (
  <Styled.Table borderTop={borderTop} borders={borders}>
    <tbody>
      <Styled.Row borders={borders}>
        <Styled.LabelCell columnWidth={labelColumnWidth}>
          <SkeletonText />
        </Styled.LabelCell>
        <Styled.ValueCell isLoading columnWidth={valueColumnWidth}>
          <SkeletonText />
        </Styled.ValueCell>
      </Styled.Row>
      <Styled.Row borders={borders}>
        <Styled.LabelCell columnWidth={labelColumnWidth}>
          <SkeletonText />
        </Styled.LabelCell>
        <Styled.ValueCell isLoading={loading} columnWidth={valueColumnWidth}>
          <SkeletonText />
        </Styled.ValueCell>
      </Styled.Row>
    </tbody>
  </Styled.Table>
);

export default InfoTableSkeleton;
