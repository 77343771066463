import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { FlexGroup } from 'components/Layout/FlexGroup';

import { PaginationPageCounter } from './PaginationPageCounter';

export const ShowFirstOrAll = styled.span`
  color: ${props => props.theme.colors.cerulean};
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.font.letterSpacing.button};
  cursor: pointer;
`;

export type FirstPageOrAllPaginationProps = {
  first: number;
  last: number;
  total: number;
  reserveTextWidth: boolean;
  showAll?: boolean;
  setShowAll?: Dispatch<SetStateAction<boolean>>;
};

export function FirstPageOrAllPagination({
  first,
  last,
  total,
  reserveTextWidth,
  showAll = false,
  setShowAll,
}: FirstPageOrAllPaginationProps) {
  const toggleShowAll = () => setShowAll?.(!showAll);

  return (
    <FlexGroup row justifyContent="space-between">
      <PaginationPageCounter first={first} last={last} total={total} reserveTextWidth={reserveTextWidth} />
      <ShowFirstOrAll onClick={toggleShowAll}>{showAll ? 'Show less' : `Show all (${total})`}</ShowFirstOrAll>
    </FlexGroup>
  );
}
