import { createReducerFromMapping } from 'redux/utils/index';
import { loadMe } from 'redux/modules/profile/profile';
import cookies from 'utils/Cookies/Cookies';
import ProfileService from 'services/profile';
import { reportErrorToNewRelic } from 'utils/newRelic';

const initialState = {
  // Make the default state loading so that the first load respects loading order via promise resolve
  loading: true,
  error: false,
};

export const APPLICATION_LOAD = 'CUSTOMER_PLATFORM/Application/LOAD';
export const APPLICATION_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/Application/LOAD_SUCCESS';
export const APPLICATION_LOAD_FAIL = 'CUSTOMER_PLATFORM/Application/LOAD_FAIL';

export const loadApplication = () => {
  return async dispatch => {
    dispatch({ type: APPLICATION_LOAD });
    try {
      const { username, language = 'en' } = await dispatch(loadMe());
      const translations = await ProfileService.translations(language);
      cookies.setItem('username', username, Infinity, '/');
      dispatch({ type: APPLICATION_LOAD_SUCCESS });
      return {
        language,
        translations,
      };
    } catch (error) {
      reportErrorToNewRelic(error);
      return dispatch({
        type: APPLICATION_LOAD_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [APPLICATION_LOAD]: state => ({
      ...state,
      loading: true,
    }),
    [APPLICATION_LOAD_SUCCESS]: state => ({
      ...state,
      loading: false,
    }),
    [APPLICATION_LOAD_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  },
  initialState
);
