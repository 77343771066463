import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { metaReferenceKeys } from 'utils/Data/values';

const EMPTY_ARRAY = [];

const useReferenceMeta = functionalLocationId => {
  const buildingMeta = useSelector(state => state.buildingMeta.meta[functionalLocationId] ?? EMPTY_ARRAY);

  const references = useMemo(
    () =>
      Object.keys(metaReferenceKeys).reduce((accu, referenceSubject) => {
        const referenceMetaKey = metaReferenceKeys[referenceSubject];
        const referenceMetaValue = buildingMeta.find(({ key }) => key === referenceMetaKey)?.value;

        if (referenceMetaValue) {
          let values = [];
          try {
            values = JSON.parse(referenceMetaValue);
          } catch (e) {
            console.error(e);
          }
          return { ...accu, [referenceMetaKey]: values };
        }
        return accu;
      }, {}),
    [buildingMeta]
  );

  return references;
};

export default useReferenceMeta;
