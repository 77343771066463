import { StyledButton } from 'components/Button/PrimaryButton/PrimaryButton';
import { StyledSnackBar } from 'components/SnackBar/SnackBar';
import styled, { css } from 'styled-components';

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-lg);
  padding: var(--size-lg) var(--size-lg-plus) var(--size-md) var(--size-lg-plus);

  @media (max-width: ${props => props.theme.breakpoints.portrait}px) {
    padding: var(--size-lg) var(--size-md-plus) var(--size-md) var(--size-md-plus);
  }
`;

const ServiceOrderGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-column-gap: var(--size-md);
  grid-template-rows: min-content 1fr;
  grid-row-gap: var(--size-md);
  grid-template-areas: 'tabs sidepanel' 'contentPanel sidepanel';
  align-items: start;

  @media (max-width: ${props => props.theme.breakpoints.desktop}px), {
    grid-template-areas: 'tabs tabs' 'contentPanel sidepanel';
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}px), print {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-template-areas: 'tabs' 'contentPanel' 'sidepanel';
  }
`;

export const ServiceOrderContentsContainer = styled(ServiceOrderGrid)`
  ${ServiceOrderGrid}
  width: 100%;
  height: 100%;
`;

export const SidePanelContainer = styled.div`
  grid-area: sidepanel;
  margin-top: 44px;
  justify-self: end;

  width: 350px;
  min-width: 350px;
  height: fit-content;
  background-color: var(--grey-08);
  border: var(--border-thin) solid var(--grey-20);

  @media (max-width: ${props => props.theme.breakpoints.desktop}px) {
    margin-top: 0;
    width: 320px;
    min-width: 320px;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}px), print {
    margin-top: 0;
    width: 100%;
  }
`;

export const SnackBar = styled(StyledSnackBar)`
  ${props =>
    !props.visible
      ? css`
          display: none;
          box-shadow: none;
        `
      : ''}
  padding: var(--size-lg) var(--size-lg-plus) var(--size-md) var(--size-lg-plus);

  @media (max-width: ${props => props.theme.breakpoints.portrait}px) {
    padding: var(--size-lg) var(--size-md-plus) var(--size-md) var(--size-md-plus);
  }
  background: var(--white);
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--size-md);
`;

export const PrimaryButton = styled(StyledButton)`
  width: 100%;
`;

export const TabPanel = styled.div`
  width: 100%;
  height: fit-content;
  grid-area: tabs;
`;

export const TabContents = styled.div`
  width: 100%;
  grid-area: contentPanel;
`;
