import { css } from '@emotion/css';
import { components, GroupBase, NoticeProps } from 'react-select';
import {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  LoadingIndicatorProps,
} from 'react-select/dist/declarations/src/components/indicators';
import Svg from 'components/Svg/Svg';
import Loader from 'components/Loader/Loader';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

function SelectInputV5DownChevron<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
): JSX.Element {
  return (
    <components.DropdownIndicator {...props}>
      <Svg name="caret-down" />
    </components.DropdownIndicator>
  );
}

function SelectInputV5ClearIndicator<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: ClearIndicatorProps<Option, IsMulti, Group>
): JSX.Element {
  return <components.ClearIndicator {...props}>{<Svg name="remove" />}</components.ClearIndicator>;
}

function SelectInputV5LoadingIndicator<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: LoadingIndicatorProps<Option, IsMulti, Group>
): JSX.Element {
  const loadingIndicatorStyles = props.getStyles('loadingIndicator', props);
  return (
    <div className={css(loadingIndicatorStyles)}>
      <Loader color="BLACK" size="SMALL" />
    </div>
  );
}

function SelectInputV5LoadingMessage<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: NoticeProps<Option, IsMulti, Group>
): JSX.Element {
  return (
    <components.LoadingMessage {...props}>
      <Loader color="GRAY" size="MEDIUM" />
    </components.LoadingMessage>
  );
}

function V5Components<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  components?: Partial<SelectComponents<Option, IsMulti, Group>>
) {
  return {
    DropdownIndicator: SelectInputV5DownChevron,
    ClearIndicator: SelectInputV5ClearIndicator,
    LoadingIndicator: SelectInputV5LoadingIndicator,
    LoadingMessage: SelectInputV5LoadingMessage,
    ...components,
  };
}

export default V5Components;
