import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import get from 'lodash/get';
import values from 'lodash/values';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import memoizeOne from 'memoize-one';
import { getUseCase } from 'utils/Data/performance';
import { useTranslations } from 'decorators/Translations/translations';

import OPICardsSkeleton from 'components/OPICard/OPICardsSkeleton';
import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';

import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { FLOOR_OPI } from './FloorOPIUtils';
import { CELSIUS } from 'utils/Data/values';
import { modalConfigNames } from '../dialogUtils';

const getUseCases = memoizeOne(data => {
  const devices = flatMap(values(data), group => group.sensors).map(sensor => sensor && sensor.parent);
  return uniq(devices.map(device => getUseCase(get(device, 'sensorType.name'))).filter(_ => _));
});

const FloorOPICards = ({ loading, ctxHelpOverride, openDialog, data }) => {
  const [t] = useTranslations();

  if (loading) {
    return <OPICardsSkeleton numberOfOPICards={3} />;
  }

  if (!data || !some(data, opi => opi.sensors && opi.sensors.length > 0)) {
    return null;
  }

  const hasOnClick = typeof openDialog === 'function';

  const airQuality = data[FLOOR_OPI.airQuality] || {};
  const temperature = data[FLOOR_OPI.temperature] || {};
  const co2 = data[FLOOR_OPI.co2] || {};
  const humidity = data[FLOOR_OPI.humidity] || {};
  const pm10 = data[FLOOR_OPI.pm10] || {};
  const pm25 = data[FLOOR_OPI.pm25] || {};
  const pressureDifference = data[FLOOR_OPI.pressureDifference] || {};
  const radon = data[FLOOR_OPI.radon] || {};
  const tvoc = data[FLOOR_OPI.tvoc] || {};
  const presenceArea = data[FLOOR_OPI.presenceArea] || {};
  const presenceSeat = data[FLOOR_OPI.presenceSeat] || {};
  const presenceZone = data[FLOOR_OPI.presenceZone] || {};
  const areaUtilizations = data[FLOOR_OPI.areaUtilizations] || [];

  const useCases = getUseCases(data);
  const performanceTitle = t(useCases.length ? 'Technical performance' : 'Indoor Air Quality');

  const renderedOPICards = [
    airQuality.sensors && airQuality.sensors.length > 0 && (
      <OPICard
        key="OPICard-airQuality"
        title={performanceTitle}
        subtitle={t('7 Days Average')}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} Indoor Air Quality`,
          override: ctxHelpOverride,
        }}
        contentWithCircle={{
          value: airQuality.value || 0,
          isAirQuality: true,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: airQuality.sensors.map(sensor => sensor.id),
                  modalConfigName: useCases.length
                    ? modalConfigNames.TECHNICAL_PERFORMANCE
                    : modalConfigNames.INDOOR_AIR_QUALITY,
                  includeMainSensor: true,
                })
            : undefined
        }
      />
    ),
    !isNil(temperature.value) && (
      <OPICard
        key="OPICard-temperature"
        title={t('Temperature')}
        subtitle={temperature.latestTime}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} Temperature`,
          override: ctxHelpOverride,
        }}
        content={{
          value: temperature.value,
          unit: CELSIUS,
          status: temperature.statusValue,
          isInvalid: temperature.valueIsInvalid,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: temperature.sensors.map(sensor => sensor.id),
                  modalConfigName: modalConfigNames.FLOOR_OPI,
                })
            : undefined
        }
      />
    ),
    !isNil(co2.value) && (
      <OPICard
        key="OPICard-co2"
        title="CO₂"
        subtitle={co2.latestTime}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} CO2`,
          override: ctxHelpOverride,
        }}
        content={{
          value: co2.value,
          unit: get(co2, ['sensors', 0, 'sensorType', 'unit']),
          status: co2.statusValue,
          isInvalid: co2.valueIsInvalid,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: co2.sensors.map(sensor => sensor.id),
                  modalConfigName: modalConfigNames.FLOOR_OPI,
                })
            : undefined
        }
      />
    ),
    !isNil(humidity.value) && (
      <OPICard
        key="OPICard-humidity"
        title={t('Humidity')}
        subtitle={humidity.latestTime}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} Humidity`,
          override: ctxHelpOverride,
        }}
        content={{
          value: parseFloat(humidity.value).toFixed(0),
          unit: get(humidity, ['sensors', 0, 'sensorType', 'unit']) || '',
          status: humidity.statusValue,
          isInvalid: humidity.valueIsInvalid,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: humidity.sensors.map(sensor => sensor.id),
                  modalConfigName: modalConfigNames.FLOOR_OPI,
                })
            : undefined
        }
      />
    ),
    !isNil(pressureDifference.value) && (
      <OPICard
        key="OPICard-pressureDifference"
        title={t('Pressure difference')}
        subtitle={pressureDifference.latestTime}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} Pressure difference`,
          override: ctxHelpOverride,
        }}
        content={{
          value: parseFloat(pressureDifference.value).toFixed(0),
          unit: get(pressureDifference, ['sensors', 0, 'sensorType', 'unit']) || '',
          status: pressureDifference.statusValue,
          isInvalid: pressureDifference.valueIsInvalid,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: pressureDifference.sensors.map(sensor => sensor.id),
                  modalConfigName: modalConfigNames.FLOOR_OPI,
                })
            : undefined
        }
      />
    ),
    !isNil(pm10.value) && (
      <OPICard
        key="OPICard-pm10"
        title="PM10"
        subtitle={pm10.latestTime}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} PM10`,
          override: ctxHelpOverride,
        }}
        content={{
          value: pm10.value,
          unit: get(pm10, ['sensors', 0, 'sensorType', 'unit']),
          status: pm10.statusValue,
          isInvalid: pm10.valueIsInvalid,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: pm10.sensors.map(sensor => sensor.id),
                  modalConfigName: modalConfigNames.FLOOR_OPI,
                })
            : undefined
        }
      />
    ),
    !isNil(pm25.value) && (
      <OPICard
        key="OPICard-pm25"
        title="PM2.5"
        subtitle={pm25.latestTime}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} PM2.5`,
          override: ctxHelpOverride,
        }}
        content={{
          value: pm25.value,
          unit: get(pm25, ['sensors', 0, 'sensorType', 'unit']),
          status: pm25.statusValue,
          isInvalid: pm25.valueIsInvalid,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: pm25.sensors.map(sensor => sensor.id),
                  modalConfigName: modalConfigNames.FLOOR_OPI,
                })
            : undefined
        }
      />
    ),
    !isNil(radon.value) && (
      <OPICard
        key="OPICard-radon"
        title={t('Radon')}
        subtitle={radon.latestTime}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} Radon`,
          override: ctxHelpOverride,
        }}
        content={{
          value: radon.value,
          unit: get(radon, ['sensors', 0, 'sensorType', 'unit']),
          status: radon.statusValue,
          isInvalid: radon.valueIsInvalid,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: radon.sensors.map(sensor => sensor.id),
                  modalConfigName: modalConfigNames.FLOOR_OPI,
                })
            : undefined
        }
      />
    ),
    !isNil(tvoc.value) && (
      <OPICard
        key="OPICard-tvoc"
        title="TVOC"
        subtitle={tvoc.latestTime}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} TVOC`,
          override: ctxHelpOverride,
        }}
        content={{
          value: tvoc.value,
          unit: get(tvoc, ['sensors', 0, 'sensorType', 'unit']),
          status: tvoc.statusValue,
          isInvalid: tvoc.valueIsInvalid,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: tvoc.sensors.map(sensor => sensor.id),
                  modalConfigName: modalConfigNames.FLOOR_OPI,
                })
            : undefined
        }
      />
    ),
    !isNil(presenceArea.value) && (
      <OPICard
        key="OPICard-presenceArea"
        title={t('Meeting Room Utilization')}
        subtitle={t('Last 7 days')}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} Meeting Room Utilization`,
          override: ctxHelpOverride,
        }}
        contentWithCircle={{
          value: presenceArea.value,
          neutral: true,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: presenceArea.sensors.map(sensor => sensor.id),
                  modalConfigName: modalConfigNames.MEETING_ROOM_UTILIZATION,
                })
            : undefined
        }
      />
    ),
    !isNil(presenceZone.value) && (
      <OPICard
        key="OPICard-presenceZone"
        title={t('Open Office Utilization')}
        subtitle={t('Last 7 days')}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} Open Office Utilization`,
          override: ctxHelpOverride,
        }}
        contentWithCircle={{
          value: presenceZone.value,
          neutral: true,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: presenceZone.sensors.map(sensor => sensor.id),
                  modalConfigName: modalConfigNames.OPEN_OFFICE_UTILIZATION,
                })
            : undefined
        }
      />
    ),
    !isNil(presenceSeat.value) && (
      <OPICard
        key="OPICard-presenceSeat"
        title={t('Seat Utilization')}
        subtitle={t('Last 7 days')}
        contextualHelp={{
          text: `${CTXHELP_PREFIX} Seat Utilization`,
          override: ctxHelpOverride,
        }}
        contentWithCircle={{
          value: presenceSeat.value,
          neutral: true,
        }}
        clickEventType={hasOnClick ? 'openModal' : undefined}
        onClick={
          hasOnClick
            ? () =>
                openDialog({
                  sensorIds: presenceSeat.sensors.map(sensor => sensor.id),
                  modalConfigName: modalConfigNames.SEAT_UTILIZATION,
                })
            : undefined
        }
      />
    ),
    ...areaUtilizations.map(
      (utilization, index) =>
        !isNil(utilization.value) && (
          <OPICard
            key={`OPICard-areaUtilizations-${index}`}
            title={t(utilization.title)}
            subtitle={utilization.latestTime}
            contextualHelp={{
              text: `${CTXHELP_PREFIX} Area Utilization Rate`,
              override: ctxHelpOverride,
            }}
            contentWithCircle={{
              value: utilization.value,
              status: utilization.status,
            }}
            clickEventType={hasOnClick ? 'openModal' : undefined}
            onClick={
              hasOnClick
                ? () =>
                    openDialog({
                      sensorIds: utilization.sensors.map(sensor => sensor.id),
                      modalConfigName: modalConfigNames.AREA_UTILIZATION,
                    })
                : undefined
            }
          />
        )
    ),
  ];

  if (renderedOPICards.filter(Boolean).length === 0) {
    return null;
  }

  return <OPICards>{renderedOPICards}</OPICards>;
};

export default FloorOPICards;

FloorOPICards.defaultProps = {
  loading: false,
  ctxHelpOverride: false,
};

FloorOPICards.propTypes = {
  data: PropTypes.object.isRequired,
  openDialog: PropTypes.func,
  loading: PropTypes.bool,
  ctxHelpOverride: PropTypes.bool,
};
