import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DialogModal from 'components/Dialog/DialogModal';

const CaptionStyle = styled.p`
  text-align: center;
  margin-top: var(--size-xs);
`;

export const ImageModal = ({ src, alt, caption }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {modalOpen ? (
        <DialogModal medium animate isActive onOverlayClick={() => setModalOpen(false)}>
          <img src={src} alt={alt} />
          {caption ? <CaptionStyle>{caption}</CaptionStyle> : null}
        </DialogModal>
      ) : null}

      <img src={src} alt={alt} onClick={() => setModalOpen(true)} />
      {caption ? <CaptionStyle>{caption}</CaptionStyle> : null}
    </>
  );
};

export default ImageModal;

ImageModal.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  caption: PropTypes.string,
};
