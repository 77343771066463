import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';

import styled, { css, useTheme } from 'styled-components';
import SkeletonPill from 'components/Skeletons/SkeletonPill';
import { SelectInputV5 } from 'components/Select/SelectInputV5';
import oldSelectInputStyles from 'components/Form/oldSelectInputStyles';

export const NO_FILTER = 'NO_FILTER';

const Block = styled.div`
  display: flex;
  ${props =>
    props.labelPosition === 'left' &&
    css`
      flex-direction: row;
      justify-content: center;
      align-items: center;
    `}
  ${props =>
    props.labelPosition === 'top' &&
    css`
      margin-right: ${props => (props.noSpacing ? 0 : 'var(--size-md)')};
      padding-bottom: ${props => (props.noSpacing ? 0 : 'var(--size-xs)')};
      flex-direction: column;
    `}
`;

const Content = styled.div`
  min-width: 14em;
`;

const Label = styled.label`
  flex-grow: 0;
  line-height: ${props => props.theme.lineHeight.text};
  display: block;
  margin: ${props => (props.position === 'left' ? '0 1em 0 0' : '0.5em 0 1em 0')};
  color: var(--filter-input-label-fg);
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: bold;
`;

const SkeletonContainer = styled.div`
  height: var(--input-height);
  display: flex;
  align-items: center;
`;

const showAll = showAllText => ({ value: NO_FILTER, text: showAllText });
// Sort the options based on the sort
const sortedOptionsBy = (options, sort) => sortBy(options.slice(), sort);
const withShowAllOption = (noFilterLast, sortedOptions, showAllOption) =>
  noFilterLast ? concat(sortedOptions, showAllOption) : concat(showAllOption, sortedOptions);
// Get the first line of text.
const renderText = text => {
  return text && text.split('\n')[0];
};
const withLabel = option => ({
  label: renderText(option.text),
  value: option.value,
});
const selectOptions = options => options.map(withLabel);
const isSelected = selectedFilter => option => option.text === selectedFilter || option.value === selectedFilter;
const selectedOption = (options, isSelected) => options.find(isSelected);

/**
 * An older implementation that uses option conversion for backwards compatibility with existing code.
 *
 * @deprecated Use SelectFilter instead.
 * @see {@link SelectFilter}
 */
const FilterDropdown = props => {
  const { handleChange, selectedFilter, noFilterLast, options: inputOptions, sort, showAllText } = props;
  const theme = useTheme();
  const styles = useMemo(() => oldSelectInputStyles(theme), [theme]);
  const onChange = useCallback(selectedValue => handleChange(selectedValue.value), [handleChange]);
  const value = useMemo(
    () => withLabel(selectedOption(inputOptions, isSelected(selectedFilter)) || showAll(showAllText)),
    [inputOptions, selectedFilter, showAllText]
  );
  const options = useMemo(
    () => selectOptions(withShowAllOption(noFilterLast, sortedOptionsBy(inputOptions, sort), showAll(showAllText))),
    [noFilterLast, inputOptions, sort, showAllText]
  );

  return (
    <Block labelPosition={props.labelPosition} className={props.className} noSpacing={props.noSpacing}>
      {props.label && (
        <Label id={'Filter' + props.label} position={props.labelPosition}>
          {props.label}
        </Label>
      )}
      <Content>
        {props.loading ? (
          <SkeletonContainer>
            <SkeletonPill margin="0px" />
          </SkeletonContainer>
        ) : (
          <SelectInputV5
            name="select-dropdown"
            styles={styles}
            value={value}
            onChange={onChange}
            options={options}
            isClearable={false}
            isSearchable={false}
            simpleValue
            isDisabled={props.disabled}
          />
        )}
      </Content>
    </Block>
  );
};

FilterDropdown.defaultProps = {
  label: null,
  labelPosition: 'top',
  selectedFilter: NO_FILTER,
  sort: null,
  loading: false,
  disabled: false,
  showAllText: 'Show all',
  noFilterLast: false,
  className: '',
  noSpacing: false,
};

FilterDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  selectedFilter: PropTypes.any,
  sort: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  showAllText: PropTypes.string,
  noFilterLast: PropTypes.bool,
  className: PropTypes.string,
  noSpacing: PropTypes.bool,
};

export default FilterDropdown;
