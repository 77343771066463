import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadFunctionalLocations } from 'redux/modules';

const useFunctionalLocations = functionalLocationKeys => {
  const dispatch = useDispatch();

  const functionalLocations = useSelector(state => state.functionalLocations.functionalLocations);

  useEffect(() => {
    dispatch(loadFunctionalLocations(functionalLocationKeys));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return functionalLocations;
};

export default useFunctionalLocations;
