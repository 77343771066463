import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslations } from 'decorators/Translations/translations';

import { Ruler } from 'components/Ruler';
import SensorsNames from './SensorsNames';

const Container = styled.div`
  margin: 0 var(--size-md);

  ${props => props.theme.media.portrait`
    margin: 0 var(--size-lg);
  `}
`;

const Series = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-lg);
`;

const SeriesSensorsNames = ({ seriesData }) => {
  const [t] = useTranslations();

  return (
    <Container>
      <Ruler />
      <Series>
        {seriesData.map((series, index) => {
          return (
            <SensorsNames
              key={`sensorsNames-${index}`}
              seriesName={series.name || `${t('Series')} ${index + 1}`}
              xName={series.xName}
              yName={series.yName}
              color={series.color}
            />
          );
        })}
      </Series>
    </Container>
  );
};

export default SeriesSensorsNames;

SeriesSensorsNames.propTypes = {
  seriesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
      xName: PropTypes.string,
      yName: PropTypes.string,
    })
  ).isRequired,
};
