import styled from 'styled-components';

export const ConfigurationModalHeader = styled.h3`
  margin: var(--size-xs) 0 var(--size-lg);
`;
ConfigurationModalHeader.displayName = 'ConfigurationModalHeader';

export const HelpText = styled.p`
  color: ${props => props.theme.input.font.color.default};
  font-size: ${props => props.theme.font.size.xs};
  line-height: var(--line-height-default);
  margin: var(--size-sm-plus) 0;
`;
HelpText.displayName = 'HelpText';

export const ConfigurationModalSection = styled.div`
  margin: var(--size-md) 0 var(--size-md-plus);
`;
ConfigurationModalSection.displayName = 'ConfigurationModalSection';

export const ConfigurationModalSectionHeader = styled.h5`
  margin: var(--size-sm-plus) 0;
`;
ConfigurationModalSectionHeader.displayName = 'ConfigurationModalSectionHeader';

export const ConfigurationList = styled.ul`
  margin: var(--size-md) 0 var(--size-sm);
`;
ConfigurationList.displayName = 'ConfigurationList';

export const ConfigurationListItem = styled.li`
  border-bottom: 1px solid ${props => props.theme.colors.mystic};
  padding: var(--size-sm);
`;
ConfigurationListItem.displayName = 'ConfigurationListItem';
