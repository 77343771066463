import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AccordionItemWrapper } from './Accordion';
import { CaretToggle } from 'components/CaretToggle/CaretToggle';

const StyledTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-md) 0;
  border-top-width: var(--border-thin);
  border-style: solid;
  border-color: var(--light-separator-color);

  ${AccordionItemWrapper}:first-child & {
    border-width: 0;
  }
`;
StyledTitle.displayName = 'StyledTitle';

const AccordionTitle = ({ title = '', open = false, children, handleClick = undefined, ...props }) => {
  const handleTitleClick = e => {
    if (typeof handleClick === 'function') {
      handleClick(e);
    }
  };

  return (
    <StyledTitle {...props}>
      <div onClick={handleTitleClick}>
        <CaretToggle type="accordion" isOpen={open} /> {title}
      </div>
      {children && <div>{children}</div>}
    </StyledTitle>
  );
};

AccordionTitle.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  handleClick: PropTypes.func,
};

export default AccordionTitle;
