import styled from 'styled-components';

const ChatBubble = styled.div`
  font-size: ${props => props.theme.font.size.xs};
  line-height: ${props => props.theme.font.lineHeight.lg};
  color: var(--chat-bubble-fg);
  margin-top: var(--size-xs);
  padding: var(--size-sm);
  border-radius: 0 8px 8px;
  background-color: var(--chat-bubble-bg);
  width: ${props => (props.fullWidth ? '100%' : 'fit-content')};
`;

export default ChatBubble;
