import { Children, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

export const Items = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  overflow-x: auto;

  @media print {
    flex-wrap: wrap;
  }
`;
Items.displayName = 'Items';

const areSame = (item1, item2) => {
  if (!item1.id || !item2.id) {
    return item1.name === item2.name;
  }
  return item1.id === item2.id;
};

const SensorItems = ({ model, property, onClick, children, disabled }) => {
  const selected = get(model, property);

  const newChildren = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        selected: !disabled && areSame(child.props.value, selected),
        property,
        onClick: disabled ? undefined : onClick,
      });
    }
  });

  return <Items>{newChildren}</Items>;
};

SensorItems.propTypes = {
  onClick: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default SensorItems;
