import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

const StyledUserManualHeader = styled.div`
  width: 100%;

  h1 {
    margin-right: var(--size-md);
  }

  p {
    margin: var(--size-md) 0 var(--size-xl);
  }
`;

StyledUserManualHeader.displayName = 'StyledUserManualHeader';

export const UserManualHeader = ({ data }) => {
  if (data && data.title) {
    return (
      <StyledUserManualHeader data-test-id="UserManualHeader">
        <PrismicRichText field={data.title} />
        <PrismicRichText field={data.version} />
        <PrismicRichText field={data.description} />
      </StyledUserManualHeader>
    );
  }
};

UserManualHeader.propTypes = {
  data: PropTypes.object.isRequired,
};
