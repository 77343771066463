import { useCallback } from 'react';

import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import useNotification from 'decorators/Notification/useNotification';
import DocumentService from 'services/document';
import useQuery from 'decorators/Query/useQuery';

const useCommentAttachment = () => {
  const { t } = useUnauthenticatedTranslations();
  const { showNotification, renderedNotification } = useNotification();
  const {
    query: { token },
  } = useQuery();

  const downloadFile = useCallback(
    (fileId, inline = false) => {
      DocumentService.downloadUrlForAdditionalSaleWithToken({ fileId, token })
        .then(result => {
          if (inline) {
            window.open(result.sasUrl, 'name');
          } else {
            window.location.href = result.sasUrl;
          }
        })
        .catch(() => showNotification({ autoHide: true, type: 'error', message: t('Failed') }));
    },
    [showNotification, t, token]
  );

  return { downloadFile, renderedNotification };
};

export default useCommentAttachment;
