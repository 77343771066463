import { useState, useEffect, useCallback } from 'react';
import MasterDataService from 'services/masterData';
import DocumentService from 'services/document';
import mergeWith from 'lodash/mergeWith';
import groupBy from 'lodash/groupBy';

import useQuery from 'decorators/Query/useQuery';
import { useTranslations } from 'decorators/Translations/translations';
import {
  createAndUpdateChecklistReportItemsWithFiles,
  createChecklistReportWithFiles,
  downloadFileWithToken,
} from 'containers/Application/Checklists/reports/reportHelpers';

const useTechniciansReport = (hasChecklistTemplate, functionalLocationId, showNotification) => {
  const [report, setReport] = useState(null);
  const [template, setTemplate] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [reportFiles, setReportFiles] = useState({});

  const {
    query: { token },
  } = useQuery();
  const [t] = useTranslations();

  const load = async () => {
    try {
      const templateResult = await MasterDataService.checklistTemplateWithToken(token);

      if (templateResult) {
        const reportResult = await MasterDataService.checklistReportsWithToken(token);
        const reportForTemplate = reportResult.find(report => report.checklistTemplateId === templateResult.id);

        setTemplate(templateResult);
        setReport(reportForTemplate || null);

        if (reportForTemplate) {
          const files = await DocumentService.findForChecklistReportWithToken({
            token,
            checklistReportId: reportForTemplate.id,
          });
          setReportFiles(groupBy(files, 'checklistReportItemId'));
        }
      }
    } catch (e) {
      setErrorMessage('Checklist not found');
    }
  };

  useEffect(() => {
    if (token && hasChecklistTemplate) {
      load();
    }
  }, [token, hasChecklistTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitReport = useCallback(
    async reportModel => {
      let savingError, reportResult, itemsResult;

      if (reportModel.id) {
        try {
          const { reportItems, ...reportForUpdate } = reportModel;
          reportResult = await MasterDataService.updateChecklistReportWithToken(token, reportForUpdate);
          itemsResult = await createAndUpdateChecklistReportItemsWithFiles({
            functionalLocationId,
            checklistReportId: reportModel.id,
            reportItems,
            token,
          });
        } catch (error) {
          savingError = error;
        }
      } else {
        try {
          reportResult = await createChecklistReportWithFiles({
            checklistReport: reportModel,
            functionalLocationId,
            token,
          });
          itemsResult = reportResult.reportItems;
        } catch (error) {
          savingError = error;
        }
      }

      if (reportResult && itemsResult) {
        setReport({ ...reportResult, reportItems: itemsResult });
        const filesByItemId = Object.fromEntries(itemsResult.map(item => [item.id, item.uploadedFiles]));
        setReportFiles(oldFiles =>
          mergeWith(filesByItemId, oldFiles, (a, b) => {
            if (Array.isArray(a)) {
              return a.concat(b);
            }
          })
        );
        MasterDataService.checklistReportsWithToken(token, { onlyInvalidateCache: true });
        DocumentService.findForChecklistReportWithToken({
          token,
          checklistReportId: reportResult.id,
          onlyInvalidateCache: true,
        });
      }

      if (savingError) {
        showNotification({ type: 'error', message: t('Saving failed'), autoHide: true });
        throw new Error(savingError);
      } else {
        showNotification({ type: 'success', message: t('Saved successfully'), autoHide: true });
      }
    },
    [t, showNotification, token, functionalLocationId]
  );

  const downloadFile = useCallback(
    (file, inline) => downloadFileWithToken(token, report.id, file, inline),
    [token, report]
  );

  return [template, report, reportFiles, submitReport, downloadFile, errorMessage];
};

export default useTechniciansReport;
