import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

/**
 * Get system config related state.
 */
export const getConfig = state => state.config;

/**
 * Get system config values grouped by config name and division.
 */

const getValuesByDivision = items => mapValues(keyBy(items, 'division'), 'value');
const getDivisionValuesByName = items => mapValues(groupBy(items, 'name'), getValuesByDivision);
const getConfigHierarchy = config => mapValues(groupBy(config, 'category'), getDivisionValuesByName);

export const getFullDivisionConfigHierarchy = createSelector(
  state => getConfig(state).data,
  config => getConfigHierarchy(config)
);

export const getDivisionConfig = createSelector(
  state => getConfig(state).data,
  config => getDivisionValuesByName(config)
);
