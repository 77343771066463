import styled from 'styled-components';
import PropTypes from 'prop-types';

import { withErrorBoundary } from 'components/ErrorPage/ErrorBoundary';
import HorizontalScroll, {
  HorizontallyScrollable,
  horizontallyScrollableMobile,
} from '../HorizontalScroll/HorizontalScroll';

export const opiCardType = Object.freeze({
  DEFAULT: 'default',
  COMPACT: 'compact',
});

const Container = styled.div`
  position: relative;
  overflow-x: hidden;

  ${props => props.theme.media.tablet`
    overflow-x: visible;

    ${HorizontallyScrollable} {
      overflow: visible;
    }
  `};

  ${horizontallyScrollableMobile}
`;

const OPICardsWrapper = styled.div`
  display: flex;
  ${props => props.theme.media.tablet`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: var(--size-md);
    grid-auto-rows: 1fr;
  `};
  ${props => props.theme.media.bigDesktop`
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  `};
`;

const OPICardsWrapperCompact = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1px;
  grid-auto-rows: 1fr;
`;

const OPICards = props => {
  const { children, type = opiCardType.DEFAULT, ...other } = props;

  if (type === opiCardType.COMPACT) {
    return <OPICardsWrapperCompact>{children}</OPICardsWrapperCompact>;
  }

  return (
    <Container {...other}>
      <HorizontalScroll>
        <OPICardsWrapper>{children}</OPICardsWrapper>
      </HorizontalScroll>
    </Container>
  );
};

OPICards.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.values(opiCardType)),
};

export default withErrorBoundary(OPICards);
