import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';
import { breakpoints } from 'styles/definitions';

const breakbooleans: { [key: string]: boolean } = {};

export function activeBreakpoints() {
  return Object.entries(breakpoints).reduce((accu, [breakpoint, size]) => {
    accu[breakpoint] = size <= window.innerWidth;
    return accu;
  }, breakbooleans);
}

export function useBreakpoints() {
  const [breakpoints, setBreakpoints] = useState(activeBreakpoints());

  useEffect(() => {
    const updateBreakpoints = throttle(() => {
      setBreakpoints(activeBreakpoints());
    }, 600);

    window.addEventListener('resize', updateBreakpoints);

    return () => window.removeEventListener('resize', updateBreakpoints);
  }, []);

  return breakpoints;
}
