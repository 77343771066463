import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableConfiguration from 'components/TableConfiguration/TableConfiguration';

import { DEFAULT_FL_COLUMNS } from 'constants/observations';
import { getColumns } from './observationsTableColumns';
import { setColumnConfig } from 'redux/modules/iot/notice';

const ObservationsTableConfiguration = props => {
  const { t, setColumnConfig, columnConfig, defaultColumns, noLocations } = props;

  // Use session or props config if it is set
  const configuration = columnConfig || defaultColumns || DEFAULT_FL_COLUMNS;
  const columns = getColumns(t, noLocations);

  const onSubmit = model => {
    const newConfiguration = columns
      .filter(option => model[option.accessor] && model[option.accessor].selected)
      .map(option => option.accessor);
    setColumnConfig(newConfiguration);
  };

  return <TableConfiguration configuration={configuration} configurationOptions={columns} onSubmit={onSubmit} />;
};

ObservationsTableConfiguration.propTypes = {
  t: PropTypes.func,
  defaultColumns: PropTypes.array,
  setColumnConfig: PropTypes.func.isRequired,
  columnConfig: PropTypes.array,
  noLocations: PropTypes.bool,
};

const mapStateToProps = state => ({
  columnConfig: state.notice.columnConfig,
});

const mapDispatchToProps = {
  setColumnConfig,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ObservationsTableConfiguration);
