import PropTypes from 'prop-types';

import DefaultCustomChart from './Default';
import ComparisonCustomChart from './Comparison';
import ScatterPlotCustomChart from './ScatterPlot';

import { CustomChart as CustomChartPropType } from './propTypes';
import { CHART_TYPES } from '../utils';
import useCustomChartKey from './useCustomChartKey';

const CustomChart = props => {
  const chartKey = useCustomChartKey(props.chart);
  const type = props.chart?.chartType;

  if (type === CHART_TYPES.DEFAULT) {
    return <DefaultCustomChart {...props} key={chartKey} />;
  }

  if (type === CHART_TYPES.COMPARISON) {
    return <ComparisonCustomChart {...props} />;
  }

  if (type === CHART_TYPES.SCATTER_PLOT) {
    return <ScatterPlotCustomChart {...props} key={chartKey} />;
  }

  return null;
};

CustomChart.propTypes = {
  chart: CustomChartPropType.isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

export default CustomChart;
