import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledTable } from './FlexTable';
import SkeletonAnimation from 'components/Skeletons/SkeletonAnimation';
import { StyledTableBody, Row, Cell } from './FlexTableBody';

const SkeletonCell = styled(Cell)`
  position: relative;
  overflow: hidden;
  background-color: ${props => props.theme.skeleton.backgroundColor};
  margin: var(--size-sm);

  & > * {
    border-radius: 4px;
  }
`;

const SKELETON_ROW_COUNT = 3;
export const SkeletonRow = ({ columns = [] }) => (
  <Row>
    {columns.map((column, cellIndex) => (
      <SkeletonCell style={column.style} hideOnMobile={column.hideOnMobile} key={cellIndex}>
        <SkeletonAnimation />
      </SkeletonCell>
    ))}
  </Row>
);

SkeletonRow.propTypes = {
  columns: PropTypes.array.isRequired,
};

export const RowWithSkeleton = props => {
  if (props.original?.loading) {
    return <SkeletonRow columns={props.columns}></SkeletonRow>;
  }
  return <Row {...props}></Row>;
};

export const FlexTableSkeleton = props => {
  const { columns } = props;

  return (
    <StyledTable>
      <StyledTableBody>
        {[...Array(SKELETON_ROW_COUNT)].map((row, rowIndex) => (
          <SkeletonRow key={rowIndex} columns={columns}></SkeletonRow>
        ))}
      </StyledTableBody>
    </StyledTable>
  );
};

FlexTableSkeleton.propTypes = {
  columns: PropTypes.array.isRequired,
};

export default FlexTableSkeleton;
