import { PureComponent } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import clone from 'lodash/clone';
import forEach from 'lodash/forEach';
import sumBy from 'lodash/sumBy';
import reject from 'lodash/reject';
import groupBy from 'lodash/groupBy';
import toDate from 'date-fns/toDate';
import { format } from 'utils/Date/dateFormatter';
import Highstock from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown';
import accessibility from 'highcharts/modules/accessibility';
import styled, { withTheme } from 'styled-components';
import { lighten } from 'polished';
import PropTypes from 'prop-types';

import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader';
import Responsive from 'components/Responsive/Responsive';

import { CELSIUS } from 'utils/Data/values';
import { getSymbolChar } from 'utils/String/symbols';
import { formatNumber } from 'utils/Number/decimalFormat';
import { getCommonExportingOptions, getCommonNavigationOptions, getAxisTextStyles } from './utils';
import simpleMultiValueTooltip from './simpleMultiValueTooltip';
import merge from 'lodash/merge';

drilldown(Highstock);
accessibility(Highstock);

const StyledBarChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .bar-chart {
    z-index: 1 !important;
  }
  .highcharts-breadcrumbs-button.highcharts-button-hover {
    rect {
      fill: ${props => props.theme.colors.lightGray} !important;
    }
  }
`;

const EMPTY_OBJECT = {};
class BarChart extends PureComponent {
  getConfig = () => {
    // Use 1.) explicit width and height, or 2.) Dimensions from Responsive, or 3.) Highcharts default.

    let chartWidth = this.props.width ? this.props.width : this.props.dimensions ? this.props.dimensions.width : null;
    const chartHeight = this.props.height
      ? this.props.height
      : this.props.dimensions
        ? this.props.dimensions.height
        : null;

    if (chartWidth > window.innerWidth) {
      chartWidth = window.innerWidth;
    }

    const series = cloneDeep(this.props.series);
    const self = this;

    // enhance temperature data and push them to series
    const outdoorsTemperatureTitle = this.props.t('Outdoors temperature');
    const outdoorsTemperatureColors = this.props.theme.charts.colors.map(color => lighten(0.1, color));

    if (this.props.temperatureSeries && this.props.temperatureSeries.length > 0) {
      forEach(this.props.temperatureSeries, (temperatureSeries, index) => {
        series.push({
          ...temperatureSeries,
          name: temperatureSeries.name,
          type: temperatureSeries.type || 'line',
          color: temperatureSeries.color || outdoorsTemperatureColors[index % outdoorsTemperatureColors.length],
          lineWidth: temperatureSeries.lineWidth || 1,
          dashStyle: temperatureSeries.dashStyle || 'Dash',
          data: temperatureSeries.data,
          _unit: temperatureSeries._unit,
          zIndex: 0,
          visible: temperatureSeries.visible ?? true,
          showInLegend: temperatureSeries.showInLegend !== undefined ? temperatureSeries.showInLegend : true,
          _showTooltipForZeroValue: true,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        });
      });
    }

    const config = {
      annotations: this.props.annotations,
      chart: {
        type: this.props.type || 'column',
        zoomType: this.props.noZoom ? undefined : 'yx',
        panning: !this.props.noZoom,
        panKey: 'shift',
        width: chartWidth,
        height: chartHeight,
        className: 'bar-chart',
        backgroundColor: this.props.backgroundColor || 'transparent',
        plotBorderWidth: this.props.plotBorderWidth,
        plotBorderColor: this.props.plotBorderColor,
        plotBackgroundColor: this.props.plotBackgroundColor,
        style: {
          cursor: this.props.pointer ? this.props.pointer : this.props.onClick && 'pointer',
          fontFamily: this.props.theme.fontFamily.text,
        },
        events: {
          click: this.props.onClick,
          selection: this.props.onSelection,
          drilldown: this.props.onDrilldown,
          drillup: this.props.onDrillup,
          render: this.props.onRender,
        },
        inverted: this.props.inverted,
        ...(this.props.chartSpacing && { spacing: this.props.chartSpacing }),
      },
      drilldown: {
        allowPointDrilldown: false,
        series: [],
        colors: this.props.theme.charts.colors,
        activeAxisLabelStyle: this.props.labelStyle,
        breadcrumbs: {
          buttonTheme: {
            fill: this.props.theme.colors.alabaster,
            stroke: this.props.theme.colors.silver,
            'stroke-width': 1,
            padding: 8,
            style: {
              color: this.props.theme.colors.black,
            },
          },
          floating: true,
          position: {
            align: 'right',
          },
          showFullPath: false,
          formatter: function () {
            return self.props.t('Zoom out');
          },
        },
      },
      colors: this.props.theme.charts.colors,
      title: { text: this.props.title || '' },
      subtitle: { text: this.props.subtitle || '' },
      xAxis: merge({}, getAxisTextStyles(this.props.theme), {
        visible: true,
        categories: this.props.categories,
        crosshair: true,
        labels: {
          enabled: true,
          style: this.props.labelStyle,
          rotation: this.props.labelRotation,
          formatter: this.props.labelFormatter,
        },
        title: {
          text: this.props.xTitle,
        },
        type: this.props.xAxisType || 'datetime',
        minRange: this.props.minXRange,
        max: this.props.maxX,
      }),
      legend: {
        align: this.props.legendAlign ? this.props.legendAlign : 'right',
        margin: this.props.legendAlign === 'left' ? 26 : 16,
        verticalAlign: 'top',
        borderWidth: 0,
        reversed: this.props.legendReversed || false,
      },
      tooltip: {
        enabled: !this.props.disableTooltip,
        outside: false,
        backgroundColor: 'transparent',
        borderWidth: 0,
        shadow: false,
        padding: 0,
        valueDecimals: this.props.valueDecimals,
        shared: true,
        useHTML: true,
        positioner: function (labelWidth, labelHeight, point) {
          const chartWidth = this.chart.chartWidth;
          const outOfBounds = point.plotX + labelWidth > chartWidth - 10;
          const rightMaxPosition = chartWidth - labelWidth - 10;
          const xPosition = outOfBounds ? rightMaxPosition : point.plotX;
          return {
            x: xPosition,
            y: 25,
          };
        },
        formatter: function () {
          let headerText = '';
          if (typeof self.props.getTooltipHeader === 'function') {
            headerText = self.props.getTooltipHeader(this.x, this.y, this.points);
          } else if (self.props.categories || self.props.xAxisType === 'category') {
            headerText = this.points[0].key;
          } else if (self.props.hideTime) {
            headerText = format(
              toDate(this.x),
              'do MMM',
              this.points?.[0]?.point?.options?.useUTC ? { timeZone: 'UTC' } : {}
            );
          } else {
            headerText = format(
              toDate(this.x),
              'do MMM HH:mm',
              this.points?.[0]?.point?.options?.useUTC ? { timeZone: 'UTC' } : {}
            );
          }

          const points = [...this.points];

          if (self.props.showTooltipTotal) {
            const total = sumBy(this.points, 'y');
            points.push({
              series: {
                ...points[0].series,
                name: self.props.t('Total'),
                color: self.props.theme.colors.black,
              },
              point: {},
              y: formatNumber(self.props.language, total),
            });
          }

          const tooltipPoints = points.reduce((accu, point) => {
            if (point && (point.y !== 0 || point.series.userOptions._showTooltipForZeroValue)) {
              const color = !point.series.userOptions._noTooltipColors && point.series.color;
              const symbol = getSymbolChar(point.series.symbol);

              let maximumFractionDigits = 2;

              if (point.series.userOptions._unit === CELSIUS) {
                maximumFractionDigits = 1;
              } else if (self.props.noRounding) {
                maximumFractionDigits = undefined; // uses default value from locale
              } else if (Number.isFinite(self.props.valueDecimals)) {
                maximumFractionDigits = self.props.valueDecimals;
              }

              const valuePart = formatNumber(self.props.language, point.y, { maximumFractionDigits });
              const unitPart = (!point.series.userOptions._hideTooltipUnit && point.series.userOptions._unit) || '';

              const value = `${valuePart} ${unitPart}`;

              return accu.concat([
                {
                  color,
                  symbol,
                  label: self.props.hideLegend ? '' : point.series.name,
                  value,
                  warning: point.point.warning,
                },
              ]);
            }
            return accu;
          }, []);

          return simpleMultiValueTooltip({ theme: self.props.theme, headerText, points: tooltipPoints });
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          pointWidth: this.props.pointWidth,
          enableMouseTracking: !this.props.disableTooltip,
          animation: !this.props.disableAnimation && !this.props.disableTooltip,
          colorByPoint: this.props.colorByPoint || false,
          stacking: this.props.stacked ? 'normal' : undefined,
          point: {
            events: {
              click: this.props.onClick,
            },
          },
          pointStart: this.props.pointStart,
          pointInterval: this.props.pointInterval,
        },
        line: {
          marker: {
            states: {
              hover: {
                lineWidthPlus: 0,
                radiusPlus: 0,
                shadow: false,
                animation: { duration: 0 },
              },
            },
          },
          states: {
            hover: {
              lineWidthPlus: 0,
              halo: { size: 0 },
            },
            inactive: {
              opacity: 1,
            },
          },
        },
        spline: {
          marker: {
            fillColor: this.props.theme.colors.white,
            lineWidth: 2,
            lineColor: null, // inherit from series
            states: {
              hover: {
                lineWidthPlus: 0,
                radiusPlus: 0,
                shadow: false,
                animation: { duration: 0 },
              },
            },
            enabled:
              this.props.plotOptions?.spline?.marker?.enabled !== undefined
                ? this.props.plotOptions?.spline.marker.enabled
                : true,
          },
          states: {
            hover: {
              lineWidthPlus: 0,
              halo: { size: 0 },
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      series,
      exporting: getCommonExportingOptions({ title: this.props.title, categories: this.props.categories }),
      navigation: getCommonNavigationOptions(),
    };

    if (this.props.colors) {
      config.colors = this.props.colors;
    }
    if (this.props.hideLegend) {
      config.legend.enabled = false;
    }

    // recycling rates specific y-axis config
    if (this.props.yAxisConfig) {
      config.yAxis = this.props.yAxisConfig;
    } else {
      // y-axis configurations for different units
      const seriesByUnits = groupBy(series, '_unit');
      const units = reject(Object.keys(seriesByUnits), key => key === 'undefined');
      const isOnlyUnitTemperature =
        units.length === 0 && (!this.props.temperatureSeries || this.props.temperatureSeries.length === 0);
      if (units.length >= 1 || isOnlyUnitTemperature) {
        config.yAxis = [];
        units.forEach((unit, unitIndex) => {
          const yAxis = merge({}, getAxisTextStyles(this.props.theme), {
            title: { text: !this.props.hideYTitle && (this.props.yTitle || unit) },
            allowDecimals: !this.props.hideDecimals,
            opposite: this.props.yOpposite,
            tickInterval:
              this.props.yTickInterval === 'auto'
                ? undefined
                : this.props.yTickInterval || (unit === CELSIUS ? 10 : undefined),
            max: this.props.yMax,
            min: this.props.yMin,
            labels: {
              format: `{value}${this.props.hideYTitle ? unit : ''}`,
              style: this.props.labelStyle,
            },
            plotLines: clone(this.props.plotLines),
          });
          if (Boolean(unitIndex)) {
            yAxis.opposite = true;
            yAxis.title.text = !this.props.hideYTitle
              ? unit === CELSIUS
                ? `${outdoorsTemperatureTitle}(${CELSIUS})`
                : unit
              : undefined;
            yAxis.gridLineWidth = 0;
            yAxis.tickInterval = this.props.yTickInterval || unit === CELSIUS ? 1 : undefined;
            yAxis.minRange = unit === CELSIUS ? 5 : undefined;
          }
          // push to config and add config-index to all the series of this unit
          config.yAxis.push(yAxis);
          seriesByUnits[unit].forEach(series => {
            series.yAxis = unitIndex;
          });
        });
      } else {
        // Disable y-axis title if no units are given.
        config.yAxis = { title: { text: null } };
      }
    }

    if (this.props.dataLabels) {
      const seriesValues = series[0].totals;
      config.plotOptions.series.dataLabels = {
        style: {
          color: this.props.theme.colors.black,
          fontSize: '1.5em',
          textOutline: 'none',
        },
        formatter: function () {
          return seriesValues && seriesValues.length > 0 ? `${seriesValues[this.point.x]}` : this.y > 0 ? this.y : '';
        },
        enabled: true,
        inside: true,
      };
    }
    return config;
  };

  noData = () => {
    if (!this.props.series || this.props.series.length === 0) {
      return true;
    }
    if (!this.props.series.some(series => series?.data?.length)) {
      return true;
    }
    return false;
  };

  render() {
    const { width, height, dimensions, loading, error, t, immutable } = this.props;
    const noData = this.noData();

    let skeletonContent;
    if (loading) {
      skeletonContent = <Loader color="BLUE" size="LARGE" />;
    } else if (noData) {
      skeletonContent = error || t('No data available');
    }

    const showSkeleton = skeletonContent !== undefined;

    // Prevent rendering issues by waiting for chart to get width first
    if (!width && dimensions?.width == null) {
      return <StyledBarChart />;
    }

    return (
      <StyledBarChart>
        {showSkeleton ? (
          <SkeletonChart
            width={width ? (width > window.innerWidth ? window.innerWidth : `${width}px`) : null}
            height={height ? `${height}px` : null}
          >
            {skeletonContent}
          </SkeletonChart>
        ) : (
          <HighchartsReact highcharts={Highstock} options={this.getConfig()} immutable={immutable} />
        )}
      </StyledBarChart>
    );
  }
}

BarChart.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  series: PropTypes.array,
  temperatureSeries: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  type: PropTypes.string,
  noZoom: PropTypes.bool,
  backgroundColor: PropTypes.string,
  plotBorderWidth: PropTypes.string,
  plotBorderColor: PropTypes.string,
  plotBackgroundColor: PropTypes.string,
  pointer: PropTypes.string,
  onClick: PropTypes.func,
  onSelection: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  categories: PropTypes.array,
  labelStyle: PropTypes.object,
  labelRotation: PropTypes.string,
  labelFormatter: PropTypes.func,
  xTitle: PropTypes.string,
  xAxisType: PropTypes.string,
  yAxisConfig: PropTypes.array,
  minXRange: PropTypes.number,
  maxX: PropTypes.number,
  legendAlign: PropTypes.string,
  legendReversed: PropTypes.bool,
  hideLegend: PropTypes.bool,
  plotOptions: PropTypes.object,
  pointWidth: PropTypes.number,
  disableTooltip: PropTypes.bool,
  colorByPoint: PropTypes.bool,
  stacked: PropTypes.bool,
  valueDecimals: PropTypes.number,
  colors: PropTypes.array,
  dataLabels: PropTypes.bool,
  hideYTitle: PropTypes.bool,
  yTitle: PropTypes.string,
  hideDecimals: PropTypes.bool,
  yOpposite: PropTypes.bool,
  yTickInterval: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  yMax: PropTypes.number,
  yMin: PropTypes.number,
  plotLines: PropTypes.array,
  annotations: PropTypes.array,
  language: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  onDrilldown: PropTypes.func,
  onDrillup: PropTypes.func,
  pointStart: PropTypes.number,
  pointInterval: PropTypes.number,
  inverted: PropTypes.bool,
  chartSpacing: PropTypes.array,
  immutable: PropTypes.bool,
  disableAnimation: PropTypes.bool,
  onRender: PropTypes.func,
};

BarChart.defaultProps = {
  labelStyle: EMPTY_OBJECT,
  immutable: false,
  disableAnimation: false,
};

export default Responsive(withTheme(BarChart));
