import { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import startsWith from 'lodash/startsWith';

import { MAX_SIZE } from 'components/Documents/DocumentManager/DocumentManager';
import ResponsiveButton from 'components/Button/ResponsiveButton';

const PhotoButton = ({ t, onFileUpload, setFileErrors }) => {
  const handleUpload = useCallback(
    fileArray => {
      setFileErrors([]);
      fileArray.forEach((file, index) => {
        const filename = file.name;
        const mimeType = file.type;
        const size = file.size;

        const fileTooBig = size > MAX_SIZE;
        const wrongMimeType = mimeType && !startsWith(mimeType, 'image') && mimeType !== 'application/pdf';
        const newErrorMessages = [];

        if (fileTooBig) {
          newErrorMessages.push(`${t('File is too big')}: ${filename}`);
        }
        if (!mimeType) {
          newErrorMessages.push(`${t('File extension missing')}: ${filename}`);
        }
        if (wrongMimeType) {
          newErrorMessages.push(`${t('Allowed file types are {0} and {1}', 'image', 'application/pdf')}: ${filename}`);
        }

        if (newErrorMessages.length === 0) {
          const reader = new FileReader();
          reader.onload = event => {
            if (event.target.result) {
              let fileString = event.target.result;
              if (fileString.indexOf(',') !== -1) {
                fileString = fileString.split(',')[1];
              }
              const data = {
                file: fileString,
                filename,
                mimeType,
                size,
              };

              onFileUpload(data);
            }
          };

          reader.readAsDataURL(file);
        } else {
          setFileErrors(oldErrors => oldErrors.concat(newErrorMessages));
        }
      });
    },
    [t, onFileUpload, setFileErrors]
  );

  return (
    <Dropzone onDrop={handleUpload} style={{}}>
      <ResponsiveButton large iconName="camera">
        {t('Add photo')}
      </ResponsiveButton>
    </Dropzone>
  );
};

PhotoButton.propTypes = {
  t: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  setFileErrors: PropTypes.func.isRequired,
};

export default PhotoButton;
