import PropTypes from 'prop-types';
import styled from 'styled-components';

import FileList from 'components/Documents/FileList/FileList';
import ChatBubble from 'components/ChatBubble/ChatBubble';
import Options from './Options';

const ItemContainer = styled.div`
  padding: var(--size-lg) 0;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};

  &:last-child {
    border: none;
  }
`;

const ItemTitle = styled.p`
  margin-bottom: var(--size-xs);
`;

const FileListWrapper = styled.div`
  margin: var(--size-xs) 0;
`;

const ItemView = ({ item, templateOptions, onFileDownload }) => {
  return (
    <ItemContainer>
      <ItemTitle>
        {item.ordinal}. {item.title}
      </ItemTitle>
      <Options options={templateOptions} selectedOption={item.selectedChecklistTemplateOptionId} />
      <FileListWrapper>
        <FileList files={item.itemFiles} onDownload={onFileDownload} showThumbnails />
      </FileListWrapper>
      {item.freeText && <ChatBubble>{item.freeText}</ChatBubble>}
    </ItemContainer>
  );
};

ItemView.propTypes = {
  item: PropTypes.object.isRequired,
  templateOptions: PropTypes.array.isRequired,
  onFileDownload: PropTypes.func.isRequired,
};

export default ItemView;
