import { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import clone from 'lodash/clone';
import find from 'lodash/find';
import some from 'lodash/some';

import translations from 'decorators/Translations/translations';
import DialogModal from 'components/Dialog/DialogModal';
import DialogFooter from 'components/Dialog/DialogFooter';
import PrimaryButton from 'components/Button/PrimaryButton';
import { InputRow, InputLabel, InputText, InputForm, InputSelectDropdown } from 'components/index';
import { findFolder, getFolderOptions, toPath } from 'utils/Data/documents';
import { upsertFolder, loadFolders, upsertPartnerFolder, loadPartnerFolders } from 'redux/modules';
import MasterDataService from 'services/masterData';

const StyledInputRow = styled(InputRow)`
  width: 100%;
  margin-right: 0 !important;
  margin-left: 0 !important;
`;
StyledInputRow.displayName = 'StyledInputRow';

const ErrorMessage = styled.p`
  color: var(--error-color);
  font-weight: ${props => props.theme.font.weight.bold};
`;
ErrorMessage.displayName = 'ErrorMessage';

const nameExists = (folderOptions, folder) => {
  const parent = find(folderOptions, { value: folder.parentId }) || {};
  const folderPath = toPath(parent.label, folder.name);
  return some(folderOptions, { label: folderPath });
};

const FolderManager = ({
  t,
  onClose,
  onSubmit,
  isCreateMode,
  folder,
  folders,
  upsertFolder,
  functionalLocation,
  partnerNumber,
  loadFolders,
}) => {
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(clone(folder));
  const [error, setError] = useState(null);

  const formRef = useRef();

  const folderOptions = getFolderOptions(folders, model.id);

  const handleSubmit = useCallback(async () => {
    if (nameExists(folderOptions, model)) {
      setError(t('Name already exists on this level'));
      return;
    }

    setLoading(true);
    setError(null);

    let folderId;

    // Resolve current parent folder path
    let path;
    if (model.parentId) {
      const parent = findFolder(model.parentId, folders);
      path = parent?.path;
    }
    if (!path) {
      if (functionalLocation) {
        const response = await MasterDataService.getBuilding(functionalLocation.functionalLocation);
        const building = response.data[0];
        path = `building/${building.id}/`;
      } else {
        path = `partner/${partnerNumber}/`;
      }
    }

    upsertFolder(path, {
      ...model,
      functionalLocation: functionalLocation?.functionalLocation,
      partnerNumber: functionalLocation ? undefined : partnerNumber,
    })
      .then(response => {
        if (response.error) {
          throw new Error(t('Save Failed'));
        }
        folderId = response.folderId;
      })
      .then(() => loadFolders())
      .then(() =>
        onSubmit(
          {
            notificationType: 'success',
            notificationVisible: true,
            notificationMessage: isCreateMode ? t('Folder successfully saved') : t('Folder successfully updated'),
          },
          true,
          folderId
        )
      )
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, [model]);

  const handleFormChange = useCallback(
    (property, value) => {
      setModel({
        ...model,
        [property]: value,
      });
    },
    [model]
  );

  return (
    <DialogModal
      small
      isActive
      t={t}
      onOverlayClick={onClose}
      footer={
        <DialogFooter>
          <PrimaryButton naked large onClick={onClose}>
            {t('Cancel')}
          </PrimaryButton>
          <PrimaryButton
            confirm
            large
            onClick={formRef.current ? formRef.current.onSubmit : undefined}
            form="folderForm"
            loading={loading}
          >
            {t('Save')}
          </PrimaryButton>
        </DialogFooter>
      }
    >
      <section>
        <h3>{isCreateMode ? t('Add folder') : t('Edit folder')}</h3>
        <InputForm
          id="folderForm"
          onPropertyChange={handleFormChange}
          model={model}
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <StyledInputRow required>
            <InputLabel text={t('Name')} />
            <InputText property="name" id="folderName" />
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel text={t('Parent folder')} />
            <InputSelectDropdown model={model} t={t} property="parentId" options={folderOptions} scrollToMenu />
          </StyledInputRow>
        </InputForm>
        <ErrorMessage>{error}</ErrorMessage>
      </section>
    </DialogModal>
  );
};

FolderManager.defaultProps = {
  isCreateMode: true,
  folder: {},
};

FolderManager.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  folders: PropTypes.array.isRequired,
  functionalLocation: PropTypes.object,
  isCreateMode: PropTypes.bool,
  folder: PropTypes.object,
  t: PropTypes.func.isRequired,
  upsertFolder: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  partnerNumber: PropTypes.string,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  upsertFolder: (path, folder) =>
    ownProps.functionalLocation ? dispatch(upsertFolder(path, folder)) : dispatch(upsertPartnerFolder(path, folder)),

  loadFolders: () =>
    ownProps.functionalLocation
      ? dispatch(loadFolders(ownProps.functionalLocation, true))
      : dispatch(loadPartnerFolders(ownProps.partnerNumber, true)),
});

const connector = connect(null, mapDispatchToProps);

export default connector(translations(FolderManager));
