import { Component, createRef } from 'react';
import merge from 'lodash/merge';
import isNumber from 'lodash/isNumber';
import sum from 'lodash/sum';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import get from 'lodash/get';
import { withTheme } from 'styled-components';
import { transparentize } from 'polished';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader';
import { getSymbolChar } from 'utils/String/symbols';
import styled from 'styled-components';
import Highstock from 'highcharts/highstock';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsAnnotations from 'highcharts/modules/annotations';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import Responsive from 'components/Responsive/Responsive';
import {
  getCommonExportingOptions,
  getCommonNavigationOptions,
  downloadSymbol,
  commentSymbol,
  getChartTranslations,
} from './utils';
import translations from 'decorators/Translations/translations';
import PropTypes from 'prop-types';

highchartsExporting(Highstock);
highchartsAnnotations(Highstock);
highchartsAccessibility(Highstock);
Highstock.SVGRenderer.prototype.symbols.download = downloadSymbol;
Highstock.SVGRenderer.prototype.symbols.comment = commentSymbol;

const TOOLTIP_WIDTH = 250;
const TOOLTIP_BREAKPOINT = 400;
const EMPTY_OBJECT = {};

const StyledBarChart = styled.div`
  width: 100%;
  height: 100%;
  .bar-chart {
    z-index: 1 !important;

    ${props => props.theme.media.landscape`
            overflow: visible !important;
        `}
  }
`;

const renderTriangle = (props, position) => {
  const size = 10;

  let triangle = `
        position: absolute;
        display: block;
        width: 0;
    `;

  let shadow = `
        position: absolute;
        display: block;
        width: 0;
    `;

  switch (position) {
    case 'top':
      triangle += `
            top: -${size - 1}px;
            left: calc(50% - ${size}px);
            border-bottom: ${size}px solid #fff;
            border-left: ${size}px solid transparent;
            border-right: ${size}px solid transparent;
        `;
      shadow += `
            top: -${size}px;
            left: calc(50% - ${size}px);
            border-bottom: ${size}px solid ${props.theme.colors.lightGray};
            border-left: ${size}px solid transparent;
            border-right: ${size}px solid transparent;
        `;
      break;
    case 'right':
      triangle += `
            top: calc(50% - ${size}px);
            right: -${size - 1}px;
            border-left: ${size}px solid #fff;
            border-top: ${size}px solid transparent;
            border-bottom: ${size}px solid transparent;
        `;
      shadow += `
            top: calc(50% - ${size}px);
            right: -${size}px;
            border-left: ${size}px solid ${props.theme.colors.lightGray};
            border-top: ${size}px solid transparent;
            border-bottom: ${size}px solid transparent;
        `;
      break;
    case 'bottom':
      triangle += `
            bottom: -${size - 1}px;
            left: calc(50% - ${size}px);
            border-top: ${size}px solid #fff;
            border-left: ${size}px solid transparent;
            border-right: ${size}px solid transparent;
        `;
      shadow += `
            bottom: -${size}px;
            left: calc(50% - ${size}px);
            border-top: ${size}px solid ${props.theme.colors.lightGray};
            border-left: ${size}px solid transparent;
            border-right: ${size}px solid transparent;
        `;
      break;
    case 'left':
      triangle += `
            top: calc(50% - ${size}px);
            left: -${size - 1}px;
            border-right: ${size}px solid #fff;
            border-top: ${size}px solid transparent;
            border-bottom: ${size}px solid transparent;
        `;
      shadow += `
            top: calc(50% - ${size}px);
            left: -${size}px;
            border-right: ${size}px solid ${props.theme.colors.lightGray};
            border-top: ${size}px solid transparent;
            border-bottom: ${size}px solid transparent;
        `;
      break;
    default:
      break;
  }

  return `
        <span style="${shadow}"></span>
        <span style="${triangle}"></span>
    `;
};

class StatisticsBarChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = createRef();
    Highstock.setOptions({
      lang: getChartTranslations(props.t),
    });
  }

  componentDidUpdate(prevProps) {
    const { height } = this.props;
    if (prevProps.height !== height && typeof this.chartRef.current?.chart?.setSize === 'function') {
      // Wait for modal resizing animation before determining container size (null = based on container)
      setTimeout(() => this.chartRef.current?.chart?.setSize(null, height || null), 300);
    }
  }

  render() {
    const props = this.props;
    const { width, height, type } = props;
    const horizontal = type === 'bar';

    const getConfig = () => {
      // Use 1.) explicit width and height, or 2.) Dimensions from Responsive, or 3.) Highcharts default.

      let chartWidth = props.width ? props.width : props.dimensions ? props.dimensions.width : null;
      const chartHeight = props.height ? props.height : props.dimensions ? props.dimensions.height : null;

      if (chartWidth > window.innerWidth) {
        chartWidth = window.innerWidth;
      }

      const config = {
        chart: {
          type: props.type || 'column',
          zoomType: props.noZoom ? undefined : 'yx',
          panning: !props.noZoom,
          panKey: 'shift',
          width: chartWidth,
          height: chartHeight,
          className: 'bar-chart',
          plotBorderWidth: props.plotBorderWidth,
          plotBorderColor: props.plotBorderColor,
          plotBackgroundColor: props.plotBackgroundColor,
          style: {
            fontFamily: props.theme.font.family.cairo,
            cursor: props.onClick && 'pointer',
          },
          events: {
            click: props.onClick,
          },
          backgroundColor: props.backgroundColor || 'transparent',
          marginLeft: props.marginLeft,
        },
        colors: props.colors || props.theme.charts.colors,
        title: { text: props.title || '' },
        subtitle: { text: props.subtitle || '' },
        xAxis: merge(
          {},
          {
            visible: true,
            minorTickLength: 0,
            tickLength: 0,
            categories: props.categories,
            crosshair: true,
            labels: {
              enabled: true,
              style: props.labelStyle,
              rotation: props.labelRotation,
            },
            title: {
              text: props.xTitle,
            },
            scrollbar: {
              size: 8,
              rifleColor: 'transparent',
              trackBackgroundColor: '#e7e7e7',
              trackBorderWidth: 0,
              trackBorderRadius: 4,
              buttonArrowColor: 'transparent',
              buttonBorderWidth: 0,
              buttonBackgroundColor: 'transparent',
              barBorderRadius: 4,
              barBackgroundColor: '#888E97',
              barBorderWidth: 0,
            },
          },
          props.xAxis
        ),
        legend: {
          enabled: !props.hideLegend,
          align: props.legendAlign ? props.legendAlign : 'right',
          margin: props.legendAlign === 'left' ? 26 : 16,
          verticalAlign: 'top',
          borderWidth: 0,
          reversed: true,
        },
        tooltip: {
          enabled: !props.disableTooltip,
          backgroundColor: 'transparent',
          borderWidth: 0,
          shadow: false,
          padding: 0,
          valueDecimals: props.valueDecimals,
          followPointer: props.simpleTooltip,
          positioner:
            !props.simpleTooltip &&
            function (labelWidth, labelHeight, point) {
              if (horizontal) {
                return {
                  x: point.plotX < TOOLTIP_BREAKPOINT ? point.plotX + 1.6 * labelWidth : point.plotX - 25,
                  y: point.plotY + 12,
                };
              }

              return {
                x: point.plotX - 55,
                y: 25,
              };
            },
          formatter: function () {
            const tooltipStyle = `
                            color: ${props.theme.colors.black};
                            background-color: ${props.theme.colors.white};
                            font-size: ${props.theme.font.size.xs};
                            border: 1px solid ${props.theme.colors.lightGray};
                            box-shadow: 0 2px 10px ${transparentize(0.9, props.theme.colors.black)};
                            border-radius: 4px;
                            padding: 0.5em 1em;
                            width: ${!props.simpleTooltip && `${TOOLTIP_WIDTH}px`};
                            max-height: ${chartHeight - 80}px;
                            overflow-y: auto;
                            pointer-events: visible;
                        `;

            const headerStyle = `
                            display: block;
                            font-weight: ${props.theme.font.weight.bold};
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        `;

            if (props.simpleTooltip) {
              const simpleValues = map(
                this.points,
                point => `<span style="${headerStyle}">${point.y} ${point.series.userOptions._unit || ''}</span>`
              ).join('');
              return `<div style="${tooltipStyle}">${simpleValues}</div>`;
            }

            let headerText = '';
            if (typeof props.getTooltipHeader === 'function') {
              headerText = props.getTooltipHeader(this.x);
            } else if (props.xAxis && props.xAxis.type === 'category') {
              headerText = this.points[0].key;
            } else {
              headerText = this.x;
            }
            let html = `
                            <div style="${tooltipStyle}">
                                <span style="${headerStyle}">${headerText}</span>
                                <table>
                        `;

            let tooltipPosition = 'bottom';
            if (horizontal) {
              const barWidth = this.points.reduce((acc, point) => acc + point.point.shapeArgs.height, 0);
              tooltipPosition = barWidth < TOOLTIP_BREAKPOINT ? 'left' : 'right';
            }

            const format = value => {
              if (isNumber(props.valueDecimals)) {
                const multiplier = Math.pow(10, props.valueDecimals);
                return Math.round(value * multiplier) / multiplier;
              }

              return value;
            };

            const total = this.points.reduce((sum, { y }) => y + sum, 0);

            const ratio = value => (total ? Math.round((value / total) * 100) : 0);

            this.points.forEach(point => {
              if (point && (point.y !== 0 || point.series.userOptions._showTooltipForZeroValue)) {
                const color = !point.series.userOptions._noTooltipColors && point.series.color;
                const symbol = point.series.userOptions.type === 'line' ? getSymbolChar(point.series.symbol) : '';
                const circleStyle = `
                                    width: ${props.theme.font.size.xxs};
                                    height: ${props.theme.font.size.xxs};
                                    border-radius: 50%;
                                    background-color: ${color || props.theme.colors.black};
                                    margin-right: var(--size-xs);
                                    margin-bottom: -2px;
                                `;
                const symbolStyle = `
                                    font-family: ${props.theme.fontFamily.text};
                                    color: ${color || props.theme.colors.black};
                                    padding-right: ${symbol ? '0.3em' : 0};
                                    font-size: ${props.theme.font.size.sm};
                                    vertical-align: center;
                                `;
                const valueStyle = `
                                    font-family: ${props.theme.fontFamily.text};
                                    color: ${props.theme.colors.black};
                                    font-size: ${props.theme.font.size.xxs};
                                    font-weight: ${props.theme.font.weight.bold};
                                    padding-right: 0.3em;
                                    padding-top: 0.3em;
                                `;
                const labelStyle = `
                                    color: ${props.theme.colors.black};
                                    font-size: ${props.theme.font.size.xxs};
                                    font-family: ${props.theme.fontFamily.text};
                                    padding: 0;
                                    padding-right: 0.3em;
                                    padding-top: 0.3em;
                                `;

                html += `
                                    <tr>
                                        <td><div style="${circleStyle}"></div></td>
                                        <td style="${symbolStyle}">${symbol}</td>
                                        <td style="${valueStyle}">
                                            ${format(point.y)} ${point.series.userOptions._unit || ''}
                                        </td>
                                        <td style="${labelStyle}">
                                            ${point.series.name}
                                        </td>
                                        <td style="${valueStyle}">
                                          ${ratio(point.y)} %
                                        </td>
                                    </tr>`;
                if (point.point.warning) {
                  html += `
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>
                                                <em>${point.point.warning}</em>
                                            </td>
                                        </tr>`;
                }
              }
            });
            html += `
                                </table>
                                ${renderTriangle(props, tooltipPosition)}
                            </div>`;
            return html;
          },
          shared: true,
          useHTML: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            pointWidth: props.pointWidth,
            enableMouseTracking: !props.disableTooltip,
            animation: !props.disableTooltip,
            colorByPoint: props.colorByPoint || false,
            stacking: props.stacked ? 'normal' : undefined,
            point: {
              events: {
                click: props.onClick,
              },
            },
          },
        },
        yAxis: {
          title: {
            text: props.yTitle,
          },
          allowDecimals: !props.hideDecimals,
          max: props.yMax,
          softMax: props.ySoftMax,
          endOnTick: props.yEndOnTick,
          plotLines: props.plotLines,
          labels: {
            format: `{value}${props.unit || ''}`,
            style: props.labelStyle,
          },
          tickInterval: props.yTickInterval,
        },
        series: props.series,
        exporting: getCommonExportingOptions({ title: this.props.title, categories: props.categories }),
        navigation: getCommonNavigationOptions(),
      };

      return config;
    };

    const hasData = () => {
      if (!props.series) {
        return false;
      }
      const dataLength = sum(
        flatten(
          props.series.map(series => {
            return map(series.data, d => {
              return d.y;
            });
          })
        )
      );
      return props.showEmpty || dataLength !== 0;
    };

    const noData = !hasData();
    const skeletonContent =
      (props.loading && <Loader color="BLUE" size="LARGE" />) ||
      (noData && props.error) ||
      (noData && props.t('No data available')) ||
      undefined;

    const addEvents = (H, chart) => {
      // Add the mousewheel event, inspired by https://www.highcharts.com/forum/viewtopic.php?t=40633
      H.addEvent(chart.container, 'wheel', event => {
        const axis = chart.xAxis[0];
        const e = chart.pointer.normalize(event);
        const extr = axis.getExtremes();
        const maxX = props.xAxis.max || 10;

        // Skip if nothing to scroll
        if (extr.dataMax < maxX) {
          return false;
        }

        const coefficient = e.deltaMode === WheelEvent.DOM_DELTA_PIXEL ? 0.01 : 1 / Math.abs(e.deltaY);
        const delta = -e.deltaY * coefficient;
        const step = ((extr.max - extr.min) / 5) * delta;
        let newMin = extr.min - step;
        let newMax = extr.max - step;

        if (newMax > extr.dataMax) {
          newMin = extr.dataMax - maxX;
          newMax = extr.dataMax;
        }
        newMin = newMin >= 0 ? newMin : 0;
        newMax = newMax < maxX ? Math.min(maxX, extr.dataMax) : newMax;

        // Prevent scrolling page when scrolling the chart
        if (extr.min !== newMin || extr.max !== newMax) {
          event.preventDefault();
        }

        axis.setExtremes(newMin, newMax, true, false);
        return false;
      });

      const isExport = get(chart, 'renderer.forExport', false);

      // fix missing first bar by setting the extreme to negative
      if (props.xAxis.max && props.xAxis.scrollbar && !props.noFix && !isExport) {
        const axis = chart.xAxis[0];
        axis.setExtremes(-0.49, props.xAxis.max, true, false);
      }
    };

    return (
      <StyledBarChart>
        {skeletonContent !== undefined ? (
          <SkeletonChart
            width={width ? (width > window.innerWidth ? window.innerWidth : `${width}px`) : null}
            height={height ? `${height}px` : null}
          >
            {skeletonContent}
          </SkeletonChart>
        ) : (
          <HighchartsReact
            highcharts={Highstock}
            options={getConfig()}
            ref={this.chartRef}
            callback={horizontal ? chart => addEvents(Highstock, chart) : undefined}
            containerProps={{ style: { height: '100%' } }}
          />
        )}
      </StyledBarChart>
    );
  }
}

StatisticsBarChart.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  dimensions: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  type: PropTypes.string,
  noZoom: PropTypes.bool,
  plotBorderWidth: PropTypes.string,
  plotBorderColor: PropTypes.string,
  plotBackgroundColor: PropTypes.string,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  marginLeft: PropTypes.string,
  colors: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  categories: PropTypes.array,
  labelStyle: PropTypes.object,
  labelRotation: PropTypes.string,
  xTitle: PropTypes.string,
  xAxis: PropTypes.object,
  hideLegend: PropTypes.bool,
  legendAlign: PropTypes.string,
  disableTooltip: PropTypes.bool,
  valueDecimals: PropTypes.number,
  simpleTooltip: PropTypes.bool,
  getTooltipHeader: PropTypes.func,
  pointWidth: PropTypes.string,
  colorByPoint: PropTypes.bool,
  stacked: PropTypes.bool,
  yTitle: PropTypes.string,
  hideDecimals: PropTypes.bool,
  yMax: PropTypes.number,
  ySoftMax: PropTypes.number,
  yEndOnTick: PropTypes.bool,
  plotLines: PropTypes.array,
  unit: PropTypes.string,
  yTickInterval: PropTypes.number,
  series: PropTypes.array,
  showEmpty: PropTypes.bool,
  loading: PropTypes.bool,
  noFix: PropTypes.bool,
  error: PropTypes.string,
};

StatisticsBarChart.defaultProps = {
  labelStyle: EMPTY_OBJECT,
};

export default Responsive(withTheme(translations(StatisticsBarChart)));
