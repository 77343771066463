import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import DOMPurify from 'dompurify';
import translations from 'decorators/Translations/translations';

const Terms = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TermsHeading = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  padding: 0 var(--size-lg-plus) 1em;
  justify-content: space-between;
  flex-direction: row;
`;

const TermsTitle = styled.div`
  font-size: var(--size-lg);
  margin-top: var(--size-xs);
`;

const TermsContent = styled.div`
  font-size: var(--size-sm-plus);
  line-height: var(--size-md-plus);
  margin: 2em 0;
  padding: 0 2em;
  white-space: pre-line;
  flex: 1;
  text-align: left;
`;

const Logo = styled.div`
  background: var(--logo-welcome-bg);
  height: 55px;
  width: 150px;
  margin-left: -8px;
`;

const TermsDescription = styled.div`
  font-size: var(--size-sm-plus);
  line-height: var(--size-md-plus);
  background-color: var(--orange-01-lighter);
  margin: 0 2em;
  padding: var(--size-sm);
`;

const TermsDescriptionHeader = styled.div`
  font-weight: bold;
`;

// Add a hook to make all links open a new window
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
});

const TermsOfService = ({ text, showLogo, title, descriptionHeader, descriptionBody }) => {
  // This is used for allowing links inside the terms of service text
  const dangerousMarkup = { __html: DOMPurify.sanitize(text) };
  return (
    <Terms>
      <TermsHeading>
        {showLogo && <Logo />}
        <TermsTitle>{title}</TermsTitle>
      </TermsHeading>
      {(descriptionHeader || descriptionBody) && (
        <TermsDescription>
          <TermsDescriptionHeader>{descriptionHeader}</TermsDescriptionHeader>
          {descriptionBody}
        </TermsDescription>
      )}
      <TermsContent dangerouslySetInnerHTML={dangerousMarkup}></TermsContent>
    </Terms>
  );
};

TermsOfService.propTypes = {
  text: PropTypes.string,
  showLogo: PropTypes.bool,
  title: PropTypes.string,
  descriptionHeader: PropTypes.string,
  descriptionBody: PropTypes.string,
};

export default translations(TermsOfService);
