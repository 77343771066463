import { useState, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import SnackBar from 'components/SnackBar/SnackBar';
import { NOTIFICATION_TIMEOUT } from 'constants/common';
import SecondaryButton from 'components/Button/SecondaryButton';
import { useTranslations } from 'decorators/Translations/translations';

export const hiddenNotification = { type: '', visible: false, message: '' };

const useNotification = (withPortal = false) => {
  const [notification, setNotification] = useState();
  const [t] = useTranslations();

  const clearNotification = useCallback(() => setNotification(hiddenNotification), []);

  const showNotification = useCallback(
    async ({ autoHide, ...notification }) => {
      setNotification({ visible: true, ...notification });
      if (autoHide) {
        return new Promise(resolve => setTimeout(() => resolve(clearNotification()), NOTIFICATION_TIMEOUT));
      }
    },
    [clearNotification]
  );

  const renderedNotification = useMemo(() => {
    if (!notification) {
      return null;
    }

    const snackBarComponent = (
      <SnackBar
        variant={notification.type}
        visible={notification.visible}
        secondaryContent={
          notification.showCloseButton ? (
            <SecondaryButton large onClick={clearNotification}>
              {t('Close')}
            </SecondaryButton>
          ) : undefined
        }
      >
        {notification.message}
      </SnackBar>
    );

    if (withPortal) {
      return ReactDOM.createPortal(snackBarComponent, document.body);
    }
    return snackBarComponent;
  }, [notification, clearNotification, withPortal, t]);

  return {
    showNotification,
    clearNotification,
    renderedNotification,
  };
};

export default useNotification;
