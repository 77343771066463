import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import themes from 'styles/themes';
import Svg from 'components/Svg/Svg';
import Loader from 'components/Loader/Loader';

const StyledButton = styled.button.attrs(props => ({
  'data-test-id': props.dataTestId,
}))`
  display: flex;
  align-items: center;
  background-color: ${props => props.color};
  color: ${props => props.textColor};
  font-size: ${props => (props.hasText ? props.theme.font.size.xxxs : props.theme.font.size.sm)};
  font-weight: ${props => props.theme.font.weight.bold};
  ${props =>
    props.hasText
      ? css`
          height: 3.2em;
          min-width: 3.2em;
          svg {
            font-size: ${props => props.theme.font.size.md};
          }
        `
      : css`
          align-items: center;
          justify-content: center;
        `}
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.letterSpacing.default};
  border-radius: ${props => (props.round ? '50%' : '6px')};
  padding: ${props => (props.hasText ? '0 3em' : '0.5em')};
  border: none;
  appearance: none;
  &:focus {
    outline: none;
  }
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;
StyledButton.displayName = 'StyledButton';

const IconContainer = styled.span`
  display: inherit;
  &:last-child {
    ${props => props.hasText && 'margin-right: 0.5em;'}
  }

  @media print {
    display: none;
  }
`;

const LoaderContainer = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
`;

const IconButton = ({
  color,
  textColor,
  onClick,
  type,
  disabled,
  value,
  iconName,
  text,
  iconColor,
  dataTestId,
  stroke,
  loading,
  children,
  ...other
}) => (
  <StyledButton
    dataTestId={dataTestId}
    color={color}
    textColor={textColor}
    onClick={onClick}
    type={type}
    disabled={disabled}
    value={value}
    hasText={Boolean(text || children)}
    {...other}
  >
    <IconContainer hasText={Boolean(text || children)}>
      {loading && (
        <LoaderContainer>
          <Loader color="WHITE" pixelSize={20} />
        </LoaderContainer>
      )}
      {!loading && iconName && <Svg name={iconName} fill={iconColor} stroke={stroke} />}
    </IconContainer>
    {text || children}
  </StyledButton>
);

IconButton.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  textColor: PropTypes.string,
  round: PropTypes.bool,
  type: PropTypes.string,
  dataTestId: PropTypes.string,
  stroke: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.any,
};

IconButton.defaultProps = {
  text: null,
  disabled: false,
  type: 'button',
  value: undefined,
  color: 'var(--btn-blue-bg)',
  textColor: 'var(--btn-blue-fg)',
  iconColor: 'var(--btn-blue-fg)',
  dataTestId: null,
};

export default IconButton;
