import ApiRequest from 'utils/Fetch/ApiRequest';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';
import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { SERVICE_API_PATH } from '../masterData/index';

export const relationType = {
  building: 'building',
  partner: 'partner',
  serviceOrder: 'service-order',
  functionalLocation: 'functional-location',
  plannedMaintenance: 'planned-maintenance',
  maintenancePlan: 'maintenance-plan',
  longTermPlan: 'long-term-plan',
  checklistReport: 'checklist-report',
  SRIReport: 'SRI-report',
};

export const invalidateMaintenanceFileCaches = () =>
  invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/document/files/`);

const findFor = async (request, { type, id, refreshCache = false }) => {
  let offset = 0;
  const limit = 100;
  const results = [];
  let fetchMore;

  do {
    const result = await request.get({
      apiPath: `${SERVICE_API_PATH}/document/files`,
      query: { path: `${type}/${id}`, limit, offset },
      refreshCache,
      useAuthorization: true,
    });
    results.push(...result);
    offset += limit;
    fetchMore = result.length === limit;
  } while (fetchMore);
  return results;
};

const findForApiRequest = async ({ type, id, refreshCache = false, onlyInvalidateCache }) => {
  let offset = 0;
  const limit = 100;
  const results = [];
  let fetchMore;

  do {
    const result = await ApiRequest.get({
      apiPath: `${SERVICE_API_PATH}/document/files`,
      query: { path: `${type}/${id}`, limit, offset },
      refreshCache,
      useAuthorization: true,
    });
    if (onlyInvalidateCache) {
      // this could cause a bug if there are more than 100 items to invalidate, the invalidation logic should be refactored
      return;
    }
    results.push(...result);
    offset += limit;
    fetchMore = result.length === limit;
  } while (fetchMore);
  return results;
};

export const findForMaintenancePlanFn = (request, { maintenancePlanId, refreshCache = false }) =>
  findFor(request, { type: relationType.maintenancePlan, id: maintenancePlanId, refreshCache });

export const findForServiceOrderFn = (request, { serviceOrderNumber, refreshCache = false }) =>
  findFor(request, { type: relationType.serviceOrder, id: serviceOrderNumber, refreshCache });

export const findForServiceOrderRequestFn = (request, { serviceOrderNumber, refreshCache = false } = {}) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/service-order/${serviceOrderNumber}/untrusted`,
    refreshCache,
    useAuthorization: true,
  });

export const findForPlannedMaintenanceFn = (request, { plannedMaintenanceId, refreshCache = false }) =>
  findFor(request, { type: relationType.plannedMaintenance, id: plannedMaintenanceId, refreshCache });

export const findForLongTermPlanFn = (request, { longTermPlanId, refreshCache = false }) =>
  findFor(request, { type: relationType.longTermPlan, id: longTermPlanId, refreshCache });

export const findForChecklistReport = ({ checklistReportId, onlyInvalidateCache = false }) =>
  findForApiRequest({ type: relationType.checklistReport, id: checklistReportId, onlyInvalidateCache });

export const findForChecklistReportWithToken = ({ token, checklistReportId, onlyInvalidateCache = false }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/public-files/checklist-report/${checklistReportId}/with-token`,
    query: { token },
    onlyInvalidateCache,
    useAuthorization: false,
  });

export const downloadArchiveForChecklistReport = ({ checklistReportId }) =>
  ApiRequest.download({
    method: 'GET',
    apiPath: `${SERVICE_API_PATH}/document/files/checklist-report/${checklistReportId}/download`,
    useAuthorization: true,
    noCache: true,
  });

export const downloadUrl = ({ fileId, relation, relationId, inline }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/files/download-url/${fileId}`,
    query: { path: `${relation}/${relationId}`, inline },
    noCache: true,
    useAuthorization: true,
  });

export const downloadUrlForServiceOrderWithToken = ({ fileId, token, checklistReportId }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/public-files/download-url-for-service-order/${fileId}/with-token`,
    query: { token, checklistReportId },
    noCache: true,
    useAuthorization: false,
  });

export const downloadUrlForCustomViewFn = (request, { customViewId }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/download-url-for-custom-view/${customViewId}`,
    noCache: true,
    useAuthorization: true,
  });

export const downloadUrlForAdditionalSaleWithToken = ({ fileId, token }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/public-files/download-url-for-additional-sale-attachment/${fileId}/with-token`,
    query: { token },
    noCache: true,
    useAuthorization: false,
  });

export const downloadExternalFileFn = (request, { functionalLocation, fileId, fileName, windowName }) =>
  request.download({
    apiPath: `${SERVICE_API_PATH}/document/files/download/external/${functionalLocation}/${fileId}`,
    fileName,
    windowName,
    noCache: true,
    useAuthorization: true,
  });

export const downloadUrlForServiceOrderUntrustedFn = (request, { serviceOrderNumber, fileName }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/download-url-for-service-order/${serviceOrderNumber}/untrusted`,
    query: { fileName },
    noCache: true,
    useAuthorization: true,
  });

export const filesForFunctionalLocation = async ({ functionalLocation, refreshCache = false }) =>
  findForApiRequest({ type: relationType.functionalLocation, id: functionalLocation, refreshCache });

export const filesForPartner = async ({ partnerNumber, refreshCache = false }) =>
  findForApiRequest({ type: relationType.partner, id: partnerNumber, refreshCache });

export const fileCounts = ({ partnerNumber }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/files/partner/${partnerNumber}/file-count-per-fl`,
    useAuthorization: true,
  });

export const filesForServiceOrderWithTokenFn = (request, { token }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/public-files/with-token`,
    query: { token },
    useAuthorization: false,
  });

export const uploadFile = data =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/document/files`,
    data,
    noCache: true,
    useAuthorization: true,
  });

export const uploadCustomViewFile = ({ file }) => {
  return ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/document/files`,
    data: file,
    noCache: true,
    useAuthorization: true,
  });
};

export const uploadFileWithToken = async ({ file, token }) => {
  const result = await ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/document/public-files/with-token`,
    query: { token },
    data: file,
    noCache: true,
    useAuthorization: false,
  });
  await invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/document/public-files/with-token`);
  return result;
};

export const updateFileFn = (request, { file, functionalLocation }) =>
  request.patch({
    apiPath: `${SERVICE_API_PATH}/document/files/functional-location/${functionalLocation}`,
    data: file,
    noCache: true,
    useAuthorization: true,
  });

export const updatePartnerFileFn = (request, { file, partnerNumber }) =>
  request.patch({
    apiPath: `${SERVICE_API_PATH}/document/files/partner/${partnerNumber}`,
    data: file,
    noCache: true,
    useAuthorization: true,
  });

export const deleteFile = ({ fileId }) =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/document/files/${fileId}`,
    noCache: true,
    useAuthorization: true,
  });

export const deleteCustomViewFile = ({ customViewId }) =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/document/files/delete-custom-view-file/${customViewId}`,
    noCache: true,
    useAuthorization: true,
  });

export const deleteServiceOrderUntrustedFileFn = (request, { serviceOrderNumber, fileName }) =>
  request.delete({
    apiPath: `${SERVICE_API_PATH}/document/files/delete-service-order-untrusted-file/${serviceOrderNumber}`,
    query: { fileName },
    noCache: true,
    useAuthorization: true,
  });

export default wrapEachWithThunkRequest({
  findForMaintenancePlan: findForMaintenancePlanFn,
  findForServiceOrder: findForServiceOrderFn,
  findForServiceOrderRequest: findForServiceOrderRequestFn,
  findForPlannedMaintenance: findForPlannedMaintenanceFn,
  findForLongTermPlan: findForLongTermPlanFn,
  downloadUrlForCustomView: downloadUrlForCustomViewFn,
  downloadExternalFile: downloadExternalFileFn,
  downloadUrlForServiceOrderUntrusted: downloadUrlForServiceOrderUntrustedFn,
  filesForServiceOrderWithToken: filesForServiceOrderWithTokenFn,
  updateFile: updateFileFn,
  updatePartnerFile: updatePartnerFileFn,
  deleteServiceOrderUntrustedFile: deleteServiceOrderUntrustedFileFn,
});
