import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { userHasFeature } from 'utils/Data/profileData';

const ConditionalRoute = props => {
  const { features, requiredFeatureKey, featureConditionFn, conditionFn, condition, ...otherProps } = props;
  if (features && requiredFeatureKey && !userHasFeature(requiredFeatureKey, features)) {
    return null;
  }
  if (typeof featureConditionFn === 'function' && !featureConditionFn(features)) {
    return null;
  }
  if (typeof conditionFn === 'function' && !conditionFn()) {
    return null;
  }
  if (condition === false) {
    return null;
  }
  return <Route {...otherProps} />;
};

ConditionalRoute.propTypes = {
  features: PropTypes.object,
  requiredFeatureKey: PropTypes.string,
  featureConditionFn: PropTypes.func,
  conditionFn: PropTypes.func,
  condition: PropTypes.bool,
};

export default ConditionalRoute;
