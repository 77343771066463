import PropTypes from 'prop-types';
import map from 'lodash/map';

const MultiInputOptions = ({ id, subProperty, options, optionsSubProperty, row }) => {
  let optionsForInput;

  /**
   * Find input options from options-object:
   * - optionsSubProperty is the column (= sub property) for basic options
   * - other columns use options based on the user input in optionsSubProperty column
   * (see examples from building/partner configuration where meta options are used, or storybook)
   */

  if (subProperty === optionsSubProperty) {
    optionsForInput = options[subProperty];
  } else {
    optionsForInput = options[subProperty][row[optionsSubProperty]];
  }

  return (
    <datalist id={id}>
      {map(optionsForInput, optionValue => (
        <option value={optionValue} key={optionValue} />
      ))}
    </datalist>
  );
};

MultiInputOptions.propTypes = {
  id: PropTypes.string.isRequired, // datalist needs id to work properly
  subProperty: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  optionsSubProperty: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};

export default MultiInputOptions;
