import { useRef, memo } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import useChartSkeleton from '../useChartSkeleton';
import useComparisonDrilldown from './useComparisonDrilldown';
import ComparisonBarChart from '../ComparisonBarChart';
import useChartDoubleClick from '../useChartDoubleClick';
import { getPointStart, getTickInterval } from './utils';
import { AnnotationInfo } from 'containers/Application/Modules/CustomChartsModule/CustomChart/DoubleClickAnnotationInfo';
import * as Styled from './ComparisonChart.styled';

const ComparisonChart = props => {
  const {
    sensorData,
    height,
    loading,
    reference,
    intervalType,
    startDate,
    loadDrilldownData,
    error,
    flipAxes,
    isPreview,
    isExpanded,
    handleSeriesChange,
    onOpenAnnotations,
    annotations,
    getAnnotationConfigs,
    baseSeries,
    outdoorTemperatureSeries,
  } = props;

  const containerRef = useRef();

  const { onDrilldown, onDrillup } = useComparisonDrilldown(loadDrilldownData, reference, getAnnotationConfigs);

  const { onDelayedAction, onClick } = useChartDoubleClick(onOpenAnnotations, onDrilldown);

  const pointStart = getPointStart(startDate, intervalType);

  const tickInterval = getTickInterval(intervalType);

  const chartSkeleton = useChartSkeleton(isNil(sensorData), error, undefined);

  return (
    <>
      <Styled.ChartWrapper ref={containerRef} isExpanded={isExpanded} isPreview={isPreview} height={height}>
        {chartSkeleton ?? (
          <ComparisonBarChart
            annotations={annotations}
            type="column"
            hideLegend={false}
            series={baseSeries}
            plotBorderWidth="0"
            legendAlign="left"
            loading={loading}
            noZoom={true}
            temperatureSeries={outdoorTemperatureSeries}
            intervalType={intervalType}
            tickInterval={tickInterval}
            pointInterval={tickInterval}
            pointStart={pointStart}
            onDrilldown={onDelayedAction}
            onDrillup={onDrillup}
            onPointClick={onClick}
            inverted={flipAxes}
            yTickInterval="auto"
            immutable={true}
            disableAnimation={true}
            onRender={handleSeriesChange}
          />
        )}
      </Styled.ChartWrapper>
      {!loading && !isPreview && <AnnotationInfo />}
    </>
  );
};

export default memo(ComparisonChart);

ComparisonChart.defaultProps = {
  error: false,
  flipAxes: false,
  isPreview: false,
  isExpanded: false,
};

ComparisonChart.propTypes = {
  loading: PropTypes.bool,
  height: PropTypes.number,
  sensorData: PropTypes.array,
  intervalType: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  loadDrilldownData: PropTypes.func,
  error: PropTypes.bool,
  flipAxes: PropTypes.bool,
  isPreview: PropTypes.bool,
  isExpanded: PropTypes.bool,
  reference: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number),
  }),
  handleSeriesChange: PropTypes.func,
  onOpenAnnotations: PropTypes.func,
  annotations: PropTypes.arrayOf(PropTypes.any),
  getAnnotationConfigs: PropTypes.func,
  baseSeries: PropTypes.arrayOf(PropTypes.any),
  outdoorTemperatureSeries: PropTypes.arrayOf(PropTypes.any),
};
