import PropTypes from 'prop-types';
import * as Styled from './ServiceOrderView.styled';

const ServiceOrderSnackbar = ({ replyInViewport, checklistAvailable, toggleShowChecklist, scrollReplyToView, t }) => {
  return (
    <Styled.SnackBar variant="confirmation" visible={!replyInViewport}>
      {checklistAvailable && (
        <Styled.PrimaryButton add large onClick={toggleShowChecklist}>
          {t('Open checklist')}
        </Styled.PrimaryButton>
      )}
      <Styled.PrimaryButton add large onClick={scrollReplyToView}>
        {t('Reply')}
      </Styled.PrimaryButton>
    </Styled.SnackBar>
  );
};

ServiceOrderSnackbar.propTypes = {
  replyInViewport: PropTypes.bool,
  checklistAvailable: PropTypes.bool,
  toggleShowChecklist: PropTypes.func,
  scrollReplyToView: PropTypes.func,
  t: PropTypes.func,
};

export default ServiceOrderSnackbar;
