import parseISO from 'date-fns/parseISO';
import toDate from 'date-fns/toDate';
import differenceInHours from 'date-fns/differenceInHours';
import { format } from 'utils/Date/dateFormatter';
import { transparentize } from 'polished';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';
import { colors, font } from 'styles/definitions';
import identity from 'lodash/identity';
import downloadIcon from 'images/svg/download.inline.svg';

const HIGHCHARTS_DEFAULT_FONT_FAMILY = '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif';

const convertSensorTitleToFilename = sensorTitle => {
  const filenamePrefix = 'smartview_chart';
  if (!sensorTitle) {
    return filenamePrefix;
  }
  return filenamePrefix + '_' + sensorTitle.toLowerCase().replace(/[^a-zåäö0-9_-]+/g, '_');
};

const DEFAULT_EXPORT_SOURCE_HEIGHT = 600;

export const getCommonExportingOptions = options => {
  const categories = get(options, 'categories', []);
  const chartOptions = {
    title: {
      text: options?.title,
    },
    chart: {
      backgroundColor: colors.white,
    },
  };

  if (categories.length) {
    chartOptions.xAxis = [
      {
        categories: options.categories,
        min: 0,
        max: categories.length - 1,
        minRange: categories.length - 1,
        scrollbar: {
          enabled: false,
        },
      },
    ];
    chartOptions.chart.events = {
      load: function () {
        this.xAxis[0].setExtremes(0, options.categories.length - 1, true, false);
      },
    };
  }

  const menuItems = ['downloadPDF', 'downloadCSV', 'downloadSVG', 'downloadPNG', 'downloadJPEG'];

  return {
    fallbackToExportServer: false,
    enabled: true,
    sourceWidth: 850,
    sourceHeight: Math.max(categories.length * 20, DEFAULT_EXPORT_SOURCE_HEIGHT),
    buttons: {
      contextButton: {
        menuItems,
        menuClassName: 'highcharts-contextmenu-exporting',
        symbol: `url(${downloadIconDataURL})`,
        y: -8,
        x: 0,
      },
    },
    chartOptions,
    filename: convertSensorTitleToFilename(options?.title),
    csv: {
      columnHeaderFormatter: function (item, _key, _keyLength) {
        if (item.name) {
          const idPart = item.options.id ? `${item.options.id}_` : '';
          return `${idPart}${item.name}`;
        }
        return false; // tell highcharts to use default formatting
      },
    },
  };
};

export const getCommonNavigationOptions = () => ({
  menuStyle: {
    backgroundColor: colors.white,
    boxShadow: '0px 2px 10px rgba(34, 34, 34, 0.1)',
    overflowY: 'auto',
    border: `1px solid ${colors.lightGray}`,
    borderImage: 'initial',
    borderRadius: '4px',
  },
  menuItemStyle: {
    display: 'block',
    whiteSpace: 'nowrap',
    backgroundColor: colors.white,
    fontFamily: font.family.cairo,
    fontSize: '0.875rem',
    fontWeight: 'normal',
    color: colors.black,
    appearance: 'none',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    padding: '0.75rem 1.5rem',
  },
  menuItemHoverStyle: {
    color: colors.black,
    backgroundColor: colors.alabaster,
  },
});

export const getChartTranslations = (t = identity) => ({
  contextButtonTitle: t('Export'),
  downloadPDF: t('Download PDF document'),
  downloadSVG: t('Download SVG image'),
  downloadPNG: t('Download PNG image'),
  downloadJPEG: t('Download JPEG image'),
  downloadCSV: t('Download CSV document'),
  resetZoom: t('Reset zoom'),
});

const downloadIconDataURL = 'data:image/svg+xml;utf8,' + downloadIcon.content?.replaceAll('symbol', 'svg');

export const downloadSymbol = (x, y, w, h) => [
  // Arrow stem
  'M',
  x + w * 0.5,
  y,
  'L',
  x + w * 0.5,
  y + h * 0.7,
  // Arrow head
  'M',
  x + w * 0.3,
  y + h * 0.5,
  'L',
  x + w * 0.5,
  y + h * 0.7,
  'L',
  x + w * 0.7,
  y + h * 0.5,
  // Box
  'M',
  x,
  y + h * 0.9,
  'L',
  x,
  y + h,
  'L',
  x + w,
  y + h,
  'L',
  x + w,
  y + h * 0.9,
];

export const commentSymbol = (x, y, w, h) => [
  'M',
  x - 0.4 * w,
  y,
  'L',
  x + 1.4 * w,
  y,
  'L',
  x + 1.4 * w,
  y + 0.9 * h,
  'L',
  x + 0.4 * w,
  y + 0.9 * h,
  'L',
  x - 0.1 * w,
  y + 1.2 * h,
  'L',
  x - 0.1 * w,
  y + 0.9 * h,
  'L',
  x - 0.4 * w,
  y + 0.9 * h,
  'z',
];

const getTrueFalseValue = (trueValue, falseValue, point) => (point > 0 ? trueValue : falseValue);

const headerTooltip = ({ theme, label, trueFalseValue, right, top, showTrueFalse }) => {
  const headerStyle = `
        color: ${theme.colors.black};
        background-color: ${theme.colors.white};
        font-size: ${theme.font.size.xs};
        padding: 0.5em 1em;
        position: relative;
        top: -${showTrueFalse ? top + 8 : top}px;
        right: 5px;
        border: 1px solid ${theme.colors.lightGray};
        box-shadow: 0 2px 10px ${transparentize(0.9, theme.colors.black)};
        border-radius: 4px;
        padding: 0.25em 0.5em;
        text-align: center;
        z-index: 10;
    `;
  const border = `
        position: absolute;
        bottom: -10px;
        right: ${right};
        border-width: 10px 8px 0;
        border-style: solid;
        border-color: ${theme.colors.lightGray} transparent;
        display: block;
        width: 0;
    `;
  const borderTriangle = `
        position: absolute;
        bottom: -9px;
        right: ${right};
        border-width: 10px 8px 0;
        border-style: solid;
        border-color: #fff transparent;
        display: block;
        width: 0;
    `;
  const header = `
        <div style="${headerStyle}">
            <span style="${border}"></span>
            ${trueFalseValue}${showTrueFalse ? '<br>' : ''}
            ${label}
            <span style="${borderTriangle}"></span>
        </div>
    `;

  return header;
};

export const pointTooltip = ({ theme, point, showName = true, padding = 0.5, values }) => {
  const itemStyle = `
    text-align: left;
    background-color: ${point?.series.color};
    color: ${theme.colors.white};
    padding: ${padding}em 1em;
    width: ${showName ? '150px' : 'auto'};
  `;
  const nameStyle = `
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  const valueRows = values
    .map(({ value, unit }) => `<div style="font-size: 1.25em;">${value + ' ' + unit}</div>`)
    .join('');

  return `
    <div style="${itemStyle}">
        <div style="${nameStyle}">${showName ? point?.series.name : ''}</div>
        ${valueRows}
    </div>
  `;
};

export const tooltipFormatter = (
  points,
  x,
  theme,
  top,
  temperatureSeriesName,
  trueValue,
  falseValue,
  precision = 2
) => {
  const dateFormatter = points[0].series.userOptions.tooltipDateFormatter;
  const hideTime = points[0].series.userOptions.hideTime;
  const label =
    isNaN(new Date(x).getFullYear()) || new Date(x).getFullYear() === 1970 // x is a category
      ? (points[0].series?.xAxis?.names?.[x] ?? x)
      : dateFormatter
        ? dateFormatter(x)
        : hideTime
          ? format(toDate(x), 'do MMM')
          : format(toDate(x), 'do MMM HH:mm');

  const showTrueFalse = trueValue !== undefined && falseValue !== undefined;
  const trueFalseValue = showTrueFalse ? getTrueFalseValue(trueValue, falseValue, points[0].y) : '';

  const plotX = points[0].point?.plotX ?? points[0].plotX;
  const chartWidth = points[0].series.chart.chartWidth;
  const plotWidth = points[0].series.chart.plotWidth;
  const distanceFromRight = chartWidth - plotX;
  const offset = plotWidth - plotX;
  const right = distanceFromRight > 120 ? 'calc(50% - 12px)' : `${offset - 5}px`;

  const header = headerTooltip({ theme, label, trueFalseValue, right, top, showTrueFalse });

  return [header].concat(
    points.map(point => {
      if (point.interpolated || point.series.userOptions.tooltip === false || showTrueFalse) {
        return false;
      }
      const {
        series: { name, userOptions },
      } = point;

      const showName = !userOptions.singleLine;
      const tempSeries = name === temperatureSeriesName;
      let value = !isNil(point.y) ? (tempSeries ? point.y.toFixed(0) : point.y.toFixed(precision)) : '';
      if (userOptions.trueValue && userOptions.falseValue) {
        value = getTrueFalseValue(userOptions.trueValue, userOptions.falseValue, point.y);
      }

      const unit = !isNil(userOptions.unit) ? userOptions.unit : tempSeries ? `${String.fromCharCode(176)}C` : '%';

      const getPadding = () => {
        if (points.length <= 6) {
          return 0.5;
        }

        return points.length === 7 ? 0.3 : 0.05;
      };

      return pointTooltip({ theme, point, showName, padding: getPadding(), values: [{ value, unit }] });
    })
  );
};

/**
 * Build zones that show data as dotted lines when value is null
 */
export const buildZonesForNulls = data => {
  const zones = [];
  for (let index = 0; index < data.length; index++) {
    if (
      (data[index][1] !== null && data[index - 1]?.[1] !== null) ||
      (data[index][1] === null && data[index - 1]?.[1] === null)
    ) {
      continue;
    }
    data[index - 1] &&
      zones.push({
        value: data[index - 1][0],
        dashStyle: data[index][1] !== null ? 'dot' : undefined,
        fillColor: data[index][1] !== null ? 'rgba(0,0,0,0.05)' : undefined,
      });
  }
  return zones;
};

/**
 * Build zones that show data as dotted lines when value has been null for over 8 hours
 */
export const buildZones = data => {
  const zones = [];
  let lastNotNullValueTime;
  forEach(data, (current, i) => {
    // If we are on the first item, don't do anything
    if (i === 0) {
      return;
    }
    const currentValue = !!current && current[1];
    const previous = data[i - 1];
    const previousValue = !!previous && previous[1];
    // Show as solid until first null value is detected
    if (previousValue !== null && currentValue === null) {
      lastNotNullValueTime = previous[0];
      zones.push({
        dashStyle: 'solid',
        value: lastNotNullValueTime,
      });
    } else if (previousValue === null && currentValue !== null) {
      // From the last not null value moment until next value,
      // we show dotted line if value has been null for over 8 hours
      const currentDate = parseISO(current[0]);
      if (!lastNotNullValueTime || differenceInHours(currentDate, lastNotNullValueTime) > 8) {
        zones.push({
          dashStyle: 'dot',
          value: current[0],
        });
      } else if (zones.length > 0) {
        // Remove unnecessary dashStyle: solid if downtime is less than 8 hours
        zones.splice(-1, 1);
      }
    }
  });
  return zones;
};

export const getAxisTextStyles = theme => ({
  labels: {
    style: {
      color: theme.colors.darkGray,
      fontFamily: HIGHCHARTS_DEFAULT_FONT_FAMILY,
    },
  },
  title: {
    style: {
      color: theme.colors.black,
      fontFamily: HIGHCHARTS_DEFAULT_FONT_FAMILY,
    },
  },
});
