import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';

import InputLabel from 'components/Form/InputLabel';
import MultiSelectDropdown from 'components/Form/MultiSelectDropdown';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import RadioButton from 'components/Form/RadioButton';
import DateTools, { DATE_RANGES } from 'components/Form/DateTools';
import InfoBox from 'components/InfoBox/InfoBox';

/**
 * Constants
 */
export const GRANULARITY = {
  hourly: 'hourly',
  daily: 'daily',
  monthly: 'monthly',
  raw: 'raw',
};

/**
 * Styled components
 */
const Group = styled.div`
  ${props =>
    props.row &&
    css`
      display: flex;
      flex-flow: row nowrap;
    `}

  &:not(:last-child) {
    margin-bottom: var(--size-md);
  }
`;
Group.displayName = 'Group';

const Container = styled.div`
  max-width: 900px;
`;

/**
 * ExportSensorDataForm component
 */
const ExportSensorDataForm = ({
  t,
  model,
  onPropertyChange,
  granularityOptions,
  categoryTitle,
  categoryPlaceholderText,
  categoryOptions,
  sensorTypesTitle,
  sensorTypesPlaceholderText,
  sensorTypeOptions,
  sensorsTitle,
  sensorsPlaceholderText,
  sensorOptions,
  defaultDateRange,
  showDateRangeAllOption,
}) => (
  <Container>
    {!isEmpty(categoryOptions) && (
      <Group>
        <InputLabel text={t(categoryTitle)} />
        <MultiSelectDropdown
          placeholder={t(categoryPlaceholderText)}
          label={t('Groups')}
          showAllText={t('Show all')}
          onChange={(property, value) =>
            onPropertyChange(
              property,
              value.map(entry => entry.value)
            )
          }
          options={categoryOptions}
          property="categoriesSelected"
          model={model}
          multi
        />
      </Group>
    )}
    {!isEmpty(sensorTypeOptions) && (
      <Group>
        <InputLabel text={t(sensorTypesTitle)} />
        <MultiSelectDropdown
          placeholder={t(sensorTypesPlaceholderText)}
          label={t('Sensor types')}
          showAllText={t('Show all')}
          onChange={(property, value) =>
            onPropertyChange(
              property,
              value.map(entry => entry.value)
            )
          }
          options={sensorTypeOptions}
          property="sensorTypesSelected"
          model={model}
          multi
        />
      </Group>
    )}
    {!isEmpty(sensorOptions) && (
      <Group>
        <InputLabel text={t(sensorsTitle)} />
        <MultiSelectDropdown
          placeholder={t(sensorsPlaceholderText)}
          label={t('Sensors')}
          showAllText={t('Show all')}
          onChange={(property, value) =>
            onPropertyChange(
              property,
              value.map(entry => entry.value)
            )
          }
          options={sensorOptions}
          property="particularSensorsSelected"
          model={model}
          multi
        />
      </Group>
    )}
    <Group>
      <DateTools
        alignment="left"
        t={t}
        model={model}
        onChange={onPropertyChange}
        defaultRange={defaultDateRange}
        placement="bottom"
        showAllOption={showDateRangeAllOption}
      />
    </Group>
    <Group>
      <RadioButtonGroup value={model && model.granularity} onChange={onPropertyChange} name="granularity">
        {!isEmpty(granularityOptions) &&
          map(keys(granularityOptions), key => (
            <RadioButton
              key={`granularity_${key}`}
              label={t(`Export ${granularityOptions[key]}`)}
              value={granularityOptions[key]}
            />
          ))}
      </RadioButtonGroup>
    </Group>
    <InfoBox>{t('EXPORT_INFO')}</InfoBox>
  </Container>
);

ExportSensorDataForm.propTypes = {
  // Translations function
  t: PropTypes.func.isRequired,
  // Form model
  model: PropTypes.shape({
    categoriesSelected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    sensorTypesSelected: PropTypes.arrayOf(PropTypes.number),
    particularSensorsSelected: PropTypes.arrayOf(PropTypes.number),
    startDatetime: PropTypes.instanceOf(Date),
    endDatetime: PropTypes.instanceOf(Date),
    granularity: PropTypes.string,
  }).isRequired,
  // Form property onchange function
  onPropertyChange: PropTypes.func.isRequired,
  // Form options for granularity
  granularityOptions: PropTypes.object.isRequired,
  // Available sensor hierarchies
  categoryTitle: PropTypes.string.isRequired,
  categoryPlaceholderText: PropTypes.string,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  // Available sensor types
  sensorTypesTitle: PropTypes.string.isRequired,
  sensorTypesPlaceholderText: PropTypes.string,
  sensorTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  // Available sensors
  sensorsTitle: PropTypes.string.isRequired,
  sensorsPlaceholderText: PropTypes.string,
  sensorOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  defaultDateRange: PropTypes.string,
  showDateRangeAllOption: PropTypes.bool,
};

ExportSensorDataForm.defaultProps = {
  categoryTitle: 'Groups',
  categoryPlaceholderText: 'All groups & floors',
  sensorTypesTitle: 'Sensor types',
  sensorTypesPlaceholderText: 'All sensor types',
  sensorsTitle: 'Sensors',
  sensorsPlaceholderText: 'All sensors',
  defaultDateRange: DATE_RANGES.DAYS_7,
  defaultGranularity: GRANULARITY.hourly,
};

export default ExportSensorDataForm;
