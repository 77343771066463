import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import { StandardPage } from 'components/index';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import themes from '../../../styles/themes';
import Svg from 'components/Svg/Svg';

const Background = createGlobalStyle`
  body {
    background: linear-gradient(
        ${props => rgba(props.theme.colors.midnight, 0.75)},
        ${props => rgba(props.theme.colors.midnight, 0.75)}
      ),
      url(${window.location.origin}/smartview-bg-mobile.jpg) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    min-height: 100vh;
  }
  ${props => props.theme.media.landscape`
    body {
      background: linear-gradient(
          ${props => rgba(props.theme.colors.midnight, 0.75)},
          ${props => rgba(props.theme.colors.midnight, 0.75)}
        ),
        url(${window.location.origin}/smartview-bg-desktop.jpg) !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-attachment: fixed !important;
      min-height: 100vh;
    }
  `}
`;
const FlexContainer = styled.div`
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 1em;
  text-align: center;
  max-width: 38em;
  margin-bottom: ${props => props.theme.navigation.height};

  ${props => props.theme.media.landscape`
    padding: var(--size-xl) var(--size-xl);
    margin-bottom: calc(${props => props.theme.navigation.height} + ${props => props.theme.navigation.tabletSpacing});
  `}

  @media (max-width: ${props => props.theme.breakpoints.landscape}px) {
    max-width: inherit;
  }

  @media (max-width: ${props => props.theme.breakpoints.portrait}px) {
    max-width: inherit;
  }
`;

export const Header = styled.header`
  position: relative;
  padding: 0 2em;
`;

export const LanguageSelectorHeader = styled.div`
  display: block;
  position: absolute;
  right: var(--size-sm);
  top: var(--size-sm);
`;

const Logo = styled.div`
  background: var(--logo-welcome-bg);
  height: 55px;
  width: 150px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--size-md);
`;

const PageTemplate = props => {
  const { children, title, header, languageSelector } = props;

  return (
    <ThemeProvider theme={themes.customerPlatform}>
      <Background />
      <Helmet title={title} />
      {header && <Header>{header}</Header>}
      {languageSelector && <LanguageSelectorHeader>{languageSelector}</LanguageSelectorHeader>}
      <FlexContainer>
        <StandardPage noMargin noBackground noFooter noHeight>
          <Container>
            <LogoContainer>
              <a href="/">
                <Logo alt="Caverion SmartView Logo" />
              </a>
            </LogoContainer>
            {children}
          </Container>
        </StandardPage>
      </FlexContainer>
    </ThemeProvider>
  );
};

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  header: PropTypes.any,
  languageSelector: PropTypes.any,
};

export default PageTemplate;
