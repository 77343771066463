import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'utils/Date/dateFormatter';
import toDate from 'date-fns/toDate';
import parseISO from 'date-fns/parseISO';

export const StyledDateColumn = styled.div`
  color: ${props => props.theme.colors.black};
`;

const DateRow = styled.div`
  font-size: ${props => props.theme.fontSize.xxs};

  ${props => props.theme.media.landscape`
        font-size: ${props => props.theme.fontSize.xs};
  `}
`;

const DateColumn = props => {
  if (!props.data || !props.data.date) {
    return null;
  }
  // Handle date objects and ISO string values
  const date = typeof props.data.date === 'string' ? parseISO(props.data.date) : toDate(props.data.date.valueOf());
  return (
    <StyledDateColumn>
      <DateRow>
        {format(date, 'P')} - {format(date, 'p')}
      </DateRow>
    </StyledDateColumn>
  );
};

DateColumn.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

export default DateColumn;
