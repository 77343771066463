import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FloorOPICards from 'containers/Application/Modules/FloorModule/FloorOPICards/FloorOPICards';
import FloorPlanSection from './FloorPlanSection';
import InsightSection from './InsightSection';
import Content from 'components/Content/Content';

const getFlexDirectionFromOpiPosition = position => {
  switch (position) {
    case 'right':
      return 'row-reverse';
    case 'bottom':
      return 'column-reverse';
    case 'left':
      return 'row';
    case 'top':
    case 'hidden':
    default:
      return 'column';
  }
};

const Container = styled(Content)`
  ${props => props.theme.media.landscape`
    flex-direction: ${props => getFlexDirectionFromOpiPosition(props.opiSectionPosition)};
  `}
`;

const Grow = styled(Content)`
  flex: 1;
`;

const EMPTY_OBJECT = {};

const FloorTab = ({
  isSelectedStickyTab,
  floor,
  loading,
  openOpiDialog,
  openInsightDialog,
  renderFloorSelector,
  isExpanded = false,
  toggleExpand,
  content,
  insights = EMPTY_OBJECT,
  selectedTab,
  opiData,
  opiSectionPosition,
}) => {
  const openFloorDialog = useCallback(
    options => {
      if (typeof openOpiDialog === 'function') {
        openOpiDialog({ floorId: floor.id, ...options });
      }
    },
    [openOpiDialog, floor.id]
  );

  if (!isSelectedStickyTab) {
    return null;
  }

  return (
    <Container opiSectionPosition={opiSectionPosition}>
      <FloorOPICards loading={loading} openDialog={openOpiDialog ? openFloorDialog : undefined} data={opiData} />
      <Grow>
        <InsightSection
          floor={floor}
          openInsightDialog={openInsightDialog}
          insightMessages={insights.insightMessages}
          loading={insights.loading}
          error={insights.error}
          hasInsights={insights.hasInsights}
        />
        <FloorPlanSection
          floor={floor}
          loading={loading}
          onSensorClick={openFloorDialog}
          renderFloorSelector={renderFloorSelector}
          isExpanded={isExpanded}
          toggleExpand={toggleExpand}
          content={content}
          selectedTab={selectedTab}
        />
      </Grow>
    </Container>
  );
};

FloorTab.propTypes = {
  isSelectedStickyTab: PropTypes.bool.isRequired,
  floor: PropTypes.object.isRequired,
  openOpiDialog: PropTypes.func,
  openInsightDialog: PropTypes.func,
  renderFloorSelector: PropTypes.func.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      performance: PropTypes.string,
      title: PropTypes.string,
      toolTipContent: PropTypes.node,
    })
  ).isRequired,
  insights: PropTypes.object,
  opiData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  selectedTab: PropTypes.object,
  isExpanded: PropTypes.bool,
  opiSectionPosition: PropTypes.string,
};

export default FloorTab;
