import styled from 'styled-components';
import PropTypes from 'prop-types';

import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonCircle from 'components/Skeletons/SkeletonCircle';

import { Card } from './Card';
import { withOpiCardError } from './OPICardError';
import { createElementIdForGTM } from 'utils/Analytics/analytics';
import OPICardContent from './OPICardContent';
import OPICardIcons from './OPICardIcons';
import OPICardTrend from './OPICardTrend';
import { opiCardType } from './OPICards';

export const TitleWrapper = styled.div`
  flex: 1;
  overflow-wrap: break-word;
`;

export const Title = styled.div`
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.semibold};
  line-height: ${props => props.theme.font.lineHeight.lg};
  color: var(--headline-fg);
  ${props => props.theme.media.landscape`
    font-size: ${props => props.theme.font.size.sm};
  `}
  margin-top: ${props => (props.type === opiCardType.COMPACT ? props.theme.layoutSpacing.xs : '0')};
`;

export const Subtitle = styled.div`
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.semibold};
  line-height: ${props => props.theme.font.lineHeight.md};
  color: ${props => (props.invalid ? 'var(--error-color)' : 'var(--supplemental-text-fg)')};
  letter-spacing: 0.2px;
  margin-top: ${props => (props.type === opiCardType.COMPACT ? props.theme.layoutSpacing.xs : '0')};
  margin-bottom: ${props => (props.type === opiCardType.COMPACT ? props.theme.layoutSpacing.xs : '0')};
`;

const TargetTrend = styled(OPICardTrend)`
  margin-top: ${props => props.theme.layoutSpacing.xs};
`;

const LoadingCircleContainer = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
LoadingCircleContainer.displayName = 'LoadingCircleContainer';

const SkeletonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  width: 75%;

  /* height = Title height + Subtitle height */
  height: ${`calc(
    ${props => props.theme.font.lineHeight.lg} * ${props => props.theme.font.size.xs} +
      ${props => props.theme.font.lineHeight.md} * ${props => props.theme.font.size.xxs}
  )`};
  ${props => props.theme.media.landscape`
      height: calc(${props => props.theme.font.lineHeight.lg} * ${props => props.theme.font.size.sm} +
        ${props => props.theme.font.lineHeight.md} * ${props => props.theme.font.size.xxs});
  `}
`;
SkeletonTextContainer.displayName = 'SkeletonTextContainer';

const OPICardWithoutError = ({
  clickEventType,
  content,
  contentWithCircle,
  contextualHelp,
  loading,
  onClick,
  subtitle,
  title,
  type = opiCardType.DEFAULT,
}) => {
  const hasClick = !!onClick;

  if (loading) {
    return (
      <Card>
        <LoadingCircleContainer>
          <SkeletonCircle width="70px" borderWidth="6px" margin="0px" />
        </LoadingCircleContainer>
        <SkeletonTextContainer>
          <SkeletonText />
          <SkeletonText />
        </SkeletonTextContainer>
      </Card>
    );
  }

  return (
    <Card
      // the id is for GTM to be able to recognize the clicks on OPI cards
      id={`OPICard${createElementIdForGTM(title)}`}
      data-test-id={loading ? 'SkeletonOPICard' : 'OPICard'}
      onClick={() => hasClick && onClick()}
      hasClick={hasClick}
      type={type}
    >
      <OPICardContent content={content} contentWithCircle={contentWithCircle} />
      <TitleWrapper>
        <Title type={type}>{title}</Title>
        {subtitle && <Subtitle invalid={content && content.isInvalid}>{subtitle}</Subtitle>}
        {(contentWithCircle?.targetTrend || contentWithCircle?.targetTrend === 0) && (
          <TargetTrend
            trend={contentWithCircle.targetTrend}
            unit={contentWithCircle.targetTrendUnit}
            sign={contentWithCircle.targetTrendSign}
          />
        )}
      </TitleWrapper>
      <OPICardIcons title={title} contextualHelp={contextualHelp} hasClick={hasClick} clickEventType={clickEventType} />
    </Card>
  );
};

OPICardWithoutError.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  clickEventType: PropTypes.oneOf(['openModal', 'redirect']),
  onClick: PropTypes.func,
  contextualHelp: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    override: PropTypes.bool,
  }),
  content: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    trend: PropTypes.number,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isInvalid: PropTypes.bool,
    isNegative: PropTypes.bool,
    isPositive: PropTypes.bool,
  }),
  contentWithCircle: PropTypes.shape({
    value: PropTypes.number,
    status: PropTypes.string,
    // neutral: uses default colors for circle
    neutral: PropTypes.bool,
    isAirQuality: PropTypes.bool,
    // Sustainability: trend shows the projected cumulatice value for current year
    // sign is used to declare color that indicates whether the value is above or below the target
    targetTrendSign: PropTypes.oneOf([1, -1, 0]), // use Math.sign(), (positive, netgative, neutral)
    targetTrend: PropTypes.number,
    targetTrendUnit: PropTypes.string,
  }),
  loading: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(opiCardType)),
};

export const OPICard = withOpiCardError(OPICardWithoutError);

export default OPICard;
