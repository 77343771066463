import styled, { css, withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Svg from 'components/Svg/Svg';
import * as styles from './SnackBar.module.scss';
import classNames from 'classnames';

export const StyledSnackBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : '100%')});
  box-shadow: 0 -2px 20px 0 rgb(0 0 0 / 0.15);
  transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: ${props => props.theme.fontWeight.bold};
  z-index: ${props => props.theme.zIndex('snackbar')};

  ${props =>
    !props.visible &&
    !!props.hideDelay &&
    css`
      transition-delay: ${props.hideDelay}ms;
    `}

  ${props => {
    switch (props.variant) {
      case 'confirmation':
        return css`
          background: ${props.theme.colors.white};
          color: ${props.theme.colors.black};
        `;
      case 'error':
        return css`
          background: ${props.theme.colors.radicalRed};
          color: ${props.theme.colors.white};
          z-index: ${props => props.theme.zIndex('snackbarError')};
        `;
      case 'success':
      default:
        return css`
          font-family: ${props.theme.fontFamily.heading};
          background: ${props.theme.colors.emerald};
          color: ${props.theme.colors.white};
        `;
    }
  }}
`;
StyledSnackBar.displayName = 'StyledSnackBar';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 54em;
  margin: 0 auto;
  padding: ${props => (props.slim ? 'var(--size-xs)' : 'var(--size-md)')};
  min-height: 4em;

  ${props => props.theme.media.desktop`
    max-width: 80em;
  `};
`;
ContentWrapper.displayName = 'ContentWrapper';

// If there's only one child, we want to center it
const Content = styled.div`
  &:only-child {
    margin: 0 auto;
  }
`;
Content.displayName = 'Content';

const StyledSvg = styled(Svg)`
  height: 1.25em;
  width: 1.25em;
  margin-right: 0.5em;
  vertical-align: middle;
  margin-bottom: 0.25em;
`;
StyledSvg.displayName = 'StyledSvg';

export const SnackBar = ({
  children,
  variant = 'success',
  visible = false,
  primaryContent,
  secondaryContent,
  hideDelay = 0,
  theme,
  slim = false,
  ...other
}) => {
  const content = primaryContent || children;
  return (
    <StyledSnackBar
      visible={visible}
      variant={variant}
      hideDelay={hideDelay}
      className={classNames(primaryContent && styles.snackBar)}
      {...other}
    >
      <ContentWrapper slim={slim}>
        {content && (
          <Content>
            {variant === 'success' && <StyledSvg name="check" fill={theme.colors.white} />}
            {content}
          </Content>
        )}
        {secondaryContent && <Content>{secondaryContent}</Content>}
      </ContentWrapper>
    </StyledSnackBar>
  );
};

export default withTheme(SnackBar);

SnackBar.displayName = 'SnackBar';

SnackBar.propTypes = {
  variant: PropTypes.string,
  visible: PropTypes.bool,
  children: PropTypes.node,
  primaryContent: PropTypes.node,
  secondaryContent: PropTypes.node,
  /** Hide delay in milliseconds */
  hideDelay: PropTypes.number,
  theme: PropTypes.object,
  slim: PropTypes.bool,
};
