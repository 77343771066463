import { FC, SVGAttributes } from 'react';
import styled, { css } from 'styled-components';

import Svg from 'components/Svg/Svg';

type CaretToggleProps = {
  type: 'accordion' | 'dropdown';
  isOpen: boolean;
} & Omit<SVGAttributes<SVGElement>, 'type'>;

type CaretIconProps = {
  isOpen: boolean;
  name: string;
} & SVGAttributes<SVGElement>;

const iconBaseStyle = css`
  fill: var(--caret-fg);
  font-size: var(--size-xs-plus);
  transition: 0.2s ease-in-out;
`;

const CaretIconAccordion = styled(({ isOpen, name, ...restProps }: CaretIconProps) => (
  <Svg name={name} {...restProps} />
))`
  ${iconBaseStyle}

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(90deg);
    `};
`;

const CaretIconDropdown = styled(({ isOpen, name, ...restProps }: CaretIconProps) => (
  <Svg name={name} {...restProps} />
))`
  ${iconBaseStyle}

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;

/**
 * @implements {UIv3}
 *
 * @param type Type is 'accordion' or 'dropdown'
 * @param isOpen
 * @param props
 */
export const CaretToggle: FC<CaretToggleProps> = ({ type, isOpen, ...props }) => {
  if (type === 'accordion') {
    return <CaretIconAccordion name="caret-right" isOpen={isOpen} {...props} />;
  }

  if (type === 'dropdown') {
    return <CaretIconDropdown name="caret-down" isOpen={isOpen} {...props} />;
  }

  return null;
};
