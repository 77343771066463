import PropTypes from 'prop-types';
import get from 'lodash/get';
import parseISO from 'date-fns/parseISO';

import Datepicker from 'components/Datepicker/Datepicker';

const InputDate = ({ model, property, startDateProperty, endDateProperty, value, clearable, onChange, ...props }) => {
  let selected = value || get(model, property);
  if (typeof selected === 'string') {
    selected = parseISO(selected);
  }

  return (
    <Datepicker
      {...props}
      selected={selected}
      onChange={newValue => onChange(property, newValue)}
      isClearable={clearable}
      startDate={get(model, startDateProperty)}
      endDate={get(model, endDateProperty)}
    />
  );
};

InputDate.propTypes = {
  // form props
  model: PropTypes.object,
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),

  // property names for startDate and endDate
  startDateProperty: PropTypes.string,
  endDateProperty: PropTypes.string,

  // styling props
  iconName: PropTypes.string,
  simple: PropTypes.bool,
};

export default InputDate;
