import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon, ICON_TYPES } from 'components/Icon/Icon';
import { icons } from 'components/Icon/IconNames';
import Loader from 'components/Loader/Loader';

const DropZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${props => props.height};
  border: var(--download-region-border);
  text-align: center;
  padding: var(--size-md) var(--size-xl);
  background: var(--download-region-bg);
  cursor: pointer;
`;

const DropZoneHeaderText = styled.h3`
  color: var(--download-header-fg);
  font-size: ${props => props.theme.font.size.sm};
  margin: var(--size-sm);
`;

const DropZoneDescription = styled.p`
  color: var(--download-text-fg);
  font-size: ${props => props.theme.font.size.xs};
  line-height: ${props => props.theme.font.lineHeight.md};
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5em;
  height: 5em;
  margin-top: 0.5em;
`;

// Using dropzone, styling props from here: https://react-dropzone.netlify.com/
const FileUpload = props => {
  const {
    upload,
    t,
    uploading = false,
    height,
    allowedMimeTypes = ['svg', 'png', 'jpg', 'dwg'],
    maxFileSize,
    noIcon = false,
    header = t('Dropzone Header'),
    noMimeTypes = false,
    multiple = false,
    infoText,
    accept,
  } = props;

  let firstItems = allowedMimeTypes;
  let lastItem;
  if (allowedMimeTypes && allowedMimeTypes.length > 1) {
    firstItems = allowedMimeTypes.slice(0, allowedMimeTypes.length - 1);
    lastItem = allowedMimeTypes[allowedMimeTypes.length - 1];
  }

  return (
    <Dropzone onDrop={upload} style={{}} multiple={multiple} accept={accept}>
      <DropZoneContainer height={height}>
        {uploading && (
          <LoaderWrapper>
            <Loader size="LARGE" />
          </LoaderWrapper>
        )}
        {!uploading && !noIcon && <Icon name={icons.IMAGES} iconType={ICON_TYPES.DROPZONE} />}

        <DropZoneHeaderText>{header}</DropZoneHeaderText>

        {!noMimeTypes && firstItems && firstItems.length && (
          <DropZoneDescription>
            {lastItem
              ? t('Allowed file types are {0} and {1}', firstItems.join(', '), lastItem)
              : t('Allowed file type is {0}', firstItems[0])}
          </DropZoneDescription>
        )}
        {infoText && <DropZoneDescription>{infoText}</DropZoneDescription>}
        {maxFileSize && <DropZoneDescription>{`${t('Maximum file size')}: ${maxFileSize}`}</DropZoneDescription>}
      </DropZoneContainer>
    </Dropzone>
  );
};

FileUpload.defaultProps = {
  height: 'fit-content',
};

FileUpload.propTypes = {
  upload: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
  height: PropTypes.string,
  allowedMimeTypes: PropTypes.arrayOf(PropTypes.string),
  maxFileSize: PropTypes.string,
  noIcon: PropTypes.bool,
  header: PropTypes.string,
  noMimeTypes: PropTypes.bool,
  multiple: PropTypes.bool,
  infoText: PropTypes.string,
  accept: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

export default FileUpload;
