import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import IconButton from 'components/Button/IconButton';

const StyledViewModeButton = styled(IconButton)`
  ${props => (props.useInitialPadding ? '' : 'padding: var(--size-xs);')}
  margin-left: var(--size-xxs);
  ${props => (props.useMarginRight ? 'margin-right: var(--size-sm);' : '')}
  outline: none;
  text-transform: uppercase;
  height: auto;
  border-radius: 3px;

  svg {
    font-size: ${props => props.theme.font.size.md};
    transform: ${props => props.rotateIcon && 'rotate(90deg)'};
  }
`;

const strokes = theme => ({
  filter: 'var(--white)',
  addNew: 'var(--white)',
  activeView: 'var(--blue-01)',
  view: 'var(--grey-100)',
});
const colors = theme => ({
  filter: 'var(--blue-01)',
  addNew: 'var(--green-01)',
  activeView: 'transparent',
  view: 'transparent',
});

const getColorProps = (theme, modeType) => ({
  stroke: strokes(theme)[modeType],
  color: colors(theme)[modeType],
});

const ViewModeButton = ({ theme, modeType, ...other }) => {
  return (
    <StyledViewModeButton
      {...other}
      stroke={strokes(theme)[modeType]}
      color={colors(theme)[modeType]}
      useMarginRight={!modeType?.view}
      useInitialPadding={modeType === 'addNew'}
      rotateIcon={modeType === 'filter'}
    />
  );
};

ViewModeButton.propTypes = {
  theme: PropTypes.object.isRequired,
  modeType: PropTypes.oneOf(['filter', 'addNew', 'view', 'activeView']).isRequired,
};

export default withTheme(ViewModeButton);
