import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CUSTOM_TYPE, formatTitleForUrl } from '../utils/UserManualUtils';
import { PrismicRichText } from '@prismicio/react';

const StyledUserManualHeader = styled.div`
  width: 100%;

  h1 {
    margin-right: var(--size-md);
  }

  p {
    margin: var(--size-md) 0 var(--size-xl);
  }
`;

StyledUserManualHeader.displayName = 'StyledUserManualHeader';

const StyledUserManualHeaderV2 = styled.div`
  align-items: baseline;
  line-height: 1.5;
  margin-bottom: var(--size-xl);
  width: 100%;

  h1 {
    margin-right: var(--size-md);
  }

  h2 {
    font-size: ${props => props.theme.font.size.xl};
  }

  p {
    margin: 0.9rem 0;
  }

  ul {
    list-style: disc;
    margin-left: var(--size-xl);
  }

  ol {
    margin-left: var(--size-xl);
  }

  ul,
  ol {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
  }
  img {
    border: solid var(--size-xxs) ${props => props.theme.colors.lightGray};
    max-width: 100%;
    max-height: 100%;
    margin: var(--size-md) 0;
  }
`;

StyledUserManualHeaderV2.displayName = 'StyledUserManualHeaderV2';

export const UserManualHeader = ({ type, data }) => {
  if (data && data.title) {
    if (CUSTOM_TYPE.USER_MANUAL === type) {
      return (
        <StyledUserManualHeaderV2 id={formatTitleForUrl(data.title[0].text)} data-test-id="UserManualHeader">
          <PrismicRichText field={data.title} />
          {data.version && <PrismicRichText field={data.version} />}
          {data.description && <PrismicRichText field={data.description} />}
        </StyledUserManualHeaderV2>
      );
    }
    return (
      <StyledUserManualHeader data-test-id="UserManualHeader">
        {data.title && <PrismicRichText field={data.title} />}
        {data.version && <PrismicRichText field={data.version} />}
        {data.description && <PrismicRichText field={data.description} />}
      </StyledUserManualHeader>
    );
  }
  return null;
};

UserManualHeader.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};
