import { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

export function clickInside(event, element) {
  return !element ? false : element.contains(event.target);
}

/**
 * Almost like airbnb's `react-outside-click-handler`, but handles click before passing down.
 * This is important when e.g. a button is clicked outside a popup. The popup should be closed
 * before the effect of the button click. Any children count as "inside", and additional refs
 * can be passed to extend the set. You can do this e.g. to prevent double handling of the
 * toggling button.
 */
export function PassThroughOutsideClickHandler({
  children,
  additionalInsideElements = [],
  onOutsideClick,
  disabled = false,
}) {
  const containerRef = useRef();

  const handleMouseDown = useCallback(
    event => {
      if (disabled) return;

      if (clickInside(event, containerRef.current)) {
        return;
      }

      for (const additionalInsideElement of additionalInsideElements) {
        if (clickInside(event, additionalInsideElement)) {
          return;
        }
      }

      onOutsideClick?.();
    },
    [onOutsideClick, additionalInsideElements, disabled]
  );

  useEffect(() => {
    if (!disabled) {
      document.addEventListener('mousedown', handleMouseDown, true);

      return () => {
        document.removeEventListener('mousedown', handleMouseDown, true);
      };
    }
  }, [handleMouseDown, disabled]);

  return <div ref={containerRef}>{children}</div>;
}

PassThroughOutsideClickHandler.propTypes = {
  children: PropTypes.any,
  additionalInsideElements: PropTypes.array,
  onOutsideClick: PropTypes.func,
  disabled: PropTypes.bool,
};
