import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SecondaryButton from 'components/Button/SecondaryButton';
import TableConfigurationModal from './TableConfigurationModal';

const IconButtonContainer = styled.div`
  display: none;

  ${props => props.theme.media.landscape`
        display: block;
    `}
`;
IconButtonContainer.displayName = 'IconButtonContainer';

const TableConfiguration = props => {
  const { configurationOptions, configuration, onSubmit } = props;

  const [showConfiguration, setShowConfiguration] = useState(false);

  const onClose = () => setShowConfiguration(false);

  return (
    <>
      <IconButtonContainer>
        <SecondaryButton iconName="admin-panel" onClick={() => setShowConfiguration(true)} />
      </IconButtonContainer>
      {showConfiguration && (
        <TableConfigurationModal
          configurationOptions={configurationOptions}
          configuration={configuration}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      )}
    </>
  );
};

TableConfiguration.propTypes = {
  configurationOptions: PropTypes.array.isRequired,
  configuration: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TableConfiguration;
