import { useEffect } from 'react';
import useQuery from 'decorators/Query/useQuery';
import { USAGE_TYPES } from 'components/PublicView/BuildingFloorUsageType';

const useDefaultFullScreen = usageType => {
  const { setQuery } = useQuery();

  useEffect(() => {
    if (usageType === USAGE_TYPES.HORIZONTAL_INFOSCREEN) {
      setQuery({ expanded: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDefaultFullScreen;
