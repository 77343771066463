import { useWindowVirtualizer, Virtualizer, VirtualizerOptions } from '@tanstack/react-virtual';
import { TableOptions, Table, PartialKeys, RowData } from '@tanstack/react-table';

import { useTable } from 'components/VirtualizedTable/useTable';

export type TableWindowVirtualizer = Virtualizer<Window, HTMLTableRowElement>;

/**
 * Low-level hook that couples `useWindowVirtualizer` from `@tanstack/react-virtual` with
 * `useTable` from `react-table`.
 *
 * @param tableOptions Table options. For automatic width for all columns and the table, set `meta.width = "auto"`.
 *   For full-width table, set `meta.width = "full"`.
 *   See {@link https://tanstack.com/table/v8/docs/adapters/react-table}.
 * @param virtualizerOptions Patch default virtualizer options. You should at least provide `estimateSize`
 *   (the default function always returns 80). Default `overscan` is 5.
 *   See {@link https://tanstack.com/virtual/v3/docs/api/virtualizer}
 */
export function useVirtualizedTable<TData extends RowData>(
  tableOptions: PartialKeys<TableOptions<TData>, 'getCoreRowModel'>,
  virtualizerOptions: Partial<VirtualizerOptions<Window, HTMLTableRowElement>>
): [Table<TData>, TableWindowVirtualizer] {
  const table = useTable(tableOptions);
  const virtualizer = useWindowVirtualizer<HTMLTableRowElement>({
    count: tableOptions.state?.pagination?.pageSize
      ? Math.min(tableOptions.state?.pagination?.pageSize, table.getRowModel().rows.length)
      : table.getRowModel().rows.length,
    estimateSize: () => 80,
    overscan: 5,
    ...virtualizerOptions,
  });

  return [table, virtualizer];
}
