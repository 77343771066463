import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Hero from 'components/Hero/Hero';
import TabContent from 'components/TabContent/TabContent';
import FloorTabs from 'components/FloorTabs/FloorTabs';
import ErrorPageContent from 'components/ErrorPage/ErrorPageContent';
import InsightDialog from 'components/Insights/InsightDialog';
import FloorSensorDialog from 'containers/Application/Modules/FloorModule/FloorSensorDialog/FloorSensorDialog';

import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import usePublicSelectedFloor from './usePublicSelectedFloor';
import { createFloorTabsContent } from './utils';
import usePageReload from './usePageReload';
import useQuery from 'decorators/Query/useQuery';
import useDefaultFullScreen from './useDefaultFullScreen';
import usePublicMeta from './usePublicMeta';
import * as dialogUtils from 'containers/Application/Modules/FloorModule/dialogUtils';

const EMPTY_OBJECT = {};

const BuildingFloors = ({ publicView }) => {
  const { sensorHierarchies: floors, configuration = EMPTY_OBJECT } = publicView;
  const { t } = useUnauthenticatedTranslations();
  const { query, setQuery } = useQuery();
  const meta = usePublicMeta(publicView);
  usePageReload();
  useDefaultFullScreen(publicView.usageType);

  const [floorData, loadingFloor, floorError] = usePublicSelectedFloor(publicView, floors);
  const content = useMemo(
    () =>
      createFloorTabsContent({
        t,
        floors,
        floorData,
        configuration,
        meta,
      }),
    [t, floors, floorData, configuration, meta]
  );
  const insights = useMemo(
    () => ({
      insightMessages: floorData.insightMessages,
      loading: loadingFloor,
      error: floorError,
      hasInsights: !!floorData.insightMessages && configuration.showInsights,
    }),
    [floorData, loadingFloor, floorError, configuration]
  );

  const openInsightDialog = useCallback(dialogUtils.openInsightDialogFn(setQuery), [setQuery]);
  const openOpiDialog = useCallback(dialogUtils.openOpiDialogFn(setQuery, floorData.sensors), [
    setQuery,
    floorData.sensors,
  ]);

  let opiDialogProps;
  if (query.openModalSensorIds && query.openModalFloorId && !loadingFloor) {
    opiDialogProps = dialogUtils.getOpiDialogProps({
      t,
      query,
      setQuery,
      floors: [floorData.floor],
      sensors: floorData.sensors,
      buildingMeta: meta,
      functionalLocation: publicView.functionalLocation,
      sensorAlarmsById: EMPTY_OBJECT,
    });
  }

  let insightDialogProps;
  if (query.openInsightId && !loadingFloor) {
    insightDialogProps = dialogUtils.getInsightDialogProps({
      t,
      query,
      setQuery,
      functionalLocation: publicView.functionalLocation,
      insightMessages: insights.insightMessages,
    });
  }

  return (
    <>
      {configuration.showHero && (
        <Hero
          functionalLocation={publicView.functionalLocation}
          title={publicView.functionalLocation.description}
          type="BU"
          loadingContext={loadingFloor}
          t={t}
          showAddress
          isBuilding
          isPublicLink
        />
      )}
      <TabContent>
        {floorError ? (
          <ErrorPageContent title={t('Cannot load selected floor')} />
        ) : (
          <FloorTabs
            t={t}
            loading={loadingFloor}
            content={content}
            insights={insights}
            openInsightDialog={openInsightDialog}
            openOpiDialog={configuration.showSensorModals ? openOpiDialog : undefined}
            opiSectionPosition={configuration.OPICardPosition}
          />
        )}
      </TabContent>

      {opiDialogProps && (
        <FloorSensorDialog {...opiDialogProps} publicViewId={publicView.id} floorId={floorData.floor.id} />
      )}
      {insightDialogProps && <InsightDialog {...insightDialogProps} />}
    </>
  );
};

BuildingFloors.propTypes = {
  publicView: PropTypes.object.isRequired,
};

export default BuildingFloors;
