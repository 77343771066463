import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import RescuePlanService from 'services/rescuePlan';
import ActionSections from './ActionSections';

export const RescuePlanActionGuide = ({ t, planId, planLanguage }) => {
  const [actionSections, setActionSections] = useState([]);
  const [error, setError] = useState(null);

  const fetchGuide = async id => {
    try {
      const sections = await RescuePlanService.actionSectionsById(id, planLanguage);
      setActionSections(sections.actionSections);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchGuide(planId);
  }, [planId, planLanguage]);

  return <ActionSections actionSections={actionSections} />;
};

RescuePlanActionGuide.propTypes = {
  t: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  planLanguage: PropTypes.string.isRequired,
};

export default translations(RescuePlanActionGuide);
