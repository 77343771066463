import { Fragment } from 'react';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import translations from 'decorators/Translations/translations';
import { getPerformanceStatus } from 'utils/Data/performance';
import { FLOOR_OPI } from 'containers/Application/Modules/FloorModule/FloorOPICards/FloorOPIUtils';
import { CELSIUS } from 'utils/Data/values';

const performanceColors = props => ({
  ok: props.theme.status.okColor,
  warning: props.theme.status.warningColor,
  alert: props.theme.status.alarmColor,
});

const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSize.note};
  line-height: 1.5;
`;

const TooltipStatus = styled.span`
  color: ${props => performanceColors(props)[props.status]};
  margin-left: 2em;
`;

const hasAirQualityData = airQuality => airQuality && airQuality.sensors && airQuality.sensors.length > 0;
const hasTemperatureData = temperature => temperature && !isNil(temperature.value);

export const hasData = opiData => {
  const airQuality = opiData[FLOOR_OPI.airQuality];
  const temperature = opiData[FLOOR_OPI.temperature];

  if (hasAirQualityData(airQuality) || hasTemperatureData(temperature)) {
    return true;
  }
  return false;
};

const FloorTooltip = ({ t, opiData }) => {
  const airQuality = opiData[FLOOR_OPI.airQuality];
  const temperature = opiData[FLOOR_OPI.temperature];

  const hasAirQuality = hasAirQualityData(airQuality);
  const hasTemperature = hasTemperatureData(temperature);

  if (!hasAirQuality && !hasTemperature) {
    return null;
  }

  return (
    <Fragment>
      {hasAirQuality && (
        <TooltipRow>
          <span>{t('Indoor Air Quality')}</span>
          <TooltipStatus status={getPerformanceStatus(airQuality.value, true)}>
            {airQuality.value ? `${airQuality.value}%` : '-'}
          </TooltipStatus>
        </TooltipRow>
      )}
      {hasTemperature && (
        <TooltipRow>
          <span>{t('Temperature')}</span>
          <TooltipStatus status={getPerformanceStatus(temperature.statusValue)}>
            {`${temperature.value}${CELSIUS}`}
          </TooltipStatus>
        </TooltipRow>
      )}
    </Fragment>
  );
};

FloorTooltip.propTypes = {
  t: PropTypes.func.isRequired,
  opiData: PropTypes.object.isRequired,
};

export default translations(FloorTooltip);
