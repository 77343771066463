import { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';

import ScatterPlotAnnotationForm from './ScatterPlotAnnotationForm';
import useVirtualReference from '../../../useVirtualReference';

import * as Styled from './ScatterPlotCreateAnnotation.styled';

const ScatterPlotCreateAnnotation = props => {
  const { onClose, containerRef, createAnnotationAt } = props;
  const { position } = createAnnotationAt;

  const [popperElement, setPopperElement] = useState(null);
  const virtualReference = useVirtualReference(position);
  const { styles, attributes } = usePopper(virtualReference, popperElement, {
    placement: 'top',
    modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
  });

  return ReactDOM.createPortal(
    <Styled.PortalContainer>
      <Styled.Backdrop onClick={onClose} />
      <Styled.Popover ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <Styled.Annotation>
          <ScatterPlotAnnotationForm {...props} />
        </Styled.Annotation>
      </Styled.Popover>
    </Styled.PortalContainer>,
    containerRef.current
  );
};

ScatterPlotCreateAnnotation.propTypes = {
  sensor: PropTypes.object,
  chart: PropTypes.object,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  onClose: PropTypes.func,
  showNotification: PropTypes.func,
  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

export default ScatterPlotCreateAnnotation;
