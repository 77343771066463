import { useEffect, Fragment, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import values from 'lodash/values';
import pick from 'lodash/pick';
import styled from 'styled-components';

import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import Benchmarking from './Benchmarking';
import { generateFilterInputs, getChangedFilterValue, hasFilters } from 'containers/Application/Energy/EnergyUtil';
import ChartSection from './ChartSection';
import ObservationsComponent from 'components/Observations/Observations';
import withQuery from 'decorators/Query/withQuery';
import connector from './connectObservations';
import { VALUE_STATUS } from 'constants/common';
import EnergyFilter from '../Energy/EnergyFilter/EnergyFilter';
import {
  filterFunctionalLocations,
  getObservationCountsByFl,
  getObservationCountsByGroupsAndMonths,
  getPerformanceOPI,
  filterObservationsByFunctionalLocations,
} from './utils';
import { useTranslations } from 'decorators/Translations/translations';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const groups = ['heating', 'cooling', 'ventilation'];

const currentYear = new Date().getFullYear();

const ObservationsFilterContainer = styled.div`
  & > div {
    padding: 0;
  }
`;
ObservationsFilterContainer.displayName = 'ObservationsFilterContainer';

const Observations = props => {
  const {
    partnerNumber,
    loadData,
    observations,
    functionalLocations = EMPTY_OBJECT,
    loadMoreObservations,
    setQuery,
    query = EMPTY_OBJECT,
    loading,
    loadingBenchmark,
    observationBenchmark,
    buildingMeta,
    loadBuildingMeta,
  } = props;
  const [t] = useTranslations();
  const queryYear = query.year;
  const [year, setYear] = useState(queryYear ?? currentYear);
  const [onlyCompleted, setOnlyCompleted] = useState(false);
  const [metaFiltersOpen, setMetaFiltersOpen] = useState(false);
  const [selectedMetaFilterValues, setSelectedMetaFilterValues] = useState({});
  const filteredFunctionalLocations = useMemo(
    () => filterFunctionalLocations(values(functionalLocations), selectedMetaFilterValues, buildingMeta),
    [functionalLocations, selectedMetaFilterValues, buildingMeta]
  );

  const filteredObservations = useMemo(
    () => filterObservationsByFunctionalLocations(observations, filteredFunctionalLocations),
    [observations, filteredFunctionalLocations]
  );

  const observationCountsByGroupsAndMonths = useMemo(
    () => getObservationCountsByGroupsAndMonths(filteredObservations[year]?.data, groups, onlyCompleted),
    [filteredObservations, year, onlyCompleted]
  );

  const metaFilterInputs = useMemo(() => {
    const buildingMetaByFL = pick(buildingMeta.meta, Object.keys(functionalLocations));
    return generateFilterInputs(buildingMetaByFL, t);
  }, [buildingMeta, t, functionalLocations]);

  const performance = getPerformanceOPI(filteredObservations.current);
  const mostOpen = getObservationCountsByFl(t, observationBenchmark?.open, filteredFunctionalLocations, partnerNumber);
  const mostCompleted = getObservationCountsByFl(
    t,
    observationBenchmark?.completed,
    filteredFunctionalLocations,
    partnerNumber
  );

  useEffect(() => {
    loadData(partnerNumber, queryYear ?? currentYear);
    setYear(queryYear ?? currentYear);
    setSelectedMetaFilterValues({});
  }, [partnerNumber, currentYear]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const functionalLocationKeys = Object.keys(functionalLocations);
    if (functionalLocationKeys.length > 0) {
      loadBuildingMeta(functionalLocationKeys);
    }
  }, [functionalLocations]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (queryYear && queryYear !== year) {
      loadMoreObservations(partnerNumber, queryYear);
      setYear(queryYear);
    }
  }, [queryYear]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMetaFilterChange = useCallback(
    (property, value, originalValue) => {
      setSelectedMetaFilterValues(getChangedFilterValue(selectedMetaFilterValues, property, value, originalValue));
    },
    [selectedMetaFilterValues, setSelectedMetaFilterValues]
  );

  const changeYear = newYear => {
    setYear(newYear);
    loadMoreObservations(partnerNumber, newYear);
  };

  const showFilters = !loading && hasFilters(metaFilterInputs);
  return (
    <Fragment>
      {showFilters && (
        <ObservationsFilterContainer>
          <EnergyFilter
            t={t}
            filtersOpen={metaFiltersOpen}
            filter={metaFilterInputs}
            toggleFilterClick={() => setMetaFiltersOpen(!metaFiltersOpen)}
            selectedFilterValues={selectedMetaFilterValues}
            loading={loading}
            handleFilterChange={handleMetaFilterChange}
          />
        </ObservationsFilterContainer>
      )}
      <OPICards>
        <OPICard
          title={t('Observations')}
          subtitle={`${performance.completed ? performance.completed : '-'}\u00A0/\u00A0${
            performance.total ? performance.total : '-'
          }\u00A0${t('Completed')}`}
          contextualHelp={{
            text: `${CTXHELP_PREFIX} Observations OPI`,
          }}
          contentWithCircle={{
            value: performance.value,
            neutral: true,
          }}
          clickEventType="openModal"
          onClick={() => setQuery({ observationsModalVisible: true })}
          loading={loading}
        />
        {filteredObservations.energy?.totalSavingsPotential * -1 > 0 && (
          <OPICard
            title={t('Savings Potential')}
            subtitle={t('Yearly consumption')}
            contextualHelp={{
              text: `${CTXHELP_PREFIX} Savings potential OPI`,
            }}
            content={{
              value: (filteredObservations.energy.totalSavingsPotential * -1) / 1000,
              unit: 'MWh',
              status: VALUE_STATUS.OK,
            }}
            clickEventType="openModal"
            onClick={() => setQuery({ observationsModalVisible: true, hideWithoutImprovement: true })}
          />
        )}
      </OPICards>
      <ObservationsComponent
        observations={filteredObservations[queryYear]?.data || EMPTY_ARRAY}
        loading={loading}
        partnerNumber={partnerNumber}
        functionalLocations={functionalLocations}
      />
      <Benchmarking
        t={t}
        ctxHelp={`${CTXHELP_PREFIX} Observations Benchmarking`}
        leftTitle="Most open observations"
        rightTitle="Most completed observations"
        leftData={mostOpen}
        rightData={mostCompleted}
        loading={loadingBenchmark}
      />
      <ChartSection
        year={Number(year)}
        changeYear={changeYear}
        series={groups.concat('other').map(group => ({
          data: observationCountsByGroupsAndMonths[group] || [],
          name: t(capitalize(group)),
          _showTooltipForZeroValue: true,
          _unit: t('Observations'),
          _hideTooltipUnit: true,
        }))}
        loading={!filteredObservations[year] || filteredObservations[year].loading}
        tabOptions={[
          { label: t('New'), value: 'new' },
          { label: t('Completed'), value: 'completed' },
        ]}
        selectedTab={onlyCompleted ? 'completed' : 'new'}
        onTabChange={(property, value) => setOnlyCompleted(value === 'completed')}
      />
    </Fragment>
  );
};

Observations.propTypes = {
  observations: PropTypes.shape({
    current: PropTypes.array,
    energy: PropTypes.array,
  }),
  partnerNumber: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  functionalLocations: PropTypes.object.isRequired,
  loadMoreObservations: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingBenchmark: PropTypes.bool,
  observationBenchmark: PropTypes.object.isRequired,
  buildingMeta: PropTypes.shape({
    meta: PropTypes.object.isRequired,
  }).isRequired,
  loadBuildingMeta: PropTypes.func.isRequired,
  setQuery: PropTypes.func,
};

export default withQuery(connector(Observations));
