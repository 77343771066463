import { forwardRef } from 'react';
import get from 'lodash/get';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Svg from 'components/Svg/Svg';

const getBorderColor = (props, focus) => {
  if (focus) {
    if (props.highlightError || props.invalid) {
      return 'error';
    }
    return 'active';
  }
  if (props.success) {
    return 'ok';
  }
  return 'static';
};

export const StyledInput = styled.input`
  width: 100%;
  height: var(--input-height);
  line-height: ${props => props.theme.input.lineHeight.default};
  background-color: ${props => props.theme.input.background.default};
  padding: ${props => props.theme.input.padding};
  padding-left: ${props => props.hasIcon && 'calc(1.5rem + 24px)'};
  border: ${props => props.theme.input.border[getBorderColor(props)]};
  ${props =>
    (props.highlightError || props.invalid) &&
    css`
      border-bottom: ${props => props.theme.input.border.error};
    `}
  border-radius: 0;
  box-shadow: ${props =>
    props.simple ? 'none' : props.invalid ? props.theme.input.shadow.errorStatic : props.theme.input.shadow.static};
  color: ${props => props.theme.input.font.color.default};
  font-size: ${props => props.theme.input.font.mobileSize};
  font-family: ${props => props.theme.input.font.family};
  transition: ${props => props.theme.input.transition};

  &:hover,
  &:focus {
    box-shadow: ${props =>
      props.simple
        ? 'none'
        : props.invalid || props.highlightError
          ? props.theme.input.shadow.errorActive
          : props.theme.input.shadow.active};
    border: ${props => props.theme.input.border[getBorderColor(props, true)]};
  }

  &:disabled,
  &:read-only,
  &:read-only:hover,
  &:read-only:focus {
    box-shadow: ${props => (props.invalid ? props.theme.input.shadow.errorStatic : 'none')};
    color: ${props => props.theme.input.font.color.disabled};
    background-color: ${props => props.theme.input.background.disabled};
  }

  &::placeholder {
    color: ${props => props.theme.input.font.color.placeholder};
    overflow: visible;
    opacity: 1;
  }

  ${props => props.theme.media.landscape`
    font-size: ${props => props.theme.input.font.size};
  `}
`;
StyledInput.displayName = 'StyledInput';

export const Lock = styled(Svg)`
  position: absolute;
  right: 0.875rem;
  top: 0.8rem;
  fill: var(--input-subcontrol-fg-disabled);
  font-size: ${props => props.theme.font.size.lg};
`;
Lock.displayName = 'Lock';

const Icon = styled(Svg)`
  position: absolute;
  left: 0.875rem;
  top: 0.8rem;
  fill: var(--input-supplemental-icon-fg);
  font-size: ${props => props.theme.font.size.lg};
`;
Icon.displayName = 'Icon';

const Container = styled.div`
  position: relative;
`;
Container.displayName = 'Container';

export const Postfix = styled.div`
  position: absolute;
  top: 16px;
  right: 2.25em;
  user-select: none;
  color: ${props => (props.disabled || props.readonly ? 'var(--input-postfix-locked)' : 'var(--input-postfix-fg)')};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  font-size: ${props => props.theme.input.font.mobileSize};
  ${props => props.theme.media.landscape`
    font-size: ${props => props.theme.input.font.size};
  `}
`;

// Use value if it is explicitly provided in the props. Otherwise, get the property from the model.
const getValueFromProps = ({ value, model, property, uncontrolled }) => {
  if (uncontrolled) {
    return;
  }
  if (value || value === 0) {
    return value;
  }
  const modelValue = get(model, property);
  if (modelValue || modelValue === 0) {
    return modelValue;
  }
  return '';
};

export const InputText = forwardRef((props, ref) => {
  const value = getValueFromProps(props);
  const postfix = (props.isOrdinal && '.') || (props.isPercentage && '%') || props.postfix;

  return (
    <Container className={props.className}>
      {props.icon && <Icon name={props.icon} />}
      <StyledInput
        ref={ref}
        id={props.id}
        data-test-id={props['data-test-id']}
        data-group={props['data-group']}
        value={value}
        onChange={e => props.onChange(props.property, e.target[props.type === 'number' ? 'valueAsNumber' : 'value'])}
        onBlur={props.onBlur}
        onKeyPress={props.onKeyPress}
        placeholder={props.placeholder}
        readOnly={props.readonly}
        type={props.type}
        required={props.required}
        invalid={props.invalid}
        disabled={props.disabled}
        simple={props.simple}
        hasIcon={!!props.icon}
        success={props.success}
        autoComplete={props.autoComplete}
        name={props.name}
        highlightError={props.highlightError}
        {...props.inputProps}
      />
      {props.postLabel}
      {postfix && (
        <Postfix disabled={props.disabled} readonly={props.readonly}>
          {postfix}
        </Postfix>
      )}
      {props.readonly && <Lock name="fa-lock" />}
    </Container>
  );
});
InputText.displayName = 'InputText';

InputText.propTypes = {
  id: PropTypes.string,
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  model: PropTypes.object,
  'data-test-id': PropTypes.string,
  'data-group': PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  type: PropTypes.string,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  simple: PropTypes.bool,
  icon: PropTypes.string,
  inputProps: PropTypes.object,
  success: PropTypes.bool,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  isOrdinal: PropTypes.bool,
  isPercentage: PropTypes.bool,
  postfix: PropTypes.string,
  name: PropTypes.string,
  highlightError: PropTypes.bool,
  postLabel: PropTypes.node,
  uncontrolled: PropTypes.bool,
};

InputText.defaultProps = {
  id: undefined,
  property: '',
  model: {},
  dataTestId: undefined,
  placeholder: '',
  onBlur: undefined,
  type: undefined,
  readonly: false,
  required: false,
  invalid: false,
  disabled: false,
  simple: false,
  success: false,
};

export default InputText;
