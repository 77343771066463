import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  createShortMessageString,
  createLocationString,
  createLongMessageString,
  createFormattedLocalTimestamp,
  createDurationString,
} from './utils';

import InsightMessageBody from './InsightMessageBody';
import InsightMessageInfoContainer from './InsightMessageInfoContainer';

const InsightMessageTitle = styled.h2`
  font-size: ${props => props.theme.fontSize.subHeading};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.lineHeight.heading};
  border-bottom: ${props => (props.noBorder ? 'none' : `var(--table-row-border)`)};
  margin-bottom: ${props => props.theme.section.spacingBetween};
  padding-bottom: var(--size-md);
`;
InsightMessageTitle.displayName = 'InsightMessageTitle';

const InsightMessageSubTitle = styled.p`
  font-size: ${props => props.theme.fontSize.subHeading};
  line-height: ${props => props.theme.lineHeight.heading};
`;
InsightMessageSubTitle.displayName = 'InsightMessageSubTitle';

const HorizontalItems = styled.div`
  display: flex;
  margin-top: ${props => props.theme.section.spacingBetween};
`;
HorizontalItems.displayName = 'HorizontalItems';

const InsightMessage = ({ message, functionalLocation, location, t, enableLocationLink = true }) => {
  const baseUrl = location.pathname.match(/.*\/FunctionalLocation\/.*\//g);
  const locationUrl =
    {
      floor: `${baseUrl}Floors?expanded=false&layer=${encodeURIComponent(message.sensorHierarchyName).toLowerCase()}`,
      building: `${baseUrl}Building`,
      group: `${baseUrl}Conditions?category=${message.sensorHierarchyId}`,
    }[message.sensorHierarchyType] || '';
  return (
    <>
      <InsightMessageSubTitle>{createLocationString(message, t)}</InsightMessageSubTitle>
      <InsightMessageTitle>{createShortMessageString(message, t)}</InsightMessageTitle>
      <InsightMessageBody rawInsightMessage={createLongMessageString(message, t)} />
      <HorizontalItems>
        <InsightMessageInfoContainer rows={[`${t('Created at')}:`, createFormattedLocalTimestamp(message.timestamp)]} />
        <InsightMessageInfoContainer
          rows={[
            `${t('Duration and status')}:`,
            message.active ? t('Currently active for') : t('Was active for'),
            createDurationString(message.duration, t),
          ]}
        />
        <InsightMessageInfoContainer
          rows={[`${t('Location')}:`, `${functionalLocation.name}`, `${createLocationString(message, t)}`]}
          linkUrl={enableLocationLink ? locationUrl : undefined}
        />
      </HorizontalItems>
    </>
  );
};
InsightMessage.propTypes = {
  message: PropTypes.object,
  functionalLocation: PropTypes.object,
  location: PropTypes.object,
  enableLocationLink: PropTypes.bool,
  t: PropTypes.func,
};

export default withRouter(InsightMessage);
