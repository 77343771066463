import { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';
import { reportErrorToNewRelic } from 'utils/newRelic';

class ErrorBoundary extends Component {
  static defaultProps = {
    FallbackComponent: ErrorBoundaryFallback,
  };

  static propTypes = {
    FallbackComponent: PropTypes.any,
    children: PropTypes.node.isRequired,
    onError: PropTypes.func,
    originalProps: PropTypes.object,
  };

  state = {
    error: null,
    info: null,
  };

  onError(error, info) {
    try {
      if (typeof this.props.onError === 'function') {
        this.props.onError.call(this, error, info?.componentStack);
      }
      if (Error.captureStackTrace) {
        Error.captureStackTrace(error);
      }
      console.error('Error caught in error boundary', error, info?.componentStack);
      reportErrorToNewRelic(error, info);
    } catch (errorFromErrorHandling) {
      console.error('Failed to handle error', errorFromErrorHandling);
    }
  }

  componentDidCatch(error, info) {
    this.onError(error, info);
    this.setState({ error, info });
  }

  render() {
    const { children, FallbackComponent, originalProps } = this.props;
    const { error, info } = this.state;

    if (error !== null) {
      return (
        <FallbackComponent componentStack={info?.componentStack || ''} error={error} originalProps={originalProps} />
      );
    }

    return children || null;
  }
}

const getWrappedComponentName = (Component, wrapperName) => {
  const componentName = Component.displayName || Component.name;
  if (componentName) {
    return `${wrapperName}(${componentName})`;
  }
};

export const withErrorBoundary = (ComponentThatMayError, options = {}) => {
  const {
    FallbackComponent,
    onError,
    wrapperName = 'WithErrorBoundary',
    passOriginalPropsToFallback = false,
  } = options;
  const Wrapped = props => (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={onError}
      originalProps={passOriginalPropsToFallback ? props : undefined}
    >
      <ComponentThatMayError {...props} />
    </ErrorBoundary>
  );
  Wrapped.displayName = getWrappedComponentName(ComponentThatMayError, wrapperName);
  return Wrapped;
};

export default ErrorBoundary;
