import ICAL from 'ical.js';
import lowerCase from 'lodash/lowerCase';
import { format, getMonths, getMonthsShort, ordinalNumber } from 'utils/Date/dateFormatter';
import { startOfUTCDay } from 'utils/Date/date';
import { add, getISODay, toDate, set } from 'date-fns';

// parser utilities:

export const parseToJCAL = data => ICAL.parse(data);

export const parseToComponent = data => new ICAL.Component(parseToJCAL(data));

export const parseToEvent = data => new ICAL.Event(parseToComponent(data).getFirstSubcomponent('vevent'));

// data utilities:

export const parseParts = parts => {
  const parsed = {};
  Object.keys(parts).forEach(partKey => {
    // allow multiple values only for BYDAY and BYMONTH
    if (partKey === 'BYDAY' || partKey === 'BYMONTH') {
      parsed[partKey] = parts[partKey];
    } else {
      const partValue = parts[partKey]?.[0];
      if (partValue) {
        if (isNaN(partValue)) {
          parsed[partKey] = partValue;
        } else {
          parsed[partKey] = +partValue;
        }
      }
    }
  });
  return parsed;
};

export const serializeParts = parts => {
  const serialized = {};
  Object.keys(parts).forEach(partKey => {
    const partValue = parts[partKey];
    if (Array.isArray(partValue)) {
      serialized[partKey] = partValue;
    } else {
      serialized[partKey] = [String(partValue)];
    }
  });
  return serialized;
};

export const timeToDate = timeObj => timeObj.toJSDate();
export const dateToTime = dateObj => ICAL.Time.fromJSDate(startOfUTCDay(dateObj), true);

export const update = component => {
  getEventComponent(component).updatePropertyWithValue('rrule', getRecurData(component));
};

export const getOccurences = schedule => {
  const vevent = new ICAL.Event(new ICAL.Component(ICAL.parse(schedule)).getFirstSubcomponent('vevent'));
  if (!vevent.startDate) {
    throw new Error('missing DTSTART');
  }
  const iterator = vevent.iterator(vevent.startDate);
  let upperLimit = add(new Date(), { years: 5 });
  if (vevent.endDate.toJSDate() < upperLimit && schedule.includes('\nDTEND')) {
    upperLimit = vevent.endDate.toJSDate();
  }
  let dt = iterator.next();
  const plannedDates = [];
  while (dt && dt.toJSDate() < upperLimit) {
    plannedDates.push(new Date(dt.toJSDate().setUTCHours(0, 0, 0, 0)));
    dt = iterator.next();
  }
  return plannedDates;
};

// getter utilities:

export const getEventComponent = component => component.getFirstSubcomponent('vevent');
export const getAlarmComponent = component => component.getFirstSubcomponent('valarm');
export const getRRule = component => component.getFirstSubcomponent('vevent').getFirstProperty('rrule');
export const getRecurData = component => getRRule(component).getFirstValue();
export const getStart = event => timeToDate(event.startDate);
export const getEnd = component => timeToDate(getRecurData(component).until);

// constants:

export const Recurrence = {
  INTERVAL: 'interval',
  FREQUENCY: 'frequency',
  PARTS: 'parts',
  BY_PART: 'frequencyBy',
  BY_ORDINAL: 'frequencyByOrdinal',
  BY_VALUE: 'frequencyByValue',
  START: 'start',
  END: 'end',
};

export const RecurrenceParts = {
  BY_DAY: 'BYDAY',
  BY_MONTH: 'BYMONTH',
  BY_MONTH_DAY: 'BYMONTHDAY',
};

export const initSchedule = () => {
  const tomorrow = startOfUTCDay(add(new Date(), { days: 1 }));
  const tomorrowString = dateToTime(tomorrow).toICALString();

  const until = dateToTime(add(tomorrow, { years: 5 }));
  const untilString = until.toICALString();

  const rrule = `RRULE:FREQ=MONTHLY;BYMONTHDAY=${tomorrow.getDate()};UNTIL=${untilString}`;

  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:-//Caverion Corporation//NONSGML SmartView//EN',
    'BEGIN:VEVENT',
    `DTSTAMP:${tomorrowString}`,
    `DTSTART:${tomorrowString}`,
    rrule,
    'SUMMARY:Planned maintenance',
    'DESCRIPTION:Planned maintenance occurrences in a maintenance plan',
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\r\n');
};

export const HumanDay = {
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
  SU: 'Sunday',
};

export const HumanFrequency = {
  DAILY: 'Day',
  WEEKLY: 'Week',
  MONTHLY: 'Month',
  YEARLY: 'Year',
};

// form options:

// frequency options
export const getFrequencyOptions = t =>
  Object.keys(HumanFrequency)
    .filter(key => key !== 'DAILY')
    .map(frequency => ({ label: t(HumanFrequency[frequency]), value: frequency }));

export const getDefaultParts = (selectedFrequency, selectedParts = {}) => {
  const today = new Date();
  const tomorrow = add(today, { days: 1 });

  const tomorrowMonth = tomorrow.getMonth() + 1;
  const tomorrowDate = tomorrow.getDate();
  const todayWeekday = Object.keys(HumanDay)[getISODay(today) - 1];

  switch (selectedFrequency) {
    case 'WEEKLY':
      return { [RecurrenceParts.BY_DAY]: selectedParts[RecurrenceParts.BY_DAY] || [todayWeekday] };
    case 'MONTHLY':
      return { [RecurrenceParts.BY_MONTH_DAY]: selectedParts[RecurrenceParts.BY_MONTH_DAY] || tomorrowDate };
    case 'YEARLY':
      return {
        [RecurrenceParts.BY_MONTH_DAY]: selectedParts[RecurrenceParts.BY_MONTH_DAY] || tomorrowDate,
        [RecurrenceParts.BY_MONTH]: selectedParts[RecurrenceParts.BY_MONTH] || [tomorrowMonth],
      };
    default:
      return {};
  }
};

export const getByDayOptions = t => {
  return Object.keys(HumanDay).map(day => ({ label: t(HumanDay[day]), value: day }));
};

export const getByMonthOptions = () => getMonthsShort().map((month, index) => ({ label: month, value: index + 1 }));

// format schedule data:

export const formatFrequency = (t, schedule) =>
  schedule[Recurrence.FREQUENCY]
    ? t(
        `Every {0} ${lowerCase(HumanFrequency[schedule[Recurrence.FREQUENCY]])}(s)`,
        schedule[Recurrence.INTERVAL] || ''
      )
    : '';

export const formatStart = schedule => (schedule[Recurrence.START] ? format(schedule[Recurrence.START], 'PPP') : '');

export const formatEnd = schedule => (schedule[Recurrence.END] ? format(schedule[Recurrence.END], 'PPP') : '');

/**
 * - Recurring by day:
 *    "maanantai"
 *    "maanantai, tiistai, torstai"
 *
 * - Recurring by month and month day:
 *    "huhtikuu (31. päivä)"
 *    "huhti, touko, kesä (31. päivä)"
 *
 * - Recurring by month:
 *    "huhtikuu"
 *    "huhti, touko, kesä"
 *
 * - Recurring by month day:
 *    "31. päivä"
 */
export const formatParts = (t, schedule) => {
  const dayParts = schedule[Recurrence.PARTS][RecurrenceParts.BY_DAY];
  const monthParts = schedule[Recurrence.PARTS][RecurrenceParts.BY_MONTH];
  const monthDayPart = schedule[Recurrence.PARTS][RecurrenceParts.BY_MONTH_DAY];

  if (dayParts) {
    return dayParts.map(day => t(HumanDay[day])).join(', ');
  }
  if (monthParts) {
    const monthNames = monthParts.length === 1 ? getMonths() : getMonthsShort();
    const months = monthParts.map(monthNumber => monthNames[monthNumber - 1]).join(', ');

    if (monthDayPart) {
      const monthDay = ordinalNumber(monthDayPart);
      return `${months} (${monthDay} ${t('day')})`;
    }

    return months;
  }
  if (monthDayPart) {
    const monthDay = ordinalNumber(monthDayPart);
    return `${monthDay} ${t('day')}`;
  }
};

export const formatServiceOrderDays = (t, maintenancePlan) =>
  maintenancePlan.createServiceOrderDays
    ? t('{0} days in advance', maintenancePlan.createServiceOrderDays)
    : maintenancePlan.createServiceOrderDays === 0
      ? t('On the day of maintenance')
      : '';

export const formatServiceOrderTime = maintenancePlan => {
  const { hours, minutes } = convertUtcMinutesAfterMidnight(maintenancePlan.createServiceOrderAt);
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

export const convertUtcMinutesAfterMidnight = utcMinutesAfterMidnight => {
  const utcTime = add(startOfUTCDay(new Date()), { minutes: utcMinutesAfterMidnight });
  const localTime = toDate(utcTime);

  return { hours: localTime.getHours(), minutes: localTime.getMinutes() };
};

export const convertLocalHoursAndMinutes = (hours, minutes) => {
  const localTime = set(new Date(), { hours, minutes });
  return localTime.getUTCHours() * 60 + localTime.getUTCMinutes();
};
