import pick from 'lodash/pick';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const filterLinkProps = props =>
  pick(props, ['children', 'to', 'component', 'replace', 'innerRef', 'title', 'id', 'className']);

// eslint-disable-next-line react/prop-types
const CleanedLink = props => <Link {...filterLinkProps(props)} />;

const getButtonElement = buttonProps => {
  if (buttonProps.externalLink) {
    return 'a';
  }
  if (buttonProps.reactLink) {
    return CleanedLink;
  }
  return undefined;
};

export const BaseButton = styled.button.attrs(props => ({
  as: getButtonElement(props),
}))`
  ${props =>
    (props.externalLink || props.reactLink) &&
    `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    `}
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: ${props => (props.large ? props.theme.font.size.xs : props.theme.font.size.xxs)};
  font-weight: ${props => props.theme.font.weight.bold};
  padding: 0 20px;
  width: ${props => (props.block ? '100%' : 'auto')};
  min-width: ${props => (props.large ? props.theme.button.large.minWidth : props.theme.button.small.minWidth)};
  height: ${props => (props.large ? props.theme.button.large.height : props.theme.button.small.height)};
  line-height: ${props => (props.large ? props.theme.button.large.lineHeight : props.theme.button.small.lineHeight)};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${props =>
    props.autoWidth &&
    `
    min-width: 0;
  `}

  appearance: none;
  &:focus {
    outline: none;
  }

  @media print {
    display: none;
  }
`;

BaseButton.propTypes = {
  large: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default BaseButton;
