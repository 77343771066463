import styled from 'styled-components';

import { useTranslations } from 'decorators/Translations/translations';
import useFilterTitle from './useFilterTitle';
import PrimaryButton from 'components/Button/PrimaryButton';

const Title = styled.h3`
  font-size: ${props => props.theme.font.size.xs};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* prevent container to change height when buttons are rendered or not */
  height: ${props => props.theme.button.small.height};
`;

type FilterTitleProps = {
  defaultSelectedFilters?: Record<string, unknown>;
  excludedFilters?: string[];
  setValidationModel: Parameters<typeof useFilterTitle>[2];
};

const FilterTitle = ({ defaultSelectedFilters, excludedFilters, setValidationModel, ...props }: FilterTitleProps) => {
  const [t] = useTranslations();
  const { filterCount, showClear, handleFilterClear, showReset, handleFilterReset } = useFilterTitle(
    defaultSelectedFilters,
    excludedFilters,
    setValidationModel
  );

  return (
    <Container {...props}>
      <Title>
        {t('Filters')} {filterCount > 0 && `(${filterCount})`}
      </Title>
      {showClear && (
        <PrimaryButton nakedLightLink onClick={handleFilterClear}>
          {t('Clear')}
        </PrimaryButton>
      )}
      {showReset && (
        <PrimaryButton nakedLightLink onClick={handleFilterReset}>
          {t('Reset')}
        </PrimaryButton>
      )}
    </Container>
  );
};

export default FilterTitle;
