import { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import translations from 'decorators/Translations/translations';
import InputTextArea from 'components/Form/InputTextArea';
import PrimaryButton from 'components/Button/PrimaryButton2';
import InputText from 'components/Form/InputText';
import { format } from 'utils/Date/dateFormatter';
import * as Styled from './AnnotationForm.styled';
import { parseISO } from 'date-fns';
import { ANNOTATION_TARGET, ANNOTATION_TARGET_LABEL } from './utils';
import { SelectInputV5 } from 'components/Select/SelectInputV5';

const getSelectedTarget = (annotation, sensor) => {
  if (annotation.functionalLocation) {
    return ANNOTATION_TARGET.building;
  }
  if (annotation.customChartId) {
    return ANNOTATION_TARGET.customChart;
  }
  if (annotation.sensorId) {
    if (sensor && String(annotation.sensorId) === String(sensor.parentId)) {
      return ANNOTATION_TARGET.device;
    }
    return ANNOTATION_TARGET.measuringPoint;
  }
};

const AnnotationForm = props => {
  const { annotation, onSubmit, onClose, sensor, t, targetOptions } = props;
  const [state, setState] = useState(
    annotation.id
      ? {
          selected: { value: annotation },
          timestamp: parseISO(annotation.timestamp),
          comment: annotation.comment,
        }
      : {
          selected: targetOptions?.[0],
          timestamp: parseISO(annotation.timestamp),
          targetOptions,
        }
  );

  const [loading, setLoading] = useState(false);
  const [missingField, setMissingField] = useState();

  const onChangeTarget = selected => setState({ ...state, selected });
  const onChangeComment = (_, comment) => setState({ ...state, comment });
  const onChangeDate = date => setState({ ...state, timestamp: date });

  const handleSubmit = async event => {
    event.preventDefault();
    if (!state.comment) {
      setMissingField('comment');
      return;
    }

    setLoading(true);
    try {
      await onSubmit({ ...state.selected, comment: state.comment, timestamp: state.timestamp });
    } catch (error) {
      setLoading(false);
      setMissingField();
    }
  };

  return (
    <Styled.Form id="annotation-form" onSubmit={handleSubmit}>
      <Styled.Label heading>
        <h2>{annotation.id ? t('Edit Note') : t('Add Note')}</h2>
        <Styled.DatePicker
          selected={state.timestamp}
          onChange={onChangeDate}
          popperPlacement="top"
          popperContainer={({ children }) =>
            ReactDOM.createPortal(
              <Styled.StyledDatePickerPopper>{children}</Styled.StyledDatePickerPopper>,
              document.body
            )
          }
          showTimeSelect
          timeFormat="HH:mm"
          customInput={
            <Styled.Time>
              {format(state.timestamp, 'PPp')} <Styled.EditIcon name="pen" />
            </Styled.Time>
          }
        />
      </Styled.Label>
      <div>
        <Styled.Label>{t('Attach note to')}</Styled.Label>
        {annotation.id ? (
          <InputText value={t(ANNOTATION_TARGET_LABEL[getSelectedTarget(state.selected, sensor)])} readonly disabled />
        ) : (
          <SelectInputV5
            onChange={onChangeTarget}
            isDisabled={targetOptions.length <= 1}
            options={targetOptions}
            value={state.selected}
            highlightError={missingField === 'target'}
            placeholder={t('Select')}
          />
        )}
      </div>
      <div>
        <Styled.Label>{t('Your note')}</Styled.Label>
        <InputTextArea
          type="text"
          property="comment"
          value={state.comment}
          onChange={onChangeComment}
          highlightError={missingField === 'comment'}
        />
      </div>
      <Styled.ButtonContainer>
        <PrimaryButton naked large block onClick={onClose} loading={loading}>
          {t('Cancel')}
        </PrimaryButton>
        <PrimaryButton confirm large block loading={loading}>
          {t('Save')}
        </PrimaryButton>
      </Styled.ButtonContainer>
    </Styled.Form>
  );
};

AnnotationForm.propTypes = {
  annotation: PropTypes.object,
  position: PropTypes.shape({
    chartX: PropTypes.number,
    chartY: PropTypes.number,
  }),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  t: PropTypes.func,
  targetOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    })
  ),
  sensor: PropTypes.object,
};

export default translations(AnnotationForm);
