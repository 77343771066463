import { useEffect } from 'react';
import queryString from 'query-string';
import { createClient } from '@prismicio/client';
import { CUSTOM_TYPE } from '../utils/UserManualUtils';

const client = createClient(process.env.REACT_APP_PRISMIC_ENDPOINT, {
  accessToken: process.env.REACT_APP_PRISMIC_TOKEN,
});

const linkResolver = doc => {
  switch (doc.type) {
    case CUSTOM_TYPE.USER_MANUAL:
    case CUSTOM_TYPE.USER_MANUAL_PAGE:
      return `/UserManual/preview`;
    default:
      // Fallback for other types, in case new custom types get created
      return '/Overview';
  }
};

const UserManualPreview = ({ history, location }) => {
  useEffect(() => {
    const params = queryString.parse(location.search);
    if (!params.token) {
      return console.warn(`No token available, check your configuration`);
    }
    client.previewSession(params.token, linkResolver, '/').then(url => history.push(url));
  });
  return null;
};

export default UserManualPreview;
