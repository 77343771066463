import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Svg from 'components/Svg/Svg';
import SortableTableRow from './SortableTableRow';
import SortableTableCell from './SortableTableCell';
import { tableHeaderCellStyle } from 'components/FlexTable/FlexTableHead';

const TableHead = styled.thead`
  display: ${props => props.hideOnMobile && 'none'};

  ${props => props.theme.media.landscape`
        display: table-header-group;
    `}
`;

const TableHeadRow = styled(SortableTableRow)`
  border: 0;
`;

export const TableHeadCell = styled(SortableTableCell)`
  ${tableHeaderCellStyle}

  white-space: nowrap;
  vertical-align: bottom;
  display: ${props => props.hideOnMobile && 'none'};
  width: ${props => (props.cellWidthMobile ? props.cellWidthMobile : 'auto')};

  ${props =>
    props.sortable &&
    css`
      cursor: pointer;
    `}

  ${props => props.theme.media.portrait`
        width: ${props => (props.cellWidth ? props.cellWidth : props.cellWidthMobile || 'auto')};
    `}

    ${props => props.theme.media.landscape`
        display: ${props => (props.hideOnDesktop ? 'none' : 'table-cell')};
    `}

    @media print {
    display: ${props => (props.hideOnPrint ? 'none' : 'table-cell')};
  }
`;

const Title = styled.span`
  display: inline-block;
  white-space: pre;
  text-align: center;
`;

const Icon = styled(Svg)`
  font-size: 10px;
  vertical-align: middle;
  margin-left: 5px;
`;

const SortableTableHead = ({
  changeOrder,
  columns,
  cellPadding,
  stripes,
  headerBackgroundColor,
  removeSidePadding,
  hideOnMobile,
  order,
  orderBy,
  borderColor = 'lightGray',
}) => {
  return (
    <TableHead hideOnMobile={hideOnMobile}>
      <TableHeadRow stripes={stripes} backgroundColor={headerBackgroundColor} borderColor={borderColor}>
        {columns &&
          columns.length > 0 &&
          columns.map((col, index) => {
            const arrowIcon = orderBy === col.field && order === 'desc' ? 'caret-down' : 'caret-up';
            const CellComponent = col.component || TableHeadCell;
            return (
              <CellComponent
                selected={orderBy === col.field}
                onClick={col.sortable ? () => changeOrder(col.field) : undefined}
                key={`${index} ${col.field}`}
                padding={cellPadding}
                sortable={col.sortable}
                align={col.align}
                cellWidth={col.width}
                cellWidthMobile={col.mobileWidth}
                removeSidePadding={removeSidePadding}
                hideOnMobile={col.hideOnMobile}
                hideOnDesktop={col.hideOnDesktop}
                hideOnPrint={col.hideOnPrint}
              >
                <Title>{col.title}</Title> {col.sortable && <Icon name={arrowIcon} key={`${index} ${col.field}`} />}
              </CellComponent>
            );
          })}
      </TableHeadRow>
    </TableHead>
  );
};

SortableTableHead.propTypes = {
  changeOrder: PropTypes.func,
  columns: PropTypes.array,
  cellPadding: PropTypes.string,
  stripes: PropTypes.bool,
  headerBackgroundColor: PropTypes.string,
  removeSidePadding: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  borderColor: PropTypes.string,
};

export default SortableTableHead;
