import { useLayoutEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import InfoBox from 'components/InfoBox/InfoBox';

const useValidationEffects = (validationErrors, ref) => {
  useLayoutEffect(() => {
    if (ref.current && !isEmpty(validationErrors)) {
      ref.current.scrollIntoView({ block: 'center' });
    }
  }, [validationErrors, ref]);

  if (isEmpty(validationErrors)) {
    return null;
  }

  return (
    <InfoBox error preWrap>
      {Object.values(validationErrors).join('\n')}
    </InfoBox>
  );
};

export default useValidationEffects;
