import PropTypes from 'prop-types';

const Svg = ({ name, ...restProps }) => {
  return (
    <svg width="1em" height="1em" {...restProps}>
      <use xlinkHref={`#${name}.inline`} />
    </svg>
  );
};

Svg.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Svg;
