import PropTypes from 'prop-types';

import * as Styled from './InfoTable.styled';
import InfoTableSkeleton from './InfoTableSkeleton';

const InfoTable = props => {
  const { loading, borderTop, borders, labelColumnWidth, valueColumnWidth, items } = props;

  if (loading) {
    return <InfoTableSkeleton {...props} />;
  }

  return (
    <Styled.Table borderTop={borderTop} borders={borders}>
      <tbody>
        {items.map(({ id, label, value }) => (
          <Styled.Row key={label} borders={borders}>
            <Styled.LabelCell columnWidth={labelColumnWidth}>{label}</Styled.LabelCell>
            <Styled.ValueCell id={id} columnWidth={valueColumnWidth}>
              {value}
            </Styled.ValueCell>
          </Styled.Row>
        ))}
      </tbody>
    </Styled.Table>
  );
};

InfoTable.defaultProps = {
  borderTop: true,
  borders: true,
};

InfoTable.propTypes = {
  loading: PropTypes.bool,
  borderTop: PropTypes.bool,
  borders: PropTypes.bool,
  labelColumnWidth: PropTypes.string,
  valueColumnWidth: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.node,
    })
  ).isRequired,
};

export default InfoTable;
