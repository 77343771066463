import PropTypes from 'prop-types';

import AnnotationPopover from './AnnotationPopover';
import CreateAnnotation from './CreateAnnotation';
import useNotification from 'decorators/Notification/useNotification';

const SensorAnnotations = props => {
  const { annotationPopover, createAnnotationAt } = props;

  const { showNotification, renderedNotification } = useNotification();

  return (
    <>
      {annotationPopover && <AnnotationPopover {...props} {...annotationPopover} showNotification={showNotification} />}
      {createAnnotationAt && (
        <CreateAnnotation {...props} {...createAnnotationAt} showNotification={showNotification} />
      )}
      {renderedNotification}
    </>
  );
};

SensorAnnotations.propTypes = {
  sensor: PropTypes.object,
  chart: PropTypes.object,
  functionalLocationKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  annotationPopover: PropTypes.object,
  createAnnotationAt: PropTypes.object,
  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

export default SensorAnnotations;
