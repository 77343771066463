import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import pickBy from 'lodash/pickBy';
import keyBy from 'lodash/keyBy';
import { createReducerFromMapping } from 'redux/utils/index';
import MasterDataService from 'services/masterData';

const initialState = {
  functionalLocationSearchResults: [],
  totalResults: 0,
  customers: {},
  functionalLocations: {},
  hierarchy: {},
  partnerSearch: {},
  typesToSearch: { BU: true, UN: true },
  error: null,
  portfolioCounts: {},
  autocomplete: {
    timestamp: 0,
    loading: false,
    result: undefined,
  },
  selectedCustomerId: undefined,
};

export const NUMBER_OF_SEARCH_RESULTS = 20;

// Test if a given string is potentially a functional location (of form FIBU000001-001-... or DEPLN...)
export const FUNCTIONAL_LOCATION_REGEX = /^((\w{8,}(-[a-z0-9]{3})+)|(depln\S+))$/i;

// Test if a given string is potentially a customer ID
export const CUSTOMER_ID_REGEX = /^\d{6,}$/;

// Test if a given string is potentially a street address number
export const STREET_NUMBER_REGEX = /^\d{1,3}$/;

export const SEARCH = 'CUSTOMER_PLATFORM/Customer/SEARCH';
export const SEARCH_SUCCESS = 'CUSTOMER_PLATFORM/Customer/SEARCH_SUCCESS';
export const SEARCH_FAIL = 'CUSTOMER_PLATFORM/Customer/SEARCH_FAIL';
export const RESET_SEARCH = 'CUSTOMER_PLATFORM/Customer/RESET_SEARCH';

export const searchFunctionalLocationsByPartners = (partners, typesToSearch) => async dispatch => {
  dispatch({ type: SEARCH, partners });
  try {
    const result = await MasterDataService.adminFunctionalLocationsByPartnerNumbers(
      partners,
      Object.keys(pickBy(typesToSearch))
    );

    return dispatch({
      type: SEARCH_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: SEARCH_FAIL,
      error,
    });
  }
};

export const resetSearch = leaveOptions => {
  return {
    type: RESET_SEARCH,
    leaveOptions,
  };
};

export const CHANGE_TYPES = 'CUSTOMER_PLATFORM/Customer/CHANGE_TYPES';
export const changeTypes = typesToSearch => {
  return {
    type: CHANGE_TYPES,
    typesToSearch,
  };
};

export const quoteSearchTerm = term => {
  const parts = term.split(' ');
  const result = [];
  for (let i = 0; i < parts.length; i++) {
    const current = parts[i];
    if (FUNCTIONAL_LOCATION_REGEX.test(current)) {
      result.push(`"${current}"`);
      continue;
    }

    const next = parts[i + 1];
    if (next && STREET_NUMBER_REGEX.test(next) && !STREET_NUMBER_REGEX.test(current)) {
      result.push(`"${current} ${next}"`);
      i++;
      continue;
    }

    result.push(current);
  }

  return result.join(' ');
};

export const AUTOCOMPLETE_PERMISSIONS = 'CUSTOMER_PLATFORM/Customer/AUTOCOMPLETE_PERMISSIONS';
export const AUTOCOMPLETE_PERMISSIONS_SUCCESS = 'CUSTOMER_PLATFORM/Customer/AUTOCOMPLETE_PERMISSIONS_SUCCESS';
export const AUTOCOMPLETE_PERMISSIONS_FAIL = 'CUSTOMER_PLATFORM/Customer/AUTOCOMPLETE_PERMISSIONS_FAIL';

const searchAutocompleteCustomersAndFunctionalLocations = async (searchText, dispatch) => {
  const [customers, functionalLocations] = await Promise.all([
    dispatch(MasterDataService.customersByKeyword(searchText)),
    MasterDataService.functionalLocationsByKeyword(searchText),
  ]);
  return { customers, functionalLocations };
};

export const autocompletePermissions = searchTerm => async dispatch => {
  const timestamp = Date.now();
  dispatch({ type: AUTOCOMPLETE_PERMISSIONS, timestamp });
  try {
    const term = quoteSearchTerm(searchTerm.trim());
    const payload = await searchAutocompleteCustomersAndFunctionalLocations(term, dispatch);
    dispatch({ type: AUTOCOMPLETE_PERMISSIONS_SUCCESS, payload, timestamp });
  } catch (error) {
    dispatch({ type: AUTOCOMPLETE_PERMISSIONS_FAIL, error, timestamp });
    throw error;
  }
};

export const AUTOCOMPLETE_PERMISSIONS_RESET = 'CUSTOMER_PLATFORM/Customer/AUTOCOMPLETE_PERMISSIONS_RESET';
export const resetPermissionAutocomplete = () => ({ type: AUTOCOMPLETE_PERMISSIONS_RESET });

export const PARTNER_SEARCH_BY_IDS = 'CUSTOMER_PLATFORM/Customer/PARTNER_SEARCH_BY_IDS_SET';
export const PARTNER_SEARCH_BY_IDS_SUCCESS = 'CUSTOMER_PLATFORM/Customer/PARTNER_SEARCH_BY_IDS_SUCCESS';
export const PARTNER_SEARCH_BY_IDS_FAIL = 'CUSTOMER_PLATFORM/Customer/PARTNER_SEARCH_BY_IDS_FAIL';

export const searchPartnersByIds = partnerNumbers => async dispatch => {
  dispatch({ type: PARTNER_SEARCH_BY_IDS });
  try {
    const result = await dispatch(MasterDataService.customersByPartnerNumbers(partnerNumbers));

    return dispatch({
      type: PARTNER_SEARCH_BY_IDS_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: PARTNER_SEARCH_BY_IDS_FAIL,
      error,
    });
  }
};

export const GET_PORTFOLIO_COUNTS = 'CUSTOMER_PLAYFORM/Customer/GET_PORTFOLIO_COUNTS';
export const GET_PORTFOLIO_COUNTS_SUCCESS = 'CUSTOMER_PLAYFORM/Customer/GET_PORTFOLIO_COUNTS_SUCCESS';
export const GET_PORTFOLIO_COUNTS_FAIL = 'CUSTOMER_PLAYFORM/Customer/GET_PORTFOLIO_COUNTS_FAIL';

export const getPortfolioCounts = partnerNumbers => async dispatch => {
  if (!partnerNumbers.length) {
    return;
  }

  dispatch({ type: GET_PORTFOLIO_COUNTS });
  try {
    const result = await MasterDataService.functionalLocationCountsByPartnerNumbers(partnerNumbers);

    return dispatch({
      type: GET_PORTFOLIO_COUNTS_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: GET_PORTFOLIO_COUNTS_FAIL,
      error,
    });
  }
};

export const LOAD_CUSTOMERS = 'CUSTOMER_PLATFORM/Customer/LOAD_CUSTOMERS';
export const LOAD_CUSTOMERS_SUCCESS = 'CUSTOMER_PLATFORM/Customer/LOAD_CUSTOMERS_SUCCESS';
export const LOAD_CUSTOMERS_FAIL = 'CUSTOMER_PLATFORM/Customer/LOAD_CUSTOMERS_FAIL';

export const loadCustomers = partnerNumbers => {
  return async dispatch => {
    dispatch({ type: LOAD_CUSTOMERS });
    try {
      const result = await dispatch(MasterDataService.customersByPartnerNumbers(partnerNumbers));

      dispatch({
        type: LOAD_CUSTOMERS_SUCCESS,
        result,
      });
      return result;
    } catch (error) {
      dispatch({
        type: LOAD_CUSTOMERS_FAIL,
        error,
      });
      throw error;
    }
  };
};

export const LOAD_PERMISSIONS_HIERARCHY = 'CUSTOMER_PLATFORM/Customer/LOAD_PERMISSIONS_HIERARCHY';
export const LOAD_PERMISSIONS_HIERARCHY_SUCCESS = 'CUSTOMER_PLATFORM/Customer/LOAD_PERMISSIONS_HIERARCHY_SUCCESS';
export const LOAD_PERMISSIONS_HIERARCHY_FAIL = 'CUSTOMER_PLATFORM/Customer/LOAD_PERMISSIONS_HIERARCHY_FAIL';

export const loadPermissionsHierarchy = functionalLocations => async dispatch => {
  dispatch({ type: LOAD_PERMISSIONS_HIERARCHY });
  try {
    const result = await MasterDataService.adminFunctionalLocations(functionalLocations);

    dispatch({
      type: LOAD_PERMISSIONS_HIERARCHY_SUCCESS,
      result,
    });
    return result;
  } catch (error) {
    dispatch({
      type: LOAD_PERMISSIONS_HIERARCHY_FAIL,
      error,
    });
  }
};

export const SET_SELECTED_CUSTOMER_ID = 'CUSTOMER_PLATFORM/Customer/SET_SELECTED_CUSTOMER_ID';
export const setSelectedCustomerId = customerId => ({
  type: SET_SELECTED_CUSTOMER_ID,
  customerId,
});

export const getSelectedCustomerId = state => state.customer.selectedCustomerId;
export const getFLResults = state => state.customer.functionalLocationSearchResults;
export const getFLResultsByCustomer = createSelector(
  getFLResults,
  getSelectedCustomerId,
  (functionalLocations, customerId) => {
    if (customerId) {
      // Do not expand to all possible customers when a specific customer is selected
      return {
        count: functionalLocations.length,
        results: [
          {
            customerId,
            functionalLocations,
          },
        ],
      };
    }

    const expanded = functionalLocations.flatMap(functionalLocation =>
      functionalLocation.partnerNumberWithParents.map(partnerNumber => ({
        ...functionalLocation,
        group: partnerNumber,
      }))
    );

    return {
      count: expanded.length,
      results: Object.entries(groupBy(expanded, 'group')).map(([customerId, results]) => ({
        customerId,
        functionalLocations: results,
      })),
    };
  }
);

export default createReducerFromMapping(
  {
    [SEARCH]: (state, action) => ({
      ...state,

      // Update partner search to include selected partner IDs. This is done
      // to support pagination so that we can re-invoke the search request when
      // a page changes.
      partnerSearch: {
        ...state.partnerSearch,
        ids: action.partners,
      },
    }),
    [SEARCH_SUCCESS]: (state, action) => ({
      ...state,
      functionalLocationSearchResults: action.result.map(functionalLocation => ({
        ...functionalLocation,
      })),
      totalResults: action.result.length > 0 ? action.result[0].totalMatches : 0,
    }),
    [SEARCH_FAIL]: (state, action) => ({
      ...state,
      functionalLocationSearchResults: [],
      error: action.error,
    }),

    [RESET_SEARCH]: (state, action) => ({
      ...state,
      functionalLocationSearchResults: [],

      // Reset partners also, but leave options if requested.
      partnerSearch: action.leaveOptions ? { options: state.partnerSearch.options } : {},
    }),

    [CHANGE_TYPES]: (state, action) => ({
      ...state,
      typesToSearch: action.typesToSearch,
    }),

    [PARTNER_SEARCH_BY_IDS]: (state, action) => ({
      ...state,

      // Reset old functionalLocation results
      functionalLocationSearchResults: [],
    }),
    [PARTNER_SEARCH_BY_IDS_SUCCESS]: (state, action) => ({
      ...state,

      // Put new options once we get the results.
      partnerSearch: {
        ...state.partnerSearch,
        options: action.result.map(x => ({ value: x.partnerNumber, label: `${x.name} (${x.partnerNumber})` })),
      },
    }),
    [PARTNER_SEARCH_BY_IDS_FAIL]: (state, action) => ({
      ...state,

      // Clear options on error.
      partnerSearch: {
        ...state.partnerSearch,
        options: [],
      },
    }),

    [GET_PORTFOLIO_COUNTS_SUCCESS]: (state, action) => ({
      ...state,
      portfolioCounts: {
        ...state.portfolioCounts,
        ...action.result,
      },
    }),

    [LOAD_CUSTOMERS]: (state, action) => ({
      ...state,
    }),
    [LOAD_CUSTOMERS_SUCCESS]: (state, action) => ({
      ...state,
      customers:
        action.result.length > 0 ? { ...state.customers, ...keyBy(action.result, 'partnerNumber') } : state.customers,
    }),
    [LOAD_CUSTOMERS_FAIL]: (state, action) => ({
      ...state,
      customers: action.error,
    }),

    [LOAD_PERMISSIONS_HIERARCHY]: (state, action) => ({
      ...state,
    }),
    [LOAD_PERMISSIONS_HIERARCHY_SUCCESS]: (state, action) => ({
      ...state,
      functionalLocations:
        action.result.length > 0
          ? { ...state.functionalLocations, ...keyBy(action.result, 'functionalLocation') }
          : state.functionalLocations,
    }),
    [LOAD_PERMISSIONS_HIERARCHY_FAIL]: (state, action) => ({
      ...state,
    }),
    [AUTOCOMPLETE_PERMISSIONS]: (state, action) => ({
      ...state,
      autocomplete: {
        ...state.autocomplete,
        timestamp: action.timestamp,
        loading: true,
      },
    }),
    [AUTOCOMPLETE_PERMISSIONS_SUCCESS]: (state, action) =>
      action.timestamp < state.autocomplete.timestamp
        ? state
        : {
            ...state,
            autocomplete: {
              ...state.autocomplete,
              timestamp: action.timestamp,
              loading: false,
              result: action.payload,
            },
          },
    [AUTOCOMPLETE_PERMISSIONS_FAIL]: (state, action) =>
      action.timestamp < state.autocomplete.timestamp
        ? state
        : {
            ...state,
            autocomplete: {
              ...state.autocomplete,
              timestamp: action.timestamp,
              loading: false,
              result: undefined,
              error: action.error,
            },
          },
    [AUTOCOMPLETE_PERMISSIONS_RESET]: state => ({
      ...state,
      autocomplete: {
        ...initialState.autocomplete,
      },
    }),
    [SET_SELECTED_CUSTOMER_ID]: (state, action) => ({
      ...state,
      selectedCustomerId: action.customerId,
    }),
  },
  initialState
);
