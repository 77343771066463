import PropTypes from 'prop-types';
import PrimaryButton from 'components/Button/PrimaryButton';

const SendAssignmentButton = ({ t, ...buttonProps }) => (
  <PrimaryButton large confirm {...buttonProps}>
    {t('Send assignment')}
  </PrimaryButton>
);

SendAssignmentButton.propTypes = {
  t: PropTypes.func.isRequired,
};

export default SendAssignmentButton;
