import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from 'decorators/Translations/translations';
import ConfirmationModal from './ConfirmationModal';

/**
 * Make sure you wrap `onConfirm` and `onDismiss` with React.useCallback to prevent unnecessary modal flickering!
 * On the contrary, `message` changes will not cause re-rendering and you may use a function or string
 */
const useConfirmationModal = ({
  message,
  additionalInfo,
  onConfirm,
  onDismiss,
  waitForSuccessfulCompletion,
  confirmButtonType,
}) => {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);
  const [confirmArgs, setConfirmArgs] = useState();
  const [t] = useTranslations();

  const ConfirmModal = ({ children }) => {
    if (!isOpen) {
      return null;
    }
    const messageText = typeof message === 'function' ? message(...confirmArgs) : message;
    const additionalInfoText = typeof additionalInfo === 'function' ? additionalInfo(...confirmArgs) : additionalInfo;
    return (
      <ConfirmationModal
        onDismiss={() => onDismiss?.(...confirmArgs)}
        onConfirm={() => onConfirm?.(...confirmArgs)}
        message={messageText}
        additionalInfoText={additionalInfoText}
        closeModal={toggleOpen}
        waitForSuccessfulCompletion={waitForSuccessfulCompletion}
        t={t}
        confirmButtonType={confirmButtonType}
      >
        {children}
      </ConfirmationModal>
    );
  };

  ConfirmModal.propTypes = {
    children: PropTypes.node,
  };

  const onClickOpen = useCallback((...args) => {
    setConfirmArgs(args);
    setOpen(true);
  }, []);

  return [ConfirmModal, onClickOpen, isOpen];
};

export default useConfirmationModal;
