import { Component } from 'react';
import translations from 'decorators/Translations/translations';

import styled, { withTheme } from 'styled-components';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import transform from 'lodash/transform';
import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import every from 'lodash/every';
import groupBy from 'lodash/groupBy';
import sum from 'lodash/sum';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { rgba } from 'polished';
import Hero from 'components/Hero/Hero';
import StandardPage from 'components/StandardPage/StandardPage';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import Header from 'containers/Application/Header/Header';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import withQuery from 'decorators/Query/withQuery';
import Benchmarking from 'components/Modules/EnergyModule/Benchmarking/Benchmarking';
import TabContent from 'components/TabContent/TabContent';
import {
  generateFilterInputs,
  getFLsByFilterMatchedBuildingMeta,
  extendEnergyRatingObjectsForBenchMarking,
  hasPartnerChanged,
  hasFunctionalLocationsChanged,
  hasFilters,
  calculateRenewableEnergyByFl,
  hasRenewableEnergy,
  consumptionValueFormatter,
  calculateCo2EmissionByFl,
  hasCo2Emissions,
  convertCo2ToTonnes,
  getChangedFilterValue,
  ENERGY_CONSUMPTION_SENSOR_TYPES,
} from './EnergyUtil';
import EnergyOpiCards from './EnergyOpiCards';
import { EnergySkeleton } from './EnergySkeleton';
import { isValidPartner } from 'utils/Data/partners';
import { getPartnerNumbers } from 'utils/profile';
import EnergyFilter from './EnergyFilter/EnergyFilter';
import connector from './connectEnergy';
import ButtonDropdown from 'components/Button/ButtonDropdown';
import ExportData from 'components/ExportData/ExportData';
import SnackBar from 'components/SnackBar/SnackBar';
import PrimaryButton from 'components/Button/PrimaryButton';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import { isEnergySensor } from 'utils/Data/values';
import { ButtonDropdownWrapper } from 'containers/Application/Recycling/RecyclingBenchmark/RecyclingBenchmark';
import { onClickPrint } from 'utils/Print/print';
import { DATE_RANGES } from 'components/Form/DateTools';
import { GRANULARITY } from 'components/ExportData/ExportSensorDataForm';
import Content from 'components/Content/Content';

const StyledMessage = styled.p`
  width: 100%;
  text-align: center;
  margin-top: var(--size-xl);
`;

const TAB_TYPES = {
  OBSERVATIONS: 'observations',
  SAVINGS_POTENTIAL: 'savings-potential',
  ENERGY_RATING: 'energy-rating',
  RENEWABLE_ENERGY: 'renewable-energy',
  ENERGY_CONSUMPTION: 'energy-consumption',
  CO2_EMISSIONS: 'co2-emissions',
};

export class Energy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      energyRatingValues: [],
      valuesByType: {},
      selectedFilterValues: {},
      toggleFilter: false,
      filtersOn: false,
      filterableFLs: [],
      filterMatchDataFound: true,
      observations: props.notices?.observations ?? [],
      showExportModal: false,
      notification: {
        visible: false,
      },
    };
  }

  handleFilterChange = (property, value, originalValue) => {
    this.setState(
      {
        selectedFilterValues: getChangedFilterValue(this.state.selectedFilterValues, property, value, originalValue),
      },
      this.onFilterSubmit
    );
  };

  onClickFilter = () => this.setState({ toggleFilter: !this.state.toggleFilter });

  resetFilter = () => this.setState({ selectedFilterValues: {}, filterableFLs: [] });

  onFilterSubmit = () => {
    const {
      buildingMeta: { meta },
      energyRatingValuesByFL,
      notices,
      consumptionKPIs,
    } = this.props;
    const { selectedFilterValues } = this.state;

    const filteredFLs = uniq(getFLsByFilterMatchedBuildingMeta(meta, selectedFilterValues));
    let consumptionValues;
    let energyRatingValues;
    let observations;

    // if selected filter is empty, show all data
    if (isEmpty(selectedFilterValues) || every(selectedFilterValues, isEmpty)) {
      energyRatingValues = this.transformEnergyRatingValues(energyRatingValuesByFL);
      consumptionValues = this.transformConsumptionValues(consumptionKPIs);
      observations = notices.observations.concat([]);
    }
    // if filtered FLs are empty, return and show "no data"
    else if (isEmpty(filteredFLs)) {
      return this.setState({
        filterMatchDataFound: false,
        filterableFLs: [],
        energyRatingValues: [],
        observations: [],
        consumptionValues: [],
      });
    }
    // show data for filtered FLs
    else {
      energyRatingValues = this.transformEnergyRatingValues(
        this.filterEnergyRatingValues(energyRatingValuesByFL, filteredFLs)
      );
      consumptionValues = this.transformConsumptionValues(consumptionKPIs, filteredFLs);
      observations = notices.observations?.filter(item => filteredFLs.includes(item.functionalLocation));
    }
    this.setState({
      energyRatingValues,
      consumptionValues,
      filterMatchDataFound: true,
      filterableFLs: filteredFLs,
      observations,
    });
  };

  filterEnergyRatingValues = (values, flFilter) => {
    return pickBy(values, (value, key) => includes(flFilter, key));
  };

  transformEnergyRatingValues = energyRatingValues => {
    return transform(
      energyRatingValues,
      (result, value, key) => {
        if (key !== 'averages' && value && value.latest) {
          result.push({
            ...value,
            functionalLocation: key,
            unit: 'kWh/m²',
          });
        }
      },
      []
    );
  };

  transformConsumptionValues = (consumptionKPIs, filterFls) => {
    const consumptionByFl = ENERGY_CONSUMPTION_SENSOR_TYPES.reduce((resultsByFl, energyType) => {
      Object.keys(consumptionKPIs[energyType]?.data?.byFL || {}).forEach(fl => {
        if (isEmpty(filterFls) || filterFls.includes(fl)) {
          const value = consumptionKPIs[energyType].data.byFL[fl];
          if (value) {
            resultsByFl[fl] = (resultsByFl[fl] || 0) + parseFloat(value);
          }
        }
      });
      return resultsByFl;
    }, {});
    return Object.keys(consumptionByFl).map(functionalLocation => ({
      functionalLocation,
      latest: consumptionByFl[functionalLocation],
    }));
  };

  setEnergyValuesToState = values => {
    const energyRatingValues = this.transformEnergyRatingValues(values);
    this.setState({ energyRatingValues });
  };

  setConsumptionValuesToState = consumptionKPIs => {
    const consumptionValues = this.transformConsumptionValues(consumptionKPIs);
    this.setState({ consumptionValues });
  };

  resetStateEnergyValues = () => {
    this.setState({ energyRatingValues: [] });
  };

  generateFilterInputsFromBuildingMeta = () => {
    const { t, buildingMeta, functionalLocations } = this.props;
    const buildingMetaByFL = pick(buildingMeta.meta, Object.keys(functionalLocations));
    return generateFilterInputs(buildingMetaByFL, t);
  };

  get opiCards() {
    const {
      energyRatingValuesByFL,
      match: {
        params: { partnerNumber },
      },
      consumptionKPIs,
      functionalLocations,
    } = this.props;
    const { filterableFLs, observations } = this.state;
    const filter = this.generateFilterInputsFromBuildingMeta();
    return (
      <EnergyOpiCards
        energyRatingValuesByFL={energyRatingValuesByFL}
        filterableFLs={filterableFLs}
        observations={observations}
        partnerNumber={partnerNumber}
        consumptionKPIs={consumptionKPIs}
        hasFilters={hasFilters(filter)}
        functionalLocations={functionalLocations}
      />
    );
  }

  get energyRatingBenchmark() {
    const { energyRatingValues } = this.state;
    const {
      t,
      functionalLocations,
      features: { energyTab },
      match: {
        params: { partnerNumber },
      },
    } = this.props;

    if (!isEmpty(energyRatingValues)) {
      const benchmarkValues = cloneDeep(energyRatingValues);
      extendEnergyRatingObjectsForBenchMarking(benchmarkValues, functionalLocations, t);
      return (
        <Benchmarking
          performers={benchmarkValues}
          t={t}
          energyTabEnabled={isBoolean(energyTab) ? energyTab : false}
          partnerNumber={partnerNumber}
        />
      );
    }
    return null;
  }

  get energyConsumptionBenchmark() {
    const { consumptionValues } = this.state;
    const {
      t,
      functionalLocations,
      features: { energyTab },
      match: {
        params: { partnerNumber },
      },
    } = this.props;

    if (!isEmpty(consumptionValues)) {
      const benchmarkValues = cloneDeep(consumptionValues);
      extendEnergyRatingObjectsForBenchMarking(benchmarkValues, functionalLocations, t);
      return (
        <Benchmarking
          performers={benchmarkValues}
          t={t}
          energyTabEnabled={isBoolean(energyTab) ? energyTab : false}
          partnerNumber={partnerNumber}
          valueFormatter={consumptionValueFormatter}
          rightTitle="Highest consumption"
          leftTitle="Lowest consumption"
          reverse
        />
      );
    }
    return null;
  }

  get savingsPotentialBenchmark() {
    const {
      t,
      functionalLocations,
      features: { energyTab },
      match: {
        params: { partnerNumber },
      },
      theme,
    } = this.props;

    const { observations } = this.state;

    if (!isEmpty(observations)) {
      const groups = groupBy(
        observations,
        observation => observation.path.find(fl => fl.substr(2, 2) === 'BU') || observation.functionalLocation
      );
      const benchmarkValues = Object.keys(groups).map(key => {
        const group = groups[key];
        const fl = functionalLocations[key];
        return {
          title: fl ? fl.description : key,
          address: fl ? `${fl.address}, ${fl.postalCode} ${fl.city}` : t('No Address Found'),
          value: Math.round(sum(group.map(item => (item.savingsPotentialSum ?? 0) / 1000)) * -1),
          unit: 'MWh',
          functionalLocation: key,
        };
      });
      return (
        <Benchmarking
          performers={benchmarkValues}
          t={t}
          rightTitle="Biggest potential"
          leftTitle="Smallest potential"
          energyTabEnabled={isBoolean(energyTab) ? energyTab : false}
          partnerNumber={partnerNumber}
          reverse={true}
          resolveValueBackgroundColor={() => rgba(theme.colors.emerald, 0.2)}
          decimalPrecision={0}
        />
      );
    }
    return null;
  }

  get observations() {
    const {
      t,
      functionalLocations,
      features: { energyTab },
      match: {
        params: { partnerNumber },
      },
    } = this.props;
    const { observations } = this.state;

    if (isEmpty(observations)) {
      return null;
    }

    const groups = groupBy(
      observations,
      observation => observation.path.find(fl => fl.substr(2, 2) === 'BU') || observation.functionalLocation
    );
    const benchmarkValues = Object.keys(groups).map(key => {
      const group = groups[key];
      const fl = functionalLocations[key];
      return {
        title: fl ? fl.description : key,
        address: fl ? `${fl.address}, ${fl.postalCode} ${fl.city}` : t('No Address Found'),
        value: group.length,
        functionalLocation: key,
      };
    });
    return (
      <Benchmarking
        performers={benchmarkValues}
        t={t}
        rightTitle="Most open observations"
        leftTitle="Least open observations"
        energyTabEnabled={isBoolean(energyTab) ? energyTab : false}
        partnerNumber={partnerNumber}
        decimalPrecision={0}
        reverse={true}
      />
    );
  }

  get renewableEnergy() {
    const {
      t,
      functionalLocations,
      consumptionKPIs,
      match: {
        params: { partnerNumber },
      },
    } = this.props;

    const renewableEnergyByFl = calculateRenewableEnergyByFl(consumptionKPIs);
    const benchmarkValues = Object.keys(renewableEnergyByFl).map(functionalLocation => {
      const fl = functionalLocations[functionalLocation];
      return {
        title: fl ? fl.description : functionalLocation,
        address: fl ? `${fl.address}, ${fl.postalCode} ${fl.city}` : t('No Address Found'),
        value: renewableEnergyByFl[functionalLocation].percentage,
        unit: '%',
        functionalLocation,
      };
    });
    return (
      <Benchmarking
        performers={benchmarkValues}
        t={t}
        rightTitle="Highest share of renewable energy"
        leftTitle="Lowest share of renewable energy"
        energyTabEnabled={false}
        partnerNumber={partnerNumber}
        reverse={true}
        decimalPrecision={0}
      />
    );
  }

  get co2Emissions() {
    const {
      t,
      functionalLocations,
      consumptionKPIs,
      match: {
        params: { partnerNumber },
      },
    } = this.props;

    const co2EmissionsByFl = calculateCo2EmissionByFl(consumptionKPIs);
    const benchmarkValues = Object.keys(co2EmissionsByFl).map(functionalLocation => {
      const fl = functionalLocations[functionalLocation];
      return {
        title: fl ? fl.description : functionalLocation,
        address: fl ? `${fl.address}, ${fl.postalCode} ${fl.city}` : t('No Address Found'),
        value: convertCo2ToTonnes(co2EmissionsByFl[functionalLocation].co2Emissions),
        unit: `CO₂ ${t('tonnes')}`,
        functionalLocation,
      };
    });
    return (
      <Benchmarking
        performers={benchmarkValues}
        t={t}
        rightTitle="Highest emissions"
        leftTitle="Lowest emissions"
        energyTabEnabled={false}
        partnerNumber={partnerNumber}
        reverse={true}
        decimalPrecision={0}
      />
    );
  }

  get customerName() {
    const {
      customers,
      match: {
        params: { partnerNumber },
      },
    } = this.props;
    return customers[partnerNumber] && customers[partnerNumber].name;
  }

  get energyFilter() {
    const {
      t,
      loading,
      match: {
        params: { partnerNumber },
      },
    } = this.props;
    const { toggleFilter, selectedFilterValues, filterMatchDataFound, energyRatingValues } = this.state;
    const noData = !loading && isEmpty(selectedFilterValues) && !filterMatchDataFound && isEmpty(energyRatingValues);
    const filter = this.generateFilterInputsFromBuildingMeta();

    if (!isValidPartner(partnerNumber) || noData || !hasFilters(filter) || loading) {
      return null;
    }

    return (
      <EnergyFilter
        t={t}
        filtersOpen={toggleFilter}
        filter={filter}
        toggleFilterClick={this.onClickFilter}
        selectedFilterValues={selectedFilterValues}
        loading={loading}
        handleFilterChange={this.handleFilterChange}
      />
    );
  }

  get tabs() {
    const tabs = [];
    const { notices, consumptionKPIs, t } = this.props;
    const { energyRatingValues, consumptionValues } = this.state;
    if (!isEmpty(consumptionValues)) {
      tabs.push({
        heading: t('Energy consumption'),
        type: TAB_TYPES.ENERGY_CONSUMPTION,
      });
    }
    if (!isEmpty(energyRatingValues)) {
      tabs.push({
        heading: t('Energy Rating'),
        type: TAB_TYPES.ENERGY_RATING,
      });
    }
    if (notices.totalSavingsPotential * -1 > 0) {
      tabs.push({
        heading: t('Savings Potential'),
        type: TAB_TYPES.SAVINGS_POTENTIAL,
      });
    }
    if (notices.observations?.length > 0) {
      tabs.push({
        heading: t('Observations'),
        type: TAB_TYPES.OBSERVATIONS,
      });
    }
    if (hasRenewableEnergy(consumptionKPIs)) {
      tabs.push({
        heading: t('Renewable energy'),
        type: TAB_TYPES.RENEWABLE_ENERGY,
      });
    }
    if (hasCo2Emissions(consumptionKPIs)) {
      tabs.push({
        heading: t(`CO₂ ${t('Emissions')}`),
        type: TAB_TYPES.CO2_EMISSIONS,
      });
    }
    return tabs;
  }

  handleTabChange = index => this.props.setQuery({ tab: this.tabs[index].type });

  get selectedTabIndex() {
    const { tab: type } = this.props.query;
    const selectedTabIndex = this.tabs.findIndex(tab => tab.type === type);
    if (selectedTabIndex === -1) {
      return 0;
    }
    return selectedTabIndex;
  }

  showNotification = (message, type) => {
    this.setState({
      notification: {
        visible: true,
        message,
        type,
        showCloseButton: true,
      },
    });
  };

  hideNotification = () => {
    this.setState({
      notification: {
        visible: false,
      },
    });
  };

  get energyContent() {
    const { t } = this.props;
    const options = this.tabs.map((tab, index) => ({ value: index, label: tab.heading }));

    if (options.length === 0) {
      return null;
    }
    const selectedTabType = this.tabs[this.selectedTabIndex]?.type;
    return (
      <Section>
        <SectionHeader>
          <SectionTabSelector
            options={options}
            model={{ tabSelected: this.selectedTabIndex }}
            property="tabSelected"
            onTabChange={(property, value) => this.handleTabChange(value)}
          />
          <ButtonDropdownWrapper>
            <ButtonDropdown
              secondary
              buttonLabel={t('Export')}
              buttonProps={{ iconName: 'export' }}
              alignment="right"
              items={[
                { label: t('CSV'), onClick: () => this.setState({ showExportModal: true }) },
                { label: t('PDF'), onClick: onClickPrint },
              ]}
            />
          </ButtonDropdownWrapper>
        </SectionHeader>
        <ScrollToComponent id="energy-benchmark" />
        {selectedTabType === TAB_TYPES.ENERGY_CONSUMPTION && this.energyConsumptionBenchmark}
        {selectedTabType === TAB_TYPES.ENERGY_RATING && this.energyRatingBenchmark}
        {selectedTabType === TAB_TYPES.SAVINGS_POTENTIAL && this.savingsPotentialBenchmark}
        {selectedTabType === TAB_TYPES.OBSERVATIONS && this.observations}
        {selectedTabType === TAB_TYPES.RENEWABLE_ENERGY && this.renewableEnergy}
        {selectedTabType === TAB_TYPES.CO2_EMISSIONS && this.co2Emissions}
        {this.state.showExportModal && (
          <ExportData
            onClose={() => this.setState({ showExportModal: false })}
            onSuccess={message => this.showNotification(message, 'success')}
            onError={message => this.showNotification(message, 'error')}
            sensorFilterFn={sensor => isEnergySensor(sensor.sensorType?.name)}
            defaultDateRange={DATE_RANGES.DAYS_365}
            defaultGranularity={GRANULARITY.monthly}
            showDateRangeAllOption={true}
          />
        )}
        <SnackBar
          variant={this.state.notification.type}
          visible={this.state.notification.visible}
          secondaryContent={
            this.state.notification.showCloseButton && (
              <PrimaryButton reveal invertColor transparentBorder large onClick={this.hideNotification}>
                {t('Close')}
              </PrimaryButton>
            )
          }
        >
          {this.state.notification.message}
        </SnackBar>
      </Section>
    );
  }

  get skeleton() {
    return <EnergySkeleton />;
  }

  get content() {
    return (
      <>
        {this.opiCards}
        {this.energyContent}
      </>
    );
  }

  get dataNotFound() {
    const { t } = this.props;
    return <StyledMessage>{t('No data available')}</StyledMessage>;
  }

  get renderCase() {
    const { loading, notices, consumptionKPIs, functionalLocations } = this.props;
    const { energyRatingValues, consumptionValues, filterMatchDataFound } = this.state;
    const noData =
      isEmpty(energyRatingValues) &&
      isEmpty(consumptionValues) &&
      isEmpty(notices?.observations) &&
      !hasRenewableEnergy(consumptionKPIs) &&
      !hasCo2Emissions(consumptionKPIs);
    if (loading) {
      return this.skeleton;
    }
    if ((!filterMatchDataFound || noData) && !isEmpty(functionalLocations)) {
      return this.dataNotFound;
    }
    return this.content;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { partnerNumber },
      },
      energyRatingValuesByFL,
      functionalLocations,
      loadFunctionalLocations,
      loadEnergyObservations,
      loadBuildingMeta,
      loadEnergyRatingValuesByFL,
      loadConsumptionKPI,
      loadBuildingLevelSensorHierarchiesByFLIds,
      notices,
      consumptionKPIs,
    } = this.props;
    if (hasFunctionalLocationsChanged(functionalLocations, prevProps)) {
      loadEnergyRatingValuesByFL(keys(functionalLocations));
      loadBuildingMeta(keys(functionalLocations));
      loadBuildingLevelSensorHierarchiesByFLIds(keys(functionalLocations));
    }
    if (hasPartnerChanged(prevProps, partnerNumber)) {
      loadFunctionalLocations(partnerNumber);
      loadEnergyObservations(partnerNumber);
      ENERGY_CONSUMPTION_SENSOR_TYPES.forEach(type => loadConsumptionKPI(partnerNumber, type));
      this.resetStateEnergyValues();
      this.resetFilter();
    }
    if (!isEqual(energyRatingValuesByFL, prevProps.energyRatingValuesByFL)) {
      this.setEnergyValuesToState(energyRatingValuesByFL);
    }
    if (!isEqual(consumptionKPIs, prevProps.consumptionKPIs)) {
      this.setConsumptionValuesToState(consumptionKPIs);
    }
    if (!isEqual(notices, prevProps.notices)) {
      this.setState({ observations: this.props.notices.observations ?? [] });
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { partnerNumber },
      },
      energyRatingValuesByFL,
      loadEnergyRatingValuesByFL,
      loadBuildingMeta,
      functionalLocations,
      loadFunctionalLocations,
      loadEnergyObservations,
      loadConsumptionKPI,
      consumptionKPIs,
    } = this.props;

    if (isEmpty(functionalLocations)) {
      loadFunctionalLocations(partnerNumber);
    } else {
      loadEnergyRatingValuesByFL(keys(functionalLocations));
      loadBuildingMeta(keys(functionalLocations));
    }
    loadEnergyObservations(partnerNumber);
    if (!isEmpty(energyRatingValuesByFL)) {
      this.setEnergyValuesToState(energyRatingValuesByFL);
    }
    if (!isEmpty(consumptionKPIs)) {
      this.setConsumptionValuesToState(consumptionKPIs);
    }
    ENERGY_CONSUMPTION_SENSOR_TYPES.forEach(type => loadConsumptionKPI(partnerNumber, type));
  }

  render() {
    const {
      t,
      match: {
        params: { partnerNumber },
      },
      profile,
    } = this.props;

    if (!partnerNumber || partnerNumber === 'all') {
      return <ErrorPage type="selectPartner" />;
    }
    if (!includes(getPartnerNumbers(profile), partnerNumber)) {
      return <ErrorPage type="partner" />;
    }

    return (
      <StandardPage withTabs disableScrollToTop>
        <Helmet title={t('Energy')} />
        <Header t={t} selected="energy" showPartnerSelect />
        <Hero title={t('Energy')} subTitle={this.customerName} t={t} type="ENERGY" />
        <TabContent>
          <Content>
            {this.energyFilter}
            {this.renderCase}
          </Content>
        </TabContent>
      </StandardPage>
    );
  }
}

Energy.defaultProps = {
  energyValuesByPartner: {},
  energyValues: {},
  energyRatingValuesByFL: {
    averages: {
      previous: null,
      latest: null,
    },
  },
};

Energy.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      partnerNumber: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  buildingMeta: PropTypes.shape({
    meta: PropTypes.object.isRequired,
  }).isRequired,
  functionalLocations: PropTypes.object.isRequired,
  customers: PropTypes.object.isRequired,
  loadBuildingMeta: PropTypes.func.isRequired,
  loadFunctionalLocations: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
  energyRatingValuesByFL: PropTypes.object,
  loadEnergyRatingValuesByFL: PropTypes.func.isRequired,
  consumptionKPIs: PropTypes.object.isRequired,
  loadConsumptionKPI: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  notices: PropTypes.object,
  theme: PropTypes.object,
  setQuery: PropTypes.func,
  loadEnergyObservations: PropTypes.func,
  query: PropTypes.object,
  loadBuildingLevelSensorHierarchiesByFLIds: PropTypes.func,
};

export default withQuery(withTheme(connector(translations(Energy))));
