import { ReactNode } from 'react';

import { GenericTextNotification } from 'components/Notification/GenericTextNotification';
import { COLOR_VARIANT } from 'components/Notification/notification.constants';
import { useTranslations } from 'decorators/Translations/translations';
import { useDebounce } from 'utils/General/useDebounce';
import { FlexGroup } from 'components/Layout/FlexGroup';
import Loader from 'components/Loader/Loader';

export type LoadingDataNotificationProps = {
  visible: boolean;
  text?: ReactNode;
};

/**
 * Displays a notification indicating that data is being fetched. A slight delay of 250 ms
 * makes sure that if the data is received fast (or found in cache), the notification will
 * not be unnecessarily flashing.
 *
 * Default text is "Loading data".
 */
export function LoadingDataNotification({ visible, text }: LoadingDataNotificationProps) {
  const [t] = useTranslations();
  text ??= t('Loading data');

  const showNotification = useDebounce(visible, 250);

  const content = (
    <>
      <FlexGroup row style={{ alignItems: 'center' }}>
        <div>{text}</div>
        <Loader color="WHITE" size="SMALL" />
      </FlexGroup>
    </>
  );

  return <GenericTextNotification visible={showNotification} variant={COLOR_VARIANT.Black} text={content} />;
}
