import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import isObjectLike from 'lodash/isObjectLike';
import { useTheme } from 'styled-components';
import { useTranslations } from 'decorators/Translations/translations';
import { SelectInputV5 } from 'components/Select/SelectInputV5';
import oldSelectInputStyles from './oldSelectInputStyles';

const SELECT_ALL_VALUE = '*.*';

const selectAllLabel = t => ({ label: t('Select all'), value: SELECT_ALL_VALUE });
const isNotUndefined = value => value !== undefined;
const newValue = (resetValue, selectedOption) => {
  if (isNotUndefined(selectedOption?.value)) {
    return selectedOption.value;
  }
  return isNotUndefined(resetValue) ? resetValue : '';
};
const getSelectedValue = (value, options) =>
  isNil(value)
    ? ''
    : find(options, [
        'value',
        isObjectLike(value) && !isObjectLike(options?.[0]?.value) ? Object.keys(value)[0] : value,
      ]) || value;

/**
 * An older implementation of a dropdown selector using value and options conversion for backwards compatibility.
 *
 * @deprecated Do not use in new components.
 * This is a single select dropdown, use {@link MultiSelectDropdown} if you need multiselect with old styles.
 */
export const InputSelectDropdown = props => {
  const { onChange, property, resetValue, value: inputValue, options: inputOptions, model } = props;
  const [t] = useTranslations();
  const theme = useTheme();
  const styles = useMemo(() => oldSelectInputStyles(theme), [theme]);

  const handleChange = useCallback(
    selectedOption => onChange(property, newValue(resetValue, selectedOption)),
    [onChange, property, resetValue]
  );

  const noOptionsMessage = useCallback(() => props.noResultsText || t('No results found'), [props.noResultsText, t]);

  const value = useMemo(
    () => getSelectedValue(inputValue || get(model, property), inputOptions),
    [inputValue, model, property, inputOptions]
  );

  const options = useMemo(
    () =>
      concat(
        props.showAll ? [selectAllLabel(t)] : [],
        filter(props.options, option => option !== value)
      ),
    [props.showAll, props.options, value, t]
  );

  return (
    <SelectInputV5
      styles={styles}
      className={props.className}
      name={props.id}
      value={value}
      required={props.required}
      isDisabled={props.disabled}
      isClearable={props.clearable}
      filterOption={props.filterOption}
      onInputChange={props.onInput}
      onChange={handleChange}
      options={options}
      placeholder={props.placeholder || `${t('Select')}...`}
      noOptionsMessage={noOptionsMessage}
      clearValueText={t('Clear value')}
      isLoading={props.loading}
      blurInputOnSelect={false}
      menuPortalTarget={props.menuPortalTarget}
      menuShouldScrollIntoView={props.scrollToMenu}
      menuPlacement={props.menuPlacement}
      label={props.label}
      highlightError={props.highlightError}
      optionRenderer={props.optionRenderer}
    />
  );
};
InputSelectDropdown.displayName = 'InputSelectDropdown';

InputSelectDropdown.defaultProps = {
  clearable: true,
};

InputSelectDropdown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.number]),
  model: PropTypes.object,
  options: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  filterOption: PropTypes.func,
  label: PropTypes.string,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  showAll: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noResultsText: PropTypes.string,
  loading: PropTypes.bool,
  menuPortalTarget: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.elementType })]),
  scrollToMenu: PropTypes.bool,
  menuPlacement: PropTypes.string,
  highlightError: PropTypes.bool,
  resetValue: PropTypes.any,
  optionRenderer: PropTypes.func,
};

export default InputSelectDropdown;
