import { Link } from 'react-router-dom';

import Svg from 'components/Svg/Svg';

import styled from 'styled-components';

export const direction = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const size = {
  NORMAL: 'normal',
  TINY: 'tiny',
};

export const LinkArrowWrapper = styled.div`
  height: 100%;
  font-size: 1rem;
  width: 2em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ArrowIcon = styled(({ tiny, fill, ...props }) => <Svg {...props} />)`
  fill: ${props => props.fill || 'var(--link-fg)'};
  font-size: ${props => (props.tiny ? 'var(--font-size-xxs)' : 'var(--font-size-sm)')};
`;

const mapDirection = {
  [direction.LEFT]: 'caret-left',
  [direction.RIGHT]: 'caret-right',
};

/* Link arrow to the given url and pointing to the given direction (from directions defined above) and of given size */
const LinkArrow = ({
  to,
  direction: myDirection,
  size: mySize = size.TINY,
  'data-test-id': dataTestId,
  fill,
  ...other
}) => {
  const arrow = mapDirection[myDirection],
    alt = 'Arrow ' + myDirection;
  if (!(myDirection in mapDirection)) {
    console.info('Direction "%s" not defined in LinkArrow', myDirection);
    return null;
  }
  return (
    <Link to={to} {...other}>
      <LinkArrowWrapper data-test-id={dataTestId}>
        <ArrowIcon tiny={mySize === size.TINY} name={arrow} alt={alt} fill={fill} />
      </LinkArrowWrapper>
    </Link>
  );
};

export default LinkArrow;
