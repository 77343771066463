import PropTypes from 'prop-types';

import AdditionalSaleHeader from './AdditionalSaleHeader';
import AdditionalSaleForm from './AdditionalSaleForm/AdditionalSaleForm';
import AdditionalSaleInfo from './AdditionalSaleInfo';
import { Container } from './common';
import { Columns, Column } from 'components/Columns/index';
import { additionalSalePropType } from './utils';
import Content from 'components/Content/Content';

const COLUMN_WIDTH = { desktop: 6, landscape: 6, portrait: 12, default: 12 };

const AdditionalSaleContent = ({ additionalSale, loading, onSubmitAction, onSubmitManagerChange }) => {
  return (
    <Container status={additionalSale?.status}>
      <Content>
        <AdditionalSaleHeader additionalSale={additionalSale} loading={loading} />
        <Columns noPadding>
          <Column columnWidth={COLUMN_WIDTH}>
            <AdditionalSaleInfo additionalSale={additionalSale} loading={loading} />
          </Column>
          <Column columnWidth={COLUMN_WIDTH}>
            <AdditionalSaleForm
              additionalSale={additionalSale}
              loading={loading}
              onSubmitAction={onSubmitAction}
              onSubmitManagerChange={onSubmitManagerChange}
            />
          </Column>
        </Columns>
      </Content>
    </Container>
  );
};

AdditionalSaleContent.propTypes = {
  additionalSale: additionalSalePropType,
  loading: PropTypes.bool,
  onSubmitAction: PropTypes.func.isRequired,
  onSubmitManagerChange: PropTypes.func.isRequired,
};

export default AdditionalSaleContent;
