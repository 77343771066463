import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import StandardPage from 'components/StandardPage/StandardPage';
import Helmet from 'react-helmet';
import Header from 'containers/Application/Header/Header';
import Hero from 'components/Hero/Hero';
import TabContent from 'components/TabContent/TabContent';
import RecyclingOPICards from 'containers/Application/Modules/RecyclingModule/RecyclingOPICards/RecyclingOPICards';
import RecyclingBenchmark from 'containers/Application/Recycling/RecyclingBenchmark/RecyclingBenchmark';
import ObservationsModal from 'components/Observations/ObservationsModal';
import translations from 'decorators/Translations/translations';
import connect from 'containers/Application/Recycling/connectRecycling';
import withQuery from 'decorators/Query/withQuery';
import styled from 'styled-components';
import portfolioLevelTabs from 'containers/Application/Recycling/tabs';
import { RECYCLING_BENCHMARK_ELEMENT_ID } from 'containers/Application/Recycling/RecyclingBenchmark/RecyclingBenchmark';
import { defaultFilterValues } from 'components/Observations/utils';
import Content from 'components/Content/Content';

const NoData = styled.div`
  text-align: center;
`;

export const Recycling = ({
  t,
  partnerSelected,
  partnerNumber,
  hasAccessRights,
  customers,
  loadData,
  noDataAvailable,
  observations,
  query,
  setQuery,
  features,
}) => {
  const [loading, setLoading] = useState(true);
  const [currentPartnerNumber, setCurrentPartnerNumber] = useState(null);
  const load = async partnerNumber => {
    setLoading(true);
    await loadData(partnerNumber);
    setLoading(false);
  };
  useEffect(() => {
    if (partnerNumber && hasAccessRights && partnerNumber !== currentPartnerNumber) {
      load(partnerNumber);
      setCurrentPartnerNumber(partnerNumber);
    }
  }, [partnerNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!partnerSelected) {
    return <ErrorPage type="selectPartner" />;
  }
  if (!hasAccessRights) {
    return <ErrorPage type="partner" />;
  }
  const pageTitle = t('Recycling');
  const opiTargetQueries = {
    recyclingRate: {
      benchmark: portfolioLevelTabs.RECYCLING_RATE.id,
      scrollTo: RECYCLING_BENCHMARK_ELEMENT_ID,
    },
    recoveryRate: {
      benchmark: portfolioLevelTabs.RECOVERY_RATE.id,
      scrollTo: RECYCLING_BENCHMARK_ELEMENT_ID,
    },
    carbonFootprint: {
      benchmark: portfolioLevelTabs.CARBON_FOOTPRINT.id,
      scrollTo: RECYCLING_BENCHMARK_ELEMENT_ID,
    },
  };
  return (
    <StandardPage withTabs scrollToTopOnMount>
      <Helmet title={pageTitle} />
      <Header t={t} selected="recycling" showPartnerSelect />
      <Hero
        title={pageTitle}
        subTitle={customers[partnerNumber] && customers[partnerNumber].name}
        t={t}
        type="RECYCLING"
      />
      <TabContent>
        {!loading && noDataAvailable ? (
          <NoData>{t('No data available')}</NoData>
        ) : (
          <Content>
            <RecyclingOPICards
              containerLoading={loading}
              targetQueries={opiTargetQueries}
              observations={observations}
            />
            <RecyclingBenchmark containerLoading={loading} features={features} />
            {query?.observationsModalVisible && (
              <ObservationsModal
                observations={observations.apiData}
                filter={{ ...defaultFilterValues, ...query }}
                onClose={() => setQuery({ ...defaultFilterValues, observationsModalVisible: undefined })}
              />
            )}
          </Content>
        )}
      </TabContent>
    </StandardPage>
  );
};

Recycling.propTypes = {
  t: PropTypes.func.isRequired,
  partnerSelected: PropTypes.bool,
  partnerNumber: PropTypes.string,
  hasAccessRights: PropTypes.bool,
  customers: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  noDataAvailable: PropTypes.bool,
  observations: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
  features: PropTypes.object,
};

export default withRouter(withQuery(connect(translations(Recycling))));
