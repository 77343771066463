import styled from 'styled-components';

const Button = styled.button`
  display: inline-block;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  padding: 0;

  @media not print {
    &:hover {
      cursor: pointer;
      background-color: var(--grey-30);
    }
  }

  svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin: auto;
  }
`;

const SimpleButtonIcon = ({ onClick, children }) => <Button onClick={onClick}>{children}</Button>;

export default SimpleButtonIcon;
