import styled from 'styled-components';
import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';

import { DATE_FORMATS, format } from 'utils/Date/dateFormatter';

export const DateStyles = styled.span`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.fontSize.xxs};
`;

export function formattedDate(isoDate) {
  return format(parseISO(isoDate), DATE_FORMATS.dateTimeShort);
}

export function FormattedDate({ isoDate }) {
  return <DateStyles>{formattedDate(isoDate)}</DateStyles>;
}

FormattedDate.propTypes = {
  isoDate: PropTypes.string.isRequired,
};
