import { useRef, useEffect, useState } from 'react';
import MasterDataService from 'services/masterData';

const usePublicView = id => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);
  const [view, setView] = useState();
  const timeoutRef = useRef();

  const load = () => {
    setLoading(true);
    setError(null);
    MasterDataService.publicView(id)
      .then(view => setView(view))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (id) {
      load();
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error && error.statusCode !== 400 && error.statusCode !== 404 && error.statusCode !== 403) {
      timeoutRef.current = setTimeout(load, 1000 * 60);
    }
    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  return [view, loading, error];
};

export default usePublicView;
