import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectCreatable from 'react-select/creatable';
import SelectAsyncCreatable from 'react-select/async-creatable';
import { useTheme } from 'styled-components';
import oldSelectInputStyles from 'components/Form/oldSelectInputStyles';
import V5Styles from 'components/Select/V5Styles';
import V5Components from 'components/Select/V5Components';
import includes from 'lodash/includes';

const filterOption = (option, searchText) =>
  includes(option.data.label.toLowerCase(), searchText.toLowerCase()) ||
  includes(option.data.value.toLowerCase(), searchText.toLowerCase());

const SelectOrType = props => {
  const { t, options, onChange, onSearch, loading, highlightError, value } = props;
  const noOptionsMessage = useCallback(() => t('Type and press enter'), [t]);
  const theme = useTheme();
  const styles = useMemo(() => V5Styles(theme, oldSelectInputStyles(theme)), [theme]);
  const components = useMemo(() => V5Components(), []);

  if (typeof onSearch === 'function') {
    return (
      <SelectAsyncCreatable
        value={null}
        defaultOptions
        styles={styles}
        components={components}
        loadOptions={onSearch}
        onChange={onChange}
        placeholder={t(loading ? 'Loading...' : 'Select or type')}
        isLoading={loading}
        noOptionsMessage={noOptionsMessage}
        filterOption={filterOption}
      />
    );
  }

  return (
    <SelectCreatable
      styles={styles}
      components={components}
      options={options}
      onChange={onChange}
      placeholder={t('Select or type')}
      isLoading={loading}
      highlightError={highlightError}
      value={value}
      noOptionsMessage={noOptionsMessage}
      filterOption={filterOption}
    />
  );
};

SelectOrType.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  highlightError: PropTypes.bool,
  value: PropTypes.object, // Only provide the value if you want it to be shown in the select text input
};

export default SelectOrType;
