import styled from 'styled-components';
import PropTypes from 'prop-types';
import SecondaryButton from './SecondaryButton/SecondaryButton';
import { IconContainer } from './ButtonIcon/ButtonIcon';

const ButtonWrapper = styled.span`
  display: inline-block;
  && ${IconContainer} {
    margin-right: 0;
    ${props =>
      props.hasChild &&
      props.theme.media.landscape`
      margin-right: var(--size-xs);
    `}
  }

  && button {
    padding: 0 var(--size-sm);
    ${props =>
      props.hasChild &&
      props.theme.media.landscape`
      padding: 0 20px;
    `}
  }
`;

const ButtonContent = styled.span`
  display: ${props => (props.hasIcon ? 'none' : 'inline-block')};
  ${props => props.theme.media.landscape`
    display: inline-block;
  `}
`;

const ResponsiveButton = ({ ButtonComponent = SecondaryButton, iconName, children, ...buttonProps }) => (
  <ButtonWrapper hasChild={Boolean(children)}>
    <ButtonComponent iconName={iconName} {...buttonProps}>
      <ButtonContent hasIcon={Boolean(iconName)}>{children}</ButtonContent>
    </ButtonComponent>
  </ButtonWrapper>
);
ResponsiveButton.displayName = 'ResponsiveButton';

ResponsiveButton.propTypes = {
  ButtonComponent: PropTypes.elementType,
  iconName: PropTypes.string,
  children: PropTypes.node,
};

export default ResponsiveButton;
