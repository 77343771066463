import MasterDataService from 'services/masterData';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import isBefore from 'date-fns/isBefore';
import parse from 'date-fns/parse';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';

import { createReducerFromMapping } from 'redux/utils';
import { indexByDate } from 'redux/utils/indexing';
import { loadEquipmentTexts } from 'redux/modules/customer/equipments';
import { loadFunctionalLocations } from 'redux/modules';
import { getUniqueFunctionalLocations, getUniqueEquipment } from 'utils/Data/serviceOrders';
import {
  isMaintenanceModuleEnabled,
  isServiceOrdersEnabled,
  isPlannedMaintenanceEnabled,
} from 'utils/Data/profileData';
import { loadServiceRequestCount, loadServiceRequests } from './serviceRequests';
import { invalidateMostServiceOrderCaches } from 'services/masterData/serviceOrders';
import { OrderStatus } from 'constants/maintenance';

const initialState = {
  calendar: {
    loadingOrders: false,
    loadingFLs: false,
  },
  kpiCounts: {
    loading: false,
    serviceOrders: {
      completed: 0,
      total: 0,
    },
    plannedMaintenances: {
      completed: 0,
      total: 0,
    },
  },
  index: indexByDate.getInitialState(),
  objects: {},
  operations: {},
  loading: {},
  search: {},
  logs: {},
};

export const LOAD_SERVICE_ORDERS_TAB = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_SERVICE_ORDERS_TAB';
export const LOAD_SERVICE_ORDERS_TAB_ORDERS_SUCCESS =
  'CUSTOMER_PLATFORM/ServiceOrders/LOAD_SERVICE_ORDERS_TAB_ORDERS_SUCCESS';
export const LOAD_SERVICE_ORDERS_TAB_LOCATIONS_SUCCESS =
  'CUSTOMER_PLATFORM/ServiceOrders/LOAD_SERVICE_ORDERS_TAB_LOCATIONS_SUCCESS';
export const LOAD_SERVICE_ORDERS_TAB_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_SERVICE_ORDERS_TAB_FAIL';

export const LOAD = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_FAIL';

export const SEARCH_SET_TEXT = 'CUSTOMER_PLATFORM/ServiceOrders/SEARCH_SET_TEXT';
export const SEARCH = 'CUSTOMER_PLATFORM/ServiceOrders/SEARCH';
export const SEARCH_SUCCESS = 'CUSTOMER_PLATFORM/ServiceOrders/SEARCH_SUCCESS';
export const SEARCH_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/SEARCH_FAIL';

export const LOAD_OPERATIONS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_OPERATIONS';
export const LOAD_OPERATIONS_SUCCESS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_OPERATIONS_SUCCESS';
export const LOAD_OPERATIONS_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_OPERATIONS_FAIL';

export const LOAD_COUNTS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_COUNTS';
export const LOAD_COUNTS_SUCCESS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_COUNTS_SUCCESS';
export const LOAD_COUNTS_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_COUNTS_FAIL';

export const COMMENT_SERVICE_ORDER = 'CUSTOMER_PLATFORM/ServiceOrders/COMMENT_SERVICE_ORDER';
export const COMMENT_SERVICE_ORDER_SUCCESS = 'CUSTOMER_PLATFORM/ServiceOrders/COMMENT_SERVICE_ORDER_SUCCESS';
export const COMMENT_SERVICE_ORDER_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/COMMENT_SERVICE_ORDER_FAIL';

export const LOAD_LOGS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_LOGS';
export const LOAD_LOGS_SUCCESS = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_LOGS_SUCCESS';
export const LOAD_LOGS_FAIL = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_LOGS_FAIL';

export const DELETE_FROM_INDEX = 'CUSTOMER_PLATFORM/ServiceOrders/DELETE_FROM_INDEX';

export const verifyDates = (start, end) => {
  if (!isValid(start) || !isValid(end)) {
    throw new Error('Invalid start or end date: Must be a valid date instance');
  } else if (isBefore(end, start)) {
    throw new Error('End cannot be before start');
  }
};

export const loadServiceOrderTab =
  (year, partnerNumber, functionalLocationId, equipmentNumber, reload = false) =>
  async (dispatch, getState) => {
    dispatch({ type: LOAD_SERVICE_ORDERS_TAB });

    try {
      const start = startOfYear(parse(year, 'yyyy', new Date()));
      const end = endOfYear(start);
      const profile = getState().profile.profile;

      if (reload) {
        await invalidateMostServiceOrderCaches();
      }

      let serviceOrders;

      // load service orders for year

      if (functionalLocationId) {
        serviceOrders = (
          await dispatch(
            loadServiceOrders(partnerNumber, functionalLocationId, equipmentNumber, start, end, 'ServiceOrders')
          )
        ).result;
      } else {
        serviceOrders = (await dispatch(loadAllServiceOrders(start, end, partnerNumber, 'ServiceOrders'))).result;
      }

      let serviceRequests = [];

      // load service requests for year (if service module is enabled)
      if (isMaintenanceModuleEnabled(profile.features)) {
        serviceRequests = (
          await dispatch(
            loadServiceRequests({
              startDate: start,
              endDate: end,
              partnerNumber,
              functionalLocation: functionalLocationId,
              refreshCache: reload,
            })
          )
        ).result;

        // if reloading, load also service request counts
        if (reload) {
          // portfolio count:
          dispatch(loadServiceRequestCount({ partnerNumber, refreshCache: reload }));
          // functional location count:
          if (functionalLocationId) {
            dispatch(
              loadServiceRequestCount({ partnerNumber, functionalLocation: functionalLocationId, refreshCache: reload })
            );
          }
        }
      }

      dispatch({ type: LOAD_SERVICE_ORDERS_TAB_ORDERS_SUCCESS });

      // load functional locations and/or equipments for service orders
      const flAndEquipmentPromises = [];

      const uniqueFLs = getUniqueFunctionalLocations([...serviceOrders, ...serviceRequests]);
      const uniqueEquipment = getUniqueEquipment(serviceOrders);

      if (uniqueFLs.length > 0) {
        flAndEquipmentPromises.push(dispatch(loadFunctionalLocations(uniqueFLs)));
      }
      if (uniqueEquipment.length > 0) {
        flAndEquipmentPromises.push(dispatch(loadEquipmentTexts(uniqueEquipment)));
      }

      await Promise.all(flAndEquipmentPromises);

      dispatch({ type: LOAD_SERVICE_ORDERS_TAB_LOCATIONS_SUCCESS });
    } catch (error) {
      dispatch({ type: LOAD_SERVICE_ORDERS_TAB_FAIL });
    }
  };

export const loadServiceOrder =
  (serviceOrderNumber, partnerNumber, functionalLocation, key, refreshCache = false) =>
  async dispatch => {
    dispatch({ type: LOAD, key });
    try {
      const result = await dispatch(
        MasterDataService.serviceOrders({
          partnerNumber,
          functionalLocation,
          serviceOrderNumber,
          refreshCache,
        })
      );
      return dispatch({
        type: LOAD_SUCCESS,
        key,
        result,
        loadOne: true,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        key,
        error,
      });
    }
  };

export const loadServiceOrderForPlannedMaintenance = (plannedMaintenanceId, key) => async dispatch => {
  dispatch({ type: LOAD, key });
  try {
    const order = await MasterDataService.plannedMaintenanceServiceOrder(plannedMaintenanceId);
    return dispatch({
      type: LOAD_SUCCESS,
      key,
      result: [order],
      loadOne: true,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_FAIL,
      key,
      error,
    });
  }
};

export const loadServiceOrders = (
  partnerNumber,
  functionalLocation,
  equipmentNumber,
  startDate,
  endDate,
  key,
  refreshCache = false
) => {
  verifyDates(startDate, endDate);
  return async dispatch => {
    dispatch({ type: LOAD, key });
    try {
      const result = await dispatch(
        MasterDataService.serviceOrders({
          partnerNumber,
          functionalLocation,
          equipmentNumber,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          refreshCache,
        })
      );

      return dispatch({
        type: LOAD_SUCCESS,
        key,
        result,
      });
    } catch (error) {
      return dispatch({
        key,
        functionalLocation: functionalLocation.functionalLocation,
        type: LOAD_FAIL,
        error,
      });
    }
  };
};

export const exportServiceOrders = (
  partnerNumber,
  functionalLocation,
  equipmentNumber,
  startDate,
  endDate,
  filteredServiceOrderNumbers
) => {
  verifyDates(startDate, endDate);
  return MasterDataService.serviceOrdersExport({
    partnerNumber,
    functionalLocation,
    serviceOrderNumbers: filteredServiceOrderNumbers,
    equipmentNumber,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });
};

export const exportServiceOrdersForUser = (startDate, endDate, partnerNumber, filteredServiceOrderNumbers) => {
  verifyDates(startDate, endDate);
  return MasterDataService.serviceOrdersExportForUser(
    startDate,
    endDate,
    partnerNumber,
    undefined,
    filteredServiceOrderNumbers
  );
};

export const loadAllServiceOrders =
  (startDate, endDate, partnerNumber, key, refreshCache = false) =>
  async dispatch => {
    verifyDates(startDate, endDate);
    dispatch({ type: LOAD, key });
    try {
      const result = await dispatch(
        MasterDataService.serviceOrdersForUser({
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          partnerNumber: partnerNumber !== 'all' ? partnerNumber : undefined,
          refreshCache,
        })
      );

      return dispatch({
        type: LOAD_SUCCESS,
        key,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        key,
        error,
      });
    }
  };

export const setServiceOrderSearchText = (section, text) => ({
  type: SEARCH_SET_TEXT,
  section,
  text,
});

export const searchServiceOrdersAndPlanned =
  (section, text, partnerNumber, functionalLocation) => async (dispatch, getState) => {
    dispatch({ type: SEARCH, section });
    try {
      const profile = getState().profile.profile;
      const searchRequest = { keyword: text, partnerNumber, functionalLocation, limit: 1000 };

      const [serviceOrders, plannedMaintenances] = await Promise.all([
        isServiceOrdersEnabled(profile.features)
          ? MasterDataService.serviceOrdersSearch(searchRequest)
          : Promise.resolve([]),
        isPlannedMaintenanceEnabled(profile.features)
          ? MasterDataService.plannedMaintenancesSearch(searchRequest)
          : Promise.resolve([]),
      ]);

      const items = [...serviceOrders, ...plannedMaintenances].slice(0, 1000);

      return dispatch({
        type: SEARCH_SUCCESS,
        section,
        result: { items, total: items.length },
      });
    } catch (error) {
      return dispatch({
        type: SEARCH_FAIL,
        section,
        error,
      });
    }
  };

export const loadCounts = (startDate, endDate, functionalLocation, partnerNumber) => {
  verifyDates(startDate, endDate);
  return async dispatch => {
    dispatch({ type: LOAD_COUNTS });
    try {
      const countRequest = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        partnerNumber: partnerNumber !== 'all' ? partnerNumber : undefined,
        functionalLocation: functionalLocation ? functionalLocation.functionalLocation : undefined,
      };
      const [serviceOrderCompleted, serviceOrderTotal, plannedMaintenances] = await Promise.all([
        MasterDataService.serviceOrdersCounts({ ...countRequest, status: [OrderStatus.COMPLETED] }),
        MasterDataService.serviceOrdersCounts({
          ...countRequest,
          excludeStatus: [OrderStatus.CANCELLED, OrderStatus.DRAFT, OrderStatus.REQUEST],
        }),
        MasterDataService.plannedMaintenancesCounts(countRequest),
      ]);
      const serviceOrders = { completed: serviceOrderCompleted.count, total: serviceOrderTotal.count };
      return dispatch({
        type: LOAD_COUNTS_SUCCESS,
        result: { serviceOrders, plannedMaintenances },
      });
    } catch (error) {
      return dispatch({
        type: LOAD_COUNTS_FAIL,
        error,
      });
    }
  };
};

export const loadOperations = (orderId, functionalLocation) => {
  return async dispatch => {
    dispatch({ type: LOAD_OPERATIONS, orderId });
    try {
      const result = await dispatch(MasterDataService.serviceOrderOperations(orderId, functionalLocation));
      return dispatch({
        orderId,
        type: LOAD_OPERATIONS_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        orderId,
        type: LOAD_OPERATIONS_FAIL,
        error,
      });
    }
  };
};

export const loadServiceOrderLogs =
  (orderId, serviceOrderNumber, refreshCache = false) =>
  async dispatch => {
    dispatch({ type: LOAD_LOGS, orderId });
    try {
      const result = await MasterDataService.serviceOrderLogs(serviceOrderNumber, refreshCache);
      return dispatch({
        type: LOAD_LOGS_SUCCESS,
        result,
        orderId,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_LOGS_FAIL,
        error,
        orderId,
      });
    }
  };

export const deleteFromIndex = serviceOrder => ({
  type: DELETE_FROM_INDEX,
  serviceOrder,
});

const getOrderYear = order => parseISO(order.createdDate).getFullYear();

export default createReducerFromMapping(
  {
    [LOAD_SERVICE_ORDERS_TAB]: state => ({
      ...state,
      calendar: {
        loadingOrders: true,
        loadingFLs: true,
      },
    }),
    [LOAD_SERVICE_ORDERS_TAB_ORDERS_SUCCESS]: state => ({
      ...state,
      calendar: {
        ...state.calendar,
        loadingOrders: false,
      },
    }),
    [LOAD_SERVICE_ORDERS_TAB_LOCATIONS_SUCCESS]: state => ({
      ...state,
      calendar: {
        ...state.calendar,
        loadingFLs: false,
      },
    }),
    [LOAD_SERVICE_ORDERS_TAB_FAIL]: state => ({
      ...state,
      calendar: {
        loadingOrders: false,
        loadingFLs: false,
      },
    }),

    [LOAD]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: true,
      },
    }),
    [LOAD_SUCCESS]: (state, action) => ({
      ...state,
      index: indexByDate.index(state.index, action.result, 'serviceOrderNumber', getOrderYear, action.loadOne),
      loading: {
        ...state.loading,
        [action.key]: false,
      },
    }),
    [LOAD_FAIL]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: false,
      },
    }),

    [SEARCH_SET_TEXT]: (state, action) => ({
      ...state,
      search: {
        ...state.search,
        [action.section]: {
          ...state.search[action.section],
          text: action.text,
        },
      },
    }),
    [SEARCH]: (state, action) => ({
      ...state,
      search: {
        ...state.search,
        [action.section]: {
          ...state.search[action.section],
          loading: true,
          items: [],
          total: 0,
          error: undefined,
        },
      },
    }),
    [SEARCH_SUCCESS]: (state, action) => ({
      ...state,
      index: indexByDate.index(state.index, action.result, 'serviceOrderNumber', getOrderYear),
      search: {
        ...state.search,
        [action.section]: {
          ...state.search[action.section],
          loading: false,
          items: action.result.items,
          total: action.result.total,
        },
      },
    }),
    [SEARCH_FAIL]: (state, action) => ({
      ...state,
      search: {
        ...state.search,
        [action.section]: {
          ...state.search[action.section],
          loading: false,
          items: [],
          total: 0,
          error: action.error,
        },
      },
    }),

    [LOAD_OPERATIONS]: (state, action) => ({
      ...state,
      operations: {
        ...state.operations,
        [action.orderId]: {
          loading: true,
        },
      },
    }),
    [LOAD_OPERATIONS_SUCCESS]: (state, action) => ({
      ...state,
      operations: {
        ...state.operations,
        [action.orderId]: {
          items: action.result,
          loading: false,
        },
      },
    }),
    [LOAD_OPERATIONS_FAIL]: (state, action) => ({
      ...state,
      operations: {
        ...state.operations,
        [action.orderId]: {
          error: action.error,
          loading: false,
        },
      },
    }),

    [LOAD_COUNTS]: state => ({
      ...state,
      kpiCounts: {
        ...initialState.kpiCounts,
        loading: true,
      },
    }),
    [LOAD_COUNTS_SUCCESS]: (state, action) => ({
      ...state,
      kpiCounts: {
        ...action.result,
        loading: false,
      },
    }),
    [LOAD_COUNTS_FAIL]: (state, action) => ({
      ...state,
      kpiCounts: {
        ...state.kpiCounts,
        error: action.error,
        loading: false,
      },
    }),

    [LOAD_LOGS]: (state, action) => ({
      ...state,
      logs: {
        ...state.logs,
        [action.orderId]: {
          loading: true,
        },
      },
    }),
    [LOAD_LOGS_SUCCESS]: (state, action) => ({
      ...state,
      logs: {
        ...state.logs,
        [action.orderId]: {
          items: action.result,
          loading: false,
        },
      },
    }),
    [LOAD_LOGS_FAIL]: (state, action) => ({
      ...state,
      logs: {
        ...state.logs,
        [action.orderId]: {
          error: action.error,
          loading: false,
        },
      },
    }),
    [DELETE_FROM_INDEX]: (state, action) => ({
      ...state,
      index: indexByDate.delete(state.index, action.serviceOrder, 'serviceOrderNumber', getOrderYear),
    }),
  },
  initialState
);
