import { connect } from 'react-redux';

import { loadEnergyRating as loadEnergyRatingValuesByFL } from 'redux/modules/iot/values/energy_rating';
import { loadEnergyObservations } from 'redux/modules/containers/energy';
import { loadBuildingMeta } from 'redux/modules/buildingConfig/buildingMeta';
import { loadFunctionalLocationsForOverview as loadFunctionalLocations } from 'redux/modules';
import { filterFLsBySelectedPartner } from './EnergyUtil';
import { createNoticeSelector } from 'redux/modules/iot/selectors/notice';
import { loadConsumptionKPI } from 'redux/modules/iot/values/consumption';
import { loadBuildingLevelSensorHierarchiesByFLIds } from 'redux/modules/customer/sensorHierarchy';

const EMPTY_ARRAY = [];

const selectObservations = (state, props) =>
  state.profile?.profile?.features?.controlRoomObservations
    ? (state.notice.observationsByPartner[props.match?.params?.partnerNumber]?.energy?.filter(
        item => item.status !== 'completed'
      ) ?? EMPTY_ARRAY)
    : EMPTY_ARRAY;

const noticeSelector = createNoticeSelector(selectObservations);

const isLoadingData = state => {
  const loadingEnergyRating = state.values.energyRating.energyRating.loading;
  const loadingEnergyConsumption = Object.values(state.values.consumption.consumptionKPIs || {}).some(
    kpi => kpi?.loading
  );
  const loadingObservations = state.notice.loadingForPartner;
  return loadingEnergyRating || loadingEnergyConsumption || loadingObservations;
};

const mapStateToProps = (state, props) => ({
  energyValuesByPartner: state.values.flEnergyValues.energyValuesByPartner,
  energyValues: state.values.flEnergyValues.energyValues,
  loading: isLoadingData(state, props),
  buildingMeta: state.buildingMeta,
  functionalLocations: filterFLsBySelectedPartner(
    state.functionalLocations.functionalLocations,
    props.match.params.partnerNumber
  ),
  customers: state.customer.customers,
  features: state.profile.profile.features,
  energyRatingValuesByFL: state.values.energyRating.energyRating.data,
  profile: state.profile.profile,
  notices: noticeSelector(state, props),
  consumptionKPIs: state.values.consumption.consumptionKPIs,
});

const mapDispatchToProps = {
  loadBuildingMeta,
  loadEnergyObservations,
  loadFunctionalLocations,
  loadEnergyRatingValuesByFL,
  loadConsumptionKPI,
  loadBuildingLevelSensorHierarchiesByFLIds,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector;
