import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import translations from 'decorators/Translations/translations';
import { Icon, ICON_SIZES } from 'components/Icon/Icon';
import { icons } from 'components/Icon/IconNames';
import { INCIDENT_ORIGIN } from 'utils/Data/notice';

const CreatedColumn = ({ data, theme }) => {
  const isAnalyticsIncident = data === INCIDENT_ORIGIN.analytics;
  return (
    <Icon
      name={isAnalyticsIncident ? icons.OBSERVATION_ANALYTICS : icons.OBSERVATION_EXPERT}
      size={ICON_SIZES.SMALL}
      fill={theme.colors.black}
    />
  );
};

CreatedColumn.propTypes = {
  data: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

export default withTheme(translations(CreatedColumn));
