import { memo, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { PassThroughOutsideClickHandler } from 'components/Dialog/PassThroughOutsideClickHandler';

import Svg from '../Svg/Svg';
import Loader from 'components/Loader/Loader';
import HeroImage from './HeroImage';
import SimpleDropdown from 'components/SimpleDropdown/SimpleDropdown';
import { flIcons } from 'utils/Data/functionalLocations';
import { icons } from 'components/Icon/IconNames';
import { canEditBuilding, canEditPartner, isSustainabilityEnabled } from 'utils/Data/profileData';
import { isSriToolsEnabled } from 'utils/Data/profileData';

export const HeroContent = styled.div`
  position: relative;
  display: none;
  height: ${props => props.theme.hero.height.default};
  width: 100%;
  background-color: var(--brand-color);
  justify-content: space-between;
  z-index: ${props => props.theme.zIndex('hero')};
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  margin: 0 auto;

  ${props => props.theme.media.portrait`
        display: flex;
    `}

  @media print {
    display: flex;
  }
`;

export const Title = styled.h1`
  color: var(--white);
  font-size: ${props => props.theme.font.size.lg};
  overflow: hidden;
  max-height: ${props => !props.isPublicLink && '50px'};
  text-overflow: ellipsis;
  white-space: nowrap;

  ${props => props.theme.media.desktop`
    font-size: ${props.theme.text.h1};
  `}
`;

Title.displayName = 'Title';

const Background = styled.div`
  width: calc(25% - var(--size-md));
  flex-shrink: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-color-tinted);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(75% - 2 * 2em - 95px);
  flex-grow: 1;
  margin: 0 2em;
`;

const Address = styled.div`
  font-size: ${props => props.theme.fontSize.xs};
  color: var(--hero-details-fg);
  line-height: ${props => props.theme.font.lineHeight.md};
`;

const Ctas = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  border-left: 1px solid var(--white-020);
  padding: 0 2em;

  @media print {
    display: none;
  }
`;

const CtaButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.fontSize.md};
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: var(--white);

  ${props =>
    props.dropdownOpen &&
    css`
      pointer-events: none;
    `}
  &:hover {
    transform: scale(1.125);
  }
`;

const CtaSelector = styled.div`
  position: absolute;
  top: calc(50% + 10px + 5px); /* +10px = caret positioning, +5px = extra */
  right: calc(50% - 10px - 9px); /* -10px = caret positioning, -9px = caret width / 2 */
`;

const Cta = styled(Link)`
  font-size: ${props => props.theme.fontSize.xs};
  color: var(--black);
  padding: var(--size-sm) var(--size-md);
  cursor: pointer;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: var(--grey-08);
  }
`;

const Icon = styled(Svg)`
  fill: var(--white);
  font-size: 3rem;
`;

const Meta = styled.div`
  padding-top: var(--size-md);
  display: flex;
  flex-wrap: wrap;
`;

const MetaItem = styled.span`
  white-space: nowrap;
`;

const MetaKey = styled.span`
  color: var(--hero-details-fg);
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: bold;
  letter-spacing: ${props => props.theme.letterSpacing.default};
  text-transform: uppercase;
`;

const MetaValue = styled.span`
  color: var(--white);
  font-size: ${props => props.theme.font.size.xxs};
  letter-spacing: 0.3px;
  margin-right: var(--size-md);
`;

const getBaseLinkForFLTab = (partnerNumber, functionalLocation) =>
  `/${partnerNumber}/FunctionalLocation/${functionalLocation}`;

const renderIcon = type => {
  const icon = flIcons[type] || icons[type];

  if (!icon) {
    return null;
  }

  return <Icon name={icon} />;
};

const Hero = props => {
  const {
    t,
    partnerNumber,
    functionalLocation,
    title,
    loadingContext,
    customImageElement,
    type,
    showAddress,
    profile,
    isBuilding,
    isOverview,
    isPublicLink,
    subTitle,
    meta,
    heroImage,
    showContactCaverion,
    showNewServiceRequest,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const showPartnerEdit = isOverview && profile && canEditPartner(profile) && partnerNumber !== 'all';
  const showBuildingEdit = isBuilding && profile && canEditBuilding(profile);
  const showCTAs = showPartnerEdit || showBuildingEdit || false;
  const hasCTAs = !!showCTAs;

  const renderPartnerCTAs = () => {
    const link = `/Admin/Partners/Edit/${partnerNumber}`;
    return (
      <Fragment>
        <Cta to={`${link}/Configuration`} data-test-id="LinkToEditConfiguration">
          {t('Portfolio Settings')}
        </Cta>
        <Cta to={`${link}/CustomerAnnouncements`} data-test-id="LinkToEditAnnouncements">
          {t('Announcements')}
        </Cta>
        <Cta to={`${link}/ImportValues`} data-test-id="LinkToImportValues">
          {t('Import values')}
        </Cta>
      </Fragment>
    );
  };

  const renderBuildingCTAs = () => {
    const baseLink = `/Admin/Buildings/Edit/${functionalLocation.functionalLocation}`;
    return (
      <Fragment>
        <Cta to={`${baseLink}/Configuration`} data-test-id="LinkToEditConfiguration">
          {t('Building Settings')}
        </Cta>
        <Cta to={`${baseLink}/FloorsAndSensors`} data-test-id="LinkToEditSensors">
          {t('Floors & Sensors')}
        </Cta>
        {/** 19929 19930 To be removed
        <Cta to={`${baseLink}/Insights`} data-test-id="LinkToInsightConfiguration">
          {t('Building Insights')}
        </Cta>
        */}
        <Cta to={`${baseLink}/AnalyticsConfiguration`} data-test-id="LinkToAnalyticsConfiguration">
          {t('Analytics Configuration')}
        </Cta>
        <Cta to={`${baseLink}/ImportValues`} data-test-id="LinkToImportValues">
          {t('Import values')}
        </Cta>
        {isSriToolsEnabled(profile.features) && (
          <Cta to={`${baseLink}/SRITools`} data-test-id="LinkToSRITools">
            {t('Caverion SRI')}
          </Cta>
        )}
        {isSustainabilityEnabled(profile.features) && (
          <Cta to={`${baseLink}/Sustainability`} data-test-id="LinkToSustainability">
            {t('Sustainability Admin')}
          </Cta>
        )}
        <Cta to={`${baseLink}/News`} data-test-id="LinkToEditAnnouncements">
          {t('Building News')}
        </Cta>
        <Cta to={`${baseLink}/TaggingTool`} data-test-id="LinkToTaggingTool">
          {t('Tagging tool')}
        </Cta>
      </Fragment>
    );
  };

  if (loadingContext) {
    return (
      <HeroContent>
        <Background>
          <Loader color="GRAY" size="MEDIUM" />
        </Background>
      </HeroContent>
    );
  }

  let addressText = '';
  if (subTitle) {
    addressText = subTitle;
  } else if (functionalLocation) {
    addressText = [functionalLocation.address, functionalLocation.city].filter(Boolean).join(', ');
  }

  let newServiceRequestTo, contactCaverionTo;

  if (functionalLocation) {
    newServiceRequestTo = `${getBaseLinkForFLTab(partnerNumber, functionalLocation.functionalLocation)}/ServiceRequest`;
    contactCaverionTo = `${getBaseLinkForFLTab(partnerNumber, functionalLocation.functionalLocation)}/ContactCaverion`;
  } else {
    newServiceRequestTo = `/${partnerNumber}/ServiceRequest`;
    contactCaverionTo = `/${partnerNumber}/ContactCaverion`;
  }

  const openDropdown = () => setDropdownOpen(true);
  const closeDropdown = () => setDropdownOpen(false);

  return (
    <HeroContent data-test-id="FunctionalLocationHero">
      {heroImage && <HeroImage image={heroImage} />}
      {!heroImage && (
        <Background>
          {type && renderIcon(type)}
          {customImageElement}
        </Background>
      )}
      <Content className="hero-contents">
        <Title data-test-id="hero-title" title={title} isPublicLink={isPublicLink}>
          {title}
        </Title>
        {(showAddress || subTitle) && <Address>{addressText}</Address>}
        {meta && meta.length > 0 && (
          <Meta>
            {' '}
            {meta.map(item => (
              <MetaItem key={item.key}>
                <MetaKey>{item.key}</MetaKey> <MetaValue>{item.value}</MetaValue>
              </MetaItem>
            ))}
          </Meta>
        )}
      </Content>
      {hasCTAs && (
        <Ctas>
          <CtaButton onClick={openDropdown} dropdownOpen={dropdownOpen} data-test-id="HeroActionButton">
            <Svg name="fa-ellipsis-h" />
          </CtaButton>
          {dropdownOpen && (
            <PassThroughOutsideClickHandler onOutsideClick={closeDropdown}>
              <CtaSelector>
                <SimpleDropdown disableCaret>
                  {showBuildingEdit && renderBuildingCTAs()}
                  {showPartnerEdit && renderPartnerCTAs()}
                  {showNewServiceRequest && (
                    <Cta to={newServiceRequestTo} onClick={closeDropdown}>
                      {t('New Service Request')}
                    </Cta>
                  )}
                </SimpleDropdown>
              </CtaSelector>
            </PassThroughOutsideClickHandler>
          )}
        </Ctas>
      )}
    </HeroContent>
  );
};

Hero.propTypes = {
  t: PropTypes.func.isRequired,
  partnerNumber: PropTypes.string,
  functionalLocation: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  loadingContext: PropTypes.bool,
  customImageElement: PropTypes.node,
  type: PropTypes.string,
  showAddress: PropTypes.bool,
  profile: PropTypes.object,
  isBuilding: PropTypes.bool,
  isOverview: PropTypes.bool,
  meta: PropTypes.arrayOf(PropTypes.object),
  heroImage: PropTypes.string,
  showContactCaverion: PropTypes.bool,
  showNewServiceRequest: PropTypes.bool,
  isPublicLink: PropTypes.bool,
};

export default memo(Hero);
