import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export const FixedFloorTabNavigationPlaceholder = styled.div`
  height: ${props => props.theme.navigation.height};
  margin: 0 -${props => props.theme.layoutSpacing.md}; /* negative margin */
  ${props => props.theme.media.tablet`
    margin: 0;
  `}
`;
FixedFloorTabNavigationPlaceholder.displayName = 'FixedFloorTabNavigationPlaceholder';

export const FixedFloorTabNavigation = styled.div`
  position: relative;
  background-color: var(--white);
  box-shadow: ${props => props.theme.section.shadow};
  border-radius: 0 0 ${props => props.theme.section.borderRadius} ${props => props.theme.section.borderRadius};
  white-space: nowrap;
  text-align: center;

  ${props =>
    props.fixedMobile &&
    css`
      top: 0;
      left: 0;
      right: 0;
      position: fixed;
      z-index: ${props => props.theme.zIndex('circleTabs')};
    `};

  ${props => props.theme.media.portrait`
      position: initial;
      ${props =>
        props.fixedTablet &&
        css`
          position: fixed;
          top: 80px;
        `}
  `}

  ${props => props.theme.media.landscape`
      position: initial;
      ${props =>
        props.fixed &&
        css`
          position: fixed;
          top: ${props => props.theme.navigation.height};
          left: ${props =>
            props.naviExtended ? props.theme.navigation.sideTabletWidth : props.theme.navigation.sideSmallWidth};
          z-index: ${props => props.theme.zIndex('circleTabs')};
        `}
  `}
  ${props => props.theme.media.desktop`
      position: initial;
      ${props =>
        props.fixed &&
        css`
          position: fixed;
          left: ${props =>
            props.naviExtended ? props.theme.navigation.sideWidth : props.theme.navigation.sideSmallWidth};
        `}
  `}
`;
FixedFloorTabNavigation.displayName = 'FixedFloorTabNavigation';

const FixedFloorTabLabels = ({ scrollTop, naviExtended, children }) => {
  const scrollOffset = 150 + 40; // Hero + margin
  const scrollOffsetMobile = 64 + 80; // Navi + tab navi
  const scrollOffsetTablet = 150 + 64 + 24; // Hero + navi + tablet margin
  const fixed = scrollOffset < scrollTop;
  const fixedMobile = scrollOffsetMobile < scrollTop;
  const fixedTablet = scrollOffsetTablet < scrollTop;

  return (
    <FixedFloorTabNavigationPlaceholder>
      <FixedFloorTabNavigation
        fixed={fixed}
        fixedMobile={fixedMobile}
        fixedTablet={fixedTablet}
        naviExtended={naviExtended}
      >
        {children}
      </FixedFloorTabNavigation>
    </FixedFloorTabNavigationPlaceholder>
  );
};

FixedFloorTabLabels.propTypes = {
  scrollTop: PropTypes.number,
  naviExtended: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default FixedFloorTabLabels;
