import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const getBuildingMeta = (functionalLocation, { refreshCache } = {}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/meta/building/${functionalLocation}`,
    refreshCache,
    useAuthorization: true,
  });

export const upsertBuildingMeta = async buildingMetaArray =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/meta/building`,
    data: buildingMetaArray,
    noCache: true,
    useAuthorization: true,
  });

export const deleteBuildingMeta = async (functionalLocation, key) => {
  await ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/meta/building/${functionalLocation}`,
    query: { key },
    noCache: true,
    useAuthorization: true,
  });
};
