import { useState, useCallback } from 'react';

import { SubmitError } from 'containers/Application/ServiceModule/ServiceModuleForm/utils/SubmitError';

export const useDocumentUpload = onDocumentUpload => {
  const [newFiles, setNewFiles] = useState([]);
  const [erroredFiles, setErroredFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleFileUpload = useCallback(file => setNewFiles(oldFiles => oldFiles.concat([file])), []);
  const handleFileRemoval = useCallback(
    index => setNewFiles(oldFiles => oldFiles.slice(0, index).concat(oldFiles.slice(index + 1, oldFiles.length))),
    []
  );

  const handleUpload = useCallback(
    async event => {
      event.preventDefault();
      setUploading(true);
      try {
        const toUpload =
          erroredFiles.length > 0 ? newFiles.filter((_, index) => erroredFiles.includes(index)) : newFiles;
        await onDocumentUpload(toUpload);
        setNewFiles([]);
        setErroredFiles([]);
      } catch (error) {
        if (error instanceof SubmitError) {
          setErroredFiles(error.erroredFileIndices);
        } else {
          throw error;
        }
      }
      setUploading(false);
    },
    [newFiles, erroredFiles, onDocumentUpload]
  );

  return {
    newFiles,
    erroredFiles,
    uploading,
    handleFileUpload,
    handleFileRemoval,
    handleUpload,
  };
};
